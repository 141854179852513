import { privateRequest } from '../../../utils/request'

class LoanApplicationsApiService {
  #api

  #endPoints

  #abortControllers

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      collection: '/finance/loan-applications',
      instance: (id) => `/finance/loan-applications/${id}`,
      workflowStates: '/loan-application-workflow-states',
      workflows: {
        confirmToApplicationPreparation: (id) =>
          `/finance/loan-applications/${id}/workflow/confirm-to-application-preparation`,
        confirmToApprovalProcessStarted: (id) =>
          `/finance/loan-applications/${id}/workflow/confirm-to-approval-process-started`,
        confirmToRepliesReceived: (id) =>
          `/finance/loan-applications/${id}/workflow/confirm-to-replies-received`,
        confirmToDealerSelected: (id) =>
          `/finance/loan-applications/${id}/workflow/confirm-to-dealer-selected`,
        confirmToCustomerSelected: (id) =>
          `/finance/loan-applications/${id}/workflow/confirm-to-customer-selected`,
      },
    }
    this.#abortControllers = {}
  }

  getLoanApplications = async (params, prefix = 'getLoanApplications') => {
    this.#abortControllers[prefix]?.abort()
    this.#abortControllers[prefix] = new AbortController()
    const response = await this.#api.get(this.#endPoints.collection, {
      params,
      signal: this.#abortControllers[prefix]?.signal,
    })
    return response
  }

  createLoanApplication = async (data) => {
    const response = await this.#api.post(this.#endPoints.collection, data)
    return response
  }

  getWorkflowStates = async () => {
    const response = await this.#api.get(this.#endPoints.workflowStates, {
      params: { pagination: false },
    })
    return response
  }

  confirmToApplicationPreparation = async (loanApplicationId) => {
    const response = await this.#api.post(
      this.#endPoints.workflows.confirmToApplicationPreparation(loanApplicationId),
      {},
    )
    return response
  }

  confirmToApprovalProcessStarted = async (loanApplicationId) => {
    const response = await this.#api.post(
      this.#endPoints.workflows.confirmToApprovalProcessStarted(loanApplicationId),
      {},
    )
    return response
  }

  confirmToRepliesReceived = async (loanApplicationId) => {
    const response = await this.#api.post(
      this.#endPoints.workflows.confirmToRepliesReceived(loanApplicationId),
      {},
    )
    return response
  }
}

const loanApplicationsApiService = new LoanApplicationsApiService({ api: privateRequest })

export default loanApplicationsApiService
