import React from 'react'
import { Box } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const UIMarkerCircle = ({ sx, ...props }) => {
  return (
    <Box
      sx={{
        width: '18px',
        minWidth: '18px',
        height: '18px',
        borderRadius: '50%',
        background: colorsConfig.mainRed,
        '&.success': {
          background: colorsConfig.mainGreen,
        },
        '&.attention': {
          background: colorsConfig.buttonYellow,
        },
        ...sx,
      }}
      {...props}
    />
  )
}

export default UIMarkerCircle
