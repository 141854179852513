import { privateMultipartRequest } from './request'
import { API_ENDPOINTS } from '../config/default'

export const uploadFile = async (file, type, returnUrl) => {
  if (!type || !file) return null
  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', type)
  if (returnUrl) {
    const file = await privateMultipartRequest
      .post(API_ENDPOINTS.file, formData)
      .then((response) => {
        return {
          fileId: response.data['@id'],
          signedUrl: response.data.signedUrl,
        }
      })
      .catch(() => {
        return null
      })
    return file
  }
  const fileId = await privateMultipartRequest
    .post(API_ENDPOINTS.file, formData)
    .then((response) => {
      return response.data['@id']
    })
    .catch(() => {
      return null
    })
  return fileId
}
