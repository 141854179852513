export const closingReasonEnumNames = {
  END_OF_CONTRACT: 'END_OF_CONTRACT',
  TOTAL_DAMAGE: 'TOTAL_DAMAGE',
  CUSTOMER_BUYOUT: 'CUSTOMER_BUYOUT',
  VEHICLE_SOLD: 'VEHICLE_SOLD',
  EARLY_CONTRACT_TERMINATION: 'EARLY_CONTRACT_TERMINATION',
  OTHER_REASON: 'OTHER_REASON',
}

export const closingReasonEnumsArray = [
  {
    value: 0,
    name: 'Unknown',
  },
  {
    value: 1,
    name: 'Restitution',
  },
  {
    value: 2,
    name: 'End of the contract',
  },
  {
    value: 3,
    name: 'Total damage',
  },
  {
    value: 4,
    name: 'Customer buyout',
  },
  {
    value: 5,
    name: 'Vehicle sold',
  },
  {
    value: 6,
    name: 'Early contract termination',
  },
  {
    value: 7,
    name: 'Other',
  },
]

export const closingReasonOptions = [
  {
    label: 'policy_closing_reason_end_of_contract',
    value: closingReasonEnumNames.END_OF_CONTRACT,
  },
  {
    label: 'policy_closing_reason_total_damage',
    value: closingReasonEnumNames.TOTAL_DAMAGE,
  },
  {
    label: 'policy_closing_reason_customer_buyout',
    value: closingReasonEnumNames.CUSTOMER_BUYOUT,
  },
  {
    label: 'policy_closing_reason_vehicle_sold',
    value: closingReasonEnumNames.VEHICLE_SOLD,
  },
  {
    label: 'policy_closing_reason_early_contract_termination',
    value: closingReasonEnumNames.EARLY_CONTRACT_TERMINATION,
  },
  {
    label: 'policy_closing_reason_other',
    value: closingReasonEnumNames.OTHER_REASON,
  },
]
