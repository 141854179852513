import React from 'react'
import { IconButton } from '@mui/material'
import IconCross from '../../assests/img/icons/icon-cross-white.svg'
import { colorsConfig } from '../../config/themeConfig'

const UICheckboxNullableUnchecked = ({ value, setValue }) => {
  return (
    <IconButton
      className={value === false && value !== null ? 'active' : ''}
      sx={{
        border: '1px solid',
        borderColor: colorsConfig.iconGray,
        width: '20px',
        height: '20px',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
          transition: '0.25s all ease-in-out',
          filter: 'brightness(60%)',
        },
        cursor: 'pointer',
        transition: '0.25s all ease-in-out',
        '&.active': {
          transition: '0.25s all ease-in-out',
          borderColor: colorsConfig.mainRed,
          background: colorsConfig.mainRed,
          '& img': {
            filter: 'brightness(100%)',
            transition: '0.25s all ease-in-out',
          },
        },
      }}
      onClick={() => {
        if (typeof value === 'boolean' && value === false) {
          setValue(null)
        } else {
          setValue(false)
        }
      }}
    >
      <img src={IconCross} alt="" />
    </IconButton>
  )
}

export default UICheckboxNullableUnchecked
