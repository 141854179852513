import React, { useEffect, useState } from 'react'
import BreadcrumbsComponent from '../../../../components/BreadcrumbsComponent'
import IconBank from '../../../../assests/img/icons/icon-bank.svg'
import TextH1 from '../../../../ui/Text/TextH1'
import { Box, FormControl, FormControlLabel, Grid, RadioGroup } from '@mui/material'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import SimpleCheckbox from '../../../../ui/SimpleCheckbox'
import TextNormal from '../../../../ui/Text/TextNormal'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { adminRequest } from '../../../../utils/request'
import { API_FINANCE_ADMIN_ENDPOINTS } from '../../../../config/default'
import { toast } from 'react-toastify'
import { setCurrentPageTitle } from '../../../../store/features/authSlice'
import ButtonPlain from '../../../../ui/Buttons/ButtonPlain'
import { colorsConfig } from '../../../../config/themeConfig'
import { Delete } from '@mui/icons-material'
import ModalWrap from '../../../../components/Modals/ModalWrap'
import ButtonDanger from '../../../../ui/Buttons/ButtonDanger'
import ButtonHollow from '../../../../ui/Buttons/ButtonHollow'
import UITextRegular from '../../../../ui/UIText/UITextRegular'
import CustomRadio from '../../../../ui/CustomRadio'

const EditBank = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const [bankName, setBankName] = useState('')
  const [savedBankName, setSavedBankName] = useState('')
  const [bankShortName, setBankShortName] = useState('')
  const [bankEmail, setBankEmail] = useState('')
  const [bankCode, setBankCode] = useState('')
  const [isActive, setIsActive] = useState(false)
  const [logoLink, setLogoLink] = useState('')
  const [confirmDeletionModal, setConfirmDeletionModal] = useState(false)
  const [disableDeleteButton, setDisableDeleteButton] = useState(false)
  const [packageType, setPackageType] = useState('normal')

  const validateEmail = (value) => {
    if (
      !value ||
      !value.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
    )
      return false
    return true
  }

  const hasErrors = !bankName || !bankCode || !validateEmail(bankEmail)

  const handleEditBank = () => {
    const formData = {
      bank: {
        email: bankEmail,
      },
      title: bankName,
      code: bankCode,
      type: 'bank',
      isActive: isActive,
      shortTitle: bankShortName,
      logo: logoLink,
      isBasic: packageType === 'basic',
      isFastest: packageType === 'fastest',
    }
    adminRequest
      .patch(`${API_FINANCE_ADMIN_ENDPOINTS.productProviders}/${params.id}`, formData, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      })
      .then(() => {
        toast.success('Updated bank successfully', { autoClose: 1000 })
        navigate('/admin/financing/banks')
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data['hydra:description']) {
          toast.error(error.response.data['hydra:description'])
        } else toast.error('Unexpected error')
      })
  }

  const handleDeleteBank = () => {
    setDisableDeleteButton(true)
    adminRequest
      .delete(`${API_FINANCE_ADMIN_ENDPOINTS.productProviders}/${params.id}`)
      .then((response) => {
        toast.success('Bank has been deleted', { autoClose: 1000 })
        navigate('/admin/financing/banks')
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data['hydra:description']) {
          toast.error(error.response.data['hydra:description'])
        } else toast.error('Unexpected error')
      })
      .finally(() => {
        setDisableDeleteButton(false)
      })
  }

  const getBank = () => {
    adminRequest
      .get(`${API_FINANCE_ADMIN_ENDPOINTS.productProviders}/${params.id}`)
      .then((response) => {
        const productProvider = response.data
        setBankName(response.data.title)
        setSavedBankName(response.data.title)
        setBankShortName(response.data.shortTitle ? response.data.shortTitle : '')
        setBankCode(response.data.code)
        setBankEmail(response.data.bank.email)
        setIsActive(response.data.isActive)
        setLogoLink(response.data.logo)
        setPackageType(
          productProvider?.isBasic ? 'basic' : productProvider?.isFastest ? 'fastest' : 'normal',
        )
      })
  }

  useEffect(() => {
    getBank()
    dispatch(setCurrentPageTitle('Edit bank'))
  }, [])

  return (
    <Box>
      <BreadcrumbsComponent
        currentPage={{ name: savedBankName ? savedBankName : `Edit bank` }}
        parentPages={[
          {
            name: 'Banks',
            icon: (
              <img src={IconBank} style={{ filter: 'brightness(73%)', width: '13px' }} alt="" />
            ),
            link: '/admin/financing/banks',
          },
        ]}
      />
      <TextH1
        sx={{
          fontSize: '36px',
          mb: '30px',
          mt: '10px',
        }}
      >
        Edit bank "{savedBankName}"
      </TextH1>
      <Grid container spacing={'20px'}>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Bank name'}
            value={bankName}
            onChange={(e) => {
              setBankName(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            fullWidth
            label={'Bank short name'}
            value={bankShortName}
            onChange={(e) => {
              setBankShortName(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Bank email'}
            value={bankEmail}
            onChange={(e) => {
              setBankEmail(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Bank code'}
            value={bankCode}
            onChange={(e) => {
              setBankCode(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            fullWidth
            label={'Logo link'}
            value={logoLink}
            onChange={(e) => {
              setLogoLink(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <SimpleCheckbox value={isActive} setValue={(value) => setIsActive(value)} />
            <TextNormal
              sx={{
                pl: '6px',
                cursor: 'pointer',
              }}
              onClick={() => setIsActive(!isActive)}
            >
              Is active
            </TextNormal>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <UITextRegular sx={{ fontWeight: 700 }} text="Set bank offers as" />
          <FormControl>
            <RadioGroup
              row
              value={packageType}
              onChange={(e) => {
                setPackageType(e.target.value)
              }}
              sx={{
                gap: '30px',
              }}
            >
              <FormControlLabel control={<CustomRadio />} value="normal" label="Normal" />
              <FormControlLabel control={<CustomRadio />} value="basic" label="Basic" />
              <FormControlLabel control={<CustomRadio />} value="fastest" label="Fastest" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: '40px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ButtonPlain
          sx={{
            color: colorsConfig.mainRed,
            '&:hover': {
              color: colorsConfig.darkRed,
            },
          }}
          onClick={() => {
            setConfirmDeletionModal(true)
          }}
        >
          <Delete
            sx={{
              fill: colorsConfig.mainRed,
              mr: '4px',
            }}
          />
          Delete
        </ButtonPlain>
        <ButtonLG
          sx={{
            minWidth: '187px',
          }}
          disabled={hasErrors}
          onClick={() => {
            handleEditBank()
          }}
        >
          Save
        </ButtonLG>
      </Box>
      <ModalWrap open={confirmDeletionModal} setOpen={setConfirmDeletionModal}>
        <TextH1 sx={{ mb: '30px', fontSize: '32px' }}>
          Are you sure you want to delete bank "{savedBankName}"?
        </TextH1>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <ButtonHollow
            sx={{
              flex: 1,
            }}
            onClick={() => {
              setConfirmDeletionModal(false)
            }}
          >
            Cancel
          </ButtonHollow>
          <ButtonDanger
            sx={{
              flex: 1,
              '&.Mui-disabled': {
                border: 'none',
                background: colorsConfig.lightGray,
                color: 'white',
              },
            }}
            disabled={disableDeleteButton}
            onClick={() => {
              handleDeleteBank()
            }}
          >
            Delete
          </ButtonDanger>
        </Box>
      </ModalWrap>
    </Box>
  )
}

export default EditBank
