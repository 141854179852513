import { updateCoinPricing } from '../coinPricings.actions'

export default (builder) => {
  builder
    .addCase(updateCoinPricing.pending, (state) => {
      state.updateCoinPricing.isLoading = true
    })
    .addCase(updateCoinPricing.fulfilled, (state, action) => {
      state.updateCoinPricing.isLoading = false
      state.updateCoinPricing.error = null
      state.coinPricings = state.coinPricings.map((item) => {
        if (item['@id'] === action.payload['@id']) {
          return action.payload
        }
        return item
      })
    })
    .addCase(updateCoinPricing.rejected, (state, action) => {
      state.updateCoinPricing.isLoading = false
      state.updateCoinPricing.error = action.payload
    })
}
