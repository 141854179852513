import React, { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import UITextBodySmall from '../../ui/UIText/UITextBodySmall'
import { colorsConfig } from '../../config/themeConfig'
import { customBorder, UISize } from '../../utils/constants/ui.constants'
import UITextButton from '../../ui/UIButtons/UITextButton'
import IconDocumentUploaded from '../../assests/img/icons/icon-appraisal-document.svg'
import IconFileRefresh from '../../assests/img/icons/icon-file-refresh.svg'
import IconDownloadFileAlt from '../../assests/img/icons/icon-file-download-alt.svg'
import { useDispatch, useSelector } from 'react-redux'
import { downloadPrivateFile } from '../../store/features/files/files.actions'
import { useTranslation } from 'react-i18next'
import UIPreloaderCircular from '../../ui/UIPreloader/UIPreloaderCircular'
import UploadTradeLicenseModal from '../../components/Modals/UploadTradeLicenseModal/UploadTradeLicenseModal'

const TradeLicense = () => {
  const { t } = useTranslation()
  const user = useSelector((state) => state.auth.user)
  const isDownloading = useSelector((state) => state.files.download.isLoading)
  const dispatch = useDispatch()

  const fileId = user?.manager?.company?.tradeLicense

  const [uploadLicenseModal, setUploadLicenseModal] = useState(false)

  const handleDownload = () => {
    dispatch(
      downloadPrivateFile({ filename: t('form_input_label_register_copy').toString(), fileId }),
    )
  }

  useEffect(() => {
    console.log({ user })
  }, [user])

  return (
    <Stack gap="8px" sx={{ mt: '30px' }}>
      <UITextBodySmall
        color={colorsConfig.lightGray}
        sx={{ fontSize: '13px' }}
        text="form_input_label_register_copy"
      />
      <Stack direction="row" alignItems="stretch" gap="4px">
        <Box
          sx={{
            width: '86px',
            height: '92px',
            borderRadius: '6px',
            verticalAlign: 'top',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: colorsConfig.bgLightBlue,
            ...customBorder.mono,
            '& img': {
              width: '59px',
              verticalAlign: 'top',
              position: 'relative',
              marginRight: '-1px',
            },
          }}
        >
          <img src={IconDocumentUploaded} alt="" />
        </Box>
        <Stack gap="4px" justifyContent="center">
          <UITextButton
            prefixIcon={<img src={IconFileRefresh} style={{ width: '16px' }} alt="" />}
            size={UISize.sm}
            text="button_update"
            sx={{ justifyContent: 'flex-start', width: '105px' }}
            onClick={() => setUploadLicenseModal(true)}
          />
          <Stack direction="row" alignItems="center">
            <UITextButton
              prefixIcon={<img src={IconDownloadFileAlt} style={{ width: '16px' }} alt="" />}
              size={UISize.sm}
              text="button_download"
              sx={{ justifyContent: 'flex-start', width: '105px' }}
              onClick={() => handleDownload()}
              disabled={isDownloading}
            />
            {isDownloading && <UIPreloaderCircular size={UISize.xs} />}
          </Stack>
        </Stack>
      </Stack>
      {uploadLicenseModal && (
        <UploadTradeLicenseModal open={uploadLicenseModal} setOpen={setUploadLicenseModal} />
      )}
    </Stack>
  )
}

export default TradeLicense

// <Grid container spacing={'24px'}>
//   <Grid item sm={3} xs={12}>
//     <Box
//       sx={{
//         width: '100px',
//         position: 'relative',
//         marginTop: '34px',
//         '& .MuiButtonBase-root': {
//           display: 'flex',
//           justifyContent: 'flex-start',
//         },
//       }}
//     >
//       <TextField
//         variant="standard"
//         disabled
//         fullWidth
//         InputLabelProps={{
//           style: {
//             paddingLeft: '36px',
//             marginTop: '-14px',
//             top: '-3px',
//             left: '0px',
//             '&.Mui-focused': {
//               color: 'black',
//             },
//             fontSize: '16px',
//           },
//         }}
//         type="file"
//         InputProps={{
//           disableUnderline: true,
//           inputComponent: FileUploader,
//           inputProps: {
//             handleChange: handleSetLicense,
//             name: 'file',
//             types: ['JPG', 'PNG', 'jpeg', 'pdf'],
//             hoverTitle: 'Drop here',
//             label: 'Upload or drop all the photos here',
//             classes: 'upload-picture',
//             maxSize: 10,
//             onDrop: () => {
//               setTradeLicenseUploaded(false)
//               setTradeLicenseDropped(true)
//             },
//             children: (
//               <>
//                 <Box
//                   sx={{
//                     borderRadius: '6px',
//                     display: 'flex',
//                     flexDirection: 'row',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     minHeight: '92px',
//                     background: 'none',
//                     cursor: 'pointer',
//                   }}
//                 ></Box>
//                 <Typography
//                   sx={{
//                     position: 'absolute',
//                     top: '-21px',
//                     left: 0,
//                     fontSize: '13px',
//                     color: '#00000061',
//                     fontFamily: '"Helvetica", sans-serif',
//                     letterSpacing: '0.00938em',
//                     lineHeight: '1.4375em',
//                   }}
//                 >
//                   {t('form_input_label_register_copy')}
//                 </Typography>
//               </>
//             ),
//           },
//         }}
//         sx={{
//           width: '86px',
//           position: 'relative',
//           top: '16px',
//           'label.is-disabled': {
//             border: 'none',
//             backgroundColor: '#F7FAFB',
//             backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='black' stroke-width='1' stroke-dasharray='10%2c 10' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e")`,
//             borderRadius: '6px',
//           },
//           '& .MuiTypography-root': {
//             width: '100px',
//             marginTop: '-3px',
//           },
//         }}
//       />
//       <Box
//         sx={{
//           position: 'absolute',
//           top: '64.5px',
//           left: '14.5px',
//           transform: 'translate(0, -50%)',
//         }}
//       >
//         <svg
//           width="59"
//           height="65"
//           viewBox="0 0 59 65"
//           fill="none"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <g clip-path="url(#clip0_2495_70049)">
//             <path
//               d="M44.0399 27.9729V15.744C44.0399 15.3941 43.8784 15.0706 43.6496 14.8148L29.9391 0.417625C29.6831 0.148422 29.3194 0 28.956 0H7.21864C3.20545 0 0 3.27259 0 7.28604V48.3902C0 52.4037 3.20545 55.6226 7.21851 55.6226H24.377C27.6224 61.0096 33.5217 64.6189 40.2419 64.6189C50.4505 64.6189 58.7872 56.3227 58.7872 46.1007C58.8011 37.1714 52.3899 29.7103 44.0399 27.9731V27.9729ZM30.303 4.72753L39.5013 14.4107H33.5351C31.7573 14.4107 30.3029 12.9428 30.3029 11.1652L30.303 4.72753ZM7.21851 52.9288C4.70027 52.9288 2.6938 50.9085 2.6938 48.3904V7.28604C2.6938 4.75403 4.70027 2.6938 7.21851 2.6938H27.6092V11.1649C27.6092 14.4375 30.2625 17.1043 33.5351 17.1043H41.3461V27.6226C40.9424 27.6092 40.619 27.5687 40.2689 27.5687C35.5687 27.5687 31.2455 29.3736 27.9863 32.2019H10.8821C10.1411 32.2019 9.5352 32.8079 9.5352 33.5483C9.5352 34.2894 10.141 34.8952 10.8821 34.8952H25.4813C24.5249 36.2421 23.7302 37.589 23.1111 39.0705H10.882C10.141 39.0705 9.53508 39.6764 9.53508 40.4174C9.53508 41.1578 10.1409 41.7643 10.882 41.7643H22.2353C21.8986 43.1112 21.7236 44.606 21.7236 46.1008C21.7236 48.5248 22.1948 50.9224 23.0433 52.9426H7.21851V52.9288ZM40.2557 61.9389C31.5285 61.9389 24.4307 54.8411 24.4307 46.1141C24.4307 37.3868 31.5148 30.2891 40.2557 30.2891C48.9962 30.2891 56.0801 37.3868 56.0801 46.1141C56.0801 54.8411 48.9828 61.9389 40.2557 61.9389Z"
//               fill="#007DFF"
//             />
//             <path
//               d="M10.8821 28.1468H24.5249C25.2659 28.1468 25.8718 27.5405 25.8718 26.7999C25.8718 26.0591 25.266 25.4531 24.5249 25.4531H10.8821C10.1411 25.4531 9.53516 26.0589 9.53516 26.7999C9.53516 27.5405 10.141 28.1468 10.8821 28.1468Z"
//               fill="#007DFF"
//             />
//             <path
//               d="M41.3805 52.3086C40.963 52.8743 40.3128 53.222 39.6097 53.2566C38.9067 53.2915 38.2257 53.0086 37.7546 52.4863L33.5331 47.8096C32.8622 47.0668 32.9218 45.9217 33.6659 45.252C34.4107 44.5822 35.557 44.642 36.2279 45.3852L39.2146 48.6942C39.2589 48.7434 39.3231 48.7698 39.3892 48.7665C39.4555 48.7633 39.5162 48.7302 39.5558 48.6775L45.6949 40.3654C46.2891 39.5602 47.4247 39.3889 48.2316 39.9824C49.0378 40.5759 49.2091 41.7096 48.6145 42.5144L41.3805 52.3086Z"
//               fill="#007DFF"
//             />
//           </g>
//           <defs>
//             <clipPath id="clip0_2495_70049">
//               <rect width="59" height="64.619" fill="white" />
//             </clipPath>
//           </defs>
//         </svg>
//       </Box>
//       <IconButton
//         sx={{
//           position: 'absolute',
//           top: 'calc(50% - 30px)',
//           left: '80px',
//           background: 'none',
//           '&:hover': {
//             backgroundColor: 'transparent',
//           },
//           marginTop: '9px',
//           width: 'fit-content',
//           padding: '10px 20px',
//         }}
//         onClick={() => {
//           console.log('Update function is mounted!')
//           setTradeLicenseModal(true)
//         }}
//       >
//         <SvgIcon sx={{ fontSize: '16px', marginBottom: '3px' }}>
//           <svg
//             width="18"
//             height="24"
//             viewBox="0 0 12 16"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M8.16219 0.117059C8.09273 0.0439009 7.99753 0 7.89885 0H1.99027C0.899939 0 0 0.896359 0 1.98654V13.1597C0 14.25 0.899968 15.1464 1.99024 15.1464H10.0097C11.1 15.1464 12 14.25 12 13.1597V4.28773C12 4.19265 11.9561 4.10124 11.8939 4.03166L8.16219 0.117059ZM8.26828 1.2915L10.7671 3.91463H9.14272C8.65978 3.91463 8.26828 3.5268 8.26828 3.04392V1.2915ZM10.0097 14.4147H1.99024C1.30614 14.4147 0.731701 13.8477 0.731701 13.1597V1.98654C0.731701 1.30244 1.30244 0.731701 1.99024 0.731701H7.53658V3.04389C7.53658 3.93289 8.25372 4.64633 9.14272 4.64633H11.2683V13.1597C11.2683 13.8477 10.6975 14.4147 10.0097 14.4147Z"
//               fill="#007DFF"
//             />
//             <path
//               d="M3.31677 6.06879L3.18822 5.52632C3.17784 5.48277 3.15898 5.44169 3.13272 5.40542C3.10646 5.36916 3.07332 5.33842 3.03518 5.31497C2.99704 5.29151 2.95466 5.2758 2.91045 5.26872C2.86624 5.26165 2.82106 5.26335 2.77751 5.27373C2.73396 5.28411 2.69288 5.30297 2.65661 5.32923C2.62035 5.35549 2.58961 5.38863 2.56615 5.42677C2.5427 5.46491 2.52698 5.50729 2.51991 5.5515C2.51283 5.59571 2.51454 5.64088 2.52492 5.68444L2.86428 7.08688C2.88562 7.17411 2.94054 7.24938 3.0171 7.29632C3.09365 7.34326 3.18564 7.35808 3.27306 7.33755L4.67679 7.00204C5.11899 6.89406 4.95831 6.23076 4.51611 6.33874L3.84124 6.50199C4.19926 6.05002 4.68374 5.71486 5.23294 5.53922C5.78214 5.36359 6.37119 5.35543 6.92504 5.51578C7.4789 5.67613 7.97247 5.99773 8.34289 6.43962C8.7133 6.8815 8.94378 7.42365 9.00496 7.99699C9.04224 8.46233 9.74667 8.38649 9.6824 7.92372C9.48572 6.08036 7.91745 4.70748 5.91855 4.72676C4.74621 4.84246 3.89394 5.34636 3.31677 6.06879ZM7.54852 9.44186C7.08833 9.53698 7.25415 10.2299 7.70664 10.1052L8.38151 9.94191C8.02431 10.3955 7.53989 10.7322 6.99025 10.909C6.4406 11.0857 5.85073 11.0944 5.29609 10.934C4.74146 10.7736 4.2473 10.4514 3.87683 10.0086C3.50637 9.56573 3.27645 9.02243 3.2165 8.44819C3.21423 8.40182 3.2025 8.35641 3.18204 8.31473C3.16157 8.27306 3.13281 8.23601 3.09751 8.20586C3.06221 8.17571 3.02112 8.15309 2.97676 8.13939C2.9324 8.12569 2.88571 8.12121 2.83956 8.12621C2.7934 8.13121 2.74876 8.14559 2.70836 8.16847C2.66797 8.19136 2.63268 8.22225 2.60466 8.25927C2.57664 8.29628 2.55648 8.33863 2.54542 8.38372C2.53436 8.42881 2.53263 8.47568 2.54034 8.52146C2.64265 9.4666 3.11549 10.3326 3.85524 10.9297C4.59498 11.5268 5.54128 11.8063 6.48673 11.7069C6.9579 11.6562 7.41429 11.5123 7.82939 11.2837C8.24448 11.0551 8.61 10.7462 8.90469 10.3751L9.03581 10.9189C9.11937 11.3945 9.83794 11.2222 9.69654 10.7607L9.36103 9.35702C9.32247 9.19505 9.17336 9.0845 8.97796 9.09992L7.54852 9.44186Z"
//               fill="#007DFF"
//             />
//           </svg>
//         </SvgIcon>
//         <Typography
//           sx={{
//             fontSize: '16px',
//             color: '#007DFF',
//           }}
//         >
//           {t('button_update')}
//         </Typography>
//       </IconButton>
//       <IconButton
//         sx={{
//           position: 'absolute',
//           top: 'calc(50% + 8px)',
//           left: '80px',
//           background: 'none',
//           '&:hover': {
//             backgroundColor: 'transparent',
//           },
//           marginTop: '9px',
//           width: 'fit-content',
//           padding: '10px 20px',
//         }}
//         onClick={() => {
//           handleDownloadFile()
//           // setTimeout(() => setTradeLicenseUploaded(null), 0);
//         }}
//       >
//         <SvgIcon sx={{ fontSize: '16px', marginBottom: '3px' }}>
//
//         </SvgIcon>
//         <Typography
//           sx={{
//             fontSize: '16px',
//             color: '#007DFF',
//           }}
//         >
//           {t('button_download')}
//         </Typography>
//       </IconButton>
//     </Box>
//   </Grid>
// </Grid>
