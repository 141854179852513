import React from 'react'
import { Box, Button, IconButton, styled, Typography } from '@mui/material'
import { AccountCircleRounded, Close } from '@mui/icons-material'
import { colorsConfig } from '../../config/themeConfig'
import { useSelector, useDispatch } from 'react-redux'
import IconCoin from '../../assests/img/icons/icon-fleetify-coin.svg'
import { useNavigate, useLocation } from 'react-router-dom'
import { menuConfig } from '../../config/menuConfig'
import { logOut } from '../../store/features/authSlice'
import iconLogOut from '../../assests/img/icons/icon-log-out.svg'
import TextNormal from '../../ui/Text/TextNormal'

const sections = [
  {
    title: 'FAQ',
    link: 'faq',
  },
  {
    title: 'Profile Management',
    link: 'profile-management',
  },
  {
    title: 'Fleatify coins',
    link: 'fleetify-coins',
  },
  {
    title: 'Dashboard',
    link: 'dashboard',
  },
  {
    title: 'Virtual Garage',
    link: 'virtual-garage',
  },
  {
    title: 'Appraisal',
    link: 'appraisal',
  },
  {
    title: 'Auction',
    link: 'auction',
  },
  {
    title: 'Loan/Finance',
    link: 'finance',
  },
  {
    title: 'Insurance',
    link: 'insurance',
  },
  {
    title: 'Warranty',
    link: 'warranty',
  },
  {
    title: 'Transportation',
    link: 'transportation',
  },
  {
    title: 'Subscription',
    link: 'subscription',
  },
  {
    title: 'Stock finance',
    link: 'stock-finance',
  },
]

const HelpHeaderMobileMenu = (props) => {
  const { open, setOpen } = props

  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const avatar = useSelector((state) => state.auth.avatar)
  const role = useSelector((state) => state.auth.role)
  const auctionCarsAmount = useSelector((state) => state.auth.auctionCarsAmount)

  return (
    <Box
      className={open ? 'open' : ''}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'white',
        zIndex: 999999,
        pt: '30px',
        px: '15px',
        pb: '20px',
        opacity: 0,
        pointerEvents: 'none',
        overflowY: 'auto',
        transformOrigin: '50% 50%',
        transform: 'scale(0.5)',
        transition: '0.4s all ease-in-out',
        '&.open': {
          pointerEvents: 'auto',
          opacity: 1,
          transform: 'scale(1)',
          transition: '0.4s all ease-in-out',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          <Close
            style={{
              fill: colorsConfig.mainRed,
            }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {role && role === 'COMPANY' ? (
          <Box
            sx={{
              width: '34px',
              height: '34px',
              border: `${colorsConfig.mainBlue} 2px solid`,
              borderRadius: '50%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              marginRight: '45px',
            }}
          >
            <img
              src={avatar}
              alt=""
              style={{
                width: '100%',
              }}
            />
          </Box>
        ) : (
          <Box>
            <AccountCircleRounded
              sx={{
                fontSize: '30px',
                position: 'relative',
                bottom: '-2px',
                fill: 'white',
              }}
            />
          </Box>
        )}
        {role && role === 'COMPANY' ? (
          <Button
            variant="outlined"
            sx={{
              textTransform: 'none',
              fontStyle: 'normal',
              lineHeight: 'normal',
              fontSize: '16px',
              fontWeight: 700,
              color: colorsConfig.mainBlack,
              height: '36px',
              border: `1px solid ${colorsConfig.mainBlack}`,
              padding: '5px',
            }}
            onClick={() => navigate('/virtual-garage')}
          >
            Go to Fleatify
          </Button>
        ) : null}
      </Box>
      <Box
        sx={{
          mt: '20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {sections.map((item, index) => {
          const { title, link } = item
          return (
            <Box
              key={index}
              sx={{
                mb: '6px',
                '&:last-child': {
                  mb: 0,
                },
              }}
            >
              <Button
                className={location.pathname === link ? 'active' : ''}
                sx={{
                  textTransform: 'none',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  position: 'relative',
                  '&::after': {
                    content: '" "',
                    position: 'absolute',
                    display: 'block',
                    height: '2px',
                    width: 'calc(100% - 12px)',
                    bottom: '4px',
                    left: '6px',
                    background: colorsConfig.mainGreen,
                    opacity: 0,
                    transformOrigin: '0% 50%',
                    transition: '0.3s all ease-in-out',
                    transform: 'scaleX(0)',
                  },
                  '&:hover': {
                    '&::after': {
                      opacity: 1,
                      transition: '0.3s all ease-in-out',
                      transform: 'scaleX(1)',
                    },
                  },
                  '&.active': {
                    '&::after': {
                      opacity: 1,
                      transform: 'scaleX(1)',
                    },
                  },
                }}
                onClick={() => {
                  setOpen(false)
                  document.getElementById(link).scrollIntoView({ behavior: 'smooth' })
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: colorsConfig.mainBlack,
                    fontWeight: 400,
                    fontSize: '16px',
                    fontFamily: '"Helvetica", sans-serif',
                  }}
                >
                  {title}
                </Typography>
              </Button>
            </Box>
          )
        })}
        {role === 'COMPANY' ? (
          <Box
            sx={{
              mt: '35px',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Button
              className={location.pathname === '/profile-management' ? 'active' : ''}
              sx={{
                textTransform: 'none',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                textAlign: 'center',
                '&::after': {
                  content: '" "',
                  position: 'absolute',
                  display: 'block',
                  height: '2px',
                  width: 'calc(100% - 12px)',
                  bottom: '4px',
                  left: '6px',
                  background: colorsConfig.mainGreen,
                  opacity: 0,
                  transformOrigin: '0% 50%',
                  transition: '0.3s all ease-in-out',
                  transform: 'scaleX(0)',
                },
                '&:hover': {
                  '&::after': {
                    opacity: 1,
                    transition: '0.3s all ease-in-out',
                    transform: 'scaleX(1)',
                  },
                },
                '&.active': {
                  '&::after': {
                    opacity: 1,
                    transform: 'scaleX(1)',
                  },
                },
              }}
              onClick={() => {
                navigate('/profile-management')
                setOpen(false)
              }}
            >
              <TextNormal>Account Settings</TextNormal>
            </Button>
            <Button
              onClick={() => {
                navigate('/help')
                setOpen(false)
              }}
              className={location.pathname === '/help' ? 'active' : ''}
              sx={{
                textTransform: 'none',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                textAlign: 'center',
                '&::after': {
                  content: '" "',
                  position: 'absolute',
                  display: 'block',
                  height: '2px',
                  width: 'calc(100% - 12px)',
                  bottom: '4px',
                  left: '6px',
                  background: colorsConfig.mainGreen,
                  opacity: 0,
                  transformOrigin: '0% 50%',
                  transition: '0.3s all ease-in-out',
                  transform: 'scaleX(0)',
                },
                '&:hover': {
                  '&::after': {
                    opacity: 1,
                    transition: '0.3s all ease-in-out',
                    transform: 'scaleX(1)',
                  },
                },
                '&.active': {
                  '&::after': {
                    opacity: 1,
                    transform: 'scaleX(1)',
                  },
                },
              }}
            >
              <TextNormal>Fleatify help</TextNormal>
            </Button>
          </Box>
        ) : null}
        <Box
          sx={{
            mt: '35px',
            position: 'relative',
            ml: '-8px',
          }}
        >
          <Button
            sx={{
              textTransform: 'none',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              position: 'relative',
              '&::after': {
                content: '" "',
                position: 'absolute',
                display: 'block',
                height: '2px',
                width: 'calc(100% - 12px)',
                bottom: '4px',
                left: '6px',
                background: colorsConfig.mainGreen,
                opacity: 0,
                transformOrigin: '0% 50%',
                transition: '0.3s all ease-in-out',
                transform: 'scaleX(0)',
              },
              '&:hover': {
                '&::after': {
                  opacity: 1,
                  transition: '0.3s all ease-in-out',
                  transform: 'scaleX(1)',
                },
              },
            }}
            onClick={() => {
              dispatch(logOut())
              setOpen(false)
            }}
          >
            <TextNormal>Log out</TextNormal>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default HelpHeaderMobileMenu
