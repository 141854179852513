import { formatDate } from '../../utils/date.helpers'
import { CURRENCY, REGION_LOCALE } from '../../utils/constants/global.constants'

export const getPackageName = (selectedProduct) => {
  if (typeof selectedProduct !== 'string' || !selectedProduct || selectedProduct === '') return '-'
  if (selectedProduct === '/reference/warranty/warranty-products/1') {
    return 'Basic'
  }
  if (selectedProduct === '/reference/warranty/warranty-products/2') {
    return 'Silver'
  }
  if (selectedProduct === '/reference/warranty/warranty-products/3') {
    return 'Platinum'
  }
}

export const getExpiryDate = (inputDate) => {
  const compareDate = new Date()
  compareDate.setFullYear(compareDate.getFullYear() - 5)
  const date = new Date(inputDate)
  if (date < compareDate) return '-'
  date.setFullYear(date.getFullYear() + 1)
  return formatDate(date)
}

export const getWarrantyApplicationsTableData = (warrantyApplications) => {
  return warrantyApplications.map((item) => {
    return {
      id: item?.id,
      link: `/cars/${item?.car?.id}?service=Warranty`,
      cells: [
        {
          type: 'text',
          value: `#${item?.id}`,
        },
        {
          type: 'text-with-caption',
          value: `${item?.car?.make?.name} ${item?.car?.model?.name}`,
          caption: `VIN: ${item?.car?.vin}`,
        },
        {
          type: 'text',
          value: formatDate(item?.createdAt),
        },
      ],
    }
  })
}

export const getWarrantyApplicationsActivatedTableData = (warrantyApplications) => {
  return warrantyApplications.map((item) => {
    return {
      id: item?.id,
      link: `/cars/${item?.car?.id}?service=Warranty`,
      cells: [
        {
          type: 'text',
          value: `#${item?.id}`,
        },
        {
          type: 'text-with-caption',
          value: `${item?.car?.make?.name} ${item?.car?.model?.name}`,
          caption: `VIN: ${item?.car?.vin}`,
        },
        {
          type: 'text',
          value: getPackageName(item?.selectedProduct),
        },
        {
          type: 'text',
          value: getExpiryDate(item?.startDate),
        },
      ],
    }
  })
}

export const getWarrantyProductsTableData = (data) => {
  return data.map((item) => {
    return {
      initialData: item,
      id: item?.id,
      link: `/admin/warranty/products/${item?.id}/edit`,
      cells: [
        {
          type: 'text',
          value: `#${item?.id}`,
        },
        {
          type: 'text',
          value: item?.name,
        },
        {
          type: 'text',
          value: `${item?.terms?.[0]?.premium} ${CURRENCY[REGION_LOCALE]}` ?? '-',
        },
        {
          type: 'text',
          value: `${item?.carAgeMax} months`,
        },
        {
          type: 'text',
          value: `${item?.mileageMax} km`,
        },
      ],
    }
  })
}

export const getCoveredComponentsTableData = (data) => {
  return data.map((item) => {
    return {
      initialData: item,
      id: item?.id,
      link: `/admin/warranty/components/${item?.id}/edit`,
      cells: [
        {
          type: 'text',
          value: `#${item?.id}`,
        },
        {
          type: 'text',
          value: item?.name,
        },
        {
          type: 'text',
          value: formatDate(item?.createdAt),
        },
      ],
    }
  })
}
