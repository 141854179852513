import { createSlice } from '@reduxjs/toolkit'
import getCompanyAddressesBuilder from './builders/getCompanyAddresses.builder'

const initialState = {
  isLoading: true,
  data: [],
}

const companyAddressesSlice = createSlice({
  name: 'companyAddressesReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getCompanyAddressesBuilder(builder)
  },
})

export default companyAddressesSlice.reducer
