import React, { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import DoubleSwitch from '../../../ui/DoubleSwitch'
import { carSpecsOptions } from '../../../default-data/defaultOptions'
import './CarSpecCategory.scss'
import CarSpecOption from './CarSpecOption'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import TextNormal from '../../../ui/Text/TextNormal'

const CarSpecCategory = (props) => {
  const { catId, title, specs, changeCarSpec, readOnly } = props
  const [categoryExpanded, setCategoryExpanded] = useState(false)

  const renderOptionInput = (type) => {
    switch (type) {
      case 'switch':
        return (
          <div className="car-option-input">
            <DoubleSwitch />
          </div>
        )
        break
      case 'select':
        break
    }
  }

  return (
    <Box>
      {readOnly ? (
        <Box
          sx={{
            mb: '12px',
          }}
        >
          <TextBoldNormal
            sx={{
              mb: '9px',
            }}
          >
            {title}{' '}
            {specs.filter((item) => item.value === true).length > 0
              ? `(${specs.filter((item) => item.value === true).length})`
              : ''}
          </TextBoldNormal>
          {specs.map((option, index) => {
            if (!option.value) return null
            return (
              <TextNormal
                key={index}
                sx={{
                  mb: '5px',
                }}
              >
                {option.name}
              </TextNormal>
            )
          })}
        </Box>
      ) : (
        <Accordion
          expanded={categoryExpanded}
          onChange={() => {
            setCategoryExpanded(!categoryExpanded)
          }}
          sx={{
            mb: '10px',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: 600 }}>
              {title}{' '}
              {specs.filter((item) => item.value === true).length > 0
                ? `(${specs.filter((item) => item.value === true).length})`
                : ''}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ borderTop: '1px solid lightgray', paddingTop: '18px' }}>
            {specs.map((option, index) => {
              return (
                <CarSpecOption
                  key={index}
                  name={option.name}
                  type={'checkbox'}
                  value={option.value}
                  id={option['@id']}
                  changeCarSpec={changeCarSpec}
                  readOnly={readOnly}
                />
              )
            })}
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  )
}

export default CarSpecCategory
