import React from 'react'
import { Box, Modal, Stack } from '@mui/material'
import { ReceiptRounded } from '@mui/icons-material'
import AnimationFile from '../../assests/img/animations/animtion-financing.gif'
import TextH1 from '../../ui/Text/TextH1'
import TextNormal from '../../ui/Text/TextNormal'
import IconWarranty from '../../assests/img/icons/icon-warranty.svg'
import ButtonLG from '../../ui/Buttons/ButtonLG'
import CarWarrantyDocuments from '../../pages/Warranty/CarWarranty/components/CarWarrantyDocuments'
import ModalWrap from './ModalWrap'

const AnimationFinancingModal = ({ open, setOpen }) => {
  return (
    <ModalWrap open={open} setOpen={setOpen}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="space-between"
      >
        <img style={{ width: '152px' }} src={AnimationFile} alt="" />
        <Box>
          <TextH1
            sx={{
              whiteSpace: 'nowrap',
              mb: '10px',
              fontSize: {
                xs: '24px',
                md: '40px',
              },
            }}
          >
            Congratulations!
          </TextH1>
          <TextNormal sx={{ mb: '30px' }}>
            You have successfully activated car warranty! Thank you for choosing Fleetify as your
            trusted service.
          </TextNormal>
          <CarWarrantyDocuments />
        </Box>
      </Stack>
    </ModalWrap>
  )
}

export default AnimationFinancingModal
