import React, { useEffect, useState } from 'react'
import BreadcrumbsComponent from '../../../../components/BreadcrumbsComponent'
import IconProductType from '../../../../assests/img/icons/icon-product-type.svg'
import TextH1 from '../../../../ui/Text/TextH1'
import { Box, Grid } from '@mui/material'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { adminRequest } from '../../../../utils/request'
import { API_FINANCE_ADMIN_ENDPOINTS } from '../../../../config/default'
import { toast } from 'react-toastify'
import { setCurrentPageTitle } from '../../../../store/features/authSlice'

const AddProductType = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [productTypeName, setProductTypeName] = useState('')
  const [productTypeCode, setProductTypeCode] = useState('')

  const hasErrors = !productTypeName || !productTypeCode

  const handleAddProductType = () => {
    const formData = {
      title: productTypeName,
      code: productTypeCode,
    }
    adminRequest
      .post(API_FINANCE_ADMIN_ENDPOINTS.productTypes, formData)
      .then((response) => {
        toast.success('Added product type successfully', { autoClose: 1000 })
        navigate('/admin/financing/product-types')
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data['hydra:description']) {
          toast.error(error.response.data['hydra:description'])
        } else toast.error('Unexpected error')
      })
  }

  useEffect(() => {
    dispatch(setCurrentPageTitle('Add a new product type'))
  }, [])

  return (
    <Box>
      <BreadcrumbsComponent
        currentPage={{ name: `Add a new product type` }}
        parentPages={[
          {
            name: 'Product types',
            icon: (
              <img
                src={IconProductType}
                style={{ filter: 'brightness(73%)', width: '13px' }}
                alt=""
              />
            ),
            link: '/admin/financing/product-types',
          },
        ]}
      />
      <TextH1
        sx={{
          fontSize: '36px',
          mb: '30px',
          mt: '10px',
        }}
      >
        Add a new product type
      </TextH1>
      <Grid container spacing={'20px'}>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Product type name'}
            value={productTypeName}
            onChange={(e) => {
              setProductTypeName(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Product type code'}
            value={productTypeCode}
            onChange={(e) => {
              setProductTypeCode(e.target.value)
            }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: '40px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <ButtonLG
          sx={{
            minWidth: '187px',
          }}
          disabled={hasErrors}
          onClick={() => {
            handleAddProductType()
          }}
        >
          Save
        </ButtonLG>
      </Box>
    </Box>
  )
}

export default AddProductType
