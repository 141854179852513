import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { adminRequest, publicRequest } from '../../utils/request'
import { API_FINANCE_ADMIN_ENDPOINTS, API_FINANCE_ENDPOINTS } from '../../config/default'
import { colorsConfig } from '../../config/themeConfig'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import TextNormal from '../../ui/Text/TextNormal'
import CustomerBankResponsesItem from './CustomerBankResponsesItem'
import LayoutPublicMini from '../../components/Layout/LayoutPublicMini'

const CustomerBankResponses = () => {
  const params = useParams()

  const [hasCustomerSelectedOffer, setHasCustomerSelectedOffer] = useState(false)
  const [loanDeals, setLoanDeals] = useState([])
  const [banks, setBanks] = useState([])
  const [loanApplication, setLoanApplication] = useState(null)

  const getBanks = () => {
    adminRequest
      .get(`${API_FINANCE_ADMIN_ENDPOINTS.productProviders}?pagination=false&type=bank`)
      .then((response) => {
        setBanks(response.data['hydra:member'])
      })
  }

  const getLoanApplication = () => {
    publicRequest
      .get(API_FINANCE_ENDPOINTS.loanApplicationForCustomer(params.id))
      .then((response) => {
        setLoanApplication(response.data)
        const loanDeals = response.data.loanDeals
        if (loanDeals.find((item) => item.isCustomerSelected)) {
          setHasCustomerSelectedOffer(true)
          setLoanDeals(loanDeals.sort((a, b) => b.isCustomerSelected - a.isCustomerSelected))
        }
        setLoanDeals(response.data.loanDeals)
      })
  }

  useEffect(() => {
    getLoanApplication()
    getBanks()
  }, [])

  return (
    <LayoutPublicMini>
      <Box
        sx={{
          overflow: 'hidden',
          position: 'relative',
          background: colorsConfig.bgLightBlue,
          mt: '30px',
          '&.disabled': {
            pointerEvents: 'none',
            opacity: 0.5,
            transition: '0.25s all ease-in-out',
          },
        }}
      >
        <Box
          sx={{
            height: '79px',
            py: '17px',
            px: '29px',
            border: '1px solid #DCDCDC',
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
            zIndex: 2,
          }}
        >
          <Box>
            <TextBoldNormal
              sx={{
                color: colorsConfig.mainBlue,
                fontSize: '18px',
              }}
            >
              Bank responses{' '}
              <span style={{ color: colorsConfig.mainGreen }}>({loanDeals.length})</span>
            </TextBoldNormal>
            <TextNormal>Choose the best option for you</TextNormal>
          </Box>
          <Box></Box>
        </Box>
        <Box
          sx={{
            paddingTop: '119px',
            mt: '-79px',
            position: 'relative',
            px: '29px',
            pb: '35px',
            borderRadius: '6px',
            border: '1px solid',
            borderColor: colorsConfig.buttonDisabled,
            background: colorsConfig.bgLightBlue,
            transition: '0.3s all ease-in-out',
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            '&.collapsed': {
              mt: '-70%',
              display: 'none',
              opacity: '0',
              pointerEvents: 'none',
              transition: '0.3s all ease-in-out',
            },
          }}
        >
          {loanDeals.map((item, index) => {
            return (
              <CustomerBankResponsesItem
                key={index}
                index={index}
                loanDeal={item}
                loanApplication={loanApplication}
                banks={banks}
                hasCustomerSelectedOffer={hasCustomerSelectedOffer}
              />
            )
          })}
        </Box>
      </Box>
    </LayoutPublicMini>
  )
}

export default CustomerBankResponses
