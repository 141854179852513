import React, { useState } from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { colorsConfig } from '../config/themeConfig'
import iconFilter from '../assests/img/icons/icon-filter.svg'
import { Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

const FilterByStatus = ({ filters, handleFilter, currentFilter }) => {
  const { t } = useTranslation()

  const [showFilters, setShowFilters] = useState(false)

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Button
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          px: '17px',
          py: '11.2px',
          border: '1px solid',
          borderColor: colorsConfig.mainBlack,
          textTransform: 'none',
        }}
        onClick={() => {
          setShowFilters(!showFilters)
        }}
      >
        <Box
          sx={{
            width: '18px',
            height: '18px',
          }}
        >
          <img src={iconFilter} alt="" />
        </Box>
        <Typography
          sx={{
            fontWeight: 700,
            color: colorsConfig.mainBlack,
            fontSize: '16px',
            marginLeft: '10px',
            whiteSpace: 'nowrap',
          }}
        >
          {t('form_input_label_filter_statuses')}
        </Typography>
      </Button>
      <Box
        className={showFilters ? 'active' : ''}
        sx={{
          position: 'absolute',
          width: 'auto',
          maxHeight: '400px',
          overflow: 'auto',
          background: '#F7FAFB',
          top: 'calc(100% + 2px)',
          right: 0,
          paddingTop: '33px',
          zIndex: 20,
          opacity: 0,
          transform: 'scale(0.5)',
          transformOrigin: '100% 0%',
          pointerEvents: 'none',
          borderRadius: '4px',
          transition: '0.25s all ease-in-out',
          p: '4px',
          '&.active': {
            opacity: 1,
            transform: 'scale(1)',
            pointerEvents: 'auto',
            transition: '0.3s all ease-in-out',
            boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
          },
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 10,
          }}
          onClick={() => {
            setShowFilters(false)
          }}
        >
          <Close
            sx={{
              color: colorsConfig.mainRed,
            }}
          />
        </IconButton>
        <Button
          className={currentFilter === 'all' ? 'active' : ''}
          sx={{
            background: '#F7FAFB',
            width: '100%',
            color: colorsConfig.mainBlack,
            textTransform: 'none',
            textAlign: 'left',
            justifyContent: 'flex-start',
            fontWeight: 400,
            paddingLeft: '30px',
            paddingRight: '30px',
            whiteSpace: 'nowrap',
            '&:hover': {
              background: 'white',
            },
            '&.active': {
              background: 'white',
            },
          }}
          onClick={() => {
            setShowFilters(false)
            handleFilter(null)
          }}
        >
          <Typography
            sx={{
              color: colorsConfig.mainBlack,
              textTransform: 'none',
              whiteSpace: 'nowrap',
            }}
          >
            {t('common_all')}
          </Typography>
        </Button>

        {filters.map((item, index) => {
          return (
            <Button
              key={index}
              className={currentFilter === item ? 'active' : ''}
              sx={{
                background: '#F7FAFB',
                width: '100%',
                color: colorsConfig.mainBlack,
                textTransform: 'none',
                textAlign: 'left',
                justifyContent: 'flex-start',
                fontWeight: 400,
                paddingLeft: '30px',
                paddingRight: '30px',
                whiteSpace: 'nowrap',
                '&:hover': {
                  background: 'white',
                },
                '&.active': {
                  background: 'white',
                },
              }}
              onClick={() => {
                setShowFilters(false)
                handleFilter(item)
              }}
            >
              <Typography
                sx={{
                  color: colorsConfig.mainBlack,
                  textTransform: 'none',
                }}
              >
                {t(item)}
              </Typography>
            </Button>
          )
        })}
      </Box>
    </Box>
  )
}

export default FilterByStatus
