import React from 'react'
import { Box } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import TextNormal from '../../../ui/Text/TextNormal'

const OperatorMarkers = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          mr: '24px',
        }}
      >
        <Box
          sx={{
            width: '18px',
            minWidth: '18px',
            height: '18px',
            borderRadius: '50%',
            background: colorsConfig.mainGreen,
            mr: '6px',
          }}
        />
        <TextNormal
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          —New request
        </TextNormal>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          mr: '24px',
        }}
      >
        <Box
          sx={{
            width: '18px',
            minWidth: '18px',
            height: '18px',
            borderRadius: '50%',
            background: colorsConfig.buttonYellow,
            mr: '6px',
          }}
        />
        <TextNormal
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          —Approaching the deadline
        </TextNormal>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '18px',
            minWidth: '18px',
            height: '18px',
            borderRadius: '50%',
            background: colorsConfig.mainRed,
            mr: '6px',
          }}
        />
        <TextNormal
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          —Action required
        </TextNormal>
      </Box>
    </Box>
  )
}

export default OperatorMarkers
