import React, { useState } from 'react'
import { Item } from 'react-photoswipe-gallery'
import { CDN_BASE_URL } from '../../../config/default'
import { Box, IconButton, useMediaQuery } from '@mui/material'
import { CancelRounded } from '@mui/icons-material'
import { colorsConfig } from '../../../config/themeConfig'

const DamagePhoto = (props) => {
  const { damagePhoto, newDamage, editing, handleDeleteDamagePhoto } = props

  const xsOnly = useMediaQuery('(max-width: 600px)')

  const [imgWidth, setImgWidth] = useState(0)
  const [imgHeight, setImgHeight] = useState(0)

  return (
    <Box
      className={xsOnly ? 'xs-only' : ''}
      sx={{
        marginRight: '7px',
        width: '92px',
        height: '52px',
        mb: '3.5px',
        mt: '3.5px',
        borderRadius: '6px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        '&.xs-only': {
          width: 'calc(33% - 6px)',
          height: 'auto',
          mr: 0,
          mb: 0,
          mt: 0,
        },
      }}
    >
      <Item
        original={
          newDamage && damagePhoto
            ? URL.createObjectURL(damagePhoto)
            : `${CDN_BASE_URL}${damagePhoto.photo.path}`
        }
        thumbnail={
          newDamage && damagePhoto
            ? URL.createObjectURL(damagePhoto)
            : `${CDN_BASE_URL}${damagePhoto.photo.path}`
        }
        width={imgWidth}
        height={imgHeight}
      >
        {({ ref, open }) => (
          <img
            style={{
              width: '100%',
              borderRadius: '6px',
            }}
            ref={ref}
            onClick={open}
            src={
              newDamage && damagePhoto
                ? URL.createObjectURL(damagePhoto)
                : `${CDN_BASE_URL}${damagePhoto.photo.path}`
            }
            onLoad={() => {
              const img = new Image()
              img.src =
                newDamage && damagePhoto
                  ? URL.createObjectURL(damagePhoto)
                  : `${CDN_BASE_URL}${damagePhoto.photo.path}`
              img.onload = () => {
                setImgHeight(img.height)
                setImgWidth(img.width)
              }
            }}
          />
        )}
      </Item>
      {editing ? (
        <IconButton
          sx={{
            position: 'absolute',
            top: '0px',
            right: '0px',
            width: '17px',
            height: '17px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: '1',
            transition: '0.25s all ease-in-out',
            '&:hover': {
              transition: '0.25s all ease-in-out',
              opacity: '1',
            },
          }}
          color="error"
          onClick={() => {
            handleDeleteDamagePhoto(damagePhoto.id)
          }}
        >
          <Box
            sx={{
              width: '13px',
              height: '13px',
              borderRadius: '50%',
              background: 'white',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CancelRounded
              sx={{
                fill: colorsConfig.mainRed,
                fontSize: '18px',
              }}
            />
          </Box>
        </IconButton>
      ) : null}
    </Box>
  )
}

export default DamagePhoto
