export const getMarkerColor = (createdAt, fill, stroke, isResponsive, mediaQuery) => {
  const empty = !fill || !stroke

  const createdAtTime = new Date(createdAt).getTime()
  const currentTime = new Date().getTime()
  const timeDifferenceInHours = (currentTime - createdAtTime) / 3600000

  if (empty) {
    if (timeDifferenceInHours > 24) {
      fill = '#AC0000'
      stroke = '#B83629' // Red color
    } else if (timeDifferenceInHours > 20) {
      fill = '#F5BD4F'
      stroke = '#D69E3C' // Yellow color
    } else {
      fill = '#4DED6D'
      stroke = '#3CC457' // Green color
    }
  }

  if (isResponsive) {
    return mediaQuery ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
      >
        <circle cx="8" cy="7.11719" r="7" fill={fill} stroke={stroke} stroke-width="0.25" />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <circle cx="10" cy="10" r="9" fill={fill} stroke={stroke} strokeWidth="0.25" />
      </svg>
    )
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <circle cx="10" cy="10" r="9" fill={fill} stroke={stroke} strokeWidth="0.25" />
      </svg>
    )
  }
}
