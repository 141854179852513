import React, { useEffect, useState } from 'react'
import { Box, Table } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { handleFormatDate } from '../../../../utils/formatDate'
import { privateRequest } from '../../../../utils/request'
import { API_ENDPOINTS } from '../../../../config/default'
import { setCurrentPageTitle } from '../../../../store/features/authSlice'
import WrapWithPreloader from '../../../../components/WrapWithPreloader'
import ButtonPlain from '../../../../ui/Buttons/ButtonPlain'
import { AddRounded } from '@mui/icons-material'
import { colorsConfig } from '../../../../config/themeConfig'
import BoxShadowed from '../../../../ui/Layout/BoxShadowed'
import CarTableHead from '../../../VirtualGarage/CarTableHead'
import CarTableBody from '../../../VirtualGarage/CarTableBody'
import TextNormal from '../../../../ui/Text/TextNormal'
import SimplePagination from '../../../../ui/SimplePagination'
import { tableHeadInsuranceCompanies } from '../adminInsurance.constants'

const AdminPanelInsuranceCompanies = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [count, setCount] = useState(0)

  const handleSetCompanies = (data) => {
    setCompanies(
      data.map((item) => {
        return {
          id: item.id,
          link: `/admin/insurance/companies/${item.id}/edit`,
          cells: [
            {
              type: 'text',
              value: `#${item.id}`,
            },
            {
              type: 'text',
              value: item?.name,
            },
            {
              type: 'text',
              value: item?.email,
            },
            {
              type: 'text',
              value: handleFormatDate(item?.updatedAt, '.'),
            },
          ],
        }
      }),
    )
  }

  const getCompanies = () => {
    setLoading(true)
    privateRequest
      .get(API_ENDPOINTS.insurance.companies, {
        params: {
          pagination: 'false',
        },
      })
      .then((response) => {
        handleSetCompanies(response.data['hydra:member'])
        setCount(response.data['hydra:totalItems'])
        setLoading(false)
      })
  }

  useEffect(() => {
    dispatch(setCurrentPageTitle('Insurance companies'))
    getCompanies()
  }, [])

  return (
    <Box>
      <WrapWithPreloader loadingText={'Loading companies...'} loading={loading}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            mb: '20px',
          }}
        >
          <ButtonPlain
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => {
              navigate('/admin/insurance/companies/add')
            }}
          >
            <AddRounded
              sx={{
                fill: colorsConfig.mainBlue,
                mr: '4px',
                fontSize: '21px',
              }}
            />
            Add company
          </ButtonPlain>
        </Box>
        <BoxShadowed>
          {companies.length > 0 ? (
            <Table>
              <CarTableHead tableCells={tableHeadInsuranceCompanies} />
              <CarTableBody content={companies} />
            </Table>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <TextNormal
                sx={{
                  color: colorsConfig.lightGray,
                }}
              >
                No companies have been added yet.
              </TextNormal>
            </Box>
          )}
        </BoxShadowed>
        {count !== 0 ? (
          <SimplePagination
            count={count}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        ) : null}
      </WrapWithPreloader>
    </Box>
  )
}

export default AdminPanelInsuranceCompanies
