import React, { useEffect } from 'react'
import { Table, Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import CarTableHead from '../../VirtualGarage/CarTableHead'
import CarTableBody from '../../VirtualGarage/CarTableBody'
import { AddRounded } from '@mui/icons-material'
import { colorsConfig } from '../../../config/themeConfig'
import ButtonPlain from '../../../ui/Buttons/ButtonPlain'
import { getWarrantyProducts } from '../../../store/features/warrantyProductsSlice/warrantyProducts.actions'
import { warrantyProductsTableHeadCells } from '../../Warranty/warranty.constants'
import { getWarrantyProductsTableData } from '../../Warranty/warranty.helpers'
import UITablePreloader from '../../../ui/UIPreloader/UITablePreloader'

const WarrantyProducts = () => {
  const isLoading = useSelector((state) => state.warrantyProducts.isLoading)
  const warrantyProducts = useSelector((state) => state.warrantyProducts.warrantyProducts)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getWarrantyProducts())
    dispatch(setCurrentPageTitle('Warranty products'))
  }, [])

  return (
    <BoxShadowed>
      <UITablePreloader loading={isLoading}>
        <Table>
          <CarTableHead tableCells={warrantyProductsTableHeadCells} />
          <CarTableBody content={getWarrantyProductsTableData(warrantyProducts)} />
        </Table>
      </UITablePreloader>
    </BoxShadowed>
  )
}

export default WarrantyProducts
