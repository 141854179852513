import React from 'react'
import { Grid } from '@mui/material'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import { useSelector } from 'react-redux'
import { getCarEnumOptionsList } from '../../../utils/helpers/optionsList.helpers'
import { CAR_ENUMS } from '../../../utils/constants/enums.constants'
import UIFormInputSelect from '../../../ui/UIFormComponents/UIFormInputSelect/UIFormInputSelect'
import UIInputAdornmentText from '../../../ui/UIFormComponents/UIInputAdornmentText'
import { isEngineTypeElectric } from '../../../utils/helpers/common.helpers'
import { cylindersOptions, gearsNumberOptions } from '../addNewVehicleForm.helpers'

const CarOptionalFields = ({ formData, setFormData }) => {
  const enumDetails = useSelector((state) => state.ref.enumDetails.data)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4}>
        <TextFieldSmallEdible
          id="car-reference-number"
          fullWidth
          label="form_input_label_reference_number"
          value={formData.referenceNumber}
          inputProps={{
            inputMode: 'numeric',
          }}
          onChange={(e) => {
            setFormData({
              ...formData,
              referenceNumber: e.target.value,
            })
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextFieldSmallEdible
          fullWidth
          label="form_input_label_model_details"
          value={formData.details}
          onChange={(e) => {
            setFormData({
              ...formData,
              details: e.target.value,
            })
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <UIFormInputSelect
          label="form_input_label_car_type"
          value={formData.type ?? ''}
          onChange={(e) => {
            setFormData({
              ...formData,
              type: e.target.value,
            })
          }}
          options={getCarEnumOptionsList(CAR_ENUMS.CAR_TYPE, enumDetails)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextFieldSmallEdible
          fullWidth
          label="form_input_label_weight"
          type="text"
          id="car-weight"
          value={formData.weight}
          inputProps={{
            inputMode: 'numeric',
          }}
          onChange={(e) => {
            setFormData({
              ...formData,
              weight: e.target.value.replace(/[^0-9]/g, ''),
            })
          }}
          InputProps={{
            endAdornment: <UIInputAdornmentText text="kg" />,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextFieldSmallEdible
          fullWidth
          id="horse-power"
          label="form_input_label_hp"
          type="text"
          inputProps={{
            inputMode: 'numeric',
          }}
          value={formData.hp}
          onChange={(e) => {
            if (e.target.value.length > 4) {
              e.preventDefault()
            } else {
              setFormData({
                ...formData,
                hp: e.target.value.replace(/[^0-9]/g, ''),
              })
            }
          }}
        />
      </Grid>
      {!isEngineTypeElectric(formData.engineType) ? (
        <Grid item xs={12} sm={6} md={4}>
          <UIFormInputSelect
            label="form_input_label_cylinder"
            value={formData.cylinders ?? ''}
            onChange={(e) => {
              setFormData({
                ...formData,
                cylinders: e.target.value,
              })
            }}
            options={cylindersOptions}
          />
        </Grid>
      ) : null}
      <Grid item xs={12} sm={6} md={4}>
        <TextFieldSmallEdible
          fullWidth
          label="form_input_label_engine_number"
          value={formData.engineNumber}
          inputProps={{
            inputMode: 'numeric',
          }}
          onChange={(e) => {
            setFormData({
              ...formData,
              engineNumber: e.target.value,
            })
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <UIFormInputSelect
          label="form_input_label_gears_number"
          value={formData.gearsNumber ?? ''}
          onChange={(e) => {
            setFormData({
              ...formData,
              gearsNumber: e.target.value,
            })
          }}
          options={gearsNumberOptions}
          disabled={isEngineTypeElectric(formData.engineType)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <UIFormInputSelect
          label="form_input_label_rims"
          value={formData.rims ?? ''}
          onChange={(e) => {
            setFormData({
              ...formData,
              rims: e.target.value,
            })
          }}
          options={getCarEnumOptionsList(CAR_ENUMS.CAR_RIMS, enumDetails)}
        />
      </Grid>
    </Grid>
  )
}

export default CarOptionalFields
