import { createEntry } from '../residualValueMatrixEntries.actions'

export default (builder) => {
  builder
    .addCase(createEntry.pending, (state) => {
      state.create.isLoading = true
    })
    .addCase(createEntry.fulfilled, (state, action) => {
      state.create.isLoading = false
      state.create.error = null
      const data = state.data
      data.push(action.payload)
      state.data = data?.sort((a, b) => a.term - b.term)
    })
    .addCase(createEntry.rejected, (state, action) => {
      state.create.isLoading = false
      state.create.error = action.payload
    })
}
