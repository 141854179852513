import React from 'react'
import { Button, styled } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const ButtonPlain = ({ children, sx, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: '"Helvetica", sans-serif',
        lineHeight: 'normal',
        textTransform: 'none',
        color: colorsConfig.mainBlue,
        transition: '0.25s all ease-in-out',
        '&:hover': {
          transition: '0.25s all ease-in-out',
          color: colorsConfig.buttonHover,
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  )
}

export default ButtonPlain
