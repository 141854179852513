import { getClosedPolicies } from '../rviDealer.actions'
import { apiConstants } from '../../../../api/api'

export default (builder) => {
  builder
    .addCase(getClosedPolicies.pending, (state) => {
      state.closedPolicies.isLoading = true
    })
    .addCase(getClosedPolicies.fulfilled, (state, action) => {
      state.closedPolicies.isLoading = false
      state.closedPolicies.data = action.payload?.[apiConstants.hydraMember]
      state.closedPolicies.total = action.payload?.[apiConstants.hydraTotal]
    })
    .addCase(getClosedPolicies.rejected, (state, action) => {
      if (action.payload?.code !== 'ERR_CANCELED') {
        state.closedPolicies.isLoading = false
      }
    })
}
