import { attachAppraisalDocument } from '../rviCar.actions'

export default (builder) => {
  builder
    .addCase(attachAppraisalDocument.pending, (state) => {
      //
    })
    .addCase(attachAppraisalDocument.fulfilled, (state, action) => {
      state.createdCarData = action.payload
    })
    .addCase(attachAppraisalDocument.rejected, (state, action) => {
      //
    })
}
