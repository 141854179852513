import { sendAppraisalLinkByEmail } from '../customerAppraisal.actions'

export default (builder) => {
  builder
    .addCase(sendAppraisalLinkByEmail.pending, (state) => {
      state.sendEmail.isLoading = true
    })
    .addCase(sendAppraisalLinkByEmail.fulfilled, (state, action) => {
      state.sendEmail.isLoading = false
      state.sendEmail.error = null
    })
    .addCase(sendAppraisalLinkByEmail.rejected, (state, action) => {
      state.sendEmail.isLoading = false
      state.sendEmail.error = action.payload
    })
}
