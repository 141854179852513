import { getCoveredComponents } from '../coveredComponents.actions'

export default (builder) => {
  builder
    .addCase(getCoveredComponents.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getCoveredComponents.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = null
      state.coveredComponents = action.payload?.['hydra:member']
    })
    .addCase(getCoveredComponents.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
}
