import React from 'react'
import { Box, Pagination, TablePagination, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../config/themeConfig'

const CustomTablePagination = (props) => {
  const { page, setPage, rowsPerPage, setRowsPerPage, amount } = props

  const xsOnly = useMediaQuery('(max-width:600px)')

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  return (
    <Box
      sx={{
        paddingLeft: xsOnly && '16px',
        paddingRight: xsOnly && '13px',
        '& .MuiToolbar-root': {
          paddingLeft: 0,
          display: 'flex',
          flexWrap: xsOnly ? 'wrap' : '',
          gap: xsOnly ? '10px 0' : '',
        },
        '& .MuiTablePagination-spacer': {
          display: 'none',
        },
        '& .MuiTablePagination-displayedRows': {
          display: xsOnly ? 'none' : 'flex',
          flexGrow: 1,
          justifyContent: 'flex-end',
          fontSize: '12px',
        },
        '& .MuiInputBase-root': {
          order: -1,
          margin: 'auto 10px',
          fontSize: '12px',
          '& .MuiSvgIcon-root': {
            fontSize: '16px',
            marginRight: '10px',
          },
          background: 'white',
          border: `1px solid ${colorsConfig.bgLightBlue}`,
          borderRadius: '4px',
          padding: '7px 15px 7px 13px',
        },
        '& .MuiPagination-root': {
          marginLeft: xsOnly ? 'auto' : '12px',
        },
        '& .MuiPaginationItem-previousNext': {
          padding: '8px 12px',
          '& .MuiSvgIcon-root': {
            display: 'none',
          },
          "&[aria-label='Go to previous page']": {
            '&::before': {
              content: "'Previous'",
            },
          },
          "&[aria-label='Go to next page']": {
            '&::before': {
              content: "'Next'",
            },
          },
          '&.Mui-disabled': {
            opacity: 1,
          },
        },
        '& .MuiPaginationItem-root': {
          background: colorsConfig.bgLightBlue,
          border: 'none',
          fontSize: '12px',

          '&.MuiPaginationItem-previousNext': {
            display: xsOnly && 'none',
            background: 'white',
            border: `1px solid ${colorsConfig.bgLightBlue}`,
          },
        },
        '& .MuiPaginationItem-page.Mui-selected': {
          background: colorsConfig.mainBlue,
          color: 'white',
          '&:hover': {
            background: 'rgb(21, 101, 192)',
          },
        },
        '& .MuiTablePagination-selectLabel': {
          fontSize: '12px',
        },
        '& .MuiSelect-select': {
          padding: 0,
          paddingRight: '12px',
          '&:focus': {
            background: 'none',
          },
        },
      }}
    >
      <TablePagination
        component="div"
        count={amount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton={false}
        showLastButton={false}
        ActionsComponent={() => (
          <>
            <p
              className="MuiTablePagination-selectLabel css-pdct74-MuiTablePagination-selectLabel"
              id=":rh:"
              style={{
                display: 'flex',
                order: -2,
              }}
            >
              Show
            </p>
            <Pagination
              count={Math.ceil(amount / rowsPerPage)}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={(event, newPage) => handleChangePage(event, newPage)}
            />
          </>
        )}
        labelRowsPerPage="entries"
        labelDisplayedRows={({ count }) => {
          const from = page * rowsPerPage + 1
          const to = Math.min((page + 1) * rowsPerPage, amount)
          if (!count) return `Show 0 entries`
          return `Show ${from}-${to} of ${count} entries`
        }}
      />
    </Box>
  )
}

export default CustomTablePagination
