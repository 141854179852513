import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import { getEntries } from '../../../store/features/residualValueMatrixEntries/residualValueMatrixEntries.actions'
import { Box, Stack } from '@mui/material'
import RVMatrixWrapper from './RVMatrixWrapper'
import RVMatrixHead from './RVMatrixHead'
import RVMatrixEntry from './RVMatrixEntry'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import UIIconButton from '../../../ui/UIButtons/UIIconButton'
import { AddRounded } from '@mui/icons-material'
import AddRVEntryModal from '../../../components/Modals/AddRVEntryModal/AddRVEntryModal'

const RVMatrix = () => {
  const entries = useSelector((state) => state.residualValueMatrixEntries.data)
  const dispatch = useDispatch()

  const [addRVEntryModalOpen, setAddRVEntryModalOpen] = useState(false)

  useEffect(() => {
    dispatch(getEntries())
    dispatch(setCurrentPageTitle('RV Matrix'))
  }, [])

  return (
    <BoxShadowed sx={{ p: '20px' }}>
      <Stack gap="30px">
        <Stack direction="row" justifyContent="flex-end">
          <UIIconButton text="Add a new row" onClick={() => setAddRVEntryModalOpen(true)}>
            <AddRounded />
          </UIIconButton>
        </Stack>
        <RVMatrixWrapper>
          <RVMatrixHead />
          <Box
            className="disable-scrollbar"
            sx={{
              maxHeight: '450px',
              overflowY: 'auto',
            }}
          >
            {entries.map((item) => {
              return <RVMatrixEntry key={item?.id} initialEntry={item} />
            })}
          </Box>
        </RVMatrixWrapper>
      </Stack>
      {addRVEntryModalOpen && (
        <AddRVEntryModal open={addRVEntryModalOpen} setOpen={setAddRVEntryModalOpen} />
      )}
    </BoxShadowed>
  )
}

export default RVMatrix
