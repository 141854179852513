import { submitCustomerAppraisal } from '../customerAppraisal.actions'

export default (builder) => {
  builder
    .addCase(submitCustomerAppraisal.pending, (state) => {
      state.submitAppraisal.isLoading = true
    })
    .addCase(submitCustomerAppraisal.fulfilled, (state, action) => {
      state.submitAppraisal.isLoading = false
      state.submitAppraisal.error = null
      state.submitAppraisal.success = true
    })
    .addCase(submitCustomerAppraisal.rejected, (state, action) => {
      state.submitAppraisal.isLoading = false
      state.submitAppraisal.error = action.payload
    })
}
