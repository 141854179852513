import IconFlagEN from '../assests/img/flags/GB.svg'
import IconFlagDE from '../assests/img/flags/DE.svg'
import IconFlagFR from '../assests/img/flags/FR.svg'
import IconFlagIT from '../assests/img/flags/IT.svg'
import IconFlagENSquare from '../assests/img/flags/GB-square.svg'
import IconFlagDESquare from '../assests/img/flags/DE-square.svg'
import IconFlagFRSquare from '../assests/img/flags/FR-square.svg'
import IconFlagITSquare from '../assests/img/flags/IT-square.svg'

export const localesConfig = [
  {
    code: 'en',
    name: 'English',
    flagIcon: IconFlagEN,
    flagIconSquare: IconFlagENSquare,
  },
  {
    code: 'de',
    name: 'German',
    flagIcon: IconFlagDE,
    flagIconSquare: IconFlagDESquare,
  },
  {
    code: 'fr',
    name: 'French',
    flagIcon: IconFlagFR,
    flagIconSquare: IconFlagFRSquare,
  },
  {
    code: 'it',
    name: 'Italian',
    flagIcon: IconFlagIT,
    flagIconSquare: IconFlagITSquare,
  },
]
