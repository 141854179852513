import React, { useEffect } from 'react'
import UITableControls from '../../../ui/UITable/UITableControls'
import UIPaper from '../../../ui/UIPaper/UIPaper'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Stack } from '@mui/material'
import UIPreloader from '../../../ui/UIPreloader/UIPreloader'
import UITable from '../../../ui/UITable/UITable'
import { getCustomerAppraisals } from '../../../store/features/customerAppraisals/customerAppraisals.actions'
import { customerAppraisalsTableHead } from '../../../utils/constants/tables/table.constants'
import { getCustomerAppraisalsTableData } from '../../../utils/helpers/tables/table.helpers'
import UITablePagination from '../../../ui/UITable/UITablePagination'
import { setCustomerAppraisalsParams } from '../../../store/features/customerAppraisals/customerAppraisals.reducer'
import CustomerAppraisalsActionItems from './CustomerAppraisalsActionItems'
import UITableMobileWrapper from '../../../ui/UITable/UITableMobileWrapper'

const CustomerAppraisals = () => {
  const isLoading = useSelector((state) => state.customerAppraisals.isLoading)
  const customerAppraisals = useSelector((state) => state.customerAppraisals.data)
  const params = useSelector((state) => state.customerAppraisals.params)
  const total = useSelector((state) => state.customerAppraisals.total)

  const carWorkflows = useSelector((state) => state.workflows.carWorkflowStates)
  const auctionWorkflows = useSelector((state) => state.workflows.auctionWorkflowStates)
  const dealWorkflows = useSelector((state) => state.workflows.dealWorkflowStates)

  const workflows = {
    carWorkflowStates: carWorkflows,
    auctionWorkflowStates: auctionWorkflows,
    dealWorkflowStates: dealWorkflows,
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCustomerAppraisals())
  }, [params])

  return (
    <UIPaper reduceOnMobile sx={{ mt: { xs: '8px', md: '40px' } }}>
      <Stack gap="30px">
        <UITableControls
          searchValue={params.customerAppraisalExtendedSearch}
          setSearchValue={(val) =>
            dispatch(
              setCustomerAppraisalsParams({
                ...params,
                page: 1,
                customerAppraisalExtendedSearch: val,
              }),
            )
          }
        />
        <Box sx={{ position: 'relative' }}>
          {isLoading && <UIPreloader text="preloader_loading_customer_appraisals" />}
          <UITableMobileWrapper>
            <UITable
              minMobileWidth="1200px"
              head={customerAppraisalsTableHead}
              content={getCustomerAppraisalsTableData(customerAppraisals, workflows)}
              emptyText="table_empty_message_customer_appraisals"
              renderActionItems={(data) => <CustomerAppraisalsActionItems data={data} />}
              sort={params.sort}
              setSort={(value) =>
                dispatch(
                  setCustomerAppraisalsParams({
                    ...params,
                    page: 1,
                    sort: value,
                  }),
                )
              }
            />
          </UITableMobileWrapper>
          <UITablePagination
            count={total}
            page={params?.page}
            onPageChange={(e, value) =>
              dispatch(
                setCustomerAppraisalsParams({
                  ...params,
                  page: value,
                }),
              )
            }
            rowsPerPage={params?.itemsPerPage}
            onRowsPerPageChange={(e) =>
              dispatch(
                setCustomerAppraisalsParams({
                  ...params,
                  page: 1,
                  itemsPerPage: e.target.value,
                }),
              )
            }
            sx={{
              px: {
                xs: '16px',
                md: 0,
              },
            }}
          />
        </Box>
      </Stack>
    </UIPaper>
  )
}

export default CustomerAppraisals
