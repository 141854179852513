import { calculateWarrantyProducts } from '../warrantyApplication.actions'

export default (builder) => {
  builder
    .addCase(calculateWarrantyProducts.pending, (state) => {
      state.calculation.isLoading = true
    })
    .addCase(calculateWarrantyProducts.fulfilled, (state, action) => {
      state.calculation.isLoading = false
      state.calculation.error = null
      state.calculation.calculatedProducts = action.payload?.calculatedProducts
    })
    .addCase(calculateWarrantyProducts.rejected, (state, action) => {
      state.calculation.error = action.payload
      state.calculation.isLoading = false
    })
}
