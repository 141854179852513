import React from 'react'
import { Button } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const ButtonAttention = ({ children, sx, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        textTransform: 'none',
        height: '48px',
        borderRadius: '4px',
        paddingLeft: {
          xl: '60px',
          md: '30px',
        },
        paddingRight: {
          xl: '60px',
          md: '30px',
        },
        color: colorsConfig.mainBlack,
        background: colorsConfig.buttonYellow,
        fontSize: '16px',
        fontWeight: 700,
        whiteSpace: 'nowrap',
        transition: '0.25s all ease-in-out',
        '&:hover': {
          background: colorsConfig.buttonYellow,
          opacity: '0.7',
          transition: '0.25s all ease-in-out',
        },
        '&.Mui-disabled': {
          background: colorsConfig.buttonDisabled,
          color: colorsConfig.white,
          transition: '0.25s all ease-in-out',
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  )
}

export default ButtonAttention
