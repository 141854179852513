import React from 'react'
import { Button } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import IconCheckmark from '../../assests/img/icons/icon-checkmark-black.svg'

const ButtonSmall = ({ children, sx, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        height: '48px',
        borderRadius: '4px',
        paddingLeft: '15px',
        paddingRight: '15px',
        background: colorsConfig.mainBlue,
        color: 'white',
        textTransform: 'none',
        fontWeight: 700,
        whiteSpace: 'nowrap',
        fontSize: '16px',
        transition: '0.25s all ease-in-out',
        '&:hover': {
          background: colorsConfig.buttonHover,
          transition: '0.25s all ease-in-out',
        },
        '&.Mui-disabled': {
          background: colorsConfig.buttonDisabled,
        },
        '&.success': {
          background: colorsConfig.mainGreen,
          color: colorsConfig.mainBlack,
          paddingLeft: '23.5px',
          paddingRight: '8px',
          pointerEvents: 'none',
          '&::after': {
            content: '" "',
            display: 'block',
            position: 'absolute',
            width: '12px',
            height: '11px',
            backgroundImage: `url(${IconCheckmark})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% auto',
            top: '50%',
            marginTop: '-5.5px',
            left: '8.5px',
          },
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  )
}

export default ButtonSmall
