import {
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import AppraisalIntro from '../../../Appraisal/Intro'
import AppraisalDocuments from '../../../Appraisal/AppraisalDocuments/AppraisalDocuments'
import CarPhotos from '../../../Appraisal/AppraisalPhotos/CarPhotos'
import AppraisalDamages from '../../../Appraisal/AppraisalDamages'
import WarningLights from '../../../Appraisal/WarningLights/WarningLights'
import AppraisalInteriorConditions from '../../../Appraisal/AppraisalInteriorConditions/AppraisalInteriorConditions'
import AppraisalMechanicalConditions from '../../../Appraisal/AppraisalMechanicalConditions/AppraisalMechanicalConditions'
import { privateMultipartRequest, privateRequest } from '../../../../utils/request'
import {
  API_ENDPOINTS,
  CDN_BASE_URL,
  FILE_TYPES,
  HINT_TYPES,
  MERCURE_BASE_URL,
} from '../../../../config/default'
import {
  setAppraisal,
  setAppraisalTab,
  setCarAppraisalChecksResource,
  setCarData,
  setDamages,
  setDeletedDamages,
  setDocuments,
  setInteriorConditions,
  setMechanicalConditions,
  setPhotos,
  setPilotLamps,
  setWorkflowState,
} from '../../../../store/features/carSlice/carSlice'
import { setBalance, setCurrentPageTitle } from '../../../../store/features/authSlice'
import { fixHint, setHints } from '../../../../store/features/qualityCheckSlice'
import { toast } from 'react-toastify'
import { downloadFileFromPrivateBucket } from '../../../../utils/downloadFile'
import {
  getCarParts,
  getForeshortenings,
  getPilotLamps,
} from '../../../Appraisal/getAppraisalReferences'
import {
  setReferenceControlPoints,
  setReferences,
} from '../../../../store/features/appraisalReferencesSlices'
import { EventSourcePolyfill } from 'event-source-polyfill'
import TextNormal from '../../../../ui/Text/TextNormal'
import { colorsConfig } from '../../../../config/themeConfig'
import CarPageTabTitle from '../../../CarPage/CarPageTabTitle'
import {
  parseControlPointsDocuments,
  parseControlPointsInteriorConditions,
  parseMechanicalConditions,
} from '../../../Appraisal/appraisal.helpers'
import Preloader from '../../../../components/Preloader'
import BreadcrumbsComponent from '../../../../components/BreadcrumbsComponent'
import CarDefaultPicture from '../../../../assests/img/car-not-found.png'
import IconApple from '../../../../assests/img/icons/icon-apple-green.svg'
import IconCarStatus from '../../../../assests/img/icons/icon-car-status.svg'
import CarLoggingNodes from '../../../CarPage/CarLoggingNodes'
import { Close } from '@mui/icons-material'
import ButtonMedium from '../../../../ui/Buttons/ButtonMedium'
import IconVirtualGarage from '../../../../assests/img/icons/icon-virtual-garage-home.svg'
import TextBoldNormal from '../../../../ui/Text/TextBoldNormal'
import QualityCheckHints from '../../../CarPage/QualityCheck/QualityCheckHints'
import AppraisalTabs from '../../../Appraisal/AppraisalTabs'
import ButtonHollow from '../../../../ui/Buttons/ButtonHollow'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import TextH1 from '../../../../ui/Text/TextH1'
import ButtonDanger from '../../../../ui/Buttons/ButtonDanger'
import ButtonSuccess from '../../../../ui/Buttons/ButtonSuccess'
import IconCoin from '../../../../assests/img/icons/icon-fleetify-coin.svg'
import ButtonAttention from '../../../../ui/Buttons/ButtonAttention'
import IconDoubleArrows from '../../../../assests/img/icons/icon-double-arrows.svg'
import TextFieldSmall from '../../../../ui/TextFieldSmall'
import NotEnoughFleetifyCoins from '../../../../components/Modals/NotEnoughFleetifyCoins'
import { COMPANY_NAME, CURRENCY, REGION_LOCALE } from '../../../../utils/constants/global.constants'

const MainAppraisal = ({ carId }) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const carData = useSelector((state) => state.car.carData)
  const carDataAppraisal = useSelector((state) => state.car.appraisal)
  const appraisalReferences = useSelector((state) => state.references)
  const hints = useSelector((state) => state.qualityCheck.hints)
  const role = useSelector((state) => state.auth.role)
  const mercureToken = useSelector((state) => state.auth.mercureToken)
  const currentTab = useSelector((state) => state.car.currentAppraisalTab)
  const qualityCheckHints = useSelector((state) => state.qualityCheck.hints)
  const carAppraisalChecksResource = useSelector((state) => state.car.carAppraisalChecksResource)
  const company = useSelector((state) => state.auth.company)
  const coinPricings = useSelector((state) => state.auth.coinPricings)

  const navigate = useNavigate()
  const [blocks, setBlocks] = useState([])
  const [downloadingPDF, setDownloadingPDF] = useState(false)
  const [notEnoughCoinsModal, setNotEnoughCoinsModal] = useState(false)
  const [generatingInvoice, setGeneratingInvoice] = useState(false)

  const dispatch = useDispatch()

  const params = {
    id: carId,
  }

  const steps = [
    {
      id: 1,
      title: 'Photos',
      component: (
        <CarPhotos
          key={3}
          carId={carId}
          // submitPhotos={submitPhotos}
        />
      ),
    },
    {
      id: 2,
      title: 'Documents',
      component: <AppraisalDocuments key="appraisal_documents" carId={carId} />,
    },
    {
      id: 3,
      title: 'Damages',
      component: <AppraisalDamages key={4} carId={carId} />,
    },
    {
      id: 4,
      title: 'Warning Lights',
      component: <WarningLights key={5} carId={carId} />,
    },
    {
      id: 5,
      title: 'Interior Conditions',
      component: <AppraisalInteriorConditions key={6} carId={carId} />,
    },
    {
      id: 6,
      title: 'Mechanical Conditions',
      component: <AppraisalMechanicalConditions key={7} carId={carId} />,
    },
  ]

  const [step, setStep] = useState(steps[currentTab])
  const [tab, setTab] = useState(0)
  const [alreadyReadIntro, setAlreadyReadIntro] = useState(false)

  const [files, setFiles] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingText, setLoadingText] = useState('Loading Appraisal')
  const [appraisalLoaded, setAppraisalLoaded] = useState(false)
  const [hintsFixedModal, setHintsFixedModal] = useState(false)
  const [finishAppraisalModal, setFinishAppraisalModal] = useState(false)
  const [qualityCheckCheckout, setQualityCheckCheckout] = useState(false)
  const [qualityCheckCheckoutFail, setQualityCheckCheckoutFail] = useState(false)

  const [coinsAmount, setCoinsAmount] = useState(50)
  const [localCurrencyAmount, setLocalCurrencyAmount] = useState(200)

  const handleSetLocalCurrencyAmount = () => {
    if (coinsAmount * 4 !== localCurrencyAmount) {
      setLocalCurrencyAmount(coinsAmount * 4)
    }
  }

  const handleSetCoinsAmount = () => {
    if (coinsAmount * 4 !== localCurrencyAmount) {
      setCoinsAmount(localCurrencyAmount / 4)
    }
  }

  useEffect(() => {
    handleSetLocalCurrencyAmount()
  }, [coinsAmount])

  useEffect(() => {
    handleSetCoinsAmount()
  }, [localCurrencyAmount])

  const handleSaveOrder = () => {
    if (carData.foreshortenings.length > 0) {
      const formData = {
        foreshortenings: carData.foreshortenings.map((item) => {
          return {
            direct: item.direct,
            carForeshortening: item['@id'],
          }
        }),
      }
      privateRequest.put(`/cars/${carId}${API_ENDPOINTS.carPhotosDirectBatch}`, formData)
    }
  }

  function compare(a, b) {
    if (a.direct < b.direct) {
      return -1
    }
    if (a.direct > b.direct) {
      return 1
    }
    return 0
  }

  const handleStartAppraisal = () => {
    privateRequest.post(`/cars/${carId}${API_ENDPOINTS.startOnSiteAppraisal}`, {}).then(() => {
      dispatch(setWorkflowState('car_workflow.appraisal_on_site'))
    })
  }

  const handleSaveChanges = (value) => {
    if (steps[value - 1].title === 'Photos') {
      handleSaveOrder()
    }
  }

  const handleSelectStep = (stepId, prev) => {
    handleSaveChanges(prev)
    if (step.id !== 0) {
      submitControlPoints()
      submitPilotLamps()
    }
    if (stepId === steps.length + 1) {
      saveData()
    } else {
      setStep(
        steps.filter((stepItem) => {
          return stepItem.id === stepId
        })[0],
      )
      setTab(stepId - 1)
      setAlreadyReadIntro(true)
      dispatch(setAppraisalTab(stepId - 1))
    }
    window.scrollTo(0, 1000)
  }

  const handleChangeTab = (event, newValue) => {
    handleSaveChanges(step.id)
    if (tab !== 0) {
      submitPilotLamps()
      submitControlPoints()
    }
    dispatch(setAppraisalTab(newValue))
    setStep(steps[newValue])
    if (xsOnly) {
      window.scrollTo(0, 350)
    } else {
      window.scrollTo(0, 700)
    }
  }

  const getCar = () => {
    privateRequest.get(`/cars/${carId}`).then((response) => {
      setCar(response.data)
      dispatch(
        setCurrentPageTitle(`Appraisal: ${response.data.make.name} ${response.data.model.name}`),
      )
      dispatch(setCarData(response.data))
      setCarTitle(`${response.data.make.name} ${response.data.model.name}`)
      if (response.data.combinedStatus === 'car_workflow.new') {
        dispatch(setAppraisalTab(0))
        setStep(steps[0])
        setTab(0)
      }
    })
  }

  const handleSubmitCoinsDemand = (amount) => {
    setGeneratingInvoice(true)
    privateRequest
      .post(API_ENDPOINTS.coins.depositDemand, { amount: parseFloat(amount) })
      .then((response) => {
        privateRequest.get(response.data.invoice).then((responseInvoice) => {
          privateRequest
            .get(
              API_ENDPOINTS.privateFiles(responseInvoice.data.file['@id'].replace('/files/', '')),
            )
            .then(async (fileResponse) => {
              await downloadFileFromPrivateBucket(
                fileResponse.data.signedUrl,
                company.name
                  ? `Invoice for ${company.name} (${coinsAmount * 4} ${CURRENCY[REGION_LOCALE]})`
                  : `Invoice (${coinsAmount * 4} ${CURRENCY[REGION_LOCALE]})`,
              )
              setGeneratingInvoice(false)
              setNotEnoughCoinsModal(false)
              setFinishAppraisalModal(false)
            })
        })
      })
      .catch((error) => {
        toast.error(error.response.data['hydra:description'])
      })
  }

  const saveData = () => {
    ///
  }

  const submitPilotLamps = async () => {
    for (let i = 0; i < carDataAppraisal.pilotLamps.length; i++) {
      if (
        carDataAppraisal.pilotLamps[i].newValue !== null &&
        carDataAppraisal.pilotLamps[i].value !== null
      ) {
        await updatePilotLamp(carDataAppraisal.pilotLamps[i])
      }
      if (
        carDataAppraisal.pilotLamps[i].newValue !== null &&
        carDataAppraisal.pilotLamps[i].value === null
      ) {
        await uploadPilotLamp(carDataAppraisal.pilotLamps[i])
      }
    }
  }

  const savePilotLamps = async () => {
    try {
      const formData = {
        controlPointAggregates: carDataAppraisal.pilotLamps.map((item) => ({
          //  co
        })),
      }
    } catch (e) {}
  }

  const submitControlPoints = async () => {
    const formData = {
      carControlPoints: [],
    }
    const curBlocks = carDataAppraisal.controlPoints
    let counter = 0
    for (let i = 0; i < curBlocks.length; i++) {
      const curSubBlocks = curBlocks[i].subblocks
      for (let j = 0; j < curSubBlocks.length; j++) {
        const curControlPoints = curSubBlocks[j].controlPoints
        for (let m = 0; m < curControlPoints.length; m++) {
          const curControlPoint = curControlPoints[m]
          if (curControlPoint.updated === true) {
            if (curControlPoint.type !== 'file') {
              formData.carControlPoints.push({
                controlPoint: curControlPoint['@id'],
                value: curControlPoint.value === null ? null : curControlPoint.value,
              })
              counter++
            } else {
              const formDataFile = new FormData()
              if (curControlPoint.deleted) {
                formData.carControlPoints.push({
                  controlPoint: curControlPoint['@id'],
                  value: null,
                })
              } else {
                formDataFile.append('file', curControlPoint.value)
                formDataFile.append('type', FILE_TYPES.controlPointDocument)
                const fileId = await privateMultipartRequest
                  .post(API_ENDPOINTS.file, formDataFile)
                  .then((response) => {
                    return response.data['@id']
                  })
                formData.carControlPoints.push({
                  controlPoint: curControlPoint['@id'],
                  value: fileId,
                })
              }
              counter++
            }
          }
        }
      }
    }
    if (formData.carControlPoints.length > 0) {
      await privateRequest.post(API_ENDPOINTS.submitCarControlPoints(carId), formData)
    }
  }

  const uploadPilotLamp = async (pilotLamp) => {
    const formData = {
      pilotLamp: pilotLamp.lampViewIri,
      value: pilotLamp.newValue,
    }
    await privateRequest.post(API_ENDPOINTS.carPilotLamp(carId), formData)
  }

  const updatePilotLamp = async (pilotLamp) => {
    const formData = {
      pilotLamp: pilotLamp.lampViewIri,
      value: pilotLamp.newValue,
    }
    await privateRequest.put(pilotLamp.lampIri, formData)
  }

  const getReferences = () => {
    const referencePromises = [getForeshortenings(), getCarParts(), getPilotLamps()]
    Promise.all(referencePromises).then((result) => {
      dispatch(setReferences(result))
    })
  }

  const getCarAppraisal = () => {
    privateRequest.get(API_ENDPOINTS.appraisalByCar(carId)).then((response) => {
      dispatch(
        setAppraisal({
          foreshortenings: response.data.foreshortenings.sort(compare),
          damages: response.data.damages,
          pilotLamps: response.data.pilotLamps,
          controlPoints: response.data.controlPoints,
        }),
      )
      if (response.data.appraisalChecksResource) {
        dispatch(setCarAppraisalChecksResource(response.data.appraisalChecksResource))
      }
      setAppraisalLoaded(true)
    })
  }

  const handleSetCarAppraisalChecksResource = (stepId) => {
    const matchStep = steps.filter((item) => item.id === stepId)[0]
    if (matchStep && matchStep.title === 'Documents') {
      dispatch(
        setCarAppraisalChecksResource({
          documentsCheck: true,
        }),
      )
      privateRequest.post(`/cars/${carId}${API_ENDPOINTS.carAppraisalChecksResource}`, {
        documentsCheck: true,
      })
    }
    if (matchStep && matchStep.title === 'Photos') {
      dispatch(
        setCarAppraisalChecksResource({
          photosCheck: true,
        }),
      )
      privateRequest.post(`/cars/${carId}${API_ENDPOINTS.carAppraisalChecksResource}`, {
        photosCheck: true,
      })
    }
    if (matchStep && matchStep.title === 'Damages') {
      dispatch(
        setCarAppraisalChecksResource({
          damagesCheck: true,
        }),
      )
      privateRequest.post(`/cars/${carId}${API_ENDPOINTS.carAppraisalChecksResource}`, {
        damagesCheck: true,
      })
    }
    if (matchStep && matchStep.title === 'Warning Lights') {
      dispatch(
        setCarAppraisalChecksResource({
          warningLightsCheck: true,
        }),
      )
      privateRequest.post(`/cars/${carId}${API_ENDPOINTS.carAppraisalChecksResource}`, {
        warningLightsCheck: true,
      })
    }
    if (matchStep && matchStep.title === 'Interior Conditions') {
      dispatch(
        setCarAppraisalChecksResource({
          interiorConditionsCheck: true,
        }),
      )
      privateRequest.post(`/cars/${carId}${API_ENDPOINTS.carAppraisalChecksResource}`, {
        interiorConditionsCheck: true,
      })
    }
    if (matchStep && matchStep.title === 'Mechanical Conditions') {
      dispatch(
        setCarAppraisalChecksResource({
          mechanicalConditionsCheck: true,
        }),
      )
      privateRequest.post(`/cars/${carId}${API_ENDPOINTS.carAppraisalChecksResource}`, {
        mechanicalConditionsCheck: true,
      })
    }
  }

  const handleFinishAppraisal = () => {
    if (
      carAppraisalChecksResource.vehicleDetailsCheck &&
      carAppraisalChecksResource.documentsCheck &&
      carAppraisalChecksResource.photosCheck &&
      carAppraisalChecksResource.damagesCheck &&
      carAppraisalChecksResource.warningLightsCheck &&
      carAppraisalChecksResource.interiorConditionsCheck
    ) {
      setFinishAppraisalModal(true)
    } else {
      saveData()
    }
  }

  const handleSetCarReadyForSale = () => {
    privateRequest
      .post(`/cars/${params.id}${API_ENDPOINTS.completeAppraisalOnCompound}`, {})
      .then(() => {
        dispatch(setWorkflowState('car_workflow.ready_for_sale'))
        toast.success('Appraisal has been finished successfully')
        navigate('/expert/dashboard')
      })
  }

  let pdfFileId = null

  const handleDownloadPDF = async () => {
    privateRequest
      .post(
        API_ENDPOINTS.carPdfAppraisalResult(params.id),
        {},
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        const url = `${MERCURE_BASE_URL}?topic=/cars/${carData.id}/pdf-appraisal-result`
        const eventSource = new EventSourcePolyfill(url, {
          headers: {
            Authorization: 'Bearer ' + mercureToken,
          },
        })

        setDownloadingPDF(true)

        eventSource.onmessage = function (event) {
          const { fileId } = JSON.parse(event.data)
          pdfFileId = fileId
          initiateFileDownload(pdfFileId)
          setDownloadingPDF(false)
          eventSource.close()
        }

        eventSource.onerror = function (error) {
          setDownloadingPDF(false)
        }

        const { make, model, vin } = carData
        const fileName = `${make.name}_${model.name}_${vin}`.replace(' ', '-')

        async function initiateFileDownload(pdfFileId) {
          privateRequest
            .get(
              API_ENDPOINTS.privateFiles(pdfFileId),
              {},
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              },
            )
            .then((response) => {
              const { signedUrl } = response.data

              // const link = document.createElement('a')
              // link.href = signedUrl
              // link.target = '_blank'
              // link.rel = 'noopener noreferrer'
              // link.click()
              downloadFileFromPrivateBucket(signedUrl, fileName)
            })
        }
      })
  }

  useEffect(() => {
    if (currentTab !== step.id - 1) {
      setStep(steps[currentTab])
    }
  }, [currentTab])

  useEffect(() => {
    if (!appraisalReferences.loaded) {
      getReferences()
    }
  }, [appraisalReferences.loaded])

  useEffect(() => {
    if (appraisalReferences.loaded && carData.loaded && appraisalLoaded) {
      // setControlPointsBlocks()
      dispatch(
        setDamages({
          carDamages: carData.damages,
          carParts: appraisalReferences.carParts,
        }),
      )
      dispatch(setPilotLamps(appraisalReferences.pilotLamps))
      // dispatch(setControlPoints(appraisalReferences.controlPoints))
      dispatch(setPhotos(appraisalReferences.foreshortenings))

      setTimeout(() => {
        setLoading(false)
      }, 500)
    }
  }, [appraisalReferences.loaded, carData.loaded, appraisalLoaded])

  const checkDeletedDamages = () => {
    const deletedDamages = JSON.parse(window.localStorage.getItem('deletedDamages'))
    if (deletedDamages && deletedDamages.length > 0) {
      dispatch(setDeletedDamages(deletedDamages))
      deletedDamages.forEach(async (item) => {
        await privateRequest.delete(`/cars/${item.carId}/damages/${item.id}`).then(() => {
          const oldDeletedDamages = JSON.parse(window.localStorage.getItem('deletedDamages'))
          if (oldDeletedDamages && oldDeletedDamages.length > 0) {
            window.localStorage.setItem(
              'deletedDamages',
              JSON.stringify(
                oldDeletedDamages.filter(
                  (damageItem) => damageItem.id !== item.id && damageItem.carId !== item.carId,
                ),
              ),
            )
          }
        })
      })
    }
  }

  const renderMarkedTitle = (title, count) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TextNormal
          sx={{
            mr: '7px',
          }}
        >
          {title}
        </TextNormal>
        <Box
          sx={{
            color: 'white',
            width: '25px',
            height: '25px',
            borderRadius: '50%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            background: colorsConfig.mainRed,
          }}
        >
          <TextNormal
            sx={{
              color: 'white',
              fontSize: '14px',
            }}
          >
            {count}
          </TextNormal>
        </Box>
      </Box>
    )
  }

  const renderTabTitle = (title) => {
    let matchHints = []
    let indexes = []
    switch (title) {
      case 'Vehicle Details':
        matchHints = qualityCheckHints.filter(
          (item) => item['@type'] === HINT_TYPES.vehicleDetails && !item.fixed,
        )
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(title, matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        }
        return {
          component: title,
          hasFixes: false,
        }
        break
      case 'Documents':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (
            item['@type'] === HINT_TYPES.subBlocks &&
            item.subblock === '/reference/appraisal/subblocks/1' &&
            !item.fixed
          ) {
            indexes.push(index)
            return true
          } else return false
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(title, matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component:
              carData.combinedStatus === 'car_workflow.appraisal_on_site' ||
              carData.combinedStatus === 'car_workflow.appraisal_compound' ? (
                <CarPageTabTitle
                  title={title}
                  isCompleted={carAppraisalChecksResource.documentsCheck}
                />
              ) : (
                title
              ),
            hasFixes: false,
          }
        }
        break
      case 'Photos':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (
            (item['@type'] === HINT_TYPES.photos || item['@type'] === HINT_TYPES.photo) &&
            !item.fixed
          ) {
            indexes.push(index)
            return true
          } else {
            return false
          }
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(title, matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component:
              carData.combinedStatus === 'car_workflow.appraisal_on_site' ||
              carData.combinedStatus === 'car_workflow.appraisal_compound' ? (
                <CarPageTabTitle
                  title={title}
                  isCompleted={carAppraisalChecksResource.photosCheck}
                />
              ) : (
                title
              ),
            hasFixes: false,
          }
        }
        break
      case 'Damages':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (
            (item['@type'] === HINT_TYPES.damage && !item.fixed) ||
            (item['@type'] === HINT_TYPES.damages && !item.fixed)
          ) {
            indexes.push(index)
            return true
          } else return false
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(title, matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component:
              carData.combinedStatus === 'car_workflow.appraisal_on_site' ||
              carData.combinedStatus === 'car_workflow.appraisal_compound' ? (
                <CarPageTabTitle
                  title={title}
                  isCompleted={carAppraisalChecksResource.damagesCheck}
                />
              ) : (
                title
              ),
            hasFixes: false,
          }
        }
        break
      case 'Warning Lights':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (item['@type'] === HINT_TYPES.warningLights && !item.fixed) {
            indexes.push(index)
            return true
          } else {
            return false
          }
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(title, matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component:
              carData.combinedStatus === 'car_workflow.appraisal_on_site' ||
              carData.combinedStatus === 'car_workflow.appraisal_compound' ? (
                <CarPageTabTitle
                  title={title}
                  isCompleted={carAppraisalChecksResource.warningLightsCheck}
                />
              ) : (
                title
              ),
            hasFixes: false,
          }
        }
        break
      case 'Interior Conditions':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (
            item['@type'] === HINT_TYPES.subBlocks &&
            item.subblock === '/reference/appraisal/subblocks/2' &&
            !item.fixed
          ) {
            indexes.push(index)
            return true
          } else return false
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(title, matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component:
              carData.combinedStatus === 'car_workflow.appraisal_on_site' ||
              carData.combinedStatus === 'car_workflow.appraisal_compound' ? (
                <CarPageTabTitle
                  title={title}
                  isCompleted={carAppraisalChecksResource.interiorConditionsCheck}
                />
              ) : (
                title
              ),
            hasFixes: false,
          }
        }
        break
      case 'Mechanical Conditions':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (
            item['@type'] === HINT_TYPES.subBlocks &&
            item.subblock !== '/reference/appraisal/subblocks/1' &&
            item.subblock !== '/reference/appraisal/subblocks/2' &&
            !item.fixed
          ) {
            indexes.push(index)
            return true
          } else {
            return false
          }
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(title, matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component:
              carData.combinedStatus === 'car_workflow.appraisal_on_site' ||
              carData.combinedStatus === 'car_workflow.appraisal_compound' ? (
                <CarPageTabTitle
                  title={title}
                  isCompleted={carAppraisalChecksResource.mechanicalConditionsCheck}
                />
              ) : (
                title
              ),
            hasFixes: false,
          }
        }
        break
      default:
        return {
          component: title,
          hasFixes: false,
        }
        break
    }
  }

  const handleFixHint = (stepId) => {
    renderTabTitle(steps[stepId - 1].title).fixHints()
  }

  const checkQualityCheckFixed = () => {
    const findUnfixed = qualityCheckHints.filter((item) => !item.fixed)[0]
    if (findUnfixed) {
      return
    }
    setHintsFixedModal(true)
  }

  const getControlPoints = () => {
    privateRequest
      .get(API_ENDPOINTS.controlPoints, {
        params: {
          pagination: false,
        },
      })
      .then((response) => {
        dispatch(setReferenceControlPoints(response.data['hydra:member']))
      })
  }

  const handleSetCarControlPoints = () => {
    dispatch(
      setDocuments(
        parseControlPointsDocuments(appraisalReferences.controlPoints, carData.controlPoints),
      ),
    )
    dispatch(
      setInteriorConditions(
        parseControlPointsInteriorConditions(
          appraisalReferences.controlPoints,
          carData.controlPoints,
        ),
      ),
    )
    dispatch(
      setMechanicalConditions(
        parseMechanicalConditions(appraisalReferences.controlPoints, carData.controlPoints),
      ),
    )
  }

  useEffect(() => {
    if (appraisalReferences.controlPoints.length > 0 && carData.loaded) {
      handleSetCarControlPoints()
    }
  }, [appraisalReferences.controlPoints, carData.loaded])

  useEffect(() => {
    getCar()
    getControlPoints()
    getCarAppraisal()
    return () => {
      submitControlPoints()
      submitPilotLamps()
    }
    // return () => {
    //     dispatch(resetCar())
    // }
  }, [])

  useEffect(() => {
    if (
      carData.combinedStatus &&
      carData.combinedStatus === 'car_workflow.moderation_failed' &&
      currentTab === 1 &&
      step.id === 1
    ) {
      setStep(steps[1])
    }
  }, [currentTab])

  const [loggingTreeModal, setLoggingTreeModal] = useState(false)

  const [car, setCar] = useState(null)
  const [carTitle, setCarTitle] = useState('')
  const [pickupDemand, setPickupDemand] = useState(null)

  const [currentWorkflow, setCurrentWorkflow] = useState(null)
  const [currentStatusColor, setCurrentStatusColor] = useState('')
  const carWorkflows = useSelector((state) => state.workflows.carWorkflowStates)
  const auctionWorkflows = useSelector((state) => state.workflows.auctionWorkflowStates)
  const dealWorkflows = useSelector((state) => state.workflows.dealWorkflowStates)
  const workflowsLoaded = useSelector((state) => state.workflows.loaded)
  const combinedStatus = useSelector((state) => state.car.carData.combinedStatus)

  const getPickupDemand = () => {
    privateRequest.get(API_ENDPOINTS.pickupDemand(carData.id)).then((response) => {
      setPickupDemand(response.data)
    })
  }

  useEffect(() => {
    if (car && workflowsLoaded && combinedStatus) {
      let findWorkflow
      if (combinedStatus.includes('car_workflow')) {
        findWorkflow = carWorkflows.filter(
          (workflow) => workflow.sysName === combinedStatus.replace('car_workflow.', ''),
        )[0]
      }
      if (combinedStatus.includes('auction_workflow')) {
        findWorkflow = auctionWorkflows.filter(
          (workflow) => workflow.sysName === combinedStatus.replace('auction_workflow.', ''),
        )[0]
      }
      if (combinedStatus.includes('deal_workflow')) {
        findWorkflow = dealWorkflows.filter(
          (workflow) => workflow.sysName === combinedStatus.replace('deal_workflow.', ''),
        )[0]
        // getDeal()
      }

      if (combinedStatus === 'car_workflow.requested_appraisal_compound') {
        getPickupDemand()
      }

      if (findWorkflow) {
        setCurrentWorkflow(findWorkflow.value)
        setCurrentStatusColor(`#${findWorkflow.color}`)
      }
    }
  }, [car, workflowsLoaded, combinedStatus])

  return (
    <Box
      className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
      sx={{
        '& .MuiContainer-root': {
          maxWidth: '1240px',
        },
        '&.xs-only': {
          pt: '25px',
          px: '16px',
        },
        '&.tablet-only': {
          px: '16px',
          pb: '30px',
        },
      }}
    >
      {loading ? (
        <Preloader type="gooey" text={loadingText} />
      ) : (
        <React.Fragment>
          <AppraisalTabs
            currentTab={currentTab}
            handleChangeTab={handleChangeTab}
            steps={steps}
            renderTabTitle={renderTabTitle}
          />
          <Container
            className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
            maxWidth="lg"
            sx={{
              ml: 0,
              '&.xs-only': {
                px: 0,
              },
              '&.tablet-only': {
                px: 0,
              },
            }}
          >
            <Box
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                '&.xs-only': {
                  pb: '40px',
                },
              }}
            >
              <Grid container>
                <Grid xs={12} item>
                  {steps.map((stepItem) => {
                    const stepId =
                      carData.combinedStatus === 'car_workflow.appraisal_compound'
                        ? step.id + 1
                        : step.id
                    if (stepItem.id === stepId) {
                      return stepItem.component
                    }
                  })}
                </Grid>
              </Grid>
            </Box>
          </Container>
          <Modal
            open={hintsFixedModal}
            onClose={() => {
              setHintsFixedModal(false)
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 470,
                background: 'white',
                borderRadius: '10px',
                padding: '30px',
                maxHeight: '80vh',
                overflow: 'auto',
                '&.xs-only': {
                  width: 'calc(100vw - 30px)',
                  px: '10px',
                  py: '20px',
                },
              }}
              className={['disable-scrollbar', xsOnly ? 'xs-only' : '']}
            >
              <TextH1
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  textAlign: 'center',
                  mb: '10px',
                  '&.xs-only': {
                    fontSize: '24px',
                  },
                }}
              >
                All updates are done
              </TextH1>
              <TextNormal
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  mb: '40px',
                  textAlign: 'center',
                  '&.xs-only': {
                    fontSize: '14px',
                    mb: '30px',
                  },
                }}
              >
                All changes will be saved and sent to the operator
              </TextNormal>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ButtonLG
                  sx={{
                    width: '310px',
                  }}
                  onClick={() => {
                    // handleSubmitToQualityCheck()
                  }}
                >
                  Finish appraisal
                </ButtonLG>
              </Box>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                }}
                onClick={() => {
                  setHintsFixedModal(false)
                }}
              >
                <Close
                  style={{
                    fill: colorsConfig.mainRed,
                  }}
                />
              </IconButton>
            </Box>
          </Modal>
          <Modal
            open={finishAppraisalModal}
            onClose={() => {
              setFinishAppraisalModal(false)
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: role === 'COMPANY' ? 460 : xsOnly ? 342 : 688,
                background: 'white',
                borderRadius: '10px',
                padding: xsOnly && role === 'EXPERT' ? '30px 20px 25px' : '30px',
                maxHeight: '80vh',
                overflow: 'auto',
                '&.xs-only': {
                  width: 'calc(100% - 30px)',
                  px: '10px',
                  py: '20px',
                },
              }}
              className={['disable-scrollbar', xsOnly ? 'xs-only' : '']}
            >
              {role === 'EXPERT' ? (
                <>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontFamily: '"Helvetica", sans-serif',
                      fontSize: xsOnly ? '24px' : '40px',
                      color: colorsConfig.mainBlack,
                      mb: '10px',
                    }}
                  >
                    Finish appraisal?
                  </Typography>
                  <TextNormal
                    sx={{
                      fontSize: xsOnly && '14px',
                      mb: '20px',
                    }}
                  >
                    Have you completed the appraisal of this vehicle and wish to proceed with
                    sending to the operator?
                  </TextNormal>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mb: '10px',
                      ml: 'auto',
                      mr: 'auto',
                    }}
                  >
                    <ButtonHollow
                      sx={{
                        mr: xsOnly ? '10px' : '5px',
                        width: xsOnly ? '50%' : '100%',
                      }}
                      fullWidth
                      onClick={() => {
                        setFinishAppraisalModal(false)
                      }}
                    >
                      Cancel
                    </ButtonHollow>
                    <ButtonLG
                      onClick={() => {
                        carData.combinedStatus === 'car_workflow.appraisal_compound'
                          ? handleSetCarReadyForSale()
                          : setQualityCheckCheckout(true)
                        //handleSubmitToQualityCheck()
                      }}
                      disabled={qualityCheckCheckoutFail || qualityCheckCheckout}
                      sx={{
                        width: xsOnly ? '50%' : '100%',
                        '&.Mui-disabled': {
                          background: colorsConfig.buttonDisabled,
                          color: 'white',
                        },
                      }}
                      fullWidth
                    >
                      Send
                    </ButtonLG>
                  </Box>
                </>
              ) : null}
              {role === 'COMPANY' ? (
                <>
                  <TextH1
                    className={xsOnly ? 'xs-only' : ''}
                    sx={{
                      mb: '10px',
                      '&.xs-only': {
                        fontSize: '24px',
                      },
                    }}
                  >
                    Appraisal is done
                  </TextH1>
                  <TextNormal
                    sx={{
                      mb: '20px',
                    }}
                  >
                    {carData.combinedStatus === 'car_workflow.appraisal_compound'
                      ? `Do you want to set car as ready for action?`
                      : `Do you want to send it to quality check now?`}
                  </TextNormal>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mb: '10px',
                      ml: 'auto',
                      mr: 'auto',
                    }}
                  >
                    <ButtonDanger
                      fullWidth
                      sx={{
                        mr: '5px',
                      }}
                      onClick={async () => {
                        await submitControlPoints()
                        saveData()
                      }}
                    >
                      No
                    </ButtonDanger>
                    <ButtonSuccess
                      fullWidth
                      onClick={() => {
                        carData.combinedStatus === 'car_workflow.appraisal_compound'
                          ? handleSetCarReadyForSale()
                          : setQualityCheckCheckout(true)
                        //handleSubmitToQualityCheck()
                      }}
                      disabled={qualityCheckCheckoutFail || qualityCheckCheckout}
                      sx={{
                        '&.Mui-disabled': {
                          background: colorsConfig.buttonDisabled,
                          color: 'white',
                        },
                      }}
                    >
                      Yes
                    </ButtonSuccess>
                  </Box>
                  <Box
                    sx={{
                      ml: 'auto',
                      mr: 'auto',
                    }}
                  >
                    <ButtonHollow
                      fullWidth
                      onClick={() => {
                        handleDownloadPDF()
                      }}
                    >
                      <span
                        style={{
                          marginRight: '5px',
                          fontFamily: '"Helvetica", sans-serif',
                          fontWeight: 700,
                        }}
                      >
                        {downloadingPDF ? 'Generating PDF...' : 'Download appraisal as pdf'}
                      </span>
                      {downloadingPDF ? (
                        <CircularProgress
                          sx={{
                            '.css-176wh8e-MuiCircularProgress-circle': {
                              color: colorsConfig.mainBlue,
                            },
                            margin: '0 8px',
                            transform: 'scale(0.7)',
                          }}
                        />
                      ) : null}
                    </ButtonHollow>
                  </Box>
                  {qualityCheckCheckout ? (
                    <Box
                      sx={{
                        mt: '30px',
                        borderTop: '3px solid',
                        borderColor: colorsConfig.mainGreen,
                        position: 'relative',
                        ml: '-30px',
                        mr: '-30px',
                      }}
                    >
                      <Box
                        sx={{
                          padding: '30px',
                          pb: '0',
                        }}
                      >
                        <TextBoldNormal
                          sx={{
                            fontSize: '18px',
                            mb: '6px',
                          }}
                        >
                          Confirm payment
                        </TextBoldNormal>
                        <TextNormal
                          sx={{
                            mb: '55px',
                          }}
                        >
                          The {COMPANY_NAME[REGION_LOCALE]} operator will verify the details of your
                          car, enabling you to access services that necessitate a clearance and
                          inspection process.
                        </TextNormal>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            pb: '9px',
                            borderBottom: '1px solid',
                            borderColor: colorsConfig.lightGray,
                            mb: '54px',
                          }}
                        >
                          <TextBoldNormal sx={{ textTransform: 'uppercase' }}>Total</TextBoldNormal>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <img src={IconCoin} alt="" />
                            <TextBoldNormal sx={{ ml: '5px' }}>
                              {
                                coinPricings.filter(
                                  (item) => item.identifier === 'quality_check',
                                )[0]?.amount
                              }
                            </TextBoldNormal>
                          </Box>
                        </Box>
                        <Box>
                          <ButtonAttention
                            fullWidth
                            onClick={() => {
                              // handleSubmitToQualityCheck()
                            }}
                          >
                            Apply for quality check
                          </ButtonAttention>
                        </Box>
                      </Box>
                    </Box>
                  ) : null}
                  {qualityCheckCheckoutFail ? (
                    <Box
                      sx={{
                        mt: '30px',
                        borderTop: '3px solid',
                        borderColor: colorsConfig.mainGreen,
                        position: 'relative',
                        ml: '-30px',
                        mr: '-30px',
                      }}
                    >
                      <Box
                        sx={{
                          padding: '30px',
                          pb: 0,
                        }}
                      >
                        <TextBoldNormal
                          sx={{
                            fontSize: '18px',
                            mb: '10px',
                          }}
                        >
                          Not enough Fleetify Coins
                        </TextBoldNormal>
                        <TextNormal
                          sx={{
                            mb: '30px',
                          }}
                        >
                          Purchase Fleetify Coins in order to use that service
                          <br />
                          Or learn how to{' '}
                          <span style={{ color: colorsConfig.mainBlue }}>
                            Earn Fleatify Coins
                          </span>{' '}
                          for free
                        </TextNormal>
                        <TextBoldNormal
                          sx={{
                            mb: '16px',
                          }}
                        >
                          Enter Amount 1 FC = 4 {CURRENCY[REGION_LOCALE]}
                        </TextBoldNormal>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            mb: '31px',
                            position: 'relative',
                          }}
                        >
                          <Box
                            sx={{
                              position: 'absolute',
                              width: '30px',
                              height: '21px',
                              top: '50%',
                              mt: '-10.5px',
                              left: '50%',
                              ml: '-15px',
                            }}
                          >
                            <img src={IconDoubleArrows} alt="" />
                          </Box>
                          <Box
                            sx={{
                              mr: '70px',
                              position: 'relative',
                            }}
                          >
                            <TextFieldSmall
                              type="text"
                              value={coinsAmount}
                              onChange={(e) => {
                                setCoinsAmount(e.target.value)
                              }}
                              sx={{
                                width: '168px',
                              }}
                            />
                            <TextNormal
                              sx={{
                                color: colorsConfig.iconGray,
                                fontSize: '14px',
                                position: 'absolute',
                                top: '9px',
                                right: '11px',
                                '&::after': {
                                  content: '" "',
                                  display: 'block',
                                  position: 'absolute',
                                  height: '16px',
                                  width: '1px',
                                  background: colorsConfig.lightGray,
                                  left: '-10px',
                                  top: '50%',
                                  mt: '-8px',
                                },
                              }}
                            >
                              FC
                            </TextNormal>
                          </Box>
                          <Box
                            sx={{
                              position: 'relative',
                            }}
                          >
                            <TextFieldSmall
                              value={localCurrencyAmount}
                              onChange={(e) => {
                                setLocalCurrencyAmount(e.target.value)
                              }}
                              type="text"
                              sx={{
                                width: '168px',
                              }}
                            />
                            <TextNormal
                              sx={{
                                color: colorsConfig.iconGray,
                                fontSize: '14px',
                                position: 'absolute',
                                top: '9px',
                                right: '11px',
                                '&::after': {
                                  content: '" "',
                                  display: 'block',
                                  position: 'absolute',
                                  height: '16px',
                                  width: '1px',
                                  background: colorsConfig.lightGray,
                                  left: '-10px',
                                  top: '50%',
                                  mt: '-8px',
                                },
                              }}
                            >
                              AED
                            </TextNormal>
                          </Box>
                        </Box>
                        <TextNormal
                          sx={{
                            fontSize: '14px',
                            color: colorsConfig.lightGray,
                            mb: '18px',
                          }}
                        >
                          Wait until Fleeetify coins are transferred to your account (up to 24 hours
                          after payment)
                        </TextNormal>
                        <ButtonAttention
                          fullWidth
                          disabled={!coinsAmount || !localCurrencyAmount || generatingInvoice}
                          onClick={() => {
                            handleSubmitCoinsDemand(coinsAmount)
                          }}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {generatingInvoice ? 'Generating invoice...' : 'Get invoice'}
                          {generatingInvoice ? <Preloader type={'gooey'} /> : null}
                        </ButtonAttention>
                      </Box>
                    </Box>
                  ) : null}
                </>
              ) : null}
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                }}
                onClick={() => {
                  setFinishAppraisalModal(false)
                }}
              >
                <Close
                  style={{
                    fill: colorsConfig.mainRed,
                  }}
                />
              </IconButton>
            </Box>
          </Modal>
          <NotEnoughFleetifyCoins
            open={notEnoughCoinsModal}
            setOpen={setNotEnoughCoinsModal}
            handleSubmit={handleSubmitCoinsDemand}
            generatingInvoice={generatingInvoice}
          />
        </React.Fragment>
      )}
    </Box>
  )
}

export default MainAppraisal
