import React from 'react'
import { Box, Stack } from '@mui/material'
import TextNormal from '../../../../ui/Text/TextNormal'
import TextBoldNormal from '../../../../ui/Text/TextBoldNormal'
import CarWarrantyDocuments from '../components/CarWarrantyDocuments'
import { useSelector } from 'react-redux'
import { formatDate } from '../../../../utils/date.helpers'
import { colorsConfig } from '../../../../config/themeConfig'
import IconCheckmark from '../../../../assests/img/icons/icon-checkmark-black.svg'
import { getPackageName } from '../../warranty.helpers'
import { parseCoveredComponents } from '../components/CarWarrantyPackages'
import RBox from '../../../../ui/RBox'

const ActivatedWarrantyDetails = () => {
  const warrantyApplication = useSelector((state) => state.warrantyApplication.warrantyApplication)
  const warrantyProducts = useSelector((state) => state.warrantyProducts.warrantyProducts)
  const coveredComponents = useSelector((state) => state.coveredComponents.coveredComponents)
  const calculatedProducts = useSelector(
    (state) => state.warrantyApplication.calculation.calculatedProducts,
  )
  const currentProduct = warrantyProducts.find(
    (item) => item?.['@id'] === warrantyApplication?.selectedProduct,
  )
  const currentProductComps = parseCoveredComponents(
    currentProduct?.coveredComponents,
    coveredComponents,
  )

  const startDate = formatDate(warrantyApplication?.startDate)
  const getEndDate = () => {
    const endDateTime = new Date(warrantyApplication?.startDate)
    endDateTime.setFullYear(endDateTime.getFullYear() + 1)
    return formatDate(endDateTime)
  }

  return (
    <Box>
      <Stack gap="30px">
        <Stack direction="row" gap="30px">
          <Box>
            <TextNormal>Start date</TextNormal>
            <TextBoldNormal>{startDate}</TextBoldNormal>
          </Box>
          <Box>
            <TextNormal>End date</TextNormal>
            <TextBoldNormal>{getEndDate()}</TextBoldNormal>
          </Box>
        </Stack>
        <RBox
          sx={{
            border: '1px solid',
            borderColor: colorsConfig.mainGreen,
            borderRadius: '10px',
            boxShadow: '0 0 10px rgba(0,0,0,.1)',
            px: '37px',
            py: '22px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '&.xs-only': {
              px: '16px',
              py: '15px',
              display: 'block',
            },
          }}
        >
          <Stack gap="20px">
            <TextBoldNormal sx={{ fontSize: '24px' }}>
              {getPackageName(warrantyApplication?.selectedProduct)} Package
            </TextBoldNormal>
            <Stack direction="row" gap="30px" flexWrap="wrap">
              {currentProductComps.map((item, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '7px',
                    }}
                  >
                    <img src={IconCheckmark} alt="" />
                    <TextNormal>{item?.name}</TextNormal>
                  </Box>
                )
              })}
            </Stack>
          </Stack>
        </RBox>
        <CarWarrantyDocuments />
      </Stack>
    </Box>
  )
}

export default ActivatedWarrantyDetails
