import React, { useEffect, useState } from 'react'
import TextBoldNormal from '../../../../ui/Text/TextBoldNormal'
import { Box, Grid, Stack } from '@mui/material'
import TextFieldSmall from '../../../../ui/TextFieldSmall'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import useValidate from '../../../../hooks/useValidate'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import { privateRequest } from '../../../../utils/request'
import { API_ENDPOINTS } from '../../../../config/default'
import { toast } from 'react-toastify'
import BoxShadowed from '../../../../ui/Layout/BoxShadowed'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import { useSelector } from 'react-redux'
import { sumDelimiter } from '../../../../utils/sumDelimiter'
import ButtonLink from '../../../../ui/Buttons/ButtonLink'
import IconEdit from '../../../../assests/img/icons/icon-edit.svg'

const DealTransportationForm = (props) => {
  const { deal } = props

  const carData = useSelector((state) => state.car.carData)

  const currentDate = new Date()

  const [pickupDate, setPickupDate] = useState(null)
  const [pickupLocation, setPickupLocation] = useState('')
  const [pickupTime, setPickupTime] = useState(null)
  const [deliveryDate, setDeliveryDate] = useState(null)
  const [deliveryLocation, setDeliveryLocation] = useState('')
  const [deliveryTime, setDeliveryTime] = useState(null)
  const [sellerEmail, setSellerEmail] = useState('')
  const [sellerName, setSellerName] = useState('')
  const [sellerPhone, setSellerPhone] = useState('+971 ')
  const [mileage, setMileage] = useState('')
  const [additionalInfo, setAdditionalInfo] = useState('')
  const [buyerEmail, setBuyerEmail] = useState('')
  const [buyerName, setBuyerName] = useState('')
  const [buyerPhone, setBuyerPhone] = useState('+971 ')
  const [buyerAdditionalInfo, setBuyerAdditionalInfo] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [pickupEditMode, setPickupEditMode] = useState(true)
  const [deliveryEditMode, setDeliveryEditMode] = useState(true)
  const [transportationFormSaved, setTransportationFormSaved] = useState(false)

  const validatePickupDate = useValidate(pickupDate, { isEmpty: true, isValidDateStr: true }, true)
  const validatePickupTime = useValidate(pickupTime, { isEmpty: true, isValidTime: true }, true)
  const validateSellerEmail = useValidate(sellerEmail, { isEmpty: true, email: true }, true)
  const validateSellerPhone = useValidate(
    sellerPhone,
    { isEmpty: true, isValidFullNumberUAE: true },
    true,
  )
  const validateDeliveryDate = useValidate(
    deliveryDate,
    { isEmpty: true, isValidDateStr: true },
    true,
  )
  const validateDeliveryTime = useValidate(deliveryTime, { isEmpty: true, isValidTime: true }, true)
  const validateBuyerEmail = useValidate(buyerEmail, { isEmpty: true, email: true }, true)
  const validateBuyerPhone = useValidate(
    sellerPhone,
    { isEmpty: true, isValidFullNumberUAE: true },
    true,
  )

  const hasErrors = () => {
    if (validatePickupDate.hasErrors) return true
    if (validatePickupTime.hasErrors) return true
    if (!pickupLocation) return true
    if (!sellerName) return true
    if (validateSellerPhone.hasErrors) return true
    if (validateSellerEmail.hasErrors) return true
    if (mileage === '') return true
    if (validateDeliveryDate.hasErrors) return true
    if (validateDeliveryTime.hasErrors) return true
    if (!deliveryLocation) return true
    if (!buyerName) return true
    if (validateBuyerPhone.hasErrors) return true
    if (validateBuyerEmail.hasErrors) return true
    return false
  }

  const getDateFromDateAndTime = (date, time) => {
    const finalDate = new Date(date)
    const finalTimeDate = new Date(time)
    finalDate.setHours(finalTimeDate.getHours())
    finalDate.setMinutes(finalTimeDate.getMinutes())
    return finalDate
  }

  const getTransportationApplication = () => {
    privateRequest.get(`${deal}/transport-application`).then((response) => {
      if (response.data.pickupDate) setTransportationFormSaved(true)
      setPickupDate(response.data.pickupDate)
      setPickupTime(response.data.pickupDate)
      setPickupLocation(response.data.pickupLocation)
      setSellerName(response.data.sellerName)
      setSellerPhone(response.data.sellerPhone)
      setSellerEmail(response.data.sellerEmail)
      if (response.data.mileage) {
        setMileage(response.data?.mileage)
      }
      setAdditionalInfo(response.data.sellerAdditionalInformation)
      setDeliveryDate(response.data.dropOffDate)
      setDeliveryTime(response.data.dropOffDate)
      setDeliveryLocation(response.data.dropOffLocation)
      setBuyerName(response.data.buyerName)
      setBuyerPhone(response.data.buyerPhone)
      setBuyerEmail(response.data.buyerEmail)
      setBuyerAdditionalInfo(response.data?.buyerAdditionalInformation)
      setPickupEditMode(false)
      setDeliveryEditMode(false)
    })
  }

  const handleSubmit = () => {
    if (hasErrors()) {
      setShowErrors(true)
      return
    }
    const formData = {
      pickupDate: getDateFromDateAndTime(pickupDate, pickupTime),
      pickupLocation: pickupLocation,
      sellerName: sellerName,
      sellerEmail: sellerEmail,
      sellerPhone: sellerPhone,
      sellerAdditionalInformation: additionalInfo,
      dropOffDate: getDateFromDateAndTime(deliveryDate, deliveryTime),
      dropOffLocation: deliveryLocation,
      buyerName: buyerName,
      buyerEmail: buyerEmail,
      buyerPhone: buyerPhone,
      buyerAdditionalInfo: buyerAdditionalInfo,
    }
    privateRequest
      .post(`${deal}${API_ENDPOINTS.transportApplication}`, formData)
      .then((response) => {
        setPickupEditMode(false)
        setDeliveryEditMode(false)
        setTransportationFormSaved(true)
        toast.success('Application for transportation has been submitted successfully')
      })
  }

  useEffect(() => {
    if (carData?.carMainSpecs?.mileage) {
      setMileage(carData?.carMainSpecs?.mileage)
    }
  }, [carData])

  useEffect(() => {
    if (deal) {
      getTransportationApplication()
    }
  }, [deal])

  return (
    <BoxShadowed
      sx={{
        mt: '50px',
      }}
    >
      <TextBoldNormal
        sx={{
          fontSize: '18px',
          mb: '24px',
        }}
      >
        Transportation details
      </TextBoldNormal>
      <Box
        sx={{
          mb: '24px',
          display: 'flex',
          gap: '16px',
          alignItems: 'center',
        }}
      >
        <TextBoldNormal>Pickup information</TextBoldNormal>
        {transportationFormSaved ? (
          <ButtonLink
            sx={{
              display: 'flex',
              gap: '6px',
            }}
            onClick={() => {
              if (pickupEditMode) handleSubmit()
              setPickupEditMode(!pickupEditMode)
            }}
          >
            <img src={IconEdit} alt="" />
            {pickupEditMode ? 'Save' : 'Change'}
          </ButtonLink>
        ) : null}
      </Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            mb: '40px',
          }}
        >
          <Grid container spacing={'24px'}>
            <Grid item md={4} xs={12}>
              <Stack spacing={'16px'}>
                <DesktopDatePicker
                  disabled={!pickupEditMode}
                  label="Pickup Date"
                  inputFormat="DD/MM/YYYY"
                  mask="__/__/____"
                  minDate={!transportationFormSaved ? currentDate : ''}
                  value={pickupDate}
                  onChange={(newValue) => {
                    setPickupDate(newValue)
                  }}
                  renderInput={(params) => (
                    <TextFieldSmallEdible
                      {...params}
                      fullWidth
                      required
                      error={showErrors && validatePickupDate.hasErrors}
                      helperText={
                        showErrors && validatePickupDate.hasErrors
                          ? validatePickupDate.errorText
                          : ''
                      }
                    />
                  )}
                />
                <TimePicker
                  disabled={!pickupEditMode}
                  fullWidth
                  label="Pickup Time"
                  value={pickupTime}
                  onChange={(newValue) => {
                    setPickupTime(newValue)
                  }}
                  renderInput={(params) => (
                    <TextFieldSmallEdible
                      {...params}
                      required
                      fullWidth
                      error={showErrors && validatePickupTime.hasErrors}
                      helperText={
                        showErrors && validatePickupTime.hasErrors
                          ? validatePickupTime.errorText
                          : ''
                      }
                    />
                  )}
                />
                <TextFieldSmallEdible
                  disabled={!pickupEditMode}
                  fullWidth
                  required
                  label="Pickup Location"
                  value={pickupLocation}
                  onChange={(e) => {
                    setPickupLocation(e.target.value)
                  }}
                  error={showErrors && !pickupLocation}
                  helperText={showErrors && !pickupLocation ? 'This field can not be empty' : ''}
                />
              </Stack>
            </Grid>
            <Grid item md={4} xs={12}>
              <Stack spacing={'16px'}>
                <TextFieldSmallEdible
                  disabled={!pickupEditMode}
                  fullWidth
                  required
                  type="text"
                  label="Contact name"
                  value={sellerName}
                  onChange={(e) => {
                    setSellerName(e.target.value)
                  }}
                  error={showErrors && !sellerName}
                  helperText={showErrors && !sellerName ? 'This field can not be empty' : ''}
                />
                <TextFieldSmallEdible
                  disabled={!pickupEditMode}
                  fullWidth
                  required
                  label="Contact phone"
                  value={sellerPhone}
                  onChange={(e) => {
                    setSellerPhone(e.target.value.replace(/[^0-9+ ]/g, ''))
                  }}
                  error={showErrors && validateSellerPhone.hasErrors}
                  helperText={
                    showErrors && validateSellerPhone.hasErrors ? validateSellerPhone.errorText : ''
                  }
                />
                <TextFieldSmallEdible
                  disabled={!pickupEditMode}
                  fullWidth
                  required
                  type="email"
                  label="Contact email"
                  value={sellerEmail}
                  onChange={(e) => {
                    setSellerEmail(e.target.value)
                  }}
                  error={showErrors && validateSellerEmail.hasErrors}
                  helperText={
                    showErrors && validateSellerEmail.hasErrors ? validateSellerEmail.errorText : ''
                  }
                />
              </Stack>
            </Grid>
            <Grid item md={4} xs={12}>
              <Stack spacing={'16px'}>
                <TextFieldSmallEdible
                  disabled={!pickupEditMode}
                  fullWidth
                  required
                  label={'Mileage (km)'}
                  value={sumDelimiter(mileage)}
                  onChange={(e) => {
                    setMileage(e.target.value.replace(/[^0-9]/g, ''))
                  }}
                  error={showErrors && !mileage}
                  helperText={showErrors && !mileage ? 'This field can not be empty' : ''}
                />
                <TextFieldSmallEdible
                  disabled={!pickupEditMode}
                  fullWidth
                  label="Additional information"
                  multiline
                  rows={3}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '91px',
                    },
                  }}
                  value={additionalInfo}
                  onChange={(e) => {
                    setAdditionalInfo(e.target.value)
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            mb: '30px',
          }}
        >
          <Box
            sx={{
              mb: '24px',
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
            }}
          >
            <TextBoldNormal>Delivery information</TextBoldNormal>
            {transportationFormSaved ? (
              <ButtonLink
                sx={{
                  display: 'flex',
                  gap: '6px',
                }}
                onClick={() => {
                  if (deliveryEditMode) handleSubmit()
                  setDeliveryEditMode(!deliveryEditMode)
                }}
              >
                <img src={IconEdit} alt="" />
                {deliveryEditMode ? 'Save' : 'Change'}
              </ButtonLink>
            ) : null}
          </Box>
          <Grid container spacing={'24px'}>
            <Grid item md={4}>
              <Stack spacing={'16px'}>
                <DesktopDatePicker
                  disabled={!deliveryEditMode}
                  label="Delivery date"
                  inputFormat="DD/MM/YYYY"
                  mask="__/__/____"
                  minDate={currentDate}
                  value={deliveryDate}
                  onChange={(newValue) => {
                    setDeliveryDate(newValue)
                  }}
                  renderInput={(params) => (
                    <TextFieldSmallEdible
                      {...params}
                      fullWidth
                      required
                      error={showErrors && validateDeliveryDate.hasErrors}
                      helperText={
                        showErrors && validateDeliveryDate.hasErrors
                          ? validateDeliveryDate.errorText
                          : ''
                      }
                    />
                  )}
                />
                <TimePicker
                  disabled={!deliveryEditMode}
                  fullWidth
                  label="Delivery time"
                  value={deliveryTime}
                  onChange={(newValue) => {
                    setDeliveryTime(newValue)
                  }}
                  renderInput={(params) => (
                    <TextFieldSmallEdible
                      {...params}
                      required
                      fullWidth
                      error={showErrors && validateDeliveryTime.hasErrors}
                      helperText={
                        showErrors && validateDeliveryTime.hasErrors
                          ? validateDeliveryTime.errorText
                          : ''
                      }
                    />
                  )}
                />
                <TextFieldSmallEdible
                  disabled={!deliveryEditMode}
                  fullWidth
                  required
                  label="Delivery location"
                  value={deliveryLocation}
                  onChange={(e) => {
                    setDeliveryLocation(e.target.value)
                  }}
                  error={showErrors && !deliveryLocation}
                  helperText={showErrors && !deliveryLocation ? 'This field can not be empty' : ''}
                />
              </Stack>
            </Grid>
            <Grid item md={4}>
              <Stack spacing={'16px'}>
                <TextFieldSmallEdible
                  disabled={!deliveryEditMode}
                  fullWidth
                  required
                  type="text"
                  label="Contact name"
                  value={buyerName}
                  onChange={(e) => {
                    setBuyerName(e.target.value)
                  }}
                  error={showErrors && !buyerName}
                  helperText={showErrors && !buyerName ? 'This field can not be empty' : ''}
                />
                <TextFieldSmallEdible
                  disabled={!deliveryEditMode}
                  fullWidth
                  required
                  label="Contact phone"
                  value={buyerPhone}
                  onChange={(e) => {
                    setBuyerPhone(e.target.value.replace(/[^0-9+ ]/g, ''))
                  }}
                  error={showErrors && validateBuyerPhone.hasErrors}
                  helperText={
                    showErrors && validateBuyerPhone.hasErrors ? validateBuyerPhone.errorText : ''
                  }
                />
                <TextFieldSmall
                  disabled={!deliveryEditMode}
                  fullWidth
                  required
                  type="email"
                  label="Contact email"
                  value={buyerEmail}
                  onChange={(e) => {
                    setBuyerEmail(e.target.value)
                  }}
                  error={showErrors && validateBuyerEmail.hasErrors}
                  helperText={
                    showErrors && validateBuyerEmail.hasErrors ? validateBuyerEmail.errorText : ''
                  }
                />
              </Stack>
            </Grid>
            <Grid item md={4}>
              <TextFieldSmallEdible
                disabled={!deliveryEditMode}
                fullWidth
                label="Additional information"
                multiline
                rows={6}
                sx={{
                  '& .MuiInputBase-root': {
                    height: '143px',
                  },
                }}
                value={buyerAdditionalInfo}
                onChange={(e) => {
                  setBuyerAdditionalInfo(e.target.value)
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>
      {!transportationFormSaved ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <ButtonLG
            sx={{
              minWidth: '200px',
            }}
            onClick={() => {
              handleSubmit()
            }}
          >
            Save
          </ButtonLG>
        </Box>
      ) : null}
    </BoxShadowed>
  )
}

export default DealTransportationForm
