import React from 'react'
import { Stack } from '@mui/material'
import ModalWrap from '../../../../components/Modals/ModalWrap'
import TextH1 from '../../../../ui/Text/TextH1'
import ButtonHollow from '../../../../ui/Buttons/ButtonHollow'
import ButtonDanger from '../../../../ui/Buttons/ButtonDanger'

const DeleteApplicationModal = ({ open, setOpen, handleSubmit }) => {
  return (
    <ModalWrap open={open} setOpen={setOpen}>
      <Stack gap="30px">
        <TextH1>Are you sure you want to delete this customer?</TextH1>
        <Stack direction="row" gap="10px">
          <ButtonHollow fullWidth onClick={() => setOpen(false)}>
            Cancel
          </ButtonHollow>
          <ButtonDanger fullWidth onClick={() => handleSubmit()}>
            Delete
          </ButtonDanger>
        </Stack>
      </Stack>
    </ModalWrap>
  )
}

export default DeleteApplicationModal
