import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Pagination,
  TablePagination,
  Table,
  useMediaQuery,
} from '@mui/material'
import { API_ENDPOINTS, CDN_BASE_URL } from '../../../config/default'
import { privateRequest } from '../../../utils/request'
import { colorsConfig } from '../../../config/themeConfig'
import Preloader from '../../../components/Preloader'
import { OperatorTableHead, OperatorTableBody } from '../../OperatorPanel/OperatorTable'
import {
  appraisalsRequestsTableHeadCells,
  auctionsRequestsTableHeadCells,
} from '../ExpertPanelTableData'
import carDefaultPicture from '../../../assests/img/car-not-found.png'
import useAllCars from '../../../hooks/useAllCars'
import { getMarkerColor } from '../../../utils/getMarkerColor'

const ExpertAppraisalApplications = ({
  onTabChange,
  tab,
  appraisalsRequests: appraisalsRequestsArray,
}) => {
  const [sort, setSort] = useState({
    field: 0, // Field to sort by
    order: 'desc', // 'asc' or 'desc'
  })

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const maxWidth360 = useMediaQuery('(max-width: 360px)')

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [appraisalsRequests, setAppraisalsRequests] = useState([])
  const [auctionsRequests, setAuctionsRequests] = useState([])
  const [paginatedRequests, setPaginatedRequests] = useState([])

  const { cars, carWorkflowFilters } = useAllCars()

  const { filterByReadyForSale } = carWorkflowFilters

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  const sections = [
    {
      id: 1,
      name: `Waiting for appraisal (${appraisalsRequests.length})`,
    },
    {
      id: 2,
      name: `Appraised cars (${auctionsRequests.length})`,
    },
  ]

  function handleFormatDate(date, symbol) {
    const regex = /^(\d{4})-(\d{2})-(\d{2}).*$/

    const match = date.match(regex)

    const day = match[3]
    const month = match[2]
    const year = match[1]

    return `${day}${symbol}${month}${symbol}${year}`
  }

  const handleSetCarPhoto = (car) => {
    if (car && car.mainForeshortening && car.mainForeshortening.photo) {
      return `${CDN_BASE_URL}${car.mainForeshortening.photo.path}`
    } else {
      return carDefaultPicture
    }
  }

  const getAppraisalsRequests = () => {
    setLoading(true)

    const filteredArray = appraisalsRequestsArray.map((item) => {
      const {
        id,
        company: { name: companyName },
        make: { name: makeName },
        model: { name: modelName },
        vin,
        createdAt: date,
      } = item

      const cells = [
        !xsOnly && {
          type: 'text',
          value: id,
        },
        {
          type: 'image',
          value: handleSetCarPhoto(item),
        },
        {
          type: 'text-with-caption',
          value: `${makeName} ${modelName}`,
          caption: `VIN ${vin}`,
        },
        !xsOnly && {
          type: 'text',
          value: companyName,
        },
        {
          type: 'text',
          value: date,
        },
      ].filter(Boolean) // Remove undefined elements

      return {
        id: id,
        link: `/cars/${id}/appraisal`,
        date: date,
        cells: cells,
      }
    })
    setAppraisalsRequests(filteredArray)
  }

  const getAuctionsRequests = () => {
    setLoading(true)

    const auctionsRequestsArray = filterByReadyForSale().map((item) => {
      const {
        id,
        company: { name: companyName },
        make: { name: makeName },
        model: { name: modelName },
        vin,
        lastUpdatedAt: date,
      } = item

      const cells = [
        !xsOnly && {
          type: 'text',
          value: id,
        },
        {
          type: 'image',
          value: handleSetCarPhoto(item),
        },
        {
          type: 'text-with-caption',
          value: `${makeName} ${modelName}`,
          caption: `VIN ${vin}`,
        },
        !xsOnly && {
          type: 'text',
          value: companyName,
        },
        {
          type: 'text',
          value: date,
        },
      ].filter(Boolean) // Remove undefined elements

      return {
        id: id,
        link: `/cars/${id}`,
        date: date,
        cells: cells,
      }
    })

    setTimeout(() => setAuctionsRequests(auctionsRequestsArray), 0)
  }

  const getPaginatedData = (data, page, rowsPerPage) => {
    const sortedData = [...data]
    if (sort.field !== null) {
      sortedData.sort((a, b) => {
        const aValue = a.cells[sort.field].value
        const bValue = b.cells[sort.field].value

        if (isDateWithFormat(aValue) && isDateWithFormat(bValue)) {
          if (sort.order === 'asc') {
            return compareDateStrings(aValue, bValue)
          } else {
            return compareDateStrings(bValue, aValue)
          }
        }

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          if (sort.order === 'asc') {
            return aValue.localeCompare(bValue)
          } else {
            return bValue.localeCompare(aValue)
          }
        } else {
          // Handle comparison for non-string values
          if (sort.order === 'asc') {
            return aValue - bValue
          } else {
            return bValue - aValue
          }
        }
      })
    }

    const startIndex = (page - 1) * rowsPerPage
    const endIndex = startIndex + rowsPerPage
    return sortedData.slice(startIndex, endIndex)
  }

  const getTableHeadCells = () => {
    const getDataSliced = (data) =>
      xsOnly ? data.filter((item, index) => index !== 0 && index !== 3) : data
    switch (tab) {
      case 0:
        return getDataSliced(appraisalsRequestsTableHeadCells)
      case 1:
        return getDataSliced(auctionsRequestsTableHeadCells)
      default:
        return getDataSliced(appraisalsRequestsTableHeadCells)
    }
  }

  const getDataLength = () => {
    switch (tab) {
      case 0:
        return appraisalsRequests.length
      case 1:
        return auctionsRequests.length
      default:
        return 0
    }
  }

  const updatePagination = () => {
    setPage(1) // Reset page to 1 when changing tabs
  }

  useEffect(() => {
    // Update data length and paginated requests when switching tabs
    updatePagination()
  }, [tab, rowsPerPage, auctionsRequests, appraisalsRequests])

  const getCurrentData = () => {
    switch (tab) {
      case 0:
        return appraisalsRequests
      case 1:
        return auctionsRequests
      default:
        return []
    }
  }

  const getPaginatedRequests = () => {
    const sortedData = getPaginatedData(getCurrentData(), page, rowsPerPage)
    setPaginatedRequests(handleShowContent(sortedData))
  }

  useEffect(() => {
    getPaginatedRequests()
  }, [])

  useEffect(() => {
    getAppraisalsRequests()
  }, [appraisalsRequestsArray])

  useEffect(() => {
    if (cars.length > 0) {
      getAuctionsRequests()
    }
  }, [cars])

  useEffect(() => {
    setLoading(false)
  }, [loading])

  const hasRequestsOver24Hours = (tab) => {
    const requests = tab === 0 ? appraisalsRequests : tab === 1 ? auctionsRequests : null
    const currentTime = new Date()
    const twentyFourHoursAgo = new Date(currentTime.getTime() - 24 * 60 * 60 * 1000)

    return requests.some((request) => {
      const requestTime = new Date(request.cells[request.cells.length - 1].value)
      return requestTime < twentyFourHoursAgo
    })
  }

  const isDateWithFormat = (dateStr) => {
    const regex = /^(\d{2})\.(\d{2})\.(\d{4})$/
    return regex.test(dateStr)
  }

  const compareDateStrings = (dateStr1, dateStr2) => {
    const [day1, month1, year1] = dateStr1.split('.')
    const [day2, month2, year2] = dateStr2.split('.')

    if (year1 !== year2) {
      return parseInt(year1) - parseInt(year2)
    }

    if (month1 !== month2) {
      return parseInt(month1) - parseInt(month2)
    }

    return parseInt(day1) - parseInt(day2)
  }

  const handleShowContent = (data) => {
    const res = data.map((request) => {
      const modifiedCells = request.cells.map((cell, index) => {
        if (index === request.cells.length - 1) {
          return {
            ...cell,
            value: (
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  fontSize: xsOnly ? '14px' : '16px',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    marginTop: xsOnly ? '0px' : '1px',
                  }}
                >
                  {tab === 0 && getMarkerColor(request.date)}
                </Box>
                {handleFormatDate(cell.value, '.')}
              </Box>
            ),
          }
        }
        return cell
      })
      return {
        ...request,
        cells: modifiedCells,
      }
    })

    return res
  }

  const handleSortRequest = (field) => {
    if (sort.field === field) {
      setSort({
        ...sort,
        order: sort.order === 'asc' ? 'desc' : 'asc',
      })
    } else {
      setSort({
        field,
        order: 'asc',
      })
    }
  }

  const handleSortClick = (columnIndex, order) => {
    setSort({
      field: columnIndex,
      order: order,
    })
  }

  return (
    <Box
      sx={{
        py: xsOnly ? '17px' : '37px',
        px: xsOnly ? '16px' : '30px',
        borderRadius: '6px',
        boxShadow: '0px 0px 24px 0px rgba(0, 0, 0, 0.10)',
        width: '100%',
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: colorsConfig.mainBlack,
          fontSize: xsOnly ? '24px' : '40px',
          fontWeight: 700,
          marginRight: '20px',
          marginBottom: xsOnly ? '15px' : '25px',
          width: 1,
        }}
      >
        Appraisal applications
      </Typography>
      {loading ? (
        <Preloader type="gooey" text={'Loading the requests...'} />
      ) : (
        <>
          {xsOnly ? null : (
            <Box
              sx={{
                display: 'flex',
                marginBottom: '20px',
                justifyContent: 'flex-end',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '24px',
                  '& .MuiBox-root': {
                    display: 'flex',
                    gap: '6px',
                    alignItems: 'center',
                    '& .MuiTypography-root': {
                      whiteSpace: 'nowrap',
                    },
                  },
                }}
              >
                <Box>
                  {getMarkerColor('', '#4DED6D', '#3CC457', true)}
                  <Typography>—New request</Typography>
                </Box>
                <Box>
                  {getMarkerColor('', '#F5BD4F', '#D69E3C', true)}
                  <Typography>—Approaching the deadline</Typography>
                </Box>
                <Box>
                  {getMarkerColor('', '#AC0000', '#B83629', true)}
                  <Typography>—Action required</Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Tabs
            value={tab}
            onChange={(e, newVal) => {
              onTabChange(newVal)
            }}
            variant="scrollable"
            sx={{
              position: 'relative',
              marginBottom: xsOnly ? '20px' : '40px',
              width: xsOnly ? 'calc(100% - 40px)' : '100%',
              '&.MuiTabs-root': {
                marginRight: xsOnly ? '0px' : '40px',
                width: maxWidth360 ? '245px' : xsOnly ? '320px' : '100%',
              },
              '& .MuiTabs-indicator': {
                background: colorsConfig.mainGreen,
              },
              '& .MuiTab-textColorPrimary': {
                color: colorsConfig.mainBlack,
                textTransform: 'none',
                fontFamily: '"Helvetica", sans-serif',
                fontSize: '16px',
                fontWeight: 500,
                paddingLeft: '15px',
              },
              '& .Mui-selected': {
                color: colorsConfig.mainBlack,
              },
              '& .MuiButtonBase-root': {
                background: colorsConfig.bgLightBlue,
                fontSize: '10px',
                '& div': {
                  fontSize: xsOnly ? '12px' : '16px',
                },
              },
              ' .MuiButtonBase-root': {
                height: '41px',
                padding: xsOnly ? '0 15px' : '0 30px',
              },
              '& .MuiTabScrollButton-root': {
                height: '48px',
              },
              '& .MuiTouchRipple-root': {
                height: '48px',
              },
              '& .MuiTabs-scrollButtonsHideMobile': {
                display: xsOnly && 'flex',
              },
            }}
          >
            {sections.map((section, index) => {
              return (
                <Tab
                  key={index}
                  label={
                    <div>
                      {section.name}
                      {index === 0 && hasRequestsOver24Hours(index) && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '8px',
                            right: xsOnly ? '3px' : '17px',
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                          >
                            <circle cx="5" cy="5" r="5" fill="#AC0000" />
                          </svg>
                        </Box>
                      )}
                    </div>
                  }
                  sx={{
                    fontSize: '13px',
                  }}
                />
              )
            })}
          </Tabs>
          <Box
            sx={{
              '& .MuiPaper-root': {
                marginBottom: '30px',
              },
              '& .MuiAccordionSummary-gutters': {
                height: '69px',
              },
            }}
          >
            <Box
              sx={{
                marginBottom: xsOnly ? '20px' : '40px',
                '& .MuiTableCell-root': {
                  padding: '15px 7px',
                },
              }}
            >
              {tab === 0 && (
                <Table>
                  <OperatorTableHead
                    tableCells={getTableHeadCells()}
                    sort={sort}
                    setSort={setSort}
                    onSortRequest={handleSortRequest}
                    handleSortClick={handleSortClick}
                  />
                  <OperatorTableBody
                    content={handleShowContent(
                      getPaginatedData(appraisalsRequests, page, rowsPerPage),
                    )}
                  />
                </Table>
              )}
              {tab === 1 && (
                <Table>
                  <OperatorTableHead
                    tableCells={getTableHeadCells()}
                    sort={sort}
                    setSort={setSort}
                    onSortRequest={handleSortRequest}
                    handleSortClick={handleSortClick}
                  />
                  <OperatorTableBody
                    content={handleShowContent(
                      getPaginatedData(auctionsRequests, page, rowsPerPage),
                    )}
                  />
                </Table>
              )}
            </Box>
            <Box
              sx={{
                '& .MuiToolbar-root': {
                  paddingLeft: 0,
                  display: 'flex',
                  flexWrap: xsOnly ? 'wrap' : '',
                  gap: xsOnly ? '10px 0' : '',
                },
                '& .MuiTablePagination-spacer': {
                  display: 'none',
                },
                '& .MuiTablePagination-displayedRows': {
                  display: xsOnly ? 'none' : 'flex',
                  flexGrow: 1,
                  justifyContent: 'flex-end',
                  fontSize: '12px',
                },
                '& .MuiInputBase-root': {
                  order: -1,
                  margin: 'auto 10px',
                  fontSize: '12px',
                  '& .MuiSvgIcon-root': {
                    fontSize: '16px',
                    marginRight: '10px',
                  },
                  background: 'white',
                  border: `1px solid ${colorsConfig.bgLightBlue}`,
                  borderRadius: '4px',
                  padding: '7px 15px 7px 13px',
                },
                '& .MuiPagination-root': {
                  marginLeft: xsOnly ? 'auto' : '12px',
                },
                '& .MuiPaginationItem-previousNext': {
                  padding: '8px 12px',
                  '& .MuiSvgIcon-root': {
                    display: 'none',
                  },
                  "&[aria-label='Go to previous page']": {
                    '&::before': {
                      content: "'Previous'",
                    },
                  },
                  "&[aria-label='Go to next page']": {
                    '&::before': {
                      content: "'Next'",
                    },
                  },
                  '&.Mui-disabled': {
                    opacity: 1,
                  },
                },
                '& .MuiPaginationItem-root': {
                  background: colorsConfig.bgLightBlue,
                  border: 'none',
                  fontSize: '12px',

                  '&.MuiPaginationItem-previousNext': {
                    display: xsOnly && 'none',
                    background: 'white',
                    border: `1px solid ${colorsConfig.bgLightBlue}`,
                  },
                },
                '& .MuiPaginationItem-page.Mui-selected': {
                  background: colorsConfig.mainBlue,
                  color: 'white',
                  '&:hover': {
                    background: 'rgb(21, 101, 192)',
                  },
                },
                '& .MuiTablePagination-selectLabel': {
                  fontSize: '12px',
                },
                '& .MuiTablePagination-select': {
                  // paddingRight: '18px',
                },
                '& .MuiSelect-select': {
                  padding: 0,
                  paddingRight: '12px',
                  '&:focus': {
                    background: 'none',
                  },
                },
              }}
            >
              <TablePagination
                component="div"
                count={getDataLength()}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton={false}
                showLastButton={false}
                ActionsComponent={() => (
                  <>
                    <p
                      className="MuiTablePagination-selectLabel css-pdct74-MuiTablePagination-selectLabel"
                      id=":rh:"
                      style={{
                        display: 'flex',
                        order: -2,
                      }}
                    >
                      Show
                    </p>
                    <Pagination
                      count={Math.ceil(getDataLength() / rowsPerPage)}
                      variant="outlined"
                      shape="rounded"
                      page={page}
                      onChange={(event, newPage) => handleChangePage(event, newPage)}
                    />
                  </>
                )}
                labelRowsPerPage="entries"
                labelDisplayedRows={({ count }) => {
                  const from = (page - 1) * rowsPerPage + 1
                  const to = Math.min(page * rowsPerPage, getDataLength())

                  if (!count) return `Show 0 entries`
                  return `Show ${from}-${to} of ${count} entries`
                }}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}

export default ExpertAppraisalApplications
