export const appraisalServiceActions = [
  {
    title: 'Start appraisal on-site',
    translation: 'button_start_appraisal_on_site',
    conditions: [
      {
        role: 'COMPANY',
        status: 'car_workflow.new',
      },
    ],
    actions: [
      {
        type: 'navigate',
        link: '/cars/:id/appraisal',
      },
    ],
  },
  {
    title: 'Edit appraisal',
    translation: 'button_edit_appraisal',
    conditions: [
      {
        role: 'COMPANY',
        status: 'car_workflow.appraisal_on_site',
      },
      {
        role: 'COMPANY',
        status: 'car_workflow.quality_check_failed',
      },
    ],
    actions: [
      {
        type: 'navigate',
        link: '/cars/:id/appraisal',
      },
    ],
  },
  {
    title: 'Edit information',
    translation: 'button_edit_information',
    conditions: [
      {
        role: 'COMPANY',
        status: 'car_workflow.new',
      },
      {
        role: 'COMPANY',
        status: 'car_workflow.appraisal_on_site',
      },
      {
        role: 'COMPANY',
        status: 'car_workflow.quality_check_failed',
      },
    ],
    actions: [
      {
        type: 'navigate',
        link: '/cars/:id/edit',
      },
    ],
  },
  {
    title: 'Send to our compound',
    translation: 'button_send_to_our_compound',
    conditions: [
      {
        role: 'COMPANY',
        status: 'car_workflow.new',
      },
      {
        role: 'COMPANY',
        status: 'car_workflow.appraisal_on_site',
      },
      // {
      //     role: 'COMPANY',
      //     status: 'car_workflow.moderation_failed'
      // },
    ],
    actions: [
      {
        type: 'modal',
        modalName: 'send_to_compound',
      },
    ],
  },
  {
    title: 'Submit to quality check',
    translation: 'button_submit_to_quality_check',
    conditions: [
      {
        role: 'COMPANY',
        status: 'car_workflow.appraisal_on_site',
      },
    ],
    specialConditions: 'appraisalChecks',
    actions: [
      {
        type: 'modal',
        modalName: 'quality_check',
      },
      // {
      //     type: 'extendedWorkflowTransition',
      //     link: `/cars/:id${API_ENDPOINTS.completeOnSiteAppraisal}`,
      //     newStatus: 'Quality check'
      // }
    ],
  },
  {
    title: 'Submit to quality check',
    translation: 'button_submit_to_quality_check',
    conditions: [
      {
        role: 'COMPANY',
        status: 'car_workflow.moderation_failed',
      },
    ],
    actions: [
      // {
      //     type: 'extendedWorkflowTransitionMulti',
      //     link: `/cars/:id${API_ENDPOINTS.startOnSiteAppraisal}`,
      //     link2: `/cars/:id${API_ENDPOINTS.completeOnSiteAppraisal}`,
      //     newStatus: 'Quality check'
      // }
      {
        type: 'modal',
        modalName: 'quality_check_failed',
      },
    ],
  },
  {
    title: 'Start auction',
    translation: 'button_start_auction',
    conditions: [
      {
        role: 'COMPANY',
        status: 'car_workflow.ready_for_sale',
      },
    ],
    actions: [
      {
        type: 'modal',
        modalName: 'start_auction',
        status: 'car_workflow.ready_for_sale',
      },
    ],
  },
  {
    title: 'Reschedule auction',
    translation: 'button_reschedule_auction',
    conditions: [
      {
        role: 'COMPANY',
        status: 'auction_workflow.pending',
      },
    ],
    actions: [
      {
        type: 'modal',
        modalName: 'start_auction',
        status: 'auction_workflow.pending',
      },
    ],
  },
  {
    title: 'Finance',
    translation: 'button_finance',
    conditions: [
      {
        role: 'COMPANY',
        status: 'car_workflow.new',
      },
      {
        role: 'COMPANY',
        status: 'car_workflow.ready_for_sale',
      },
    ],
    actions: [
      {
        type: 'navigate',
        link: '/cars/:id?service=Finance',
      },
    ],
  },
  {
    title: 'Archive',
    translation: 'button_archive',
    conditions: [
      {
        role: 'COMPANY',
        status: 'car_workflow.new',
      },
      {
        role: 'COMPANY',
        status: 'car_workflow.appraisal_on_site',
      },
      {
        role: 'COMPANY',
        status: 'car_workflow.moderation_failed',
      },
      {
        role: 'COMPANY',
        status: 'car_workflow.ready_for_sale',
      },
      {
        role: 'COMPANY',
        status: 'car_workflow.sale_succeed',
      },
    ],
    actions: [
      {
        type: 'archive',
        link: '/cars/:id/archive',
      },
    ],
  },
  {
    title: 'Finish quality check',
    conditions: [
      {
        role: 'OPERATOR',
        status: 'car_workflow.quality_check',
      },
    ],
    actions: [
      {
        type: 'navigate',
        link: '/cars/:id',
      },
    ],
  },
]
