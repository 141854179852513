import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { UISize } from '../../utils/constants/ui.constants'

const UIPreloaderCircular = ({ sx, size = UISize.md, ...props }) => {
  const sizes = {
    [UISize.md]: 60,
    [UISize.xs]: 16,
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        ...sx,
      }}
      {...props}
    >
      <CircularProgress
        size={sizes[size]}
        sx={{
          '.css-176wh8e-MuiCircularProgress-circle': {
            color: colorsConfig.mainBlue,
          },
          margin: '0 8px',
        }}
      />
    </Box>
  )
}

export default UIPreloaderCircular
