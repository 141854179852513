import React from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { colorsConfig } from '../../config/themeConfig'
import { FONT } from '../../utils/constants/ui.constants'

const UIButtonLink = ({ text, children, sx, ...props }) => {
  const { t } = useTranslation()

  return (
    <Button
      component="a"
      disableRipple
      sx={{
        display: 'inline-flex',
        px: 0,
        py: 0,
        background: 'none',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: 'normal',
        fontFamily: FONT.PRIMARY,
        color: colorsConfig.mainBlue,
        transition: '0.25s all ease-in-out',
        verticalAlign: 'baseline',
        minWidth: 'auto',
        minHeight: 'auto',
        '&:hover': {
          background: 'none',
          color: colorsConfig.buttonHover,
          transition: '0.25s all ease-in-out',
        },
        ...sx,
      }}
      {...props}
    >
      {text ? t(text) : children}
    </Button>
  )
}

export default UIButtonLink
