import React from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { colorsConfig } from '../../config/themeConfig'

const UIBreadcrumbButton = ({ icon, link, text, prefixIcon, sx, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Button
      className={!link ? 'button-link-disabled' : ''}
      sx={{
        textTransform: 'none',
        fontFamily: '"Helvetica", sans-serif',
        fontSize: '14px',
        color: colorsConfig.lightGray,
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        transition: '0.25s all ease-in-out',
        '& svg': {
          color: colorsConfig.lightGray,
          fontSize: '20px',
          transition: '0.25s all ease-in-out',
        },
        '&:hover': {
          color: colorsConfig.mainBlue,
          transition: '0.25s all ease-in-out',
          '& svg': {
            color: colorsConfig.mainBlue,
            transition: '0.25s all ease-in-out',
          },
        },
        '&.button-link-disabled': {
          cursor: 'auto',
          '&:hover': {
            color: colorsConfig.lightGray,
            background: 'none',
            transition: '0.25s all ease-in-out',
            '& svg': {
              color: colorsConfig.lightGray,
              transition: '0.25s all ease-in-out',
            },
          },
          '&:focus': {
            background: 'none',
          },
        },
      }}
      disableRipple={!link}
      onClick={() => {
        if (link) navigate(link)
      }}
    >
      {prefixIcon ?? null}
      {t(text)}
    </Button>
  )
}

export default UIBreadcrumbButton
