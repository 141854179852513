import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import { getCoinPricings } from '../../../store/features/coinPricings/coinPricings.actions'
import UIPreloader from '../../../ui/UIPreloader/UIPreloader'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import CoinPricingsItem from './CoinPricingsItem'
import { excludeCoinPricings } from './coinPricings.helpers'

const CoinPricings = () => {
  const { isLoading, coinPricings } = useSelector((state) => state.coinPricings)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setCurrentPageTitle('Coin pricings'))
    dispatch(getCoinPricings())
  }, [])

  return (
    <BoxShadowed sx={{ position: 'relative' }}>
      {isLoading ? <UIPreloader /> : null}
      {excludeCoinPricings(coinPricings).map((item) => {
        return <CoinPricingsItem key={item?.['@id']} data={item} />
      })}
    </BoxShadowed>
  )
}

export default CoinPricings
