import React from 'react'
import { FormControl, FormHelperText, InputLabel } from '@mui/material'
import PhoneInput from 'react-phone-input-2'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import UITextBodySmall from '../../../../ui/UIText/UITextBodySmall'
import { colorsConfig } from '../../../../config/themeConfig'

const FormPhoneNumber = ({ required, label, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers
  const { t } = useTranslation()

  const handleChange = (value) => {
    setValue(value)
  }
  return (
    <FormControl
      required={required}
      error={meta.error && meta.touched}
      variant="outlined"
      fullWidth
      sx={{
        '& .MuiFormLabel-asterisk': {
          color: colorsConfig.mainRed,
        },
      }}
    >
      <InputLabel shrink htmlFor="phone-input">
        {t(label)}
      </InputLabel>
      <PhoneInput
        required={required}
        country="ch"
        containerStyle={{
          border: 'none',
        }}
        inputStyle={{
          border: 'none',
          height: '37.13px',
          width: '100%',
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, .1)',
        }}
        buttonStyle={{
          background: 'white',
          border: 'none',
        }}
        value={field.value}
        onChange={handleChange}
        {...props}
      />
      {meta.error && meta.touched ? (
        <FormHelperText>
          <UITextBodySmall
            sx={{ color: colorsConfig.mainRed, fontSize: '12px' }}
            text={meta.error}
          />
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}

export default FormPhoneNumber
