import { getCompany } from '../company.actions'

export default (builder) => {
  builder
    .addCase(getCompany.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getCompany.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = null
      state.data = action.payload
    })
    .addCase(getCompany.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
}
