import { adminRequest } from '../../utils/request'

class ProductProvidersApiService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      list: '/reference/product-provider/product-providers',
      instance: (id) => `/reference/product-provider/product-providers/${id}`,
    }
  }

  getDealerProducts = async (params) => {
    const response = await this.#api.get(this.#endPoints.list, {
      params,
    })
    return response
  }

  getProductProvider = async (productProviderId) => {
    const response = await this.#api.get(this.#endPoints.instance(productProviderId))
    return response
  }

  getProductProviderByIdUrl = async (idUrl) => {
    const response = await this.#api.get(idUrl)
    return response
  }
}

const productProvidersApiService = new ProductProvidersApiService({ api: adminRequest })

export default productProvidersApiService
