import { iubendaApi } from '../api'

class IubendaApiService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      privacyPolicy: {
        de: '/privacy-policy/96872507',
        en: '/privacy-policy/86965446',
        fr: '/privacy-policy/30744296',
        it: '/privacy-policy/88779045',
      },
      cookiePolicy: {
        de: '/privacy-policy/96872507/cookie-policy',
        en: '/privacy-policy/86965446/cookie-policy',
        fr: '/privacy-policy/30744296/cookie-policy',
        it: '/privacy-policy/88779045/cookie-policy',
      },
    }
  }

  getPrivacyPolicy = async (locale = 'en') => {
    const response = await this.#api.get(this.#endPoints.privacyPolicy[locale])
    return response
  }

  getCookiePolicy = async (locale = 'en') => {
    const response = await this.#api.get(this.#endPoints.cookiePolicy[locale])
    return response
  }
}

const iubendaApiService = new IubendaApiService({ api: iubendaApi })

export default iubendaApiService
