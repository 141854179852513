import React from 'react'
import { Box, IconButton } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import IconCheckbox from '../../assests/img/icons/icon-checkbox.svg'

const UICheckboxPlain = ({ value, setValue, sx, ...props }) => {
  return (
    <IconButton
      sx={{
        width: '24px',
        height: '24px',
        p: 0,
        borderRadius: '6px',
        ...sx,
      }}
      onClick={() => {
        setValue(!value)
      }}
    >
      <Box
        sx={{
          border: '1px solid',
          borderColor: colorsConfig.mainBlack,
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: '24px',
          height: '24px',
        }}
      >
        <Box
          className={value ? 'active' : ''}
          sx={{
            opacity: 0,
            transform: 'scale(0)',
            transition: '0.25s all ease-in-out',
            '&.active': {
              transform: 'scale(1)',
              opacity: 1,
              transition: '0.25s all ease-in-out',
            },
          }}
        >
          <img src={IconCheckbox} style={{ width: '15px' }} alt="" />
        </Box>
      </Box>
    </IconButton>
  )
}

export default UICheckboxPlain
