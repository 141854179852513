import React from 'react'
import { Box } from '@mui/material'
import SquareCounterWithPopover from '../../../components/SquareCounterWithPopover'

const InsuranceTableQuotes = ({ success, attention, danger }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '14px',
      }}
    >
      <SquareCounterWithPopover className="success" text="Accepted" count={success} />
      <SquareCounterWithPopover className="attention" text="Request info" count={attention} />
      <SquareCounterWithPopover className="danger" text="Refused" count={danger} />
    </Box>
  )
}

export default InsuranceTableQuotes
