import { createAsyncThunk } from '@reduxjs/toolkit'
import warrantyApplicationsService from '../../../api/warrantyApplicationsService/warrantyApplicationsApiService'
import { toast } from 'react-toastify'
import carService from '../../../api/carService/carApiService'
import filesService from '../../../api/filesService/filesApiService'
import { downloadFileFromPrivateBucket } from '../../../utils/downloadFile'
import { getApiError } from '../../../api/api'

export const getWarrantyApplication = createAsyncThunk(
  'warrantyApplication/getWarrantyApplication',
  async (_, { rejectWithValue, getState }) => {
    try {
      const car = getState().car?.car?.['@id']
      const response = await warrantyApplicationsService.getApplicationsByCar(car)
      return response.data['hydra:member']?.[0]
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e)
    }
  },
)

export const createWarrantyApplication = createAsyncThunk(
  'warrantyApplication/createWarrantyApplication',
  async (
    { car, independentCarInfoId, independentCarInfo, applicationData },
    { rejectWithValue },
  ) => {
    try {
      await carService.updateIndependentCarInfo(independentCarInfoId, independentCarInfo)
      const response = await warrantyApplicationsService.createApplication(applicationData)
      const applicationId = response.data?.id
      await warrantyApplicationsService.confirmToApplicationPreparation(applicationId)
      return response.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)

export const calculateWarrantyProducts = createAsyncThunk(
  'warrantyApplication/calculateWarrantyProducts',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await warrantyApplicationsService.calculateWarrantyProducts(data)
      return response.data
    } catch (e) {
      if (!e.response) throw e
      toast.error(e.response?.data?.['hydra:description'])
      return rejectWithValue(e.response?.data?.['hydra:description'])
    }
  },
)

export const activateWarranty = createAsyncThunk(
  'warrantyApplication/activateWarranty',
  async ({ applicationData, selectedProductData }, { rejectWithValue, getState }) => {
    try {
      const warrantyApplication = getState().warrantyApplication.warrantyApplication
      await Promise.all([
        warrantyApplicationsService.updateApplication(
          warrantyApplication?.['@id'],
          applicationData,
        ),
        warrantyApplicationsService.selectProduct(warrantyApplication?.id, selectedProductData),
      ])
      await warrantyApplicationsService.confirmToIssued(warrantyApplication?.id)
      await warrantyApplicationsService.sendInvoiceIssuedNotification(warrantyApplication?.id)
      const response = await warrantyApplicationsService.reissueWarranty(warrantyApplication?.id)
      return response?.data
    } catch (e) {
      if (!e.response) throw e
      toast.error(e.response?.data?.['hydra:description'])
      return rejectWithValue(e.response?.data?.['hydra:description'])
    }
  },
)

export const getWarrantyCertificate = createAsyncThunk(
  'warrantyApplication/getWarrantyCertificate',
  async (_, { rejectWithValue, getState }) => {
    try {
      const car = getState().car?.car
      const warrantyApplication = getState().warrantyApplication.warrantyApplication
      if (!warrantyApplication) return null
      let warrantyCertificateFileId = warrantyApplication?.warrantyCertificate?.id
      let reissueData = null
      if (!warrantyCertificateFileId) {
        reissueData = await warrantyApplicationsService.reissueWarranty(warrantyApplication?.id)
        warrantyCertificateFileId = reissueData.data?.warrantyCertificate?.id
      }
      const response = await filesService.getPrivateFile(warrantyCertificateFileId)
      const certificateName = `${car.vin}_${reissueData?.data?.warrantyNumber ?? warrantyApplication?.warrantyNumber}`
      downloadFileFromPrivateBucket(response.data?.signedUrl, certificateName)
      return { signedUrl: response.data?.signedUrl, updateApplication: reissueData?.data }
    } catch (e) {
      if (!e.response) throw e
      toast.error(e.response?.data?.['hydra:description'])
      return rejectWithValue(e.response?.data?.['hydra:description'])
    }
  },
)

export const getWarrantyInvoice = createAsyncThunk(
  'warrantyApplication/getWarrantyInvoice',
  async (_, { rejectWithValue, getState }) => {
    try {
      const car = getState().car?.car
      const invoiceName = `Car Warranty Invoice: ${car?.make?.name.replace('.', '_')} ${car?.model?.name.replace('.', '_')}`
      const warrantyApplication = getState().warrantyApplication.warrantyApplication
      if (!warrantyApplication) return null
      let invoiceFileId = warrantyApplication?.invoice?.file?.id
      let reissueData = null
      if (!invoiceFileId) {
        reissueData = await warrantyApplicationsService.reissueWarranty(warrantyApplication?.id)
        invoiceFileId = reissueData.data?.invoice?.file?.id
      }
      const response = await filesService.getPrivateFile(invoiceFileId)
      downloadFileFromPrivateBucket(response.data?.signedUrl, invoiceName)
      return { signedUrl: response.data?.signedUrl, updateApplication: reissueData?.data }
    } catch (e) {
      if (!e.response) throw e
      toast.error(e.response?.data?.['hydra:description'])
      return rejectWithValue(e.response?.data?.['hydra:description'])
    }
  },
)
