import React, { useEffect } from 'react'
import { Stack } from '@mui/material'
import { AddRounded, BusinessRounded, PeopleRounded } from '@mui/icons-material'
import UIBreadcrumbs from '../../../../ui/UIBreadcrumbs/UIBreadcrumbs'
import { useDispatch } from 'react-redux'
import { setCurrentPageTitle } from '../../../../store/features/authSlice'
import AddManagerForm from '../../../../components/Forms/FormTemplates/Admin/AddManagerForm/AddManagerForm'

const AddManager = () => {
  const dispatch = useDispatch()
  dispatch(setCurrentPageTitle('Add a new manager'))

  return (
    <Stack gap="30px">
      <UIBreadcrumbs
        pages={[
          {
            name: 'Managers',
            icon: <PeopleRounded />,
            link: '/admin/users',
          },
          {
            name: 'Add a new manager',
            icon: <AddRounded />,
          },
        ]}
      />
      <AddManagerForm />
    </Stack>
  )
}

export default AddManager
