import { privateRequest } from '../../utils/request'

class WorkflowsService {
  #request

  #endPoints

  constructor({ request }) {
    this.#request = request
    this.#endPoints = {
      insuranceApplications: '/insurance-application-workflow-states',
    }
  }

  getInsuranceApplicationWorkflowStates = async () => {
    const response = await this.#request.get(this.#endPoints.insuranceApplications, {
      params: {
        pagination: false,
      },
    })
    return response
  }
}

const workflowsService = new WorkflowsService({ request: privateRequest })

export default workflowsService
