import React, { useEffect, useState } from 'react'
import ModalWrap from '../../../../components/Modals/ModalWrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCompanies } from '../../../../store/features/companiesSlice/companies.actions'
import UIPreloader from '../../../../ui/UIPreloader/UIPreloader'
import UITextHeadlineSmall from '../../../../ui/UIText/UITextHeadlineSmall'
import { Autocomplete, Stack } from '@mui/material'
import { originTypes } from '../../../../config/formsConfig'
import { setCarMainData } from '../../../../store/features/addNewCarSlice/addNewCar.reducer'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import ButtonHollow from '../../../../ui/Buttons/ButtonHollow'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import {
  attachPolicyToDealer,
  getPoliciesStatusCount,
  getUnlinkedPolicies,
} from '../../../../store/features/rviOperator/rviOperator.actions'
import { toast } from 'react-toastify'

const LinkDealerModal = ({ open, setOpen, policy }) => {
  const isLoadingCompanies = useSelector((state) => state.companies.allCompanies.isLoading)
  const companies = useSelector((state) => state.companies.allCompanies.data)
  const isLoading = useSelector((state) => state.rviOperator.attachPolicy.isLoading)

  const [company, setCompany] = useState(null)

  const dispatch = useDispatch()

  const handleLinkPolicyToDealer = async () => {
    const data = {
      company: company?.value?.['@id'],
      policies: [policy?.['@id']],
    }
    const res = await dispatch(attachPolicyToDealer({ data }))
    if (attachPolicyToDealer.fulfilled.match(res)) {
      dispatch(getUnlinkedPolicies())
      dispatch(getPoliciesStatusCount())
      toast.success(`Policy has been linked to ${company?.label}!`)
      setCompany(null)
      setOpen(false)
    }
  }

  useEffect(() => {
    if (open) {
      dispatch(getAllCompanies())
    }
  }, [open])

  return (
    <ModalWrap wrapperStyles={{ maxWidth: '500px' }} open={open} setOpen={setOpen}>
      {isLoadingCompanies || isLoading ? <UIPreloader /> : null}
      <Stack gap="20px">
        <UITextHeadlineSmall
          sx={{
            fontSize: {
              xs: '20px',
              md: '32px',
            },
          }}
          text="Link the policy to a dealer"
        />
        <Autocomplete
          fullWidth
          id="car-origin"
          options={companies.map((item) => ({
            value: item,
            label: item?.name,
          }))}
          value={company}
          onChange={(e, newVal) => {
            setCompany(newVal)
          }}
          renderInput={(params) => (
            <TextFieldSmallEdible {...params} isAutocomplete label="Select dealer" required />
          )}
        />
        <Stack direction={{ xs: 'column', md: 'row' }} gap="10px">
          <ButtonHollow sx={{ flex: 1 }} onClick={() => setOpen(false)}>
            Cancel
          </ButtonHollow>
          <ButtonLG disabled={!company} sx={{ flex: 1 }} onClick={() => handleLinkPolicyToDealer()}>
            Confirm
          </ButtonLG>
        </Stack>
      </Stack>
    </ModalWrap>
  )
}

export default LinkDealerModal
