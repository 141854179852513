import React from 'react'
import { Box, Modal, useMediaQuery } from '@mui/material'
import ThumbsUp from '../../assests/img/animations/bidding.gif'
import TextH1 from '../../ui/Text/TextH1'

const AnimationBiddingModal = (props) => {
  const { open, setOpen } = props

  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 584,
          height: 213,
          background: 'white',
          borderRadius: '10px',
          padding: '30px',
          maxHeight: '80vh',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          '&.xs-only': {
            width: 'calc(100vw - 30px)',
            flexDirection: 'column',
            gap: '20px',
            height: 'auto',
          },
        }}
        className={['disable-scrollbar', xsOnly ? 'xs-only' : ''].join(' ')}
      >
        <img style={{ width: '152px' }} src={ThumbsUp} alt="" />
        <TextH1
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            whiteSpace: 'nowrap',
            '&.xs-only': {
              fontSize: '24px',
              textAlign: 'center',
            },
          }}
        >
          Your bid updated <br />
          successfully
        </TextH1>
      </Box>
    </Modal>
  )
}

export default AnimationBiddingModal
