import React, { createRef, useEffect, useState } from 'react'
import { Box, IconButton, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../../../config/themeConfig'
import { NavigateNextRounded } from '@mui/icons-material'
import Preloader from '../../../Preloader'
import Flicking from '@egjs/react-flicking'
import FormTermsSelectItem from './FormTermsSelectItem'

const FormTermsSelect = ({ slides, isLoading, onChange, selectedTerm, disabled, ...props }) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')
  const [currentIndex, setCurrentIndex] = useState(0)

  const flicking = createRef()

  return (
    <Box
      className={[
        xsOnly ? 'xs-only' : '',
        tabletOnly ? 'tablet-only' : '',
        disabled ? 'disabled' : '',
      ].join(' ')}
      sx={{
        width: 'calc(100vw - 510px)',
        position: 'relative',
        '&.xs-only': {
          width: 'calc(100% + 40px)',
          ml: '-20px',
        },
        '&.tablet-only': {
          width: 'calc(100vw - 32px)',
        },
        '&.disabled': {
          opacity: 0.5,
          pointerEvents: 'none',
        },
      }}
    >
      {slides.length > 5 && !xsOnly && !tabletOnly ? (
        <>
          <IconButton
            sx={{
              position: 'absolute',
              top: '50%',
              mt: '-15px',
              right: '-30px',
              height: '30px',
              width: '30px',
              borderRadius: '50%',
              opacity: 0.5,
              background: colorsConfig.mainBlack,
              transition: '0.25s all ease-in-out',
              '&:hover': {
                opacity: 0.8,
                background: colorsConfig.mainBlack,
                transition: '0.25s all ease-in-out',
              },
            }}
            disabled={currentIndex >= 1}
            onClick={() => {
              flicking.current.next()
            }}
          >
            <NavigateNextRounded
              sx={{
                fill: 'white',
                fontSize: '26px',
              }}
            />
          </IconButton>
          <IconButton
            sx={{
              position: 'absolute',
              top: '50%',
              mt: '-15px',
              left: '-30px',
              height: '30px',
              width: '30px',
              borderRadius: '50%',
              opacity: 0.5,
              background: colorsConfig.mainBlack,
              transition: '0.25s all ease-in-out',
              '&:hover': {
                opacity: 0.8,
                background: colorsConfig.mainBlack,
                transition: '0.25s all ease-in-out',
              },
            }}
            disabled={currentIndex === 0}
            onClick={() => {
              flicking.current.prev()
            }}
          >
            <NavigateNextRounded
              sx={{
                fill: 'white',
                fontSize: '26px',
                transformOrigin: '50% 50%',
                transform: 'rotate(180deg)',
              }}
            />
          </IconButton>
        </>
      ) : null}
      {isLoading ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'white',
            opacity: 0.5,
            zIndex: 2,
          }}
        >
          <Preloader type={'gooey'} />
        </Box>
      ) : null}
      <Flicking
        panelsPerView={xsOnly ? 1 : 5}
        circular={xsOnly}
        ref={flicking}
        align={{
          camera: 0,
          panel: 0,
        }}
        onChanged={(e) => {
          setCurrentIndex(e.index)
        }}
      >
        {slides.map((item, index) => {
          return (
            <Box
              key={index}
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                px: '10px',
                py: '10px',
                position: 'relative',
                zIndex: 3,
                '&.xs-only': {
                  width: '142px',
                },
              }}
            >
              <FormTermsSelectItem
                slide={item}
                onSelectSlide={onChange}
                selectedTerm={selectedTerm}
              />
            </Box>
          )
        })}
      </Flicking>
    </Box>
  )
}

export default FormTermsSelect
