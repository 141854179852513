import React from 'react'
import UIModal from '../../../ui/UIModal/UIModal'
import UITextHeadlineLarge from '../../../ui/UIText/UITextHeadlineLarge'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import { colorsConfig } from '../../../config/themeConfig'
import { Stack } from '@mui/material'
import IconCoin from '../../../assests/img/icons/icon-fleetify-coin.svg'
import { useSelector } from 'react-redux'
import UIPreloaderLG from '../../../ui/UIPreloader/UIPreloaderLG'
import useBreakpoints from '../../../hooks/useBreakpoints'
import UIButton from '../../../ui/UIButtons/UIButton'
import { UIVariant } from '../../../utils/constants/ui.constants'

const VinAutofillModal = ({
  open,
  setOpen,
  handleSubmit,
  title = 'modal_title_autofill_car_info',
  description = 'modal_p_autofill_car_info',
  cancelButtonText = 'button_fill_in_manually',
  submitButtonText = 'button_autofill',
}) => {
  const { isMaxWidth600 } = useBreakpoints()
  const isLoading = useSelector((state) => state.car.vinProvider.isLoading)
  const coinPricings = useSelector((state) => state.auth.coinPricings)

  return (
    <UIModal width="688px" open={open} setOpen={setOpen}>
      {isLoading && <UIPreloaderLG />}
      <UITextHeadlineLarge text={title} sx={{ mb: '10px' }} />
      <UITextRegular text={description} sx={{ mb: '20px' }} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          pb: '9px',
          borderBottom: '1px solid',
          borderColor: colorsConfig.lightGray,
          mb: '40px',
        }}
      >
        <UITextRegular fontWeight="700" sx={{ textTransform: 'uppercase' }} text="common_total" />
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <img src={IconCoin} alt="" style={{ paddingBottom: '2px' }} />
          <UITextRegular
            fontWeight="700"
            sx={{ ml: '5px' }}
            text={coinPricings?.find((item) => item?.identifier === 'vin_provider_request')?.amount}
          />
        </Stack>
      </Stack>
      <Stack direction={isMaxWidth600 ? 'column' : 'row'} gap="10px">
        <UIButton
          variant={UIVariant.attention_hollow}
          fullWidth
          onClick={() => setOpen(false)}
          sx={{
            order: isMaxWidth600 ? 1 : -1,
          }}
          text={cancelButtonText}
        />
        <UIButton
          variant={UIVariant.attention}
          fullWidth
          onClick={() => handleSubmit()}
          text={submitButtonText}
        />
      </Stack>
    </UIModal>
  )
}

export default VinAutofillModal
