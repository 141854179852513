import { getPolicyImports } from '../rviOperator.actions'
import { apiConstants } from '../../../../api/api'

export default (builder) => {
  builder
    .addCase(getPolicyImports.pending, (state) => {
      state.policyImports.isLoading = true
    })
    .addCase(getPolicyImports.fulfilled, (state, action) => {
      state.policyImports.isLoading = false
      state.policyImports.error = null
      state.policyImports.data = action.payload?.[apiConstants.hydraMember]
      state.policyImports.total = action.payload?.[apiConstants.hydraTotal]
    })
    .addCase(getPolicyImports.rejected, (state, action) => {
      state.policyImports.isLoading = false
      state.policyImports.error = action.payload
    })
}
