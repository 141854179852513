import { getCustomerAppraisals } from '../customerAppraisals.actions'
import { apiConstants } from '../../../../api/api'

export default (builder) => {
  builder
    .addCase(getCustomerAppraisals.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getCustomerAppraisals.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = null
      state.data = action.payload?.[apiConstants.hydraMember]
      state.total = action.payload?.[apiConstants.hydraTotal]
    })
    .addCase(getCustomerAppraisals.rejected, (state, action) => {
      if (action.payload?.code !== 'ERR_CANCELED') {
        state.isLoading = false
        state.error = action.payload
      }
    })
}
