import { createCustomerAppraisal } from '../customerAppraisal.actions'

export default (builder) => {
  builder
    .addCase(createCustomerAppraisal.pending, (state) => {
      state.create.isLoading = true
    })
    .addCase(createCustomerAppraisal.fulfilled, (state, action) => {
      state.create.isLoading = false
      state.create.error = null
      state.create.data = action.payload
    })
    .addCase(createCustomerAppraisal.rejected, (state, action) => {
      state.create.isLoading = false
      state.create.error = action.payload
    })
}
