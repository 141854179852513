import React, { useEffect, useState, forwardRef } from 'react'
import {
  Box,
  Paper,
  Button,
  Typography,
  Grid,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { useSelector, useDispatch } from 'react-redux'
import { setCarData } from '../../store/features/carSlice/carSlice'
import { useParams, useNavigate } from 'react-router-dom'
import { privateRequest } from '../../utils/request'
import useValidate from '../../hooks/useValidate'
import useCheckAllErrors from '../../hooks/useCheckAllErrors'
import { API_ENDPOINTS } from '../../config/default'
import { toast } from 'react-toastify'
import TextAreaSmall from '../../ui/TextAreaSmall'
import { colorsConfig } from '../../config/themeConfig'
import { sumDelimiter } from '../../utils/sumDelimiter'
import AnimationSendToCompoundModal from '../../components/Modals/AnimationSendToCompoundModal'
import { countries } from '../../default-data/coutries'
import TextFieldSmallEdible from '../../ui/TextFieldSmallEdible'

const expandAccordionIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
    <path
      d="M4.39053 6.97598C4.79086 7.49405 5.57278 7.49405 5.9731 6.97598L10.1184 1.61145C10.6263 0.954136 10.1578 0 9.32714 0H1.03649C0.205805 0 -0.262712 0.954137 0.245209 1.61145L4.39053 6.97598Z"
      fill="#3D3D3D"
    />
  </svg>
)

const expandAccordionIconBig = (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
    <path
      d="M6.67377 9.7882C7.07074 10.3704 7.92926 10.3704 8.32623 9.7882L13.9341 1.56334C14.3867 0.899527 13.9113 0 13.1079 0H1.89214C1.08872 0 0.613315 0.899529 1.06591 1.56334L6.67377 9.7882Z"
      fill="#3D3D3D"
    />
  </svg>
)

const checkMarkIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
    <path
      d="M7.31955 11.1628C6.95195 11.6609 6.3795 11.967 5.76055 11.9975C5.1416 12.0282 4.54213 11.7792 4.1274 11.3193L0.410875 7.20208C-0.179797 6.54812 -0.127328 5.54005 0.527753 4.95039C1.18346 4.36073 2.19263 4.41342 2.78331 5.06769L5.41274 7.98087C5.4517 8.02415 5.50825 8.04736 5.56638 8.04454C5.62482 8.04171 5.67823 8.01254 5.7131 7.96612L11.1178 0.648379C11.6409 -0.0604678 12.6406 -0.211333 13.351 0.311206C14.0608 0.833746 14.2116 1.83178 13.6881 2.54031L7.31955 11.1628Z"
      fill="#4DED6D"
    />
  </svg>
)

const CustomListBox = forwardRef(function CustomListBox(props, ref) {
  return (
    <Paper
      {...props}
      ref={ref}
      id="custom-listbox"
      sx={{
        width: 'max-content',
      }}
    />
  )
})

const SendToCompound = (props) => {
  const { closeModal, fromVirtualGarage, outsideCarData } = props

  const currentDate = new Date()

  const params = useParams()
  const navigate = useNavigate()

  const carData = useSelector((state) => state.car.carData)
  const carWorkflows = useSelector((state) => state.workflows.carWorkflowStates)
  const user = useSelector((state) => state.auth.user)
  const dispatch = useDispatch()
  const isStateRequestedOnCompound = carData.currentWorkflowState === 'requested_appraisal_compound'
  const isStateRequestedOnCompoundFailed =
    carData.currentWorkflowState === 'requested_appraisal_compound_failed'
  const isStateNew = carData.currentWorkflowState === 'new'

  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  const [isAddressAccordionOpen, setIsAddressAccordionOpen] = useState(false)
  const [date, setDate] = useState(null)
  const [time, setTime] = useState(null)
  const [location, setLocation] = useState(null)
  const [contactName, setContactName] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [contactPhone, setContactPhone] = useState('')
  const [mileage, setMileage] = useState('')
  const [additionalInfo, setAdditionalInfo] = useState('')
  const [disableButton, setDisableButton] = useState(false)
  const [pickupDemand, setPickupDemand] = useState(null)
  const [pickupLocation, setPickupLocation] = useState(null)
  const [animationModal, setAnimationModal] = useState(false)

  const validateDate = useValidate(date, { isEmpty: true, isValidDate: true })
  const validateTime = useValidate(time, { isEmpty: true, isValidDate: true })
  const validateLocation = useValidate(location, { isEmpty: true })
  const validateContactName = useValidate(contactName, { isEmpty: true })
  const validateContactPhone = useValidate(contactPhone, { isEmpty: true, exactLength: 9 })
  const validateContactEmail = useValidate(contactEmail, { isEmpty: true, email: true })
  const validateMileage = useValidate(mileage, { isEmpty: true })
  const hasErrors = useCheckAllErrors([
    validateLocation.hasErrors,
    validateContactName.hasErrors,
    validateContactPhone.hasErrors,
    validateContactEmail.hasErrors,
    validateMileage.hasErrors,
    validateDate.hasErrors,
    validateTime.hasErrors,
  ])

  const handleAccordionToggle = () => {
    setIsAccordionOpen((prevIsOpen) => !prevIsOpen)
  }

  const handleAddressAccordionToggle = () => {
    setIsAddressAccordionOpen((prevIsOpen) => !prevIsOpen)
  }

  const getCar = () => {
    if (fromVirtualGarage) {
      privateRequest.get(API_ENDPOINTS.carId(outsideCarData.carId)).then((response) => {
        setMileage(response.data.mileage)
      })
    } else {
      privateRequest.get(API_ENDPOINTS.carId(params.id)).then((response) => {
        dispatch(setCarData(response.data))
      })
    }
  }

  const getPickupDemand = () => {
    if (isStateRequestedOnCompound || isStateRequestedOnCompoundFailed) {
      privateRequest.get(API_ENDPOINTS.pickupDemand(carData.id)).then((response) => {
        setPickupDemand(response.data)
      })
    }
  }

  const getPickupDemandLocation = async () => {
    if (pickupDemand) {
      const pickupDemandAddressIri = pickupDemand.address
      const pickupDemandAddressId = +(pickupDemandAddressIri.match(/\/(\d+)$/) || [])[1]
      const pickupLocationArray = await privateRequest
        .get(API_ENDPOINTS.address(pickupDemandAddressId))
        .then((response) => response.data)
      setPickupLocation(pickupLocationArray)
    }
  }

  useEffect(() => {
    setDate(pickupDemand ? pickupDemand.date : null)
    setTime(pickupDemand ? pickupDemand.date : null)
    setContactEmail(pickupDemand ? pickupDemand.email : '')
    setContactName(pickupDemand ? pickupDemand.contactPerson : '')
    setContactPhone(pickupDemand ? pickupDemand.phone : '')
    setMileage(
      carData?.carMainSpecs?.mileage
        ? carData.carMainSpecs.mileage
        : pickupDemand
          ? pickupDemand.mileage
          : '',
    )
    setAdditionalInfo(pickupDemand ? pickupDemand.additionalInformation : '')
  }, [pickupDemand])

  useEffect(() => {
    setCountry(
      pickupLocation ? countries.filter((item) => item.label === pickupLocation?.country)[0] : null,
    )
    setState(pickupLocation ? pickupLocation.state : null)
    setStreet(pickupLocation ? pickupLocation.street : null)
    setPostcode(pickupLocation ? pickupLocation.postcode : null)
    setAddressLine1(pickupLocation ? pickupLocation.addressLine1 : null)
    setAddressLine2(pickupLocation ? pickupLocation.addressLine2 : null)
    setComment(pickupLocation ? pickupLocation.comment : null)
  }, [pickupLocation])

  useEffect(() => {
    if (fromVirtualGarage) getCar()
  }, [])

  useEffect(() => {
    if (!fromVirtualGarage) {
      getPickupDemand()
      if (
        carData.currentWorkflowState === 'new' ||
        carData.currentWorkflowState === 'appraisal_on_site' ||
        carData.currentWorkflowState === 'requested_appraisal_compound_failed' ||
        carData.currentWorkflowState === 'moderation_failed'
      ) {
        setIsAccordionOpen(true)
      }
    }
  }, [carData])

  useEffect(() => {
    if (pickupDemand) {
      getPickupDemandLocation()
    }
  }, [pickupDemand])

  function transformDateFormat(inputDate) {
    const date = new Date(inputDate)

    const year = date.getUTCFullYear()
    const month = String(date.getUTCMonth() + 1).padStart(2, '0')
    const day = String(date.getUTCDate()).padStart(2, '0')
    const hours = String(date.getUTCHours()).padStart(2, '0')
    const minutes = String(date.getUTCMinutes()).padStart(2, '0')
    const seconds = String(date.getUTCSeconds()).padStart(2, '0')
    const timezoneOffsetHours = String(Math.abs(date.getTimezoneOffset() / 60)).padStart(2, '0')
    const timezoneOffsetMinutes = String(Math.abs(date.getTimezoneOffset() % 60)).padStart(2, '0')
    const timezoneOffsetSign = date.getTimezoneOffset() < 0 ? '+' : '-'

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneOffsetSign}${timezoneOffsetHours}:${timezoneOffsetMinutes}`

    return formattedDate
  }

  const handleSubmit = async () => {
    let newAddressIri

    try {
      newAddressIri = await handleAddNewLocation(isAddressAccordionOpen)
    } finally {
      setDisableButton(true)
      const currentDate = isStateRequestedOnCompoundFailed ? new Date(date) : new Date(date['$d'])
      const currentTimeDate = isStateRequestedOnCompoundFailed
        ? new Date(time)
        : new Date(time['$d'])
      currentDate.setHours(currentTimeDate.getHours())
      currentDate.setMinutes(currentTimeDate.getMinutes())
      const formData = {
        address: isAddressAccordionOpen ? newAddressIri : location?.addressIri,
        phone: contactPhone,
        email: contactEmail,
        contactPerson: contactName,
        date: transformDateFormat(currentDate),
        mileage: parseFloat(mileage),
        additionalInformation: additionalInfo,
      }

      if (fromVirtualGarage) {
        privateRequest
          .post(`/cars/${outsideCarData.carId}${API_ENDPOINTS.requestCompound}`, formData)
          .then(() => {
            setAnimationModal(true)
            setTimeout(() => {
              setAnimationModal(false)
              closeModal(true)
            }, 3000)
          })
          .catch(() => {
            toast.error('An error occurred while applying for pickup')
          })

        return
      }

      privateRequest
        .post(`${carData['@id']}${API_ENDPOINTS.requestCompound}`, formData)
        .then(() => {
          getCar()
          setAnimationModal(true)
          setTimeout(() => {
            setAnimationModal(false)
          }, 3000)
        })
        .catch(() => {
          toast.error('An error occurred while applying for pickup')
        })
        .finally(() => {
          setDisableButton(false)
        })
    }
  }

  const handleRestartFromVirtualGarage = () => {
    setDisableButton(true)
    privateRequest
      .post(
        API_ENDPOINTS.revertFromAppraisalCompoundFailed(carData.id),
        {},
        {
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        toast.success('The car has been returned to the Virtual Garage')
        navigate('/virtual-garage')
      })
      .catch(() => {
        toast.error('An error occurred while handling the request')
      })
  }

  const handleRepeatedSubmit = async () => {
    let newAddressIri

    try {
      newAddressIri = await handleAddNewLocation()
    } finally {
      setDisableButton(true)
      const phonePrefix = '971'
      const currentDate = isStateRequestedOnCompoundFailed ? new Date(date) : new Date(date['$d'])
      const currentTimeDate = isStateRequestedOnCompoundFailed
        ? new Date(time)
        : new Date(time['$d'])
      currentDate.setHours(currentTimeDate.getHours())
      currentDate.setMinutes(currentTimeDate.getMinutes())
      const formData = {
        address: isAddressAccordionOpen ? newAddressIri : location?.addressIri,
        phone: contactPhone,
        email: contactEmail,
        contactPerson: contactName,
        date: transformDateFormat(currentDate),
        mileage: parseFloat(mileage),
        additionalInformation: additionalInfo,
      }

      if (fromVirtualGarage) {
        privateRequest
          .post(`/cars/${outsideCarData.carId}${API_ENDPOINTS.requestCompound}`, formData)
          .then(() => {
            setAnimationModal(true)
            setTimeout(() => {
              setAnimationModal(false)
              closeModal(true)
            }, 3000)
          })
          .catch(() => {
            toast.error('An error occurred while applying for pickup')
          })

        return
      }

      privateRequest
        .post(API_ENDPOINTS.revertFromAppraisalCompoundFailed(carData.id), formData, {
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then(() => {
          toast.success('The request for an update of the information was successfully sent')
          privateRequest
            .post(`${carData['@id']}${API_ENDPOINTS.requestCompound}`, formData)
            .then(() => {
              // toast.success('Your request for pickup has been submitted')
              getCar()
              setAnimationModal(true)
              setTimeout(() => {
                setAnimationModal(false)
              }, 3000)
              // closeModal()
              // if (locationPage.pathname.includes('appraisal')) {
              //     navigate(`/cars/${params.id}`)
              // }
            })
            .catch(() => {
              toast.error('An error occurred while applying for pickup')
            })
            .finally(() => {
              setDisableButton(false)
            })
        })
        .catch(() => {
          toast.error('An error occurred while handling the request')
        })
    }
  }

  const createButton = (color, background, label, handler, variant, icon, disabled) => {
    return (
      <Button
        variant={variant}
        sx={{
          height: '48px',
          fontFamily: 'Helvetica',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
          textTransform: 'none',
          marginTop: '13px',
          color: color,
          background: background,
          borderRadius: '4px',
          padding: '6px 70px',
          gap: '5px',
          '&.Mui-disabled': {
            background: background,
            color: color,
          },
        }}
        onClick={handler}
        disabled={disabled ? false : true}
      >
        {icon}
        {label}
      </Button>
    )
  }

  const [showErrors, setShowErrors] = useState(false)
  const [country, setCountry] = useState('')
  const [state, setState] = useState('')
  const [street, setStreet] = useState('')
  const [postcode, setPostcode] = useState('')
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')
  const [comment, setComment] = useState('')
  const [locationsInfo, setLocationsInfo] = useState([])
  const [addressTypes, setAddressTypes] = useState([])

  const checkErrors = () => {
    if (!country) return true
    if (!state) return true
    if (!addressLine1) return true
    return false
  }

  const getAddressTypes = () => {
    privateRequest
      .get(
        API_ENDPOINTS.addressTypes,
        {},
        {
          headers: {
            accept: 'application/json',
          },
        },
      )
      .then((response) => {
        setAddressTypes(response.data['hydra:member'])
      })
  }

  const handleAddNewLocation = async (isMain) => {
    const addressTypeIri = addressTypes.filter((typeItem) => typeItem.name === 'Pickup')[0]['@id']

    const formData = {
      type: addressTypeIri,
      country: country?.label,
      state,
      postcode,
      addressLine1,
      addressLine2,
      comment,
    }

    const response = await privateRequest.post(API_ENDPOINTS.newAddress, formData, {
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
    })

    const id = response.data.id
    const addressIri = `addresses/${id}`

    await privateRequest.post(
      API_ENDPOINTS.companyAddresses,
      {
        address: addressIri,
        isMain: isMain,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
      },
    )

    return addressIri
  }

  const getLocation = async (itemAddressId) => {
    const response = await privateRequest.get(
      API_ENDPOINTS.companyAddress(itemAddressId),
      {},
      {
        headers: {
          accept: 'application/json',
        },
      },
    )

    const locationItem = response.data
    const { type, id, street, state, country, postcode, addressLine1, addressLine2, comment } =
      locationItem.address
    const addressIri = locationItem.address['@id']
    const companyAddressId = +(locationItem['@id'].match(/\/(\d+)$/) || [])[1]
    const addressType =
      addressTypes.filter((typeItem) => typeItem['@id'] === type)[0]?.name || 'All'

    return {
      addressType,
      id,
      street,
      state,
      country,
      postcode,
      addressLine1,
      addressLine2,
      companyAddressId,
      comment,
      addressIri,
    }
  }

  const getAllPickupLocations = async () => {
    const response = await privateRequest.get(
      API_ENDPOINTS.companyAddresses,
      {},
      {
        headers: {
          accept: 'application/json',
        },
      },
    )

    const locationsInfoArray = await Promise.all(
      response.data['hydra:member'].map(async (item) => {
        const companyAddressId = +(item['@id'].match(/\/(\d+)$/) || [])[1]
        return getLocation(companyAddressId)
      }),
    )
    const pickupLocationsArray = locationsInfoArray.filter(
      (location) => location.addressType === 'Pickup' || location.addressType === 'All',
    )
    setLocationsInfo(pickupLocationsArray)
  }

  useEffect(() => {
    getAddressTypes()
  }, [])

  useEffect(() => {
    if (addressTypes.length > 0) {
      getAllPickupLocations()
    }
  }, [addressTypes])

  useEffect(() => {
    if (
      carData?.currentWorkflowState === 'new' ||
      carData?.currentWorkflowState === 'appraisal-on-site' ||
      carData?.currentWorkflowState === 'moderation_failed'
    ) {
      if (user?.email) setContactEmail(user?.email)
      if (user?.manager?.firstName && user?.manager?.surname)
        setContactName(`${user?.manager?.firstName} ${user?.manager?.surname}`)
      if (user?.manager?.phoneNumber) setContactPhone(user?.manager?.phoneNumber.replace('971', ''))
    }
  }, [user, carData?.currentWorkflowState])

  useEffect(() => {
    if (
      locationsInfo.length > 0 &&
      (carData?.currentWorkflowState === 'new' ||
        carData?.currentWorkflowState === 'appraisal-on-site' ||
        carData?.currentWorkflowState === 'moderation_failed')
    ) {
      setLocation(locationsInfo[0])
    }
  }, [locationsInfo])

  return (
    <>
      <Accordion
        sx={{
          boxShadow: '0px 0px 24px 0px rgba(0, 0, 0, 0.10)',
        }}
        expanded={isAccordionOpen}
      >
        <AccordionSummary
          expandIcon={expandAccordionIcon}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            padding: '0 40px',
            marginTop: '20px',
            position: 'relative',
            height: '70px',
          }}
          onClick={handleAccordionToggle}
        >
          <Typography
            sx={{
              fontFamily: 'Helvetica',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: 'normal',
              marginRight: '6px',
            }}
          >
            {carData.currentWorkflowState === 'requested_appraisal_compound_failed'
              ? carWorkflows.filter(
                  (item) => item.sysName === 'requested_appraisal_compound_failed',
                )[0]?.value
              : 'Appraisal on compound request'}
          </Typography>
          <Box sx={{ paddingTop: '1px' }}>{isStateRequestedOnCompound && checkMarkIcon}</Box>
          <Typography
            sx={{
              position: 'absolute',
              top: '50%',
              right: '40px',
              transform: 'translate(-50%, -50%)',
              fontSize: '14px',
              fontStyle: 'normal',
              lineHeight: 'normal',
            }}
          >
            {isAccordionOpen ? 'Close' : 'Open'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: '0 0 30px',
            marginTop: '17px',
          }}
        >
          {pickupDemand?.failMessage ? (
            <Box sx={{ background: '#FFF9ED', margin: '0 0 40px', padding: '41px 42px 40px 39px' }}>
              <Box sx={{ display: 'flex', gap: '9px', alignItems: 'center' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <path
                    d="M15 2.5C8.10125 2.5 2.5 8.10125 2.5 15C2.5 21.8988 8.10125 27.5 15 27.5C21.8988 27.5 27.5 21.8988 27.5 15C27.5 8.10125 21.8988 2.5 15 2.5ZM15 5C20.5188 5 25 9.48125 25 15C25 20.5188 20.5188 25 15 25C9.48125 25 5 20.5188 5 15C5 9.48125 9.48125 5 15 5ZM15 18.7413C15.3315 18.7413 15.6495 18.8729 15.8839 19.1074C16.1183 19.3418 16.25 19.6597 16.25 19.9913C16.25 20.3228 16.1183 20.6407 15.8839 20.8751C15.6495 21.1096 15.3315 21.2413 15 21.2413C14.6685 21.2413 14.3505 21.1096 14.1161 20.8751C13.8817 20.6407 13.75 20.3228 13.75 19.9913C13.75 19.6597 13.8817 19.3418 14.1161 19.1074C14.3505 18.8729 14.6685 18.7413 15 18.7413ZM16.25 16.25V8.75C16.25 8.41848 16.1183 8.10054 15.8839 7.86612C15.6495 7.6317 15.3315 7.5 15 7.5C14.6685 7.5 14.3505 7.6317 14.1161 7.86612C13.8817 8.10054 13.75 8.41848 13.75 8.75V16.25C13.75 16.5815 13.8817 16.8995 14.1161 17.1339C14.3505 17.3683 14.6685 17.5 15 17.5C15.3315 17.5 15.6495 17.3683 15.8839 17.1339C16.1183 16.8995 16.25 16.5815 16.25 16.25Z"
                    fill="#F5BD4F"
                  />
                </svg>
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                  }}
                >
                  Updates requires
                </Typography>
              </Box>
              <Typography sx={{ marginTop: '20px', fontStyle: 'normal', lineHeight: 'normal' }}>
                {pickupDemand.failMessage}
              </Typography>
            </Box>
          ) : null}
          <Box
            sx={{
              padding: '0 40px 0 55px',
              px: '30px',
            }}
          >
            <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
              <Box
                sx={{
                  mb: '8px',
                }}
              >
                <Grid
                  container
                  spacing={'20px'}
                  sx={{
                    '& .MuiInputBase-root': {
                      background: 'white',
                    },
                  }}
                >
                  <Grid item md={3}>
                    <Stack spacing="20px">
                      <DesktopDatePicker
                        label="Pickup Date"
                        inputFormat="DD/MM/YYYY"
                        mask="__/__/____"
                        minDate={currentDate}
                        value={date}
                        onChange={(newValue) => {
                          setDate(newValue)
                        }}
                        renderInput={(params) => (
                          <TextFieldSmallEdible
                            {...params}
                            fullWidth
                            required
                            error={showErrors && !date}
                            helperText={showErrors && !date ? 'Invalid date' : ''}
                          />
                        )}
                        disabled={isStateRequestedOnCompound}
                      />
                      <TimePicker
                        fullWidth
                        label="Pickup Time"
                        value={time}
                        onChange={(newValue) => {
                          setTime(newValue)
                        }}
                        renderInput={(params) => (
                          <TextFieldSmallEdible
                            required
                            fullWidth
                            {...params}
                            error={showErrors && !time}
                            helperText={showErrors && !time ? 'This field can not be empty' : ''}
                          />
                        )}
                        disabled={isStateRequestedOnCompound}
                      />
                      <Autocomplete
                        required
                        fullWidth
                        id="address-pickup"
                        options={locationsInfo ? locationsInfo : []}
                        getOptionLabel={(location) =>
                          [
                            location.state,
                            location.country,
                            location.addressLine1,
                            location.postcode,
                          ]
                            .filter((item) => Boolean(item))
                            .join(' ') || ''
                        }
                        value={isAddressAccordionOpen ? null : location}
                        onChange={(e, newVal) => {
                          setLocation(newVal)
                        }}
                        disabled={isStateRequestedOnCompound ? true : isAddressAccordionOpen}
                        renderInput={(params) => (
                          <TextFieldSmallEdible
                            {...params}
                            label="Pickup address"
                            required={!isAddressAccordionOpen}
                            error={showErrors && !isAddressAccordionOpen && !location}
                            helperText={
                              showErrors && !isAddressAccordionOpen && !location
                                ? 'This field can not be empty'
                                : ''
                            }
                          />
                        )}
                        renderOption={(
                          props,
                          { addressType, addressLine1, state, country, postcode },
                        ) => (
                          <li {...props}>
                            <Box sx={{ display: 'flex', gap: '4px' }}>
                              <Typography
                                sx={{ fontSize: '14px', fontWeight: 700, width: 'max-content' }}
                              >
                                {addressType}
                              </Typography>
                              <Typography sx={{ fontSize: '14px', width: 'fit-content' }}>
                                {addressLine1}
                              </Typography>
                              <Typography sx={{ fontSize: '14px', width: 'fit-content' }}>
                                {state}
                              </Typography>
                              <Typography sx={{ fontSize: '14px', width: 'fit-content' }}>
                                {country}
                              </Typography>
                              <Typography sx={{ fontSize: '14px', width: 'fit-content' }}>
                                {postcode}
                              </Typography>
                            </Box>
                          </li>
                        )}
                        sx={{
                          '& .MuiInputBase-input': {
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          },
                          display: isStateRequestedOnCompound ? 'none' : '',
                        }}
                        ListboxComponent={CustomListBox}
                      />
                    </Stack>
                  </Grid>
                  <Grid item md={3}>
                    <Stack spacing={'20px'}>
                      <TextFieldSmallEdible
                        fullWidth
                        required
                        type="text"
                        label="Contact name"
                        value={contactName}
                        onChange={(e) => {
                          setContactName(e.target.value)
                        }}
                        disabled={isStateRequestedOnCompound}
                        error={showErrors && !contactName}
                        helperText={showErrors && !contactName ? 'This field can not be empty' : ''}
                      />
                      <TextFieldSmallEdible
                        fullWidth
                        type="text"
                        required
                        label="Contact phone"
                        value={contactPhone}
                        onChange={(e) => {
                          setContactPhone(e.target.value.replace(/[^0-9]/g, ''))
                        }}
                        error={validateContactPhone.hasErrors && showErrors}
                        helperText={
                          validateContactPhone.hasErrors && showErrors ? 'Invalid phone number' : ''
                        }
                        disabled={isStateRequestedOnCompound}
                        inputProps={{
                          maxLength: 9,
                        }}
                        InputProps={{
                          startAdornment: (
                            <Typography
                              sx={{
                                fontSize: '14px',
                                mr: '3px',
                                color: isStateRequestedOnCompound
                                  ? 'rgba(0, 0, 0, 0.38)'
                                  : '#757575',
                              }}
                            >
                              +971
                            </Typography>
                          ),
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item md={3}>
                    <Stack spacing={'20px'}>
                      <TextFieldSmallEdible
                        fullWidth
                        required
                        type="email"
                        label="Email"
                        value={contactEmail}
                        onChange={(e) => {
                          setContactEmail(e.target.value)
                        }}
                        error={validateContactEmail.hasErrors && showErrors}
                        helperText={
                          validateContactEmail.hasErrors && showErrors ? 'Invalid email' : ''
                        }
                        disabled={isStateRequestedOnCompound}
                      />
                      <TextFieldSmallEdible
                        fullWidth
                        required
                        type="text"
                        label="Mileage"
                        value={sumDelimiter(mileage)}
                        onChange={(e) => {
                          setMileage(e.target.value.replace(/[^0-9]/g, ''))
                        }}
                        error={showErrors && !mileage}
                        helperText={showErrors && !mileage ? 'This field can not be empty' : ''}
                        disabled={isStateRequestedOnCompound}
                      />
                    </Stack>
                  </Grid>
                  <Grid item md={3}>
                    <Stack>
                      <TextAreaSmall
                        fullWidth
                        label="Additional Information"
                        multiline
                        rows={4}
                        sx={{
                          background: 'white',
                        }}
                        value={additionalInfo}
                        onChange={(e) => {
                          setAdditionalInfo(e.target.value)
                        }}
                        disabled={isStateRequestedOnCompound}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </LocalizationProvider>
            <Box>
              <Accordion
                expanded={isAddressAccordionOpen}
                sx={{
                  background: 'none',
                  boxShadow: 'none',
                  border: 'none',
                  position: 'static',
                }}
              >
                <AccordionSummary
                  expandIcon={expandAccordionIconBig}
                  onClick={handleAddressAccordionToggle}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  sx={{
                    width: 'fit-content',
                    height: '70px',
                    padding: 0,
                    marginTop: '20px',
                    marginLeft: '8px',
                    position: 'relative',
                  }}
                >
                  <Typography
                    sx={{ fontStyle: 'normal', lineHeight: 'normal', marginRight: '7px' }}
                  >
                    {isStateRequestedOnCompound ? `Pickup address` : `Choose another address`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    px: 0,
                  }}
                >
                  <Grid
                    container
                    spacing={'20px'}
                    sx={{
                      marginBottom: '30px',
                    }}
                  >
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        id="address-country"
                        options={[
                          {
                            label: null,
                          },
                          ...countries,
                        ]}
                        getOptionLabel={(option) => option.label || ''}
                        fullWidth
                        autoHighlight
                        value={country}
                        disabled={isStateRequestedOnCompound}
                        onChange={(e, newVal) => {
                          setCountry(newVal)
                        }}
                        renderOption={(props, option) => {
                          if (!option.label) return null
                          return (
                            <Box
                              component="li"
                              sx={{
                                '& > img': {
                                  mr: 2,
                                  flexShrink: 0,
                                },
                              }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                alt=""
                              />
                              {option.label}
                            </Box>
                          )
                        }}
                        renderInput={(params) => (
                          <TextFieldSmallEdible
                            required
                            {...params}
                            label="Country"
                            error={showErrors && !country}
                            helperText={showErrors && !country ? 'This field can not be empty' : ''}
                            disabled={isStateRequestedOnCompound}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextFieldSmallEdible
                        required
                        id="address-state"
                        fullWidth
                        label="State"
                        value={state}
                        onChange={(e) => {
                          setState(e.target.value)
                        }}
                        error={showErrors && !state}
                        helperText={showErrors && !state ? 'This field can not be empty' : ''}
                        disabled={isStateRequestedOnCompound}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextFieldSmallEdible
                        id="address-postcode"
                        fullWidth
                        label="ZIP/postcode"
                        value={postcode}
                        onChange={(e) => {
                          setPostcode(e.target.value.toUpperCase())
                        }}
                        disabled={isStateRequestedOnCompound}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextFieldSmallEdible
                        required
                        id="address-line-1"
                        fullWidth
                        label="Address line 1"
                        value={addressLine1}
                        onChange={(e) => {
                          setAddressLine1(e.target.value)
                        }}
                        error={showErrors && !addressLine1}
                        helperText={
                          showErrors && !addressLine1 ? 'This field can not be empty' : ''
                        }
                        disabled={isStateRequestedOnCompound}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextFieldSmallEdible
                        id="address-line-2"
                        fullWidth
                        label="Address line 2"
                        value={addressLine2}
                        onChange={(e) => {
                          setAddressLine2(e.target.value)
                        }}
                        disabled={isStateRequestedOnCompound}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldSmallEdible
                        id="address-comment"
                        fullWidth
                        type="textarea"
                        multiline
                        rows={4}
                        label="Comment"
                        value={comment}
                        onChange={(e) => {
                          setComment(e.target.value)
                        }}
                        disabled={isStateRequestedOnCompound}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '25px',
              }}
            >
              {isStateRequestedOnCompoundFailed
                ? createButton(
                    'white',
                    '#AC0000',
                    'Restart',
                    () => {
                      handleRestartFromVirtualGarage()
                    },
                    'contained',
                    null,
                    'true',
                  )
                : null}
              {isStateRequestedOnCompound
                ? createButton(
                    colorsConfig.mainBlack,
                    colorsConfig.buttonDisabled,
                    'Applied for pickup',
                    null,
                    'contained',
                  )
                : createButton(
                    'white',
                    colorsConfig.mainBlue,
                    'Apply for pickup',
                    () => {
                      if (
                        date &&
                        time &&
                        (location || !checkErrors()) &&
                        contactName &&
                        contactPhone &&
                        contactEmail &&
                        mileage &&
                        !hasErrors
                      ) {
                        if (
                          isStateNew ||
                          carData.currentWorkflowState === 'appraisal_on_site' ||
                          carData.currentWorkflowState === 'moderation_failed'
                        ) {
                          handleSubmit()
                        }
                        if (isStateRequestedOnCompoundFailed) {
                          handleRepeatedSubmit()
                        }
                        return
                      }
                      setShowErrors(true)
                      toast.error('Please fill correctly all the required fields')
                    },
                    'contained',
                    null,
                    'true',
                  )}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <AnimationSendToCompoundModal open={animationModal} setOpen={setAnimationModal} />
    </>
  )
}

export default SendToCompound
