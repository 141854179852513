import { createCompany } from '../companies.actions'

export default (builder) => {
  builder
    .addCase(createCompany.pending, (state) => {
      state.create.isLoading = true
    })
    .addCase(createCompany.fulfilled, (state, action) => {
      state.create.isLoading = false
      state.create.error = null
    })
    .addCase(createCompany.rejected, (state, action) => {
      state.create.isLoading = false
      state.create.isLoading = action.payload
    })
}
