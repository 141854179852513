import React from 'react'
import { Button } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const ButtonRoundedSmall = ({ children, sx, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        textTransform: 'none',
        fontSize: '19px',
        fontFamily: '"Helvetica", sans-serif',
        fontWeight: 400,
        color: colorsConfig.mainBlack,
        borderRadius: '100px',
        px: '15px',
        py: '0.75px',
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.10)',
        border: '1px solid',
        borderColor: colorsConfig.buttonDisabled,
        background: 'white',
        transition: '0.25s all ease-in-out',
        '&:hover': {
          transition: '0.25s all ease-in-out',
          background: colorsConfig.bgLightBlue,
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  )
}

export default ButtonRoundedSmall
