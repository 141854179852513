import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import financeCalculatorApiService from '../../../api/finance/financeCalculatorApiService/financeCalculatorApiService'
import loanApplicationsApiService from '../../../api/finance/loanApplicationsApiService/loanApplicationsApiService'
import { getApiError } from '../../../api/api'

export const calculateRV = createAsyncThunk(
  'financing/calculateRV',
  async ({ data }, { rejectWithValue }) => {
    try {
      console.log({ data })
      const response = await financeCalculatorApiService.calculateRv(data)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const calculateTerms = createAsyncThunk(
  'financing/calculateTerms',
  async ({ terms, data }, { rejectWithValue }) => {
    try {
      const termsResponses = await Promise.all(
        terms.map((term) => {
          return financeCalculatorApiService.calculateTerm(term, data)
        }),
      )
      const termsData = termsResponses.map((res) => res.data)
      return termsData
    } catch (e) {
      toast.error(e)
      return rejectWithValue(e)
    }
  },
)

export const calculateCustomPackageTerms = createAsyncThunk(
  'financing/calculateCustomPackageTerms',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await financeCalculatorApiService.calculateOffer(data)
      return response.data
    } catch (e) {
      toast.error(e)
      return rejectWithValue(e)
    }
  },
)

export const createLoanApplication = createAsyncThunk(
  'financing/createLoanApplication',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await loanApplicationsApiService.createLoanApplication(data)
      return response.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)

export const getCarLoanApplications = createAsyncThunk(
  'financing/getCarLoanApplications',
  async ({ carIdUrl }, { rejectWithValue }) => {
    try {
      const params = {
        pagination: false,
        car: carIdUrl,
        isArchived: false,
      }
      const response = await loanApplicationsApiService.getLoanApplications(
        params,
        'getCarLoanApplications',
      )
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const saveLoanApplicationCustomer = createAsyncThunk(
  'financing/saveLoanApplicationCustomer',
  async (_, { rejectWithValue, getState }) => {
    try {
      const loanApplicationId = getState().financing.currentLoanApplication.data?.id
      const response =
        await loanApplicationsApiService.confirmToApplicationPreparation(loanApplicationId)
      return response.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)

export const submitLoanApplicationToBanks = createAsyncThunk(
  'financing/submitLoanApplicationToBanks',
  async (_, { rejectWithValue, getState }) => {
    try {
      const loanApplicationId = getState().financing.currentLoanApplication.data?.id
      const response =
        await loanApplicationsApiService.confirmToApprovalProcessStarted(loanApplicationId)
      return response.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)
