import React from 'react'
import { Box } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import useBreakpoints from '../../hooks/useBreakpoints'

const UIPaper = ({ sx, children, reduceOnMobile, ...props }) => {
  const { smDown } = useBreakpoints()

  return (
    <Box
      sx={{
        p: '30px',
        borderRadius: '8px',
        background: colorsConfig.white,
        boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
        ...(reduceOnMobile &&
          smDown && {
            boxShadow: 'none',
            p: 0,
            borderRadius: 0,
          }),
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

export default UIPaper
