import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ColorRadioButton = (props) => {
  const { t } = useTranslation()

  const { label, color, itemsPerRow, allowMultiple, value, setValue, image, translation } = props
  const [isActive, setIsActive] = useState(false)
  const [activeIndicator, setActiveIndicator] = useState('')

  useEffect(() => {
    if (allowMultiple) {
      setValue({
        label: label,
        value: isActive,
      })
    }
  }, [isActive])

  useEffect(() => {
    if (allowMultiple) {
      if (value.length > 0 && value.filter((item) => item === label)[0]) {
        setActiveIndicator('active')
      } else {
        setActiveIndicator('')
      }
    } else {
      if (value === label) {
        setActiveIndicator('active')
      } else {
        setActiveIndicator('')
      }
    }
  }, [value])

  return (
    <div
      className={`color-radio-button ${activeIndicator} ${color === '#FFFFFF' ? 'color-radio-button-white' : ''}`}
      style={{
        width: `calc(100% / ${itemsPerRow}`,
      }}
    >
      {image ? (
        <Box
          className={`${activeIndicator} color-radio-button-image`}
          sx={{
            position: 'relative',
            mr: '10px',
            cursor: 'pointer',
            '& .img-active': {
              position: 'absolute',
              top: 0,
              left: 0,
              transformOrigin: '50% 50%',
              transform: 'scale(0.7)',
              width: '24px',
              height: '24px',
              transition: '0.3s all ease-in-out',
            },
            '&.active': {
              '& .img-active': {
                transform: 'scale(1)',
                transition: '0.3s all ease-in-out',
              },
            },
          }}
          onClick={() => {
            if (allowMultiple) {
              setIsActive(!isActive)
            } else {
              setValue(label)
            }
          }}
        >
          <img
            className={'img-inactive'}
            src={image.image}
            style={{ verticalAlign: 'middle' }}
            alt=""
          />
          <img className={'img-active'} src={image.imageFull} alt="" />
        </Box>
      ) : (
        <div
          className={`color-radio-button-box`}
          style={{
            borderColor: color === '#FFFFFF' ? 'black' : color,
          }}
          onClick={() => {
            if (allowMultiple) {
              setIsActive(!isActive)
            } else {
              setValue(label)
            }
          }}
        >
          <div
            className="color-radio-button-box-inner"
            style={{
              background: color,
            }}
          />
        </div>
      )}

      <div className="color-radio-button-label">{translation ? t(translation) : label}</div>
    </div>
  )
}

export default ColorRadioButton
