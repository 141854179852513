import React, { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import { useDispatch } from 'react-redux'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import { privateRequest } from '../../../utils/request'
import { API_ENDPOINTS, currentLocales } from '../../../config/default'
import AdminPanelControlPoint from './AdminPanelControlPoint'

const AdminPanelInteriorConditions = () => {
  const dispatch = useDispatch()

  const [controlPoints, setControlPoints] = useState([])

  const handleSetControlPoints = (data) => {
    setControlPoints(
      data.map((item) => {
        return {
          ...item,
          translations: currentLocales.map((locale) => {
            return {
              ...locale,
              value:
                item.translations.filter(
                  (translation) => translation.locale === locale.systemName,
                )[0]?.value || ' ',
            }
          }),
        }
      }),
    )
  }

  const getInteriorConditions = () => {
    privateRequest
      .get(`${API_ENDPOINTS.controlPoints}?subblock=2&pagination=false`)
      .then((response) => {
        handleSetControlPoints(response.data['hydra:member'])
      })
  }

  useEffect(() => {
    getInteriorConditions()
    dispatch(setCurrentPageTitle('Interior conditions'))
  }, [])

  return (
    <Box>
      <BoxShadowed>
        <Stack spacing={'10px'}>
          {controlPoints.map((item, index) => {
            return (
              <AdminPanelControlPoint
                key={index}
                controlPoint={item}
                getControlPoints={getInteriorConditions}
              />
            )
          })}
        </Stack>
      </BoxShadowed>
    </Box>
  )
}

export default AdminPanelInteriorConditions
