import React from 'react'
import ButtonLink from '../../ui/Buttons/ButtonLink'
import { useTranslation } from 'react-i18next'
import UIButtonLink from '../../ui/UIButtons/UIButtonLink'

const PrivacyPolicyLink = ({ children }) => {
  const { t, i18n } = useTranslation()
  const handleOpenPrivacyPolicy = (e) => {
    e.preventDefault()
    document.getElementById(`privacy-policy-link-${i18n.language}`).click()
  }
  return (
    <UIButtonLink onClick={handleOpenPrivacyPolicy}>
      {children ?? t('common_privacy_policy').toLowerCase()}
    </UIButtonLink>
  )
}

export default PrivacyPolicyLink
