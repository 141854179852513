export const areMatricesEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false
  }
  let hasDifference = false
  arr1.forEach((item, index) => {
    if (item.term !== arr2[index].term) {
      hasDifference = true
    }
    if (item.contractEndCarAgeMax !== arr2[index].contractEndCarAgeMax) {
      hasDifference = true
    }
    if (item.contractEndCarAgeMin !== arr2[index].contractEndCarAgeMin) {
      hasDifference = true
    }
    if (item.contractEndMileageMax !== arr2[index].contractEndMileageMax) {
      hasDifference = true
    }
    if (item.contractEndMileageMin !== arr2[index].contractEndMileageMin) {
      hasDifference = true
    }
    if (item.residualValueGross !== arr2[index].residualValueGross) {
      hasDifference = true
    }
    if (item.residualValueNet !== arr2[index].residualValueNet) {
      hasDifference = true
    }
  })
  return !hasDifference
}

export const getEntryFormData = (data) => {
  return {
    term: parseInt(data.term),
    contractEndCarAgeMin: parseInt(data.contractEndCarAgeMin),
    contractEndCarAgeMax: parseInt(data.contractEndCarAgeMax),
    contractEndMileageMin: parseInt(data.contractEndMileageMin),
    contractEndMileageMax: parseInt(data.contractEndMileageMax),
    residualValueGross: data.residualValueGross.toString(),
    residualValueNet: data.residualValueNet.toString(),
  }
}
