import { getCoinPricings } from '../coinPricings.actions'

export default (builder) => {
  builder
    .addCase(getCoinPricings.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getCoinPricings.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = null
      state.coinPricings = action.payload
    })
    .addCase(getCoinPricings.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
}
