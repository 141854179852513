import React, { useState, useEffect } from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { useSelector } from 'react-redux'
import { CDN_BASE_URL } from '../../../config/default'
import 'photoswipe/dist/photoswipe.css'
import { Gallery } from 'react-photoswipe-gallery'
import { colorsConfig } from '../../../config/themeConfig'
import TextNormal from '../../../ui/Text/TextNormal'
import pdfIcon from '../../../assests/img/icons/pdf-icon.png'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import { useTranslation } from 'react-i18next'
import DamagePhoto from '../../../pages/Appraisal/AppraisalDamages/DamagePhoto'

const CarDamages = ({ carDamages }) => {
  const { t } = useTranslation()
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const role = useSelector((state) => state.auth.role)

  const finalDamages =
    carDamages && carDamages?.length > 0
      ? carDamages.map((item) => {
          const newPhotos = item.photos.map((photo) => {
            const img = new Image()
            img.src = `${CDN_BASE_URL}${photo.photo.path}`
            return {
              ...photo,
              imgWidth: img.width,
              imgHeight: img.height,
            }
          })
          return {
            ...item,
            photos: newPhotos,
          }
        })
      : []

  return (
    <Box
      className={xsOnly ? 'xs-only' : ''}
      sx={{
        padding: '25px',
        boxShadow: '0 0 24px 0 rgba(0, 0, 0, .1)',
        borderRadius: '6px',
        '&.xs-only': {
          padding: '8px',
        },
      }}
    >
      {finalDamages && finalDamages.length !== 0 ? (
        <>
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              mb: '24px',
              '&.xs-only': {
                display: 'none',
              },
            }}
          >
            <TextBoldNormal
              sx={{
                width: role === 'OPERATOR' ? '21%' : '23%',
                paddingLeft: '17px',
              }}
            >
              {t('form_input_label_car_part')}
            </TextBoldNormal>
            <TextBoldNormal
              sx={{
                width: role === 'OPERATOR' ? '19%' : '21%',
                paddingLeft: '17px',
              }}
            >
              {t('form_input_label_damage_type')}
            </TextBoldNormal>
            <TextBoldNormal
              sx={{
                width: role === 'OPERATOR' ? '31%' : '60%',
                paddingLeft: '17px',
              }}
            >
              {t('common_photos')}
            </TextBoldNormal>
          </Box>
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              mt: '12px',
              position: 'relative',
              '&.xs-only': {
                mt: 0,
              },
            }}
          >
            {finalDamages.map((carDamage, index) => {
              return (
                <Box
                  className={xsOnly ? 'xs-only' : ''}
                  key={index}
                  sx={{
                    border: '1px solid',
                    borderRadius: '6px',
                    borderColor: colorsConfig.lightGray,
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    mb: '12px',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    '&.xs-only': {
                      flexWrap: 'wrap',
                      border: 'none',
                      pt: 0,
                      pb: '10px',
                      mb: '10px',
                      borderBottom: '1px solid',
                      borderColor: colorsConfig.lightGray,
                      borderRadius: 0,
                      '&:last-child': {
                        borderBottom: 'none',
                        pb: 0,
                        mb: 0,
                      },
                    },
                  }}
                >
                  <TextNormal
                    className={xsOnly ? 'xs-only' : ''}
                    sx={{
                      width: role === 'OPERATOR' ? '21%' : '23%',
                      paddingLeft: '17px',
                      height: '52px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      '&.xs-only': {
                        width: '50%',
                        height: 'auto',
                        pl: 0,
                        display: 'block',
                      },
                    }}
                  >
                    <TextNormal
                      sx={{
                        display: xsOnly ? 'block' : 'none',
                        fontWeight: 700,
                        fontSize: '14px',
                      }}
                    >
                      Car part
                    </TextNormal>
                    {carDamage.damage.part.name}
                  </TextNormal>
                  <TextNormal
                    className={xsOnly ? 'xs-only' : ''}
                    sx={{
                      width: role === 'OPERATOR' ? '19%' : '21%',
                      paddingLeft: '17px',
                      height: '52px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      '&.xs-only': {
                        width: '50%',
                        height: 'auto',
                        pl: 0,
                        display: 'block',
                      },
                    }}
                  >
                    <TextNormal
                      sx={{
                        display: xsOnly ? 'block' : 'none',
                        fontWeight: 700,
                        fontSize: '14px',
                      }}
                    >
                      Damage type
                    </TextNormal>
                    {carDamage.damage.name}
                  </TextNormal>
                  <Box
                    className={xsOnly ? 'xs-only' : ''}
                    sx={{
                      width: role === 'OPERATOR' ? '35%' : '41%',
                      paddingLeft: '17px',
                      paddingRight: '17px',
                      '&.xs-only': {
                        px: 0,
                        width: '100%',
                        mt: '15px',
                      },
                    }}
                  >
                    <Gallery>
                      <Box
                        className={xsOnly ? 'xs-only' : ''}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: {
                            xs: 'center',
                            md: 'flex-start',
                          },
                          width: {
                            xs: '100%',
                            md: 'auto',
                          },
                          maxWidth: '100%',
                          overflow: 'hidden',
                          '&.xs-only': {
                            flexWrap: 'wrap',
                          },
                        }}
                      >
                        {carDamage.newDamage ? (
                          <React.Fragment>
                            {carDamage.damagePhotos.map((damagePhoto, indexPhoto) => {
                              if (damagePhoto.type === 'application/pdf') {
                                return (
                                  <Box
                                    key={index}
                                    sx={{
                                      position: 'relative',
                                      marginRight: '7px',
                                      marginBottom: carDamage.damagePhotos.length > 4 ? '7px' : '0',
                                    }}
                                  >
                                    <a
                                      href={URL.createObjectURL(damagePhoto)}
                                      download
                                      className="link"
                                      target="_blank"
                                      style={{
                                        width: '92px',
                                        height: '52px',
                                        borderRadius: '6px',
                                        overflow: 'hidden',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <img style={{ width: '50px' }} src={pdfIcon} alt="" />
                                    </a>
                                  </Box>
                                )
                              } else {
                                return (
                                  <DamagePhoto
                                    key={indexPhoto}
                                    damagePhoto={damagePhoto}
                                    newDamage={true}
                                  />
                                )
                              }
                            })}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {carDamage.photos.map((damagePhoto, indexPhoto) => {
                              if (damagePhoto.photo.path?.includes('.pdf')) {
                                return (
                                  <Box
                                    sx={{
                                      position: 'relative',
                                      marginRight: '10px',
                                    }}
                                  >
                                    <a
                                      href={`${CDN_BASE_URL}${damagePhoto.photo.path}`}
                                      download
                                      className="link"
                                      target="_blank"
                                    >
                                      <img style={{ width: '50px' }} src={pdfIcon} alt="" />
                                    </a>
                                  </Box>
                                )
                              } else {
                                return (
                                  <DamagePhoto
                                    key={indexPhoto}
                                    damagePhoto={damagePhoto}
                                    newDamage={false}
                                  />
                                )
                              }
                            })}
                          </React.Fragment>
                        )}
                      </Box>
                    </Gallery>
                  </Box>
                </Box>
              )
            })}
          </Box>
        </>
      ) : (
        <TextNormal
          sx={{
            color: colorsConfig.iconGray,
            textAlign: 'center',
          }}
        >
          No damages added yet
        </TextNormal>
      )}
    </Box>
  )
}

export default CarDamages
