import React, { useState } from 'react'
import UIModal from '../../../ui/UIModal/UIModal'
import { FileUploader } from 'react-drag-drop-files'
import { Box, Stack } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import UIButton from '../../../ui/UIButtons/UIButton'
import IconDropFile from '../../../assests/img/icons/icon-drag-and-drop.svg'
import UITextBodySmall from '../../../ui/UIText/UITextBodySmall'
import UIButtonLink from '../../../ui/UIButtons/UIButtonLink'
import { useDispatch, useSelector } from 'react-redux'
import { updateTradeLicense } from '../../../store/features/settings/settings.actions'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import UIPreloader from '../../../ui/UIPreloader/UIPreloader'

const UploadTradeLicenseModal = ({ open, setOpen }) => {
  const { t } = useTranslation()
  const isSaving = useSelector((state) => state.settings.tradeLicense.update.isLoading)
  const dispatch = useDispatch()

  const [file, setFile] = useState(null)

  const handleSubmit = async () => {
    const res = await dispatch(updateTradeLicense({ file }))
    if (updateTradeLicense.fulfilled.match(res)) {
      toast.success(t('notification_success_update_trade_license'))
      setOpen(false)
    }
  }

  return (
    <UIModal open={open} setOpen={setOpen}>
      {isSaving && <UIPreloader />}
      <Stack gap="20px" sx={{ mt: !!file ? '5px' : '20px' }}>
        {!!file && (
          <Stack direction="row" gap="8px">
            <UITextBodySmall color={colorsConfig.buttonDisabled} text="p_pending_to_upload" />
            <UIButtonLink sx={{ fontSize: '14px' }} text={file?.name} />
          </Stack>
        )}
        <FileUploader onDrop={setFile} onSelect={setFile}>
          <Box
            sx={{
              border: '2px dashed',
              borderColor: colorsConfig.buttonDisabled,
              minHeight: '150px',
              borderRadius: '6px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '12px',
              cursor: 'pointer',
            }}
          >
            <img src={IconDropFile} alt="" />
            <UITextRegular
              sx={{ textAlign: 'center', fontSize: '14px', maxWidth: '80%' }}
              color={colorsConfig.lightGray}
              text="p_upload_or_drag_drop_license"
            />
          </Box>
        </FileUploader>
        {!!file && (
          <UIButton
            disabled={isSaving}
            text="button_save"
            fullWidth
            onClick={() => handleSubmit()}
          />
        )}
      </Stack>
    </UIModal>
  )
}

export default UploadTradeLicenseModal
