import { createSlice } from '@reduxjs/toolkit'
import createWarrantyApplicationBuilder from './builders/createWarrantyApplication.builder'
import calculateWarrantyProductsBuilder from './builders/calculateWarrantyProducts.builder'
import getWarrantyApplicationBuilder from './builders/getWarrantyApplication.builder'
import activateWarrantyBuilder from './builders/activateWarranty.builder'
import getWarrantyCertificateBuilder from './builders/getWarrantyCertificate.builder'
import getWarrantyInvoiceBuilder from './builders/getWarrantyInvoice.builder'

const initialState = {
  warrantyApplication: null,
  isLoading: false,
  error: null,
  carDetails: {
    isLoading: false,
    saved: false,
    open: true,
    error: null,
  },
  warrantyDetails: {
    isLoading: false,
    saved: false,
    open: false,
    error: null,
  },
  calculation: {
    isLoading: false,
    error: null,
    calculatedProducts: [],
  },
  warrantyCertificate: {
    isLoading: false,
    error: null,
    signedUrl: null,
  },
  invoice: {
    isLoading: false,
    error: null,
    signedUrl: null,
  },
  showActivatedPopup: false,
}

const warrantyApplicationSlice = createSlice({
  name: 'warrantyApplication',
  initialState,
  reducers: {
    setCarDetailsOpen: (state, action) => {
      state.carDetails.open = action.payload
    },
    setWarrantyDetailsOpen: (state, action) => {
      state.warrantyDetails.open = action.payload
    },
    resetWarrantyApplication: (state) => {
      state.warrantyApplication = initialState.warrantyApplication
      state.carDetails = initialState.carDetails
      state.warrantyDetails = initialState.warrantyDetails
      state.calculation = initialState.calculation
      state.showActivatedPopup = false
      state.invoice = initialState.invoice
      state.warrantyCertificate = initialState.warrantyCertificate
    },
  },
  extraReducers: (builder) => {
    getWarrantyApplicationBuilder(builder)
    createWarrantyApplicationBuilder(builder)
    calculateWarrantyProductsBuilder(builder)
    activateWarrantyBuilder(builder)
    getWarrantyCertificateBuilder(builder)
    getWarrantyInvoiceBuilder(builder)
  },
})

export const { setCarDetailsOpen, setWarrantyDetailsOpen, resetWarrantyApplication } =
  warrantyApplicationSlice.actions

export default warrantyApplicationSlice.reducer
