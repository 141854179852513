import React, { useEffect, useState } from 'react'
import { Box, Divider, Grid, InputAdornment, Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import BreadcrumbsComponent from '../../../components/BreadcrumbsComponent'
import { Delete, HomeRounded } from '@mui/icons-material'
import { colorsConfig } from '../../../config/themeConfig'
import TextH1 from '../../../ui/Text/TextH1'
import warrantyProductsApiService from '../../../api/warrantyApplicationsService/warrantyProductsApiService'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import { getCoveredComponents } from '../../../store/features/coveredComponentsSlice/coveredComponents.actions'
import UITextTitleSmall from '../../../ui/UIText/UITextTitleSmall'
import SimpleCheckbox from '../../../ui/SimpleCheckbox'
import UITextHeadlineSmall from '../../../ui/UIText/UITextHeadlineSmall'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import UITextBodySmall from '../../../ui/UIText/UITextBodySmall'
import { onlyPositiveFloat } from '../../../utils/constants/regExp.constants'
import { sumDelimiter } from '../../../utils/sumDelimiter'
import { toast } from 'react-toastify'
import { apiConstants } from '../../../api/api'
import coveredComponentsService from '../../../api/warrantyApplicationsService/coveredComponentsApiService'
import { handleDefaultError } from '../../../api/APIErrors'
import ButtonPlain from '../../../ui/Buttons/ButtonPlain'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import ButtonDanger from '../../../ui/Buttons/ButtonDanger'
import ModalWrap from '../../../components/Modals/ModalWrap'

const EditCoveredComponent = () => {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [coveredComponent, setCoveredComponent] = useState(null)
  const [name, setName] = useState('')
  const [savedName, setSavedName] = useState('')
  const [systemName, setSystemName] = useState('')
  const [confirmDeletionModal, setConfirmDeletionModal] = useState(false)

  const handleSubmit = async () => {
    setIsLoading(true)
    const formData = {
      name,
      sysName: systemName,
    }
    try {
      await coveredComponentsService.updateCoveredComponent(params.id, formData)
      toast.success('Covered component has been updated')
      navigate('/admin/warranty/components')
    } catch (e) {
      handleDefaultError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDelete = async () => {
    setIsLoading(true)
    try {
      await coveredComponentsService.deleteCoveredComponent(params.id)
      toast.success(`Covered component "${savedName}" has been deleted`)
      setConfirmDeletionModal(false)
      navigate('/admin/warranty/components')
    } catch (e) {
      handleDefaultError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const getData = async () => {
    const response = await coveredComponentsService.getCoveredComponentById(params.id)
    const coveredComponentData = response.data
    setCoveredComponent(coveredComponentData)
    setName(coveredComponentData?.name)
    setSavedName(coveredComponentData?.name)
    setSystemName(coveredComponentData?.sysName)
  }

  useEffect(() => {
    getData()
    dispatch(setCurrentPageTitle('Edit warranty product'))
  }, [])

  return (
    <Stack>
      <BreadcrumbsComponent
        currentPage={{ name: savedName ?? `Edit covered component` }}
        parentPages={[
          {
            name: 'Covered components',
            icon: <HomeRounded sx={{ fill: colorsConfig.lightGray }} />,
            link: '/admin/warranty/components',
          },
        ]}
      />
      <TextH1
        sx={{
          fontSize: '36px',
          mb: '30px',
          mt: '10px',
        }}
      >
        Edit covered component "{savedName}"
      </TextH1>
      <Grid container spacing="30px">
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            fullWidth
            label="System name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="space-between">
          <ButtonPlain
            sx={{
              color: colorsConfig.mainRed,
              '&:hover': {
                color: colorsConfig.darkRed,
              },
            }}
            onClick={() => {
              setConfirmDeletionModal(true)
            }}
          >
            <Delete
              sx={{
                fill: colorsConfig.mainRed,
                mr: '4px',
              }}
            />
            Delete
          </ButtonPlain>
          <ButtonLG
            sx={{ minWidth: '200px' }}
            disabled={isLoading || !name || !systemName}
            onClick={() => handleSubmit()}
          >
            Save
          </ButtonLG>
        </Grid>
      </Grid>
      <ModalWrap open={confirmDeletionModal} setOpen={setConfirmDeletionModal}>
        <TextH1 sx={{ mb: '30px', fontSize: '32px' }}>
          Are you sure you want to delete covered component "{savedName}"?
        </TextH1>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <ButtonHollow
            sx={{
              flex: 1,
            }}
            onClick={() => {
              setConfirmDeletionModal(false)
            }}
          >
            Cancel
          </ButtonHollow>
          <ButtonDanger
            sx={{
              flex: 1,
              '&.Mui-disabled': {
                border: 'none',
                background: colorsConfig.lightGray,
                color: 'white',
              },
            }}
            disabled={isLoading}
            onClick={() => {
              handleDelete()
            }}
          >
            Delete
          </ButtonDanger>
        </Box>
      </ModalWrap>
    </Stack>
  )
}

export default EditCoveredComponent
