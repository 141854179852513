import React from 'react'
import { Box } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import UITextBodySmall from '../UIText/UITextBodySmall'

const UITableMarker = ({ text }) => {
  return (
    <Box
      sx={{
        borderRadius: '18px',
        px: '12px',
        py: '3px',
        background: colorsConfig.mainBlue,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <UITextBodySmall sx={{ color: colorsConfig.white, fontSize: '12px' }} text={text} />
    </Box>
  )
}

export default UITableMarker
