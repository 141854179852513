import React, { useState, useEffect } from 'react'
import { Box, Button, Typography, styled } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { menuConfig, operatorMenuConfig, expertMenuConfig } from '../../config/menuConfig'
import logo from '../../assests/img/logo.svg'
import { useNavigate, useLocation } from 'react-router-dom'
import iconLogOut from '../../assests/img/icons/icon-log-out.svg'
import { useDispatch, useSelector } from 'react-redux'
import { logOut } from '../../store/features/authSlice'
import { operatorRoutes } from '../../router/Routes'
import { COMPANY_NAME, REGION_LOCALE } from '../../utils/constants/global.constants'

const logoHelp = (
  <svg xmlns="http://www.w3.org/2000/svg" width="194" height="35" viewBox="0 0 194 35" fill="none">
    <path
      d="M1.28 29.1387C0.831958 29.1387 0.607937 29.1387 0.436808 29.0515C0.286278 28.9748 0.163893 28.8524 0.0871948 28.7019C0 28.5307 0 28.3067 0 27.8587V14.5328C0 12.2926 0 11.1725 0.435974 10.3169C0.819467 9.5642 1.43139 8.95228 2.18404 8.56879C3.03969 8.13281 4.15979 8.13281 6.4 8.13281H13.8518C14.2999 8.13281 14.5239 8.13281 14.695 8.22001C14.8456 8.29671 14.9679 8.41909 15.0446 8.56962C15.1318 8.74075 15.1318 8.96477 15.1318 9.41281V11.5989C15.1318 12.0469 15.1318 12.271 15.0446 12.4421C14.9679 12.5926 14.8456 12.715 14.695 12.7917C14.5239 12.8789 14.2999 12.8789 13.8518 12.8789H7.27121C6.82317 12.8789 6.59915 12.8789 6.42802 12.9661C6.27749 13.0428 6.1551 13.1652 6.07841 13.3157C5.99121 13.4868 5.99121 13.7109 5.99121 14.1589V15.0852C5.99121 15.5333 5.99121 15.7573 6.07841 15.9284C6.1551 16.079 6.27749 16.2013 6.42802 16.278C6.59915 16.3652 6.82317 16.3652 7.27121 16.3652H12.929C13.377 16.3652 13.601 16.3652 13.7722 16.4524C13.9227 16.5291 14.0451 16.6515 14.1218 16.802C14.209 16.9732 14.209 17.1972 14.209 17.6452V19.8167C14.209 20.2647 14.209 20.4887 14.1218 20.6599C14.0451 20.8104 13.9227 20.9328 13.7722 21.0095C13.601 21.0967 13.377 21.0967 12.929 21.0967H7.28586C6.83782 21.0967 6.6138 21.0967 6.44267 21.1839C6.29214 21.2606 6.16975 21.383 6.09305 21.5335C6.00586 21.7046 6.00586 21.9286 6.00586 22.3767V27.8587C6.00586 28.3067 6.00586 28.5307 5.91866 28.7019C5.84197 28.8524 5.71958 28.9748 5.56905 29.0515C5.39792 29.1387 5.1739 29.1387 4.72586 29.1387H1.28Z"
      fill="#3D3D3D"
    />
    <path
      d="M30.9463 27.8587C30.9463 28.3067 30.9463 28.5307 30.8591 28.7019C30.7824 28.8524 30.66 28.9748 30.5095 29.0515C30.3384 29.1387 30.1143 29.1387 29.6663 29.1387H18.4128C17.9648 29.1387 17.7407 29.1387 17.5696 29.0515C17.4191 28.9748 17.2967 28.8524 17.22 28.7019C17.1328 28.5307 17.1328 28.3067 17.1328 27.8587V9.41281C17.1328 8.96477 17.1328 8.74075 17.22 8.56962C17.2967 8.41909 17.4191 8.29671 17.5696 8.22001C17.7407 8.13281 17.9648 8.13281 18.4128 8.13281H21.8575C22.3059 8.13281 22.5301 8.13281 22.7013 8.22011C22.8519 8.29689 22.9743 8.4194 23.051 8.57007C23.1381 8.74136 23.1379 8.96556 23.1375 9.41397L23.1252 23.1114C23.1248 23.5598 23.1246 23.784 23.2117 23.9553C23.2884 24.106 23.4108 24.2285 23.5614 24.3053C23.7326 24.3926 23.9568 24.3926 24.4052 24.3926H29.6663C30.1143 24.3926 30.3384 24.3926 30.5095 24.4798C30.66 24.5565 30.7824 24.6789 30.8591 24.8294C30.9463 25.0005 30.9463 25.2245 30.9463 25.6726V27.8587Z"
      fill="#3D3D3D"
    />
    <path
      d="M34.2253 29.1387C33.7773 29.1387 33.5532 29.1387 33.3821 29.0515C33.2316 28.9748 33.1092 28.8524 33.0325 28.7019C32.9453 28.5307 32.9453 28.3067 32.9453 27.8587V9.41281C32.9453 8.96477 32.9453 8.74075 33.0325 8.56962C33.1092 8.41909 33.2316 8.29671 33.3821 8.22001C33.5532 8.13281 33.7773 8.13281 34.2253 8.13281H46.7239C47.1719 8.13281 47.396 8.13281 47.5671 8.22001C47.7176 8.29671 47.84 8.41909 47.9167 8.56962C48.0039 8.74075 48.0039 8.96477 48.0039 9.41281V11.5989C48.0039 12.0469 48.0039 12.271 47.9167 12.4421C47.84 12.5926 47.7176 12.715 47.5671 12.7917C47.396 12.8789 47.1719 12.8789 46.7239 12.8789H40.2312C39.7831 12.8789 39.5591 12.8789 39.388 12.9661C39.2374 13.0428 39.1151 13.1652 39.0384 13.3157C38.9512 13.4868 38.9512 13.7109 38.9512 14.1589V15.0852C38.9512 15.5333 38.9512 15.7573 39.0384 15.9284C39.1151 16.079 39.2374 16.2013 39.388 16.278C39.5591 16.3652 39.7831 16.3652 40.2312 16.3652H45.8011C46.2491 16.3652 46.4731 16.3652 46.6442 16.4524C46.7948 16.5291 46.9172 16.6515 46.9939 16.802C47.0811 16.9732 47.0811 17.1972 47.0811 17.6452V19.8167C47.0811 20.2647 47.0811 20.4887 46.9939 20.6599C46.9172 20.8104 46.7948 20.9328 46.6442 21.0095C46.4731 21.0967 46.2491 21.0967 45.8011 21.0967H40.2312C39.7831 21.0967 39.5591 21.0967 39.388 21.1839C39.2374 21.2606 39.1151 21.383 39.0384 21.5335C38.9512 21.7046 38.9512 21.9286 38.9512 22.3767V23.1126C38.9512 23.5606 38.9512 23.7846 39.0384 23.9558C39.1151 24.1063 39.2374 24.2287 39.388 24.3054C39.5591 24.3926 39.7831 24.3926 40.2312 24.3926H47.1634C47.6114 24.3926 47.8354 24.3926 48.0066 24.4798C48.1571 24.5565 48.2795 24.6789 48.3562 24.8294C48.4434 25.0005 48.4434 25.2245 48.4434 25.6726V27.8587C48.4434 28.3067 48.4434 28.5307 48.3562 28.7019C48.2795 28.8524 48.1571 28.9748 48.0066 29.0515C47.8354 29.1387 47.6114 29.1387 47.1634 29.1387H34.2253Z"
      fill="#3D3D3D"
    />
    <path
      d="M51.7253 29.1387C51.2773 29.1387 51.0532 29.1387 50.8821 29.0515C50.7316 28.9748 50.6092 28.8524 50.5325 28.7019C50.4453 28.5307 50.4453 28.3067 50.4453 27.8587V9.41281C50.4453 8.96477 50.4453 8.74075 50.5325 8.56962C50.6092 8.41909 50.7316 8.29671 50.8821 8.22001C51.0532 8.13281 51.2773 8.13281 51.7253 8.13281H64.2239C64.6719 8.13281 64.896 8.13281 65.0671 8.22001C65.2176 8.29671 65.34 8.41909 65.4167 8.56962C65.5039 8.74075 65.5039 8.96477 65.5039 9.41281V11.5989C65.5039 12.0469 65.5039 12.271 65.4167 12.4421C65.34 12.5926 65.2176 12.715 65.0671 12.7917C64.896 12.8789 64.6719 12.8789 64.2239 12.8789H57.7312C57.2831 12.8789 57.0591 12.8789 56.888 12.9661C56.7374 13.0428 56.6151 13.1652 56.5384 13.3157C56.4512 13.4868 56.4512 13.7109 56.4512 14.1589V15.0852C56.4512 15.5333 56.4512 15.7573 56.5384 15.9284C56.6151 16.079 56.7374 16.2013 56.888 16.278C57.0591 16.3652 57.2831 16.3652 57.7312 16.3652H63.3011C63.7491 16.3652 63.9731 16.3652 64.1442 16.4524C64.2948 16.5291 64.4172 16.6515 64.4939 16.802C64.5811 16.9732 64.5811 17.1972 64.5811 17.6452V19.8167C64.5811 20.2647 64.5811 20.4887 64.4939 20.6599C64.4172 20.8104 64.2948 20.9328 64.1442 21.0095C63.9731 21.0967 63.7491 21.0967 63.3011 21.0967H57.7312C57.2831 21.0967 57.0591 21.0967 56.888 21.1839C56.7374 21.2606 56.6151 21.383 56.5384 21.5335C56.4512 21.7046 56.4512 21.9286 56.4512 22.3767V23.1126C56.4512 23.5606 56.4512 23.7846 56.5384 23.9558C56.6151 24.1063 56.7374 24.2287 56.888 24.3054C57.0591 24.3926 57.2831 24.3926 57.7312 24.3926H64.6634C65.1114 24.3926 65.3354 24.3926 65.5066 24.4798C65.6571 24.5565 65.7795 24.6789 65.8562 24.8294C65.9434 25.0005 65.9434 25.2245 65.9434 25.6726V27.8587C65.9434 28.3067 65.9434 28.5307 65.8562 28.7019C65.7795 28.8524 65.6571 28.9748 65.5066 29.0515C65.3354 29.1387 65.1114 29.1387 64.6634 29.1387H51.7253Z"
      fill="#3D3D3D"
    />
    <path
      d="M67.2422 9.41281C67.2422 8.96477 67.2422 8.74075 67.3294 8.56962C67.4061 8.41909 67.5285 8.29671 67.679 8.22001C67.8501 8.13281 68.0741 8.13281 68.5222 8.13281H82.1634C82.6114 8.13281 82.8354 8.13281 83.0066 8.22001C83.1571 8.29671 83.2795 8.41909 83.3562 8.56962C83.4434 8.74075 83.4434 8.96477 83.4434 9.41281V11.5989C83.4434 12.0469 83.4434 12.271 83.3562 12.4421C83.2795 12.5926 83.1571 12.715 83.0066 12.7917C82.8354 12.8789 82.6114 12.8789 82.1634 12.8789H79.6257C79.1777 12.8789 78.9536 12.8789 78.7825 12.9661C78.632 13.0428 78.5096 13.1652 78.4329 13.3157C78.3457 13.4868 78.3457 13.7109 78.3457 14.1589V27.8587C78.3457 28.3067 78.3457 28.5307 78.2585 28.7019C78.1818 28.8524 78.0594 28.9748 77.9089 29.0515C77.7378 29.1387 77.5137 29.1387 77.0657 29.1387H73.6345C73.1865 29.1387 72.9624 29.1387 72.7913 29.0515C72.6408 28.9748 72.5184 28.8524 72.4417 28.7019C72.3545 28.5307 72.3545 28.3067 72.3545 27.8587V14.1589C72.3545 13.7109 72.3545 13.4868 72.2673 13.3157C72.1906 13.1652 72.0682 13.0428 71.9177 12.9661C71.7466 12.8789 71.5225 12.8789 71.0745 12.8789H68.5222C68.0741 12.8789 67.8501 12.8789 67.679 12.7917C67.5285 12.715 67.4061 12.5926 67.3294 12.4421C67.2422 12.271 67.2422 12.0469 67.2422 11.5989V9.41281Z"
      fill="#3D3D3D"
    />
    <path
      d="M96.2019 29.1387C95.7538 29.1387 95.5298 29.1387 95.3587 29.0515C95.2082 28.9748 95.0858 28.8524 95.0091 28.7019C94.9219 28.5307 94.9219 28.3067 94.9219 27.8587V9.41281C94.9219 8.96477 94.9219 8.74075 95.0091 8.56962C95.0858 8.41909 95.2082 8.29671 95.3587 8.22001C95.5298 8.13281 95.7538 8.13281 96.2019 8.13281H108.774C109.222 8.13281 109.446 8.13281 109.617 8.22001C109.767 8.29671 109.89 8.41909 109.967 8.56962C110.054 8.74075 110.054 8.96477 110.054 9.41281V11.5989C110.054 12.0469 110.054 12.271 109.967 12.4421C109.89 12.5926 109.767 12.715 109.617 12.7917C109.446 12.8789 109.222 12.8789 108.774 12.8789H102.193C101.745 12.8789 101.521 12.8789 101.35 12.9661C101.199 13.0428 101.077 13.1652 101 13.3157C100.913 13.4868 100.913 13.7109 100.913 14.1589V15.0852C100.913 15.5333 100.913 15.7573 101 15.9284C101.077 16.079 101.199 16.2013 101.35 16.278C101.521 16.3652 101.745 16.3652 102.193 16.3652H107.851C108.299 16.3652 108.523 16.3652 108.694 16.4524C108.845 16.5291 108.967 16.6515 109.044 16.802C109.131 16.9732 109.131 17.1972 109.131 17.6452V19.8167C109.131 20.2647 109.131 20.4887 109.044 20.6599C108.967 20.8104 108.845 20.9328 108.694 21.0095C108.523 21.0967 108.299 21.0967 107.851 21.0967H102.208C101.76 21.0967 101.536 21.0967 101.365 21.1839C101.214 21.2606 101.092 21.383 101.015 21.5335C100.928 21.7046 100.928 21.9286 100.928 22.3767V27.8587C100.928 28.3067 100.928 28.5307 100.841 28.7019C100.764 28.8524 100.641 28.9748 100.491 29.0515C100.32 29.1387 100.096 29.1387 99.6477 29.1387H96.2019Z"
      fill="#3D3D3D"
    />
    <path
      d="M119.901 14.2038C120.272 15.0273 120.457 15.439 120.715 15.5661C120.938 15.6765 121.201 15.6763 121.424 15.5656C121.681 15.4381 121.866 15.0261 122.236 14.2021L124.51 9.13295C124.633 8.85925 124.695 8.72239 124.791 8.62207C124.877 8.53339 124.981 8.46561 125.097 8.42401C125.228 8.37695 125.378 8.37695 125.678 8.37695H129.383C130.078 8.37695 130.425 8.37695 130.633 8.52323C130.814 8.65093 130.934 8.84798 130.964 9.06743C130.999 9.31881 130.839 9.62742 130.52 10.2446L124.201 22.4706C124.148 22.5737 124.122 22.6252 124.103 22.6794C124.086 22.7275 124.074 22.7771 124.067 22.8275C124.059 22.8842 124.059 22.9423 124.059 23.0583V28.1028C124.059 28.5509 124.059 28.7749 123.971 28.946C123.895 29.0965 123.772 29.2189 123.622 29.2956C123.451 29.3828 123.227 29.3828 122.779 29.3828H119.333C118.885 29.3828 118.661 29.3828 118.49 29.2956C118.339 29.2189 118.217 29.0965 118.14 28.946C118.053 28.7749 118.053 28.5509 118.053 28.1028V23.0577C118.053 22.9419 118.053 22.884 118.045 22.8273C118.037 22.777 118.025 22.7275 118.009 22.6795C117.99 22.6254 117.963 22.5739 117.91 22.471L111.604 10.2437C111.285 9.62674 111.126 9.31824 111.161 9.06699C111.191 8.84764 111.311 8.65072 111.492 8.52312C111.7 8.37695 112.047 8.37695 112.741 8.37695H116.449C116.749 8.37695 116.898 8.37695 117.029 8.4239C117.145 8.46539 117.25 8.53301 117.335 8.62149C117.432 8.72158 117.493 8.85814 117.616 9.13126L119.901 14.2038Z"
      fill="#3D3D3D"
    />
    <path
      d="M86.8816 29.1387C86.4335 29.1387 86.2095 29.1387 86.0384 29.0515C85.8878 28.9748 85.7655 28.8524 85.6888 28.7019C85.6016 28.5307 85.6016 28.3067 85.6016 27.8587V9.41281C85.6016 8.96477 85.6016 8.74075 85.6888 8.56962C85.7655 8.41909 85.8878 8.29671 86.0384 8.22001C86.2095 8.13281 86.4335 8.13281 86.8816 8.13281H90.3274C90.7755 8.13281 90.9995 8.13281 91.1706 8.22001C91.3211 8.29671 91.4435 8.41909 91.5202 8.56962C91.6074 8.74075 91.6074 8.96477 91.6074 9.41281V27.8587C91.6074 28.3067 91.6074 28.5307 91.5202 28.7019C91.4435 28.8524 91.3211 28.9748 91.1706 29.0515C90.9995 29.1387 90.7755 29.1387 90.3274 29.1387H86.8816Z"
      fill="#3D3D3D"
    />
    <circle cx="88.6836" cy="3.23828" r="3.23828" fill="#4DED6D" />
    <path
      d="M141 8.8C141 8.35817 141.358 8 141.8 8H142.757C143.198 8 143.557 8.35817 143.557 8.8V13.4804C143.557 14.239 144.553 14.5763 145.19 14.1641C146.014 13.6244 147.041 13.3546 148.272 13.3546C150.478 13.3546 151.974 14.1262 152.76 15.6696C153.186 16.5124 153.399 17.6817 153.399 19.1778V28.1353C153.399 28.5771 153.041 28.9353 152.599 28.9353H151.572C151.13 28.9353 150.772 28.5771 150.772 28.1353V19.3482C150.772 18.2309 150.63 17.4119 150.346 16.8911C149.882 16.0579 149.011 15.6412 147.732 15.6412C146.672 15.6412 145.711 16.0058 144.849 16.7349C143.987 17.464 143.557 18.8417 143.557 20.868V28.1353C143.557 28.5771 143.198 28.9353 142.757 28.9353H141.8C141.358 28.9353 141 28.5771 141 28.1353V8.8Z"
      fill="#3D3D3D"
    />
    <path
      d="M163.526 13.383C164.605 13.383 165.652 13.6386 166.665 14.1499C167.678 14.6518 168.45 15.3051 168.98 16.1099C169.491 16.8769 169.832 17.7717 170.003 18.7943C170.126 19.3668 170.2 20.2174 170.222 21.3461C170.231 21.7879 169.872 22.1462 169.43 22.1462H159.905C159.454 22.1462 159.088 22.5204 159.139 22.9689C159.273 24.1367 159.618 25.1028 160.174 25.8674C160.856 26.7954 161.912 27.2593 163.341 27.2593C164.676 27.2593 165.742 26.819 166.537 25.9385C166.858 25.5772 167.113 25.1757 167.302 24.7341C167.443 24.4047 167.75 24.1631 168.109 24.1631H169.116C169.595 24.1631 169.965 24.5846 169.805 25.0365C169.694 25.3532 169.542 25.687 169.349 26.0379C168.98 26.7196 168.563 27.2783 168.099 27.7138C167.323 28.4713 166.362 28.9826 165.216 29.2478C164.601 29.3993 163.905 29.475 163.128 29.475C161.235 29.475 159.63 28.7885 158.314 27.4156C156.997 26.0331 156.339 24.1015 156.339 21.6207C156.339 19.1778 157.002 17.1941 158.328 15.6696C159.653 14.1452 161.386 13.383 163.526 13.383ZM166.724 20.1152C167.195 20.1152 167.569 19.7084 167.484 19.2447C167.354 18.5333 167.152 17.9382 166.878 17.4592C166.177 16.2283 165.008 15.6128 163.37 15.6128C162.196 15.6128 161.211 16.0389 160.416 16.8911C159.794 17.5494 159.401 18.3493 159.236 19.2907C159.158 19.7371 159.528 20.1152 159.981 20.1152H166.724Z"
      fill="#3D3D3D"
    />
    <path
      d="M173.454 8.87102C173.454 8.42919 173.812 8.07102 174.254 8.07102H175.211C175.652 8.07102 176.011 8.42919 176.011 8.87102V28.1353C176.011 28.5771 175.652 28.9353 175.211 28.9353H174.254C173.812 28.9353 173.454 28.5771 173.454 28.1353V8.87102Z"
      fill="#3D3D3D"
    />
    <path
      d="M186.279 27.2167C187.472 27.2167 188.462 26.7196 189.248 25.7254C190.043 24.7217 190.441 23.2257 190.441 21.2372C190.441 20.0253 190.266 18.9837 189.915 18.1126C189.252 16.4366 188.041 15.5986 186.279 15.5986C184.509 15.5986 183.297 16.484 182.643 18.2546C182.293 19.2015 182.118 20.404 182.118 21.8622C182.118 23.0363 182.293 24.0352 182.643 24.859C183.306 26.4308 184.518 27.2167 186.279 27.2167ZM179.661 14.5948C179.661 14.153 180.019 13.7948 180.461 13.7948H181.646C181.922 13.7948 182.146 14.0187 182.146 14.2949C182.146 14.7771 182.802 15.0093 183.167 14.6934C183.379 14.5096 183.597 14.3474 183.822 14.2067C184.684 13.6386 185.697 13.3546 186.862 13.3546C188.585 13.3546 190.048 14.0174 191.25 15.343C192.453 16.6591 193.054 18.5434 193.054 20.9958C193.054 24.3098 192.188 26.677 190.455 28.0973C189.357 28.9968 188.078 29.4466 186.62 29.4466C185.474 29.4466 184.513 29.1957 183.737 28.6938C183.138 28.3196 182.217 28.6432 182.217 29.3494V34.2C182.217 34.6418 181.859 35 181.417 35H180.461C180.019 35 179.661 34.6418 179.661 34.2V14.5948Z"
      fill="#3D3D3D"
    />
  </svg>
)

const sections = [
  {
    title: 'FAQ',
    link: 'faq',
  },
  {
    title: 'Profile Management',
    link: 'profile-management',
  },
  {
    title: `${COMPANY_NAME[REGION_LOCALE]} coins`,
    link: `${COMPANY_NAME[REGION_LOCALE].toLowerCase()}-coins`,
  },
  {
    title: 'Dashboard',
    link: 'dashboard',
  },
  {
    title: 'Virtual Garage',
    link: 'virtual-garage',
  },
  {
    title: 'Appraisal',
    link: 'appraisal',
  },
  {
    title: 'Auction',
    link: 'auction',
  },
  {
    title: 'Loan/Finance',
    link: 'finance',
  },
  {
    title: 'Insurance',
    link: 'insurance',
  },
  {
    title: 'Warranty',
    link: 'warranty',
  },
  {
    title: 'Transportation',
    link: 'transportation',
  },
  {
    title: 'Subscription',
    link: 'subscription',
  },
  {
    title: 'Stock finance',
    link: 'stock-finance',
  },
]

const HelpSidebar = () => {
  const MenuButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    '&::after': {
      content: '" "',
      position: 'absolute',
      display: 'block',
      height: '2px',
      width: 'calc(100% - 12px)',
      bottom: '4px',
      left: '6px',
      background: colorsConfig.mainGreen,
      opacity: 0,
      transformOrigin: '0% 50%',
      transition: '0.3s all ease-in-out',
      transform: 'scaleX(0)',
    },
    '&:hover': {
      '&::after': {
        opacity: 1,
        transition: '0.3s all ease-in-out',
        transform: 'scaleX(1)',
      },
    },
    '&.active': {
      '&::after': {
        opacity: 1,
        transform: 'scaleX(1)',
      },
    },
  }))

  const auctionCarsAmount = useSelector((state) => state.auth.auctionCarsAmount)
  const role = useSelector((state) => state.auth.role)

  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  return (
    <Box
      sx={{
        width: '290px',
        minWidth: '290px',
        background: colorsConfig.bgLightBlue,
        paddingLeft: '60px',
        paddingTop: '18px',
        paddingBottom: '100px',
        display: {
          xs: 'none',
          md: 'block',
        },
      }}
    >
      <Box
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => {
          navigate('/virtual-garage')
        }}
      >
        {logoHelp}
      </Box>
      <Box
        sx={{
          mt: '30px',
          position: 'relative',
          ml: '-8px',
        }}
      >
        {role === 'COMPANY' ? (
          <>
            {sections.map((item, index) => {
              const { title, link } = item
              return (
                <Box
                  key={index}
                  sx={{
                    mb: '8px',
                    '&:last-child': {
                      mb: 0,
                    },
                  }}
                >
                  <Button
                    className={location.pathname === link ? 'active' : ''}
                    sx={{
                      textTransform: 'none',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      position: 'relative',
                      '&::after': {
                        content: '" "',
                        position: 'absolute',
                        display: 'block',
                        height: '2px',
                        width: 'calc(100% - 12px)',
                        bottom: '4px',
                        left: '6px',
                        background: colorsConfig.mainGreen,
                        opacity: 0,
                        transformOrigin: '0% 50%',
                        transition: '0.3s all ease-in-out',
                        transform: 'scaleX(0)',
                      },
                      '&:hover': {
                        '&::after': {
                          opacity: 1,
                          transition: '0.3s all ease-in-out',
                          transform: 'scaleX(1)',
                        },
                      },
                      '&.active': {
                        '&::after': {
                          opacity: 1,
                          transform: 'scaleX(1)',
                        },
                      },
                    }}
                    onClick={() => {
                      document.getElementById(link).scrollIntoView({ behavior: 'smooth' })
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: colorsConfig.mainBlack,
                        fontSize: '16px',
                        fontFamily: '"Helvetica", sans-serif',
                      }}
                    >
                      {title}
                    </Typography>
                  </Button>
                </Box>
              )
            })}
          </>
        ) : null}
      </Box>
    </Box>
  )
}

export default HelpSidebar
