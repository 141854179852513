import React from 'react'
import { Box, Button, Grid, IconButton, useMediaQuery } from '@mui/material'
import TextNormal from '../../../ui/Text/TextNormal'
import { FileUploader } from 'react-drag-drop-files'
import { colorsConfig } from '../../../config/themeConfig'
import IconFile from '../../../assests/img/icons/icon-file-gray.svg'
import { DownloadRounded, HighlightOffRounded } from '@mui/icons-material'
import IconDocument from '../../../assests/img/icons/icon-appraisal-document.svg'
import { useDispatch } from 'react-redux'
import { updateCustomerDocuments } from '../../../store/features/financeCar'

const FileField = (props) => {
  const { id, title, files, caption } = props

  const dispatch = useDispatch()

  const xsOnly = useMediaQuery('(max-width: 600px)')

  const handleSetFiles = (newFiles) => {
    dispatch(
      updateCustomerDocuments({
        id: id,
        files: [
          ...files,
          ...Array.from(newFiles).map((item) => {
            return {
              file: item,
              newFile: true,
            }
          }),
        ],
      }),
    )
  }

  const handleDeleteFile = (index, newFile) => {
    if (newFile) {
      dispatch(
        updateCustomerDocuments({
          id: id,
          files: files.filter((item, i) => i !== index),
        }),
      )
    } else {
      dispatch(
        updateCustomerDocuments({
          id: id,
          files: files.map((item, fileIndex) => {
            if (fileIndex === index) {
              return {
                ...item,
                deleted: true,
              }
            }
            return item
          }),
        }),
      )
    }
  }

  const handleDownloadLocalFile = (file) => {
    const blobUrl = window.URL.createObjectURL(file)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = blobUrl
    a.download = file.name
    document.body.appendChild(a)
    a.click()
    a.remove()
  }

  return (
    <Grid item xs={12} sm={6}>
      <Box>
        <TextNormal
          sx={{
            mb: '10px',
          }}
        >
          {title}
        </TextNormal>
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            position: 'relative',
            width: 'calc((200% / 3) - 12px)',
            '&.xs-only': {
              width: '100%',
            },
          }}
        >
          <FileUploader
            multiple
            handleChange={handleSetFiles}
            name="file"
            types={['JPG', 'PNG', 'jpeg', 'pdf', 'docx']}
            maxSize={10}
            children={
              <Button
                fullWidth
                sx={{
                  height: '38px',
                  border: '1px solid',
                  borderColor: colorsConfig.mainBlue,
                  borderRadius: '4px',
                  textTransform: 'none',
                  fontSize: '14px',
                  fontFamily: '"Helvetica", sans-serif',
                  color: colorsConfig.iconGray,
                  justifyContent: 'flex-start',
                  pl: '17px',
                  position: 'relative',
                }}
              >
                <img
                  style={{
                    marginRight: '5px',
                  }}
                  src={IconFile}
                  alt=""
                />
                Upload or drop files here
              </Button>
            }
          />
        </Box>
        {caption ? (
          <TextNormal
            sx={{
              fontSize: '14px',
              color: colorsConfig.iconGray,
              mt: '6px',
            }}
          >
            *{caption}
          </TextNormal>
        ) : null}
      </Box>
      {files.filter((item) => !item.deleted).length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '5px',
            mt: '10px',
          }}
        >
          {files
            .filter((item) => !item.deleted)
            .map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <IconButton
                    sx={{
                      width: '70px',
                      borderRadius: '6px',
                      '& img': {
                        width: '90%',
                      },
                      '&:hover': {
                        '& + .overlay-download': {
                          opacity: 1,
                          transition: '0.25s all ease-in-out',
                        },
                      },
                    }}
                    key={index}
                    onClick={() => {
                      handleDownloadLocalFile(item)
                    }}
                  >
                    <img src={IconDocument} alt="" />
                  </IconButton>
                  <Box
                    className={'overlay-download'}
                    sx={{
                      width: '100%',
                      height: '100%',
                      opacity: 0,
                      top: 0,
                      left: 0,
                      position: 'absolute',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: 'rgba(0,0,0,.1)',
                      borderRadius: '6px',
                      pointerEvents: 'none',
                    }}
                  >
                    <DownloadRounded
                      sx={{
                        fill: 'white',
                        opacity: '1',
                      }}
                    />
                  </Box>
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      borderRadius: '6px',
                      width: '23px',
                      height: '23px',
                    }}
                    onClick={() => {
                      handleDeleteFile(index, item.newFile)
                    }}
                  >
                    <HighlightOffRounded
                      sx={{
                        fill: colorsConfig.darkRed,
                        fontSize: '18px',
                      }}
                    />
                  </IconButton>
                </Box>
              )
            })}
        </Box>
      ) : null}
    </Grid>
  )
}

export default FileField
