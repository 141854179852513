import React from 'react'
import { Tabs } from '@mui/material'
import { colorsConfig } from '../config/themeConfig'

const CustomTabs = (props) => {
  const { children, sx, ...rest } = props

  return (
    <Tabs
      sx={{
        position: 'relative',
        width: '100%',
        '& .MuiTabs-indicator': {
          background: colorsConfig.mainGreen,
        },
        '& .MuiTab-textColorPrimary': {
          color: colorsConfig.mainBlack,
          textTransform: 'none',
          fontFamily: '"Helvetica", sans-serif',
          fontSize: '16px',
          fontWeight: 500,
          paddingLeft: '15px',
        },
        '& .Mui-selected': {
          color: colorsConfig.mainBlack,
        },
        '& .MuiButtonBase-root': {
          background: colorsConfig.bgLightBlue,
          fontSize: '10px',
        },
        ' .MuiButtonBase-root': {
          height: '41px',
          padding: '0 30px',
        },
        '& .MuiTabScrollButton-root': {
          height: '48px',
        },
        '& .MuiTouchRipple-root': {
          height: '48px',
        },
        ...sx,
      }}
      variant="scrollable"
      {...rest}
    >
      {children}
    </Tabs>
  )
}

export default CustomTabs
