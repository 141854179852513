import React from 'react'

const EditIcon = ({ editIconWidth = '24', editIconHeight = '24', editIconColour = '#3D3D3D' }) => {
  return (
    <svg
      width={editIconWidth}
      height={editIconHeight}
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame" clip-path="url(#clip0_1038_37454)">
        <g id="Group">
          <path
            id="Vector"
            d="M18.2083 12.2722C17.6783 12.2722 17.25 12.7016 17.25 13.2305V20.8972C17.25 21.4252 16.8206 21.8555 16.2917 21.8555H2.875C2.34596 21.8555 1.91673 21.4252 1.91673 20.8972V7.48047C1.91673 6.95246 2.34596 6.52219 2.875 6.52219H10.5417C11.0717 6.52219 11.5 6.09283 11.5 5.56392C11.5 5.03488 11.0717 4.60547 10.5417 4.60547H2.875C1.28993 4.60547 0 5.8954 0 7.48047V20.8972C0 22.4823 1.28993 23.7722 2.875 23.7722H16.2917C17.8768 23.7722 19.1667 22.4823 19.1667 20.8972V13.2305C19.1667 12.7006 18.7382 12.2722 18.2083 12.2722Z"
            fill={editIconColour}
          />
          <path
            id="Vector_2"
            d="M8.98607 11.3996C8.91946 11.4666 8.87384 11.5515 8.85481 11.6439L8.1773 15.0328C8.14572 15.1898 8.19553 15.3517 8.30856 15.4658C8.35316 15.5103 8.40609 15.5455 8.46431 15.5695C8.52254 15.5935 8.58492 15.6058 8.6479 15.6057C8.67844 15.6057 8.7102 15.6029 8.74178 15.5962L12.1295 14.9187C12.2234 14.8994 12.3087 14.8545 12.3748 14.7873L19.9572 7.20489L16.5694 3.81733L8.98607 11.3996ZM22.2993 1.47425C21.365 0.539833 19.845 0.539833 18.9115 1.47425L17.5852 2.80048L20.973 6.18822L22.2993 4.86181C22.7516 4.41048 23.0008 3.80857 23.0008 3.16844C23.0008 2.5283 22.7516 1.92644 22.2993 1.47425Z"
            fill={editIconColour}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1038_37454">
          <rect width="23" height="23" fill="white" transform="translate(0 0.726562)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EditIcon
