import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { API_ENDPOINTS } from '../../../config/default'
import { privateRequest } from '../../../utils/request'
import ExpertTasksCake from './ExpertTasksCake'
import ExpertAppraisalApplications from './ExpertAppraisalApplications'
import { useDispatch } from 'react-redux'
import { setCurrentPageTitle } from '../../../store/features/authSlice'

const ExpertDashboard = () => {
  const dispatch = useDispatch()

  const [appraisalsRequests, setAppraisalsRequests] = useState([])

  const [tab, setTab] = useState(0)

  const updateTab = (newTab) => {
    setTab(newTab)
  }

  const getAppraisalsRequestsArray = () => {
    const path = `?pagination=false&isArchived=false&combinedStatus[]=car_workflow.appraisal_compound&order[createdAt]=desc`

    privateRequest
      .get(
        `${API_ENDPOINTS.cars.default}${path}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        },
      )
      .then((response) => {
        setTimeout(() => setAppraisalsRequests(response.data[`hydra:member`]), 0)
      })
  }

  useEffect(() => {
    getAppraisalsRequestsArray()
    dispatch(setCurrentPageTitle('Task list'))
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
      <ExpertAppraisalApplications
        onTabChange={updateTab}
        tab={tab}
        appraisalsRequests={appraisalsRequests}
      />
      <ExpertTasksCake tab={tab} appraisalsRequests={appraisalsRequests} />
    </Box>
  )
}

export default ExpertDashboard
