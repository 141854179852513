import { colorsConfig } from '../../config/themeConfig'

export const pieChartConfig = {
  innerRadius: 78,
  outerRadius: 100,
  paddingAngle: 4,
  cornerRadius: 50,
  startAngle: -180,
  endAngle: 180,
  cx: 100,
  cy: 100,
}

export const insuranceStatusColors = [
  colorsConfig.buttonHover,
  colorsConfig.mainBlue,
  colorsConfig.fadedBlue,
  colorsConfig.darkRed,
  colorsConfig.buttonYellow,
  colorsConfig.mainGreen,
]

export const warrantyStatusColors = [
  colorsConfig.mainGreen,
  colorsConfig.buttonYellow,
  colorsConfig.darkRed,
]
