import React from 'react'
import { Tab } from '@mui/material'

const CustomTab = (props) => {
  const { children, sx, ...rest } = props

  return (
    <Tab
      sx={{
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Tab>
  )
}

export default CustomTab
