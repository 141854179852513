import React, { useEffect, useState } from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { privateRequest } from '../../utils/request'
import { API_ENDPOINTS } from '../../config/default'
import { useSelector } from 'react-redux'
import AuctionTimer from './AuctionCar/AuctionTimer'
import { colorsConfig } from '../../config/themeConfig'
import IconCash from '../../assests/img/icons/icon-cash.svg'
import TextNormal from '../../ui/Text/TextNormal'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import { sumDelimiter } from '../../utils/sumDelimiter'
import IconClocks from '../../assests/img/icons/icon-clocks.svg'
import { useParams } from 'react-router-dom'
import { CURRENCY, REGION_LOCALE } from '../../utils/constants/global.constants'

const CarActionInfoBlock = (props) => {
  const { currentAuction } = props

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const params = useParams()

  const combinedStatus = useSelector((state) => state.car.carData.combinedStatus)

  const [dealCancelReason, setDealCancelReason] = useState('')

  const getLastDeal = () => {
    privateRequest.get(API_ENDPOINTS.lastDeal(params.id)).then((response) => {
      setDealCancelReason(response.data.cancellationReason)
    })
  }

  useEffect(() => {
    if (combinedStatus && combinedStatus === 'deal_workflow.cancel') {
      getLastDeal()
    }
  }, [combinedStatus])

  return (
    <Box
      className={xsOnly ? 'xs-only' : ''}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        mb: '20px',
        '&.xs-only': {
          display: 'block',
        },
      }}
    >
      {combinedStatus === 'deal_workflow.pending' ||
      combinedStatus === 'deal_workflow.money_received' ||
      combinedStatus === 'deal_workflow.invoice_sent' ? (
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '18px',
              fontFamily: '"Helvetica", sans-serif',
              color: colorsConfig.mainBlack,
              mb: '5px',
            }}
          >
            Car sold successfully. Payment pending
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <img style={{ marginRight: '8px' }} src={IconClocks} alt="" />
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                fontFamily: '"Helvetica", sans-serif',
                color: colorsConfig.mainBlack,
              }}
            >
              Money is credited within: 2 days
            </Typography>
          </Box>
        </Box>
      ) : null}
      {combinedStatus === 'deal_workflow.cancel' ? (
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '18px',
              fontFamily: '"Helvetica", sans-serif',
              color: colorsConfig.mainRed,
              mb: '10px',
            }}
          >
            Deal cancelled
          </Typography>
          <TextNormal
            sx={{
              mb: '10px',
            }}
          >
            {dealCancelReason}
          </TextNormal>
        </Box>
      ) : null}
      {combinedStatus === 'deal_workflow.money_payed' ||
      combinedStatus === 'deal_workflow.transportation_in_progress' ? (
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '18px',
              fontFamily: '"Helvetica", sans-serif',
              color: colorsConfig.mainBlack,
              mb: '5px',
            }}
          >
            Car sold successfully. Transportation in progress
          </Typography>
        </Box>
      ) : null}
      {combinedStatus === 'car_workflow.sale_succeed' ? (
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '18px',
              fontFamily: '"Helvetica", sans-serif',
              color: colorsConfig.mainBlack,
              mb: '5px',
            }}
          >
            Car sold and delivered.
          </Typography>
        </Box>
      ) : null}
      {combinedStatus === 'auction_workflow.completed' ? (
        <Box
          sx={{
            px: '41px',
            height: '48px',
            borderRadius: '4px',
            border: '1px solid',
            borderColor: colorsConfig.mainGreen,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            mr: '10px',
          }}
        >
          <img src={IconCash} alt="" style={{ marginRight: '9px' }} />
          <TextNormal sx={{ mr: '5px' }}>
            {currentAuction && currentAuction.maxBid && currentAuction.maxBid.value
              ? 'The Highest bid:'
              : 'No bids'}
          </TextNormal>
          {currentAuction && currentAuction.maxBid && currentAuction.maxBid.value ? (
            <TextBoldNormal sx={{ color: colorsConfig.mainGreen }}>
              {sumDelimiter(currentAuction.maxBid.value)} {CURRENCY[REGION_LOCALE]}
            </TextBoldNormal>
          ) : null}
        </Box>
      ) : null}
      {combinedStatus === 'auction_workflow.active' ? (
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            px: '41px',
            height: '48px',
            borderRadius: '4px',
            border: '1px solid',
            borderColor: colorsConfig.mainGreen,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            mr: '10px',
            '&.xs-only': {
              mr: 0,
              px: '10px',
              width: '100%',
              mb: '10px',
            },
          }}
        >
          <img src={IconCash} alt="" style={{ marginRight: '9px' }} />
          <TextNormal
            sx={{
              mr: '5px',
            }}
          >
            {currentAuction && currentAuction.maxBid && currentAuction.maxBid.value
              ? 'Current Highest bid:'
              : 'No bids yet'}
          </TextNormal>
          {currentAuction && currentAuction.maxBid && currentAuction.maxBid.value ? (
            <TextBoldNormal sx={{ color: colorsConfig.mainGreen }}>
              {sumDelimiter(currentAuction.maxBid.value)} {CURRENCY[REGION_LOCALE]}
            </TextBoldNormal>
          ) : null}
        </Box>
      ) : null}
      {(combinedStatus === 'auction_workflow.pending' ||
        combinedStatus === 'auction_workflow.active') &&
      currentAuction ? (
        <AuctionTimer
          startTime={currentAuction.startTime}
          endTime={currentAuction.endTime}
          currentStatus={combinedStatus}
          mode={'small'}
        />
      ) : null}
    </Box>
  )
}

export default CarActionInfoBlock
