import React from 'react'
import { Button } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { useTranslation } from 'react-i18next'

const UIButtonActionMenu = ({ text, ...props }) => {
  const { t } = useTranslation()
  return (
    <Button
      sx={{
        textTransform: 'none',
        width: '100%',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        display: 'flex',
        justifyContent: 'flex-start',
        fontSize: '16px',
        fontWeight: 400,
        background: 'transparent',
        transition: '0.25s all ease-in-out',
        color: colorsConfig.mainBlack,
        px: '20px',
        '&:hover': {
          background: colorsConfig.white,
          transition: '0.25s all ease-in-out',
        },
      }}
      {...props}
    >
      {t(text)}
    </Button>
  )
}

export default UIButtonActionMenu
