import { createSlice } from '@reduxjs/toolkit'
import getLoanProgramsBuilder from './builders/getLoanPrograms.builder'

const initialState = {
  isLoading: false,
  error: null,
  data: [],
  params: {
    page: 1,
    itemsPerPage: 10,
    title: '',
    productProvider: '',
  },
}

const loanProgramsSlice = createSlice({
  name: 'loanProgramsReducer',
  initialState,
  reducers: {
    setLoanProgramsParams: (state, action) => {
      state.params = action.payload
    },
  },
  extraReducers: (builder) => {
    getLoanProgramsBuilder(builder)
  },
})

export const { setLoanProgramsParams } = loanProgramsSlice.actions

export default loanProgramsSlice.reducer
