import { getWarrantyApplication } from '../warrantyApplication.actions'

export default (builder) => {
  builder
    .addCase(getWarrantyApplication.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getWarrantyApplication.fulfilled, (state, action) => {
      const applicationData = action.payload
      state.isLoading = false
      state.error = null
      state.warrantyApplication = applicationData
      if (applicationData?.currentWorkflowState === 'application_preparation') {
        state.carDetails.open = false
        state.carDetails.saved = true
        state.warrantyDetails.open = true
      }
      if (applicationData?.currentWorkflowState === 'issued') {
        state.carDetails.open = false
        state.carDetails.saved = true
        state.warrantyDetails.open = true
        state.warrantyDetails.saved = true
      }
    })
    .addCase(getWarrantyApplication.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
}
