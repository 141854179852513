import { createSlice } from '@reduxjs/toolkit'
import downloadPrivateFileBuilder from './builders/downloadPrivateFile.builder'

const initialState = {
  download: {
    isLoading: false,
    error: null,
    data: null,
  },
}

const filesSlice = createSlice({
  name: 'filesReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    downloadPrivateFileBuilder(builder)
  },
})

export default filesSlice.reducer
