export const COIN_PRICING_IDENTIFIER_ENUMS = {
  APPRAISAL_PDF: 'appraisal_pdf',
  QUALITY_CHECK: 'quality_check',
  VIN_PROVIDER_REQUEST: 'vin_provider_request',
  TRANSIT_LOAN_DEALS_TO_APPROVAL: 'transit_loan_deals_to_approval',
  TRANSIT_INSURANCE_DEALS_TO_APPROVAL: 'transit_insurance_deals_to_approval',
  EXAMPLE: 'example',
}

export const COIN_PRICING_NAMES = {
  [COIN_PRICING_IDENTIFIER_ENUMS.APPRAISAL_PDF]: 'Appraisal PDF',
  [COIN_PRICING_IDENTIFIER_ENUMS.QUALITY_CHECK]: 'Quality check',
  [COIN_PRICING_IDENTIFIER_ENUMS.VIN_PROVIDER_REQUEST]: 'VIN-autofill',
  [COIN_PRICING_IDENTIFIER_ENUMS.TRANSIT_INSURANCE_DEALS_TO_APPROVAL]: 'Insurance request',
  [COIN_PRICING_IDENTIFIER_ENUMS.TRANSIT_LOAN_DEALS_TO_APPROVAL]: 'Finance request',
}

export const excludedCoinPricingIdentifiers = [COIN_PRICING_IDENTIFIER_ENUMS.EXAMPLE]
