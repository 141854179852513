import { createSlice } from '@reduxjs/toolkit'
import getActivePoliciesBuilder from './builders/getActivePolicies.builder'
import getClosedPoliciesBuilder from './builders/getClosedPolicies.builder'
import getPoliciesStatusCountBuilder from './builders/getPoliciesStatusCount.builder'

export const initialActivePoliciesParams = {
  isArchived: false,
  page: 1,
  itemsPerPage: 10,
  policyExtendedSearch: '',
  'order[createdAt]': 'desc',
  currentWorkflowState: '',
}

export const initialClosedPoliciesParams = {
  isArchived: false,
  page: 1,
  itemsPerPage: 10,
  policyExtendedSearch: '',
  'order[createdAt]': 'desc',
}

const initialState = {
  activePolicies: {
    isLoading: false,
    data: [],
    total: 0,
    params: initialActivePoliciesParams,
  },
  closedPolicies: {
    isLoading: false,
    data: [],
    total: 0,
    params: initialClosedPoliciesParams,
  },
  statusCount: {
    active: 0,
    closed: 0,
  },
  startRestitution: {
    isLoading: false,
    error: null,
  },
}

const rviDealerSlice = createSlice({
  name: 'rviDealerReducer',
  initialState,
  reducers: {
    setActivePoliciesParams: (state, action) => {
      state.activePolicies.params = action.payload
    },
    setClosedPoliciesParams: (state, action) => {
      state.closedPolicies.params = action.payload
    },
  },
  extraReducers: (builder) => {
    getActivePoliciesBuilder(builder)
    getClosedPoliciesBuilder(builder)
    getPoliciesStatusCountBuilder(builder)
  },
})

export const { setActivePoliciesParams, setClosedPoliciesParams } = rviDealerSlice.actions

export default rviDealerSlice.reducer
