import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { adminRequest } from '../../../../utils/request'
import { API_FINANCE_ADMIN_ENDPOINTS } from '../../../../config/default'
import { toast } from 'react-toastify'
import { setCurrentPageTitle } from '../../../../store/features/authSlice'
import BreadcrumbsComponent from '../../../../components/BreadcrumbsComponent'
import IconLoanProgram from '../../../../assests/img/icons/icon-loan-program.svg'
import TextH1 from '../../../../ui/Text/TextH1'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import TextBoldNormal from '../../../../ui/Text/TextBoldNormal'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import { colorsConfig } from '../../../../config/themeConfig'
import { AddRounded, CheckRounded, Delete } from '@mui/icons-material'
import { sumDelimiter } from '../../../../utils/sumDelimiter'
import TextNormal from '../../../../ui/Text/TextNormal'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import ButtonHollow from '../../../../ui/Buttons/ButtonHollow'
import ButtonDanger from '../../../../ui/Buttons/ButtonDanger'
import ModalWrap from '../../../../components/Modals/ModalWrap'
import ButtonPlain from '../../../../ui/Buttons/ButtonPlain'
import { CURRENCY, REGION_LOCALE } from '../../../../utils/constants/global.constants'

const creditTypes = ['classical', 'balloon']

const initialRateData = {
  termMin: '',
  termMax: '',
  baseRate: '',
  marketingRate: '',
  privilegeRate: '',
  isBankCustomerOnly: false,
  isActive: false,
}

const EditLoanProgram = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const [banks, setBanks] = useState([])
  const [title, setTitle] = useState('')
  const [savedTitle, setSavedTitle] = useState('')
  const [marketingTitle, setMarketingTitle] = useState('')
  const [externalId, setExternalId] = useState('')
  const [bank, setBank] = useState('')
  const [creditType, setCreditType] = useState('')
  const [incomeProofRequired, setIncomeProofRequired] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [carPriceMin, setCarPriceMin] = useState('')
  const [carPriceMax, setCarPriceMax] = useState('')
  const [loanAmountMin, setLoanAmountMin] = useState('')
  const [loanAmountMax, setLoanAmountMax] = useState('')
  const [downPaymentMin, setDownPaymentMin] = useState('')
  const [downPaymentMax, setDownPaymentMax] = useState('')
  const [additionalProductsMax, setAdditionalProductsMax] = useState('')
  const [rates, setRates] = useState([initialRateData])
  const [confirmDeletionModal, setConfirmDeletionModal] = useState(false)
  const [disableDeleteButton, setDisableDeleteButton] = useState(false)

  const hasErrors = () => {
    if (!title) return true
    if (!marketingTitle) return true
    if (!creditType) return true
    if (!carPriceMin) return true
    if (!carPriceMax) return true
    if (!loanAmountMin) return true
    if (!loanAmountMax) return true
    if (!downPaymentMin) return true
    if (!downPaymentMax) return true
    if (!additionalProductsMax) return true
    if (rates.length === 0) return true
    if (
      rates.filter((item) => {
        if (!item.termMin) return true
        if (!item.termMax) return true
        if (!item.baseRate) return true
        if (!item.marketingRate) return true
        if (!item.privilegeRate) return true
        if (!item.isActive) return true
      })[0]
    )
      return true
    return false
  }

  const handleSetRates = (index, payload) => {
    setRates(
      rates.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            ...payload,
          }
        }
        return item
      }),
    )
  }

  const getLoanProgram = () => {
    adminRequest
      .get(`${API_FINANCE_ADMIN_ENDPOINTS.loanPrograms}/${params.id}`)
      .then((response) => {
        setTitle(response.data.title)
        setSavedTitle(response.data.title)
        setMarketingTitle(response.data.marketingTitle)
        setExternalId(response.data.externalId)
        adminRequest
          .get(response.data.productProvider)
          .then((response) => setBank(response.data.title))
        setCreditType(response.data.creditType)
        setIncomeProofRequired(response.data.isIncomeProofRequired)
        setIsActive(response.data.isActive)
        setCarPriceMin(response.data.carPriceMin)
        setCarPriceMax(response.data.carPriceMax)
        setLoanAmountMin(response.data.loanAmountMin)
        setLoanAmountMax(response.data.loanAmountMax)
        setAdditionalProductsMax(response.data.additionalAmountMax)
        setDownPaymentMin(response.data.dpMin)
        setDownPaymentMax(response.data.dpMax)
        setRates(response.data.termLimits)
      })
  }

  const getBanks = () => {
    adminRequest
      .get(`${API_FINANCE_ADMIN_ENDPOINTS.productProviders}?type=bank&pagination=false`)
      .then((response) => {
        setBanks(response.data['hydra:member'])
      })
  }

  const handleUpdateLoanProgram = () => {
    const formData = {
      externalId: externalId,
      productProvider: banks.filter((item) => item.title === bank)[0]['@id'],
      creditType: creditType,
      formulaType: 'annuity',
      title: title,
      marketingTitle: marketingTitle,
      isIncomeProofRequired: incomeProofRequired,
      carPriceMin: parseInt(carPriceMin),
      carPriceMax: parseInt(carPriceMax),
      loanAmountMin: parseInt(loanAmountMin),
      loanAmountMax: parseInt(loanAmountMax),
      additionalAmountMax: parseFloat(additionalProductsMax),
      dpMin: parseInt(downPaymentMin),
      dpMax: parseInt(downPaymentMax),
      isActive: isActive,
      termLimits: rates.map((item) => {
        return {
          ...item,
          termMin: parseInt(item.termMin),
          termMax: parseInt(item.termMax),
          baseRate: item.baseRate.toString(),
          marketingRate: item.marketingRate.toString(),
          privilegeRate: item.privilegeRate.toString(),
        }
      }),
    }
    adminRequest
      .put(`${API_FINANCE_ADMIN_ENDPOINTS.loanPrograms}/${params.id}`, formData, {
        headers: {
          'Content-type': 'application/ld+json',
          Accept: 'application/ld+json',
        },
      })
      .then((response) => {
        toast.success('Updated loan program successfully', { autoClose: 1000 })
        navigate('/admin/financing/loan-programs')
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data['hydra:description']) {
          toast.error(error.response.data['hydra:description'])
        } else toast.error('Unexpected error')
      })
  }

  const handleDeleteLoanProgram = () => {
    setDisableDeleteButton(true)
    adminRequest
      .delete(`${API_FINANCE_ADMIN_ENDPOINTS.loanPrograms}/${params.id}`)
      .then((response) => {
        toast.success('Loan program has been deleted', { autoClose: 1000 })
        navigate('/admin/financing/loan-programs')
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data['hydra:description']) {
          toast.error(error.response.data['hydra:description'])
        } else toast.error('Unexpected error')
      })
      .finally(() => {
        setDisableDeleteButton(false)
      })
  }

  useEffect(() => {
    if (banks.length > 0) {
      getLoanProgram()
    }
  }, [banks])

  useEffect(() => {
    dispatch(setCurrentPageTitle('Edit loan program'))
    getBanks()
  }, [])

  return (
    <Box>
      <BreadcrumbsComponent
        currentPage={{ name: savedTitle ? savedTitle : `Edit loan program` }}
        parentPages={[
          {
            name: 'Loan programs',
            icon: (
              <img
                src={IconLoanProgram}
                style={{ filter: 'brightness(73%)', width: '13px' }}
                alt=""
              />
            ),
            link: '/admin/financing/loan-programs',
          },
        ]}
      />
      <TextH1
        sx={{
          fontSize: '36px',
          mb: '30px',
          mt: '10px',
        }}
      >
        Edit loan program "{savedTitle}"
      </TextH1>
      <Grid container spacing={'20px'}>
        <Grid item xs={12}>
          <TextBoldNormal>Main information</TextBoldNormal>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Title'}
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Marketing title'}
            value={marketingTitle}
            onChange={(e) => {
              setMarketingTitle(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldSmallEdible
            fullWidth
            label={'External ID (ID in the bank)'}
            value={externalId}
            onChange={(e) => {
              setExternalId(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl
            required
            fullWidth
            sx={{
              color: colorsConfig.iconGray,
              fontWeight: 400,
              fontSize: '14px',
              border: 'none',
            }}
            size={'small'}
          >
            <InputLabel
              id="bank"
              sx={{
                color: colorsConfig.iconGray,
                fontWeight: 400,
                fontSize: '14px',
                '&.Mui-focused': {
                  background: 'white',
                  color: colorsConfig.mainBlue,
                  boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                  overflow: 'visible',
                },
              }}
            >
              Bank
            </InputLabel>
            <Select
              labelId="bank"
              id="bank-field"
              label="Engine type"
              value={bank}
              onChange={(e) => {
                setBank(e.target.value)
              }}
              sx={{
                borderRadius: '4px',
                height: '38px',
                fontSize: '14px',
                border: 'none',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                  boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                },
              }}
            >
              <MenuItem value={''}>Not Chosen</MenuItem>
              {banks.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.title}>
                    {item.title}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl
            required
            fullWidth
            sx={{
              color: colorsConfig.iconGray,
              fontWeight: 400,
              fontSize: '14px',
              border: 'none',
            }}
            size={'small'}
          >
            <InputLabel
              id="credit-type"
              sx={{
                color: colorsConfig.iconGray,
                fontWeight: 400,
                fontSize: '14px',
                '&.Mui-focused': {
                  background: 'white',
                  color: colorsConfig.mainBlue,
                  boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                  overflow: 'visible',
                },
              }}
            >
              Credit type
            </InputLabel>
            <Select
              labelId="credit-type"
              id="credit-type-field"
              label="Credit type"
              value={creditType}
              onChange={(e) => {
                setCreditType(e.target.value)
              }}
              sx={{
                borderRadius: '4px',
                height: '38px',
                fontSize: '14px',
                textTransform: 'capitalize',
                border: 'none',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                  boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                },
              }}
            >
              <MenuItem value={''}>Not Chosen</MenuItem>
              {creditTypes.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={item}
                    sx={{
                      textTransform: 'capitalize',
                    }}
                  >
                    {item}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldSmallEdible required fullWidth label={'Formula'} readOnly value={'annuity'} />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            label="Is income proof required"
            control={
              <Checkbox
                checked={incomeProofRequired}
                icon={
                  <Box
                    sx={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '6px',
                      border: '1px solid',
                      borderColor: colorsConfig.mainBlack,
                    }}
                  />
                }
                checkedIcon={
                  <Box
                    sx={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '6px',
                      border: '1px solid',
                      borderColor: colorsConfig.mainBlack,
                    }}
                  >
                    <CheckRounded
                      sx={{
                        fill: colorsConfig.mainGreen,
                        stroke: colorsConfig.mainGreen,
                        strokeWidth: '1px',
                      }}
                    />
                  </Box>
                }
                onChange={(e) => {
                  setIncomeProofRequired(e.target.checked)
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            label="Is active"
            control={
              <Checkbox
                checked={isActive}
                icon={
                  <Box
                    sx={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '6px',
                      border: '1px solid',
                      borderColor: colorsConfig.mainBlack,
                    }}
                  />
                }
                checkedIcon={
                  <Box
                    sx={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '6px',
                      border: '1px solid',
                      borderColor: colorsConfig.mainBlack,
                    }}
                  >
                    <CheckRounded
                      sx={{
                        fill: colorsConfig.mainGreen,
                        stroke: colorsConfig.mainGreen,
                        strokeWidth: '1px',
                      }}
                    />
                  </Box>
                }
                onChange={(e) => {
                  setIsActive(e.target.checked)
                }}
              />
            }
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          background: '#e2e2e2',
          width: '100%',
          height: '1px',
          my: '30px',
        }}
      />
      <Grid container spacing={'20px'}>
        <Grid item xs={12}>
          <TextBoldNormal sx={{ mt: '10px' }}>Limits</TextBoldNormal>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Car price (min)'}
            value={sumDelimiter(carPriceMin)}
            onChange={(e) => {
              setCarPriceMin(e.target.value.replace(/[^0-9]/g, ''))
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'}>
                  <TextNormal
                    sx={{
                      fontSize: '14px',
                      color: colorsConfig.iconGray,
                    }}
                  >
                    {CURRENCY[REGION_LOCALE]}
                  </TextNormal>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Car price (max)'}
            value={sumDelimiter(carPriceMax)}
            onChange={(e) => {
              setCarPriceMax(e.target.value.replace(/[^0-9]/g, ''))
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'}>
                  <TextNormal
                    sx={{
                      fontSize: '14px',
                      color: colorsConfig.iconGray,
                    }}
                  >
                    CHF
                  </TextNormal>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Loan amount (min)'}
            value={sumDelimiter(loanAmountMin)}
            onChange={(e) => {
              setLoanAmountMin(e.target.value.replace(/[^0-9]/g, ''))
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'}>
                  <TextNormal
                    sx={{
                      fontSize: '14px',
                      color: colorsConfig.iconGray,
                    }}
                  >
                    CHF
                  </TextNormal>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Loan amount (max)'}
            value={sumDelimiter(loanAmountMax)}
            onChange={(e) => {
              setLoanAmountMax(e.target.value.replace(/[^0-9]/g, ''))
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'}>
                  <TextNormal
                    sx={{
                      fontSize: '14px',
                      color: colorsConfig.iconGray,
                    }}
                  >
                    CHF
                  </TextNormal>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Down payment (min)'}
            value={downPaymentMin}
            onChange={(e) => {
              if (parseInt(e.target.value) < 100 || e.target.value === '') {
                setDownPaymentMin(e.target.value.replace(/[^0-9]/g, ''))
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'}>
                  <TextNormal
                    sx={{
                      fontSize: '14px',
                      color: colorsConfig.iconGray,
                    }}
                  >
                    %
                  </TextNormal>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Down payment (max)'}
            value={downPaymentMax}
            onChange={(e) => {
              if (parseInt(e.target.value) < 100 || e.target.value === '') {
                setDownPaymentMax(e.target.value.replace(/[^0-9]/g, ''))
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'}>
                  <TextNormal
                    sx={{
                      fontSize: '14px',
                      color: colorsConfig.iconGray,
                    }}
                  >
                    %
                  </TextNormal>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Additional products (max)'}
            value={additionalProductsMax}
            onChange={(e) => {
              if (parseFloat(e.target.value) < 100 || e.target.value === '') {
                setAdditionalProductsMax(e.target.value.replace(/[^0-9.]/g, ''))
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'}>
                  <TextNormal
                    sx={{
                      fontSize: '14px',
                      color: colorsConfig.iconGray,
                    }}
                  >
                    %
                  </TextNormal>
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiFormHelperText-root': {
                ml: '7px',
                fontSize: '11px',
              },
            }}
            helperText={'How much can be added to the loan'}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          background: '#e2e2e2',
          width: '100%',
          height: '1px',
          my: '30px',
        }}
      />
      <TextBoldNormal sx={{ mb: '20px' }}>Rates and terms</TextBoldNormal>
      {rates.map((item, index) => {
        return (
          <Box
            key={index}
            sx={{
              position: 'relative',
              pr: '60px',
              pb: '15px',
              borderBottom: '1px solid',
              borderColor: '#e2e2e2',
              mb: '15px',
            }}
          >
            <IconButton
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
              }}
              onClick={() => {
                setRates(rates.filter((item, i) => i !== index))
              }}
            >
              <Delete
                sx={{
                  fill: colorsConfig.darkRed,
                }}
              />
            </IconButton>
            <Grid container spacing={'20px'}>
              <Grid item xs={12} sm={6} md={3}>
                <TextFieldSmallEdible
                  required
                  fullWidth
                  label={'Term (min)'}
                  value={item.termMin}
                  onChange={(e) => {
                    handleSetRates(index, { termMin: e.target.value.replace(/[^0-9]/g, '') })
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextFieldSmallEdible
                  required
                  fullWidth
                  label={'Term (max)'}
                  value={item.termMax}
                  onChange={(e) => {
                    handleSetRates(index, { termMax: e.target.value.replace(/[^0-9]/g, '') })
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextFieldSmallEdible
                  required
                  fullWidth
                  label={'Base rate'}
                  value={item.baseRate}
                  onChange={(e) => {
                    if (parseFloat(e.target.value) < 100 || e.target.value === '') {
                      handleSetRates(index, { baseRate: e.target.value.replace(/[^0-9.]/g, '') })
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        <TextNormal
                          sx={{
                            fontSize: '14px',
                            color: colorsConfig.iconGray,
                          }}
                        >
                          %
                        </TextNormal>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextFieldSmallEdible
                  required
                  fullWidth
                  label={'Marketing rate'}
                  value={item.marketingRate}
                  onChange={(e) => {
                    if (parseFloat(e.target.value) < 100 || e.target.value === '') {
                      handleSetRates(index, {
                        marketingRate: e.target.value.replace(/[^0-9.]/g, ''),
                      })
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        <TextNormal
                          sx={{
                            fontSize: '14px',
                            color: colorsConfig.iconGray,
                          }}
                        >
                          %
                        </TextNormal>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextFieldSmallEdible
                  required
                  fullWidth
                  label={'Privilege rate'}
                  value={item.privilegeRate}
                  onChange={(e) => {
                    if (parseFloat(e.target.value) < 100 || e.target.value === '') {
                      handleSetRates(index, {
                        privilegeRate: e.target.value.replace(/[^0-9.]/g, ''),
                      })
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        <TextNormal
                          sx={{
                            fontSize: '14px',
                            color: colorsConfig.iconGray,
                          }}
                        >
                          %
                        </TextNormal>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControlLabel
                  label="Is bank customer only"
                  control={
                    <Checkbox
                      checked={item.isBankCustomerOnly}
                      icon={
                        <Box
                          sx={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '6px',
                            border: '1px solid',
                            borderColor: colorsConfig.mainBlack,
                          }}
                        />
                      }
                      checkedIcon={
                        <Box
                          sx={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '6px',
                            border: '1px solid',
                            borderColor: colorsConfig.mainBlack,
                          }}
                        >
                          <CheckRounded
                            sx={{
                              fill: colorsConfig.mainGreen,
                              stroke: colorsConfig.mainGreen,
                              strokeWidth: '1px',
                            }}
                          />
                        </Box>
                      }
                      onChange={(e) => {
                        handleSetRates(index, { isBankCustomerOnly: e.target.checked })
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControlLabel
                  label="Is active"
                  control={
                    <Checkbox
                      checked={item.isActive}
                      icon={
                        <Box
                          sx={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '6px',
                            border: '1px solid',
                            borderColor: colorsConfig.mainBlack,
                          }}
                        />
                      }
                      checkedIcon={
                        <Box
                          sx={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '6px',
                            border: '1px solid',
                            borderColor: colorsConfig.mainBlack,
                          }}
                        >
                          <CheckRounded
                            sx={{
                              fill: colorsConfig.mainGreen,
                              stroke: colorsConfig.mainGreen,
                              strokeWidth: '1px',
                            }}
                          />
                        </Box>
                      }
                      onChange={(e) => {
                        handleSetRates(index, { isActive: e.target.checked })
                      }}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Box>
        )
      })}
      <IconButton
        sx={{
          width: '33px',
          height: '33px',
          border: '1px solid',
          borderColor: colorsConfig.lightGray,
          padding: '0',
          transition: '0.25s all ease-in-out',
          '&:hover': {
            background: 'none',
            borderColor: colorsConfig.mainBlue,
            transition: '0.25s all ease-in-out',
            '& .add-icon': {
              opacity: 1,
              transition: '0.25s all ease-in-out',
              fill: colorsConfig.mainBlue,
            },
          },
          '&.Mui-disabled': {
            opacity: '0.2',
          },
        }}
        onClick={() => {
          setRates([...rates, initialRateData])
        }}
      >
        <AddRounded
          className={'add-icon'}
          sx={{
            opacity: 0.5,
            fontSize: '25px',
            fill: colorsConfig.lightGray,
            transition: '0.25s all ease-in-out',
          }}
        />
      </IconButton>
      <Box
        sx={{
          mt: '40px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <ButtonPlain
          sx={{
            color: colorsConfig.mainRed,
            '&:hover': {
              color: colorsConfig.darkRed,
            },
          }}
          onClick={() => {
            setConfirmDeletionModal(true)
          }}
        >
          <Delete
            sx={{
              fill: colorsConfig.mainRed,
              mr: '4px',
            }}
          />
          Delete
        </ButtonPlain>
        <ButtonLG
          sx={{
            minWidth: '187px',
          }}
          disabled={hasErrors()}
          onClick={() => {
            handleUpdateLoanProgram()
          }}
        >
          Save
        </ButtonLG>
      </Box>
      <ModalWrap open={confirmDeletionModal} setOpen={setConfirmDeletionModal}>
        <TextH1 sx={{ mb: '30px', fontSize: '32px' }}>
          Are you sure you want to delete loan program "{savedTitle}"?
        </TextH1>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <ButtonHollow
            sx={{
              flex: 1,
            }}
            onClick={() => {
              setConfirmDeletionModal(false)
            }}
          >
            Cancel
          </ButtonHollow>
          <ButtonDanger
            sx={{
              flex: 1,
              '&.Mui-disabled': {
                border: 'none',
                background: colorsConfig.lightGray,
                color: 'white',
              },
            }}
            disabled={disableDeleteButton}
            onClick={() => {
              handleDeleteLoanProgram()
            }}
          >
            Delete
          </ButtonDanger>
        </Box>
      </ModalWrap>
    </Box>
  )
}

export default EditLoanProgram
