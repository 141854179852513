import React from 'react'
import ModalWrap from './ModalWrap'
import TextH1 from '../../ui/Text/TextH1'
import TextNormal from '../../ui/Text/TextNormal'
import { colorsConfig } from '../../config/themeConfig'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import { Box, useMediaQuery } from '@mui/material'
import IconCoin from '../../assests/img/icons/icon-fleetify-coin.svg'
import ButtonAttention from '../../ui/Buttons/ButtonAttention'
import ButtonHollow from '../../ui/Buttons/ButtonHollow'
import { useSelector } from 'react-redux'

const SendToBanksModal = (props) => {
  const { open, setOpen, handleAction } = props

  const xsOnly = useMediaQuery('(max-width: 600px)')

  const coinPricings = useSelector((state) => state.auth.coinPricings)

  return (
    <ModalWrap
      open={open}
      setOpen={setOpen}
      wrapperStyles={{
        width: '688px',
      }}
    >
      <TextH1
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          mb: '16px',
          '&.xs-only': {
            fontSize: '24px',
          },
        }}
      >
        Apply for financing?
      </TextH1>
      <TextNormal
        sx={{
          mb: '28px',
          lineHeight: 'normal',
        }}
      >
        Loan calculation and customer data are being processed and will be promptly sent to the
        banks.
      </TextNormal>
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          pb: '9px',
          borderBottom: '1px solid',
          borderColor: colorsConfig.lightGray,
          mb: '54px',
          '&.xs-only': {
            mb: '25px',
          },
        }}
      >
        <TextBoldNormal sx={{ textTransform: 'uppercase' }}>Total</TextBoldNormal>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={IconCoin} alt="" />
          <TextBoldNormal sx={{ ml: '5px' }}>
            {
              coinPricings.filter((item) => item.identifier === 'transit_loan_deals_to_approval')[0]
                ?.amount
            }
          </TextBoldNormal>
        </Box>
      </Box>
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '8px',
          '&.xs-only': {
            display: 'block',
          },
        }}
      >
        <ButtonHollow
          fullWidth={xsOnly}
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            flex: 1,
            '&.xs-only': {
              mb: '8px',
            },
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          Double-check application
        </ButtonHollow>
        <ButtonAttention
          fullWidth={xsOnly}
          sx={{
            flex: 1,
          }}
          onClick={() => {
            handleAction()
          }}
        >
          Send to banks
        </ButtonAttention>
      </Box>
    </ModalWrap>
  )
}

export default SendToBanksModal
