import React from 'react'
import { Box, useMediaQuery } from '@mui/material'

const UITableWrapper = ({ children }) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <Box
      className={xsOnly ? 'xs-only' : ''}
      sx={{
        position: 'relative',
        '&.xs-only': {
          maxWidth: '100vw',
          overflowY: 'auto',
          px: '16px',
        },
      }}
    >
      {children}
    </Box>
  )
}

export default UITableWrapper
