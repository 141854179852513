import { Box, Container, Grid, Stack, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import AppraisalDocuments from '../../Appraisal/AppraisalDocuments/AppraisalDocuments'
import CarPhotos from '../../Appraisal/AppraisalPhotos/CarPhotos'
import AppraisalDamages from '../../Appraisal/AppraisalDamages'
import WarningLights from '../../Appraisal/WarningLights/WarningLights'
import AppraisalInteriorConditions from '../../Appraisal/AppraisalInteriorConditions/AppraisalInteriorConditions'
import AppraisalMechanicalConditions from '../../Appraisal/AppraisalMechanicalConditions/AppraisalMechanicalConditions'
import { privateMultipartRequest, privateRequest } from '../../../utils/request'
import { API_ENDPOINTS, FILE_TYPES, HINT_TYPES } from '../../../config/default'
import {
  setAppraisal,
  setAppraisalTab,
  setCarAppraisalChecksResource,
  setCarData,
  setDamages,
  setDocuments,
  setInteriorConditions,
  setMechanicalConditions,
  setPhotos,
  setPilotLamps,
  setWorkflowState,
} from '../../../store/features/carSlice/carSlice'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import { fixHint } from '../../../store/features/qualityCheckSlice'
import { getCarParts, getForeshortenings, getPilotLamps } from '../getAppraisalReferences'
import {
  setReferenceControlPoints,
  setReferences,
} from '../../../store/features/appraisalReferencesSlices'
import TextNormal from '../../../ui/Text/TextNormal'
import { colorsConfig } from '../../../config/themeConfig'
import CarPageTabTitle from '../../CarPage/CarPageTabTitle'
import {
  parseControlPointsDocuments,
  parseControlPointsInteriorConditions,
  parseMechanicalConditions,
} from '../appraisal.helpers'
import Preloader from '../../../components/Preloader'
import AppraisalTabs from '../../Appraisal/AppraisalTabs'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import { useTranslation } from 'react-i18next'
import carPilotLampsApiService from '../../../api/appraisalService/CarPilotLampsApiService'
import { toast } from 'react-toastify'
import { getApiError } from '../../../api/api'

const AppraisalRoot = ({ carId, activeStep, setActiveStep }) => {
  const { t, i18n } = useTranslation()
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const carData = useSelector((state) => state.car.carData)
  const carDataAppraisal = useSelector((state) => state.car.appraisal)
  const appraisalReferences = useSelector((state) => state.references)
  const currentTab = useSelector((state) => state.car.currentAppraisalTab)
  const qualityCheckHints = useSelector((state) => state.qualityCheck.hints)
  const carAppraisalChecksResource = useSelector((state) => state.car.carAppraisalChecksResource)

  const dispatch = useDispatch()

  const params = {
    id: carId,
  }

  const steps = [
    {
      id: 1,
      title: 'Photos',
      translation: 'tab_photos',
      component: (
        <CarPhotos
          key={3}
          carId={carId}
          // submitPhotos={submitPhotos}
        />
      ),
    },
    {
      id: 2,
      title: 'Documents',
      component: <AppraisalDocuments key="appraisal_documents" carId={carId} />,
    },
    {
      id: 3,
      title: 'Damages',
      component: <AppraisalDamages key={4} carId={carId} />,
    },
    {
      id: 4,
      title: 'Warning Lights',
      component: <WarningLights key={5} carId={carId} />,
    },
    {
      id: 5,
      title: 'Interior Conditions',
      component: <AppraisalInteriorConditions key={6} carId={carId} />,
    },
    {
      id: 6,
      title: 'Mechanical Conditions',
      component: <AppraisalMechanicalConditions key={7} carId={carId} />,
    },
  ]

  const [step, setStep] = useState(steps[currentTab])
  const [tab, setTab] = useState(0)

  const [loading, setLoading] = useState(true)
  const [loadingText, setLoadingText] = useState(t('preloader_loading_appraisal'))
  const [appraisalLoaded, setAppraisalLoaded] = useState(false)

  const [coinsAmount, setCoinsAmount] = useState(50)
  const [localCurrencyAmount, setLocalCurrencyAmount] = useState(200)

  const handleSetLocalCurrencyAmount = () => {
    if (coinsAmount * 4 !== localCurrencyAmount) {
      setLocalCurrencyAmount(coinsAmount * 4)
    }
  }

  const handleSetCoinsAmount = () => {
    if (coinsAmount * 4 !== localCurrencyAmount) {
      setCoinsAmount(localCurrencyAmount / 4)
    }
  }

  useEffect(() => {
    handleSetLocalCurrencyAmount()
  }, [coinsAmount])

  useEffect(() => {
    handleSetCoinsAmount()
  }, [localCurrencyAmount])

  const handleSaveOrder = () => {
    if (carData.foreshortenings.length > 0) {
      const formData = {
        foreshortenings: carData.foreshortenings.map((item) => {
          return {
            direct: item.direct,
            carForeshortening: item['@id'],
          }
        }),
      }

      privateRequest.put(`/cars/${carId}${API_ENDPOINTS.carPhotosDirectBatch}`, formData)
    }
  }

  function compare(a, b) {
    if (a.direct < b.direct) {
      return -1
    }
    if (a.direct > b.direct) {
      return 1
    }
    return 0
  }

  const handleSaveChanges = (value) => {
    if (steps[value - 1].title === 'Photos') {
      handleSaveOrder()
    }
  }

  const handleChangeTab = (event, newValue) => {
    handleSaveChanges(step.id)
    if (tab !== 0) {
      submitControlPoints()
    }
    if (currentTab === 0) {
      handleSaveOrder()
    }
    if (currentTab === 3) {
      submitPilotLamps()
    }

    dispatch(setAppraisalTab(newValue))
    setStep(steps[newValue])
    if (xsOnly) {
      window.scrollTo(0, 350)
    } else {
      window.scrollTo(0, 700)
    }
  }

  const handleChangeStep = (direction) => {
    if (currentTab === 0) {
      handleSaveOrder()
    }
    if (currentTab === 3) {
      submitPilotLamps()
    }
    if (direction === -1) {
      if (currentTab === 0) {
        setActiveStep(activeStep - 1)
      } else {
        dispatch(setAppraisalTab(currentTab - 1))
      }
    }
    if (direction === 1) {
      if (currentTab === 5) {
        setActiveStep(activeStep + 1)
      } else {
        dispatch(setAppraisalTab(currentTab + 1))
      }
    }
  }

  const getCar = () => {
    privateRequest.get(`/cars/${carId}`).then((response) => {
      setCar(response.data)
      dispatch(
        setCurrentPageTitle(`Appraisal: ${response.data.make.name} ${response.data.model.name}`),
      )
      dispatch(setCarData(response.data))
      setCarTitle(`${response.data.make.name} ${response.data.model.name}`)
      if (response.data.combinedStatus === 'car_workflow.new') {
        dispatch(setAppraisalTab(0))
        setStep(steps[0])
        setTab(0)
      }
    })
  }

  const submitPilotLamps = async () => {
    const updatedPilotLamps = carDataAppraisal.pilotLamps.filter((item) => item?.newValue !== null)
    if (updatedPilotLamps?.length > 0) {
      const formData = {
        batchAggregates: updatedPilotLamps.map((item) => ({
          pilotLamp: item.lampViewIri,
          value: item.newValue,
        })),
      }
      try {
        await carPilotLampsApiService.batchPilotLamps(carId, formData)
      } catch (e) {
        console.log(e)
        // toast.error(getApiError(e))
      }
    }
  }

  const submitControlPoints = async () => {
    const formData = {
      carControlPoints: [],
    }
    const curBlocks = carDataAppraisal.controlPoints
    let counter = 0
    for (let i = 0; i < curBlocks.length; i++) {
      const curSubBlocks = curBlocks[i].subblocks
      for (let j = 0; j < curSubBlocks.length; j++) {
        const curControlPoints = curSubBlocks[j].controlPoints
        for (let m = 0; m < curControlPoints.length; m++) {
          const curControlPoint = curControlPoints[m]
          if (curControlPoint.updated === true) {
            if (curControlPoint.type !== 'file') {
              formData.carControlPoints.push({
                controlPoint: curControlPoint['@id'],
                value: curControlPoint.value === null ? null : curControlPoint.value,
              })
              counter++
            } else {
              const formDataFile = new FormData()
              if (curControlPoint.deleted) {
                formData.carControlPoints.push({
                  controlPoint: curControlPoint['@id'],
                  value: null,
                })
              } else {
                formDataFile.append('file', curControlPoint.value)
                formDataFile.append('type', FILE_TYPES.controlPointDocument)
                const fileId = await privateMultipartRequest
                  .post(API_ENDPOINTS.file, formDataFile)
                  .then((response) => {
                    return response.data['@id']
                  })
                formData.carControlPoints.push({
                  controlPoint: curControlPoint['@id'],
                  value: fileId,
                })
              }
              counter++
            }
          }
        }
      }
    }
    if (formData.carControlPoints.length > 0) {
      await privateRequest.post(API_ENDPOINTS.submitCarControlPoints(carId), formData)
    }
  }

  const uploadPilotLamp = async (pilotLamp) => {
    const formData = {
      pilotLamp: pilotLamp.lampViewIri,
      value: pilotLamp.newValue,
    }
    await privateRequest.post(API_ENDPOINTS.carPilotLamp(carId), formData)
  }

  const updatePilotLamp = async (pilotLamp) => {
    const formData = {
      pilotLamp: pilotLamp.lampViewIri,
      value: pilotLamp.newValue,
    }
    await privateRequest.put(pilotLamp.lampIri, formData)
  }

  const getReferences = () => {
    const referencePromises = [getForeshortenings(), getCarParts(), getPilotLamps()]
    Promise.all(referencePromises).then((result) => {
      dispatch(setReferences(result))
    })
  }

  const getCarAppraisal = () => {
    privateRequest.get(API_ENDPOINTS.appraisalByCar(carId)).then((response) => {
      dispatch(
        setAppraisal({
          foreshortenings: response.data.foreshortenings.sort(compare),
          damages: response.data.damages,
          pilotLamps: response.data.pilotLamps,
          controlPoints: response.data.controlPoints,
        }),
      )
      if (response.data.appraisalChecksResource) {
        dispatch(setCarAppraisalChecksResource(response.data.appraisalChecksResource))
      }
      setAppraisalLoaded(true)
    })
  }

  useEffect(() => {
    getReferences()
    getControlPoints()
  }, [i18n.language])

  useEffect(() => {
    if (currentTab !== step.id - 1) {
      setStep(steps[currentTab])
    }
  }, [currentTab])

  useEffect(() => {
    if (appraisalReferences.loaded && carData.loaded && appraisalLoaded) {
      // setControlPointsBlocks()
      dispatch(
        setDamages({
          carDamages: carData.damages,
          carParts: appraisalReferences.carParts,
        }),
      )
      dispatch(setPilotLamps(appraisalReferences.pilotLamps))
      // dispatch(setControlPoints(appraisalReferences.controlPoints))
      dispatch(setPhotos(appraisalReferences.foreshortenings))

      setTimeout(() => {
        setLoading(false)
      }, 500)
    }
  }, [appraisalReferences, carData.loaded, appraisalLoaded])

  const renderMarkedTitle = (title, count) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TextNormal
          sx={{
            mr: '7px',
          }}
        >
          {title}
        </TextNormal>
        <Box
          sx={{
            color: 'white',
            width: '25px',
            height: '25px',
            borderRadius: '50%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            background: colorsConfig.mainRed,
          }}
        >
          <TextNormal
            sx={{
              color: 'white',
              fontSize: '14px',
            }}
          >
            {count}
          </TextNormal>
        </Box>
      </Box>
    )
  }

  const renderTabTitle = (title) => {
    let matchHints = []
    let indexes = []
    switch (title) {
      case 'Vehicle Details':
        matchHints = qualityCheckHints.filter(
          (item) => item['@type'] === HINT_TYPES.vehicleDetails && !item.fixed,
        )
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(title, matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        }
        return {
          component: title,
          hasFixes: false,
        }
        break
      case 'Documents':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (
            item['@type'] === HINT_TYPES.subBlocks &&
            item.subblock === '/reference/appraisal/subblocks/1' &&
            !item.fixed
          ) {
            indexes.push(index)
            return true
          } else return false
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(t('tab_documents'), matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component:
              carData.combinedStatus === 'car_workflow.appraisal_on_site' ||
              carData.combinedStatus === 'car_workflow.appraisal_compound' ? (
                <CarPageTabTitle
                  title={t('tab_documents')}
                  isCompleted={carAppraisalChecksResource.documentsCheck}
                />
              ) : (
                t('tab_documents')
              ),
            hasFixes: false,
          }
        }
        break
      case 'Photos':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (
            (item['@type'] === HINT_TYPES.photos || item['@type'] === HINT_TYPES.photo) &&
            !item.fixed
          ) {
            indexes.push(index)
            return true
          } else {
            return false
          }
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(t('tab_photos'), matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component:
              carData.combinedStatus === 'car_workflow.appraisal_on_site' ||
              carData.combinedStatus === 'car_workflow.appraisal_compound' ? (
                <CarPageTabTitle
                  title={t('tab_photos')}
                  isCompleted={carAppraisalChecksResource.photosCheck}
                />
              ) : (
                t('tab_photos')
              ),
            hasFixes: false,
          }
        }
        break
      case 'Damages':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (
            (item['@type'] === HINT_TYPES.damage && !item.fixed) ||
            (item['@type'] === HINT_TYPES.damages && !item.fixed)
          ) {
            indexes.push(index)
            return true
          } else return false
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(t('tab_damages'), matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component:
              carData.combinedStatus === 'car_workflow.appraisal_on_site' ||
              carData.combinedStatus === 'car_workflow.appraisal_compound' ? (
                <CarPageTabTitle
                  title={t('tab_damages')}
                  isCompleted={carAppraisalChecksResource.damagesCheck}
                />
              ) : (
                t('tab_damages')
              ),
            hasFixes: false,
          }
        }
        break
      case 'Warning Lights':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (item['@type'] === HINT_TYPES.warningLights && !item.fixed) {
            indexes.push(index)
            return true
          } else {
            return false
          }
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(t('tab_warning_lights'), matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component:
              carData.combinedStatus === 'car_workflow.appraisal_on_site' ||
              carData.combinedStatus === 'car_workflow.appraisal_compound' ? (
                <CarPageTabTitle
                  title={t('tab_warning_lights')}
                  isCompleted={carAppraisalChecksResource.warningLightsCheck}
                />
              ) : (
                t('tab_warning_lights')
              ),
            hasFixes: false,
          }
        }
        break
      case 'Interior Conditions':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (
            item['@type'] === HINT_TYPES.subBlocks &&
            item.subblock === '/reference/appraisal/subblocks/2' &&
            !item.fixed
          ) {
            indexes.push(index)
            return true
          } else return false
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(t('tab_interior_conditions'), matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component:
              carData.combinedStatus === 'car_workflow.appraisal_on_site' ||
              carData.combinedStatus === 'car_workflow.appraisal_compound' ? (
                <CarPageTabTitle
                  title={t('tab_interior_conditions')}
                  isCompleted={carAppraisalChecksResource.interiorConditionsCheck}
                />
              ) : (
                t('tab_interior_conditions')
              ),
            hasFixes: false,
          }
        }
        break
      case 'Mechanical Conditions':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (
            item['@type'] === HINT_TYPES.subBlocks &&
            item.subblock !== '/reference/appraisal/subblocks/1' &&
            item.subblock !== '/reference/appraisal/subblocks/2' &&
            !item.fixed
          ) {
            indexes.push(index)
            return true
          } else {
            return false
          }
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle(t('tab_mechanical_conditions'), matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component:
              carData.combinedStatus === 'car_workflow.appraisal_on_site' ||
              carData.combinedStatus === 'car_workflow.appraisal_compound' ? (
                <CarPageTabTitle
                  title={t('tab_mechanical_conditions')}
                  isCompleted={carAppraisalChecksResource.mechanicalConditionsCheck}
                />
              ) : (
                t('tab_mechanical_conditions')
              ),
            hasFixes: false,
          }
        }
        break
      default:
        return {
          component: t(title),
          hasFixes: false,
        }
        break
    }
  }

  const getControlPoints = () => {
    privateRequest
      .get(API_ENDPOINTS.controlPoints, {
        params: {
          pagination: false,
        },
      })
      .then((response) => {
        dispatch(setReferenceControlPoints(response.data['hydra:member']))
      })
  }

  const handleSetCarControlPoints = () => {
    dispatch(
      setDocuments(
        parseControlPointsDocuments(appraisalReferences.controlPoints, carData.controlPoints),
      ),
    )
    dispatch(
      setInteriorConditions(
        parseControlPointsInteriorConditions(
          appraisalReferences.controlPoints,
          carData.controlPoints,
        ),
      ),
    )
    dispatch(
      setMechanicalConditions(
        parseMechanicalConditions(appraisalReferences.controlPoints, carData.controlPoints),
      ),
    )
  }

  useEffect(() => {
    if (appraisalReferences.controlPoints.length > 0 && carData.loaded) {
      handleSetCarControlPoints()
    }
  }, [appraisalReferences.controlPoints, carData.loaded])

  useEffect(() => {
    getCar()

    getCarAppraisal()
    return () => {
      submitControlPoints()
      submitPilotLamps()
    }
  }, [])

  useEffect(() => {
    if (
      carData.combinedStatus &&
      carData.combinedStatus === 'car_workflow.moderation_failed' &&
      currentTab === 1 &&
      step.id === 1
    ) {
      setStep(steps[1])
    }
  }, [currentTab])

  const [car, setCar] = useState(null)
  const [carTitle, setCarTitle] = useState('')
  const [pickupDemand, setPickupDemand] = useState(null)

  const [currentWorkflow, setCurrentWorkflow] = useState(null)
  const [currentStatusColor, setCurrentStatusColor] = useState('')
  const carWorkflows = useSelector((state) => state.workflows.carWorkflowStates)
  const auctionWorkflows = useSelector((state) => state.workflows.auctionWorkflowStates)
  const dealWorkflows = useSelector((state) => state.workflows.dealWorkflowStates)
  const workflowsLoaded = useSelector((state) => state.workflows.loaded)
  const combinedStatus = useSelector((state) => state.car.carData.combinedStatus)

  const getPickupDemand = () => {
    privateRequest.get(API_ENDPOINTS.pickupDemand(carData.id)).then((response) => {
      setPickupDemand(response.data)
    })
  }

  useEffect(() => {
    if (car && workflowsLoaded && combinedStatus) {
      let findWorkflow
      if (combinedStatus.includes('car_workflow')) {
        findWorkflow = carWorkflows.filter(
          (workflow) => workflow.sysName === combinedStatus.replace('car_workflow.', ''),
        )[0]
      }
      if (combinedStatus.includes('auction_workflow')) {
        findWorkflow = auctionWorkflows.filter(
          (workflow) => workflow.sysName === combinedStatus.replace('auction_workflow.', ''),
        )[0]
      }
      if (combinedStatus.includes('deal_workflow')) {
        findWorkflow = dealWorkflows.filter(
          (workflow) => workflow.sysName === combinedStatus.replace('deal_workflow.', ''),
        )[0]
        // getDeal()
      }

      if (combinedStatus === 'car_workflow.requested_appraisal_compound') {
        getPickupDemand()
      }

      if (findWorkflow) {
        setCurrentWorkflow(findWorkflow.value)
        setCurrentStatusColor(`#${findWorkflow.color}`)
      }
    }
  }, [car, workflowsLoaded, combinedStatus])

  return (
    <Box
      className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
      sx={{
        width: '100%',
        '& .MuiContainer-root': {
          maxWidth: '1240px',
        },
        '&.xs-only': {
          minHeight: 'calc(100vh - 150px)',
        },
        '&.tablet-only': {
          px: '16px',
          pb: '30px',
        },
      }}
    >
      {loading ? (
        <Preloader type="gooey" text={loadingText} />
      ) : (
        <React.Fragment>
          <AppraisalTabs
            currentTab={currentTab}
            handleChangeTab={handleChangeTab}
            steps={steps}
            renderTabTitle={renderTabTitle}
          />
          <Container
            className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
            maxWidth="lg"
            sx={{
              ml: 0,
              '&.xs-only': {
                px: 0,
              },
              '&.tablet-only': {
                px: 0,
              },
            }}
          >
            <Box
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                '&.xs-only': {
                  pb: '40px',
                },
              }}
            >
              <Grid container>
                <Grid xs={12} item>
                  {steps.map((stepItem) => {
                    if (stepItem.id === step.id) {
                      return stepItem.component
                    }
                  })}
                </Grid>
              </Grid>
            </Box>
            <Stack
              direction="row"
              gap="10px"
              justifyContent="flex-end"
              sx={{
                mt: {
                  md: '30px',
                },
                ...(xsOnly && {
                  position: 'fixed',
                  bottom: 0,
                  right: 0,
                  width: '100vw',
                  p: '16px',
                  background: 'white',
                  zIndex: 10,
                  boxShadow: '0 0 24px 0 rgba(255,255,255,.5)',
                  '& button': {
                    flex: 1,
                    maxWidth: 'calc(50% - 5px)',
                  },
                }),
              }}
            >
              <ButtonHollow onClick={() => handleChangeStep(-1)}>{t('button_back')}</ButtonHollow>
              <ButtonLG onClick={() => handleChangeStep(1)}>{t('button_next')}</ButtonLG>
            </Stack>
          </Container>
        </React.Fragment>
      )}
    </Box>
  )
}

export default AppraisalRoot
