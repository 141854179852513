import React from 'react'
import { Box, IconButton } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import Tooltip from '@material-ui/core/Tooltip'
import { useTranslation } from 'react-i18next'

const UIIconButtonRoundedBorder = ({
  children,
  sx,
  tooltipPlacement = 'bottom',
  text = '',
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <Tooltip title={t(text)} placement={tooltipPlacement}>
      <IconButton
        sx={{
          '& svg': {
            fill: colorsConfig.mainBlue,
          },
          ...sx,
        }}
        {...props}
      >
        <Box
          sx={{
            width: '30px',
            height: '30px',
            border: '1px solid',
            borderRadius: '50%',
            borderColor: colorsConfig.mainBlue,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {children}
        </Box>
      </IconButton>
    </Tooltip>
  )
}

export default UIIconButtonRoundedBorder
