import { getInitialMakeAndModel } from '../rviCar.actions'

export default (builder) => {
  builder
    .addCase(getInitialMakeAndModel.pending, (state) => {
      state.init.isLoading = true
    })
    .addCase(getInitialMakeAndModel.fulfilled, (state, action) => {
      state.init.isLoading = false
      const { make, model } = action.payload
      if (make) {
        state.carFirstData.make = {
          id: make?.['@id'],
          name: make?.name,
        }
      }
      if (model) {
        state.carFirstData.model = {
          id: model?.['@id'],
          name: model?.name,
        }
        state.carFirstData.valid = true
      }
    })
    .addCase(getInitialMakeAndModel.rejected, (state, action) => {
      state.init.isLoading = false
    })
}
