import React, { useEffect, useState } from 'react'
import { Divider, Grid, InputAdornment, Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import BreadcrumbsComponent from '../../../components/BreadcrumbsComponent'
import { HomeRounded } from '@mui/icons-material'
import { colorsConfig } from '../../../config/themeConfig'
import TextH1 from '../../../ui/Text/TextH1'
import warrantyProductsApiService from '../../../api/warrantyApplicationsService/warrantyProductsApiService'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import { getCoveredComponents } from '../../../store/features/coveredComponentsSlice/coveredComponents.actions'
import UITextTitleSmall from '../../../ui/UIText/UITextTitleSmall'
import SimpleCheckbox from '../../../ui/SimpleCheckbox'
import UITextHeadlineSmall from '../../../ui/UIText/UITextHeadlineSmall'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import UITextBodySmall from '../../../ui/UIText/UITextBodySmall'
import { onlyPositiveFloat } from '../../../utils/constants/regExp.constants'
import { sumDelimiter } from '../../../utils/sumDelimiter'
import { toast } from 'react-toastify'
import { apiConstants } from '../../../api/api'
import { CURRENCY, REGION_LOCALE } from '../../../utils/constants/global.constants'

const EditWarrantyProduct = () => {
  const isCoveredComponentsLoading = useSelector((state) => state.coveredComponents.isLoading)
  const coveredComponents = useSelector((state) => state.coveredComponents.coveredComponents)

  const params = useParams()
  const navigate = useNavigate()
  const [warrantyProduct, setWarrantyProduct] = useState(null)
  const [savedName, setSavedName] = useState('')
  const [name, setName] = useState('')
  const [premium, setPremium] = useState('0')
  const [mileageMax, setMileageMax] = useState('0')
  const [carAgeMax, setCarAgeMax] = useState('0')
  const [hpMax, setHpMax] = useState('0')
  const [hpMin, setHpMin] = useState('0')
  const [selectedCoveredComponents, setSelectedCoveredComponents] = useState([])
  const dispatch = useDispatch()

  const handleSubmit = async () => {
    const formData = {
      ...warrantyProduct,
      coveredComponents: selectedCoveredComponents,
      terms: [
        {
          ...warrantyProduct?.terms?.[0],
          hpMax: parseInt(hpMax),
          hpMin: parseInt(hpMin),
          premium: parseFloat(premium),
        },
      ],
      mileageMax: parseInt(mileageMax),
      carAgeMax: parseInt(carAgeMax),
      currency: 'CHF',
      name,
    }
    try {
      await warrantyProductsApiService.updateWarrantyProduct(params.id, formData)
      toast.success(`Warranty product has been updated`)
      navigate('/admin/warranty/products')
    } catch (e) {
      if (!e.response) {
        toast.error(e)
      }
      if (e?.response?.data) toast.error(e?.response?.data?.[apiConstants.hydraDescription])
    }
  }

  const getData = async () => {
    const warrantyProductResponse = await warrantyProductsApiService.getWarrantyProductById(
      params.id,
    )
    const warrantyProductData = warrantyProductResponse.data
    setWarrantyProduct(warrantyProductData)
    setName(warrantyProductData?.name)
    setSavedName(warrantyProductData?.name)
    setPremium(warrantyProductData?.terms?.[0]?.premium)
    setHpMax(warrantyProductData?.terms?.[0]?.hpMax)
    setHpMin(warrantyProductData?.terms?.[0]?.hpMin)
    setCarAgeMax(warrantyProductData?.carAgeMax)
    setMileageMax(warrantyProductData?.mileageMax)
    setSelectedCoveredComponents(warrantyProductData?.coveredComponents)
  }

  useEffect(() => {
    getData()
    dispatch(getCoveredComponents())
    dispatch(setCurrentPageTitle('Edit warranty product'))
  }, [])

  return (
    <Stack>
      <BreadcrumbsComponent
        currentPage={{ name: savedName ?? `Edit warranty product` }}
        parentPages={[
          {
            name: 'Warranty products',
            icon: <HomeRounded sx={{ fill: colorsConfig.lightGray }} />,
            link: '/admin/warranty/products',
          },
        ]}
      />
      <TextH1
        sx={{
          fontSize: '36px',
          mb: '30px',
          mt: '10px',
        }}
      >
        Edit warranty product "{savedName}"
      </TextH1>
      <Grid container spacing="30px">
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            fullWidth
            label="Price"
            value={sumDelimiter(premium)}
            onChange={(e) => setPremium(e.target.value.replace(onlyPositiveFloat, ''))}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <UITextBodySmall
                    sx={{ color: colorsConfig.iconGray }}
                    text={CURRENCY[REGION_LOCALE]}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            fullWidth
            label="Max mileage"
            value={sumDelimiter(mileageMax)}
            onChange={(e) => setMileageMax(e.target.value.replace(onlyPositiveFloat, ''))}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <UITextBodySmall sx={{ color: colorsConfig.iconGray }} text="km" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            fullWidth
            label="Max car age"
            value={carAgeMax}
            onChange={(e) => setCarAgeMax(e.target.value.replace(onlyPositiveFloat, ''))}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <UITextBodySmall sx={{ color: colorsConfig.iconGray }} text="months" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            fullWidth
            label="Min engine power"
            value={hpMin}
            onChange={(e) => {
              if (e.target.value.length > 4) return
              setHpMin(e.target.value.replace(onlyPositiveFloat, ''))
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <UITextBodySmall sx={{ color: colorsConfig.iconGray }} text="horse powers" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            fullWidth
            label="Max engine power"
            value={hpMax}
            onChange={(e) => {
              if (e.target.value.length > 4) return
              setHpMax(e.target.value.replace(onlyPositiveFloat, ''))
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <UITextBodySmall sx={{ color: colorsConfig.iconGray }} text="horse powers" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack gap="10px">
            <UITextHeadlineSmall>Covered components:</UITextHeadlineSmall>
            <Divider />
          </Stack>
        </Grid>
        {!isCoveredComponentsLoading ? (
          <Grid item container xs={12}>
            {coveredComponents.map((item, index) => {
              return (
                <Grid key={index} item xs={12} sm={6} md={3}>
                  <Stack
                    direction="row"
                    gap="5px"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: '100%', pr: '30px' }}
                  >
                    <UITextTitleSmall text={item?.name} />
                    <SimpleCheckbox
                      value={!!selectedCoveredComponents.find((comp) => comp === item?.['@id'])}
                      setValue={(value) => {
                        if (!!selectedCoveredComponents.find((comp) => comp === item?.['@id'])) {
                          setSelectedCoveredComponents(
                            selectedCoveredComponents.filter((comp) => comp !== item?.['@id']),
                          )
                        } else {
                          setSelectedCoveredComponents([
                            ...selectedCoveredComponents,
                            item?.['@id'],
                          ])
                        }
                      }}
                    />
                  </Stack>
                </Grid>
              )
            })}
          </Grid>
        ) : null}
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <ButtonLG sx={{ minWidth: '200px' }} onClick={() => handleSubmit()}>
            Save
          </ButtonLG>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default EditWarrantyProduct
