export const statusTheme = {
  readyForAppraisal: {
    label: 'Ready for Appraisal',
    color: '#0fa6f7',
  },
  submittedToAppraisal: {
    label: 'Submitted to Appraisal',
    color: '#fcdb03',
  },
  readyForAuction: {
    label: 'Ready for Auction',
    color: '#1ae89c',
  },
  inAuction: {
    label: 'In Auction',
    color: '#1a9166',
  },
  auctionEnded: {
    label: "Auction Ended (waiting for seller's approval)",
    color: '#f56f42',
  },
  waitingForPayment: {
    label: 'Waiting for Payment',
    color: '#e9f542',
  },
  inDelivery: {
    label: 'In Delivery',
    color: '#42f5e3',
  },
  sold: {
    label: 'Sold at Autrada Auction',
    color: '#42f587',
  },
  archived: {
    label: 'Archived',
    color: '#cee0d5',
  },
}

export const filters = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: statusTheme.readyForAppraisal.label,
    label: 'Newly Registered',
  },
  {
    value: statusTheme.submittedToAppraisal.label,
    label: statusTheme.submittedToAppraisal.label,
  },
  {
    value: statusTheme.readyForAuction.label,
    label: statusTheme.readyForAuction.label,
  },
  {
    value: statusTheme.inAuction.label,
    label: statusTheme.inAuction.label,
  },
  {
    value: statusTheme.auctionEnded.label,
    label: statusTheme.auctionEnded.label,
  },
  {
    value: statusTheme.waitingForPayment.label,
    label: statusTheme.waitingForPayment.label,
  },
  {
    value: statusTheme.inDelivery.label,
    label: statusTheme.inDelivery.label,
  },
  {
    value: statusTheme.sold.label,
    label: statusTheme.sold.label,
  },
  {
    value: statusTheme.archived.label,
    label: statusTheme.archived.label,
  },
]

export const operatorDashboardTableHeadCells = [
  {
    title: 'Number',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Image',
    sortable: false,
  },
  {
    title: 'Brand/Model',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Client',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Request date',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  // {
  //     title: 'Days in stock',
  //     sortable: true,
  //     sortableAsc: '&order[createdAt]=asc',
  //     sortableDesc: '&order[createdAt]=desc'
  // },
]

export const appraisalsRequestsTableHeadCells = [
  {
    title: 'Number',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Image',
    sortable: false,
  },
  {
    title: 'Brand/Model',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Client',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Request date',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
]

export const auctionsRequestsTableHeadCells = [
  {
    title: 'Seller',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Buyer',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Brand/Model',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Amount',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Request date',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
]

export const financingRequestsTableHeadCells = [
  {
    title: 'Number',
    sortable: false,
  },
  {
    title: 'Client',
    sortable: false,
  },
  {
    title: 'Bank',
    sortable: false,
  },
  {
    title: 'Brand/Model',
    sortable: false,
  },
  {
    title: 'Status',
    sortable: false,
  },
  {
    title: 'Request date',
    sortable: false,
  },
]

export const signUpRequestsTableHeadCells = [
  {
    title: 'Number',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Login',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Full name',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Company',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Request date',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
]

export const awaitingConfirmationRequestsTableHeadCells = [
  {
    title: 'Number',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Image',
    sortable: false,
  },
  {
    title: 'Brand/Model',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Client',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Request date',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
]

export const transportationRequestsTableHeadCells = [
  {
    title: 'Seller',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Buyer',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Brand/Model',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Amount',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Request date',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
]

export const dealsClearedRequestsTableHeadCells = [
  {
    title: 'Seller',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Buyer',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Brand/Model',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Amount',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Request date',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
]

export const operatorAppraisalsTableHeadCells = [
  {
    title: 'Number',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Image',
    sortable: false,
  },
  {
    title: 'Brand/Model',
    sortable: true,
  },
  {
    title: 'Client',
    sortable: false,
  },
  {
    title: 'Status',
    sortable: false,
  },
  {
    title: 'Request date',
    sortable: true,
    sortableAsc: '&order[createdAt]=asc',
    sortableDesc: '&order[createdAt]=desc',
  },
  {
    title: 'Actions',
    sortable: false,
  },
]
