import React from 'react'
import { Box, IconButton, Modal } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { CloseRounded } from '@mui/icons-material'

const UIBottomModal = ({ open, setOpen, sx, children, ...props }) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)} {...props}>
      <Box
        className="disable-scrollbar"
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          background: colorsConfig.white,
          padding: {
            xs: '10px',
            md: '30px',
          },
          maxHeight: '50vh',
          overflow: 'auto',
          outline: 'none',
          ...sx,
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
            zIndex: '100',
            '& svg': {
              fill: colorsConfig.mainBlack,
              transition: '0.25s all ease-in-out',
            },
            '&:hover': {
              '& svg': {
                fill: colorsConfig.darkRed,
                transition: '0.25s all ease-in-out',
              },
            },
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          <CloseRounded />
        </IconButton>
        {children}
      </Box>
    </Modal>
  )
}

export default UIBottomModal
