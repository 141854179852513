import React from 'react'
import { Autocomplete, Grid, Stack } from '@mui/material'
import EnergyEfficiencyCategory from './EnergyEfficiencyCategory'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import UIInputAdornmentText from '../../../ui/UIFormComponents/UIInputAdornmentText'
import { isEngineTypeElectric } from '../../../utils/helpers/common.helpers'
import UIFormInputSelect from '../../../ui/UIFormComponents/UIFormInputSelect/UIFormInputSelect'
import {
  CO2EmissionOptions,
  euroNormOptions,
  fuelConsumptionOptions,
} from '../addNewVehicleForm.helpers'
import { getCarEnumOptionsList } from '../../../utils/helpers/optionsList.helpers'
import { CAR_ENUMS } from '../../../utils/constants/enums.constants'
import TextFieldSmall from '../../../ui/TextFieldSmall'
import { formErrors } from '../../../components/Forms/FormTemplates/formErrors'
import { useTranslation } from 'react-i18next'

const CarEcoFields = ({ formData, setFormData }) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4}>
        <EnergyEfficiencyCategory
          value={formData.energyEfficiencyCategory}
          onChange={(value) => {
            setFormData({
              ...formData,
              energyEfficiencyCategory: value,
            })
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Stack spacing={3}>
          {isEngineTypeElectric(formData.engineType) ? (
            <TextFieldSmallEdible
              id="car-fuel-consumption"
              fullWidth
              label="form_input_label_electricity_consumption"
              value={formData.electricityConsumption}
              inputProps={{
                inputMode: 'numeric',
              }}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  electricityConsumption: e.target.value.replace(/[^0-9]/g, ''),
                })
              }}
              InputProps={{ endAdornment: <UIInputAdornmentText text="kwh / 100 km" /> }}
            />
          ) : (
            // <Autocomplete
            //   fullWidth
            //   options={fuelConsumptionOptions}
            //   getOptionLabel={(item) => t(item?.label)}
            //   value={formData.fuelConsumption}
            //   onChange={(e, newVal) => {
            //     setFormData({
            //       ...formData,
            //       fuelConsumption: newVal,
            //     })
            //   }}
            //   renderInput={(params) => (
            //     <TextFieldSmall
            //       {...params}
            //       label={t('form_input_label_fuel_consumption')}
            //       value={formData.fuelConsumption}
            //       onChange={(e, newVal) => {
            //         setFormData({
            //           ...formData,
            //           fuelConsumption: newVal,
            //         })
            //       }}
            //     />
            //   )}
            // />
            <UIFormInputSelect
              label="form_input_label_fuel_consumption"
              value={formData.fuelConsumption ?? ''}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  fuelConsumption: e.target.value,
                })
              }}
              options={fuelConsumptionOptions}
              InputProps={{ endAdornment: <UIInputAdornmentText text="L / 100 km" /> }}
            />
          )}

          <UIFormInputSelect
            label="form_input_label_co2_emission"
            value={formData.CO2Emission ?? ''}
            onChange={(e) => {
              setFormData({
                ...formData,
                CO2Emission: e.target.value,
              })
            }}
            options={CO2EmissionOptions}
            InputProps={{ endAdornment: <UIInputAdornmentText text="g / km" /> }}
          />
          <UIFormInputSelect
            label="form_input_label_euro_norm"
            value={formData.euroNorm ?? ''}
            onChange={(e) => {
              setFormData({
                ...formData,
                euroNorm: e.target.value,
              })
            }}
            options={euroNormOptions}
          />
        </Stack>
      </Grid>
      <Grid item md={4}></Grid>
    </Grid>
  )
}

export default CarEcoFields
