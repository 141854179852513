import { getCustomerAppraisal } from '../customerAppraisal.actions'

export default (builder) => {
  builder
    .addCase(getCustomerAppraisal.pending, (state) => {
      state.customer.isLoading = true
    })
    .addCase(getCustomerAppraisal.fulfilled, (state, action) => {
      state.customer.isLoading = false
      state.customer.error = null
      console.log(action.payload)
      state.customer.data = action.payload
      state.customer.car.data = action.payload?.car
    })
    .addCase(getCustomerAppraisal.rejected, (state, action) => {
      state.customer.isLoading = false
      state.customer.error = action.payload
    })
}
