export const downloadFileFromPrivateBucket = async (url, fileName) => {
  const response = await fetch(url)
  const blob = await response.blob()
  const blobUrl = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = blobUrl
  a.download = fileName // Name of the downloaded file
  document.body.appendChild(a)
  a.click()
  a.remove()
}
