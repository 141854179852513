import React, { useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import RVMatrixCell from './RVMatrixCell'
import { getEntryFormData } from './rvMatrix.helpers'
import { useDispatch } from 'react-redux'
import { updateEntry } from '../../../store/features/residualValueMatrixEntries/residualValueMatrixEntries.actions'
import RVActionsCell from './RVActionsCell'

const RVMatrixEntry = ({ initialEntry }) => {
  const dispatch = useDispatch()

  const [entry, setEntry] = useState({
    term: '',
    contractEndCarAgeMin: '',
    contractEndCarAgeMax: '',
    contractEndMileageMin: '',
    contractEndMileageMax: '',
    residualValueGross: '',
    residualValueNet: '',
  })

  const handleUpdate = async () => {
    const data = getEntryFormData(entry)
    dispatch(updateEntry({ entryId: initialEntry?.id, data }))
  }

  useEffect(() => {
    if (initialEntry) {
      setEntry(initialEntry)
    }
  }, [initialEntry])

  return (
    <Stack
      direction="row"
      sx={{
        borderBottom: '1px solid',
        borderColor: colorsConfig.buttonDisabled,
        '&:last-child': {
          border: 'none',
        },
      }}
    >
      <RVMatrixCell
        value={entry?.term}
        onChange={(e) => setEntry({ ...entry, term: e.target.value })}
        onBlur={() => handleUpdate()}
        wrapperSx={{
          maxWidth: '90px',
        }}
      />
      <RVMatrixCell
        value={entry?.contractEndCarAgeMin}
        onChange={(e) => setEntry({ ...entry, contractEndCarAgeMin: e.target.value })}
        onBlur={() => handleUpdate()}
        wrapperSx={{
          width: '120px',
        }}
      />
      <RVMatrixCell
        value={entry?.contractEndCarAgeMax}
        onChange={(e) => setEntry({ ...entry, contractEndCarAgeMax: e.target.value })}
        onBlur={() => handleUpdate()}
        wrapperSx={{
          width: '120px',
        }}
      />
      <RVMatrixCell
        value={entry?.contractEndMileageMin}
        onChange={(e) => setEntry({ ...entry, contractEndMileageMin: e.target.value })}
        onBlur={() => handleUpdate()}
        wrapperSx={{
          width: '160px',
        }}
      />
      <RVMatrixCell
        value={entry?.contractEndMileageMax}
        onChange={(e) => setEntry({ ...entry, contractEndMileageMax: e.target.value })}
        onBlur={() => handleUpdate()}
        wrapperSx={{
          width: '160px',
        }}
      />
      <RVMatrixCell
        value={entry?.residualValueGross}
        onChange={(e) => setEntry({ ...entry, residualValueGross: e.target.value })}
        onBlur={() => handleUpdate()}
        wrapperSx={{
          width: '125px',
        }}
      />
      <RVMatrixCell
        value={entry?.residualValueNet}
        onChange={(e) => setEntry({ ...entry, residualValueNet: e.target.value })}
        onBlur={() => handleUpdate()}
        wrapperSx={{
          width: '125px',
        }}
      />
      <RVActionsCell />
    </Stack>
  )
}

export default RVMatrixEntry
