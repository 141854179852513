import React from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { colorsConfig } from '../../config/themeConfig'
import { FONT } from '../../utils/constants/ui.constants'

const UITextBodySmall = ({ children, sx, text, color = colorsConfig.mainBlack, ...props }) => {
  const { t } = useTranslation()

  return (
    <Typography
      sx={{
        color,
        fontFamily: FONT.PRIMARY,
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: 'normal',
        ...sx,
      }}
      {...props}
    >
      {text ? t(text) : children}
    </Typography>
  )
}

export default UITextBodySmall
