import React, { useEffect, useState } from 'react'
import { Button, IconButton, InputAdornment, Stack, TextField } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import ModalWrap from '../../components/Modals/ModalWrap'
import TextH1 from '../../ui/Text/TextH1'
import TextFieldSmallEdible from '../../ui/TextFieldSmallEdible'
import useValidate from '../../hooks/useValidate'
import ButtonLG from '../../ui/Buttons/ButtonLG'
import { privateRequest } from '../../utils/request'
import { API_ENDPOINTS } from '../../config/default'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { updateManagerEmail } from '../../store/features/authSlice'

const ChangeEmailModal = (props) => {
  const { open, setOpen } = props

  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [checkPassword, setCheckPassword] = useState('')
  const [passwordVisible, setPasswordVisible] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const validateEmail = useValidate(email, { isEmpty: true, email: true }, true)

  const hasErrors = () => {
    if (validateEmail.hasErrors) return true
    if (checkPassword === '') return true
    return false
  }

  const handleSubmit = () => {
    if (hasErrors()) {
      setShowErrors(true)
      return
    }
    setButtonDisabled(true)
    const formData = {
      plainPassword: checkPassword,
      newEmail: email,
    }
    privateRequest
      .put(API_ENDPOINTS.changeEmail, formData)
      .then(() => {
        dispatch(updateManagerEmail(email))
        setOpen(false)
        toast.success('The email has been changed successfully')
        setEmail('')
        setCheckPassword('')
        setShowErrors(false)
      })
      .catch(() => {
        toast.error('Invalid password')
      })
      .finally(() => {
        setButtonDisabled(false)
      })
  }

  return (
    <ModalWrap
      open={open}
      setOpen={setOpen}
      wrapperStyles={{
        maxWidth: '450px',
      }}
    >
      <TextH1
        sx={{
          fontSize: '32px',
          mb: '20px',
        }}
      >
        Change email
      </TextH1>
      <Stack spacing={'15px'}>
        <TextFieldSmallEdible
          fullWidth
          required
          label="New Email"
          type="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          error={showErrors && validateEmail.hasErrors}
          helperText={showErrors && validateEmail.hasErrors ? validateEmail.errorText : ''}
        />
        <TextFieldSmallEdible
          fullWidth
          required
          label="Confirm Password"
          type={passwordVisible ? 'text' : 'password'}
          value={checkPassword}
          onChange={(e) => {
            setCheckPassword(e.target.value)
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setPasswordVisible(!passwordVisible)
                  }}
                >
                  {passwordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={showErrors && checkPassword === ''}
          helperText={showErrors && checkPassword === '' ? 'This field can not be empty' : ''}
        />
      </Stack>
      <ButtonLG
        fullWidth
        disabled={buttonDisabled}
        onClick={() => {
          handleSubmit()
        }}
        sx={{
          mt: '30px',
        }}
      >
        Confirm
      </ButtonLG>
    </ModalWrap>
  )
}

export default ChangeEmailModal
