import React, { useState, useEffect } from 'react'

const useValidate = (value, rules, showDirtyError) => {
  const helperTexts = {
    isEmpty: 'This filed can not be empty',
    minLength: 'This field should contain at least $number characters',
    exactLength: 'This field must be $number characters long',
    email: 'Invalid email',
    isValidDate: 'Invalid date',
    isValidDateStr: 'Invalid date',
    isValidTime: 'Invalid time',
    isValidFullNumberUAE: 'Invalid phone',
  }

  const [hasErrors, setHasErrors] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    let findErrors = false
    for (const rule in rules) {
      switch (rule) {
        case 'isEmpty':
          if (rules[rule]) {
            if (!value || (value !== null && value.length === 0)) {
              findErrors = true
              setErrorText(helperTexts[rule])
            }
          }
          break
        case 'minLength':
          if (value.length < rules[rule]) {
            findErrors = true
            setErrorText(helperTexts[rule].replace('$number', rules[rule]))
          }
          break
        case 'exactLength':
          if (value.length !== rules[rule]) {
            findErrors = true
            setErrorText(helperTexts[rule].replace('$number', rules[rule]))
          }
          break
        case 'email':
          if (
            !value.match(
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            )
          ) {
            findErrors = true
            setErrorText(helperTexts[rule])
          }
          break
        case 'isValidDate':
          if ((value && value['$d'] === 'Invalid Date') || value === null) {
            findErrors = true
            setErrorText(helperTexts[rule])
          }
          break
        case 'isValidDateStr':
          if (
            !value ||
            (value && value['$d'] && value['$d'].toString() === 'Invalid Date') ||
            value === null
          ) {
            findErrors = true
            setErrorText(helperTexts[rule])
          }
          break
        case 'isValidTime':
          if (
            !value ||
            (value && value['$d'] && value['$d'].toString() === 'Invalid Date') ||
            value === null
          ) {
            findErrors = true
            setErrorText(helperTexts[rule])
          }
          break
        case 'isValidFullNumberUAE':
          if (
            !value ||
            value.replace('+', '').replace(' ', '').length < 11 ||
            value.replace('+', '').replace(' ', '').length > 12
          ) {
            findErrors = true
            setErrorText(helperTexts[rule])
          }
      }
    }
    setHasErrors(findErrors)
    if (!findErrors || (!isDirty && !showDirtyError)) {
      setErrorText('')
    }
    if (value !== null && value !== '') {
      setIsDirty(true)
    }
  }, [value])

  return {
    hasErrors,
    errorText,
    isDirty,
  }
}

export default useValidate
