import React from 'react'
import { colorsConfig } from '../config/themeConfig'
import TextNormal from '../ui/Text/TextNormal'
import { Box } from '@mui/material'

const SquareCounterWithPopover = ({ count, text, ...props }) => {
  return (
    <Box
      sx={{
        width: '35px',
        height: '35px',
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        background: colorsConfig.buttonDisabled,
        position: 'relative',
        '& p': { color: 'white' },
        '&.danger': {
          background: colorsConfig.darkRed,
          '& p.popover': { color: colorsConfig.mainBlack },
        },
        '&.success': {
          background: colorsConfig.mainGreen,
          '& p': { color: colorsConfig.mainBlack },
        },
        '&.attention': {
          background: colorsConfig.buttonYellow,
          '& p': { color: colorsConfig.mainBlack },
        },
        '&:hover': {
          '& .popover': {
            opacity: 1,
            transform: 'scale(1)',
            transition: '0.25s all ease-in-out',
          },
        },
      }}
      {...props}
    >
      <TextNormal>{count}</TextNormal>
      <TextNormal
        className={'popover'}
        sx={{
          py: '4px',
          px: '6px',
          borderRadius: '6px',
          background: 'white',
          position: 'absolute',
          boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
          bottom: '100%',
          left: '-9px',
          opacity: 0,
          transform: 'scale(0)',
          transformOrigin: '20px 100%',
          transition: '0.25s all ease-in-out',
          whiteSpace: 'nowrap',
          '&::after': {
            position: 'absolute',
            content: '" "',
            display: 'block',
            top: '100%',
            left: '20px',
            width: '0px',
            height: '0px',
            borderStyle: 'solid',
            borderWidth: '0 6px 9px 6px',
            borderColor: 'transparent transparent white transparent',
            transform: 'rotate(180deg)',
          },
        }}
      >
        {text}
      </TextNormal>
    </Box>
  )
}

export default SquareCounterWithPopover
