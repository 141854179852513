import React, { useEffect, useState } from 'react'
import { Breadcrumbs, Link, Typography, Box, styled } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import { colorsConfig } from '../config/themeConfig'
import { useSelector } from 'react-redux'
import LeaveAddNewVehicleModal from './Modals/LeaveAddNewVehicleModal'

const BreadcrumbsComponent = (props) => {
  const { currentPage, parentPages } = props

  const carCreatedId = useSelector((state) => state.addCar.carCreatedId)

  const navigate = useNavigate()
  const location = useLocation()

  const [leaveModal, setLeaveModal] = useState(false)
  const [pendingLink, setPendingLink] = useState('')

  const BreadcrumbLink = styled(Link)({
    paddingLeft: '0px',
    paddingRight: '11px',
    paddingBottom: '5px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
    mb: {
      xs: 2,
      md: 0,
    },
    position: 'relative',
    '&::after': {
      content: '"»"',
      position: 'absolute',
      fontWeight: 400,
      fontSize: '14px',
      color: colorsConfig.lightGray,
      right: 0,
      top: '0px',
    },
    '&:last-child': {
      cursor: 'auto',
      '&::after': {
        display: 'none',
      },
    },
  })

  const handleNavigate = (link) => {
    if (location.pathname === '/add-new-vehicle' && !carCreatedId) {
      setPendingLink(link)
      setLeaveModal(true)
    } else {
      navigate(link)
    }
  }

  const leaveAddNewVehiclePage = () => {
    setLeaveModal(false)
    navigate(pendingLink)
    setPendingLink('')
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItmes: 'center',
      }}
    >
      {parentPages.map((page, index) => {
        return (
          <BreadcrumbLink
            key={index}
            sx={{
              mb: {
                xs: 1,
                md: 0,
              },
            }}
            onClick={() => {
              handleNavigate(page.link)
            }}
          >
            {page.icon}
            <Typography
              variant="body1"
              sx={{
                ml: '5px',
                color: colorsConfig.lightGray,
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: 'normal',
                position: 'relative',
                marginBottom: '-1px',
              }}
            >
              {page.name}
            </Typography>
          </BreadcrumbLink>
        )
      })}
      {currentPage ? (
        <BreadcrumbLink
          sx={{
            mb: {
              xs: 1,
              md: 0,
            },
          }}
          onClick={() => {
            if (currentPage.link) {
              navigate(currentPage.link)
            }
          }}
        >
          {currentPage.icon}
          <Typography
            variant="body1"
            sx={{
              ml: '5px',
              color: colorsConfig.lightGray,
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: 'normal',
              position: 'relative',
              marginBottom: '-1px',
            }}
          >
            {currentPage.name}
          </Typography>
        </BreadcrumbLink>
      ) : null}
      <LeaveAddNewVehicleModal
        open={leaveModal}
        setOpen={setLeaveModal}
        handleSubmit={leaveAddNewVehiclePage}
      />
    </Box>
  )
}

export default BreadcrumbsComponent
