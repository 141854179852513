import React, { useState } from 'react'
import { Stack } from '@mui/material'
import ButtonLink from '../../ui/Buttons/ButtonLink'
import { useTranslation } from 'react-i18next'
import TermsAndConditionsModal from '../Modals/TermsAndConditionsModal/TermsAndConditionsModal'
import { getCookiePolicy } from '../../utils/helpers/iubenda.helpers'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { colorsConfig } from '../../config/themeConfig'

const FooterCookiesAndPrivacyPolicy = () => {
  const { t, i18n } = useTranslation()
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const navigate = useNavigate()

  const [termsAndConditionsModal, setTermsAndConditionsModal] = useState(false)
  const [documentContent, setDocumentContent] = useState(null)

  const handleOpenCookiePolicy = async (e) => {
    e.preventDefault()
    document.getElementById(`cookie-policy-link-${i18n.language}`).click()
    // const content = await getCookiePolicy(i18n.language)
    // setDocumentContent(content)
  }

  const handleOpenPrivacyPolicy = (e) => {
    e.preventDefault()
    document.getElementById(`privacy-policy-link-${i18n.language}`).click()
  }

  return (
    <Stack sx={{ position: 'relative', mt: '-6px' }} alignItems="flex-start">
      {isAuthenticated && (
        <ButtonLink
          onClick={() => navigate('/terms-and-conditions')}
          sx={{
            py: '5px',
            display: 'inline-flex',
            width: 'auto',
            px: '5px',
            textDecoration: 'none',
            color: colorsConfig.mainBlack,
            '&:hover': {
              textDecoration: 'none',
              color: colorsConfig.mainBlack,
            },
          }}
        >
          {t('common_terms_and_conditions')}
        </ButtonLink>
      )}
      <ButtonLink
        onClick={handleOpenCookiePolicy}
        sx={{
          py: '5px',
          display: 'inline-flex',
          width: 'auto',
          px: '5px',
          textDecoration: 'none',
          color: colorsConfig.mainBlack,
          '&:hover': {
            textDecoration: 'none',
            color: colorsConfig.mainBlack,
          },
        }}
      >
        {t('common_cookie_policy')}
      </ButtonLink>
      <ButtonLink
        onClick={handleOpenPrivacyPolicy}
        sx={{
          py: '5px',
          display: 'inline-flex',
          width: 'auto',
          px: '5px',
          textDecoration: 'none',
          color: colorsConfig.mainBlack,
          '&:hover': {
            textDecoration: 'none',
            color: colorsConfig.mainBlack,
          },
        }}
      >
        {t('common_privacy_policy')}
      </ButtonLink>
      {termsAndConditionsModal && (
        <TermsAndConditionsModal
          open={termsAndConditionsModal}
          setOpen={setTermsAndConditionsModal}
        />
      )}
      {documentContent && <div dangerouslySetInnerHTML={{ __html: documentContent }}></div>}
    </Stack>
  )
}

export default FooterCookiesAndPrivacyPolicy
