import React from 'react'
import { Box } from '@mui/material'
import RBox from '../RBox'

const BoxShadowed = ({ sx, children, ...props }) => {
  return (
    <RBox
      sx={{
        borderRadius: '6px',
        background: 'white',
        px: '30px',
        py: '30px',
        boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
        ...sx,
      }}
      {...props}
    >
      {children}
    </RBox>
  )
}

export default BoxShadowed
