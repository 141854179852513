export const warrantyApplicationsTableCells = [
  {
    title: 'Number',
    sortable: false,
  },
  {
    title: 'Brand/Model',
    sortable: false,
  },
  {
    title: 'Created at',
    sortable: false,
  },
]

export const warrantyApplicationsActivatedTableCells = [
  {
    title: 'Number',
    sortable: false,
  },
  {
    title: 'Brand/Model',
    sortable: false,
  },
  {
    title: 'Package',
    sortable: false,
  },
  {
    title: 'Expires at',
    sortable: false,
  },
]

export const warrantyProductsTableHeadCells = [
  {
    title: 'ID',
    sortable: false,
  },
  {
    title: 'Name',
    sortable: false,
  },
  {
    title: 'Price',
    sortable: false,
  },
  {
    title: 'Car Age (max)',
    sortable: false,
  },
  {
    title: 'Mileage (max)',
    sortable: false,
  },
]

export const coveredComponentsTableHeadCells = [
  {
    title: 'ID',
    sortable: false,
  },
  {
    title: 'Name',
    sortable: false,
  },
  {
    title: 'Created at',
    sortable: false,
  },
]
