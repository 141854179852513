import * as Yup from 'yup'
import { formErrors } from '../formErrors'

export const addCompanyFormFormValidationSchema = Yup.object().shape({
  name: Yup.string().required(formErrors.required),
  invoiceEmail: Yup.string().required(formErrors.required).email(formErrors.invalidEmail),
  tradeLicenseNumber: Yup.string().required(formErrors.required),
  tradeLicense: Yup.string().required(formErrors.required),
  landlineNumber: Yup.string().required(formErrors.required),
})
