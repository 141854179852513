import React from 'react'
import { Stack } from '@mui/material'
import UICheckboxNullableChecked from './UICheckboxNullableChecked'
import UICheckboxNullableUnchecked from './UICheckboxNullableUnchecked'

const UICheckboxNullable = ({ value, setValue, ...props }) => {
  return (
    <Stack direction="row" gap="3px" {...props}>
      <UICheckboxNullableChecked value={value} setValue={setValue} />
      <UICheckboxNullableUnchecked value={value} setValue={setValue} />
    </Stack>
  )
}

export default UICheckboxNullable
