import React from 'react'
import useBreakpoints from '../../hooks/useBreakpoints'
import { Box } from '@mui/material'

const UITableMobileWrapper = ({ children }) => {
  const { smDown } = useBreakpoints()

  return smDown ? (
    <Box
      sx={{
        px: '16px',
        maxWidth: '100vw',
        overflowX: 'auto',
      }}
    >
      {children}
    </Box>
  ) : (
    children
  )
}

export default UITableMobileWrapper
