import { CAR_ENUMS } from '../constants/enums.constants'
import { swissCantons } from '../constants/geo.constants'
import { emirates } from '../constants/geo.constants'

export const getCarEnumOptionsList = (carEnum, enumDetails) => {
  if (!enumDetails) return []
  const currentEnumCase = enumDetails?.find((item) => item?.name === carEnum)
  if (!currentEnumCase) return []
  const caseDetails = currentEnumCase?.caseDetails?.filter((item) => item?.name !== 'UNSPECIFIED')
  if (!caseDetails) return []
  const options = caseDetails.map((item) => ({
    label: item?.readableValue,
    value: item?.value,
  }))
  const fullOptions = [
    {
      value: '',
      label: 'common_not_chosen',
    },
    ...options,
  ]
  if (carEnum === CAR_ENUMS.CAR_ORIGIN) {
    return fullOptions.filter((item) => item.value === 16 || item.value === 15 || item.value === '')
  }
  return fullOptions
}

export const getCarEnumOptionsListWithIcons = (carEnum, enumDetails) => {
  if (!enumDetails) return []
  const currentEnumCase = enumDetails?.find((item) => item?.name === carEnum)
  if (!currentEnumCase) return []
  const caseDetails = currentEnumCase?.caseDetails?.filter((item) => item?.name !== 'UNSPECIFIED')
  if (!caseDetails) return []
  const options = caseDetails.map((item) => ({
    label: item?.readableValue,
    value: item?.value,
    icon: item?.metadata?.iconUrl,
  }))
  return [
    {
      value: '',
      label: 'common_not_chosen',
    },
    ...options,
  ]
}

export const getAddressTypesOptions = (addressTypes) => {
  if (!addressTypes) return []
  return [
    {
      label: 'common_all',
      value: 'all',
    },
    ...addressTypes
      .filter((e) => e.sysName !== 'None')
      .map((e) => ({ label: e?.name, value: e?.['@id'] })),
  ]
}

export const getStatesOptionList = (countryCode, t) => {
  if (!countryCode) return []
  if (countryCode === 'CH') return swissCantons.map((e) => ({ label: t(e), value: e }))
  if (countryCode === 'AE') return emirates.map((e) => ({ label: e, value: e }))
  return []
}
