import React, { useEffect, useState } from 'react'
import { Box, Button, InputAdornment, Tab, Tabs, TextField, Typography, Table } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import TextNormal from '../../../ui/Text/TextNormal'
import iconSearch from '../../../assests/img/icons/icon-search.svg'
import CarTableHead from '../../VirtualGarage/CarTableHead'
import CarTableBody from '../../VirtualGarage/CarTableBody'
import { useDispatch } from 'react-redux'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import { privateRequest } from '../../../utils/request'
import { API_FINANCE_ENDPOINTS } from '../../../config/default'
import FilterByStatus from '../../../components/FilterByStatus'
import SimplePagination from '../../../ui/SimplePagination'
import SearchInput from '../../../components/Search/SearchInput'

const OperatorFinancing = () => {
  const dispatch = useDispatch()

  const tableHeadCells = [
    {
      title: 'Number',
      sortable: false,
      // sortableAsc: '&order[referenceNumber]=asc',
      // sortableDesc: '&order[referenceNumber]=desc'
    },
    {
      title: 'Client',
      sortable: false,
    },
    {
      title: 'Bank',
      sortable: false,
    },
    {
      title: 'Brand/Model',
      sortable: false,
    },
    {
      title: 'Status',
      sortable: false,
    },
    {
      title: 'Request Date',
      sortable: true,
      sortableAsc: '&order[updateddAt]=asc',
      sortableDesc: '&order[updatedAt]=desc',
    },
  ]

  const LoanDealWorkflowTypes = [
    {
      id: 1,
      systemName: 'bank_sent',
      name: 'Waiting for bank response',
    },
    {
      id: 2,
      systemName: 'bank_declined',
      name: 'Refused',
    },
    {
      id: 3,
      systemName: 'bank_request_info',
      name: 'Bank request',
    },
    {
      id: 4,
      systemName: 'approved',
      name: 'Accepted',
    },
    {
      id: 5,
      systemName: 'approval_request_error',
      name: 'Sending to banks error',
    },
    {
      id: 6,
      systemName: 'approval_request',
      name: 'Preparing to send ',
    },
  ]

  const [tab, setTab] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchLine, setSearchLine] = useState('')
  const [activeLoanDeals, setActiveLoanDeals] = useState([])
  const [processedLoanDeals, setProcessedLoanDeals] = useState([])
  const [filteredProcessedLoanDeals, setFilteredProcessedLoanDeals] = useState([])
  const [sort, setSort] = useState('')
  const [currentFilter, setCurrentFilter] = useState(null)
  const [processedLoanDealsCount, setProcessedLoanDealsCount] = useState(0)
  const [activeLoanDealsCount, setActiveLoanDealsCount] = useState(0)
  const [processedLoanDealsCountDynamic, setProcessedLoanDealsCountDynamic] = useState(0)
  const [activeLoanDealsCountDynamic, setActiveLoanDealsCountDynamic] = useState(0)

  const sections = [
    {
      id: 1,
      name: 'Awaiting for approval',
      count: activeLoanDealsCount,
    },
    {
      id: 2,
      name: 'Processed',
      count: processedLoanDealsCount,
    },
  ]

  const getFormattedDate = (date) => {
    const yyyy = date.getFullYear()
    let mm = date.getMonth() + 1
    let dd = date.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm
    return dd + '.' + mm + '.' + yyyy
  }

  const handleFilter = (status) => {
    if (!status || status === 'All') {
      setCurrentFilter(null)
      setFilteredProcessedLoanDeals(processedLoanDeals)
      return
    }
    setCurrentFilter(status)
    setFilteredProcessedLoanDeals(
      processedLoanDeals.filter((item) => item.cells[4].value === status),
    )
  }

  const handleSetLoanDealsTable = (data) => {
    return data.map((item, index) => {
      const person = item.loanApplication?.customer?.person
      const loanApplicationId = item.loanApplication['@id'].replace(/[^0-9]/g, '')
      return {
        id: index + 1,
        link: `/operator/finance-requests/${loanApplicationId}?loanDeal=${item.id}`,
        cells: [
          {
            type: 'text',
            value: loanApplicationId,
          },
          {
            type: 'text',
            minWidth: '100px',
            value: person
              ? `${person?.firstName} ${person?.lastName}`
              : `Customer #${item.loanApplication?.customer?.id}`,
          },
          {
            type: 'text',
            value: item.bankTitle,
          },
          {
            type: 'text-with-caption',
            noWrap: true,
            value: `${item.loanApplication.car.make.name} ${item.loanApplication.car.model.name}`,
            caption: `VIN ${item.loanApplication.car.vin}`,
          },
          {
            type: 'text',
            value: LoanDealWorkflowTypes.filter(
              (workflow) => workflow.systemName === item.currentWorkflowState,
            )[0]?.name,
          },
          {
            type: 'text-with-marker',
            value: getFormattedDate(new Date(item.updatedAt)),
            color: colorsConfig.mainRed,
          },
        ],
      }
    })
  }

  const handleSetLoanDeals = (data) => {
    if (!processedLoanDealsCount) {
      setProcessedLoanDealsCount(
        data.filter(
          (item) =>
            item.currentWorkflowState === 'approved' ||
            item.currentWorkflowState === 'bank_request_info' ||
            item.currentWorkflowState === 'bank_declined',
        ).length,
      )
    }
    setProcessedLoanDealsCountDynamic(
      data.filter(
        (item) =>
          item.currentWorkflowState === 'approved' ||
          item.currentWorkflowState === 'bank_request_info' ||
          item.currentWorkflowState === 'bank_declined',
      ).length,
    )
    if (!activeLoanDealsCount) {
      setActiveLoanDealsCount(
        data.filter((item) => item.currentWorkflowState === 'bank_sent').length,
      )
    }
    setActiveLoanDealsCountDynamic(
      data.filter((item) => item.currentWorkflowState === 'bank_sent').length,
    )
    setActiveLoanDeals(
      handleSetLoanDealsTable(data.filter((item) => item.currentWorkflowState === 'bank_sent')),
    )
    setProcessedLoanDeals(
      handleSetLoanDealsTable(
        data.filter(
          (item) =>
            item.currentWorkflowState === 'approved' ||
            item.currentWorkflowState === 'bank_request_info' ||
            item.currentWorkflowState === 'bank_declined',
        ),
      ),
    )
    if (!currentFilter) {
      setFilteredProcessedLoanDeals(
        handleSetLoanDealsTable(
          data.filter(
            (item) =>
              item.currentWorkflowState === 'approved' ||
              item.currentWorkflowState === 'bank_request_info' ||
              item.currentWorkflowState === 'bank_declined',
          ),
        ),
      )
    } else {
      setFilteredProcessedLoanDeals(
        handleSetLoanDealsTable(
          data.filter(
            (item) =>
              item.currentWorkflowState ===
              LoanDealWorkflowTypes.filter((workflow) => workflow.name === currentFilter)[0]
                .systemName,
          ),
        ),
      )
    }
  }

  useEffect(() => {
    dispatch(setCurrentPageTitle('Financing Requests'))
  }, [])

  useEffect(() => {
    let requestSent = true
    const getLoanDeals = () => {
      let link
      link = `${API_FINANCE_ENDPOINTS.loanDeals}`
      if (searchLine) {
        link = `${link}&loanDealExtendedSearch=${searchLine}`
      }
      if (sort) {
        link = `${link}${sort}`
      }
      privateRequest.get(link).then((response) => {
        if (!requestSent) return
        if (!sort) {
          handleSetLoanDeals(
            response.data['hydra:member'].sort(function (a, b) {
              return new Date(b.updatedAt) - new Date(a.updatedAt)
            }),
          )
        } else {
          handleSetLoanDeals(response.data['hydra:member'])
        }
      })
    }
    getLoanDeals()
    return () => {
      requestSent = false
    }
  }, [searchLine, sort])

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Tabs
          value={tab}
          onChange={(e, newVal) => {
            setTab(newVal)
            setPage(0)
          }}
          variant="scrollable"
          sx={{
            width: '100%',
            '& .MuiTabs-indicator': {
              background: colorsConfig.mainGreen,
            },
            '& .MuiTab-textColorPrimary': {
              color: colorsConfig.mainBlack,
              textTransform: 'none',
              fontFamily: '"HelveticaNeue", sans-serif',
              fontSize: '16px',
              fontWeight: 500,
            },
            '& .MuiButtonBase-root': {
              background: colorsConfig.bgLightBlue,
            },
            '& .Mui-selected': {
              color: colorsConfig.mainBlack,
            },
          }}
        >
          {sections.map((section, index) => {
            return (
              <Tab
                key={index}
                label={`${section.name} (${section.count})`}
                sx={{
                  fontSize: '13px',
                }}
              />
            )
          })}
        </Tabs>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              mr: '24px',
            }}
          >
            <Box
              sx={{
                width: '18px',
                minWidth: '18px',
                height: '18px',
                borderRadius: '50%',
                background: colorsConfig.mainGreen,
                mr: '6px',
              }}
            />
            <TextNormal
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              —New request
            </TextNormal>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              mr: '24px',
            }}
          >
            <Box
              sx={{
                width: '18px',
                minWidth: '18px',
                height: '18px',
                borderRadius: '50%',
                background: '#F5BD4F',
                mr: '6px',
              }}
            />
            <TextNormal
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              —Approaching the deadline
            </TextNormal>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '18px',
                minWidth: '18px',
                height: '18px',
                borderRadius: '50%',
                background: colorsConfig.mainRed,
                mr: '6px',
              }}
            />
            <TextNormal
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              —Action required
            </TextNormal>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          background: 'white',
          boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
          borderRadius: '6px',
          py: '37px',
          px: '40px',
          mt: '50px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: colorsConfig.mainBlack,
                fontSize: '24px',
                fontWeight: 700,
                marginRight: '20px',
              }}
            >
              {tab === 0 ? 'Waiting for clearing' : 'Processed'}
            </Typography>
            <SearchInput
              value={searchLine}
              onChange={(e) => {
                setSearchLine(e.target.value)
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {tab === 1 ? (
              <Box
                sx={{
                  marginLeft: '24px',
                  position: 'relative',
                }}
              >
                <FilterByStatus
                  handleFilter={handleFilter}
                  currentFilter={currentFilter}
                  filters={['Accepted', 'Bank request', 'Refused']}
                />
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box
          sx={{
            mt: '50px',
          }}
        >
          <Table>
            <CarTableHead tableCells={tableHeadCells} sort={sort} setSort={setSort} />
            {tab === 0 ? (
              <CarTableBody
                content={activeLoanDeals.slice(page * rowsPerPage, (page + 1) * rowsPerPage)}
              />
            ) : null}
            {tab === 1 ? (
              <CarTableBody
                content={filteredProcessedLoanDeals.slice(
                  page * rowsPerPage,
                  (page + 1) * rowsPerPage,
                )}
              />
            ) : null}
          </Table>
          <SimplePagination
            count={tab === 0 ? activeLoanDeals.length : filteredProcessedLoanDeals.length}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default OperatorFinancing
