import React from 'react'
import { Box, IconButton, Modal, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { Close } from '@mui/icons-material'
import TextH1 from '../../ui/Text/TextH1'
import TextNormal from '../../ui/Text/TextNormal'
import ButtonDanger from '../../ui/Buttons/ButtonDanger'
import ButtonAttention from '../../ui/Buttons/ButtonAttention'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import IconCoin from '../../assests/img/icons/icon-fleetify-coin.svg'

const ServiceHintQualityCheckModal = (props) => {
  const { open, setOpen, handleAction } = props

  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 686,
          background: colorsConfig.bgLightBlue,
          borderRadius: '10px',
          padding: '30px',
          pb: '35px',
          maxHeight: '80vh',
          overflow: 'auto',
          '&.xs-only': {
            width: 'calc(100% - 30px)',
            px: '10px',
            py: '20px',
          },
        }}
        className={['disable-scrollbar', xsOnly ? 'xs-only' : '']}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          <Close
            style={{
              fill: colorsConfig.mainRed,
            }}
          />
        </IconButton>
        <TextH1
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            mb: '16px',
            '&.xs-only': {
              fontSize: '24px',
            },
          }}
        >
          Quality check needed
        </TextH1>
        <TextNormal
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            mb: '60px',
            '&.xs-only': {
              mb: '30px',
              fontSize: '14px',
            },
          }}
        >
          Please go through quality check before applying the car to the auction
        </TextNormal>
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: '9px',
            borderBottom: '1px solid',
            borderColor: colorsConfig.lightGray,
            mb: '54px',
            '&.xs-only': {
              mb: '30px',
            },
          }}
        >
          <TextBoldNormal sx={{ textTransform: 'uppercase' }}>Total</TextBoldNormal>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src={IconCoin} alt="" />
            <TextBoldNormal sx={{ ml: '5px' }}>5</TextBoldNormal>
          </Box>
        </Box>
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '24px',
            '&.xs-only': {
              flexDirection: 'column',
            },
          }}
        >
          <ButtonDanger
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              width: 'calc(50% - 12px)',
              '&.xs-only': {
                width: '100%',
              },
            }}
            onClick={() => {
              setOpen(false)
            }}
          >
            Cancel
          </ButtonDanger>
          <ButtonAttention
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              width: 'calc(50% - 12px)',
              '&.xs-only': {
                width: '100%',
              },
            }}
            onClick={() => {
              handleAction()
            }}
          >
            Apply for quality check
          </ButtonAttention>
        </Box>
      </Box>
    </Modal>
  )
}

export default ServiceHintQualityCheckModal
