import React, { useEffect, useState } from 'react'
import BreadcrumbsComponent from '../../../../components/BreadcrumbsComponent'
import IconProduct from '../../../../assests/img/icons/icon-product.svg'
import TextH1 from '../../../../ui/Text/TextH1'
import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import ButtonPlain from '../../../../ui/Buttons/ButtonPlain'
import { colorsConfig } from '../../../../config/themeConfig'
import { Delete } from '@mui/icons-material'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import ModalWrap from '../../../../components/Modals/ModalWrap'
import ButtonHollow from '../../../../ui/Buttons/ButtonHollow'
import ButtonDanger from '../../../../ui/Buttons/ButtonDanger'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { adminRequest } from '../../../../utils/request'
import { API_FINANCE_ADMIN_ENDPOINTS } from '../../../../config/default'
import { toast } from 'react-toastify'
import { setCurrentPageTitle } from '../../../../store/features/authSlice'

const EditProduct = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const [productName, setProductName] = useState('')
  const [productType, setProductType] = useState('')
  const [productTypes, setProductTypes] = useState([])
  const [savedProductName, setSavedProductName] = useState('')
  const [confirmDeletionModal, setConfirmDeletionModal] = useState(false)
  const [disableDeleteButton, setDisableDeleteButton] = useState(false)

  const hasErrors = !productType || !productName

  const handleEditProduct = () => {
    const formData = {
      title: productName,
      type: productTypes.filter((item) => item.title === productType)[0]['@id'],
    }
    adminRequest
      .patch(`${API_FINANCE_ADMIN_ENDPOINTS.products}/${params.id}`, formData, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      })
      .then((response) => {
        toast.success('Updated product successfully', { autoClose: 1000 })
        navigate('/admin/financing/products')
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data['hydra:description']) {
          toast.error(error.response.data['hydra:description'])
        } else toast.error('Unexpected error')
      })
  }

  const handleDeleteProduct = () => {
    setDisableDeleteButton(true)
    adminRequest
      .delete(`${API_FINANCE_ADMIN_ENDPOINTS.products}/${params.id}`)
      .then((response) => {
        toast.success('Product has been deleted', { autoClose: 1000 })
        navigate('/admin/financing/products')
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data['hydra:description']) {
          toast.error(error.response.data['hydra:description'])
        } else toast.error('Unexpected error')
      })
      .finally(() => {
        setDisableDeleteButton(false)
      })
  }

  const getProduct = () => {
    adminRequest.get(`${API_FINANCE_ADMIN_ENDPOINTS.products}/${params.id}`).then((response) => {
      setProductName(response.data.title)
      adminRequest.get(response.data.type).then((res) => setProductType(res.data.title))
      setProductType(response.data.type.title)
      setSavedProductName(response.data.title)
    })
  }

  const getProductTypes = () => {
    adminRequest.get(API_FINANCE_ADMIN_ENDPOINTS.productTypes).then((response) => {
      setProductTypes(response.data['hydra:member'])
      getProduct()
    })
  }

  useEffect(() => {
    getProductTypes()
    dispatch(setCurrentPageTitle('Edit product'))
  }, [])

  return (
    <Box>
      <BreadcrumbsComponent
        currentPage={{ name: savedProductName ? savedProductName : `Edit product` }}
        parentPages={[
          {
            name: 'Products',
            icon: (
              <img src={IconProduct} style={{ filter: 'brightness(73%)', width: '13px' }} alt="" />
            ),
            link: '/admin/financing/products',
          },
        ]}
      />
      <TextH1
        sx={{
          fontSize: '36px',
          mb: '30px',
          mt: '10px',
        }}
      >
        Edit product "{savedProductName}"
      </TextH1>
      <Grid container spacing={'20px'}>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Product name'}
            value={productName}
            onChange={(e) => {
              setProductName(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            required
            fullWidth
            sx={{
              color: colorsConfig.iconGray,
              fontWeight: 400,
              fontSize: '14px',
              border: 'none',
            }}
            size={'small'}
          >
            <InputLabel
              id="product-type"
              sx={{
                color: colorsConfig.iconGray,
                fontWeight: 400,
                fontSize: '14px',
                '&.Mui-focused': {
                  background: 'white',
                  color: colorsConfig.mainBlue,
                  boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                  overflow: 'visible',
                },
              }}
            >
              Product type
            </InputLabel>
            <Select
              labelId="product-type"
              id="product-type-field"
              label="Engine type"
              value={productType}
              onChange={(e) => {
                setProductType(e.target.value)
              }}
              sx={{
                borderRadius: '4px',
                height: '38px',
                fontSize: '14px',
                border: 'none',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                  boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                },
              }}
            >
              <MenuItem value={''}>Not Chosen</MenuItem>
              {productTypes.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.title}>
                    {item.title}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: '40px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ButtonPlain
          sx={{
            color: colorsConfig.mainRed,
            '&:hover': {
              color: colorsConfig.darkRed,
            },
          }}
          onClick={() => {
            setConfirmDeletionModal(true)
          }}
        >
          <Delete
            sx={{
              fill: colorsConfig.mainRed,
              mr: '4px',
            }}
          />
          Delete
        </ButtonPlain>
        <ButtonLG
          sx={{
            minWidth: '187px',
          }}
          disabled={hasErrors}
          onClick={() => {
            handleEditProduct()
          }}
        >
          Save
        </ButtonLG>
      </Box>
      <ModalWrap open={confirmDeletionModal} setOpen={setConfirmDeletionModal}>
        <TextH1 sx={{ mb: '30px', fontSize: '32px' }}>
          Are you sure you want to delete product type "{savedProductName}"?
        </TextH1>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <ButtonHollow
            sx={{
              flex: 1,
            }}
            onClick={() => {
              setConfirmDeletionModal(false)
            }}
          >
            Cancel
          </ButtonHollow>
          <ButtonDanger
            sx={{
              flex: 1,
              '&.Mui-disabled': {
                border: 'none',
                background: colorsConfig.lightGray,
                color: 'white',
              },
            }}
            disabled={disableDeleteButton}
            onClick={() => {
              handleDeleteProduct()
            }}
          >
            Delete
          </ButtonDanger>
        </Box>
      </ModalWrap>
    </Box>
  )
}

export default EditProduct
