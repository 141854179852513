import React, { useState, useEffect } from 'react'
import { Box, Grid, Stack, Typography, IconButton, Button, TextField } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import TextFieldSmall from '../../../ui/TextFieldSmall'
import TextAreaSmall from '../../../ui/TextAreaSmall'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import { privateRequest } from '../../../utils/request'
import { API_ENDPOINTS, CDN_BASE_URL } from '../../../config/default'
import { useSelector } from 'react-redux'
import carDefaultPicture from '../../../assests/img/car-not-found.png'
import { colorsConfig } from '../../../config/themeConfig'
import EditIcon from '../../ProfileManagement/EditIcon'
import { toast } from 'react-toastify'
import useValidate from '../../../hooks/useValidate'
import useCheckAllErrors from '../../../hooks/useCheckAllErrors'
import ButtonDangerSmall from '../../../ui/Buttons/ButtonDangerSmall'
import { useNavigate } from 'react-router-dom'
import { sumDelimiter } from '../../../utils/sumDelimiter'

const AppraisalOnCompoundBlock = () => {
  const [pickupDemand, setPickupDemand] = useState(null)
  const carData = useSelector((state) => state.car.carData)

  const getPickupDemand = () => {
    privateRequest.get(API_ENDPOINTS.pickupDemand(carData.id)).then((response) => {
      setPickupDemand(response.data)
    })
  }

  const navigate = useNavigate()

  const { deal } = ''
  const currentDate = new Date()

  const [date, setDate] = useState(null)
  const [location, setLocation] = useState('')
  const [time, setTime] = useState(null)
  const [contactEmail, setContactEmail] = useState('')
  const [contactName, setContactName] = useState('')
  const [contactPhone, setContactPhone] = useState('')
  const [mileage, setMileage] = useState('')
  const [additionalInformation, setAdditionalInformation] = useState('')
  const [isEditable, setIsEditable] = useState(false)
  const [rejectionReason, setRejectionReason] = useState('')

  const localStorageKey = `isAwaitingDelivery_${carData.id}`

  const [isAwaitingDelivery, setIsAwaitingDelivery] = useState(
    localStorage.getItem(localStorageKey) === 'true',
  )

  useEffect(() => {
    localStorage.setItem(localStorageKey, isAwaitingDelivery.toString())
  }, [isAwaitingDelivery, localStorageKey])

  const validateLocation = useValidate(location, { isEmpty: true })
  const validateContactEmail = useValidate(contactEmail, { isEmpty: true, email: true })
  const validateContactPhone = useValidate(contactPhone, { isEmpty: true, exactLength: 9 })
  const hasErrors = useCheckAllErrors([
    validateLocation.hasErrors,
    validateContactEmail.hasErrors,
    validateContactEmail.hasErrors,
  ])

  const handleSubmit = () => {
    const formData = {
      address: location['@id'],
      phone: contactPhone,
      email: contactEmail,
      contactPerson: contactName,
      date: date,
      mileage: +mileage,
      additionalInformation: additionalInformation,
    }

    privateRequest
      .patch(API_ENDPOINTS.pickupDemand(carData.id), formData, {
        headers: {
          accept: 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
        },
      })
      .then(() => {
        toast.success('The request for pickup has been changed successfully')
      })
      .catch(() => {
        toast.error('An error occurred while changing the request for pickup')
      })
  }

  const buttonsIcons = [
    {
      component: <EditIcon editIconWidth={15} editIconHeight={15} editIconColour={'#007DFF'} />,
    },
    {
      component: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="15"
          viewBox="0 0 12 15"
          fill="none"
        >
          <path
            d="M11.3274 3.16269C10.8808 2.30027 10.2201 1.56166 9.40601 1.01475C8.59194 0.467839 7.6506 0.130171 6.66852 0.032783C6.22403 -0.0109277 5.77619 -0.0109277 5.3317 0.032783C4.34966 0.130326 3.40839 0.468055 2.59434 1.01495C1.78029 1.56184 1.11957 2.30037 0.672857 3.16269C0.110781 4.26302 -0.106383 5.50168 0.0487142 6.72269C0.203811 7.94369 0.724234 9.09244 1.54444 10.0242L5.54547 14.7898C5.60072 14.8555 5.67014 14.9085 5.74874 14.9448C5.82735 14.9811 5.91319 15 6.00011 15C6.08703 15 6.17287 14.9811 6.25147 14.9448C6.33008 14.9085 6.3995 14.8555 6.45474 14.7898L10.4552 10.0242C11.2755 9.0925 11.796 7.94378 11.9512 6.72278C12.1064 5.50177 11.8894 4.26307 11.3274 3.16269ZM6.00011 8.07649C5.53421 8.07649 5.07877 7.94114 4.69139 7.68756C4.30401 7.43398 4.00208 7.07356 3.82379 6.65187C3.6455 6.23018 3.59885 5.76617 3.68974 5.31851C3.78064 4.87084 4.00499 4.45964 4.33443 4.13689C4.66387 3.81415 5.0836 3.59435 5.54055 3.50531C5.99749 3.41626 6.47113 3.46196 6.90157 3.63663C7.332 3.8113 7.6999 4.10709 7.95874 4.4866C8.21758 4.86611 8.35573 5.3123 8.35573 5.76873C8.35496 6.38055 8.10652 6.9671 7.66493 7.39972C7.22333 7.83235 6.62462 8.07573 6.00011 8.07649Z"
            fill="#007DFF"
          />
        </svg>
      ),
    },
  ]

  useEffect(() => {
    getPickupDemand()
  }, [])

  const pickupLocationInfo = ({ street, state, country, postcode }) =>
    `${state} ${street} ${country} ${postcode}`

  const openGoogleMaps = () => {
    const keywords = location
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(keywords)}`
    window.open(googleMapsUrl, '_blank')
  }

  const createButton = (color, background, border, label, handler, variant) => {
    return (
      <Button
        variant={variant}
        sx={{
          height: '48px',
          fontFamily: 'Helvetica',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
          textTransform: 'none',
          marginTop: '13px',
          color: color,
          background: background,
          border: border,
          borderRadius: '4px',
          padding: '6px 70px',
        }}
        onClick={handler}
      >
        {label}
      </Button>
    )
  }

  const createButtonLink = (iconComponent, typographyText, handler) => {
    return (
      <Box
        sx={{
          width: 'fit-content',
        }}
        onClick={handler}
      >
        <IconButton
          sx={{
            padding: 0,
            paddingBottom: '3px',
            background: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          {iconComponent}
          <Typography
            sx={{
              fontSize: '16px',
              textDecorationLine: 'underline',
              color: '#007DFF',
              marginLeft: '3px',
              marginBottom: '-3px',
            }}
          >
            {typographyText}
          </Typography>
        </IconButton>
      </Box>
    )
  }

  const handleFailRequestedCompoundAppraisal = () => {
    privateRequest
      .post(
        API_ENDPOINTS.failRequestedCompoundAppraisal(carData.id),
        { message: rejectionReason },
        {
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        toast.success('The request for an update of the information was successfully sent')
        navigate('/operator-panel')
      })
      .catch(() => {
        toast.error('An error occurred while handling the request')
      })
  }

  const handleAwaitingDeliveryRequest = () => {
    privateRequest
      .post(
        API_ENDPOINTS.sentToCompound(carData.id),
        {},
        {
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        toast.success('The request for pickup has been sent')
        setIsAwaitingDelivery(true)
        // navigate('/operator-panel');
      })
      .catch(() => {
        toast.error('An error occurred while handling the request')
      })
  }

  const handleDeliveredToCompoundRequest = () => {
    privateRequest
      .post(
        API_ENDPOINTS.startAppraisalOnCompound(carData.id),
        {},
        {
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        toast.success('The request for appraisal on compound has been sent')
        setIsAwaitingDelivery(true)
        navigate('/operator-panel')
      })
      .catch(() => {
        toast.error('An error occurred while handling the request')
      })
  }

  useEffect(() => {
    setDate(pickupDemand ? pickupDemand.date : '')
    setLocation(pickupDemand ? pickupDemand.address : '')
    setTime(pickupDemand ? pickupDemand.date : '')
    setContactEmail(pickupDemand ? pickupDemand.email : '')
    setContactName(pickupDemand ? pickupDemand.contactPerson : '')
    setContactPhone(pickupDemand ? pickupDemand.phone : '')
    setMileage(pickupDemand ? pickupDemand.mileage : '')
    setAdditionalInformation(pickupDemand ? pickupDemand.additionalInformation : '')
  }, [pickupDemand])

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: '15px',
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            color: colorsConfig.mainBlack,
            fontSize: '40px',
          }}
        >
          #{carData.referenceNumber} {carData.make.name} {carData.model.name}
        </Typography>
        <svg
          width="230"
          height="40"
          viewBox="0 0 230 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 6C0 2.68629 2.68629 0 6 0H218.711C223.519 0 226.374 5.3719 223.684 9.35684L204.784 37.3568C203.669 39.0095 201.805 40 199.811 40H6C2.68629 40 0 37.3137 0 34V6Z"
            fill="#F5BD4F"
          />
          <path
            d="M110.282 13.3637L114.405 25.5851L118.479 13.3637H120.657L115.421 27.7148H113.36L108.133 13.3637H110.282ZM122.68 13.3637H133.142V15.1222H124.575V19.4793H132.498V21.1401H124.575V26.0052H133.289V27.7148H122.68V13.3637ZM142.511 19.9385C143.423 19.9385 144.143 19.7561 144.67 19.3914C145.204 19.0267 145.471 18.3689 145.471 17.418C145.471 16.3955 145.1 15.6986 144.358 15.3273C143.96 15.132 143.43 15.0343 142.765 15.0343H138.017V19.9385H142.511ZM136.073 13.3637H142.716C143.811 13.3637 144.713 13.5233 145.423 13.8424C146.771 14.4546 147.445 15.5846 147.445 17.2324C147.445 18.0921 147.266 18.7954 146.907 19.3425C146.556 19.8896 146.061 20.3292 145.423 20.6614C145.983 20.8893 146.403 21.1889 146.683 21.5602C146.969 21.9314 147.129 22.5338 147.161 23.3675L147.23 25.2921C147.249 25.8391 147.295 26.2462 147.367 26.5132C147.484 26.9691 147.692 27.2622 147.992 27.3925V27.7148H145.608C145.543 27.5911 145.491 27.4315 145.452 27.2361C145.413 27.0408 145.38 26.663 145.354 26.1029L145.237 23.7094C145.191 22.7716 144.843 22.1431 144.192 21.8239C143.82 21.6481 143.237 21.5602 142.443 21.5602H138.017V27.7148H136.073V13.3637ZM150.737 13.3637H152.701V27.7148H150.737V13.3637ZM156.052 13.3637H166.006V15.1222H157.996V19.4793H165.039V21.1889H157.996V27.7148H156.052V13.3637ZM168.537 13.3637H170.5V27.7148H168.537V13.3637ZM173.851 13.3637H184.314V15.1222H175.747V19.4793H183.669V21.1401H175.747V26.0052H184.461V27.7148H173.851V13.3637ZM192.52 26.0541C193.178 26.0541 193.719 25.9857 194.142 25.8489C194.898 25.5949 195.516 25.1064 195.998 24.3835C196.383 23.8039 196.659 23.0614 196.829 22.1561C196.926 21.6155 196.975 21.114 196.975 20.6516C196.975 18.8736 196.62 17.4929 195.91 16.5094C195.207 15.526 194.07 15.0343 192.501 15.0343H189.052V26.0541H192.52ZM187.098 13.3637H192.911C194.885 13.3637 196.415 14.0638 197.503 15.4641C198.473 16.7276 198.958 18.3461 198.958 20.3195C198.958 21.8435 198.672 23.221 198.099 24.4519C197.089 26.6272 195.354 27.7148 192.892 27.7148H187.098V13.3637Z"
            fill="#3D3D3D"
          />
          <path
            d="M14.8809 28.0499C14.5725 28.0499 14.4184 28.0499 14.3006 27.9899C14.197 27.9371 14.1128 27.8529 14.06 27.7493C14 27.6315 14 27.4773 14 27.169V17.9982C14 16.4565 14 15.6856 14.3 15.0968C14.564 14.5788 14.9851 14.1577 15.503 13.8938C16.0919 13.5938 16.8627 13.5938 18.4045 13.5938H23.5328C23.8411 13.5938 23.9953 13.5938 24.1131 13.6538C24.2166 13.7065 24.3009 13.7908 24.3537 13.8944C24.4137 14.0121 24.4137 14.1663 24.4137 14.4746V15.9791C24.4137 16.2874 24.4137 16.4416 24.3537 16.5594C24.3009 16.663 24.2166 16.7472 24.1131 16.8C23.9953 16.86 23.8411 16.86 23.5328 16.86H19.004C18.6957 16.86 18.5415 16.86 18.4237 16.92C18.3201 16.9728 18.2359 17.057 18.1831 17.1606C18.1231 17.2784 18.1231 17.4325 18.1231 17.7409V18.3784C18.1231 18.6867 18.1231 18.8409 18.1831 18.9587C18.2359 19.0623 18.3201 19.1465 18.4237 19.1993C18.5415 19.2593 18.6957 19.2593 19.004 19.2593H22.8977C23.206 19.2593 23.3602 19.2593 23.478 19.3193C23.5815 19.3721 23.6658 19.4563 23.7186 19.5599C23.7786 19.6776 23.7786 19.8318 23.7786 20.1402V21.6345C23.7786 21.9429 23.7786 22.097 23.7186 22.2148C23.6658 22.3184 23.5815 22.4026 23.478 22.4554C23.3602 22.5154 23.206 22.5154 22.8977 22.5154H19.0141C18.7058 22.5154 18.5516 22.5154 18.4338 22.5754C18.3302 22.6282 18.246 22.7124 18.1932 22.816C18.1332 22.9338 18.1332 23.088 18.1332 23.3963V27.169C18.1332 27.4773 18.1332 27.6315 18.0732 27.7493C18.0204 27.8529 17.9362 27.9371 17.8326 27.9899C17.7148 28.0499 17.5607 28.0499 17.2523 28.0499H14.8809Z"
            fill="#3D3D3D"
          />
          <path
            d="M35.2974 27.169C35.2974 27.4773 35.2974 27.6315 35.2374 27.7493C35.1846 27.8529 35.1004 27.9371 34.9968 27.9899C34.879 28.0499 34.7248 28.0499 34.4165 28.0499H26.6719C26.3636 28.0499 26.2094 28.0499 26.0916 27.9899C25.988 27.9371 25.9038 27.8529 25.851 27.7493C25.791 27.6315 25.791 27.4773 25.791 27.169V14.4746C25.791 14.1663 25.791 14.0121 25.851 13.8944C25.9038 13.7908 25.988 13.7065 26.0916 13.6538C26.2094 13.5938 26.3636 13.5938 26.6719 13.5938H29.0425C29.3511 13.5938 29.5054 13.5938 29.6232 13.6538C29.7269 13.7067 29.8111 13.791 29.8639 13.8947C29.9238 14.0125 29.9237 14.1668 29.9234 14.4754L29.9149 23.902C29.9147 24.2106 29.9145 24.3649 29.9745 24.4827C30.0272 24.5864 30.1115 24.6707 30.2151 24.7236C30.3329 24.7836 30.4872 24.7836 30.7958 24.7836H34.4165C34.7248 24.7836 34.879 24.7836 34.9968 24.8437C35.1004 24.8964 35.1846 24.9807 35.2374 25.0843C35.2974 25.202 35.2974 25.3562 35.2974 25.6645V27.169Z"
            fill="#3D3D3D"
          />
          <path
            d="M37.5606 28.0499C37.2522 28.0499 37.0981 28.0499 36.9803 27.9899C36.8767 27.9371 36.7925 27.8529 36.7397 27.7493C36.6797 27.6315 36.6797 27.4773 36.6797 27.169V14.4746C36.6797 14.1663 36.6797 14.0121 36.7397 13.8944C36.7925 13.7908 36.8767 13.7065 36.9803 13.6538C37.0981 13.5938 37.2522 13.5938 37.5606 13.5938H46.1621C46.4704 13.5938 46.6246 13.5938 46.7423 13.6538C46.8459 13.7065 46.9302 13.7908 46.9829 13.8944C47.0429 14.0121 47.0429 14.1663 47.0429 14.4746V15.9791C47.0429 16.2874 47.0429 16.4416 46.9829 16.5594C46.9302 16.663 46.8459 16.7472 46.7423 16.8C46.6246 16.86 46.4704 16.86 46.1621 16.86H41.6938C41.3854 16.86 41.2313 16.86 41.1135 16.92C41.0099 16.9728 40.9257 17.057 40.8729 17.1606C40.8129 17.2784 40.8129 17.4325 40.8129 17.7409V18.3784C40.8129 18.6867 40.8129 18.8409 40.8729 18.9587C40.9257 19.0623 41.0099 19.1465 41.1135 19.1993C41.2313 19.2593 41.3854 19.2593 41.6938 19.2593H45.527C45.8353 19.2593 45.9895 19.2593 46.1072 19.3193C46.2108 19.3721 46.2951 19.4563 46.3478 19.5599C46.4078 19.6776 46.4078 19.8318 46.4078 20.1402V21.6345C46.4078 21.9429 46.4078 22.097 46.3478 22.2148C46.2951 22.3184 46.2108 22.4026 46.1072 22.4554C45.9895 22.5154 45.8353 22.5154 45.527 22.5154H41.6938C41.3854 22.5154 41.2313 22.5154 41.1135 22.5754C41.0099 22.6282 40.9257 22.7124 40.8729 22.816C40.8129 22.9338 40.8129 23.088 40.8129 23.3963V23.9028C40.8129 24.2111 40.8129 24.3653 40.8729 24.483C40.9257 24.5866 41.0099 24.6709 41.1135 24.7236C41.2313 24.7836 41.3854 24.7836 41.6938 24.7836H46.4645C46.7728 24.7836 46.927 24.7836 47.0448 24.8437C47.1484 24.8964 47.2326 24.9807 47.2854 25.0843C47.3454 25.202 47.3454 25.3562 47.3454 25.6645V27.169C47.3454 27.4773 47.3454 27.6315 47.2854 27.7493C47.2326 27.8529 47.1484 27.9371 47.0448 27.9899C46.927 28.0499 46.7728 28.0499 46.4645 28.0499H37.5606Z"
            fill="#3D3D3D"
          />
          <path
            d="M49.6035 28.0499C49.2952 28.0499 49.141 28.0499 49.0233 27.9899C48.9197 27.9371 48.8354 27.8529 48.7827 27.7493C48.7227 27.6315 48.7227 27.4773 48.7227 27.169V14.4746C48.7227 14.1663 48.7227 14.0121 48.7827 13.8944C48.8354 13.7908 48.9197 13.7065 49.0233 13.6538C49.141 13.5938 49.2952 13.5938 49.6035 13.5938H58.205C58.5134 13.5938 58.6675 13.5938 58.7853 13.6538C58.8889 13.7065 58.9731 13.7908 59.0259 13.8944C59.0859 14.0121 59.0859 14.1663 59.0859 14.4746V15.9791C59.0859 16.2874 59.0859 16.4416 59.0259 16.5594C58.9731 16.663 58.8889 16.7472 58.7853 16.8C58.6675 16.86 58.5134 16.86 58.205 16.86H53.7368C53.4284 16.86 53.2742 16.86 53.1565 16.92C53.0529 16.9728 52.9687 17.057 52.9159 17.1606C52.8559 17.2784 52.8559 17.4325 52.8559 17.7409V18.3784C52.8559 18.6867 52.8559 18.8409 52.9159 18.9587C52.9687 19.0623 53.0529 19.1465 53.1565 19.1993C53.2742 19.2593 53.4284 19.2593 53.7368 19.2593H57.5699C57.8783 19.2593 58.0324 19.2593 58.1502 19.3193C58.2538 19.3721 58.338 19.4563 58.3908 19.5599C58.4508 19.6776 58.4508 19.8318 58.4508 20.1402V21.6345C58.4508 21.9429 58.4508 22.097 58.3908 22.2148C58.338 22.3184 58.2538 22.4026 58.1502 22.4554C58.0324 22.5154 57.8783 22.5154 57.5699 22.5154H53.7368C53.4284 22.5154 53.2742 22.5154 53.1565 22.5754C53.0529 22.6282 52.9687 22.7124 52.9159 22.816C52.8559 22.9338 52.8559 23.088 52.8559 23.3963V23.9028C52.8559 24.2111 52.8559 24.3653 52.9159 24.483C52.9687 24.5866 53.0529 24.6709 53.1565 24.7236C53.2742 24.7836 53.4284 24.7836 53.7368 24.7836H58.5075C58.8158 24.7836 58.97 24.7836 59.0877 24.8437C59.1913 24.8964 59.2756 24.9807 59.3283 25.0843C59.3883 25.202 59.3883 25.3562 59.3883 25.6645V27.169C59.3883 27.4773 59.3883 27.6315 59.3283 27.7493C59.2756 27.8529 59.1913 27.9371 59.0877 27.9899C58.97 28.0499 58.8158 28.0499 58.5075 28.0499H49.6035Z"
            fill="#3D3D3D"
          />
          <path
            d="M60.2754 14.4785C60.2754 14.1702 60.2754 14.016 60.3354 13.8983C60.3882 13.7947 60.4724 13.7104 60.576 13.6577C60.6938 13.5977 60.8479 13.5977 61.1563 13.5977H70.5441C70.8524 13.5977 71.0066 13.5977 71.1244 13.6577C71.228 13.7104 71.3122 13.7947 71.365 13.8983C71.425 14.016 71.425 14.1702 71.425 14.4785V15.983C71.425 16.2913 71.425 16.4455 71.365 16.5633C71.3122 16.6669 71.228 16.7511 71.1244 16.8039C71.0066 16.8639 70.8524 16.8639 70.5441 16.8639H68.7977C68.4893 16.8639 68.3352 16.8639 68.2174 16.9239C68.1138 16.9767 68.0296 17.0609 67.9768 17.1645C67.9168 17.2823 67.9168 17.4364 67.9168 17.7448V27.1729C67.9168 27.4812 67.9168 27.6354 67.8568 27.7532C67.804 27.8568 67.7198 27.941 67.6162 27.9938C67.4984 28.0538 67.3442 28.0538 67.0359 28.0538H64.6745C64.3662 28.0538 64.212 28.0538 64.0943 27.9938C63.9907 27.941 63.9064 27.8568 63.8537 27.7532C63.7937 27.6354 63.7937 27.4812 63.7937 27.1729V17.7448C63.7937 17.4364 63.7937 17.2823 63.7336 17.1645C63.6809 17.0609 63.5966 16.9767 63.493 16.9239C63.3753 16.8639 63.2211 16.8639 62.9128 16.8639H61.1563C60.8479 16.8639 60.6938 16.8639 60.576 16.8039C60.4724 16.7511 60.3882 16.6669 60.3354 16.5633C60.2754 16.4455 60.2754 16.2913 60.2754 15.983V14.4785Z"
            fill="#3D3D3D"
          />
          <path
            d="M80.2051 28.0538C79.8968 28.0538 79.7426 28.0538 79.6248 27.9938C79.5212 27.941 79.437 27.8568 79.3842 27.7532C79.3242 27.6354 79.3242 27.4812 79.3242 27.1729V14.4785C79.3242 14.1702 79.3242 14.016 79.3842 13.8983C79.437 13.7947 79.5212 13.7104 79.6248 13.6577C79.7426 13.5977 79.8968 13.5977 80.2051 13.5977H88.857C89.1653 13.5977 89.3195 13.5977 89.4373 13.6577C89.5409 13.7104 89.6251 13.7947 89.6779 13.8983C89.7379 14.016 89.7379 14.1702 89.7379 14.4785V15.983C89.7379 16.2913 89.7379 16.4455 89.6779 16.5633C89.6251 16.6669 89.5409 16.7511 89.4373 16.8039C89.3195 16.8639 89.1653 16.8639 88.857 16.8639H84.3282C84.0199 16.8639 83.8657 16.8639 83.748 16.9239C83.6444 16.9767 83.5601 17.0609 83.5074 17.1645C83.4473 17.2823 83.4473 17.4364 83.4473 17.7448V18.3823C83.4473 18.6906 83.4473 18.8448 83.5074 18.9626C83.5601 19.0662 83.6444 19.1504 83.748 19.2032C83.8657 19.2632 84.0199 19.2632 84.3282 19.2632H88.2219C88.5302 19.2632 88.6844 19.2632 88.8022 19.3232C88.9058 19.376 88.99 19.4602 89.0428 19.5638C89.1028 19.6816 89.1028 19.8357 89.1028 20.1441V21.6384C89.1028 21.9468 89.1028 22.101 89.0428 22.2187C88.99 22.3223 88.9058 22.4065 88.8022 22.4593C88.6844 22.5193 88.5302 22.5193 88.2219 22.5193H84.3383C84.03 22.5193 83.8758 22.5193 83.758 22.5793C83.6544 22.6321 83.5702 22.7163 83.5174 22.8199C83.4574 22.9377 83.4574 23.0919 83.4574 23.4002V27.1729C83.4574 27.4812 83.4574 27.6354 83.3974 27.7532C83.3446 27.8568 83.2604 27.941 83.1568 27.9938C83.039 28.0538 82.8849 28.0538 82.5765 28.0538H80.2051Z"
            fill="#3D3D3D"
          />
          <path
            d="M96.5155 17.7726C96.7708 18.3393 96.8984 18.6227 97.0755 18.7101C97.2294 18.7861 97.4099 18.786 97.5637 18.7098C97.7407 18.6221 97.8679 18.3385 98.1224 17.7715L99.6877 14.2829C99.7722 14.0945 99.8145 14.0003 99.8809 13.9313C99.9397 13.8703 100.012 13.8236 100.091 13.795C100.182 13.7626 100.285 13.7626 100.491 13.7626H103.041C103.519 13.7626 103.758 13.7626 103.901 13.8633C104.025 13.9512 104.108 14.0868 104.129 14.2378C104.153 14.4108 104.043 14.6232 103.824 15.0479L99.4751 23.4618C99.4384 23.5327 99.4201 23.5682 99.4072 23.6055C99.3957 23.6386 99.3874 23.6727 99.3824 23.7074C99.3768 23.7465 99.3768 23.7864 99.3768 23.8662V27.3379C99.3768 27.6462 99.3768 27.8004 99.3168 27.9181C99.264 28.0217 99.1798 28.106 99.0762 28.1587C98.9584 28.2188 98.8042 28.2188 98.4959 28.2188H96.1244C95.8161 28.2188 95.6619 28.2188 95.5442 28.1587C95.4406 28.106 95.3564 28.0217 95.3036 27.9181C95.2436 27.8004 95.2436 27.6462 95.2436 27.3379V23.8659C95.2436 23.7861 95.2436 23.7463 95.238 23.7073C95.233 23.6727 95.2247 23.6386 95.2133 23.6055C95.2004 23.5683 95.1821 23.5329 95.1456 23.462L90.8052 15.0473C90.5862 14.6227 90.4767 14.4104 90.5007 14.2375C90.5217 14.0865 90.6042 13.951 90.7288 13.8632C90.8715 13.7626 91.1103 13.7626 91.5881 13.7626H94.1399C94.3461 13.7626 94.4491 13.7626 94.5392 13.7949C94.6189 13.8235 94.6909 13.87 94.7496 13.9309C94.8161 13.9998 94.8584 14.0938 94.9431 14.2817L96.5155 17.7726Z"
            fill="#3D3D3D"
          />
          <path
            d="M73.791 28.0538C73.4827 28.0538 73.3285 28.0538 73.2108 27.9938C73.1072 27.941 73.0229 27.8568 72.9702 27.7532C72.9102 27.6354 72.9102 27.4812 72.9102 27.1729V14.4785C72.9102 14.1702 72.9102 14.016 72.9702 13.8983C73.0229 13.7947 73.1072 13.7104 73.2108 13.6577C73.3285 13.5977 73.4827 13.5977 73.791 13.5977H76.1625C76.4708 13.5977 76.625 13.5977 76.7428 13.6577C76.8463 13.7104 76.9306 13.7947 76.9834 13.8983C77.0434 14.016 77.0434 14.1702 77.0434 14.4785V27.1729C77.0434 27.4812 77.0434 27.6354 76.9834 27.7532C76.9306 27.8568 76.8463 27.941 76.7428 27.9938C76.625 28.0538 76.4708 28.0538 76.1625 28.0538H73.791Z"
            fill="#3D3D3D"
          />
          <circle cx="75.0313" cy="10.2286" r="2.22857" fill="#3D3D3D" />
        </svg>
      </Box>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Grid item>
          <Box
            sx={{
              width: '445px',
              borderRadius: '6px',
              overflow: 'hidden',
              height: '298px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {carData.foreshortenings.length > 0 && carData.foreshortenings[0].photo ? (
              <img
                src={`${CDN_BASE_URL}${carData.foreshortenings[0].photo.path}`}
                alt=""
                style={{
                  width: '100%',
                  borderRadius: '6px',
                }}
              />
            ) : (
              <img src={carDefaultPicture} style={{ width: '100%' }} alt="" />
            )}
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            width: '765px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: '20px',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Helvetica',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: 'normal',
              }}
            >
              Appraisal on compound request
            </Typography>
            {isEditable
              ? createButtonLink(buttonsIcons[0].component, 'Save changes', () => {
                  setIsEditable((prevValue) => !prevValue)
                  handleSubmit()
                })
              : createButtonLink(buttonsIcons[0].component, 'Change', () => {
                  setIsEditable((prevValue) => !prevValue)
                })}
          </Box>

          {pickupDemand ? (
            <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
              <Box
                sx={{
                  mb: '8px',
                }}
              >
                <Grid
                  container
                  spacing={3}
                  sx={{
                    '& .MuiInputBase-root': {
                      background: 'white',
                    },
                  }}
                >
                  <Grid item md={4}>
                    <Stack spacing={3}>
                      <DesktopDatePicker
                        label="Pickup Date"
                        inputFormat="DD/MM/YYYY"
                        mask="__/__/____"
                        minDate={currentDate}
                        value={date}
                        onChange={(newValue) => {
                          setDate(newValue)
                        }}
                        renderInput={(params) => <TextFieldSmall {...params} fullWidth required />}
                        disabled={!isEditable}
                      />
                      <TimePicker
                        fullWidth
                        label="Pickup Time"
                        value={time}
                        onChange={(newValue) => {
                          setTime(newValue)
                        }}
                        renderInput={(params) => <TextFieldSmall required fullWidth {...params} />}
                        disabled={!isEditable}
                      />
                      <TextFieldSmall
                        fullWidth
                        required
                        type="text"
                        label="Pickup Location"
                        value={pickupLocationInfo(location)}
                        onChange={(e) => {
                          setLocation(e.target.value)
                        }}
                        error={validateLocation.hasErrors && validateLocation.isDirty}
                        helperText={validateLocation.errorText}
                        disabled={!isEditable}
                      />
                    </Stack>
                  </Grid>
                  <Grid item md={4}>
                    <Stack spacing={3}>
                      <TextFieldSmall
                        fullWidth
                        required
                        type="text"
                        label="Contact name"
                        value={contactName}
                        onChange={(e) => {
                          setContactName(e.target.value)
                        }}
                        disabled={!isEditable}
                      />
                      <TextFieldSmall
                        fullWidth
                        type="text"
                        required
                        label="Contact phone"
                        value={contactPhone}
                        onChange={(e) => {
                          setContactPhone(e.target.value.replace(/[^0-9]/g, ''))
                        }}
                        error={validateContactPhone.hasErrors && validateContactPhone.isDirty}
                        helperText={validateContactPhone.errorText}
                        disabled={!isEditable}
                        inputProps={{
                          maxLength: 9,
                        }}
                        InputProps={{
                          startAdornment: (
                            <Typography
                              sx={{
                                fontSize: '14px',
                                paddingTop: '1px',
                                color: isEditable ? '#757575' : 'rgba(0, 0, 0, 0.38)',
                              }}
                            >
                              +971
                            </Typography>
                          ),
                        }}
                      />
                      <TextFieldSmall
                        fullWidth
                        required
                        type="email"
                        label="Email"
                        value={contactEmail}
                        onChange={(e) => {
                          setContactEmail(e.target.value)
                        }}
                        error={validateContactEmail.hasErrors && validateContactEmail.isDirty}
                        helperText={validateContactEmail.errorText}
                        disabled={!isEditable}
                      />
                    </Stack>
                  </Grid>
                  <Grid item md={4}>
                    <Stack spacing={3}>
                      <TextFieldSmall
                        fullWidth
                        required
                        type="text"
                        label="Mileage"
                        value={sumDelimiter(mileage)}
                        onChange={(e) => {
                          setMileage(e.target.value.replace(/[^0-9]/g, ''))
                        }}
                        disabled={!isEditable}
                      />
                      <TextAreaSmall
                        fullWidth
                        label="Additional Information"
                        multiline
                        rows={4}
                        sx={{
                          '& .MuiInputBase-root': {
                            height: '100px !important',
                          },
                          background: 'white',
                        }}
                        value={additionalInformation}
                        onChange={(e) => {
                          setAdditionalInformation(e.target.value)
                        }}
                        disabled={!isEditable}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </LocalizationProvider>
          ) : null}
          {/*{ createButtonLink(buttonsIcons[1].component, 'See on map', ()=> {openGoogleMaps()}) }*/}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '30px',
            }}
          >
            {isAwaitingDelivery ? (
              createButton(
                'white',
                colorsConfig.mainBlue,
                'none',
                'Delivered to compound',
                () => {
                  handleDeliveredToCompoundRequest()
                },
                'contained',
              )
            ) : (
              <>
                {/* {createButton(colorsConfig.mainRed, 'white', `1px solid ${colorsConfig.mainRed}`, 'Reject', () => { handleRejectRequest() })} */}
                {createButton(
                  'white',
                  colorsConfig.mainBlue,
                  'none',
                  'Awaiting delivery',
                  () => {
                    handleAwaitingDeliveryRequest()
                  },
                  'contained',
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          marginTop: '50px',
          position: 'relative',
          minHeight: '159px',
          background: 'white',
        }}
      >
        <TextFieldSmall
          fullWidth
          label={'What information should be updated?'}
          type="textarea"
          multiline
          sx={{
            height: 'auto',
          }}
          rows={7}
          value={rejectionReason}
          onChange={(e) => {
            setRejectionReason(e.target.value)
          }}
        />
        <ButtonDangerSmall
          sx={{
            position: 'absolute',
            bottom: '16px',
            right: '15px',
          }}
          onClick={() => {
            rejectionReason
              ? handleFailRequestedCompoundAppraisal()
              : toast.error('Please write the information update for your request')
          }}
        >
          Request information update
        </ButtonDangerSmall>
      </Box>
    </Box>
  )
}

export default AppraisalOnCompoundBlock
