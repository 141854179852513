import React from 'react'
import { IconButton } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import IconCheckmark from '../../assests/img/icons/icon-checkmark.svg'

const UICheckboxNullableChecked = ({ value, setValue }) => {
  return (
    <IconButton
      className={value === true && typeof value === 'boolean' ? 'active' : ''}
      sx={{
        border: '1px solid',
        borderColor: colorsConfig.iconGray,
        width: '20px',
        height: '20px',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        mr: '3px',
        transition: '0.25s all ease-in-out',
        '& img': {
          filter: 'brightness(60%)',
          transition: '0.25s all ease-in-out',
        },
        cursor: 'pointer',
        '&.active': {
          transition: '0.25s all ease-in-out',
          borderColor: colorsConfig.mainGreen,
          background: colorsConfig.mainGreen,
          '& img': {
            filter: 'brightness(100%)',
            transition: '0.25s all ease-in-out',
          },
        },
      }}
      onClick={() => {
        if (typeof value === 'boolean' && value === true) {
          setValue(null)
        } else {
          setValue(true)
        }
      }}
    >
      <img
        src={IconCheckmark}
        alt=""
        style={{
          width: '11px',
        }}
      />
    </IconButton>
  )
}

export default UICheckboxNullableChecked
