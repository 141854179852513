import React from 'react'
import { Stack } from '@mui/material'
import UITextHeadlineLarge from '../../../../ui/UIText/UITextHeadlineLarge'
import ButtonHollow from '../../../../ui/Buttons/ButtonHollow'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import ModalWrap from '../../../../components/Modals/ModalWrap'
import { useSelector } from 'react-redux'

const ApproveRestitutionRequestModal = ({ open, setOpen, handleAction }) => {
  const isLoading = useSelector((state) => state.rviOperator.closeRestitution.isLoading)

  return (
    <ModalWrap open={open} setOpen={setOpen}>
      <Stack gap="30px">
        <UITextHeadlineLarge
          sx={{
            fontSize: {
              xs: '24px',
              md: '32px',
            },
          }}
          text="Are you sure you want to close restitution process for this vehicle?"
        />
        <Stack gap="10px" direction={{ xs: 'column', md: 'row' }}>
          <ButtonHollow disabled={isLoading} sx={{ flex: 1 }} onClick={() => setOpen(false)}>
            Cancel
          </ButtonHollow>
          <ButtonLG disabled={isLoading} sx={{ flex: 1 }} onClick={() => handleAction()}>
            Confirm
          </ButtonLG>
        </Stack>
      </Stack>
    </ModalWrap>
  )
}

export default ApproveRestitutionRequestModal
