import { privateRequest } from '../../utils/request'

class ControlPointsApiService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      carControlPoints: {
        batch: (id) => `/cars/${id}/control-points/batch`,
      },
      controlPoints: {
        collection: 'reference/appraisal/control-points',
      },
    }
  }

  updateControlPoints = async (carId, carControlPoints) => {
    const response = await this.#api.post(this.#endPoints.carControlPoints.batch(carId), {
      controlPointAggregates: carControlPoints,
    })
    return response
  }

  getControlPoints = async () => {
    const response = await this.#api.get(this.#endPoints.controlPoints.collection, {
      params: {
        pagination: false,
      },
    })
    return response
  }
}

const controlPointsApiService = new ControlPointsApiService({ api: privateRequest })

export default controlPointsApiService
