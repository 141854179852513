import React from 'react'
import { Box, IconButton } from '@mui/material'
import { colorsConfig } from '../config/themeConfig'
import IconCheckmark from '../assests/img/icons/icon-checkmark-black.svg'

const CheckmarkBlack = (props) => {
  const { value, setValue } = props

  return (
    <Box>
      <IconButton
        className={value ? 'active' : ''}
        sx={{
          width: '24px',
          height: '24px',
          borderRadius: '6px',
          border: '1px solid',
          borderColor: colorsConfig.mainBlack,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          '& img': {
            opacity: 0,
            transition: '0.25s all ease-in-out',
          },
          '&.active': {
            '& img': {
              opacity: 1,
              transition: '0.25s all ease-in-out',
            },
          },
        }}
        onClick={() => {
          setValue(!value)
        }}
      >
        <img style={{ width: '16px' }} src={IconCheckmark} alt="" />
      </IconButton>
    </Box>
  )
}

export default CheckmarkBlack
