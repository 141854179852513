import { createSlice } from '@reduxjs/toolkit'
import getWorkflowsStatesBuilder from './builders/getWorkflowsStates.builder'
import getLoanApplicationWorkflowStatesBuilder from './builders/getLoanApplicationWorkflowStates.builder'

const localWorkflowStates = JSON.parse(localStorage.getItem('workflowStates'))

const carWorkflowStates = localWorkflowStates?.car ?? []
const auctionWorkflowStates = localWorkflowStates?.auction ?? []
const dealWorkflowStates = localWorkflowStates?.deal ?? []
const insuranceApplicationWorkflowStates = localWorkflowStates?.insuranceApplication ?? []
const loanApplicationWorkflowStates = localWorkflowStates?.loanApplication ?? []

const initialState = {
  loaded: false,
  carWorkflowStates,
  auctionWorkflowStates,
  dealWorkflowStates,
  insuranceApplicationWorkflowStates,
  loanApplication: {
    isLoading: false,
    error: null,
    data: loanApplicationWorkflowStates,
  },
  loading: false,
  error: null,
}

export const workflowsReducer = createSlice({
  name: 'workflows',
  initialState: initialState,
  reducers: {
    setCarWorkflows: (state, action) => {
      state.carWorkflowStates = action.payload
      if (state.auctionWorkflowStates.length > 0 && state.dealWorkflowStates.length > 0) {
        state.loaded = true
      }
    },
    setAuctionWorkflows: (state, action) => {
      state.auctionWorkflowStates = action.payload
      if (state.carWorkflowStates.length > 0 && state.dealWorkflowStates.length > 0) {
        state.loaded = true
      }
    },
    setDealWorkflows: (state, action) => {
      state.dealWorkflowStates = action.payload
      if (state.carWorkflowStates.length > 0 && state.auctionWorkflowStates.length > 0) {
        state.loaded = true
      }
    },
  },
  extraReducers: (builder) => {
    getWorkflowsStatesBuilder(builder)
    getLoanApplicationWorkflowStatesBuilder(builder)
  },
})

export const { setCarWorkflows, setAuctionWorkflows, setDealWorkflows } = workflowsReducer.actions

export default workflowsReducer.reducer
