import { updateTradeLicense } from '../settings.actions'

export default (builder) => {
  builder
    .addCase(updateTradeLicense.pending, (state) => {
      state.tradeLicense.update.isLoading = true
    })
    .addCase(updateTradeLicense.fulfilled, (state, action) => {
      state.tradeLicense.update.isLoading = false
      state.tradeLicense.update.error = null
      console.log(action.payload)
    })
    .addCase(updateTradeLicense.rejected, (state, action) => {
      state.tradeLicense.update.isLoading = false
      state.tradeLicense.update.error = action.payload
    })
}
