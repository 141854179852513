import { getCustomerAppraisalCars } from '../customerAppraisals.actions'
import { apiConstants } from '../../../../api/api'

export default (builder) => {
  builder
    .addCase(getCustomerAppraisalCars.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getCustomerAppraisalCars.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = null
      console.log(action.payload?.[apiConstants.hydraMember])
      state.data = action.payload?.[apiConstants.hydraMember]
    })
    .addCase(getCustomerAppraisalCars.rejected, (state, action) => {
      if (action.payload?.code !== 'CANCELED') {
        state.isLoading = false
        state.error = action.payload
      }
    })
}
