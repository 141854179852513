import React from 'react'
import TextNormal from '../Text/TextNormal'
import { colorsConfig } from '../../config/themeConfig'
import { InputAdornment } from '@mui/material'
import { useTranslation } from 'react-i18next'

const UIInputAdornmentText = ({ text }) => {
  const { t } = useTranslation()

  return (
    <InputAdornment position="end">
      <TextNormal
        sx={{
          fontSize: '14px',
          color: colorsConfig.lightGray,
        }}
      >
        {t(text)}
      </TextNormal>
    </InputAdornment>
  )
}

export default UIInputAdornmentText
