import React, { useState } from 'react'
import { Box, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material'
import { colorsConfig } from '../../../../config/themeConfig'
import { updateLoanCalculatorParams } from '../../../../store/features/financeCar'
import TextFieldSmall from '../../../../ui/TextFieldSmall'
import { sumDelimiter } from '../../../../utils/sumDelimiter'
import SelectSmall from '../../../../ui/SelectSmall'

const downPaymentInputType = {
  money: 'money',
  percentage: 'percentage',
}

const FormInputMoneyAndPercentage = ({
  inPercents,
  setInPercents,
  valuePercents,
  valueMoney,
  onChangePercents,
  onChangeMoney,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        mr: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      {inPercents ? (
        <FormControl
          fullWidth
          size={'small'}
          sx={{
            color: colorsConfig.iconGray,
            fontWeight: 400,
            fontSize: '14px',
            border: 'none',
          }}
        >
          <InputLabel
            id="label-down-payment"
            sx={{
              color: colorsConfig.iconGray,
              fontWeight: 400,
              fontSize: '14px',
              '&.Mui-focused': {
                background: 'white',
                color: colorsConfig.mainBlue,
                boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                overflow: 'visible',
              },
            }}
          >
            Down payment
          </InputLabel>
          <Select
            labelId={'label-down-payment'}
            value={valuePercents}
            onChange={(e) => {
              onChangePercents(e.target.value)
            }}
            sx={{
              borderRadius: '4px',
              height: '38px',
              fontSize: '14px',
              border: 'none',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
                boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
              },
            }}
          >
            <MenuItem sx={{ display: 'none' }} selected value={''}></MenuItem>
            <MenuItem value={5}>5 %</MenuItem>
            <MenuItem value={10}>10 %</MenuItem>
            <MenuItem value={15}>15 %</MenuItem>
            <MenuItem value={20}>20 %</MenuItem>
            <MenuItem value={25}>25 %</MenuItem>
            <MenuItem value={30}>30 %</MenuItem>
            <MenuItem value={35}>35 %</MenuItem>
            <MenuItem value={40}>40 %</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <TextFieldSmall
          fullWidth
          label={'Down payment'}
          value={sumDelimiter(valueMoney)}
          onChange={(e) => {
            onChangeMoney(e.target.value.replace(/[^0-9]/g, ''))
          }}
        />
      )}
      <Box
        sx={{
          minWidth: '92px',
          ml: '7px',
        }}
      >
        <SelectSmall
          value={inPercents ? 'in %' : 'in CHF'}
          setValue={(newVal) => setInPercents(newVal === 'in %')}
          id={'down-payment-in'}
          options={['in %', 'in CHF']}
          sx={{
            '& .MuiSelect-select': {
              color: `${colorsConfig.iconGray} !important`,
            },
          }}
        />
      </Box>
    </Box>
  )
}

export default FormInputMoneyAndPercentage
