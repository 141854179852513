import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import { Box, Grid } from '@mui/material'
import FormInput from '../../FormComponents/FormInput'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import FormUIDMaskedInput from '../../FormComponents/FormUIDMaskedInput/FormUIDMaskedInput'
import FormPhoneNumber from '../../FormComponents/FormPhoneNumber/FormPhoneNumber'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import FormUploadFile from '../../FormComponents/FormUploadFile/FormUploadFile'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  editCompanyFormFormValidationSchema,
  getEditCompanyFormInitialState,
} from './editCompanyForm.helpers'
import { getCompany, updateCompany } from '../../../../store/features/company/company.actions'
import { downloadPrivateFile } from '../../../../store/features/files/files.actions'
import ButtonHollow from '../../../../ui/Buttons/ButtonHollow'
import UIPreloader from '../../../../ui/UIPreloader/UIPreloader'

const EditCompanyForm = () => {
  const isLoading = useSelector((state) => state.company.isLoading)
  const company = useSelector((state) => state.company.data)
  const isSaving = useSelector((state) => state.company.update.isLoading)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const handleSubmit = async (values) => {
    const data = {
      ...values,
      taxRegistrationNumber: `${values?.tradeLicenseNumber} MWST`,
    }
    if (!data?.tradeLicense) delete data?.tradeLicense
    const res = await dispatch(updateCompany({ companyId: params?.id, data }))
    if (updateCompany.fulfilled.match(res)) {
      toast.success('Company has been updated successfully')
      navigate(-1)
    }
  }

  useEffect(() => {
    dispatch(getCompany({ companyId: params?.id }))
  }, [])

  return (
    <Box sx={{ position: 'relative' }}>
      {isSaving && <UIPreloader isExtended text="Saving data..." />}
      <Formik
        initialValues={getEditCompanyFormInitialState(company)}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={editCompanyFormFormValidationSchema}
      >
        {(props) => (
          <Form>
            <Grid container spacing="30px">
              <Grid item xs={4}>
                <FormInput required label="Company name" name="name" id="name" />
              </Grid>
              <Grid item xs={4}>
                <FormInput required label="E-mail" name="invoiceEmail" id="email" />
              </Grid>
              <Grid item xs={4}>
                <FormPhoneNumber label="form_input_label_landline_number" name="landlineNumber" />
              </Grid>
              <Grid item xs={4}>
                <FormUIDMaskedInput
                  required
                  label="form_input_label_uid"
                  name="tradeLicenseNumber"
                  id="tradeLicenseNumber"
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldSmallEdible
                  fullWidth
                  required
                  label="form_input_label_vat_nr"
                  inputProps={{
                    inputMode: 'numeric',
                    readOnly: true,
                  }}
                  value={`${props.values?.tradeLicenseNumber} MWST`}
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={6}>
                <FormUploadFile
                  file={company?.tradeLicense}
                  handleDownload={() =>
                    dispatch(downloadPrivateFile({ fileId: company?.tradeLicense }))
                  }
                  downloadText="Download current Registry copy"
                  label="Update Registry copy"
                  name="tradeLicense"
                />
              </Grid>
              <Grid xs={12} item display="flex" justifyContent="flex-end" gap="10px">
                <ButtonHollow onClick={() => navigate(-1)}>Cancel</ButtonHollow>
                <ButtonLG
                  type="submit"
                  disabled={isLoading || (props.touched && !props.isValid && props.submitCount > 0)}
                >
                  Update company
                </ButtonLG>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default EditCompanyForm
