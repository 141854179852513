import React from 'react'
import RBox from '../RBox'

const BoxShadowedGradient = ({ sx, children, className, ...props }) => {
  return (
    <RBox
      className={className}
      sx={{
        overflow: 'hidden',
        borderRadius: '6px',
        background: 'white',
        px: '15px',
        py: '15px',
        boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
        position: 'relative',
        '&::after': {
          content: '" "',
          pointerEvents: 'none',
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '50px',
          top: 0,
          left: 0,
        },
        '&.success': {
          '&::after': {
            background:
              'linear-gradient(180deg, rgba(77, 237, 109, 0.15) 0%, rgba(77, 237, 109, 0.00) 100%)',
          },
        },
        '&.attention': {
          '&::after': {
            background:
              'linear-gradient(180deg, rgba(245, 189, 79, 0.25) 0%, rgba(245, 189, 79, 0.00) 100%)',
          },
        },
        '&.error': {
          '&::after': {
            background:
              'linear-gradient(180deg, rgba(172, 0, 0, 0.15) 0%, rgba(172, 0, 0, 0.00) 100%)',
          },
        },
        '&.faded': {
          opacity: 0.6,
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </RBox>
  )
}

export default BoxShadowedGradient
