import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import { Box, Grid } from '@mui/material'
import FormInput from '../../../FormComponents/FormInput'
import FormPhoneNumber from '../../../FormComponents/FormPhoneNumber/FormPhoneNumber'
import ButtonLG from '../../../../../ui/Buttons/ButtonLG'
import FormSelect from '../../../FormComponents/FormSelect'
import ButtonHollow from '../../../../../ui/Buttons/ButtonHollow'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import FormInputAutocomplete from '../../../FormComponents/FormInputAutocomplete/FormInputAutocomplete'
import { localeOptions } from '../../../../../utils/constants/options.constants'
import { addManagerFormValidationSchema } from './addManagerForm.helpers'
import { getAllCompanies } from '../../../../../store/features/companiesSlice/companies.actions'
import UIPreloader from '../../../../../ui/UIPreloader/UIPreloader'
import { createManager } from '../../../../../store/features/manager/manager.actions'
import { toast } from 'react-toastify'

const AddManagerForm = () => {
  const isLoading = useSelector((state) => state.manager.create.isLoading)
  const isLoadingCompanies = useSelector((state) => state.companies.allCompanies.isLoading)
  const companies = useSelector((state) => state.companies.allCompanies.data)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const preFilledCompany = location?.state?.company

  const companiesOptions = companies.map((item) => ({
    label: item?.name,
    value: item?.['@id'],
  }))

  const handleSubmit = async (values) => {
    const { phoneNumber, firstName, surname, company, login, email, locale } = values
    const data = {
      phoneNumber,
      firstName,
      surname,
      company,
      user: {
        login,
        email,
        locale,
      },
    }
    const res = await dispatch(createManager({ data }))
    if (createManager.fulfilled.match(res)) {
      toast.success('Manager has been successfully created')
      navigate(-1)
    }
  }

  useEffect(() => {
    dispatch(getAllCompanies())
  }, [])

  return (
    <Box sx={{ position: 'relative' }}>
      {isLoading && <UIPreloader text="Saving data..." isExtended />}
      <Formik
        initialValues={{
          phoneNumber: '',
          firstName: '',
          surname: '',
          company: companies?.length > 0 && preFilledCompany ? preFilledCompany : '',
          login: '',
          email: '',
          locale: 'en',
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={addManagerFormValidationSchema}
      >
        {(props) => (
          <Form noValidate>
            <Grid container spacing="30px">
              <Grid item lg={3}>
                <FormInputAutocomplete
                  required
                  options={companiesOptions}
                  name="company"
                  label="Company"
                  disabled={isLoading || isLoadingCompanies}
                />
              </Grid>
              <Grid item lg={3}>
                <FormInput required label="Username" name="login" id="login" />
              </Grid>
              <Grid item lg={3}>
                <FormInput required label="E-mail" name="email" id="email" />
              </Grid>
              <Grid item lg={3}>
                <FormPhoneNumber
                  required
                  label="form_input_label_landline_number"
                  name="phoneNumber"
                />
              </Grid>
              <Grid item lg={3}>
                <FormInput required label="First name" name="firstName" id="firstName" />
              </Grid>
              <Grid item lg={3}>
                <FormInput required label="Last name" name="surname" id="surname" />
              </Grid>
              <Grid item lg={3}>
                <FormSelect label="Acount language" name="locale" options={localeOptions} />
              </Grid>
              <Grid xs={12} item display="flex" justifyContent="flex-end" gap="10px">
                <ButtonHollow onClick={() => navigate(-1)}>Cancel</ButtonHollow>
                <ButtonLG
                  type="submit"
                  disabled={isLoading || (props.touched && !props.isValid && props.submitCount > 0)}
                >
                  Save manager
                </ButtonLG>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default AddManagerForm
