import React from 'react'
import { colorsConfig } from '../../config/themeConfig'
import { Box } from '@mui/material'

const UIHeaderSectionWrapper = ({ sx, children, ...props }) => {
  return (
    <Box
      sx={{
        background: colorsConfig.bgLightBlue,
        position: 'relative',
        ml: {
          xs: '-16px',
          md: '-23px',
        },
        mr: {
          xs: '-16px',
          md: '-98px',
        },
        pl: {
          xs: '16px',
          md: '23px',
        },
        pr: {
          xs: '16px',
          md: '98px',
        },
        pb: {
          xs: '24px',
          md: '30px',
        },
        pt: {
          xs: '24px',
          md: '23px',
        },
        mt: {
          xs: 0,
          md: '-30px',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

export default UIHeaderSectionWrapper
