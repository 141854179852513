import React, { useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import UIAccordion from '../../../ui/UIAccordion/UIAccordion'
import UICounter from '../../../ui/UICounter/UICounter'
import ControlPointBoolean from './ControlPointBoolean'

const ControlPointSubBlock = ({ data, readonly }) => {
  const { name, controlPoints } = data

  // const [showHint, setShowHint] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [counterChecked, setCounterChecked] = useState(0)
  const [counterUnchecked, setCounterUnchecked] = useState(0)
  const [counterDefault, setCounterDefault] = useState(0)

  useEffect(() => {
    setCounterChecked(controlPoints.filter((item) => item?.carControlPoint?.value === true).length)
    setCounterUnchecked(
      controlPoints.filter((item) => item?.carControlPoint?.value === false).length,
    )
    setCounterDefault(controlPoints.filter((item) => item?.carControlPoint?.value === null).length)
  }, [data])

  return (
    <UIAccordion
      name={name}
      expanded={expanded}
      setExpanded={setExpanded}
      summaryAdornment={
        <Stack direction="row" gap="3px">
          <UICounter text={counterChecked} variant="success" />
          <UICounter text={counterUnchecked} variant="danger" />
          <UICounter text={counterDefault} variant="disabled" />
        </Stack>
      }
    >
      <Stack gap="14px">
        {controlPoints.map((item) => {
          return <ControlPointBoolean key={item?.['@id']} data={item} readonly={readonly} />
        })}
      </Stack>
    </UIAccordion>
  )
}

export default ControlPointSubBlock
