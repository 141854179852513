import React from 'react'
import { Button } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const ButtonMedium = ({ children, sx, type, className, ...props }) => {
  return (
    <Button
      className={[type, className].join(' ')}
      {...props}
      sx={{
        textTransform: 'none',
        height: '48px',
        borderRadius: '4px',
        px: '21px',
        color: 'white',
        background: colorsConfig.mainBlue,
        fontSize: '16px',
        fontWeight: 700,
        whiteSpace: 'nowrap',
        transition: '0.25s all ease-in-out',
        '&:hover': {
          background: colorsConfig.buttonHover,
          transition: '0.25s all ease-in-out',
        },
        '&.danger': {
          background: colorsConfig.darkRed,
          '&:hover': {
            background: colorsConfig.darkRedHover,
          },
        },
        '&.attention': {
          color: colorsConfig.mainBlack,
          background: colorsConfig.buttonYellow,
          '&:hover': {
            opacity: '.7',
          },
        },
        '&.success': {
          color: colorsConfig.mainBlack,
          background: colorsConfig.mainGreen,
          '&:hover': {
            opacity: '.7',
          },
        },
        '&.inactive': {
          color: colorsConfig.iconGray,
          background: colorsConfig.buttonDisabled,
          '&.success:hover': {
            color: colorsConfig.mainBlack,
            background: colorsConfig.mainGreen,
          },
          '&.danger:hover': {
            color: 'white',
            background: colorsConfig.darkRedHover,
          },
          '&.attention:hover': {
            color: colorsConfig.mainBlack,
            background: colorsConfig.buttonYellow,
          },
        },
        '&.blocked': {
          pointerEvents: 'none',
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  )
}

export default ButtonMedium
