import React, { useEffect, useState } from 'react'
import BreadcrumbsComponent from '../../../../components/BreadcrumbsComponent'
import IconProduct from '../../../../assests/img/icons/icon-product.svg'
import TextH1 from '../../../../ui/Text/TextH1'
import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { adminRequest } from '../../../../utils/request'
import { API_FINANCE_ADMIN_ENDPOINTS } from '../../../../config/default'
import { toast } from 'react-toastify'
import { setCurrentPageTitle } from '../../../../store/features/authSlice'
import { colorsConfig } from '../../../../config/themeConfig'

const AddProduct = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [productName, setProductName] = useState('')
  const [productType, setProductType] = useState('')
  const [productTypes, setProductTypes] = useState([])

  const hasErrors = !productType || !productName

  const getProductTypes = () => {
    adminRequest.get(API_FINANCE_ADMIN_ENDPOINTS.productTypes).then((response) => {
      setProductTypes(response.data['hydra:member'])
    })
  }

  const handleAddProduct = () => {
    const formData = {
      title: productName,
      type: productTypes.filter((item) => item.title === productType)[0]['@id'],
    }
    adminRequest
      .post(API_FINANCE_ADMIN_ENDPOINTS.products, formData)
      .then((response) => {
        toast.success('Added product successfully', { autoClose: 1000 })
        navigate('/admin/financing/products')
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data['hydra:description']) {
          toast.error(error.response.data['hydra:description'])
        } else toast.error('Unexpected error')
      })
  }

  useEffect(() => {
    getProductTypes()
    dispatch(setCurrentPageTitle('Add a new product'))
  }, [])

  return (
    <Box>
      <BreadcrumbsComponent
        currentPage={{ name: `Add a new product` }}
        parentPages={[
          {
            name: 'Products',
            icon: (
              <img src={IconProduct} style={{ filter: 'brightness(73%)', width: '13px' }} alt="" />
            ),
            link: '/admin/financing/products',
          },
        ]}
      />
      <TextH1
        sx={{
          fontSize: '36px',
          mb: '30px',
          mt: '10px',
        }}
      >
        Add a new product
      </TextH1>
      <Grid container spacing={'20px'}>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Product name'}
            value={productName}
            onChange={(e) => {
              setProductName(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            required
            fullWidth
            sx={{
              color: colorsConfig.iconGray,
              fontWeight: 400,
              fontSize: '14px',
              border: 'none',
            }}
            size={'small'}
          >
            <InputLabel
              id="product-type"
              sx={{
                color: colorsConfig.iconGray,
                fontWeight: 400,
                fontSize: '14px',
                '&.Mui-focused': {
                  background: 'white',
                  color: colorsConfig.mainBlue,
                  boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                  overflow: 'visible',
                },
              }}
            >
              Product type
            </InputLabel>
            <Select
              labelId="product-type"
              id="product-type-field"
              label="Product type"
              value={productType}
              onChange={(e) => {
                setProductType(e.target.value)
              }}
              sx={{
                borderRadius: '4px',
                height: '38px',
                fontSize: '14px',
                border: 'none',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                  boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                },
              }}
            >
              <MenuItem value={''}>Not Chosen</MenuItem>
              {productTypes.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.title}>
                    {item.title}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: '40px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <ButtonLG
          sx={{
            minWidth: '187px',
          }}
          disabled={hasErrors}
          onClick={() => {
            handleAddProduct()
          }}
        >
          Save
        </ButtonLG>
      </Box>
    </Box>
  )
}

export default AddProduct
