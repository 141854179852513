import { getCarDetailsFromVIN } from '../car.actions'

export default (builder) => {
  builder
    .addCase(getCarDetailsFromVIN.pending, (state) => {
      state.vinProvider.isLoading = true
    })
    .addCase(getCarDetailsFromVIN.fulfilled, (state, action) => {
      state.vinProvider.isLoading = false
      state.vinProvider.error = null
      state.vinProvider.data = action.payload
    })
    .addCase(getCarDetailsFromVIN.rejected, (state, action) => {
      state.vinProvider.isLoading = false
      state.vinProvider.error = action.payload
    })
}
