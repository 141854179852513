import { updateRviPolicy } from '../rviPolicy.actions'

export default (builder) => {
  builder
    .addCase(updateRviPolicy.pending, (state) => {
      state.updatePolicy.isLoading = true
    })
    .addCase(updateRviPolicy.fulfilled, (state, action) => {
      state.updatePolicy.isLoading = false
      state.updatePolicy.error = null
    })
    .addCase(updateRviPolicy.rejected, (state, action) => {
      state.updatePolicy.isLoading = false
      state.updatePolicy.error = action.payload
    })
}
