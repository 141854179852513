import React from 'react'
import { Box, Container, useMediaQuery } from '@mui/material'
import TextH1 from '../../ui/Text/TextH1'
import TextNormal from '../../ui/Text/TextNormal'
import Image1 from '../../assests/img/image-green-page-1.png'
import Image2 from '../../assests/img/image-green-page-2.png'
import ImageApples from '../../assests/img/image-green-page-apples.png'
import ImageGreenCar from '../../assests/img/image-green-car.png'
import { colorsConfig } from '../../config/themeConfig'

const GreenPageContent = () => {
  const mdOnly = useMediaQuery('(min-width: 1240px) and (max-width: 1500px)')
  const extraLarge = useMediaQuery('(min-width: 1500px)')

  return (
    <Box
      className={extraLarge ? 'extra-large' : ''}
      sx={{
        pt: '166px',
        pb: '60px',
        backgroundImage: `url(${Image1})`,
        backgroundSize: '100% auto',
        backgroundPosition: '0 30px',
        backgroundRepeat: 'no-repeat',
        '&.extra-large': {
          backgroundPosition: 'right top',
        },
      }}
    >
      <Container maxWidth={false} sx={{ maxWidth: '1240px' }}>
        <TextH1
          sx={{
            mb: '10px',
          }}
        >
          Drive the Change
        </TextH1>
        <TextNormal
          className={extraLarge ? 'extra-large' : ''}
          sx={{
            maxWidth: '420px',
            mb: '190px',
            '&.extra-large': {
              mb: '250px',
            },
          }}
        >
          By choosing an eco-friendly vehicle, you contribute to a cleaner and healthier planet. Our
          platform makes it easy for you to explore and purchase green alternatives, ensuring you're
          not just buying a car. You're investing in the future.
        </TextNormal>
        <Box
          sx={{
            boxShadow: '0px 0px 24px 0px rgba(0, 0, 0, 0.10)',
            borderRadius: '10px',
            height: '276px',
            maxWidth: '1000px',
            ml: 'auto',
            mr: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${ImageApples})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right center',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '-9px',
              left: '-42px',
            }}
          >
            <img src={Image2} alt="" />
          </Box>
          <Box>
            <TextH1
              sx={{
                mb: '10px',
              }}
            >
              Encouraging Eco-Friendlyness
            </TextH1>
            <TextNormal
              sx={{
                maxWidth: '660px',
              }}
            >
              Beyond just vehicle selection, we actively promote eco-friendly practices among our
              users. From sharing tips on efficient driving to maintaining vehicles for optimal fuel
              economy.
            </TextNormal>
          </Box>
        </Box>
      </Container>
      <Box
        className={mdOnly ? 'md-only' : ''}
        sx={{
          mt: '60px',
          height: '416px',
          display: 'flex',
          alignItems: 'center',
          background: colorsConfig.bgGreen,
          position: 'relative',
          '&.md-only': {
            height: '350px',
          },
        }}
      >
        <Box
          className={mdOnly ? 'md-only' : ''}
          sx={{
            position: 'absolute',
            top: '-23px',
            right: 0,
            '&.md-only': {
              width: '700px',
            },
          }}
        >
          <img
            src={ImageGreenCar}
            alt=""
            style={{
              width: '100%',
            }}
          />
        </Box>
        <Container maxWidth={false} sx={{ maxWidth: '1240px' }}>
          <TextH1 sx={{ mb: '10px' }}>Curating a Green Fleet</TextH1>
          <TextNormal
            sx={{
              maxWidth: '450px',
            }}
          >
            We take pride in curating a diverse selection of eco-friendly vehicles, including hybrid
            and electric cars. By connecting environmentally conscious buyers with these green
            options.
          </TextNormal>
        </Container>
      </Box>
    </Box>
  )
}

export default GreenPageContent
