import React from 'react'
import { SvgIcon } from '@mui/material'

const UIIconEdit = ({ color, sx, ...props }) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20" sx={{ fontSize: '28px' }}>
      <path
        d="M13.655 8.90758C13.2867 8.90758 12.989 9.20603 12.989 9.57359V14.9021C12.989 15.269 12.6905 15.5681 12.323 15.5681H2.99816C2.63047 15.5681 2.33215 15.269 2.33215 14.9021V5.57727C2.33215 5.21029 2.63047 4.91125 2.99816 4.91125H8.32664C8.69496 4.91125 8.99266 4.61284 8.99266 4.24524C8.99266 3.87755 8.69496 3.5791 8.32664 3.5791H2.99816C1.89652 3.5791 1 4.47562 1 5.57727V14.9021C1 16.0037 1.89652 16.9002 2.99816 16.9002H12.323C13.4246 16.9002 14.3211 16.0037 14.3211 14.9021V9.57359C14.3211 9.20531 14.0233 8.90758 13.655 8.90758Z"
        fill="currentColor"
      />
      <path
        d="M7.24437 8.38536C7.19808 8.43187 7.16637 8.49088 7.15314 8.55515L6.68226 10.91042C6.66031 11.0196 6.69494 11.1321 6.77349 11.2114C6.80449 11.2423 6.84128 11.2668 6.88174 11.2835C6.92221 11.3001 6.96557 11.3087 7.00934 11.3086C7.03057 11.3086 7.05264 11.3066 7.07459 11.302L9.42911 10.83115C9.49437 10.81772 9.55363 10.7865 9.59961 10.73979L14.8695 5.46995L12.5149 3.11555L7.24437 8.38536ZM16.4972 1.487076C15.8479 0.837641 14.7915 0.837641 14.1427 1.487076L13.2209 2.40882L15.5754 4.76335L16.4972 3.84148C16.8116 3.52779 16.9848 3.10946 16.9848 2.66456C16.9848 2.21965 16.8116 1.80135 16.4972 1.487076Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default UIIconEdit
