import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IUBENDA_BASE_URL } from '../../api/api'

const policiesLinks = {
  privacyPolicy: {
    de: '/privacy-policy/96872507',
    en: '/privacy-policy/86965446',
    fr: '/privacy-policy/30744296',
    it: '/privacy-policy/88779045',
  },
  cookiePolicy: {
    de: '/privacy-policy/96872507/cookie-policy',
    en: '/privacy-policy/86965446/cookie-policy',
    fr: '/privacy-policy/30744296/cookie-policy',
    it: '/privacy-policy/88779045/cookie-policy',
  },
}

const CookiesAndPrivacyPolicy = () => {
  const { t, i18n } = useTranslation()
  const [href, setHref] = useState(policiesLinks.cookiePolicy[i18n.language])
  useEffect(() => {
    const loadIubendaScript = () => {
      const script = document.createElement('script')
      script.src = 'https://cdn.iubenda.com/iubenda.js'
      script.async = true
      document.body.appendChild(script)
    }
    console.log(i18n.language)
    loadIubendaScript()
    setHref(policiesLinks.cookiePolicy[i18n.language])
    return () => {
      const existingScript = document.querySelector(
        'script[src="https://cdn.iubenda.com/iubenda.js"]',
      )
      if (existingScript) document.body.removeChild(existingScript)
    }
  }, [i18n.language])

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: 0,
        height: 0,
        overflow: 'hidden',
        pointerEvents: 'none',
      }}
    >
      <a
        id="cookie-policy-link-en"
        href={`${IUBENDA_BASE_URL}${policiesLinks.cookiePolicy.en}`}
        className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
      ></a>
      <a
        id="cookie-policy-link-de"
        href={`${IUBENDA_BASE_URL}${policiesLinks.cookiePolicy.de}`}
        className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
      ></a>
      <a
        id="cookie-policy-link-fr"
        href={`${IUBENDA_BASE_URL}${policiesLinks.cookiePolicy.fr}`}
        className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
      ></a>
      <a
        id="cookie-policy-link-it"
        href={`${IUBENDA_BASE_URL}${policiesLinks.cookiePolicy.it}`}
        className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
      ></a>

      <a
        id="privacy-policy-link-en"
        href={`${IUBENDA_BASE_URL}${policiesLinks.privacyPolicy.en}`}
        className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
      ></a>
      <a
        id="privacy-policy-link-de"
        href={`${IUBENDA_BASE_URL}${policiesLinks.privacyPolicy.de}`}
        className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
      ></a>
      <a
        id="privacy-policy-link-fr"
        href={`${IUBENDA_BASE_URL}${policiesLinks.privacyPolicy.fr}`}
        className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
      ></a>
      <a
        id="privacy-policy-link-it"
        href={`${IUBENDA_BASE_URL}${policiesLinks.privacyPolicy.it}`}
        className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
      ></a>
    </div>
  )
}

export default CookiesAndPrivacyPolicy
