import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, useMediaQuery } from '@mui/material'
import TextBoldNormal from '../Text/TextBoldNormal'
import IconTriangle from '../../assests/img/icons/icon-triangle.svg'

const UIAccordion = ({ expanded, setExpanded, name, children, summaryAdornment }) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <Accordion
      className={xsOnly ? 'xs-only' : ''}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      sx={{
        border: 'none',
        boxShadow: 'none',
        '& .MuiAccordionSummary-gutter': {
          px: 0,
        },
        '&.MuiAccordion-root': {
          '&::before': {
            display: 'none',
          },
        },
        '&.xs-only': {
          '& .MuiAccordionSummary-root': {
            px: 0,
          },
          '& .MuiAccordionSummary-contentGutters': {
            mb: '5px',
          },
          '& .MuiAccordionDetails-root': {
            px: 0,
          },
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <Box
            sx={{
              transformOrigin: '50% 50%',
              transform: 'rotate(180deg)',
            }}
          >
            <img src={IconTriangle} alt="" />
          </Box>
        }
      >
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            flexWrap: 'wrap',
            pr: '24px',
            '&.xs-only': {
              alignItems: 'flex-start',
            },
          }}
        >
          <TextBoldNormal
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              width: '33%',
              flexShrink: 0,
              '&.xs-only': {
                width: '75%',
              },
            }}
          >
            {name}
          </TextBoldNormal>
          {summaryAdornment || null}
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

export default UIAccordion
