import { createSlice } from '@reduxjs/toolkit'

const currentTime = new Date()
const localReferences = JSON.parse(window.localStorage.getItem('appraisalReferences'))
const foreshortenings =
  localReferences && localReferences.foreshortenings ? localReferences.foreshortenings : []
const controlPoints =
  localReferences && localReferences.controlPoints ? localReferences.controlPoints : []
const carParts = localReferences && localReferences.carParts ? localReferences.carParts : []
const pilotLamps = localReferences && localReferences.pilotLamps ? localReferences.pilotLamps : []
const updatedAt = localReferences && localReferences.updatedAt ? localReferences.updatedAt : null
let loaded = Boolean(
  localReferences &&
    localReferences.foreshortenings &&
    localReferences.carParts &&
    localReferences.pilotLamps &&
    localReferences.controlPoints,
)
if (localReferences && localReferences.updatedAt) {
  if (currentTime - new Date(localReferences.updatedAt) > 6000) {
    loaded = false
  }
}

const initialState = {
  loaded: loaded,
  updatedAt: updatedAt,
  foreshortenings: foreshortenings,
  controlPoints: controlPoints,
  carParts: carParts,
  pilotLamps: pilotLamps,
}

export const appraisalReferencesSlice = createSlice({
  name: 'references',
  initialState: initialState,
  reducers: {
    setReferences: (state, action) => {
      const [foreshortenings, carParts, pilotLamps, controlPoints] = action.payload
      state.foreshortenings = foreshortenings
      state.carParts = carParts
      state.pilotLamps = pilotLamps
      state.updatedAt = new Date()
      const updatedData = {
        updatedAt: new Date(),
        foreshortenings: foreshortenings,
        carParts: carParts,
        pilotLamps: pilotLamps,
      }
      window.localStorage.setItem('appraisalReferences', JSON.stringify(updatedData))
      state.loaded = true
    },
    setReferenceControlPoints: (state, action) => {
      state.controlPoints = action.payload
    },
  },
})

export const { setReferences, setReferenceControlPoints } = appraisalReferencesSlice.actions

export default appraisalReferencesSlice.reducer
