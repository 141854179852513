import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import { Box, Grid } from '@mui/material'
import FormInput from '../../../FormComponents/FormInput'
import FormPhoneNumber from '../../../FormComponents/FormPhoneNumber/FormPhoneNumber'
import ButtonLG from '../../../../../ui/Buttons/ButtonLG'
import FormSelect from '../../../FormComponents/FormSelect'
import ButtonHollow from '../../../../../ui/Buttons/ButtonHollow'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import FormInputAutocomplete from '../../../FormComponents/FormInputAutocomplete/FormInputAutocomplete'
import { localeOptions } from '../../../../../utils/constants/options.constants'
import {
  editManagerFormValidationSchema,
  getEditManagerFormInitialState,
} from './editManagerForm.helpers'
import { getAllCompanies } from '../../../../../store/features/companiesSlice/companies.actions'
import UIPreloader from '../../../../../ui/UIPreloader/UIPreloader'
import {
  createManager,
  getManager,
  updateManager,
} from '../../../../../store/features/manager/manager.actions'
import { toast } from 'react-toastify'

const EditManagerForm = () => {
  const isLoading = useSelector((state) => state.manager.isLoading)
  const manager = useSelector((state) => state.manager.data)
  const isSaving = useSelector((state) => state.manager.update.isLoading)
  const isLoadingCompanies = useSelector((state) => state.companies.allCompanies.isLoading)
  const companies = useSelector((state) => state.companies.allCompanies.data)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const companiesOptions = companies.map((item) => ({
    label: item?.name,
    value: item?.['@id'],
  }))

  const handleSubmit = async (values) => {
    const { phoneNumber, firstName, surname, company, email, locale } = values
    const data = {
      phoneNumber,
      firstName,
      surname,
      company,
    }
    const res = await dispatch(updateManager({ managerId: params?.id, data }))
    if (updateManager.fulfilled.match(res)) {
      toast.success('Manager has been successfully updated')
      navigate(-1)
    }
  }

  useEffect(() => {
    dispatch(getManager({ managerId: params?.id }))
    dispatch(getAllCompanies())
  }, [])

  return (
    <Box sx={{ position: 'relative' }}>
      {isLoading && <UIPreloader text="Loading data..." isExtended />}
      {isSaving && <UIPreloader text="Saving data..." isExtended />}
      <Formik
        initialValues={getEditManagerFormInitialState(manager)}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={editManagerFormValidationSchema}
      >
        {(props) => (
          <Form noValidate>
            <Grid container spacing="30px">
              <Grid item lg={3}>
                <FormInputAutocomplete
                  required
                  options={companiesOptions}
                  name="company"
                  label="Company"
                  disabled={isLoading || isLoadingCompanies}
                />
              </Grid>
              <Grid item lg={3}>
                <FormInput required label="Username" name="login" id="login" disabled />
              </Grid>
              <Grid item lg={3}>
                <FormInput disabled required label="E-mail" name="email" id="email" />
              </Grid>
              <Grid item lg={3}>
                <FormPhoneNumber
                  required
                  label="form_input_label_landline_number"
                  name="phoneNumber"
                />
              </Grid>
              <Grid item lg={3}>
                <FormInput required label="First name" name="firstName" id="firstName" />
              </Grid>
              <Grid item lg={3}>
                <FormInput required label="Last name" name="surname" id="surname" />
              </Grid>
              <Grid item lg={3}>
                <FormSelect
                  disabled
                  label="Acount language"
                  name="locale"
                  options={localeOptions}
                />
              </Grid>
              <Grid xs={12} item display="flex" justifyContent="flex-end" gap="10px">
                <ButtonHollow onClick={() => navigate(-1)}>Cancel</ButtonHollow>
                <ButtonLG
                  type="submit"
                  disabled={isLoading || (props.touched && !props.isValid && props.submitCount > 0)}
                >
                  Update manager
                </ButtonLG>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default EditManagerForm
