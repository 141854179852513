import { saveLoanApplicationCustomer } from '../financing.actions'
import { loanApplicationWorkflowStates } from '../../../../utils/constants/workflows.constants'

export default (builder) => {
  builder
    .addCase(saveLoanApplicationCustomer.pending, (state) => {
      state.currentLoanApplication.saveCustomerData.isLoading = true
    })
    .addCase(saveLoanApplicationCustomer.fulfilled, (state, action) => {
      state.currentLoanApplication.saveCustomerData.isLoading = false
      state.currentLoanApplication.saveCustomerData.error = null
      state.currentLoanApplication.data = {
        ...state.currentLoanApplication.data,
        currentWorkflowState: loanApplicationWorkflowStates.application_preparation,
      }
    })
    .addCase(saveLoanApplicationCustomer.rejected, (state, action) => {
      state.currentLoanApplication.saveCustomerData.isLoading = false
      state.currentLoanApplication.saveCustomerData.error = action.payload
    })
}
