import React, { useEffect, useRef, useState } from 'react'
import UITableActionButton from './UITableActionButton'
import { Box, CircularProgress } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import UICloseButton from '../UIIconButtons/UICloseButton'
import UIButtonActionMenu from '../UIButtons/UIButtonActionMenu'

const UITableActionsList = ({ actions, isLoading }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const actionList = useRef()

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMenuOpen(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  useEffect(() => {
    if (isLoading) setMenuOpen(false)
  }, [isLoading])

  useOutsideAlerter(actionList)

  return (
    <Box sx={{ position: 'relative' }}>
      {isLoading ? (
        <CircularProgress sx={{ color: colorsConfig.mainBlue }} />
      ) : (
        <>
          <UITableActionButton disabled={actions?.length === 0} onClick={() => setMenuOpen(true)} />
          {menuOpen ? (
            <Box
              sx={{
                position: 'absolute',
                top: '8px',
                right: 'calc(100% - 33px)',
                background: colorsConfig.bgLightBlue,
                borderRadius: '8px',
                minWidth: '200px',
                p: '5px',
                zIndex: 10,
                pt: '30px',
                boxShadow: '0 0 12px 0 rgba(0,0,0,.10)',
              }}
              ref={actionList}
            >
              <UICloseButton
                sx={{ position: 'absolute', top: 0, right: 0 }}
                onClick={() => setMenuOpen(false)}
              />
              {actions.map((item, index) => {
                return (
                  <UIButtonActionMenu
                    key={item?.name ?? index}
                    text={item.name}
                    onClick={() => {
                      item.handleAction()
                      setMenuOpen(false)
                    }}
                  />
                )
              })}
            </Box>
          ) : null}
        </>
      )}
    </Box>
  )
}

export default UITableActionsList
