import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { patchRequest, privateRequest } from '../../../../utils/request'
import { API_ENDPOINTS } from '../../../../config/default'
import { toast } from 'react-toastify'
import { setCurrentPageTitle } from '../../../../store/features/authSlice'
import BreadcrumbsComponent from '../../../../components/BreadcrumbsComponent'
import { Delete, HomeRounded } from '@mui/icons-material'
import { colorsConfig } from '../../../../config/themeConfig'
import TextH1 from '../../../../ui/Text/TextH1'
import { Box, Grid } from '@mui/material'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import ButtonPlain from '../../../../ui/Buttons/ButtonPlain'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import ModalWrap from '../../../../components/Modals/ModalWrap'
import ButtonHollow from '../../../../ui/Buttons/ButtonHollow'
import ButtonDanger from '../../../../ui/Buttons/ButtonDanger'

const AdminPanelInsuranceCompaniesEdit = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const [name, setName] = useState('')
  const [savedName, setSavedName] = useState('')
  const [systemName, setSystemName] = useState('')
  const [email, setEmail] = useState('')
  const [confirmDeletionModal, setConfirmDeletionModal] = useState(false)
  const [disableDeleteButton, setDisableDeleteButton] = useState(false)

  const hasErrors = !name || !systemName || !email

  const handleEdit = () => {
    const formData = {
      name,
      sysName: systemName,
      email,
    }
    privateRequest
      .put(`${API_ENDPOINTS.insurance.companies}/${params.id}`, formData)
      .then(() => {
        toast.success('Updated company successfully', { autoClose: 1000 })
        navigate('/admin/insurance/companies')
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data['hydra:description']) {
          toast.error(error.response.data['hydra:description'])
        } else toast.error('Unexpected error')
      })
  }

  const handleDelete = () => {
    setDisableDeleteButton(true)
    privateRequest
      .delete(`${API_ENDPOINTS.insurance.companies}/${params.id}`)
      .then((response) => {
        toast.success('Company has been deleted', { autoClose: 1000 })
        navigate('/admin/insurance/companies')
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data['hydra:description']) {
          toast.error(error.response.data['hydra:description'])
        } else toast.error('Unexpected error')
      })
      .finally(() => {
        setDisableDeleteButton(false)
      })
  }

  const getData = () => {
    privateRequest.get(`${API_ENDPOINTS.insurance.companies}/${params.id}`).then((response) => {
      setName(response.data.name)
      setSavedName(response.data.name)
      setSystemName(response.data?.sysName)
      setEmail(response.data?.email)
    })
  }

  useEffect(() => {
    getData()
    dispatch(setCurrentPageTitle('Edit company'))
  }, [])

  return (
    <Box>
      <BreadcrumbsComponent
        currentPage={{ name: savedName ?? `Edit company` }}
        parentPages={[
          {
            name: 'Insurance companies',
            icon: <HomeRounded sx={{ fill: colorsConfig.lightGray }} />,
            link: '/admin/insurance/companies',
          },
        ]}
      />
      <TextH1
        sx={{
          fontSize: '36px',
          mb: '30px',
          mt: '10px',
        }}
      >
        Edit company "{savedName}"
      </TextH1>
      <Grid container spacing={'20px'}>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Company name'}
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'System name'}
            value={systemName}
            onChange={(e) => {
              setSystemName(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Email'}
            value={email || ''}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: '40px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ButtonPlain
          sx={{
            color: colorsConfig.mainRed,
            '&:hover': {
              color: colorsConfig.darkRed,
            },
          }}
          onClick={() => {
            setConfirmDeletionModal(true)
          }}
        >
          <Delete
            sx={{
              fill: colorsConfig.mainRed,
              mr: '4px',
            }}
          />
          Delete
        </ButtonPlain>
        <ButtonLG
          sx={{
            minWidth: '187px',
          }}
          disabled={hasErrors}
          onClick={() => {
            handleEdit()
          }}
        >
          Save
        </ButtonLG>
      </Box>
      <ModalWrap open={confirmDeletionModal} setOpen={setConfirmDeletionModal}>
        <TextH1 sx={{ mb: '30px', fontSize: '32px' }}>
          Are you sure you want to delete company "{savedName}"?
        </TextH1>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <ButtonHollow
            sx={{
              flex: 1,
            }}
            onClick={() => {
              setConfirmDeletionModal(false)
            }}
          >
            Cancel
          </ButtonHollow>
          <ButtonDanger
            sx={{
              flex: 1,
              '&.Mui-disabled': {
                border: 'none',
                background: colorsConfig.lightGray,
                color: 'white',
              },
            }}
            disabled={disableDeleteButton}
            onClick={() => {
              handleDelete()
            }}
          >
            Delete
          </ButtonDanger>
        </Box>
      </ModalWrap>
    </Box>
  )
}

export default AdminPanelInsuranceCompaniesEdit
