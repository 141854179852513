import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import TextH1 from '../ui/Text/TextH1'
import TextNormal from '../ui/Text/TextNormal'
import BGTop from '../assests/img/background-coming-soon-top.png'
import BGBottom from '../assests/img/bg-coming-soon-bottom.png'

const ComingSoon = () => {
  const extraLarge = useMediaQuery('(min-width: 1350px')
  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <Box
      className={[extraLarge ? 'extra-large' : '', xsOnly ? 'xs-only' : ''].join(' ')}
      sx={{
        position: 'relative',
        top: '-31px',
        left: '-23px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'calc(100vh - 231px)',
        height: '611px',
        width: 'calc(100% + 46px)',
        '&.extra-large': {
          width: 'calc(100% + 121px)',
        },
        '&.xs-only': {
          width: '100%',
          left: 'auto',
          top: 'auto',
          minHeight: '100vh',
          height: 'auto',
        },
      }}
    >
      <Box
        className={extraLarge ? 'extra-large' : ''}
        sx={{
          background: `url('${BGTop}') no-repeat`,
          backgroundPosition: 'top center',
          backgroundSize: '100% auto',
          position: 'absolute',
          top: 0,
          right: 0,
          width: '80%',
          height: '100%',
          pointerEvents: 'none',
          '&.extra-large': {
            width: '60%',
          },
        }}
      />
      <Box
        className={[extraLarge ? 'extra-large' : '', xsOnly ? 'xs-only' : ''].join(' ')}
        sx={{
          background: `url('${BGBottom}') no-repeat`,
          backgroundPosition: 'bottom center',
          backgroundSize: '100% auto',
          position: 'absolute',
          bottom: '-64px',
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
          '&.extra-large': {
            width: '80%',
          },
          '&.xs-only': {
            bottom: 0,
          },
        }}
      />
      <TextH1
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          fontSize: '60px',
          mb: '24px',
          textAlign: 'center',
          '&.xs-only': {
            fontSize: '32px',
            mb: '15px',
          },
        }}
      >
        Great Things <br />
        Coming Soon!
      </TextH1>
      <TextNormal
        sx={{
          textAlign: 'center',
        }}
      >
        Our team is dedicated to bringing you the latest services for your convenience.
      </TextNormal>
    </Box>
  )
}

export default ComingSoon
