import { privateRequest } from '../../utils/request'

class AddressTypesApiService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      collection: '/address-types',
      instance: (id) => `/address-types/${id}`,
    }
  }

  getAddressTypes = async () => {
    const response = await this.#api.get(this.#endPoints.collection, {
      params: { pagination: false },
    })
    return response
  }
}

const addressTypesApiService = new AddressTypesApiService({ api: privateRequest })

export default addressTypesApiService
