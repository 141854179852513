import React, { useEffect, useState } from 'react'
import { Box, Stack, Table } from '@mui/material'
import { useDispatch } from 'react-redux'
import { setCurrentPageTitle } from '../../../../store/features/authSlice'
import { privateRequest } from '../../../../utils/request'
import { API_ENDPOINTS } from '../../../../config/default'
import CustomTabs from '../../../../ui/CustomTabs'
import CustomTab from '../../../../ui/CustomTab'
import OperatorMarkers from '../../OperatorPanelComponents/OperatorMarkers'
import TextNormal from '../../../../ui/Text/TextNormal'
import BoxShadowed from '../../../../ui/Layout/BoxShadowed'
import { colorsConfig } from '../../../../config/themeConfig'
import SearchInput from '../../../../components/Search/SearchInput'
import TextBoldNormal from '../../../../ui/Text/TextBoldNormal'
import CarTableHead from '../../../VirtualGarage/CarTableHead'
import { insuranceRequestsTableHead } from '../operator-insurance.constants'
import CarTableBody from '../../../VirtualGarage/CarTableBody'
import {
  getInsuranceRequestsProcessedTableData,
  getInsuranceRequestsTableData,
  hasUrgentRequests,
} from '../operator-insurance.helpers'
import WrapWithPreloader from '../../../../components/WrapWithPreloader'
import UIMarkerCircle from '../../../../ui/Markers/UIMarkerCircle'

const InsuranceRequests = () => {
  const dispatch = useDispatch()

  const [initialActiveRequests, setInitialActiveRequests] = useState([])
  const [activeRequestsLoading, setActiveRequestsLoading] = useState(true)
  const [processedRequestsLoading, setProcessedRequestsLoading] = useState(true)
  const [activeRequests, setActiveRequests] = useState([])
  const [processedRequests, setProcessedRequests] = useState([])
  const [activeRequestsTotal, setActiveRequestsTotal] = useState(0)
  const [processedRequestsTotal, setProcessedRequestsTotal] = useState(0)
  const [sort, setSort] = useState('asc')
  const [insuranceCompanies, setInsuranceCompanies] = useState([])
  const [tab, setTab] = useState(0)
  const [searchLine, setSearchLine] = useState('')

  const getActiveRequests = () => {
    setActiveRequestsLoading(true)
    privateRequest
      .get(API_ENDPOINTS.insurance.deals, {
        params: {
          currentWorkflowState: 'insurance_company_sent',
          pagination: false,
          'order[createdAt]': sort,
          insuranceDealExtendedSearch: searchLine,
          'insuranceApplication.isArchived': false,
        },
      })
      .then((response) => {
        const data = response.data['hydra:member']
        if (activeRequestsTotal === 0) setActiveRequestsTotal(data.length)
        if (initialActiveRequests.length === 0) setInitialActiveRequests(data)
        setActiveRequests(data)
      })
      .finally(() => {
        setActiveRequestsLoading(false)
      })
  }

  const getProcessedRequests = () => {
    setProcessedRequestsLoading(true)
    privateRequest
      .get(API_ENDPOINTS.insurance.deals, {
        params: {
          'currentWorkflowState[]': [
            'insurance_company_declined',
            'insurance_company_request_info',
          ],
          pagination: false,
          'order[createdAt]': sort,
          insuranceDealExtendedSearch: searchLine,
          'insuranceApplication.isArchived': false,
        },
      })
      .then((response) => {
        const data = response.data['hydra:member']
        if (processedRequestsTotal === 0) setProcessedRequestsTotal(data.length)
        setProcessedRequests(data)
      })
      .finally(() => {
        setProcessedRequestsLoading(false)
      })
  }

  const getInsuranceCompanies = () => {
    privateRequest.get(API_ENDPOINTS.insurance.companies).then((response) => {
      setInsuranceCompanies(response.data['hydra:member'])
    })
  }

  useEffect(() => {
    if (insuranceCompanies.length !== 0) {
      getActiveRequests()
      getProcessedRequests()
    }
  }, [insuranceCompanies, sort, searchLine])

  useEffect(() => {
    dispatch(setCurrentPageTitle('Insurance requests'))
    getInsuranceCompanies()
  }, [])

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: '50px',
        }}
      >
        <CustomTabs
          value={tab}
          onChange={(e, newVal) => {
            setTab(newVal)
          }}
        >
          <CustomTab
            label={
              <TextNormal sx={{ position: 'relative' }}>
                Awaiting for approval{' '}
                <span style={{ color: colorsConfig.mainBlue }}>({activeRequestsTotal})</span>
                {hasUrgentRequests(initialActiveRequests) ? (
                  <UIMarkerCircle
                    sx={{
                      position: 'absolute',
                      top: '1px',
                      right: '-19px',
                      width: '14px',
                      minWidth: '14px',
                      height: '14px',
                      background: colorsConfig.darkRed,
                    }}
                  />
                ) : null}
              </TextNormal>
            }
          />
          <CustomTab
            label={
              <TextNormal>
                Processed{' '}
                <span style={{ color: colorsConfig.mainBlue }}>({processedRequestsTotal})</span>
              </TextNormal>
            }
          />
        </CustomTabs>
        <OperatorMarkers />
      </Box>

      <BoxShadowed sx={{ minHeight: '400px' }}>
        <Stack gap="30px">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <TextBoldNormal
              sx={{
                fontSize: '24px',
              }}
            >
              {tab === 0 ? 'Waiting for clearing' : 'Processed'}
            </TextBoldNormal>
            <SearchInput
              value={searchLine}
              onChange={(e) => {
                setSearchLine(e.target.value)
              }}
            />
          </Box>
          <WrapWithPreloader
            loading={activeRequestsLoading || processedRequestsLoading}
            loadingText="Loading insurance requests..."
          >
            <Table>
              <CarTableHead tableCells={insuranceRequestsTableHead} sort={sort} setSort={setSort} />
              <CarTableBody
                content={
                  tab === 0
                    ? getInsuranceRequestsTableData(activeRequests, insuranceCompanies)
                    : getInsuranceRequestsProcessedTableData(processedRequests, insuranceCompanies)
                }
              />
            </Table>
          </WrapWithPreloader>
        </Stack>
      </BoxShadowed>
    </Box>
  )
}

export default InsuranceRequests
