import { updateCar } from '../car.actions'

export default (builder) => {
  builder
    .addCase(updateCar.pending, (state) => {
      state.update.isLoading = true
    })
    .addCase(updateCar.fulfilled, (state, action) => {
      state.update.isLoading = false
      state.update.error = null
      state.car = action.payload
    })
    .addCase(updateCar.rejected, (state, action) => {
      state.update.isLoading = false
      state.update.error = action.payload
    })
}
