import carBodyTypeCompact from '../assests/img/icon-car-compact.png'
import carBodyTypeConvertible from '../assests/img/icon-car-convertible.png'
import carBodyTypeCoupe from '../assests/img/icon-car-coupe.png'
import carBodyTypeOffRoad from '../assests/img/icon-car-offRoad.png'
import carBodyTypeStationWagon from '../assests/img/icon-car-stationWagon.png'
import carBodyTypeSedan from '../assests/img/icon-car-sedan.png'
import carBodyTypeVan from '../assests/img/icon-car-van.png'
import carBodyTypeTransporter from '../assests/img/icon-car-transporter.png'
import carBodyTypeOther from '../assests/img/icon-car-other.png'
import IconBronze from '../assests/img/icons/icon-bronze.svg'
import IconBronzeFull from '../assests/img/icons/icon-bronze-full.svg'
import IconSilver from '../assests/img/icons/icon-silver.svg'
import IconSilverFull from '../assests/img/icons/icon-silver-full.svg'
import IconGold from '../assests/img/icons/icon-gold.svg'
import IconGoldFull from '../assests/img/icons/icon-golden-full.svg'

export const emirates = [
  'Dubai',
  'Abu Dhabi',
  'Sharjah',
  'Ras Al Khaimah',
  'Fujairah',
  'Ajman',
  'Umm Al Quwain',
]

export const originTypes = [
  {
    id: 1,
    systemName: 'gcc',
    name: 'GCC',
  },
  {
    id: 2,
    systemName: 'usa',
    name: 'United States of America',
    translation: 'origin_type_us',
  },
  {
    id: 3,
    systemName: 'japan',
    name: 'Japan',
    translation: 'origin_type_japan',
  },
  {
    id: 4,
    systemName: 'germany',
    name: 'Germany',
    translation: 'origin_type_germany',
  },
  {
    id: 5,
    systemName: 'france',
    name: 'France',
    translation: 'origin_type_france',
  },
  {
    id: 6,
    systemName: 'italy',
    name: 'Italy',
    translation: 'origin_type_italy',
  },
  {
    id: 7,
    systemName: 'uk',
    name: 'United Kingdom',
    translation: 'origin_type_united_kingdom',
  },
  {
    id: 8,
    systemName: 'southKorea',
    name: 'South Korea',
    translation: 'origin_type_south_korea',
  },
  {
    id: 9,
    systemName: 'china',
    name: 'China',
    translation: 'origin_type_china',
  },
  {
    id: 10,
    systemName: 'india',
    name: 'India',
    translation: 'origin_type_india',
  },
  {
    id: 11,
    systemName: 'russia',
    name: 'Russia',
    translation: 'origin_type_russia',
  },
  {
    id: 12,
    systemName: 'sweden',
    name: 'Sweden',
    translation: 'origin_type_sweden',
  },
  {
    id: 13,
    systemName: 'brazil',
    name: 'Brazil',
    translation: 'origin_type_brazil',
  },
  {
    id: 14,
    systemName: 'other',
    name: 'Other',
    translation: 'common_other',
  },
]

export const gearboxTypes = [
  {
    id: 1,
    systemName: 'manual',
    name: 'Manual',
    translation: 'gearbox_type_manual',
  },
  {
    id: 2,
    systemName: 'automatic',
    name: 'Automatic',
    translation: 'gearbox_type_automatic',
  },
  {
    id: 3,
    systemName: 'other',
    name: 'Other',
    translation: 'common_other',
  },
]

export const driveTypes = [
  {
    id: 1,
    systemName: 'front',
    name: 'Front',
    translation: 'drive_type_front',
  },
  {
    id: 2,
    systemName: 'rear',
    name: 'Rear',
    translation: 'drive_type_rear',
  },
  {
    id: 3,
    systemName: 'awd',
    name: 'AWD',
    translation: 'drive_type_awd',
  },
  {
    id: 4,
    systemName: 'other',
    name: 'Other',
    translation: 'common_other',
  },
]

export const bodyTypes = [
  // {
  //     id: 1,
  //     systemName: 'compact',
  //     name: 'Compact',
  //     icon: carBodyTypeCompact
  // },
  {
    id: 2,
    systemName: 'convertible',
    name: 'Convertible',
    icon: carBodyTypeConvertible,
    translation: 'body_type_convertible',
  },
  {
    id: 3,
    systemName: 'coupe',
    name: 'Coupe',
    icon: carBodyTypeCoupe,
    translation: 'body_type_coupe',
  },
  {
    id: 4,
    systemName: 'suv',
    name: 'SUV/Off-Road/Pick-up',
    icon: carBodyTypeOffRoad,
    translation: 'body_type_suv',
  },
  {
    id: 5,
    systemName: 'stationWagon',
    name: 'Station wagon',
    icon: carBodyTypeStationWagon,
    translation: 'body_type_station_wagon',
  },
  {
    id: 6,
    systemName: 'sedan',
    name: 'Sedan',
    icon: carBodyTypeSedan,
    translation: 'body_type_sedan',
  },
  {
    id: 7,
    systemName: 'van',
    name: 'Van',
    icon: carBodyTypeVan,
    translation: 'body_type_van',
  },
  {
    id: 8,
    systemName: 'transporter',
    name: 'Transporter',
    icon: carBodyTypeTransporter,
    translation: 'body_type_transporter',
  },
  {
    id: 9,
    systemName: 'hatchback',
    name: 'Hatchback',
    icon: carBodyTypeCompact,
    translation: 'body_type_hatchback',
  },
  {
    id: 10,
    systemName: 'other',
    name: 'Other',
    icon: carBodyTypeOther,
    translation: 'common_other',
  },
]

export const engineTypes = [
  {
    id: 1,
    systemName: 'petrol',
    name: 'Petrol',
    translation: 'engine_type_petrol',
  },
  {
    id: 2,
    systemName: 'diesel',
    name: 'Diesel',
    translation: 'engine_type_diesel',
  },
  {
    id: 3,
    systemName: 'hybrid',
    name: 'Hybrid',
    translation: 'engine_type_hybrid',
  },
  {
    id: 4,
    systemName: 'plugInHybridElectric',
    name: 'Plug-in Hybrid',
    translation: 'engine_type_plug_in_hybrid',
  },
  {
    id: 5,
    systemName: 'electric',
    name: 'Electric',
    translation: 'engine_type_electric',
  },
  {
    id: 6,
    systemName: 'other',
    name: 'Other',
    translation: 'common_other',
  },
]

export const rimsTypes = [
  {
    id: 1,
    name: 'Steel Wheels',
    systemName: 'steelWheels',
    translation: 'rims_type_steel_wheels',
  },
  {
    id: 2,
    name: 'Light Alloy Wheels',
    systemName: 'lightAlloyWheels',
    translation: 'rims_type_light_alloy_wheels',
  },
  {
    id: 3,
    name: 'Forged Wheels',
    systemName: 'forgedWheels',
    translation: 'rims_type_forged_wheels',
  },
  {
    id: 4,
    name: 'Carbon Wheels',
    systemName: 'carbonWheels',
    translation: 'rims_type_carbon_wheels',
  },
]

export const colorTypes = [
  { color: '#DBAF6E', label: 'Beige', translation: 'color_beige' },
  { color: '#0059B2', label: 'Blue', translation: 'color_blue' },
  { color: '#994200', label: 'Brown', translation: 'color_brown' },
  {
    color: '#DB9D5D',
    label: 'Bronze',
    translation: 'color_bronze',
    specialType: 'image',
    image: IconBronze,
    imageFull: IconBronzeFull,
  },
  { color: '#F7C81E', label: 'Yellow', translation: 'color_yellow' },
  { color: '#949494', label: 'Grey', translation: 'color_grey' },
  { color: '#38A614', label: 'Green', translation: 'color_green' },
  { color: '#D91A2A', label: 'Red', translation: 'color_red' },
  { color: '#000000', label: 'Black', translation: 'color_black' },
  {
    color: '#C4C4C4',
    label: 'Silver',
    translation: 'color_silver',
    specialType: 'image',
    image: IconSilver,
    imageFull: IconSilverFull,
  },
  { color: '#991289', label: 'Violet', translation: 'color_violet' },
  { color: '#F0F0F0', label: 'White', translation: 'color_white' },
  { color: '#FF7500', label: 'Orange', translation: 'color_orange' },
  {
    color: '#E8C84A',
    label: 'Gold',
    translation: 'color_gold',
    specialType: 'image',
    image: IconGold,
    imageFull: IconGoldFull,
  },
]

export const bundledProductsTypes = ['Insurance', 'PPI', 'Warranty', 'Casco', 'RVI']
