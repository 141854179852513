import React from 'react'
import { FormControl, FormHelperText, InputAdornment, InputLabel } from '@mui/material'
import PhoneInput from 'react-phone-input-2'
import { useTranslation } from 'react-i18next'
import { colorsConfig } from '../../../../config/themeConfig'
import UITextBodySmall from '../../../../ui/UIText/UITextBodySmall'

const FormPhoneNumberBase = ({
  value,
  onChange,
  label,
  InputProps,
  inputRef,
  disabled,
  required,
  helperText,
  error,
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <FormControl
      required={required}
      variant="outlined"
      error={error}
      fullWidth
      sx={{
        position: 'relative',
        '& .MuiFormLabel-asterisk': {
          color: colorsConfig.mainRed,
        },
      }}
    >
      <InputLabel
        shrink
        htmlFor="phone-input"
        sx={{ color: disabled ? colorsConfig.lightGray : colorsConfig.mainBlack }}
      >
        {t(label)}
      </InputLabel>
      <PhoneInput
        country="ch"
        value={value}
        onChange={onChange}
        containerStyle={{
          border: 'none',
        }}
        inputStyle={{
          border: 'none',
          height: '37.13px',
          width: '100%',
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, .1)',
          color: disabled ? colorsConfig.lightGray : colorsConfig.mainBlack,
        }}
        buttonStyle={{
          background: 'white',
          border: 'none',
        }}
        inputProps={{
          ref: inputRef,
        }}
        disabled={disabled}
        {...props}
      />
      {InputProps?.endAdornment && (
        <InputAdornment
          position="end"
          sx={{ position: 'absolute', top: '50%', mt: '-1px', right: '12px' }}
        >
          {InputProps?.endAdornment}
        </InputAdornment>
      )}
      {helperText && <FormHelperText>{t(helperText)}</FormHelperText>}
    </FormControl>
  )
}

export default FormPhoneNumberBase
