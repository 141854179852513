import React, { useState } from 'react'
import { Box, Grid, MenuItem, Slider, useMediaQuery } from '@mui/material'
import CollapseBlock from '../../../../ui/Accordions/CollapseBlock'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmall'
import TextNormal from '../../../../ui/Text/TextNormal'
import { colorsConfig } from '../../../../config/themeConfig'
import IconBlueCar from '../../../../assests/img/icons/icon-blue-car.svg'
import TextBoldNormal from '../../../../ui/Text/TextBoldNormal'
import IconAppleRed from '../../../../assests/img/icons/icon-apple-red.svg'
import IconAppleGreen from '../../../../assests/img/icons/icon-apple-green.svg'
import FormControlTFS from '../../../../ui/CustomSelect/FormControlTFS'
import SelectTFS from '../../../../ui/CustomSelect/SelectTFS'
import SliderApple from '../../../../assests/img/eco-slider-apple.png'
import { ecoMarks } from '../../../CarPage/DealerFinancing/LoanCalculator/LoanCalculatorV1'
import RBox from '../../../../ui/RBox'
import CarInsurancePackages from '../CarInsuranceComponents/CarInsurancePackages'
import SimpleCheckbox from '../../../../ui/SimpleCheckbox'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import { useSelector, useDispatch } from 'react-redux'
import {
  setCollapseBlockState,
  setApplicationData,
  updateInsuranceDetails,
} from '../../../../store/features/insuranceSlice'
import { patchRequest, privateRequest } from '../../../../utils/request'
import { API_ENDPOINTS } from '../../../../config/default'
import { toast } from 'react-toastify'
import { getPackageRisks } from '../../insurance.helpers'
import { insuranceWorkflowsEnums } from '../../insurance.constants'
import SendToInsuranceCompaniesModal from '../../../../components/Modals/SendToInsuranceCompaniesModal'
import { setBalance } from '../../../../store/features/authSlice'
import useValidation from '../../../../hooks/useValidation'
import insuranceApplicationService from '../../../../api/insuranceService/insuranceApplicationService'
import { CURRENCY, REGION_LOCALE } from '../../../../utils/constants/global.constants'

const renderEcoLabel = (topLine, bottomLine) => {
  return (
    <Box sx={{ textAlign: 'center', pt: '7px' }}>
      <TextNormal sx={{ fontSize: '14px' }}>{topLine}</TextNormal>
      <TextNormal sx={{ fontSize: '12px' }}>{bottomLine}</TextNormal>
    </Box>
  )
}

export const ecoMarksInsurance = [
  {
    value: 0,
    amount: 0,
    label: renderEcoLabel('I breathe CO2', `0 ${CURRENCY[REGION_LOCALE]}/month`),
    title: `0 ${CURRENCY[REGION_LOCALE]}/month (I breathe CO2)`,
  },
  {
    value: 1,
    amount: 1,
    label: renderEcoLabel('Eco Enthusiast', `+1 ${CURRENCY[REGION_LOCALE]}/month`),
    title: `+1 ${CURRENCY[REGION_LOCALE]}/month (Eco Enthusiast)`,
  },
  {
    value: 2,
    amount: 2,
    label: renderEcoLabel('Eco Enthusiast', `+2 ${CURRENCY[REGION_LOCALE]}/month`),
    title: `+2 ${CURRENCY[REGION_LOCALE]}/month (Eco Enthusiast)`,
  },
  {
    value: 3,
    amount: 3,
    label: renderEcoLabel('Earth Friend', `'+3 ${CURRENCY[REGION_LOCALE]}/month')`),
    title: `+3 ${CURRENCY[REGION_LOCALE]}/month (Earth Friend)`,
  },
  {
    value: 4,
    amount: 5,
    label: renderEcoLabel('Earth Friend', `+5 ${CURRENCY[REGION_LOCALE]}/month`),
    title: `+5 ${CURRENCY[REGION_LOCALE]}/month (Earth Friend)`,
  },
  {
    value: 5,
    amount: 10,
    label: renderEcoLabel('Earth Supporter', `+10 ${CURRENCY[REGION_LOCALE]}/month`),
    title: `+10 ${CURRENCY[REGION_LOCALE]}/month (Earth Supporter)`,
  },
  {
    value: 6,
    amount: 15,
    label: renderEcoLabel('Earth Supporter', `+15 ${CURRENCY[REGION_LOCALE]}/month`),
    title: `+15 ${CURRENCY[REGION_LOCALE]}/month (Earth Supporter)`,
  },
  {
    value: 7,
    amount: 20,
    label: renderEcoLabel('Earth Supporter', `+20 ${CURRENCY[REGION_LOCALE]}/month`),
    title: `+20 ${CURRENCY[REGION_LOCALE]}/month (Earth Supporter)`,
  },
  {
    value: 8,
    amount: 25,
    label: renderEcoLabel('Earth Protector', `+25 ${CURRENCY[REGION_LOCALE]}/month`),
    title: `+25 ${CURRENCY[REGION_LOCALE]}/month (Earth Protector)`,
  },
  {
    value: 9,
    amount: 30,
    label: renderEcoLabel('Earth Hero', `+30 ${CURRENCY[REGION_LOCALE]}/month`),
    title: `+30 ${CURRENCY[REGION_LOCALE]}/month (Earth Hero)`,
  },
  {
    value: 10,
    amount: 35,
    label: renderEcoLabel('Earth Savior', `+35 ${CURRENCY[REGION_LOCALE]}/month`),
    title: `+35 ${CURRENCY[REGION_LOCALE]}/month (Earth Savior)`,
  },
]

const CarInsuranceBlockInsuranceDetails = () => {
  const balance = useSelector((state) => state.auth.balance)
  const selectedTab = useSelector((state) => state.insurance.selectedTab)
  const collapsed = useSelector(
    (state) =>
      state.insurance.applications[selectedTab]?.collapseBlocksState?.insuranceDetailsCollapsed,
  )
  const saved = useSelector(
    (state) =>
      state.insurance.applications[selectedTab]?.collapseBlocksState?.insuranceDetailsSaved,
  )
  const disabled = useSelector(
    (state) =>
      state.insurance.applications[selectedTab]?.collapseBlocksState?.insuranceDetailsDisabled,
  )
  const application = useSelector((state) => state.insurance.applications[selectedTab])
  const referenceRisks = useSelector((state) => state.insurance.referenceRisks)
  const dispatch = useDispatch()

  const xsOnly = useMediaQuery('(max-width: 600px)')

  const [ecoSacrifice, setEcoSacrifice] = useState(2)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [submitModal, setSubmitModal] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const startDateValidation = useValidation(application?.data?.startDate, { isEmpty: true })

  const hasErrors = () => {
    if (!application?.data?.consent) return true
    if (startDateValidation.hasErrors) return true
    if (!application?.packages.find((item) => item.selected)) return true
    return false
  }

  const handleOpenModal = () => {
    if (hasErrors()) {
      setShowErrors(true)
      return
    }
    setSubmitModal(true)
  }

  const handleSubmit = () => {
    setButtonDisabled(true)
    const selectedPackage = application.packages.find((item) => item.selected)
    const formData = {
      annualMileage: parseInt(application.data.annualMileage) * 5000,
      additionalRequestNote: application.data.additionalRequest,
      startDate: application.data.startDate,
      selectedRisks: getPackageRisks(referenceRisks, selectedPackage.selectedRisks),
    }
    patchRequest
      .patch(`${API_ENDPOINTS.insurance.applications}/${application.id}`, formData)
      .then((result) => {
        insuranceApplicationService.transitionToApprovalProcessStarted(application.id).then(() => {
          setSubmitModal(false)
          toast.success(
            'Congratulations! Your insurance applications have been submitted and we will send you quotes shortly',
          )
          dispatch(updateInsuranceDetails())
          dispatch(setBalance(balance - 1))
          window.scrollTo(0, 0)
        })
      })
      .finally(() => setButtonDisabled(false))
  }

  return (
    <CollapseBlock
      title={'Insurance Details'}
      description={'Enter insurance details'}
      collapsed={collapsed}
      saved={saved}
      disabled={disabled}
      onCollapseChange={(value) => {
        dispatch(
          setCollapseBlockState({
            id: application.id,
            block: 'insuranceDetailsCollapsed',
            value: value,
          }),
        )
      }}
    >
      <Grid container spacing={'24px'}>
        <Grid item xs={12} md={4}>
          <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Start date"
              inputFormat="DD/MM/YYYY"
              mask={'__/__/____'}
              disableMaskedInput={false}
              renderInput={(params) => (
                <TextFieldSmallEdible
                  {...params}
                  fullWidth
                  required
                  value={application?.data?.startDate}
                  error={showErrors && startDateValidation.hasErrors}
                  helperText={
                    showErrors && startDateValidation.hasErrors ? startDateValidation.errorText : ''
                  }
                />
              )}
              onChange={(newVal) => {
                dispatch(
                  setApplicationData({
                    id: application.id,
                    data: {
                      ...application.data,
                      startDate: newVal,
                    },
                  }),
                )
              }}
              value={application?.data?.startDate}
              disablePast
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: '25px',
          mt: '30px',
        }}
      >
        <TextNormal>Annual Mileage</TextNormal>
        <TextNormal>{application?.data?.annualMileage * 5000 - 5000} km/year</TextNormal>
      </Box>
      <Slider
        marks
        min={1}
        step={1}
        max={10}
        value={application?.data?.annualMileage}
        onChange={(e, newVal) => {
          dispatch(
            setApplicationData({
              id: application.id,
              data: {
                ...application.data,
                annualMileage: newVal === 1 ? 2 : newVal,
              },
            }),
          )
        }}
        sx={{
          color: colorsConfig.mainBlue,
          height: '10px',
          '& .MuiSlider-track': {
            background: colorsConfig.mainBlue,
            border: 'none',
          },
          '& .MuiSlider-rail': {
            background: colorsConfig.bgLightBlue,
            border: 'none',
            boxShadow: '0px 0px 7px 0px rgba(0, 0, 0, 0.10) inset',
          },
          '& .MuiSlider-thumb': {
            width: 0,
            height: 0,
            boxShadow: 'none !important',
            '&:hover': {
              boxShadow: 'none',
            },
            '&::before': {
              display: 'none',
            },
            '&::after': {
              content: '" "',
              position: 'absolute',
              display: 'block',
              width: '79px',
              height: '30px',
              background: `url(${IconBlueCar}) no-repeat`,
              borderRadius: '0',
              transform: 'none',
              top: '-35px',
              left: '-79px',
            },
          },
          '& .MuiSlider-mark': {
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            background: '#ECEFF0',
            transform: 'translate(-5px, -50%)',
            opacity: 1,
            '&.MuiSlider-markActive': {
              background: colorsConfig.mainBlue,
            },
          },
        }}
      />
      <Box sx={{ mt: '20px', height: '100px' }}>
        <TextFieldSmallEdible
          fullWidth
          label={'Additional request'}
          multiline
          minRows={4}
          value={application?.data?.additionalRequest}
          onChange={(e) => {
            dispatch(
              setApplicationData({
                id: application.id,
                data: {
                  ...application.data,
                  additionalRequest: e.target.value,
                },
              }),
            )
          }}
        />
      </Box>
      <RBox
        sx={{
          mt: '30px',
          mb: '30px',
          borderRadius: '6px',
          paddingRight: '115px',
          paddingBottom: '15px',
          position: 'relative',
          background: `linear-gradient(90deg, ${colorsConfig.bgLightBlue} 0%, ${colorsConfig.bgLightBlue} 29%, rgba(244,255,243,1) 100%)`,
          '&.xs-only': {
            px: 0,
            borderRadius: 0,
            background: 'none',
          },
        }}
      >
        <TextBoldNormal sx={{ mb: '2px' }}>Make the planet greener</TextBoldNormal>
        <TextNormal
          sx={{
            mb: '8px',
            lineHeight: 'normal',
            '&.xs-only': {
              mb: '20px',
            },
          }}
        >
          By opting for this service, you're supporting carbon offset initiatives that counteract
          the environmental impact of using conventional vehicles.
          {!xsOnly ? (
            <>
              We invite you to join us in our mission and get{' '}
              <span style={{ fontWeight: 700 }}>[your offer]</span>
            </>
          ) : (
            ''
          )}
        </TextNormal>
        {!xsOnly ? (
          <Box sx={{ px: '10px' }}>
            <Slider
              marks={ecoMarksInsurance.map((item, index) => {
                return {
                  ...item,
                  label: ecoSacrifice === index ? item.label : '',
                }
              })}
              min={0}
              step={1}
              max={10}
              //disabled={!isEditingEnabled}
              value={ecoSacrifice}
              onChange={(e, newVal) => {
                setEcoSacrifice(newVal)
              }}
              sx={{
                color: colorsConfig.mainBlue,
                height: '10px',
                '& .MuiSlider-track': {
                  background: colorsConfig.mainGreen,
                  border: 'none',
                },
                '& .MuiSlider-rail': {
                  background: colorsConfig.bgLightBlue,
                  border: 'none',
                  boxShadow: '0px 0px 7px 0px rgba(0, 0, 0, 0.10) inset',
                },
                '& .MuiSlider-thumb': {
                  width: 0,
                  height: 0,
                  boxShadow: 'none !important',
                  '&:hover': {
                    boxShadow: 'none',
                  },
                  '&::before': {
                    display: 'none',
                  },
                  '&::after': {
                    content: '"ECO"',
                    position: 'absolute',
                    width: '28px',
                    height: '29px',
                    top: '-2.5px',
                    left: '4px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontFamily: '"Helvetica", sans-serif',
                    fontWeight: 700,
                    fontSize: '8px',
                    color: ecoSacrifice === 0 ? 'white' : colorsConfig.mainBlack,
                    background: `url(${ecoSacrifice === 0 ? IconAppleRed : IconAppleGreen}) no-repeat`,
                    backgroundSize: '100% auto',
                  },
                },
                '& .MuiSlider-mark': {
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  background: '#ECEFF0',
                  transform: 'translate(-5px, -50%)',
                  opacity: 1,
                  '&.MuiSlider-markActive': {
                    background: colorsConfig.mainGreen,
                  },
                },
              }}
            />
          </Box>
        ) : (
          <Box>
            <FormControlTFS fullWidth>
              <SelectTFS
                value={ecoSacrifice}
                onChange={(e) => {
                  setEcoSacrifice(e.target.value)
                }}
              >
                {ecoMarks.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.title}
                    </MenuItem>
                  )
                })}
              </SelectTFS>
            </FormControlTFS>
          </Box>
        )}
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            height: '100%',
            borderRadius: '0 6px 6px 0',
            overflow: 'hidden',
            '&.xs-only': {
              display: 'none',
            },
          }}
        >
          <img src={SliderApple} style={{ height: '100%', width: 'auto' }} alt="" />
        </Box>
      </RBox>
      <CarInsurancePackages />
      {showErrors && !application?.packages.find((item) => item.selected) ? (
        <TextNormal sx={{ color: colorsConfig.mainRed, mt: '20px' }}>
          You have to choose the package to proceed
        </TextNormal>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          mt: '40px',
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              mb: '15px',
            }}
          >
            <SimpleCheckbox
              value={application?.data?.consent}
              setValue={(value) => {
                dispatch(
                  setApplicationData({
                    id: application.id,
                    data: {
                      ...application.data,
                      consent: value,
                    },
                  }),
                )
              }}
            />
            <TextNormal
              sx={{
                fontSize: '14px',
                color: colorsConfig.iconGray,
                ml: '5px',
                cursor: 'pointer',
              }}
              onClick={() => {
                dispatch(
                  setApplicationData({
                    id: application.id,
                    data: {
                      ...application.data,
                      consent: !application?.data?.consent,
                    },
                  }),
                )
              }}
            >
              I consent to the processing <br />
              of my personal data
            </TextNormal>
          </Box>
          {application.currentWorkflowState === insuranceWorkflowsEnums.DRIVER_DETAILS_SAVED ? (
            <ButtonLG
              disabled={(hasErrors() && showErrors) || buttonDisabled}
              onClick={() => handleOpenModal()}
            >
              Request quotes
            </ButtonLG>
          ) : null}
        </Box>
      </Box>
      <SendToInsuranceCompaniesModal
        open={submitModal}
        setOpen={setSubmitModal}
        handleAction={handleSubmit}
      />
    </CollapseBlock>
  )
}

export default CarInsuranceBlockInsuranceDetails
