import React from 'react'
import { Box, Typography } from '@mui/material'
import { AccountCircleRounded } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { colorsConfig } from '../../../../config/themeConfig'
import TextBoldNormal from '../../../../ui/Text/TextBoldNormal'
import HeaderProfileMenu from './HeaderProfileMenu'

const HeaderProfile = () => {
  const role = useSelector((state) => state.auth.role)
  const login = useSelector((state) => state.auth.login)
  const avatar = useSelector((state) => state.auth.avatar)

  const navigate = useNavigate()

  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginRight: '10px',
        cursor: 'pointer',
        transition: '0.25s all ease-in-out',
        '& .default-avatar-icon': {
          transition: '0.25s all ease-in-out',
          fill: colorsConfig.white,
        },
        '& .header-profile-username': {
          transition: '0.25s all ease-in-out',
          color: colorsConfig.white,
        },
        '& .header-profile-menu': {
          pointerEvents: 'none',
          opacity: 0,
          transformOrigin: '100% 0%',
          transform: 'scale(0)',
          transition: '0.25s all ease-in-out',
        },
        '&:hover': {
          '& .header-profile-menu': {
            pointerEvents: 'auto',
            transform: 'scale(1)',
            opacity: 1,
            transition: '0.25s all ease-in-out',
          },
          '& .header-profile-username': {
            transition: '0.25s all ease-in-out',
            color: colorsConfig.mainBlack,
          },
          '& .default-avatar-icon': {
            transition: '0.25s all ease-in-out',
            fill: colorsConfig.mainBlack,
          },
        },
      }}
      onClick={() => {
        if (role && role === 'COMPANY') {
          navigate('/profile-management')
        }
      }}
    >
      <HeaderProfileMenu />
      <TextBoldNormal
        variant="body1"
        className="header-profile-username"
        sx={{
          marginRight: '6px',
          color: colorsConfig.white,
          position: 'relative',
          zIndex: 2,
        }}
      >
        {login}
      </TextBoldNormal>
      {role && role === 'COMPANY' ? (
        <Box
          sx={{
            position: 'relative',
            zIndex: 2,
            width: '34px',
            height: '34px',
            border: `${colorsConfig.mainBlue} 2px solid`,
            borderRadius: '50%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <img
            src={avatar}
            alt=""
            style={{
              width: '100%',
            }}
          />
        </Box>
      ) : (
        <Box>
          <AccountCircleRounded
            className="default-avatar-icon"
            sx={{
              fontSize: '30px',
              position: 'relative',
              bottom: '-2px',
              fill: 'white',
            }}
          />
        </Box>
      )}
    </Box>
  )
}

export default HeaderProfile
