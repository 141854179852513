import React from 'react'
import UITableActionsList from '../../../../ui/UITable/UITableActionsList'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getInitialMakeAndModel } from '../../../../store/features/addNewCarSlice/addNewCar.actions'
import { setRviCarPolicy } from '../../../../store/features/addNewCarSlice/addNewCar.reducer'

const ClosedPoliciesActionItems = ({ data }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <UITableActionsList
      actions={[
        {
          name: 'Add car to a dealer',
          handleAction: async () => {
            dispatch(setRviCarPolicy(data?.initialData))
            navigate('/operator/add-car-to-dealer')
          },
        },
        {
          name: 'Archive',
          handleAction: () => {
            console.log('archive')
          },
        },
      ]}
    />
  )
}

export default ClosedPoliciesActionItems
