import { createAsyncThunk } from '@reduxjs/toolkit'
import addressesApiService from '../../../api/addressesApiService/addressesApiService'
import companyAddressesApiService from '../../../api/addressesApiService/companyAddressesApiService'
import { toast } from 'react-toastify'
import { adaptParams, getApiError } from '../../../api/api'
import { initialApplicationData as response } from '../../../pages/Insurance/insurance.constants'
import filesApiService from '../../../api/filesService/filesApiService'
import { fileTypeEnums } from '../../../utils/constants/enums.constants'
import profileApiService from '../../../api/profileApiService/profileApiService'
import { patchUser } from '../authSlice'

export const updateTradeLicense = createAsyncThunk(
  'settings/updateTradeLicense',
  async ({ file }, { rejectWithValue, dispatch }) => {
    try {
      const fileRes = await filesApiService.createFile(file, fileTypeEnums.trade_license)
      const fileIdUrl = fileRes.data?.['@id']
      const data = {
        tradeLicense: fileIdUrl,
      }
      const response = await profileApiService.updateCompanyCredentials(data)
      dispatch(patchUser({ manager: { company: { tradeLicense: fileIdUrl } } }))
      return response.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)

export const createCompanyAddress = createAsyncThunk(
  'settings/createCompanyAddress',
  async ({ data }, { rejectWithValue }) => {
    try {
      const addressRes = await addressesApiService.createAddress(data)
      const address = addressRes.data?.['@id']
      const companyAddressRes = await companyAddressesApiService.createCompanyAddress({
        address,
        isMain: true,
      })
      return companyAddressRes.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)

export const getCompanyAddresses = createAsyncThunk(
  'settings/getCompanyAddresses',
  async (_, { rejectWithValue, getState }) => {
    try {
      const params = getState().settings.companyAddresses.params
      const response = await companyAddressesApiService.getCompanyAddresses(adaptParams(params))

      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const updateCompanyAddress = createAsyncThunk(
  'settings/updateCompanyAddress',
  async ({ addressId, data }, { rejectWithValue }) => {
    try {
      const response = await addressesApiService.patchAddress(addressId, data)
      return response.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)

export const deleteCompanyAddress = createAsyncThunk(
  'settings/deleteCompanyAddress',
  async ({ companyAddressId }, { rejectWithValue }) => {
    try {
      const response = await companyAddressesApiService.deleteCompanyAddress(companyAddressId)
      return response.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)
