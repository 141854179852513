import { createAsyncThunk } from '@reduxjs/toolkit'
import warrantyApplicationsApiService from '../../../api/warrantyApplicationsService/warrantyApplicationsApiService'
import { apiConstants } from '../../../api/api'

export const getWarrantyApplications = createAsyncThunk(
  'warrantyApplications/getWarrantyApplications',
  async (_, { rejectWithValue, getState }) => {
    try {
      const searchLine = getState().warrantyApplications.searchLine
      const currentTab = getState().warrantyApplications.currentTab
      const params = {
        isArchived: false,
        pagination: false,
        currentWorkflowState: currentTab === 0 ? 'application_preparation' : 'issued',
      }
      if (searchLine.trim() !== '') params.warrantyApplicationExtendedSearch = searchLine.trim()
      const response = await warrantyApplicationsApiService.getApplications(params)
      return response.data
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e.response?.data?.[apiConstants.hydraDescription])
    }
  },
)

export const getWarrantyApplicationsWorkflowStateCounts = createAsyncThunk(
  'warrantyApplications/getWarrantyApplicationsWorkflowStateCounts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await warrantyApplicationsApiService.getWorkflowStateCounts()
      return response.data
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e.response?.data?.[apiConstants.hydraDescription])
    }
  },
)
