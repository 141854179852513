import React from 'react'
import { Box, Typography, Button, CircularProgress, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import useAllCars from '../../../hooks/useAllCars'
import { useNavigate } from 'react-router-dom'
import useBreakpoints from '../../../hooks/useBreakpoints'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import TextNormal from '../../../ui/Text/TextNormal'
import { useTranslation } from 'react-i18next'

const { mainBlue, mainBlack, buttonDisabled } = colorsConfig

export const DealerActivities = ({ translation }) => {
  const { t } = useTranslation()
  const { isMinWidth1440, isMaxWidth1440 } = useBreakpoints()
  const { loggingNodes, getCarVIN, loading } = useAllCars()

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  function handleFormatDate(date, symbol) {
    const regex = /^(\d{4})-(\d{2})-(\d{2}).*$/

    const match = date.match(regex)

    const day = match[3]
    const month = match[2]
    const year = match[1]

    return `${day}${symbol}${month}${symbol}${year}`
  }

  const navigate = useNavigate()

  const handleGoTo = (carLink, action) => {
    if (action.includes('loan_application') || action.includes('loan_deal')) {
      navigate(`${carLink}?service=Finance`)
      return
    }
    navigate(carLink)
  }

  return (
    <>
      {isMinWidth1440 ? (
        <Box
          sx={{
            width: '100%',
            height: '265px',
            margin: 0,
            padding: '25px 25px 25px 45px',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: mainBlack,
              fontSize: '40px',
              fontWeight: 700,
              lineHeight: 'normal',
              marginBottom: '12px',
              width: 1,
            }}
          >
            {t(translation)}
          </Typography>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
              }}
            >
              <CircularProgress
                size={60}
                sx={{
                  '.css-176wh8e-MuiCircularProgress-circle': {
                    color: colorsConfig.mainBlue,
                  },
                  margin: '0 8px',
                }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                height: '135px',
                display: 'flex',
                flexDirection: 'column',
                gap: '7px',
                overflowY: 'auto',
                scrollBehavior: 'smooth',
                scrollbarGutter: 'stable',
                scrollbarWidth: 'thin',
                '&::-webkit-scrollbar': {
                  width: '5px',
                  height: '120px',
                  background: buttonDisabled,
                  borderRadius: '5px',
                },
                '&::-webkit-scrollbar-thumb': {
                  height: '70px',
                  background: mainBlue,
                  borderRadius: '5px',
                },
              }}
            >
              {loggingNodes &&
                loggingNodes.map((node, index) => {
                  const { createdAt: date, translationContext, action, properties, car } = node

                  return (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        gap: '20px',
                      }}
                    >
                      <Typography sx={{ color: '#757575' }}>
                        {handleFormatDate(date, '.')}
                      </Typography>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            marginBottom: '-4px',
                            maxWidth: '100%',
                            overflow: 'hidden',
                            pr: '20px',
                          }}
                        >
                          {translationContext?.description}
                        </Typography>
                        <Button
                          sx={{
                            fontSize: '14px',
                            color: '#757575',
                            textDecoration: 'underline',
                            margin: 0,
                            padding: 0,
                            '&:hover': {
                              background: '#fff',
                            },
                          }}
                          onClick={() => handleGoTo(car['@id'], action)}
                        >
                          {car.vin}
                        </Button>
                      </Box>
                    </Box>
                  )
                })}
            </Box>
          )}
        </Box>
      ) : isMaxWidth1440 ? (
        <Box
          className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
          sx={{
            width: '100%',
            height: '245px',
            margin: 0,
            padding: '25px 20px 25px 30px',
            '&.xs-only': {
              pb: 0,
              pl: '10px',
              pr: '15px',
              pt: '33px',
              height: '232px',
              overflow: 'hidden',
            },
            '&.tablet-only': {
              px: '16px',
              py: '16px',
            },
          }}
        >
          <TextBoldNormal
            className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
            sx={{
              fontSize: '32px',
              lineHeight: 'normal',
              marginBottom: '12px',
              width: 1,
              '&.xs-only': {
                fontSize: '24px',
                mb: '16px',
              },
              '&.tablet-only': {
                fontSize: '24px',
              },
            }}
          >
            {t(translation)}
          </TextBoldNormal>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
              }}
            >
              <CircularProgress
                size={60}
                sx={{
                  '.css-176wh8e-MuiCircularProgress-circle': {
                    color: colorsConfig.mainBlue,
                  },
                  margin: '0 8px',
                }}
              />
            </Box>
          ) : (
            <Box
              className={[
                xsOnly ? 'xs-only' : '',
                loggingNodes.length > 2 ? 'scrollbar-visible' : '',
                tabletOnly ? 'tablet-only' : '',
              ].join(' ')}
              sx={{
                height: '135px',
                display: 'flex',
                flexDirection: 'column',
                gap: '7px',
                overflowY: 'auto',
                scrollBehavior: 'smooth',
                scrollbarGutter: 'stable',
                position: 'relative',
                scrollbarWidth: 'thin', // For Firefox
                '&::-webkit-scrollbar': {
                  width: '5px',
                  height: '120px',
                  background: buttonDisabled,
                  borderRadius: '5px',
                },
                '&::-webkit-scrollbar-thumb': {
                  height: '70px',
                  background: mainBlue,
                  borderRadius: '5px',
                },
                '&.xs-only': {
                  height: '154px',
                  overflow: 'hidden',
                  gap: 0,
                  '&::after': {
                    position: 'absolute',
                    content: '" "',
                    width: '5px',
                    height: 'calc(100% - 22px)',
                    background: colorsConfig.buttonDisabled,
                    top: 0,
                    right: '0px',
                    borderRadius: '5px',
                  },
                  '&.scrollbar-visible': {
                    '&::after': {
                      display: 'block',
                    },
                  },
                },
                '&.tablet-only': {
                  height: '80%',
                  pr: '10px',
                },
              }}
            >
              <Box
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  position: 'relative',
                  zIndex: 2,
                  '&.xs-only': {
                    overflow: 'auto',
                    gap: '7px',
                    pb: '21px',
                    scrollBehavior: 'smooth',
                    scrollbarGutter: 'stable',
                    scrollbarWidth: 'thin', // For Firefox
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '120px',
                      background: 'transparent',
                      borderRadius: '5px',
                      transform: 'scaleY(0.8)',
                    },
                    '&::-webkit-scrollbar-track': {
                      background: 'transparent',
                    },
                    '&::-webkit-scrollbar-track-piece:end': {
                      marginBottom: '21px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      height: '70px',
                      background: mainBlue,
                      borderRadius: '5px',
                      marginRight: '5px',
                    },
                  },
                }}
              >
                {loggingNodes &&
                  loggingNodes.map((node, index) => {
                    const { createdAt: date, translationContext, action, properties, car } = node

                    return (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          gap: '20px',
                        }}
                      >
                        <TextNormal
                          className={[
                            xsOnly ? 'xs-only' : '',
                            tabletOnly ? 'tablet-only' : '',
                          ].join(' ')}
                          sx={{
                            color: colorsConfig.iconGray,
                            '&.xs-only': {
                              fontSize: '14px',
                            },
                            '&.tablet-only': {
                              fontSize: '14px',
                            },
                          }}
                        >
                          {handleFormatDate(date, '.')}
                        </TextNormal>
                        <Box>
                          <TextBoldNormal
                            className={[
                              xsOnly ? 'xs-only' : '',
                              tabletOnly ? 'tablet-only' : '',
                            ].join(' ')}
                            sx={{
                              mb: '-4px',
                              maxWidth: '300px',
                              overflow: 'hidden',
                              '&.xs-only': {
                                fontSize: '14px',
                              },
                              '&.tablet-only': {
                                fontSize: '14px',
                              },
                            }}
                          >
                            {translationContext.description}
                          </TextBoldNormal>
                          <Button
                            className={[
                              xsOnly ? 'xs-only' : '',
                              tabletOnly ? 'tablet-only' : '',
                            ].join(' ')}
                            sx={{
                              fontSize: '14px',
                              color: '#757575',
                              textDecoration: 'underline',
                              margin: 0,
                              padding: 0,
                              '&:hover': {
                                background: '#fff',
                              },
                              '&.xs-only': {
                                fontSize: '12px',
                              },
                              '&.tablet-only': {
                                fontSize: '12px',
                              },
                            }}
                            onClick={() => handleGoTo(car['@id'], action)}
                          >
                            {car.vin}
                          </Button>
                        </Box>
                      </Box>
                    )
                  })}
              </Box>
            </Box>
          )}
        </Box>
      ) : null}
    </>
  )
}
