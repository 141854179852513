export const loanProgramsTableHead = [
  {
    key: 'title',
    title: 'Title',
  },
  {
    key: 'marketingTitle',
    title: 'Marketing title',
  },
  {
    key: 'creditType',
    title: 'Credit type',
  },
  {
    key: 'Bank',
    title: 'Bank',
  },
  {
    title: 'Action',
  },
]
