import React, { useState, useEffect } from 'react'
import { Box, Button, useMediaQuery, Stack } from '@mui/material'
import { AddRounded } from '@mui/icons-material'
import { colorsConfig } from '../../config/themeConfig'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import CarTable from './CarTable'
import { API_ENDPOINTS, CDN_BASE_URL, isDevEnv } from '../../config/default'
import carDefaultPicture from '../../assests/img/car-not-found.png'
import { useSelector, useDispatch } from 'react-redux'
import { privateRequest } from '../../utils/request'
import { useNavigate } from 'react-router-dom'
import Banners from './Banners'
import { setAuctionCarsAmount, setCurrentPageTitle } from '../../store/features/authSlice'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import BoxShadowed from '../../ui/Layout/BoxShadowed'
import VirtualGarageStatusIcons from './VirtualGarageStatusIcons'
import UITextHeadlineSmall from '../../ui/UIText/UITextHeadlineSmall'
import UITextRegular from '../../ui/UIText/UITextRegular'

const VirtualGarage3 = () => {
  const { t } = useTranslation()

  const extraLarge = useMediaQuery('(min-width: 1400px)')
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const actions = [
    {
      title: 'Start appraisal on-site',
      translation: 'button_start_appraisal_on_site',
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.new',
        },
      ],
      actions: [
        // {
        //     type: 'workflowTransition',
        //     link: `/cars/:id${API_ENDPOINTS.startOnSiteAppraisal}`
        // },
        {
          type: 'navigate',
          link: '/cars/:id/appraisal',
        },
      ],
    },
    {
      title: 'Edit appraisal',
      translation: 'button_edit_appraisal',
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.appraisal_on_site',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.quality_check_failed',
        },
      ],
      actions: [
        {
          type: 'navigate',
          link: '/cars/:id/appraisal',
        },
      ],
    },
    {
      title: 'Edit information',
      translation: 'button_edit_information',
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.new',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.appraisal_on_site',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.quality_check_failed',
        },
      ],
      actions: [
        {
          type: 'navigate',
          link: '/cars/:id/edit',
        },
      ],
    },
    {
      title: 'Send to our compound',
      translation: 'button_send_to_our_compound',
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.new',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.appraisal_on_site',
        },
        // {
        //     role: 'COMPANY',
        //     status: 'car_workflow.moderation_failed'
        // },
      ],
      actions: [
        {
          type: 'modal',
          modalName: 'send_to_compound',
        },
      ],
    },
    {
      title: 'Submit to quality check',
      translation: 'button_submit_to_quality_check',
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.appraisal_on_site',
        },
      ],
      specialConditions: 'appraisalChecks',
      actions: [
        {
          type: 'modal',
          modalName: 'quality_check',
        },
        // {
        //     type: 'extendedWorkflowTransition',
        //     link: `/cars/:id${API_ENDPOINTS.completeOnSiteAppraisal}`,
        //     newStatus: 'Quality check'
        // }
      ],
    },
    {
      title: 'Submit to quality check',
      translation: 'button_submit_to_quality_check',
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.moderation_failed',
        },
      ],
      actions: [
        // {
        //     type: 'extendedWorkflowTransitionMulti',
        //     link: `/cars/:id${API_ENDPOINTS.startOnSiteAppraisal}`,
        //     link2: `/cars/:id${API_ENDPOINTS.completeOnSiteAppraisal}`,
        //     newStatus: 'Quality check'
        // }
        {
          type: 'modal',
          modalName: 'quality_check_failed',
        },
      ],
    },
    {
      title: 'Start auction',
      translation: 'button_start_auction',
      disabled: !isDevEnv,
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.ready_for_sale',
        },
      ],
      actions: [
        {
          type: 'modal',
          modalName: 'start_auction',
          status: 'car_workflow.ready_for_sale',
        },
      ],
    },
    {
      title: 'Reschedule auction',
      translation: 'button_reschedule_auction',
      disabled: !isDevEnv,
      conditions: [
        {
          role: 'COMPANY',
          status: 'auction_workflow.pending',
        },
      ],
      actions: [
        {
          type: 'modal',
          modalName: 'start_auction',
          status: 'auction_workflow.pending',
        },
      ],
    },
    {
      title: 'Finance',
      translation: 'button_finance',
      disabled: !isDevEnv,
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.new',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.appraisal_on_site',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.quality_check',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.moderation_failed',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.requested_appraisal_compound',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.requested_appraisal_compound_failed',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.awaiting_appraisal_compound',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.appraisal_compound',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.ready_for_sale',
        },
        {
          role: 'COMPANY',
          status: 'auction_workflow.pending',
        },

        {
          role: 'COMPANY',
          status: 'auction_workflow.active',
        },

        {
          role: 'COMPANY',
          status: 'auction_workflow.completed',
        },
      ],
      actions: [
        {
          type: 'navigate',
          link: '/cars/:id?service=Finance',
        },
      ],
    },
    {
      title: 'Insurance',
      translation: 'button_insurance',
      disabled: !isDevEnv,
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.new',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.appraisal_on_site',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.quality_check',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.moderation_failed',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.requested_appraisal_compound',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.requested_appraisal_compound_failed',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.awaiting_appraisal_compound',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.appraisal_compound',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.ready_for_sale',
        },
        {
          role: 'COMPANY',
          status: 'auction_workflow.pending',
        },

        {
          role: 'COMPANY',
          status: 'auction_workflow.active',
        },

        {
          role: 'COMPANY',
          status: 'auction_workflow.completed',
        },
      ],
      actions: [
        {
          type: 'navigate',
          link: '/cars/:id?service=Insurance',
        },
      ],
    },
    {
      title: 'Warranty',
      translation: 'button_warranty',
      disabled: !isDevEnv,
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.new',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.appraisal_on_site',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.quality_check',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.moderation_failed',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.requested_appraisal_compound',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.requested_appraisal_compound_failed',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.awaiting_appraisal_compound',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.appraisal_compound',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.ready_for_sale',
        },
        {
          role: 'COMPANY',
          status: 'auction_workflow.pending',
        },

        {
          role: 'COMPANY',
          status: 'auction_workflow.active',
        },

        {
          role: 'COMPANY',
          status: 'auction_workflow.completed',
        },
      ],
      actions: [
        {
          type: 'navigate',
          link: '/cars/:id?service=Warranty',
        },
      ],
    },
    {
      title: 'Archive',
      translation: 'button_archive',
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.new',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.appraisal_on_site',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.moderation_failed',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.ready_for_sale',
        },
        {
          role: 'COMPANY',
          status: 'car_workflow.sale_succeed',
        },
      ],
      actions: [
        {
          type: 'archive',
          link: '/cars/:id/archive',
        },
      ],
    },
    {
      title: 'Finish quality check',
      translation: 'button_finish_quality_check',
      conditions: [
        {
          role: 'OPERATOR',
          status: 'car_workflow.quality_check',
        },
      ],
      actions: [
        {
          type: 'navigate',
          link: '/cars/:id',
        },
      ],
    },
  ]

  const carWorkflows = useSelector((state) => state.workflows.carWorkflowStates)
  const auctionWorkflows = useSelector((state) => state.workflows.auctionWorkflowStates)
  const dealWorkflows = useSelector((state) => state.workflows.dealWorkflowStates)
  const workflowsLoaded = useSelector((state) => state.workflows.loaded)
  const role = useSelector((state) => state.auth.role)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [cars, setCars] = useState([])
  const [loading, setLoading] = useState(false)
  const [carsTotal, setCarsTotal] = useState(0)
  const [statuses, setStatuses] = useState([])
  const [filterStatus, setFilterStatus] = useState(null)
  const [sort, setSort] = useState('&order[lastUpdatedAt]=desc')
  const [searchLine, setSearchLine] = useState('')
  const [archivedCarsCount, setArchivedCarsCount] = useState(0)
  const [displayMode, setDisplayMode] = useState('table')

  const handleSetCarPhoto = (photo) => {
    if (photo && photo.photo && photo.photo.path) {
      return `${CDN_BASE_URL}${photo.photo.path}`
    } else {
      return carDefaultPicture
    }
  }

  const getDaysInStock = (date) => {
    const date1 = new Date()
    const date2 = new Date(date)
    const diffTime = Math.abs(date2 - date1)
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
    return diffDays === 0
      ? `0 ${t('common_days')}`
      : diffDays === 1
        ? `1 ${t('common_day')}`
        : `${diffDays} ${t('common_days')}`
  }

  const getCarStatus = (combinedStatus, isArchived) => {
    if (!combinedStatus) {
      return 'Outdated car'
    }
    if (isArchived) {
      return 'Archived'
    }
    if (combinedStatus.includes('car_workflow')) {
      return carWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('car_workflow.', ''),
      )[0].value
    }
    if (combinedStatus.includes('auction_workflow')) {
      return auctionWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('auction_workflow.', ''),
      )[0].value
    }
    if (combinedStatus.includes('deal_workflow')) {
      return dealWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('deal_workflow.', ''),
      )[0].value
    }
  }

  const getActionList = (carStatus, isArchived, isChecksDone) => {
    if (isArchived) {
      return []
    }
    const availableActions = actions.filter((item) => {
      if ((item.specialConditions === 'appraisalChecks' && !isChecksDone) || item?.disabled) {
        return false
      }
      return item.conditions.filter(
        (condition) => role === condition.role && carStatus === condition.status,
      )[0]
    })
    return availableActions
  }

  const handleSetFilters = (data, mode) => {
    if (mode === 'cars') {
      let filteredData
      if (role === 'OPERATOR') {
        filteredData = data.filter(
          (item) =>
            (item.name === 'car_workflow.quality_check' ||
              item.name === 'deal_workflow.pending' ||
              item.name === 'car_workflow.requested_appraisal_compound' ||
              item.name === 'car_workflow.awaiting_appraisal_compound' ||
              item.name === 'deal_workflow.pending') &&
            item.count !== 0,
        )
      } else if (role === 'EXPERT') {
        filteredData = data.filter(
          (item) =>
            (item.name === 'car_workflow.quality_check' ||
              item.name === 'deal_workflow.pending' ||
              item.name === 'car_workflow.requested_appraisal_compound' ||
              item.name === 'car_workflow.awaiting_appraisal_compound' ||
              item.name === 'deal_workflow.pending') &&
            item.count !== 0,
        )
      } else if (role === 'COMPANY') {
        filteredData = data.filter((item) => item.count !== 0)
      }
      setStatuses(
        filteredData.map((item, index) => {
          return {
            id: index + 1,
            systemName: item.name,
            label: getCarStatus(item.name),
            count: item.count,
          }
        }),
      )
    }
  }

  const getAuctionsAmount = () => {
    privateRequest.get(API_ENDPOINTS.auctions).then((response) => {
      dispatch(
        setAuctionCarsAmount({
          auctionCarsAmount: response.data['hydra:totalItems'],
        }),
      )
    })
  }

  const getCars = () => {
    setLoading(true)
    let link, operatorLink, expertLink
    if (!filterStatus) {
      link = API_ENDPOINTS.cars.companyCars
    } else if (filterStatus !== 'Archived') {
      link = `${API_ENDPOINTS.cars.companyCars}&combinedStatus[]=${filterStatus}`
    } else if (filterStatus === 'Archived') {
      link = API_ENDPOINTS.cars.companyCars.replace('isArchived=false', 'isArchived=true')
    }
    if (searchLine) {
      link = `${link}&or[make.name]=${searchLine}&or[model.name]=${searchLine}&or[referenceNumber]=${searchLine}&or[vin]=${searchLine}&or[combinedStatus]=${searchLine}&or[id]=${searchLine}`
    }
    if (sort) {
      link = `${link}${sort}`
    }
    if (role === 'OPERATOR') {
      if (!filterStatus) {
        operatorLink = `${API_ENDPOINTS.cars.companyCars}&combinedStatus[]=car_workflow.requested_appraisal_compound&combinedStatus[]=car_workflow.awaiting_appraisal_compound&combinedStatus[]=car_workflow.quality_check&combinedStatus[]=deal_workflow.pending&combinedStatus[]=deal_workflow.money_received&combinedStatus[]=deal_workflow.money_payed&combinedStatus[]=deal_workflow.transportation_in_progress&combinedStatus[]=deal_workflow.invoice_sent`
      } else {
        operatorLink = `${API_ENDPOINTS.cars.companyCars}&combinedStatus[]=${filterStatus}`
      }
      if (searchLine) {
        operatorLink = `${operatorLink}&or[make.name]=${searchLine}&or[model.name]=${searchLine}&or[referenceNumber]=${searchLine}&or[vin]=${searchLine}&or[combinedStatus]=${searchLine}&or[id]=${searchLine}`
      }
      if (sort) {
        operatorLink = `${operatorLink}${sort}`
      }
    }
    if (role === 'EXPERT') {
      if (!filterStatus) {
        expertLink = `${API_ENDPOINTS.cars.companyCars}&combinedStatus[]=car_workflow.appraisal_compound`
      } else {
        expertLink = `${API_ENDPOINTS.cars.companyCars}&combinedStatus[]=${filterStatus}`
      }
      if (searchLine) {
        expertLink = `${expertLink}&or[make.name]=${searchLine}&or[model.name]=${searchLine}&or[referenceNumber]=${searchLine}&or[vin]=${searchLine}&or[combinedStatus]=${searchLine}&or[id]=${searchLine}`
      }
      if (sort) {
        expertLink = `${expertLink}${sort}`
      }
    }
    privateRequest
      .get(role === 'COMPANY' ? link : role === 'EXPERT' ? expertLink : operatorLink)
      .then((response) => {
        if (carsTotal === 0) {
          setCarsTotal(response.data['hydra:totalItems'])
        }
        setCars(
          response.data['hydra:member'].map((item) => {
            let link = `/cars/${item.id}`
            if (role === 'OPERATOR' && item.combinedStatus.includes('deal_workflow')) {
              link = `/cars/${item.id}/deal-clearing`
            }
            if (role === 'EXPERT') {
              link = `/cars/${item.id}/appraisal`
            }
            if (role === 'EXPERT' && item.combinedStatus.includes('deal_workflow')) {
              link = `/cars/${item.id}/deal-clearing`
            }
            return {
              id: item.id,
              link: link,
              car: item,
              greenCar: item.engineType === 'electric' || item.engineType === 'hybrid',
              cells: [
                {
                  type: 'text',
                  value: item.referenceNumber ? item.referenceNumber : item.id,
                },
                {
                  type: 'image',
                  value: handleSetCarPhoto(item.mainForeshortening),
                },
                {
                  type: 'text-with-caption',
                  value: `${item.make.name} ${item.model.name}`,
                  caption: `VIN ${item.vin}`,
                },
                {
                  type: 'text',
                  value: item?.isCompounded
                    ? 'Fleetify compounds'
                    : t('table_cell_value_your_garage'),
                },
                {
                  type: 'text',
                  value: getDaysInStock(item.createdAt),
                },
                {
                  type: 'component',
                  value: (
                    <VirtualGarageStatusIcons
                      isInsuranceRelated={item?.isInsuranceRelated}
                      isFinanceRelated={item.isFinanceRelated}
                      isWarrantyRelated={item?.isWarrantyRelated}
                      combinedStatus={item.combinedStatus}
                    />
                  ),
                },
                {
                  type: 'actions',
                  value: getActionList(
                    item.combinedStatus,
                    item.isArchived,
                    item.isAppraisalChecksDone,
                  ),
                },
              ],
            }
          }),
        )
      })
      .finally(() => setLoading(false))
  }

  const getCarStatusCount = () => {
    privateRequest.get(API_ENDPOINTS.cars.statusCount).then((response) => {
      const data = response.data['hydra:member']
      handleSetFilters(data, 'cars')
    })
  }

  const getArchivedCarsCount = () => {
    privateRequest.get(API_ENDPOINTS.cars.archivedCars).then((response) => {
      setArchivedCarsCount(response.data['hydra:totalItems'])
    })
  }

  useEffect(() => {
    if (workflowsLoaded) {
      getCars()
      getCarStatusCount()
      getAuctionsAmount()
      getArchivedCarsCount()
    }
  }, [workflowsLoaded, filterStatus, searchLine, sort])

  useEffect(() => {
    if (role && role === 'OPERATOR') dispatch(setCurrentPageTitle("Operator's Panel"))
    if (role && role === 'EXPERT') dispatch(setCurrentPageTitle("Expert's Panel"))
    if (role && role === 'COMPANY') dispatch(setCurrentPageTitle(t('page_title_virtual_garage')))
  }, [role, i18next.language])

  useEffect(() => {
    if (xsOnly) {
      setDisplayMode('list')
    }
  }, [xsOnly])

  return (
    <Box
      className={tabletOnly ? 'tablet-only' : ''}
      sx={{
        position: 'relative',
        display: 'block',
        '&.tablet-only': {
          px: '16px',
        },
      }}
    >
      {role === 'COMPANY' && !xsOnly ? (
        <Box
          className={tabletOnly ? 'tablet-only' : ''}
          sx={{
            mt: '10px',
            mb: '30px',
            '&.tablet-only': {
              width: 'calc(100vw - 32px)',
            },
            '& .control-dots': {
              display: 'flex',
              justifyContent: 'center',
            },
          }}
        >
          <Banners />
        </Box>
      ) : null}
      {role === 'COMPANY' && !xsOnly ? (
        <Box
          sx={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mb: {
              xs: '115px',
              sm: '40px',
            },
            display: {
              sm: 'flex',
            },
            px: {
              xs: '16px',
              sm: 0,
            },
          }}
        >
          <Button
            fullWidth={xsOnly}
            variant="contained"
            sx={{
              background: colorsConfig.mainGreen,
              borderRadius: '4px',
              fontSize: '16px',
              fontWeight: 700,
              textTransform: 'none',
              px: '60px',
              py: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              color: colorsConfig.mainBlack,
              transition: '0.25s all ease-in-out',
              '&:hover': {
                background: colorsConfig.mainGreen,
                opacity: '0.8',
                transition: '0.25s all ease-in-out',
              },
            }}
            onClick={() => {
              navigate('/add-new-vehicle')
            }}
          >
            <AddRounded
              sx={{
                fill: colorsConfig.mainBlack,
                mr: '11px',
                position: 'relative',
                mt: '-1px',
              }}
            />
            {t('button_add_vehicle')}
          </Button>
        </Box>
      ) : null}
      <BoxShadowed
        className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
        sx={{
          px: '40px',
          py: '37px',
          minHeight: '161px',
          position: 'relative',
          mt: xsOnly && role !== 'COMPANY' ? '50px' : 0,
          '&.xs-only': {
            boxShadow: 'none',
            p: 0,
            borderRadius: 0,
          },
          '&.tablet-only': {
            boxShadow: 'none',
            p: 0,
            borderRadius: 0,
          },
        }}
      >
        {carsTotal === 0 && !loading ? (
          <Stack gap="20px" sx={{ minHeight: '87px' }} justifyContent="center">
            <UITextHeadlineSmall text="table_cars_subtitle" />
            <UITextRegular text="table_cars_empty_message" />
          </Stack>
        ) : (
          <CarTable
            loading={loading}
            type={'cars'}
            content={cars}
            filters={statuses}
            setFilterStatus={setFilterStatus}
            getCars={getCars}
            searchLine={searchLine}
            setSearchLine={setSearchLine}
            sort={sort}
            setSort={setSort}
            cars={cars}
            setCars={setCars}
            getActionList={getActionList}
            archivedCount={archivedCarsCount}
          />
        )}
      </BoxShadowed>
      {role === 'COMPANY' && xsOnly ? (
        <Box
          sx={{
            mt: {
              xs: '20px',
              sm: '10px',
            },
            mb: {
              sm: '30px',
              xs: '40px',
            },
            pl: {
              xs: '16px',
              sm: 0,
            },
            pr: {
              xs: '16px',
              sm: 0,
            },
          }}
        >
          <Banners />
        </Box>
      ) : null}
    </Box>
  )
}

export default VirtualGarage3
