import React, { useState } from 'react'
import { Box, Button, Grid } from '@mui/material'
import TextNormal from '../../../ui/Text/TextNormal'
import { colorsConfig } from '../../../config/themeConfig'
import IconEdit from '../../../assests/img/icons/icon-edit.svg'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import { privateRequest } from '../../../utils/request'
import { toast } from 'react-toastify'

const AdminPanelDamage = (props) => {
  const { damage, refresh } = props

  const { name, translations } = damage

  const [editMode, setEditMode] = useState(false)
  const [mainName, setMainName] = useState(name)
  const [nameDE, setNameDE] = useState(
    translations.filter((item) => item.systemName === 'de')[0].value,
  )
  const [nameFR, setNameFR] = useState(
    translations.filter((item) => item.systemName === 'fr')[0].value,
  )
  const [nameIT, setNameIT] = useState(
    translations.filter((item) => item.systemName === 'it')[0].value,
  )
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)

  const handleUpdateDamage = () => {
    setSaveButtonDisabled(true)
    const formData = {
      name: mainName,
      translations: [
        {
          locale: 'en',
          field: 'name',
          value: mainName,
        },
        {
          locale: 'de',
          field: 'name',
          value: nameDE,
        },
        {
          locale: 'fr',
          field: 'name',
          value: nameFR,
        },
        {
          locale: 'it',
          field: 'name',
          value: nameIT,
        },
      ],
    }
    privateRequest
      .put(damage['@id'], formData)
      .then(() => {
        toast.success('Damage has been updated', { autoClose: 1000 })
        refresh()
        setEditMode(false)
      })
      .finally(() => {
        setSaveButtonDisabled(false)
      })
  }

  return (
    <Box
      sx={{
        background: 'white',
        borderRadius: '6px',
        boxShadow: '0 0 10px 0 rgba(0,0,0,.05)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: '3px',
          px: '10px',
        }}
      >
        <TextNormal>{name}</TextNormal>
        <Box>
          <Button
            sx={{
              textTransform: 'none',
            }}
            onClick={() => {
              setEditMode(!editMode)
            }}
          >
            {editMode ? (
              'Close'
            ) : (
              <>
                <img
                  src={IconEdit}
                  style={{
                    marginRight: '5px',
                  }}
                  alt=""
                />
                Edit
              </>
            )}
          </Button>
        </Box>
      </Box>
      {editMode ? (
        <Box
          sx={{
            p: '20px',
            borderTop: '1px solid',
            borderColor: colorsConfig.lightGray,
          }}
        >
          <Grid container spacing={'15px'}>
            <Grid item xs={12}>
              <TextFieldSmallEdible
                fullWidth
                label={'Name'}
                value={mainName}
                onChange={(e) => {
                  setMainName(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label={'Name (German)'}
                value={nameDE}
                onChange={(e) => {
                  setNameDE(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label={'Name (French)'}
                value={nameFR}
                onChange={(e) => {
                  setNameFR(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldSmallEdible
                fullWidth
                label={'Name (Italian)'}
                value={nameIT}
                onChange={(e) => {
                  setNameIT(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <ButtonLG
                  sx={{
                    height: '38px',
                  }}
                  disabled={saveButtonDisabled}
                  onClick={() => {
                    handleUpdateDamage()
                  }}
                >
                  Save
                </ButtonLG>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </Box>
  )
}

export default AdminPanelDamage
