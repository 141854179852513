import { calculateCustomPackageTerms } from '../financing.actions'

export default (builder) => {
  builder
    .addCase(calculateCustomPackageTerms.pending, (state) => {
      state.termsCalculation.isLoadingCustom = true
    })
    .addCase(calculateCustomPackageTerms.fulfilled, (state, action) => {
      state.termsCalculation.isLoadingCustom = false
      state.termsCalculation.errorCustom = null
      state.termsCalculation.customPrice =
        action.payload?.loanOfferCalculation?.calculationDetails?.monthlyPayment?.units
    })
    .addCase(calculateCustomPackageTerms.rejected, (state, action) => {
      if (action.payload?.code !== 'ERR_CANCELED') {
        state.termsCalculation.isLoadingCustom = false
        state.termsCalculation.errorCustom = action.payload
      }
    })
}
