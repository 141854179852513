import { privateRequest } from '../../utils/request'
import { headersIdJson } from '../APIHeaders'

class WarrantyApplicationsService {
  #api

  #endPoints

  #abortControllers

  constructor({ api }) {
    this.#api = api
    this.#abortControllers = {}
    this.#endPoints = {
      warrantyApplications: '/warranty/warranty-applications',
      warrantyApplication: (id) => `/warranty/warranty-applications/${id}`,
      selectProduct: (id) => `/warranty/warranty-applications/${id}/select-product`,
      sendInvoice: (id) => `/warranty/warranty-applications/${id}/send-invoice-issued-notification`,
      workflow: {
        confirmToApplicationPreparation: (id) =>
          `/warranty/warranty-applications/${id}/workflow/confirm-to-application-preparation`,
        confirmToIssued: (id) => `/warranty/warranty-applications/${id}/workflow/confirm-to-issued`,
      },
      workflowStateCounts: 'workflow-state-counts/warranty-applications',
      calculateWarrantyProducts: '/warranty/warranty-products/calculate',
      reissue: (id) => `/warranty/warranty-applications/${id}/reissue`,
    }
  }

  getApplications = async (params) => {
    this.#abortControllers.getApplications?.abort()
    this.#abortControllers.getApplications = new AbortController()
    const response = await this.#api.get(this.#endPoints.warrantyApplications, {
      params,
      signal: this.#abortControllers.getApplications?.signal,
    })
    return response
  }

  getApplicationsByCar = async (car) => {
    const response = await this.#api.get(this.#endPoints.warrantyApplications, {
      params: {
        car,
      },
    })
    return response
  }

  createApplication = async (data) => {
    const response = await this.#api.post(this.#endPoints.warrantyApplications, data)
    return response
  }

  updateApplication = async (application, data) => {
    const response = await this.#api.put(application, data)
    return response
  }

  selectProduct = async (applicationId, data) => {
    const response = await this.#api.put(this.#endPoints.selectProduct(applicationId), data)
    return response
  }

  sendInvoiceIssuedNotification = async (applicationId) => {
    const response = await this.#api.post(this.#endPoints.sendInvoice(applicationId), {})
    return response
  }

  confirmToApplicationPreparation = async (applicationId) => {
    const response = await this.#api.post(
      this.#endPoints.workflow.confirmToApplicationPreparation(applicationId),
      {
        headers: headersIdJson,
      },
    )
    return response
  }

  confirmToIssued = async (applicationId) => {
    const response = await this.#api.post(this.#endPoints.workflow.confirmToIssued(applicationId), {
      headers: headersIdJson,
    })
    return response
  }

  calculateWarrantyProducts = async (data) => {
    const response = await this.#api.post(this.#endPoints.calculateWarrantyProducts, data)
    return response
  }

  reissueWarranty = async (applicationId) => {
    const response = await this.#api.post(this.#endPoints.reissue(applicationId), {})
    return response
  }

  getWorkflowStateCounts = async () => {
    const response = await this.#api.get(this.#endPoints.workflowStateCounts, {
      params: {
        pagination: false,
      },
    })
    return response
  }
}

const warrantyApplicationsService = new WarrantyApplicationsService({ api: privateRequest })

export default warrantyApplicationsService
