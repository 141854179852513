import React from 'react'
import { Typography } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const TextBoldNormal = ({ children, sx, ...props }) => {
  return (
    <Typography
      sx={{
        fontFamily: '"Helvetica", sans-serif',
        fontSize: '16px',
        fontWeight: 700,
        color: colorsConfig.mainBlack,
        textDecoration: 'none',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  )
}

export default TextBoldNormal
