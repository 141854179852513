import React, { useEffect, useState } from 'react'
import BreadcrumbsComponent from '../../../../components/BreadcrumbsComponent'
import TextH1 from '../../../../ui/Text/TextH1'
import { Box, Grid } from '@mui/material'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { patchRequest, privateRequest } from '../../../../utils/request'
import { API_ENDPOINTS } from '../../../../config/default'
import { toast } from 'react-toastify'
import { setCurrentPageTitle } from '../../../../store/features/authSlice'
import ButtonPlain from '../../../../ui/Buttons/ButtonPlain'
import { colorsConfig } from '../../../../config/themeConfig'
import { Delete, HomeRounded } from '@mui/icons-material'
import ModalWrap from '../../../../components/Modals/ModalWrap'
import ButtonDanger from '../../../../ui/Buttons/ButtonDanger'
import ButtonHollow from '../../../../ui/Buttons/ButtonHollow'

const EditRisk = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const [name, setName] = useState('')
  const [savedName, setSavedName] = useState('')
  const [riskFormula, setRiskFormula] = useState('')
  const [confirmDeletionModal, setConfirmDeletionModal] = useState(false)
  const [disableDeleteButton, setDisableDeleteButton] = useState(false)

  const hasErrors = !name

  const handleEditRisk = () => {
    const formData = {
      name,
      riskFormula,
    }
    patchRequest
      .patch(`${API_ENDPOINTS.insurance.risks}/${params.id}`, formData)
      .then(() => {
        toast.success('Updated risk successfully', { autoClose: 1000 })
        navigate('/admin/insurance/risks')
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data['hydra:description']) {
          toast.error(error.response.data['hydra:description'])
        } else toast.error('Unexpected error')
      })
  }

  const handleDeleteRisk = () => {
    setDisableDeleteButton(true)
    privateRequest
      .delete(`${API_ENDPOINTS.insurance.risks}/${params.id}`)
      .then((response) => {
        toast.success('Risk has been deleted', { autoClose: 1000 })
        navigate('/admin/insurance/risks')
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data['hydra:description']) {
          toast.error(error.response.data['hydra:description'])
        } else toast.error('Unexpected error')
      })
      .finally(() => {
        setDisableDeleteButton(false)
      })
  }

  const getRisk = () => {
    privateRequest.get(`${API_ENDPOINTS.insurance.risks}/${params.id}`).then((response) => {
      setName(response.data.name)
      setRiskFormula(response.data.riskFormula)
      setSavedName(response.data.name)
    })
  }

  useEffect(() => {
    getRisk()
    dispatch(setCurrentPageTitle('Edit risk'))
  }, [])

  return (
    <Box>
      <BreadcrumbsComponent
        currentPage={{ name: savedName ?? `Edit risk` }}
        parentPages={[
          {
            name: 'Risks',
            icon: <HomeRounded sx={{ fill: colorsConfig.lightGray }} />,
            link: '/admin/insurance/risks',
          },
        ]}
      />
      <TextH1
        sx={{
          fontSize: '36px',
          mb: '30px',
          mt: '10px',
        }}
      >
        Edit risk "{savedName}"
      </TextH1>
      <Grid container spacing={'20px'}>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Risk name'}
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            fullWidth
            label={'Risk formula'}
            value={riskFormula}
            disabled
            onChange={(e) => {
              setRiskFormula(e.target.value)
            }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: '40px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ButtonPlain
          sx={{
            color: colorsConfig.mainRed,
            '&:hover': {
              color: colorsConfig.darkRed,
            },
          }}
          onClick={() => {
            setConfirmDeletionModal(true)
          }}
        >
          <Delete
            sx={{
              fill: colorsConfig.mainRed,
              mr: '4px',
            }}
          />
          Delete
        </ButtonPlain>
        <ButtonLG
          sx={{
            minWidth: '187px',
          }}
          disabled={hasErrors}
          onClick={() => {
            handleEditRisk()
          }}
        >
          Save
        </ButtonLG>
      </Box>
      <ModalWrap open={confirmDeletionModal} setOpen={setConfirmDeletionModal}>
        <TextH1 sx={{ mb: '30px', fontSize: '32px' }}>
          Are you sure you want to delete risk "{savedName}"?
        </TextH1>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <ButtonHollow
            sx={{
              flex: 1,
            }}
            onClick={() => {
              setConfirmDeletionModal(false)
            }}
          >
            Cancel
          </ButtonHollow>
          <ButtonDanger
            sx={{
              flex: 1,
              '&.Mui-disabled': {
                border: 'none',
                background: colorsConfig.lightGray,
                color: 'white',
              },
            }}
            disabled={disableDeleteButton}
            onClick={() => {
              handleDeleteRisk()
            }}
          >
            Delete
          </ButtonDanger>
        </Box>
      </ModalWrap>
    </Box>
  )
}

export default EditRisk
