import { createSlice } from '@reduxjs/toolkit'
import { generateUID } from '../../utils/generateUID'
import {
  initialApplicationData,
  insuranceWorkflowsEnums,
} from '../../pages/Insurance/insurance.constants'

const initialState = {
  selectedTab: 0,
  applications: [],
  referenceRisks: [],
  referenceCompanies: [],
}

const insuranceSlice = createSlice({
  name: 'insurance',
  initialState,
  reducers: {
    addApplication: (state) => {
      state.applications.push({
        ...initialApplicationData,
        id: generateUID(),
      })
      state.selectedTab = state.applications.length - 1
    },
    setApplications: (state, action) => {
      state.applications = action.payload
    },
    deleteApplication: (state, action) => {
      state.applications = state.applications.filter((item) => item.id !== action.payload)
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload
    },
    setCollapseBlockState: (state, action) => {
      state.applications.find((item) => item.id === action.payload.id).collapseBlocksState[
        action.payload.block
      ] = action.payload.value
    },
    setApplicationData: (state, action) => {
      state.applications.find((item) => item.id === action.payload.id).data = action.payload.data
    },
    updateApplicationData: (state, action) => {
      const updatedApplication = state.applications.find((item) => item.id === action.payload.id)
      if (updatedApplication) {
        updatedApplication.currentWorkflowState = insuranceWorkflowsEnums.CAR_DETAILS_SAVED
        updatedApplication.collapseBlocksState.carDetailsCollapsed = true
        updatedApplication.collapseBlocksState.carDetailsSaved = true
        updatedApplication.collapseBlocksState.driversDetailsDisabled = false
        updatedApplication.collapseBlocksState.driversCollapsed = false
        updatedApplication.id = action.payload.data.id
      }
    },
    updateCustomerData: (state, action) => {
      const updatedApplication = state.applications[state.selectedTab]
      if (updatedApplication) {
        updatedApplication.customer = action.payload
      }
    },
    setDriverSaved: (state, action) => {
      const updatedApplication = state.applications.find((item) => item.id === action.payload.id)
      if (updatedApplication) {
        updatedApplication.collapseBlocksState.driversSaved = true
        updatedApplication.collapseBlocksState.driversCollapsed = true
        updatedApplication.collapseBlocksState.insuranceDetailsDisabled = false
        updatedApplication.collapseBlocksState.insuranceDetailsCollapsed = false
        updatedApplication.currentWorkflowState = insuranceWorkflowsEnums.DRIVER_DETAILS_SAVED
      }
    },
    setReferenceRisks: (state, action) => {
      state.referenceRisks = action.payload
    },
    setSelectedPackage: (state, action) => {
      const packages = state.applications[state.selectedTab].packages
      if (packages.find((item) => item?.selected)) {
        packages.find((item) => item.selected).selected = false
      }
      packages.find((item) => item.name === action.payload).selected = true
    },
    setSelectedRisks: (state, action) => {
      const updateCustomPackage = state.applications[state.selectedTab].packages[2]
      if (action.payload.isActive) {
        updateCustomPackage.selectedRisks.push(action.payload.risk)
      } else {
        updateCustomPackage.selectedRisks = updateCustomPackage.selectedRisks.filter(
          (item) => item !== action.payload.risk,
        )
      }
    },
    setAllCalculations: (state, action) => {
      const updateApplication = state.applications[state.selectedTab]
      if (updateApplication) {
        updateApplication.packages = updateApplication.packages.map((item, index) => {
          return {
            ...item,
            loaded: true,
            currentPrice: action.payload[index],
          }
        })
      }
    },
    setCustomPackageCalculation: (state, action) => {
      const updateApplication = state.applications[state.selectedTab]
      if (updateApplication) {
        updateApplication.packages[2].currentPrice = action.payload
      }
    },
    updateInsuranceDetails: (state) => {
      const updatedApplication = state.applications[state.selectedTab]
      if (updatedApplication) {
        updatedApplication.currentWorkflowState = insuranceWorkflowsEnums.AWAITING_QUOTES
        updatedApplication.collapseBlocksState.insuranceDetailsCollapsed = true
        updatedApplication.collapseBlocksState.insuranceDetailsSaved = true
        updatedApplication.collapseBlocksState.insurancesResponsesDisabled = true
      }
    },
    setDeals: (state, action) => {
      state.applications[action.payload.index].deals = action.payload.data
    },
    updateDeal: (state, action) => {
      const deals = state.applications[state.selectedTab].deals
      state.applications[state.selectedTab].deals = deals.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload.data
        }
        return item
      })
    },
    setReferenceCompanies: (state, action) => {
      state.referenceCompanies = action.payload
    },
    resetInsuranceApplications: (state) => {
      state.applications = []
      state.selectedTab = 0
    },
  },
})

export const {
  addApplication,
  deleteApplication,
  setSelectedTab,
  setCollapseBlockState,
  setApplicationData,
  updateApplicationData,
  setApplications,
  updateCustomerData,
  setReferenceRisks,
  setSelectedPackage,
  updateInsuranceDetails,
  resetInsuranceApplications,
  setDriverSaved,
  setAllCalculations,
  setSelectedRisks,
  setCustomPackageCalculation,
  setDeals,
  setReferenceCompanies,
  updateDeal,
} = insuranceSlice.actions

export default insuranceSlice.reducer
