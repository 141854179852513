import { getWarrantyCertificate } from '../warrantyApplication.actions'

export default (builder) => {
  builder
    .addCase(getWarrantyCertificate.pending, (state) => {
      state.warrantyCertificate.isLoading = true
    })
    .addCase(getWarrantyCertificate.fulfilled, (state, action) => {
      state.warrantyCertificate.isLoading = false
      state.warrantyCertificate.error = null
      if (action.payload?.updateApplication) {
        state.warrantyApplication = action.payload?.updateApplication
      }
      state.warrantyCertificate.signedUrl = action.payload?.signedUrl
    })
    .addCase(getWarrantyCertificate.rejected, (state, action) => {
      state.warrantyCertificate.isLoading = false
      state.warrantyCertificate.error = action.payload
    })
}
