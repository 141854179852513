import { privateRequest } from '../../utils/request'
import { API_ENDPOINTS } from '../../config/default'

function compare(a, b) {
  if (a.direct < b.direct) {
    return -1
  }
  if (a.direct > b.direct) {
    return 1
  }
  return 0
}

export const getForeshortenings = async () => {
  const result = await privateRequest.get(API_ENDPOINTS.foreshortenings).then(async (response) => {
    const carViews = response.data['hydra:member']
    // const files = carViews.map(item => privateRequest.get(item.file))
    // await Promise.all(files).then(response => {
    //     for (let i = 0; i < carViews.length; i++) {
    //         carViews[i].file = response[i].data
    //     }
    // })
    return carViews
  })
  return result
}

export const getCarParts = async () => {
  const result = await privateRequest
    .get(`${API_ENDPOINTS.carParts}?pagination=false`)
    .then((response) => {
      return response.data['hydra:member']
    })
  return result
}

export const getPilotLamps = async () => {
  const result = await privateRequest.get(API_ENDPOINTS.pilotLamps).then(async (response) => {
    const pilotLamps = response.data['hydra:member']
    // const icons = pilotLamps.map(item => privateRequest.get(item.icon))
    // await Promise.all(icons).then(response => {
    //     for (let i = 0; i < pilotLamps.length; i++) {
    //         pilotLamps[i].icon = response[i].data
    //     }
    // })
    return pilotLamps
  })
  return result
}

export const getControlPoints = async () => {
  const result = await privateRequest.get(API_ENDPOINTS.controlPointsBlock).then((response) => {
    const sortedResult = response.data['hydra:member'].map((block) => {
      if (block.id === 1) {
        const sortedSubblocks = block.subblocks.map((subblock) => {
          if (subblock.id === 1) {
            const sortedControlPoints = subblock.controlPoints.sort(compare)
            return {
              ...subblock,
              controlPoints: sortedControlPoints,
            }
          }
          return subblock
        })
        return {
          ...block,
          subblocks: sortedSubblocks,
        }
      }
      return block
    })
    return sortedResult
  })
  return result
}
