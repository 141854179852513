import React from 'react'
import { Box } from '@mui/material'
import TextNormal from './Text/TextNormal'
import { colorsConfig } from '../config/themeConfig'
import { Clear } from '@mui/icons-material'

const EnhancedTab = (props) => {
  const { index, handleDelete, tabsAmount, text, caption } = props

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          mr: '5px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <TextNormal
          sx={{
            whiteSpace: 'nowrap',
            fontFamily: '"HelveticaNeue", sans-serif',
            fontWeight: 500,
            textAlign: 'left',
          }}
        >
          {text}
        </TextNormal>
        <TextNormal
          sx={{
            whiteSpace: 'nowrap',
            fontFamily: '"HelveticaNeue", sans-serif',
            fontWeight: 500,
            fontSize: '12px',
            color: colorsConfig.mainBlue,
          }}
        >
          {caption}
        </TextNormal>
      </Box>
      <Box
        className={tabsAmount === 1 ? 'disabled' : ''}
        sx={{
          width: '28px',
          height: '28px',
          borderRadius: '50%',
          transition: '0.25s all ease-in-out',
          background: 'transparent',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': {
            transition: '0.25s all ease-in-out',
            background: 'rgba(0,0,0,.1)',
            '& > svg': {
              fill: colorsConfig.darkRed,
              transition: '0.25s all ease-in-out',
            },
          },
          '&.disabled': {
            opacity: '0',
            pointerEvents: 'none',
          },
        }}
        onClick={(e) => {
          e.stopPropagation()
          handleDelete(index)
        }}
      >
        <Clear
          sx={{
            fill: colorsConfig.lightGray,
            fontSize: '20px',
            transition: '0.25s all ease-in-out',
          }}
        />
      </Box>
    </Box>
  )
}

export default EnhancedTab
