import React, { useEffect, useState } from 'react'
import { Box, Table } from '@mui/material'
import { alpha } from '@material-ui/core'
import Preloader from '../../../../components/Preloader'
import CarTableHead from '../../../VirtualGarage/CarTableHead'
import { tableHeadCellsProducts } from '../../../../config/tablesConfig'
import CarTableBody from '../../../VirtualGarage/CarTableBody'
import SimplePagination from '../../../../ui/SimplePagination'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { adminRequest } from '../../../../utils/request'
import { API_FINANCE_ADMIN_ENDPOINTS } from '../../../../config/default'
import { setCurrentPageTitle } from '../../../../store/features/authSlice'
import ButtonPlain from '../../../../ui/Buttons/ButtonPlain'
import { AddRounded } from '@mui/icons-material'
import { colorsConfig } from '../../../../config/themeConfig'

const Products = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [count, setCount] = useState(0)

  const handleSetProducts = (data) => {
    setProducts(
      data.map((item) => {
        return {
          id: item.id,
          link: `/admin/financing/products/${item.id}/edit`,
          cells: [
            {
              type: 'text',
              value: item.title,
            },
            {
              type: 'text',
              value: item.type.title,
            },
          ],
        }
      }),
    )
  }

  const getProducts = () => {
    setLoading(true)
    adminRequest
      .get(`${API_FINANCE_ADMIN_ENDPOINTS.products}?pagination=false`)
      .then((response) => {
        Promise.all(response.data['hydra:member'].map((item) => adminRequest.get(item.type))).then(
          (result) => {
            handleSetProducts(
              response.data['hydra:member'].map((item) => {
                return {
                  ...item,
                  type: result.filter((resultItem) => resultItem.data['@id'] === item.type)[0].data,
                }
              }),
            )
            setCount(response.data['hydra:totalItems'])
            setLoading(false)
          },
        )
      })
  }

  useEffect(() => {
    dispatch(setCurrentPageTitle('Available products'))
    getProducts()
  }, [])

  return (
    <Box>
      <Box
        sx={{
          borderRadius: '6px',
          boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
          px: '30px',
          py: '20px',
          minHeight: '200px',
          position: 'relative',
        }}
      >
        {loading ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: alpha('#FFFFFF', 0.5),
              zIndex: 3,
              opacity: 1,
            }}
          >
            <Preloader type={'gooey'} text={'Loading products...'} />
          </Box>
        ) : null}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            mb: '20px',
          }}
        >
          <ButtonPlain
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => {
              navigate('/admin/financing/products/add')
            }}
          >
            <AddRounded
              sx={{
                fill: colorsConfig.mainBlue,
                mr: '4px',
                fontSize: '21px',
              }}
            />
            Add product
          </ButtonPlain>
        </Box>
        <Table>
          <CarTableHead tableCells={tableHeadCellsProducts} />
          <CarTableBody content={products.slice(page * rowsPerPage, (page + 1) * rowsPerPage)} />
        </Table>
      </Box>
      <SimplePagination
        count={count}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </Box>
  )
}

export default Products
