import React from 'react'
import { TextField } from '@mui/material'
import { colorsConfig } from '../config/themeConfig'

const TextAreaSmall = ({ InputProps, InputLabelProps, inputProps, sx, ...props }) => {
  return (
    <TextField
      {...props}
      size={'small'}
      sx={{
        borderRadius: '4px',
        fontSize: '14px',
        border: 'none',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
          boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
        },
        ...sx,
      }}
      InputLabelProps={{
        ...InputLabelProps,
        sx: {
          color: colorsConfig.iconGray,
          fontWeight: 400,
          fontSize: '14px',
          '&.Mui-focused': {
            background: 'white',
            color: colorsConfig.mainBlue,
            boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
            overflow: 'visible',
          },
        },
      }}
      inputProps={{
        ...inputProps,
        sx: {
          color: colorsConfig.iconGray,
          fontWeight: 400,
          fontSize: '14px',
          border: 'none',
        },
      }}
      InputProps={{
        ...InputProps,
        sx: {
          color: colorsConfig.iconGray,
          fontWeight: 400,
          fontSize: '14px',
          border: 'none',
        },
      }}
    />
  )
}

export default TextAreaSmall
