import React from 'react'
import { Box, Grid, Typography, Button, useMediaQuery } from '@mui/material'
import CarDefaultPicture from '../../assests/img/car-not-found.png'
import { CDN_BASE_URL } from '../../config/default'
import { colorsConfig } from '../../config/themeConfig'
import IconMileage from '../../assests/img/icons/icon-mileage.svg'
import IconCalendar from '../../assests/img/icons/icon-calendar-2.svg'
import IconGear from '../../assests/img/icons/icon-gear.svg'
import TextNormal from '../../ui/Text/TextNormal'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { sumDelimiter } from '../../utils/sumDelimiter'
import AuctionTimer from '../CarPage/AuctionCar/AuctionTimer'
import ImageVerified from '../../assests/img/fleatify-verified.svg'
import IconApple from '../../assests/img/icons/icon-apple.svg'
import { formatDate, getFormattedDate } from '../../utils/date.helpers'
import { CURRENCY, REGION_LOCALE } from '../../utils/constants/global.constants'
import { getCarEnumName } from '../../utils/helpers/enums.helpers'
import { CAR_ENUMS } from '../../utils/constants/enums.constants'

const AuctionCarsItem = ({ auction, displayMode }) => {
  const enumDetails = useSelector((state) => state.ref.enumDetails.data)

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const extraLarge = useMediaQuery('(min-width: 1500px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')
  const navigate = useNavigate()

  const company = useSelector((state) => state.auth.companyId)

  const isUserCar =
    `/admin/companies/${company}` === auction.car.company ||
    `/companies/${company}` === auction.car.company

  const isGreenCar =
    auction?.car?.engineType === 'electric' || auction?.car?.engineType === 'hybrid'
  const isVerified = auction?.car?.isCompounded

  const handleGoToAuctionCar = () => {
    if (isUserCar) {
      navigate(`/cars/${auction.car.id}`)
    } else {
      navigate(`/cars-on-auction/${auction.id}`)
    }
  }

  return (
    <Grid item md={displayMode === 'table' ? 4 : 12} xs={displayMode === 'table' ? 6 : 12}>
      {displayMode === 'table' ? (
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            height: '385px',
            borderRadius: '15px',
            boxShadow: '0 4px 10px 0 rgba(0, 0, 0, .1)',
            overflow: 'hidden',
            cursor: 'pointer',
            position: 'relative',
            '&.xs-only': {
              height: '232px',
              borderRadius: '6px',
            },
          }}
          onClick={() => {
            handleGoToAuctionCar()
          }}
        >
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              position: 'absolute',
              top: '16px',
              right: '13.25px',
              '&.xs-only': {
                top: '7px',
                right: '7px',
              },
            }}
          >
            <AuctionTimer
              startTime={auction.startTime}
              endTime={auction.endTime}
              currentStatus={'auction_workflow.active'}
              mode={'mini'}
            />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              left: '13px',
              top: '16px',
              display: 'flex',
              gap: '5px',
            }}
          >
            {isGreenCar ? (
              <Box
                sx={{
                  width: '36px',
                  height: '36px',
                  borderRadius: '6px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: colorsConfig.mainBlack,
                  '& img': {
                    width: '27px',
                  },
                }}
              >
                <img src={IconApple} alt="" />
              </Box>
            ) : null}
            {isVerified ? (
              <Box
                className={extraLarge ? 'extra-large' : ''}
                sx={{
                  width: '110px',
                  '&.extra-large': {
                    width: '160px',
                  },
                }}
              >
                <img
                  src={ImageVerified}
                  alt=""
                  style={{
                    width: '100%',
                  }}
                />
              </Box>
            ) : null}
          </Box>
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              width: '100%',
              height: '221px',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              '&.xs-only': {
                height: '93px',
              },
            }}
          >
            <img
              src={
                auction.car.mainForeshortening
                  ? `${CDN_BASE_URL}${auction.car.mainForeshortening.photo.path}`
                  : CarDefaultPicture
              }
              alt=""
              style={
                auction.car.mainForeshortening
                  ? {
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }
                  : {
                      width: '75%',
                    }
              }
            />
          </Box>
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              px: '20px',
              pb: '26px',
              '&.xs-only': {
                height: 'calc(100% - 93px)',
                px: '7px',
                pb: '15px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              },
            }}
          >
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Typography
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  fontSize: '18px',
                  fontWeight: 700,
                  fontFamily: '"Helvetica", sans-serif',
                  color: colorsConfig.mainBlack,
                  mt: '20px',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  '&.xs-only': {
                    mt: '8px',
                    fontSize: '16px',
                  },
                }}
              >
                {auction.car.make.name} {auction.car.model.name}
              </Typography>
              <Box
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  mt: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  '&.xs-only': {
                    mt: '8px',
                    width: '100%',
                  },
                }}
              >
                <Box
                  className={xsOnly ? 'xs-only' : ''}
                  sx={{
                    width: 'calc(100% / 3)',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    '&.xs-only': {
                      flexDirection: 'column',
                      '& img': {
                        height: '9px',
                        width: '100%',
                      },
                    },
                  }}
                >
                  <img src={IconMileage} alt="" />
                  <TextNormal
                    className={xsOnly ? 'xs-only' : ''}
                    sx={{
                      ml: '10px',
                      '&.xs-only': {
                        fontSize: '12px',
                        ml: 0,
                      },
                    }}
                  >
                    {auction.car.mileage ? auction.car.mileage : 'N/A'}
                  </TextNormal>
                </Box>
                <Box
                  className={xsOnly ? 'xs-only' : ''}
                  sx={{
                    width: 'calc(100% / 3)',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    '&.xs-only': {
                      flexDirection: 'column',
                      '& img': {
                        height: '9px',
                        width: '100%',
                      },
                    },
                  }}
                >
                  <img src={IconCalendar} alt="" />
                  <TextNormal
                    className={xsOnly ? 'xs-only' : ''}
                    sx={{
                      ml: '10px',
                      '&.xs-only': {
                        fontSize: '12px',
                        ml: 0,
                      },
                    }}
                  >
                    {formatDate(auction?.car?.firstRegistrationDate).slice(3).replace('.', '/')}
                  </TextNormal>
                </Box>
                <Box
                  className={xsOnly ? 'xs-only' : ''}
                  sx={{
                    width: 'calc(100% / 3)',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    '&.xs-only': {
                      flexDirection: 'column',
                      '& img': {
                        height: '9px',
                        width: '100%',
                      },
                    },
                  }}
                >
                  <img src={IconGear} alt="" />
                  <TextNormal
                    className={xsOnly ? 'xs-only' : ''}
                    sx={{
                      ml: '10px',
                      '&.xs-only': {
                        fontSize: '12px',
                        ml: 0,
                      },
                    }}
                  >
                    {auction?.car?.gearbox
                      ? getCarEnumName(auction?.car?.gearbox, CAR_ENUMS.CAR_GEARBOX, enumDetails)
                      : '-'}
                  </TextNormal>
                </Box>
              </Box>
            </Box>
            <Button
              className={xsOnly ? 'xs-only' : ''}
              fullWidth
              sx={{
                mt: '20px',
                color: isUserCar ? colorsConfig.mainBlack : 'white',
                fontFamily: '"Helvetica", sans-serif',
                fontSize: '16px',
                fontWeight: 400,
                textTransform: 'none',
                borderRadius: '4px',
                height: '38px',
                background: isUserCar ? colorsConfig.mainGreen : colorsConfig.mainBlue,
                transition: '0.25s all ease-in-out',
                '&:hover': {
                  background: isUserCar ? colorsConfig.mainGreen : colorsConfig.buttonHover,
                  transition: '0.25s all ease-in-out',
                },
                '&.xs-only': {
                  mt: '10px',
                  fontWeight: 700,
                  fontSize: '14px',
                  height: 'auto',
                  py: '6.75px',
                  lineHeight: 'normal',
                },
              }}
            >
              {isUserCar
                ? 'My car'
                : auction.companyBid
                  ? `Change your bid (${sumDelimiter(auction?.companyBid?.value)} ${CURRENCY[REGION_LOCALE]})`
                  : 'Make a bid'}
            </Button>
          </Box>
        </Box>
      ) : null}
      {displayMode === 'list' ? (
        <Box
          sx={{
            height: '150px',
            borderRadius: '6px',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.1)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            overflow: 'hidden',
            cursor: 'pointer',
            position: 'relative',
          }}
          onClick={() => {
            handleGoToAuctionCar()
          }}
        >
          <Box
            sx={{
              width: '271px',
              height: '100%',
            }}
          >
            <img
              src={
                auction.car.mainForeshortening
                  ? `${CDN_BASE_URL}${auction.car.mainForeshortening?.photo?.path}`
                  : CarDefaultPicture
              }
              alt=""
              style={
                auction.car.mainForeshortening
                  ? {
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }
                  : {
                      width: '75%',
                    }
              }
            />
          </Box>
          <Box
            sx={{
              py: '19px',
              ml: '23px',
            }}
          >
            <Typography
              sx={{
                fontFamily: '"Helvetica", sans-serif',
                fontSize: '18px',
                fontWeight: 700,
                color: colorsConfig.mainBlack,
                mb: '18px',
              }}
            >
              {auction.car.make.name} {auction.car.model.name}
            </Typography>
            <Box
              className={tabletOnly ? 'tablet-only' : ''}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mb: '18px',
                '&.tablet-only': {
                  '& div': {
                    mr: '10px',
                  },
                  '& p': {
                    fontSize: '14px',
                  },
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  mr: '38px',
                }}
              >
                <img src={IconMileage} alt="" />
                <TextNormal sx={{ ml: '10px' }}>
                  {auction.car.mileage ? auction.car.mileage : 'N/A'}
                </TextNormal>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: '38px',
                }}
              >
                <img src={IconCalendar} alt="" />
                <TextNormal sx={{ ml: '10px' }}>
                  {getFormattedDate(auction?.car?.firstRegistrationDate).slice(3) ?? '-'}
                </TextNormal>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <img src={IconGear} alt="" />
                <TextNormal sx={{ ml: '10px' }}>
                  {auction.car.gearbox
                    ? getCarEnumName(auction.car.gearbox, CAR_ENUMS.CAR_GEARBOX, enumDetails)
                    : '-'}
                </TextNormal>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'inline-block',
              }}
            >
              <AuctionTimer
                startTime={auction?.startTime}
                endTime={auction?.endTime}
                currentStatus={'auction_workflow.active'}
                mode={'mini'}
              />
            </Box>
          </Box>
          <Box
            className={tabletOnly ? 'tablet-only' : ''}
            sx={{
              flex: 1,
              ml: '45px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              '&.tablet-only': {
                ml: 0,
                position: 'absolute',
                top: '50%',
                right: '10px',
                mt: '-39px',
              },
            }}
          >
            <Button
              sx={{
                mt: '20px',
                color: isUserCar ? colorsConfig.mainBlack : 'white',
                fontFamily: '"Helvetica", sans-serif',
                fontSize: '16px',
                fontWeight: 400,
                textTransform: 'none',
                borderRadius: '4px',
                maxWidth: '358px',
                minWidth: '130px',
                width: '65%',
                height: '38px',
                background: isUserCar ? colorsConfig.mainGreen : colorsConfig.mainBlue,
                transition: '0.25s all ease-in-out',
                '&:hover': {
                  background: isUserCar ? colorsConfig.mainGreen : colorsConfig.buttonHover,
                  transition: '0.25s all ease-in-out',
                },
              }}
            >
              {isUserCar
                ? 'My car'
                : auction.companyBid
                  ? `Change your bid (${sumDelimiter(auction.companyBid.value)} ${CURRENCY[REGION_LOCALE]})`
                  : 'Make a bid'}
            </Button>
          </Box>
        </Box>
      ) : null}
    </Grid>
  )
}

export default AuctionCarsItem
