import React from 'react'
import DeleteModal from './DeleteModal'
import { useDispatch, useSelector } from 'react-redux'
import { deleteCompanyAddress } from '../../../store/features/settings/settings.actions'

const DeleteLocationModal = ({ open, setOpen, data, onSubmit }) => {
  const isLoading = useSelector((state) => state.settings.companyAddresses.delete.isLoading)
  const dispatch = useDispatch()

  const handleDelete = async () => {
    const res = await dispatch(deleteCompanyAddress({ companyAddressId: data?.id }))
    if (deleteCompanyAddress.fulfilled.match(res)) {
      if (onSubmit) onSubmit()
      setOpen(false)
    }
  }

  return (
    <DeleteModal
      open={open}
      setOpen={setOpen}
      isLoading={isLoading}
      title="modal_title_delete_location"
      description="modal_p_delete_location_description"
      handleSubmit={() => handleDelete()}
    />
  )
}

export default DeleteLocationModal
