export const addWorkingDaysToCurrentDate = (days = 15) => {
  let result = new Date()
  let count = 0

  while (count < days) {
    result.setDate(result.getDate() + 1)
    if (result.getDay() !== 0 && result.getDay() !== 6) {
      count++
    }
  }

  return result
}
