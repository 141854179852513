import { createSlice } from '@reduxjs/toolkit'
import getCustomerAppraisalsBuilder from './builders/getCustomerAppraisals.builder'
import getCustomerAppraisalCarsBuilder from './builders/getCustomerAppraisalCars.builder'

const initialState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  params: {
    page: 1,
    itemsPerPage: 10,
    creationSource: 2,
    sort: {
      order: 'desc',
      key: 'createdAt',
    },
    customerAppraisalExtendedSearch: '',
  },
}

const customerAppraisalsSlice = createSlice({
  name: 'customerAppraisals',
  initialState,
  reducers: {
    setCustomerAppraisalsParams: (state, action) => {
      state.params = action.payload
    },
  },
  extraReducers: (builder) => {
    getCustomerAppraisalsBuilder(builder)
    getCustomerAppraisalCarsBuilder(builder)
  },
})

export const { setCustomerAppraisalsParams } = customerAppraisalsSlice.actions

export default customerAppraisalsSlice.reducer
