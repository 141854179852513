import React, { useEffect } from 'react'
import { Grid, Stack } from '@mui/material'
import { Formik, Form } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { createWarrantyApplication } from '../../../../../store/features/warrantyApplicationSlice/warrantyApplication.actions'
import FormInput from '../../../FormComponents/FormInput'
import FormSelect from '../../../FormComponents/FormSelect'
import { onlyPositiveFloat } from '../../../../../utils/constants/regExp.constants'
import {
  carStateOptions,
  isModifiedOptions,
  modelYearOptions,
} from '../../../../../utils/constants/forms.constants'
import FormDate from '../../../FormComponents/FormDate'
import UITextRegular from '../../../../../ui/UIText/UITextRegular'
import FormRadio from '../../../FormComponents/FormRadio'
import ButtonLG from '../../../../../ui/Buttons/ButtonLG'
import {
  initialWarrantyCarDetailsFormState,
  warrantyCarDetailsFormValidationSchema,
} from './warrantyCarDetailsForm.constants'
import { sumDelimiter } from '../../../../../utils/sumDelimiter'
import { adjustCar } from '../../../../../store/features/carSlice/carSlice'
import { getCarEnumOptionsList } from '../../../../../utils/helpers/optionsList.helpers'
import { CAR_ENUMS } from '../../../../../utils/constants/enums.constants'

const WarrantyCarDetailsForm = () => {
  const warrantyActivated = useSelector((state) => state.warrantyApplication.warrantyDetails.saved)
  const isLoading = useSelector((state) => state.warrantyApplication.carDetails.isLoading)
  const car = useSelector((state) => state.car.car)
  const enumDetails = useSelector((state) => state.ref.enumDetails.data)
  const dispatch = useDispatch()

  const handleSubmit = (values) => {
    const {
      modelYear,
      carState,
      enginePower,
      firstRegistrationDate,
      mileage,
      modelDetails,
      isModified,
    } = values

    dispatch(
      adjustCar({
        ...car,
        modelYear: parseInt(modelYear),
        state: carState,
        details: modelDetails,
        firstRegistrationDate,
        hp: parseInt(enginePower),
        isModified: isModified === 'yes',
      }),
    )

    dispatch(
      createWarrantyApplication({
        car: car?.['@id'],
        independentCarInfoId: car?.independentCarInfo?.id,
        independentCarInfo: {
          modelYear: parseInt(modelYear),
          state: carState,
          mileage: parseInt(mileage),
          details: modelDetails,
          firstRegistrationDate,
          hp: parseInt(enginePower),
          isModified: isModified === 'yes',
        },
        applicationData: {
          car: car?.['@id'],
          currency: 'CHF',
          mileage: parseInt(mileage),
          startDate: new Date(0),
          hp: parseInt(enginePower),
        },
      }),
    )
  }

  return (
    <Formik
      initialValues={initialWarrantyCarDetailsFormState(car)}
      onSubmit={handleSubmit}
      validationSchema={warrantyCarDetailsFormValidationSchema}
    >
      {(props) => (
        <Form>
          <Grid container spacing="30px">
            <Grid xs={12} md={4} item>
              <FormSelect
                required
                id="modelYear"
                name="modelYear"
                label="Model year"
                options={modelYearOptions}
                disabled={isLoading}
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <FormSelect
                required
                id="carState"
                name="carState"
                label="Car state"
                options={getCarEnumOptionsList(CAR_ENUMS.CAR_STATE, enumDetails)}
                disabled={isLoading}
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <FormInput
                required
                id="enginPower"
                name="enginePower"
                label="Engine power"
                suffix="Horse powers"
                maxLength={4}
                inputProps={{
                  inputMode: 'numeric',
                }}
                regExp={onlyPositiveFloat}
                disabled={isLoading}
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <FormDate
                required
                id="firstRegistrationDate"
                name="firstRegistrationDate"
                label="First registration date"
                disabled={isLoading}
                disableFuture
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <FormInput
                id="mileage"
                name="mileage"
                label="Mileage"
                suffix="kms"
                inputProps={{
                  inputMode: 'numeric',
                }}
                regExp={onlyPositiveFloat}
                valueFormatter={sumDelimiter}
                disabled={isLoading}
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <FormInput
                id="modelDetails"
                name="modelDetails"
                label="Model details"
                disabled={isLoading}
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                gap={{ xs: '15px', md: '40px' }}
                alignItems={{ xs: 'flex-start', md: 'center' }}
              >
                <UITextRegular text="Is the car modified?" />
                <FormRadio
                  id="isModified"
                  name="isModified"
                  options={isModifiedOptions}
                  disabled={isLoading}
                />
              </Stack>
            </Grid>
            {!warrantyActivated ? (
              <Grid xs={12} item display="flex" justifyContent="flex-end">
                <ButtonLG
                  type="submit"
                  disabled={isLoading || (props.touched && !props.isValid && props.submitCount > 0)}
                >
                  Save and continue
                </ButtonLG>
              </Grid>
            ) : null}
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default WarrantyCarDetailsForm
