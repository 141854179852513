import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  Grid,
  Pagination,
  TablePagination,
  useMediaQuery,
} from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import iconSearch from '../../assests/img/icons/icon-search.svg'
import useBreakpoints from '../../hooks/useBreakpoints'
import useAllCars from '../../hooks/useAllCars'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentPageTitle } from '../../store/features/authSlice'
import Preloader from '../../components/Preloader'
import { useNavigate } from 'react-router-dom'
import InboxNotificationItem from './InboxNotificationItem'
import { setUnreadNotificationsCount } from '../../store/features/authSlice'
import SelectSmall from '../../ui/SelectSmall'
import ButtonSmall from '../../ui/Buttons/ButtonSmall'
import { MarkEmailReadRounded } from '@mui/icons-material'

const { mainBlack, bgLightBlue, mainGreen } = colorsConfig

const InboxNotifications = () => {
  const dispatch = useDispatch()
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const { isMinWidth1440, isMaxWidth1440 } = useBreakpoints()
  const {
    notificationLogs,
    markNotificationAsRead,
    notificationsLoading,
    markAllNotificationAsRead,
  } = useAllCars()

  const [tab, setTab] = useState(0)
  const [searchLine, setSearchLine] = useState('')
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const sections = [
    {
      id: 1,
      name: `All`,
    },
    {
      id: 2,
      name: `Virtual Garage`,
    },
    {
      id: 3,
      name: `Auctions`,
    },
    {
      id: 4,
      name: `Financing`,
    },
    {
      id: 5,
      name: `Insurance`,
    },
    // {
    //     id: 6,
    //     name: `Car Warranty`,
    // },
    // {
    //     id: 7,
    //     name: `Car Subscription`,
    // },
    // {
    //     id: 8,
    //     name: `Stock Financing`,
    // },
    {
      id: 6,
      name: `Coins`,
    },
    {
      id: 7,
      name: `Other`,
    },
  ]

  const getArrayFilteredByType = (notificationsArray, type) =>
    notificationsArray.filter((notification) => notification.type === type)

  const filterBySearchLine = (notification) => {
    // Filter by searchLine
    const searchValue = searchLine.toLowerCase()

    const { title, description } = notification.translationContext

    return (
      title.toLowerCase().includes(searchValue) || description.toLowerCase().includes(searchValue)
    )
  }

  const filteredNotificationLogs = notificationLogs
    .filter((item) => item.translationContext.title)
    .filter((item) => filterBySearchLine(item))

  const getFilteredNotificationLogs = () => {
    switch (tab) {
      case 0:
        return filteredNotificationLogs
      case 1:
        return getArrayFilteredByType(filteredNotificationLogs, 'virtualGarage')
      case 2:
        return getArrayFilteredByType(filteredNotificationLogs, 'auctionService')
      case 3:
        return getArrayFilteredByType(filteredNotificationLogs, 'financeService')
      case 4:
        return getArrayFilteredByType(filteredNotificationLogs, 'insuranceService')
      case 5:
        return getArrayFilteredByType(filteredNotificationLogs, 'coin')
      case 6:
        return getArrayFilteredByType(filteredNotificationLogs, 'other')
      default:
        return []
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  const getPaginatedData = (data, page, rowsPerPage) => {
    const startIndex = (page - 1) * rowsPerPage
    const endIndex = startIndex + rowsPerPage
    return data.slice(startIndex, endIndex)
  }

  const updatePagination = () => {
    setPage(1) // Reset page to 1 when changing tabs
  }

  useEffect(() => {
    // Update data length and paginated requests when switching tabs
    updatePagination()
  }, [tab, notificationLogs])

  const getPaginatedNotificationLogs = () => {
    return getPaginatedData(getFilteredNotificationLogs(), page, rowsPerPage)
  }

  useEffect(() => {
    dispatch(setCurrentPageTitle('Inbox'))
    getPaginatedNotificationLogs()
  }, [])

  const getDataLength = () => {
    return getFilteredNotificationLogs().length
  }

  useEffect(() => {
    dispatch(setUnreadNotificationsCount(notificationLogs.filter((item) => !item.readAt).length))
  }, [notificationLogs])

  return (
    <Box
      className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
      sx={{
        paddingLeft: '37px',
        marginBottom: '80px',
        '&.xs-only': {
          mb: '40px',
          px: '16px',
          maxWidth: '100vw',
          pt: '25px',
        },
        '&.tablet-only': {
          px: '16px',
          pb: '30px',
          maxWidth: '100vw',
        },
      }}
    >
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          display: 'flex',
          gap: '30px',
          marginBottom: '30px',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&.xs-only': {
            mb: '15px',
            display: 'block',
            gap: 0,
            width: '100%',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '30px',
          }}
        >
          <Typography
            className={xsOnly ? 'xs-only' : ''}
            variant="body1"
            sx={{
              color: mainBlack,
              fontSize: '24px',
              fontWeight: 700,
              '&.xs-only': {
                display: 'none',
              },
            }}
          >
            Search
          </Typography>
          <TextField
            className={xsOnly ? 'xs-only' : ''}
            id="search"
            label="Search"
            size="small"
            value={searchLine}
            onChange={(e) => {
              setSearchLine(e.target.value)
              handleChangePage(e, 1)
            }}
            sx={{
              '& .MuiInputBase-input': {
                width: xsOnly ? '100%' : '205px',
                height: '38px',
                boxSizing: 'border-box',
                borderRadius: '6px',
                borderColor: mainBlack,
                color: mainBlack,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: '6px',
                borderColor: mainBlack,
              },
              '&.xs-only': {
                display: 'block',
                width: '100%',
                '& .MuiInputBase-root': {
                  width: '100%',
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img src={iconSearch} alt="" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {notificationLogs.filter((item) => !item.readAt)[0] ? (
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              '&.xs-only': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                mt: '15px',
              },
            }}
          >
            <ButtonSmall
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '15px',
                px: '10px',
                py: '6px',
                height: '36px',
              }}
              onClick={() => {
                markAllNotificationAsRead()
              }}
            >
              <MarkEmailReadRounded
                sx={{
                  fontSize: '16px',
                  fill: 'white',
                  mr: '4px',
                }}
              />
              Mark all as read
            </ButtonSmall>
          </Box>
        ) : null}
      </Box>
      {xsOnly ? (
        <Box
          sx={{
            mb: '30px',
          }}
        >
          <SelectSmall
            label={''}
            options={sections.map((item) => item.name)}
            value={sections[tab].name}
            setValue={(newVal) => {
              let i
              sections.forEach((item, index) => {
                if (newVal === item.name) {
                  i = index
                }
              })
              setTab(i)
            }}
          />
        </Box>
      ) : (
        <Tabs
          value={tab}
          onChange={(e, newVal) => {
            setTab(newVal)
          }}
          variant="scrollable"
          sx={{
            position: 'relative',
            marginBottom: '40px',
            width: 1,
            maxWidth: isMaxWidth1440 ? '1000px' : 1,
            '& .MuiTabs-indicator': {
              background: mainGreen,
            },
            '& .MuiTab-textColorPrimary': {
              color: mainBlack,
              textTransform: 'none',
              fontFamily: '"Helvetica", sans-serif',
              fontSize: '16px',
              fontWeight: 500,
              paddingLeft: '15px',
            },
            '& .Mui-selected': {
              color: mainBlack,
            },
            '& .MuiButtonBase-root': {
              fontFamily: '"Helvetica", sans-serif',
              background: bgLightBlue,
            },
            ' .MuiButtonBase-root': {
              height: '41px',
              // padding: '0 30px',
            },
            '& .MuiTabScrollButton-root': {
              height: '48px',
            },
            '& .MuiTouchRipple-root': {
              height: '48px',
            },
          }}
        >
          {sections.map((section, index) => {
            return (
              <Tab
                key={index}
                label={section.name}
                sx={{
                  fontSize: '16px',
                  cursor: 'pointer',
                }}
              />
            )
          })}
        </Tabs>
      )}
      <Grid
        container
        className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
        sx={{
          gap: '14px',
          width: isMinWidth1440 ? '1030px' : isMaxWidth1440 ? '910px' : null,
          '&.xs-only': {
            width: 'calc(100vw - 32px)',
          },
          '&.tablet-only': {
            width: 'calc(100vw - 32px)',
          },
        }}
      >
        {notificationsLoading ? (
          <Preloader type="gooey" text={'Loading the notifications...'} />
        ) : (
          notificationLogs &&
          notificationLogs.length > 0 &&
          getPaginatedNotificationLogs().map((notification, index) => {
            return (
              <InboxNotificationItem
                key={index}
                notification={notification}
                markNotificationAsRead={markNotificationAsRead}
              />
            )
          })
        )}

        <Box
          sx={{
            width: 1,
            '& .MuiToolbar-root': {
              paddingLeft: 0,
            },
            '& .MuiTablePagination-spacer': {
              display: 'none',
            },
            '& .MuiTablePagination-displayedRows': {
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'flex-end',
              fontSize: '12px',
            },
            '& .MuiInputBase-root': {
              order: -1,
              margin: 'auto 10px',
              fontSize: '12px',
              '& .MuiSvgIcon-root': {
                fontSize: '16px',
                marginRight: '10px',
              },
              background: 'white',
              border: `1px solid ${colorsConfig.bgLightBlue}`,
              borderRadius: '4px',
              padding: '7px 15px 7px 13px',
            },
            '& .MuiPagination-root': {
              marginLeft: '12px',
            },
            '& .MuiPaginationItem-previousNext': {
              padding: '8px 12px',
              '& .MuiSvgIcon-root': {
                display: 'none',
              },
              "&[aria-label='Go to previous page']": {
                '&::before': {
                  content: "'Previous'",
                },
              },
              "&[aria-label='Go to next page']": {
                '&::before': {
                  content: "'Next'",
                },
              },
              '&.Mui-disabled': {
                opacity: 1,
              },
            },
            '& .MuiPaginationItem-root': {
              background: colorsConfig.bgLightBlue,
              border: 'none',
              fontSize: '12px',

              '&.MuiPaginationItem-previousNext': {
                background: 'white',
                border: `1px solid ${colorsConfig.bgLightBlue}`,
              },
            },
            '& .MuiPaginationItem-page.Mui-selected': {
              background: colorsConfig.mainBlue,
              color: 'white',
              '&:hover': {
                background: 'rgb(21, 101, 192)',
              },
            },
            '& .MuiTablePagination-selectLabel': {
              fontSize: '12px',
            },
            '& .MuiTablePagination-select': {
              // paddingRight: '18px',
            },
            '& .MuiSelect-select': {
              padding: 0,
              paddingRight: '12px',
              '&:focus': {
                background: 'none',
              },
            },
          }}
        >
          <TablePagination
            component="div"
            count={getDataLength()}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton={false}
            showLastButton={false}
            ActionsComponent={() => (
              <>
                <p
                  className="MuiTablePagination-selectLabel css-pdct74-MuiTablePagination-selectLabel"
                  id=":rh:"
                  style={{
                    display: xsOnly ? 'none' : 'flex',
                    order: -2,
                  }}
                >
                  Show
                </p>
                <Pagination
                  count={Math.ceil(getDataLength() / rowsPerPage)}
                  variant="outlined"
                  shape="rounded"
                  page={page}
                  onChange={(event, newPage) => handleChangePage(event, newPage)}
                />
              </>
            )}
            labelRowsPerPage={xsOnly ? '' : 'entries'}
            labelDisplayedRows={({ count }) => {
              if (xsOnly) return ''
              const from = (page - 1) * rowsPerPage + 1
              const to = Math.min(page * rowsPerPage, getDataLength())

              if (!count) return `Show 0 entries`
              return `Show ${from}-${to} of ${count} entries`
            }}
          />
        </Box>
      </Grid>
    </Box>
  )
}

export default InboxNotifications
