import { createAsyncThunk } from '@reduxjs/toolkit'
import productProvidersApiService from '../../../api/financeAdmin/productProvidersApiService'

export const getProductProviders = createAsyncThunk(
  'productProviders/getProductProviders',
  async (_, { rejectWithValue, getState }) => {
    try {
      const params = getState().productProviders.params
      const response = await productProvidersApiService.getDealerProducts(params)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)
