import React from 'react'
import { Stack } from '@mui/material'
import ModalWrap from '../../../components/Modals/ModalWrap'
import UITextHeadlineLarge from '../../../ui/UIText/UITextHeadlineLarge'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import ButtonLG from '../../../ui/Buttons/ButtonLG'

const ConfirmRestitutionModal = ({ open, setOpen, handleAction }) => {
  return (
    <ModalWrap open={open} setOpen={setOpen}>
      <Stack gap="30px">
        <UITextHeadlineLarge text="Are you sure you want to restitute this vehicle?" />
        <Stack gap="10px" direction="row">
          <ButtonHollow sx={{ flex: 1 }} onClick={() => setOpen(false)}>
            Cancel
          </ButtonHollow>
          <ButtonLG sx={{ flex: 1 }} onClick={() => handleAction()}>
            Confirm
          </ButtonLG>
        </Stack>
      </Stack>
    </ModalWrap>
  )
}

export default ConfirmRestitutionModal
