import { getLoanApplicationWorkflowStates } from '../workflows.actions'
import { apiConstants } from '../../../../api/api'

export default (builder) => {
  builder
    .addCase(getLoanApplicationWorkflowStates.pending, (state) => {
      state.loanApplication.isLoading = true
    })
    .addCase(getLoanApplicationWorkflowStates.fulfilled, (state, action) => {
      state.loanApplication.isLoading = false
      state.loanApplication.error = null
      state.loanApplication.data = action.payload?.[apiConstants.hydraMember]
    })
    .addCase(getLoanApplicationWorkflowStates.rejected, (state, action) => {
      state.loanApplication.isLoading = false
      state.loanApplication.error = action.payload
    })
}
