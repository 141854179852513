import React, { useEffect, useState } from 'react'
import VINAutofillModal from '../Modals/VINAutofillModals/VINAutofillModal'
import { useDispatch } from 'react-redux'
import { getCarDetailsFromVIN } from '../../store/features/carSlice/car.actions'
import VINAutofillFailedModal from '../Modals/VINAutofillModals/VINAutofillFailedModal'
import VINAutofillSuccessModal from '../Modals/VINAutofillModals/VINAutofillSuccessModal'
import RequestCoinsModal from '../Modals/RequestCoinsModal'

const VINAutoFill = ({ car, open, setOpen, onSuccess, onError, vinAutofillModalProps }) => {
  const dispatch = useDispatch()
  const [vinAutofillModal, setVinAutofillModal] = useState(false)
  const [vinAutofillFailedModal, setVinAutofillFailedModal] = useState(false)
  const [vinAutofillSuccessModal, setVinAutofillSuccessModal] = useState(false)
  const [requestCoinsModal, setRequestCoinsModal] = useState(false)

  const handleSubmit = async () => {
    const res = await dispatch(getCarDetailsFromVIN({ vin: car?.vin }))
    if (getCarDetailsFromVIN.fulfilled.match(res)) {
      setVinAutofillModal(false)
      setVinAutofillSuccessModal(true)
      setTimeout(() => {
        setVinAutofillSuccessModal(false)
      }, 4000)
      if (onSuccess) onSuccess(res.payload)
    } else if (getCarDetailsFromVIN.rejected.match(res)) {
      setVinAutofillModal(false)
      console.log(res.payload)
      if (res.payload?.status === 402) {
        setRequestCoinsModal(true)
      } else {
        setVinAutofillFailedModal(true)
      }
      if (onError) onError(res.payload)
    }
  }

  useEffect(() => {
    if (open) {
      setVinAutofillModal(true)
      setOpen(false)
    }
  }, [open])

  return (
    <>
      <VINAutofillModal
        open={vinAutofillModal}
        setOpen={() => setVinAutofillModal(false)}
        handleSubmit={handleSubmit}
        {...vinAutofillModalProps}
      />
      <VINAutofillSuccessModal
        open={vinAutofillSuccessModal}
        setOpen={setVinAutofillSuccessModal}
      />
      <VINAutofillFailedModal open={vinAutofillFailedModal} setOpen={setVinAutofillFailedModal} />
      <RequestCoinsModal
        title={'Not enough Fleatify Coins'}
        description={'Purchase Fleatify Coins in order to use that service'}
        open={requestCoinsModal}
        setOpen={setRequestCoinsModal}
      />
    </>
  )
}

export default VINAutoFill
