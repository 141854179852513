import React from 'react'
import { colorsConfig } from '../../config/themeConfig'

const UIIconEdit = ({ color = colorsConfig.mainBlue, width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.655 7.90758C12.2867 7.90758 11.989 8.20603 11.989 8.57359V13.9021C11.989 14.269 11.6905 14.5681 11.323 14.5681H1.99816C1.63047 14.5681 1.33215 14.269 1.33215 13.9021V4.57727C1.33215 4.21029 1.63047 3.91125 1.99816 3.91125H7.32664C7.69496 3.91125 7.99266 3.61284 7.99266 3.24524C7.99266 2.87755 7.69496 2.5791 7.32664 2.5791H1.99816C0.89652 2.5791 0 3.47562 0 4.57727V13.9021C0 15.0037 0.89652 15.9002 1.99816 15.9002H11.323C12.4246 15.9002 13.3211 15.0037 13.3211 13.9021V8.57359C13.3211 8.20531 13.0233 7.90758 12.655 7.90758Z"
        // fill={color}
      />
      <path
        d="M6.24437 7.38536C6.19808 7.43187 6.16637 7.49088 6.15314 7.55515L5.68226 9.91042C5.66031 10.0196 5.69494 10.1321 5.77349 10.2114C5.80449 10.2423 5.84128 10.2668 5.88174 10.2835C5.92221 10.3001 5.96557 10.3087 6.00934 10.3086C6.03057 10.3086 6.05264 10.3066 6.07459 10.302L8.42911 9.83115C8.49437 9.81772 8.55363 9.7865 8.59961 9.73979L13.8695 4.46995L11.5149 2.11555L6.24437 7.38536ZM15.4972 0.487076C14.8479 -0.162359 13.7915 -0.162359 13.1427 0.487076L12.2209 1.40882L14.5754 3.76335L15.4972 2.84148C15.8116 2.52779 15.9848 2.10946 15.9848 1.66456C15.9848 1.21965 15.8116 0.80135 15.4972 0.487076Z"
        //  fill={color}
      />
    </svg>
  )
}

export default UIIconEdit
