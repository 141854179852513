import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  referenceRisks: [],
  referenceCompanies: [],
  application: {
    customer: null,
  },
  deals: [],
  selectedTab: 0,
}

const insuranceOperatorSlice = createSlice({
  name: 'insuranceOperatorReducer',
  initialState,
  reducers: {
    setReferenceRisks: (state, action) => {
      state.referenceRisks = action.payload
    },
    setReferenceCompanies: (state, action) => {
      state.referenceCompanies = action.payload
    },
    setApplication: (state, action) => {
      state.application = action.payload
    },
    setDeals: (state, action) => {
      state.deals = action.payload
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload
    },
    setCurrentDealStatus: (state, action) => {
      state.deals[state.selectedTab].currentStatus = action.payload
    },
    setRiskDetails: (state, action) => {
      state.deals[state.selectedTab].risks[action.payload.index].risksDetails = action.payload.data
    },
    setApprovedData: (state, action) => {
      state.deals[state.selectedTab].approvedData = action.payload
    },
    updateDeal: (state, action) => {
      state.deals[action.payload.index] = action.payload.data
    },
    updatedApprovedParams: (state, action) => {
      state.deals[action.payload.index].approvedParams = action.payload.approvedParams
    },
    resetData: (state) => {
      state.application = initialState.application
      state.deals = initialState.deals
    },
  },
})

export const {
  setApplication,
  setReferenceCompanies,
  setReferenceRisks,
  setDeals,
  setSelectedTab,
  setCurrentDealStatus,
  setRiskDetails,
  setApprovedData,
  updateDeal,
  updatedApprovedParams,
  resetData,
} = insuranceOperatorSlice.actions

export default insuranceOperatorSlice.reducer
