import React from 'react'
import { Typography, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const UICounterVariantColors = {
  success: colorsConfig.mainGreen,
  danger: colorsConfig.mainRed,
  disabled: colorsConfig.lightGray,
}

const UICounter = ({ children, variant = 'success', text }) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <Typography
      className={xsOnly ? 'xs-only' : ''}
      sx={{
        background: UICounterVariantColors[variant],
        width: '20px',
        height: '20px',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '3px',
        fontFamily: '"Helvetica", sans-serif',
        fontSize: '14px',
        fontWeight: 400,
        color: 'white',
      }}
    >
      {children ?? text}
    </Typography>
  )
}

export default UICounter
