import { privateRequest } from '../../utils/request'

class CoinPricingApiService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      coinPricings: '/coin-pricings',
    }
  }

  getCoinPricings = async () => {
    const response = await this.#api.get(this.#endPoints.coinPricings, {
      params: { pagination: false },
    })
    return response
  }

  updateCoinPricing = async (coinPricing, data) => {
    const response = await this.#api.put(coinPricing, data)
    return response
  }
}

const coinPricingApiService = new CoinPricingApiService({ api: privateRequest })

export default coinPricingApiService
