import React, { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
} from '@mui/material'
import Option from './Option'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import IconTriangle from '../../../assests/img/icons/icon-triangle.svg'
import { colorsConfig } from '../../../config/themeConfig'
import { useSelector, useDispatch } from 'react-redux'
import IconAttentionRed from '../../../assests/img/icons/icon-attention-red.svg'
import TextNormal from '../../../ui/Text/TextNormal'
import QualityCheckSubblock from '../../CarPage/QualityCheck/QualityCheckSubblock'
import useBreakpoints from '../../../hooks/useBreakpoints'

const SubBlock = (props) => {
  const { isMinWidth1440 } = useBreakpoints()

  const xsOnly = useMediaQuery('(max-width: 600px)')

  const role = useSelector((state) => state.auth.role)
  const qualityCheckHints = useSelector((state) => state.qualityCheck.hints)

  const { singleSubBlock, subblock, blockIndex, subBlockIndex, readonly } = props

  const [blockExpanded, setBlockExpanded] = useState(false)

  const [counterChecked, setCounterChecked] = useState(0)
  const [counterUnhecked, setCounterUnchecked] = useState(0)
  const [counterDefault, setCounterDefault] = useState(0)
  const [currentHint, setCurrentHint] = useState('')
  const [showHint, setShowHint] = useState(false)

  useEffect(() => {
    if (subblock.id !== 1 || subblock.id !== 2) {
      setCounterChecked(subblock.controlPoints.filter((item) => item.value === true).length)
      setCounterUnchecked(subblock.controlPoints.filter((item) => item.value === false).length)
      setCounterDefault(subblock.controlPoints.filter((item) => item.value === null).length)
    }
  }, [subblock])

  const handleSetShowHint = () => {
    if (role !== 'COMPANY' && showHint) {
      setShowHint(false)
      return
    }
    const matchHint = qualityCheckHints.filter(
      (item) => item.subblock && item.subblock === subblock['@id'],
    )[0]
    const findMultiBlockHint = qualityCheckHints.filter(
      (item) => item.subblock && item.subblock.includes('/reference/appraisal/blocks/3'),
    )[0]
    if ((matchHint && !matchHint.fixed) || findMultiBlockHint) {
      setShowHint(true)
    } else {
      setShowHint(false)
    }
  }

  const handleSetCurrentHint = () => {
    const matchHint = qualityCheckHints.filter(
      (item) => item.subblock && item.subblock === subblock['@id'],
    )[0]
    if (matchHint) {
      setCurrentHint(matchHint.hint)
    }
  }

  useEffect(() => {
    handleSetShowHint()
  }, [qualityCheckHints])

  useEffect(() => {
    handleSetCurrentHint()
  }, [])

  return (
    <React.Fragment>
      {singleSubBlock ? (
        <Box>
          <Grid
            container
            spacing={blockIndex === 1 ? 1 : 3}
            columns={10}
            sx={{
              justifyContent: 'space-between',
              ...(blockIndex === 0
                ? {
                    '& .MuiGrid-item:last-child': {
                      marginLeft: isMinWidth1440 ? 'auto' : null,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-end',
                    },
                  }
                : {}),
            }}
          >
            {subblock.controlPoints.map((option, index) => {
              return (
                <Option
                  key={index}
                  fromSingleSubBlock={true}
                  type={option.type}
                  name={option.name}
                  distinction={option.distinction}
                  blockIndex={blockIndex}
                  subBlockIndex={subBlockIndex}
                  controlPointIndex={index}
                  readonly={readonly ? readonly : false}
                />
              )
            })}
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <Box
            sx={{
              width: role === 'OPERATOR' ? '65%' : showHint ? '80%' : '100%',
            }}
          >
            <Accordion
              className={xsOnly ? 'xs-only' : ''}
              expanded={blockExpanded}
              onChange={() => {
                setBlockExpanded(!blockExpanded)
              }}
              sx={{
                border: 'none',
                boxShadow: 'none',
                '& .MuiAccordionSummary-gutter': {
                  px: 0,
                },
                '&.MuiAccordion-root': {
                  '&::before': {
                    display: 'none',
                  },
                },
                '&.xs-only': {
                  '& .MuiAccordionSummary-root': {
                    px: 0,
                  },
                  '& .MuiAccordionSummary-contentGutters': {
                    mb: '5px',
                  },
                  '& .MuiAccordionDetails-root': {
                    px: 0,
                  },
                },
              }}
            >
              <AccordionSummary expandIcon={<img src={IconTriangle} alt="" />}>
                <Box
                  className={xsOnly ? 'xs-only' : ''}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    flexWrap: 'wrap',
                    '&.xs-only': {
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <TextBoldNormal
                    className={xsOnly ? 'xs-only' : ''}
                    sx={{
                      width: '33%',
                      flexShrink: 0,
                      '&.xs-only': {
                        width: '75%',
                      },
                    }}
                  >
                    {subblock.name}
                  </TextBoldNormal>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      className={xsOnly ? 'xs-only' : ''}
                      sx={{
                        background: colorsConfig.mainGreen,
                        width: '20px',
                        height: '20px',
                        borderRadius: '4px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '3px',
                        fontFamily: '"Helvetica", sans-serif',
                        fontSize: '14px',
                        fontWeight: 400,
                        color: 'white',
                      }}
                    >
                      {counterChecked}
                    </Typography>
                    <Typography
                      sx={{
                        background: colorsConfig.mainRed,
                        width: '20px',
                        height: '20px',
                        borderRadius: '4px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '3px',
                        fontFamily: '"Helvetica", sans-serif',
                        fontSize: '14px',
                        fontWeight: 400,
                        color: 'white',
                      }}
                    >
                      {counterUnhecked}
                    </Typography>
                    <Typography
                      className={xsOnly ? 'xs-only' : ''}
                      sx={{
                        background: colorsConfig.lightGray,
                        width: '20px',
                        height: '20px',
                        borderRadius: '4px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mr: '24px',
                        fontFamily: '"Helvetica", sans-serif',
                        fontSize: '14px',
                        fontWeight: 400,
                        color: 'white',
                        '&.xs-only': {
                          mr: '10px',
                        },
                      }}
                    >
                      {counterDefault}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {subblock.controlPoints.map((option, index) => {
                  return (
                    <Option
                      key={index}
                      fromSingleBlock={false}
                      name={option.name}
                      type={option.type}
                      distinction={option.distinction}
                      blockIndex={blockIndex}
                      subBlockIndex={subBlockIndex}
                      controlPointIndex={index}
                      readonly={readonly ? readonly : false}
                    />
                  )
                })}
              </AccordionDetails>
            </Accordion>
          </Box>
          {role === 'OPERATOR' ? (
            <QualityCheckSubblock
              setBlockExpanded={setBlockExpanded}
              blockExpanded={blockExpanded}
              subBlockId={subblock['@id']}
              subBlockIndex={subBlockIndex}
            />
          ) : null}
          {showHint && currentHint && role === 'COMPANY' ? (
            <Box
              sx={{
                pt: '7px',
                width: '20%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
              }}
            >
              <img src={IconAttentionRed} style={{ marginBottom: '15px' }} alt="" />
              {blockExpanded ? <TextNormal>{currentHint}</TextNormal> : null}
            </Box>
          ) : null}
        </Box>
      )}
    </React.Fragment>
  )
}

export default SubBlock
