import { getWarrantyInvoice } from '../warrantyApplication.actions'

export default (builder) => {
  builder
    .addCase(getWarrantyInvoice.pending, (state) => {
      state.invoice.isLoading = true
    })
    .addCase(getWarrantyInvoice.fulfilled, (state, action) => {
      state.invoice.isLoading = false
      state.invoice.error = null
      if (action.payload?.updateApplication) {
        state.warrantyApplication = action.payload?.updateApplication
      }
      state.invoice.signedUrl = action.payload?.signedUrl
    })
    .addCase(getWarrantyInvoice.rejected, (state, action) => {
      state.invoice.isLoading = false
      state.invoice.error = action.payload
    })
}
