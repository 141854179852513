import { createWarrantyApplication } from '../warrantyApplication.actions'

export default (builder) => {
  builder
    .addCase(createWarrantyApplication.pending, (state) => {
      state.carDetails.isLoading = true
    })
    .addCase(createWarrantyApplication.fulfilled, (state, action) => {
      console.log(action.payload)
      state.warrantyApplication = action.payload
      state.carDetails.isLoading = false
      state.carDetails.error = null
      state.carDetails.open = false
      state.carDetails.saved = true
      state.warrantyDetails.open = true
    })
    .addCase(createWarrantyApplication.rejected, (state, action) => {
      state.carDetails.error = action.payload
      state.carDetails.isLoading = false
    })
}
