import { getWorkflowsStates } from '../workflows.actions'

export default (builder) => {
  builder
    .addCase(getWorkflowsStates.pending, (state) => {
      state.loading = true
    })
    .addCase(getWorkflowsStates.fulfilled, (state, action) => {
      state.loading = false
      state.error = null
      state.insuranceApplicationWorkflowStates = action.payload
    })
    .addCase(getWorkflowsStates.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
}
