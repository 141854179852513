import { deleteCustomerAppraisal } from '../customerAppraisal.actions'

export default (builder) => {
  builder
    .addCase(deleteCustomerAppraisal.pending, (state) => {
      state.delete.isLoading = true
    })
    .addCase(deleteCustomerAppraisal.fulfilled, (state, action) => {
      state.delete.isLoading = false
      state.delete.error = null
    })
    .addCase(deleteCustomerAppraisal.rejected, (state, action) => {
      state.delete.isLoading = false
      state.delete.error = action.payload
    })
}
