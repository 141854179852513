import React, { useState } from 'react'
import { Box } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentDealStatus } from '../../../../store/features/insuranceOperatorSlice'
import TextH1 from '../../../../ui/Text/TextH1'
import ButtonMedium from '../../../../ui/Buttons/ButtonMedium'
import BoxShadowed from '../../../../ui/Layout/BoxShadowed'
import InsuranceRequestFormAccepted from './InsuranceRequestFormAccepted'
import InsuranceRequestFormRefused from './InsuranceRequestFormRefused'
import InsuranceRequestSaved from './InsuranceRequestSaved'

const InsuranceRequestForm = () => {
  const selectedTab = useSelector((state) => state.insuranceOperator.selectedTab)
  const currentStatus = useSelector(
    (state) => state.insuranceOperator.deals[selectedTab]?.currentStatus,
  )
  const currentWorkflowState = useSelector(
    (state) => state.insuranceOperator.deals[selectedTab]?.currentWorkflowState,
  )
  const dispatch = useDispatch()

  const displaySavedRequest =
    currentWorkflowState === 'approved' ||
    currentWorkflowState === 'insurance_company_request_info' ||
    currentWorkflowState === 'insurance_company_declined'

  const displayForm = currentWorkflowState === 'insurance_company_sent'

  return (
    <BoxShadowed sx={{ mt: '20px' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <TextH1
          sx={{
            mr: '20px',
          }}
        >
          {currentWorkflowState === 'insurance_company_sent'
            ? 'Create insurance response'
            : 'Saved insurance response'}
        </TextH1>
        <ButtonMedium
          type={'danger'}
          disabled={currentWorkflowState !== 'insurance_company_sent'}
          className={currentStatus === 'Refused' ? '' : 'inactive'}
          sx={{
            mr: '10px',
          }}
          onClick={() => {
            dispatch(setCurrentDealStatus('Refused'))
          }}
        >
          Refused
        </ButtonMedium>
        <ButtonMedium
          type={'success'}
          disabled={currentWorkflowState !== 'insurance_company_sent'}
          className={currentStatus === 'Accepted' ? '' : 'inactive'}
          sx={{
            mr: '10px',
          }}
          onClick={() => {
            dispatch(setCurrentDealStatus('Accepted'))
          }}
        >
          Accepted
        </ButtonMedium>
        <ButtonMedium
          type={'attention'}
          disabled={currentWorkflowState !== 'insurance_company_sent'}
          className={currentStatus === 'Request info' ? '' : 'inactive'}
          onClick={() => {
            dispatch(setCurrentDealStatus('Request info'))
          }}
        >
          Request info
        </ButtonMedium>
      </Box>
      {displayForm ? (
        <>
          {currentStatus === 'Accepted' || currentStatus === 'Request info' ? (
            <InsuranceRequestFormAccepted />
          ) : null}
          {currentStatus === 'Refused' ? <InsuranceRequestFormRefused /> : null}
        </>
      ) : null}
      {displaySavedRequest ? <InsuranceRequestSaved /> : null}
    </BoxShadowed>
  )
}

export default InsuranceRequestForm
