export const tableHeadInsuranceCompanies = [
  {
    title: 'ID',
    sortable: false,
  },
  {
    title: 'Name',
    sortable: false,
  },
  {
    title: 'Email',
    sortable: false,
  },
  {
    title: 'Updated at',
    sortable: false,
  },
]
