import React, { useState } from 'react'

const AccordionItem = (props) => {
  const { title, content } = props

  const [open, setOpen] = useState(false)

  return (
    <div className={`accordion-item ${open ? 'open' : ''}`}>
      <div
        className="accordion-item-title"
        onClick={() => {
          setOpen(!open)
        }}
      >
        {title}
      </div>
      <div className="accordion-item-content">{content}</div>
    </div>
  )
}

export default AccordionItem
