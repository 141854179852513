import { privateRequest } from '../../utils/request'

class CoveredComponentsService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      coveredComponents: '/reference/warranty/covered-components',
      coveredComponentById: (id) => `/reference/warranty/covered-components/${id}`,
    }
  }

  getCoveredComponents = async () => {
    const response = await this.#api.get(this.#endPoints.coveredComponents, {
      params: {
        pagination: false,
      },
    })
    return response
  }

  getCoveredComponentById = async (id) => {
    const response = await this.#api.get(this.#endPoints.coveredComponentById(id))
    return response
  }

  createCoveredComponent = async (data) => {
    const response = await this.#api.post(this.#endPoints.coveredComponents, data)
    return response
  }

  updateCoveredComponent = async (id, data) => {
    const response = await this.#api.put(this.#endPoints.coveredComponentById(id), data)
    return response
  }

  deleteCoveredComponent = async (id) => {
    const response = await this.#api.delete(this.#endPoints.coveredComponentById(id))
    return response
  }
}

const coveredComponentsService = new CoveredComponentsService({ api: privateRequest })

export default coveredComponentsService
