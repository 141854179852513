import { rviApproveRestitution } from '../rviOperator.actions'

export default (builder) => {
  builder
    .addCase(rviApproveRestitution.pending, (state) => {
      state.approveRestitution.isLoading = true
    })
    .addCase(rviApproveRestitution.fulfilled, (state, action) => {
      state.approveRestitution.isLoading = false
      state.approveRestitution.error = null
    })
    .addCase(rviApproveRestitution.rejected, (state, action) => {
      state.approveRestitution.isLoading = false
      state.approveRestitution.error = action.payload
    })
}
