import React from 'react'
import { colorsConfig } from '../../../config/themeConfig'
import { Box, Stack } from '@mui/material'
import UIIconButton from '../../../ui/UIButtons/UIIconButton'
import { RemoveRounded } from '@mui/icons-material'
import { UIVariant } from '../../../utils/constants/ui.constants'

const RVActionsCell = () => {
  return (
    <Box
      sx={{
        flex: 1,
        borderRight: '1px solid',
        borderColor: colorsConfig.buttonDisabled,
        px: '10px',
        '&:last-child': {
          border: 'none',
        },
      }}
    >
      <Stack
        direction="row"
        gap="10px"
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100%' }}
      >
        <UIIconButton text="Delete row" variant={UIVariant.danger}>
          <RemoveRounded />
        </UIIconButton>
      </Stack>
    </Box>
  )
}

export default RVActionsCell
