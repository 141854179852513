import React, { useState } from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { useSelector } from 'react-redux'
import TextNormal from '../../ui/Text/TextNormal'
import { useTranslation } from 'react-i18next'

const CarSteps = (props) => {
  const { block } = props

  const [showSteps, setShowSteps] = useState(false)

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')
  const { t } = useTranslation()

  const combinedStatus = useSelector((state) => state.car.carData.combinedStatus)

  return (
    <Box
      className={[
        xsOnly ? 'xs-only' : '',
        showSteps ? 'active' : '',
        tabletOnly ? 'tablet-only' : '',
      ].join(' ')}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        position: 'relative',
        '&::after': {
          content: '" "',
          position: 'absolute',
          display: 'block',
          height: '1px',
          width: '100%',
          background: colorsConfig.mainBlack,
          top: '24px',
          left: 0,
        },
        '&::before': {
          content: '" "',
          position: 'absolute',
          display: 'block',
          height: '5px',
          width: '5px',
          borderRight: '1px solid',
          borderBottom: '1px solid',
          borderColor: colorsConfig.mainBlack,
          right: '-2px',
          top: '18.5px',
          transform: 'rotate(-45deg)',
          transformOrigin: '100% 100%',
        },
        mb: '40px',
        '&.xs-only, &.tablet-only': {
          mb: '20px',
          mt: '20px',
          height: '95px',
          overflow: 'hidden',
          display: 'block',
          transition: '0.3s all ease-in-out',
          '&::after': {
            display: 'none',
          },
          '&::before': {
            display: 'none',
          },
          '&.active': {
            height: 'auto',
            transition: '0.3s all ease-in-out',
          },
        },
      }}
    >
      {xsOnly || tabletOnly ? (
        <Box
          className={showSteps ? 'open' : ''}
          sx={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            height: '45px',
            background: `linear-gradient(0deg, ${colorsConfig.bgLightBlue} 0%, ${colorsConfig.bgLightBlue} 40%, transparent 100%)`,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            '&.open': {
              background: 'none',
              height: '25px',
            },
          }}
          onClick={() => {
            setShowSteps(!showSteps)
          }}
        >
          <TextNormal
            sx={{
              fontSize: '14px',
              cursor: 'pointer',
            }}
          >
            {showSteps ? 'Less...' : 'More...'}
          </TextNormal>
        </Box>
      ) : null}
      {block.steps.map((item, index) => {
        const active = !item.inactive.filter((item) => item === combinedStatus)[0]
        return (
          <Box
            className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
            key={index}
            sx={{
              width: '25%',
              paddingRight: '25px',
              '&.xs-only, &.tablet-only': {
                width: '100%',
                pr: 0,
                mb: '15px',
              },
            }}
          >
            <Box
              className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mb: '15px',
                '&.xs-only, &.tablet-only': {
                  mb: '10px',
                },
              }}
            >
              <Box
                className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
                sx={{
                  width: '25px',
                  height: '24px',
                  borderRadius: '6px 6px 0 0',
                  background: active ? colorsConfig.mainBlack : colorsConfig.buttonDisabled,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '&.xs-only, &.tablet-only': {
                    borderRadius: '6px',
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: active ? 'white' : colorsConfig.iconGray,
                  }}
                >
                  {index + 1}
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontWeight: 700,
                  color: active ? colorsConfig.mainBlack : colorsConfig.lightGray,
                  fontSize: '16px',
                  ml: '5px',
                  whiteSpace: 'nowrap',
                }}
              >
                {t(item.name)}
              </Typography>
            </Box>
            <Typography
              className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                color: active ? colorsConfig.mainBlack : colorsConfig.lightGray,
                mt: '18px',
                '&.xs-only, &.tablet-only': {
                  mt: '0px',
                },
              }}
            >
              {t(item.description)}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}

export default CarSteps
