import { getWarrantyApplications } from '../warrantyApplications.actions'
import { apiConstants } from '../../../../api/api'

export default (builder) => {
  builder
    .addCase(getWarrantyApplications.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getWarrantyApplications.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = null
      state.warrantyApplications = action.payload?.[apiConstants.hydraMember]
    })
    .addCase(getWarrantyApplications.rejected, (state, action) => {
      state.error = action.payload
      state.isLoading = false
    })
}
