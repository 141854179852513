import React, { useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import TextFieldSmallEdible from '../../../../../ui/TextFieldSmallEdible'
import ButtonHollow from '../../../../../ui/Buttons/ButtonHollow'
import ButtonLG from '../../../../../ui/Buttons/ButtonLG'
import UIInputAdornmentText from '../../../../../ui/UIFormComponents/UIInputAdornmentText'
import { formErrors } from '../../formErrors'
import { useDispatch, useSelector } from 'react-redux'
import { createEntry } from '../../../../../store/features/residualValueMatrixEntries/residualValueMatrixEntries.actions'
import { toast } from 'react-toastify'
import UIPreloader from '../../../../../ui/UIPreloader/UIPreloader'

const AddRVEntryForm = ({ onClose }) => {
  const isSaving = useSelector((state) => state.residualValueMatrixEntries.create.isLoading)
  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    term: '',
    contractEndCarAgeMin: '',
    contractEndCarAgeMax: '',
    contractEndMileageMin: '',
    contractEndMileageMax: '',
    residualValueGross: '',
    residualValueNet: '',
  })
  const [showErrors, setShowErrors] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [errors, setErrors] = useState({})

  const handleValidate = () => {
    const {
      term,
      contractEndCarAgeMin,
      contractEndCarAgeMax,
      contractEndMileageMin,
      contractEndMileageMax,
      residualValueGross,
      residualValueNet,
    } = formData
    let isValid = true
    if (!term) isValid = false
    if (!contractEndCarAgeMin) isValid = false
    if (!contractEndCarAgeMax) isValid = false
    if (!contractEndMileageMin) isValid = false
    if (!contractEndMileageMax) isValid = false
    if (!residualValueGross) isValid = false
    if (!residualValueNet) isValid = false
    setIsValid(isValid)
    return isValid
  }

  const handleSubmit = async () => {
    if (!handleValidate()) {
      setShowErrors(true)
      setIsValid(false)
      return null
    }
    const data = {
      ...formData,
      term: parseInt(formData.term),
      contractEndCarAgeMin: parseInt(formData.contractEndCarAgeMin),
      contractEndCarAgeMax: parseInt(formData.contractEndCarAgeMax),
      contractEndMileageMin: parseInt(formData.contractEndMileageMin),
      contractEndMileageMax: parseInt(formData.contractEndMileageMax),
    }
    const res = await dispatch(createEntry({ data }))
    if (createEntry.fulfilled.match(res)) {
      toast.success('Row has been added successfully')
      onClose()
    }
  }

  useEffect(() => {
    if (showErrors) {
      handleValidate()
    }
  }, [showErrors, formData])

  return (
    <Stack sx={{ position: 'relative' }} gap="30px">
      {isSaving && <UIPreloader text="Saving data..." />}
      <TextFieldSmallEdible
        label="Duration"
        value={formData.term}
        onChange={(e) => setFormData({ ...formData, term: e.target.value.replace(/[^0-9]/g, '') })}
        inputProps={{ inputMode: 'numeric' }}
        InputProps={{ endAdornment: <UIInputAdornmentText text="months" /> }}
        error={!formData.term && showErrors}
        helperText={!formData.term && showErrors ? formErrors.required : ''}
      />
      <TextFieldSmallEdible
        label="Car age, min"
        value={formData.contractEndCarAgeMin}
        onChange={(e) =>
          setFormData({ ...formData, contractEndCarAgeMin: e.target.value.replace(/[^0-9]/g, '') })
        }
        inputProps={{ inputMode: 'numeric' }}
        InputProps={{ endAdornment: <UIInputAdornmentText text="months" /> }}
        error={!formData.contractEndCarAgeMin && showErrors}
        helperText={!formData.contractEndCarAgeMin && showErrors ? formErrors.required : ''}
      />
      <TextFieldSmallEdible
        label="Car age, max"
        value={formData.contractEndCarAgeMax}
        onChange={(e) =>
          setFormData({ ...formData, contractEndCarAgeMax: e.target.value.replace(/[^0-9]/g, '') })
        }
        inputProps={{ inputMode: 'numeric' }}
        InputProps={{ endAdornment: <UIInputAdornmentText text="months" /> }}
        error={!formData.contractEndCarAgeMax && showErrors}
        helperText={!formData.contractEndCarAgeMax && showErrors ? formErrors.required : ''}
      />
      <TextFieldSmallEdible
        label="Total mileage, min"
        value={formData.contractEndMileageMin}
        onChange={(e) =>
          setFormData({
            ...formData,
            contractEndMileageMin: e.target.value.replace(/[^0-9.]/g, ''),
          })
        }
        inputProps={{ inputMode: 'numeric' }}
        InputProps={{ endAdornment: <UIInputAdornmentText text="km" /> }}
        error={!formData.contractEndMileageMin && showErrors}
        helperText={!formData.contractEndMileageMin && showErrors ? formErrors.required : ''}
      />
      <TextFieldSmallEdible
        label="Total mileage, max"
        value={formData.contractEndMileageMax}
        onChange={(e) =>
          setFormData({
            ...formData,
            contractEndMileageMax: e.target.value.replace(/[^0-9.]/g, ''),
          })
        }
        inputProps={{ inputMode: 'numeric' }}
        InputProps={{ endAdornment: <UIInputAdornmentText text="km" /> }}
        error={!formData.contractEndMileageMax && showErrors}
        helperText={!formData.contractEndMileageMax && showErrors ? formErrors.required : ''}
      />
      <TextFieldSmallEdible
        label="RV from Gross price"
        value={formData.residualValueGross}
        onChange={(e) =>
          setFormData({ ...formData, residualValueGross: e.target.value.replace(/[^0-9.]/g, '') })
        }
        inputProps={{ inputMode: 'numeric' }}
        InputProps={{ endAdornment: <UIInputAdornmentText text="%" /> }}
        error={!formData.residualValueGross && showErrors}
        helperText={!formData.residualValueGross && showErrors ? formErrors.required : ''}
      />
      <TextFieldSmallEdible
        label="RV from Net price"
        value={formData.residualValueNet}
        onChange={(e) =>
          setFormData({ ...formData, residualValueNet: e.target.value.replace(/[^0-9.]/g, '') })
        }
        inputProps={{ inputMode: 'numeric' }}
        InputProps={{ endAdornment: <UIInputAdornmentText text="%" /> }}
        error={!formData.residualValueNet && showErrors}
        helperText={!formData.residualValueNet && showErrors ? formErrors.required : ''}
      />
      <Stack gap="10px" direction="row" alignItems="center" sx={{ '& button': { flex: 1 } }}>
        <ButtonHollow onClick={() => onClose()}>Cancel</ButtonHollow>
        <ButtonLG disabled={showErrors && !isValid} onClick={() => handleSubmit()}>
          Add
        </ButtonLG>
      </Stack>
    </Stack>
  )
}

export default AddRVEntryForm
