import { privateRequest } from '../../utils/request'

class AppraisalService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      controlPoints: {
        deleteFileCollectionFile: (id) => `/file-collection-discriminator-file/${id}`,
      },
    }
  }

  deleteFileCollectionFile = async (fileId) => {
    const response = await this.#api.delete(
      this.#endPoints.controlPoints.deleteFileCollectionFile(fileId),
    )
    return response
  }
}

const appraisalService = new AppraisalService({ api: privateRequest })

export default appraisalService
