import React, { forwardRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useValidate from '../../hooks/useValidate'
import { privateRequest } from '../../utils/request'
import { API_ENDPOINTS } from '../../config/default'
import { toast } from 'react-toastify'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import TextFieldSmall from '../../ui/TextFieldSmall'
import { sumDelimiter } from '../../utils/sumDelimiter'
import TextAreaSmall from '../../ui/TextAreaSmall'
import AnimationSendToCompoundModal from '../../components/Modals/AnimationSendToCompoundModal'
import TextFieldSmallEdible from '../../ui/TextFieldSmallEdible'
import ModalWrap from '../../components/Modals/ModalWrap'
import TextH1 from '../../ui/Text/TextH1'
import TextNormal from '../../ui/Text/TextNormal'
import ButtonLG from '../../ui/Buttons/ButtonLG'
import { countries } from '../../default-data/coutries'
import { emirates } from '../../config/formsConfig'

const CustomListBox = forwardRef(function CustomListBox(props, ref) {
  return (
    <Paper
      {...props}
      ref={ref}
      id="custom-listbox"
      sx={{
        width: 'max-content',
      }}
    />
  )
})

const expandAccordionIconBig = (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
    <path
      d="M6.67377 9.7882C7.07074 10.3704 7.92926 10.3704 8.32623 9.7882L13.9341 1.56334C14.3867 0.899527 13.9113 0 13.1079 0H1.89214C1.08872 0 0.613315 0.899529 1.06591 1.56334L6.67377 9.7882Z"
      fill="#3D3D3D"
    />
  </svg>
)

const SendToCompoundModal = (props) => {
  const { open, setOpen, onFinish, car } = props

  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const currentDate = new Date()

  const user = useSelector((state) => state.auth.user)

  const [isAddressAccordionOpen, setIsAddressAccordionOpen] = useState(false)
  const [date, setDate] = useState(null)
  const [time, setTime] = useState(null)
  const [location, setLocation] = useState(null)
  const [contactName, setContactName] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [contactPhone, setContactPhone] = useState('')
  const [mileage, setMileage] = useState(0)
  const [additionalInfo, setAdditionalInfo] = useState('')
  const [disableButton, setDisableButton] = useState(false)
  const [animationModal, setAnimationModal] = useState(false)

  const validateDate = useValidate(date, { isEmpty: true, isValidDate: true })
  const validateTime = useValidate(time, { isEmpty: true, isValidDate: true })
  const validateContactName = useValidate(contactName, { isEmpty: true })
  const validateContactPhone = useValidate(contactPhone, { isEmpty: true, exactLength: 9 })
  const validateContactEmail = useValidate(contactEmail, { isEmpty: true, email: true })
  const validateMileage = useValidate(mileage, { isEmpty: true })

  const [showErrors, setShowErrors] = useState(false)
  const [country, setCountry] = useState(countries.find((item) => item.code === 'AE'))
  const [state, setState] = useState('')
  const [postcode, setPostcode] = useState('')
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')
  const [comment, setComment] = useState('')
  const [locationsInfo, setLocationsInfo] = useState([])
  const [addressTypes, setAddressTypes] = useState([])

  const handleAddressAccordionToggle = () => {
    setIsAddressAccordionOpen((prevIsOpen) => !prevIsOpen)
  }

  function transformDateFormat(inputDate) {
    const date = new Date(inputDate)
    const year = date.getUTCFullYear()
    const month = String(date.getUTCMonth() + 1).padStart(2, '0')
    const day = String(date.getUTCDate()).padStart(2, '0')
    const hours = String(date.getUTCHours()).padStart(2, '0')
    const minutes = String(date.getUTCMinutes()).padStart(2, '0')
    const seconds = String(date.getUTCSeconds()).padStart(2, '0')
    const timezoneOffsetHours = String(Math.abs(date.getTimezoneOffset() / 60)).padStart(2, '0')
    const timezoneOffsetMinutes = String(Math.abs(date.getTimezoneOffset() % 60)).padStart(2, '0')
    const timezoneOffsetSign = date.getTimezoneOffset() < 0 ? '+' : '-'
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneOffsetSign}${timezoneOffsetHours}:${timezoneOffsetMinutes}`
    return formattedDate
  }

  const hasErrors = () => {
    if (validateDate.hasErrors) return true
    if (validateTime.hasErrors) return true
    if (validateContactName.hasErrors) return true
    if (
      validateContactPhone.hasErrors &&
      contactPhone !== user?.manager?.phoneNumber.replace('971', '')
    )
      return true
    if (validateContactEmail.hasErrors) return true
    if (validateMileage.hasErrors) return true
    if (!isAddressAccordionOpen && !location) return true
    if (isAddressAccordionOpen && (!country || !state || !addressLine1)) return true
    return false
  }

  const handleAddNewLocation = async (isMain) => {
    const addressTypeIri = addressTypes.filter((typeItem) => typeItem.name === 'Pickup')[0]['@id']

    const formData = {
      type: addressTypeIri,
      country: country?.label,
      state,
      postcode,
      addressLine1,
      addressLine2,
      comment,
    }

    const response = await privateRequest.post(API_ENDPOINTS.newAddress, formData)

    const id = response.data.id
    const addressIri = `addresses/${id}`

    await privateRequest.post(API_ENDPOINTS.companyAddresses, {
      address: addressIri,
      isMain: isMain,
    })

    return addressIri
  }

  const handleSubmit = async () => {
    if (hasErrors()) {
      setShowErrors(true)
      return
    }
    setDisableButton(true)
    const currentDate = new Date(date['$d'])
    const currentTimeDate = new Date(time['$d'])
    currentDate.setHours(currentTimeDate.getHours())
    currentDate.setMinutes(currentTimeDate.getMinutes())
    let newLocationCopy
    if (isAddressAccordionOpen) {
      newLocationCopy = await handleAddNewLocation(false)
      await handleAddNewLocation(true)
    }
    const formData = {
      address: isAddressAccordionOpen ? newLocationCopy : location['@id'],
      phone: contactPhone,
      email: contactEmail,
      contactPerson: contactName,
      date: transformDateFormat(currentDate),
      mileage: parseFloat(mileage),
      additionalInformation: additionalInfo,
    }
    privateRequest
      .post(`${car['@id']}${API_ENDPOINTS.requestCompound}`, formData)
      .then(() => {
        setAnimationModal(true)
        setTimeout(() => {
          if (onFinish) onFinish()
          setAnimationModal(false)
        }, 3000)
      })
      .catch(() => {
        toast.error('An error occurred while applying for pickup')
      })
      .finally(() => {
        setDisableButton(false)
      })
  }

  const getAddressTypes = () => {
    privateRequest.get(API_ENDPOINTS.addressTypes).then((response) => {
      setAddressTypes(response.data['hydra:member'])
    })
  }

  const handleSetPickupLocations = (data) => {
    setLocationsInfo(
      data
        .map((item) => {
          return {
            ...item.address,
            addressType: !item.address.type
              ? 'All'
              : addressTypes.find((type) => type['@id'] === item.address.type)?.name,
          }
        })
        .filter((item) => item.addressType === 'All' || item.addressType === 'Pickup'),
    )
  }

  const getCompanyAddresses = () => {
    privateRequest.get(API_ENDPOINTS.companyAddresses).then((response) => {
      handleSetPickupLocations(response.data['hydra:member'])
    })
  }

  useEffect(() => {
    if (addressTypes.length > 0) {
      getCompanyAddresses()
    }
  }, [addressTypes])

  useEffect(() => {
    if (car) setMileage(car.mileage)
  }, [car])

  useEffect(() => {
    if (user?.email) setContactEmail(user?.email)
    if (user?.manager?.firstName && user?.manager?.surname)
      setContactName(`${user?.manager?.firstName} ${user?.manager?.surname}`)
    if (user?.manager?.phoneNumber) setContactPhone(user?.manager?.phoneNumber.replace('971', ''))
  }, [user])

  useEffect(() => {
    if (locationsInfo.length > 0) {
      setLocation(locationsInfo[0])
    }
  }, [locationsInfo])

  useEffect(() => {
    getAddressTypes()
    getCompanyAddresses()
  }, [])

  return (
    <ModalWrap
      open={open}
      setOpen={setOpen}
      wrapperStyles={{
        width: '1029px',
        maxHeight: 'calc(100vh - 50px)',
        '&.tablet-only': {
          width: '80vw',
        },
      }}
    >
      <TextH1
        sx={{
          fontSize: '32px',
          mb: '25px',
        }}
      >
        Appraisal on compound request
      </TextH1>
      <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
        <Grid container spacing={'18px'}>
          <Grid item xs={12} sm={6} md={3}>
            <Stack spacing={'18px'}>
              <DesktopDatePicker
                label="Pickup Date"
                inputFormat="DD/MM/YYYY"
                mask="__/__/____"
                minDate={currentDate}
                value={date}
                onChange={(newValue) => {
                  setDate(newValue)
                }}
                renderInput={(params) => (
                  <TextFieldSmallEdible
                    {...params}
                    fullWidth
                    required
                    error={showErrors && validateDate.hasErrors}
                    helperText={showErrors && validateDate.hasErrors ? 'Invalid date' : ''}
                  />
                )}
              />
              <TimePicker
                fullWidth
                label="Pickup Time"
                value={time}
                onChange={(newValue) => {
                  setTime(newValue)
                }}
                renderInput={(params) => (
                  <TextFieldSmall
                    required
                    fullWidth
                    {...params}
                    error={showErrors && validateTime.hasErrors}
                    helperText={showErrors && validateTime.hasErrors ? 'Invalid time' : ''}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Stack spacing={'18px'}>
              <TextFieldSmallEdible
                fullWidth
                required
                type="text"
                label="Contact Name"
                value={contactName}
                onChange={(e) => {
                  setContactName(e.target.value)
                }}
                error={showErrors && validateContactName.hasErrors}
                helperText={
                  showErrors && validateContactName.hasErrors ? 'This field can not be empty' : ''
                }
              />
              <TextFieldSmallEdible
                fullWidth
                type="text"
                required
                inputProps={{
                  inputMode: 'numeric',
                }}
                label="Contact Phone"
                value={contactPhone}
                onChange={(e) => {
                  if (e.target.value.length <= 9) {
                    setContactPhone(e.target.value.replace(/[^0-9+]/g, ''))
                  }
                }}
                error={showErrors && validateContactPhone.hasErrors}
                helperText={showErrors && validateContactPhone.hasErrors ? 'Invalid phone' : ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <TextNormal
                        sx={{
                          fontSize: '14px',
                          color: '#757575',
                          mr: '5px',
                        }}
                      >
                        + 971
                      </TextNormal>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Stack spacing={'18px'}>
              <TextFieldSmallEdible
                fullWidth
                required
                type="email"
                label="Contact Email"
                value={contactEmail}
                onChange={(e) => {
                  setContactEmail(e.target.value)
                }}
                error={showErrors && validateContactEmail.hasErrors}
                helperText={showErrors && validateContactEmail.hasErrors ? 'Invalid email' : ''}
              />
              <TextFieldSmallEdible
                fullWidth
                required
                type="text"
                label="Mileage (km)"
                inputProps={{
                  inputMode: 'numeric',
                }}
                value={sumDelimiter(mileage)}
                onChange={(e) => {
                  setMileage(e.target.value.replace(/[^0-9]/g, ''))
                }}
                error={showErrors && validateMileage.hasErrors}
                helperText={
                  showErrors && validateMileage.hasErrors ? 'This field can not be empty' : ''
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextAreaSmall
              fullWidth
              label="Additional Information"
              multiline
              rows={3}
              value={additionalInfo}
              onChange={(e) => {
                setAdditionalInfo(e.target.value)
              }}
              sx={{
                '& .MuiInputBase-root': {
                  height: '92px',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              required
              fullWidth
              id="address-pickup"
              options={locationsInfo}
              disabled={isAddressAccordionOpen}
              getOptionLabel={(item) =>
                [item.addressType, item.addressLine1, item.state, item.country, item.postcode].join(
                  ', ',
                )
              }
              value={location}
              onChange={(e, newVal) => {
                setLocation(newVal)
              }}
              renderInput={(params) => (
                <TextFieldSmallEdible
                  {...params}
                  label="Pickup location"
                  required={!isAddressAccordionOpen}
                  error={showErrors && !location && !isAddressAccordionOpen}
                  helperText={
                    showErrors && !location && !isAddressAccordionOpen
                      ? 'This field can not be empty'
                      : ''
                  }
                />
              )}
              renderOption={(props, { addressType, addressLine1, state, country, postcode }) => (
                <li {...props}>
                  <Box sx={{ display: 'flex', gap: '4px' }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 700, width: 'max-content' }}>
                      {addressType}
                    </Typography>
                    <Typography sx={{ fontSize: '14px', width: 'fit-content' }}>
                      {addressLine1}
                    </Typography>
                    <Typography sx={{ fontSize: '14px', width: 'fit-content' }}>{state}</Typography>
                    <Typography sx={{ fontSize: '14px', width: 'fit-content' }}>
                      {country}
                    </Typography>
                    <Typography sx={{ fontSize: '14px', width: 'fit-content' }}>
                      {postcode}
                    </Typography>
                  </Box>
                </li>
              )}
              sx={{
                '& .MuiInputBase-input': {
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                },
              }}
              ListboxComponent={CustomListBox}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
      <Box>
        <Accordion
          expanded={isAddressAccordionOpen}
          sx={{
            background: 'none',
            boxShadow: 'none',
            border: 'none',
            position: 'static',
          }}
        >
          <AccordionSummary
            expandIcon={expandAccordionIconBig}
            onClick={handleAddressAccordionToggle}
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{
              width: 'fit-content',
              height: '70px',
              padding: 0,
              marginTop: '20px',
              marginLeft: '8px',
              position: 'relative',
            }}
          >
            <TextNormal sx={{ mr: '7px' }}>Choose another address</TextNormal>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              p: 0,
            }}
          >
            <Grid
              container
              spacing={'18px'}
              sx={{
                marginBottom: '60px',
              }}
            >
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  id="address-country"
                  options={[
                    {
                      label: null,
                    },
                    ...countries,
                  ]}
                  getOptionLabel={(option) => option.label || ''}
                  fullWidth
                  autoHighlight
                  value={country}
                  onChange={(e, newVal) => {
                    setCountry(newVal)
                  }}
                  renderOption={(props, option) => {
                    if (!option.label) return null
                    return (
                      <Box
                        component="li"
                        sx={{
                          '& > img': {
                            mr: 2,
                            flexShrink: 0,
                          },
                        }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          alt=""
                        />
                        {option.label}
                      </Box>
                    )
                  }}
                  renderInput={(params) => (
                    <TextFieldSmallEdible
                      required
                      {...params}
                      label="Country"
                      error={showErrors && !country}
                      helperText={showErrors && !country ? 'This field can not be empty' : ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  id={'address-state'}
                  fullWidth
                  autoHighlight
                  value={state}
                  onChange={(e, newVal) => {
                    setState(newVal)
                  }}
                  renderInput={(params) => (
                    <TextFieldSmallEdible
                      {...params}
                      required
                      label={country?.code === 'AE' ? 'Emirate' : 'State'}
                      error={showErrors && !state}
                      helperText={showErrors && !state ? 'This field can not be empty' : ''}
                    />
                  )}
                  options={country?.code === 'AE' ? emirates : []}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextFieldSmall
                  id="address-postcode"
                  fullWidth
                  label="ZIP/postcode"
                  value={postcode}
                  onChange={(e) => {
                    setPostcode(e.target.value.toUpperCase())
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFieldSmallEdible
                  id="address-line-1"
                  fullWidth
                  label="Address line 1"
                  value={addressLine1}
                  onChange={(e) => {
                    setAddressLine1(e.target.value)
                  }}
                  error={showErrors && !addressLine1}
                  helperText={showErrors && !addressLine1 ? 'This field can not be empty' : ''}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFieldSmallEdible
                  id="address-line-2"
                  fullWidth
                  label="Address line 2"
                  value={addressLine2}
                  onChange={(e) => {
                    setAddressLine2(e.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldSmall
                  id="address-comment"
                  fullWidth
                  type="textarea"
                  multiline
                  rows={3}
                  label="Comment"
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value)
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Box
          sx={{
            mt: '20px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <ButtonLG
            onClick={() => {
              handleSubmit()
            }}
          >
            Apply for pickup
          </ButtonLG>
        </Box>
      </Box>
      <AnimationSendToCompoundModal open={animationModal} setOpen={setAnimationModal} />
    </ModalWrap>
  )
}

export default SendToCompoundModal
