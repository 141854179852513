import { getEntries } from '../residualValueMatrixEntries.actions'
import { apiConstants } from '../../../../api/api'

export default (builder) => {
  builder
    .addCase(getEntries.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getEntries.fulfilled, (state, action) => {
      state.isLoading = true
      state.error = null
      state.data = action.payload?.[apiConstants.hydraMember]?.sort((a, b) => a.term - b.term)
      state.total = action.payload?.[apiConstants.hydraTotal]
    })
    .addCase(getEntries.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
}
