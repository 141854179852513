import React from 'react'
import { Stack } from '@mui/material'
import IconDocument from '../../assests/img/icons/icon-appraisal-document.svg'
import UITextRegular from '../UIText/UITextRegular'
import { colorsConfig } from '../../config/themeConfig'
import UIIconButton from '../UIButtons/UIIconButton'
import { UIVariant } from '../../utils/constants/ui.constants'
import { CloseRounded } from '@mui/icons-material'

const UIFileItem = ({ file, handleRemove }) => {
  return (
    <Stack sx={{ position: 'relative' }} gap="8px" justifyContent="center" alignItems="center">
      <UIIconButton
        sx={{ position: 'absolute', top: '-28px', right: 0 }}
        variant={UIVariant.danger}
        onClick={() => handleRemove(file)}
      >
        <CloseRounded />
      </UIIconButton>
      <img src={IconDocument} alt="" />
      <UITextRegular sx={{ color: colorsConfig.lightGray }} text={file?.name} />
    </Stack>
  )
}

export default UIFileItem
