import React from 'react'
import { colorsConfig } from '../../config/themeConfig'
import { Button } from '@mui/material'

const ButtonTransparent = ({ children, sx, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        textTransform: 'none',
        borderRadius: '4px',
        background: 'white',
        color: colorsConfig.mainBlue,
        height: '48px',
        fontWeight: 700,
        fontSize: '16px',
        paddingLeft: '60px',
        paddingRight: '60px',
        boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
        ...sx,
      }}
    >
      {children}
    </Button>
  )
}

export default ButtonTransparent
