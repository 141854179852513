import React, { useEffect } from 'react'
import UIBreadcrumbs from '../../../../ui/UIBreadcrumbs/UIBreadcrumbs'
import { AddRounded, EditRounded, PeopleRounded } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPageTitle } from '../../../../store/features/authSlice'
import EditManagerForm from '../../../../components/Forms/FormTemplates/Admin/EditManagerForm/EditManagerForm'

const EditManager = () => {
  const manager = useSelector((state) => state.manager.data)
  const dispatch = useDispatch()

  const managerName = manager ? `${manager?.firstName} ${manager?.surname}` : '-'

  useEffect(() => {
    if (manager) dispatch(setCurrentPageTitle(`Edit manager "${managerName}"`))
  }, [manager])

  return (
    <Stack gap="30px">
      <UIBreadcrumbs
        pages={[
          {
            name: 'Managers',
            icon: <PeopleRounded />,
            link: '/admin/users',
          },
          {
            name: `Edit manager "${managerName}"`,
            icon: <EditRounded />,
          },
        ]}
      />
      <EditManagerForm />
    </Stack>
  )
}

export default EditManager
