import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import workflowsService from '../../../api/workflowsService/workflowsService'
import { APIErrors } from '../../../api/APIErrors'
import { getLocalWorkflowsState } from '../../../utils/localStorage.helpers'
import loanApplicationsApiService from '../../../api/finance/loanApplicationsApiService/loanApplicationsApiService'
import { apiConstants } from '../../../api/api'

export const getWorkflowsStates = createAsyncThunk(
  'workflows/getWorkflows',
  async ({}, { rejectWithValue }) => {
    const localWorkflows = await getLocalWorkflowsState()
    if (localWorkflows && localWorkflows?.insuranceApplications) {
      return localWorkflows?.insuranceApplications
    }
    try {
      const response = await workflowsService.getInsuranceApplicationWorkflowStates()
      return response.data['hydra:member']
    } catch (e) {
      if (!e.response) toast.error(APIErrors.NETWORK_ERROR)
      return rejectWithValue(e.response.data)
    }
  },
)

export const getLoanApplicationWorkflowStates = createAsyncThunk(
  'workflows/getLoanApplicationWorkflowStates',
  async (_, { rejectWithValue }) => {
    const localWorkflows = await getLocalWorkflowsState()
    if (localWorkflows && localWorkflows?.loanApplication) {
      return localWorkflows?.loanApplication
    }
    try {
      const response = await loanApplicationsApiService.getWorkflowStates()
      localStorage.setItem(
        'workflowStates',
        JSON.stringify({
          ...localWorkflows,
          loanApplication: response.data[apiConstants.hydraMember],
        }),
      )
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)
