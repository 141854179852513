import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import Sidebar from './Sidebar'
import Header from './Layout/Header/Header'
import PageTitle from './PageTitle'
import Footer2 from './Footer2'

const Layout = ({ children }) => {
  const largeOnly = useMediaQuery('(min-width: 1350px)')

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
        }}
      >
        <Sidebar />
        <Box
          sx={{
            flex: 1,
            pt: {
              xs: 0,
              md: '72px',
            },
          }}
        >
          <Header />
          <Box
            sx={{
              paddingTop: {
                xs: 0,
                sm: '30px',
              },
              paddingLeft: {
                xs: 0,
                sm: '23px',
              },
              paddingRight: {
                xs: 0,
                sm: largeOnly ? '98px' : '23px',
              },
              paddingBottom: {
                xs: 0,
                sm: '33px',
              },
              display: {
                md: 'block',
              },
            }}
          >
            <PageTitle />
            {children}
          </Box>
        </Box>
      </Box>
      <Footer2 />
    </React.Fragment>
  )
}

export default Layout
