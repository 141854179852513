import { createAsyncThunk } from '@reduxjs/toolkit'
import loanProgramsApiService from '../../../../api/financeAdmin/loanProgramsApiService'
import { adaptParams } from '../../../../api/api'
import productProvidersApiService from '../../../../api/financeAdmin/productProvidersApiService'

export const getLoanPrograms = createAsyncThunk(
  'loanPrograms/getLoanPrograms',
  async (_, { rejectWithValue, getState }) => {
    try {
      const params = getState().loanPrograms.params
      const response = await loanProgramsApiService.getLoanPrograms(adaptParams(params))
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getLoanProgramsBanks = createAsyncThunk(
  'loanPrograms/getLoanProgramsBanks',
  async ({ loanPrograms }, { rejectWithValue }) => {
    try {
      const bankIdUrls = loanPrograms.map((item) => item?.productProvider)
      const response = await Promise.allSettled(
        bankIdUrls.map((item) => productProvidersApiService.getProductProviderByIdUrl(item)),
      )
      const banks = response
        .filter((item) => item?.status === 'fulfilled')
        .map((item) => item.value?.data)
      const updatedLoanPrograms = loanPrograms.map((item, index) => ({
        ...item,
        productProvider: banks[index],
      }))
      return updatedLoanPrograms
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)
