import * as Yup from 'yup'
import { formErrors } from '../../formErrors'

export const initialWarrantyCarDetailsFormState = (car) => {
  const isModified = car?.independentCarInfo?.isModified ?? car?.isModified
  const carState = car?.independentCarInfo?.state ?? car?.state ?? null
  return {
    modelYear: car?.independentCarInfo?.modelYear ?? car?.modelYear ?? '',
    carState: carState !== 0 ? carState : '',
    enginePower: car?.independentCarInfo?.hp ?? car?.hp ?? '',
    firstRegistrationDate:
      car?.independentCarInfo?.firstRegistrationDate ?? car?.firstRegistrationDate ?? '',
    mileage: car?.independentCarInfo?.mileage ?? car?.mileage ?? '',
    modelDetails: car?.independentCarInfo?.details ?? car?.details ?? '',
    isModified: isModified ? 'yes' : 'no',
  }
}

export const warrantyCarDetailsFormValidationSchema = Yup.object().shape({
  modelYear: Yup.string().required(formErrors.required),
  carState: Yup.string().required(formErrors.required),
  enginePower: Yup.string().required(formErrors.required),
  firstRegistrationDate: Yup.string().required(formErrors.required),
  mileage: Yup.string().required(formErrors.required),
})
