import React from 'react'
import { Button } from '@mui/material'
import { CheckRounded } from '@mui/icons-material'
import { colorsConfig } from '../../config/themeConfig'
import IconCheck from '../../assests/img/icons/icon-checkmark.svg'

const UIBadgeSelectable = ({ isActive, children, sx, ...props }) => {
  return (
    <Button
      sx={{
        borderRadius: '20px',
        background: 'rgba(0,0,0,.05)',
        textTransform: 'none',
        py: '4px',
        px: '20px',
        color: colorsConfig.mainBlack,
        transition: '0.25s all ease-in-out',
        display: 'flex',
        gap: '7px',
        '&:hover': {
          background: colorsConfig.mainBlue,
          color: colorsConfig.white,
          transition: '0.25s all ease-in-out',
        },
        ...(isActive && {
          background: colorsConfig.mainBlue,
          color: colorsConfig.white,
          transition: '0.25s all ease-in-out',
        }),
        ...sx,
      }}
      {...props}
    >
      {isActive && <img src={IconCheck} style={{ width: '18px' }} alt="" />}
      {children}
    </Button>
  )
}

export default UIBadgeSelectable
