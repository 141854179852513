import React from 'react'
import { Box, IconButton, Modal, useMediaQuery } from '@mui/material'
import TextNormal from '../../ui/Text/TextNormal'
import { colorsConfig } from '../../config/themeConfig'
import TextH1 from '../../ui/Text/TextH1'
import { Close } from '@mui/icons-material'
import ButtonDanger from '../../ui/Buttons/ButtonDanger'
import ButtonSuccess from '../../ui/Buttons/ButtonSuccess'

const ConfirmDepositModal = (props) => {
  const { open, setOpen, handleSubmit, companyName, amount } = props

  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: xsOnly ? '90%' : 650,
          background: 'white',
          borderRadius: '10px',
          padding: '30px',
          maxHeight: '80vh',
          overflow: 'auto',
        }}
        className={'disable-scrollbar'}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          <Close
            style={{
              fill: colorsConfig.mainRed,
            }}
          />
        </IconButton>
        <TextH1
          sx={{
            mt: xsOnly && '10px',
            mb: '16px',
            fontSize: xsOnly ? '24px' : '30px',
            lineHeight: 'normal',
            textAlign: 'center',
          }}
        >
          Deposit confirmation
        </TextH1>
        <TextNormal
          sx={{
            mb: '30px',
            fontSize: xsOnly ? '18px' : '22px',
            lineHeight: 'normal',
            textAlign: 'center',
          }}
        >
          Are you sure you want to deposit <br />
          <span style={{ fontWeight: 700 }}>{amount} Fleetify coins</span> to{' '}
          <span style={{ fontWeight: 700 }}>{companyName}</span>?
        </TextNormal>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ButtonDanger
            sx={{
              width: 'calc(50% - 4px)',
            }}
            onClick={() => {
              setOpen(false)
            }}
          >
            Cancel
          </ButtonDanger>
          <ButtonSuccess
            sx={{
              width: 'calc(50% - 4px)',
            }}
            onClick={() => {
              handleSubmit()
            }}
          >
            Confirm
          </ButtonSuccess>
        </Box>
      </Box>
    </Modal>
  )
}

export default ConfirmDepositModal
