import { getEnumDetails } from '../references.actions'
import { apiConstants } from '../../../../api/api'

export default (builder) => {
  builder
    .addCase(getEnumDetails.pending, (state) => {
      state.enumDetails.isLoading = true
    })
    .addCase(getEnumDetails.fulfilled, (state, action) => {
      state.enumDetails.isLoading = false
      state.enumDetails.error = null
      state.enumDetails.data = action.payload?.[apiConstants.hydraMember]
    })
    .addCase(getEnumDetails.rejected, (state, action) => {
      state.enumDetails.isLoading = false
      state.enumDetails.error = action.payload
    })
}
