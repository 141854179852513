import { loanApplicationWorkflowStates } from '../constants/workflows.constants'

export const getLoanApplicationSectionsState = (currentWorkflowState) => {
  switch (currentWorkflowState) {
    case loanApplicationWorkflowStates.new:
      return {
        loanCalculator: {
          isOpen: false,
          isSaved: true,
          disabled: false,
        },
        customerDataForm: {
          isOpen: true,
          isSaved: false,
          disabled: false,
        },
        bankResponses: {
          isOpen: false,
          isSaved: false,
          disabled: true,
        },
      }
    case loanApplicationWorkflowStates.application_preparation:
      return {
        loanCalculator: {
          isOpen: false,
          isSaved: true,
          disabled: false,
        },
        customerDataForm: {
          isOpen: false,
          isSaved: true,
          disabled: false,
        },
        bankResponses: {
          isOpen: false,
          isSaved: false,
          disabled: true,
        },
      }
    case loanApplicationWorkflowStates.approval_process_started:
      return {
        loanCalculator: {
          isOpen: false,
          isSaved: true,
          disabled: false,
        },
        customerDataForm: {
          isOpen: false,
          isSaved: true,
          disabled: false,
        },
        bankResponses: {
          isOpen: false,
          isSaved: false,
          disabled: true,
        },
      }
    case loanApplicationWorkflowStates.replies_received:
      return {
        loanCalculator: {
          isOpen: false,
          isSaved: true,
          disabled: false,
        },
        customerDataForm: {
          isOpen: false,
          isSaved: true,
          disabled: false,
        },
        bankResponses: {
          isOpen: true,
          isSaved: false,
          disabled: false,
        },
      }
    case loanApplicationWorkflowStates.dealer_selected:
      return {
        loanCalculator: {
          isOpen: false,
          isSaved: true,
          disabled: false,
        },
        customerDataForm: {
          isOpen: false,
          isSaved: true,
          disabled: false,
        },
        bankResponses: {
          isOpen: true,
          isSaved: true,
          disabled: false,
        },
      }
    case loanApplicationWorkflowStates.customer_selected:
      return {
        loanCalculator: {
          isOpen: false,
          isSaved: true,
          disabled: false,
        },
        customerDataForm: {
          isOpen: false,
          isSaved: true,
          disabled: false,
        },
        bankResponses: {
          isOpen: true,
          isSaved: true,
          disabled: false,
        },
      }
    default:
      return {
        loanCalculator: {
          isOpen: true,
          isSaved: false,
          disabled: false,
        },
        customerDataForm: {
          isOpen: false,
          isSaved: false,
          disabled: true,
        },
        bankResponses: {
          isOpen: false,
          isSaved: false,
          disabled: true,
        },
      }
  }
}
