import { updateCompanyAddress } from '../settings.actions'

export default (builder) => {
  builder
    .addCase(updateCompanyAddress.pending, (state) => {
      state.companyAddresses.update.isLoading = true
    })
    .addCase(updateCompanyAddress.fulfilled, (state, action) => {
      state.companyAddresses.update.isLoading = false
      state.companyAddresses.update.error = null
    })
    .addCase(updateCompanyAddress.rejected, (state, action) => {
      state.companyAddresses.update.isLoading = false
      state.companyAddresses.update.error = action.payload
    })
}
