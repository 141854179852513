import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
  Autocomplete,
  Button,
  useMediaQuery,
} from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { privateRequest } from '../../utils/request'
import { API_ENDPOINTS } from '../../config/default'
import AuctionCarsItem from './AuctionCarsItem'
import { GridView } from '@mui/icons-material'
import SimpleCheckbox from '../../ui/SimpleCheckbox'
import TextNormal from '../../ui/Text/TextNormal'
import { useSelector, useDispatch } from 'react-redux'
import iconSearch from '../../assests/img/icons/icon-search.svg'
import { setAuctionCarsAmount } from '../../store/features/authSlice'
import { setCurrentPageTitle } from '../../store/features/authSlice'

const AuctionCars = () => {
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const emirates = [
    'All',
    'Dubai',
    'Abu Dhabi',
    'Sharjah',
    'Ajman',
    'Umm Al-Quwain',
    'Fujairah',
    'Ras Al Khaimah',
  ]

  const companyId = useSelector((state) => state.auth.companyId)

  const dispatch = useDispatch()

  const [auctions, setAuctions] = useState([])
  const [displayMode, setDisplayMode] = useState('table')
  const [myCarsOnly, setMyCarsOnly] = useState(false)
  const [searchLine, setSearchLine] = useState('')
  const [emirate, setEmirate] = useState('All')
  const [year, setYear] = useState('')
  const [makes, setMakes] = useState([])
  const [make, setMake] = useState('')
  const [makesLoading, setMakesLoading] = useState(false)
  const [makeId, setMakeId] = useState('')
  const [timeFilter, setTimeFilter] = useState('')

  const getAuctionsAmount = () => {
    privateRequest.get(API_ENDPOINTS.auctions).then((response) => {
      dispatch(
        setAuctionCarsAmount({
          auctionCarsAmount: response.data['hydra:totalItems'],
        }),
      )
    })
  }

  const getAuctions = () => {
    let link = API_ENDPOINTS.auctions
    if (myCarsOnly) {
      link = `${link}&car.company=${companyId}`
    }
    if (searchLine) {
      link = `${link}&or[car.make.name]=${searchLine}&or[car.model.name]=${searchLine}&or[car.company.address.state]=${searchLine}`
    }
    if (makeId) {
      link = `${link}&car.make=${makeId}`
    }
    if (year) {
      link = `${link}&order[car.firstRegistrationYear]=${year}`
    }
    if (timeFilter) {
      if (timeFilter === 'expiring') {
        const today = new Date()
        today.setDate(today.getDate() + 1)
        link = `${link}&endTime[strictly_before]=${today.toDateString()}&order[endTime]=asc`
      }
      if (timeFilter === 'newest') {
        link = `${link}&order[startTime]=desc`
      }
      if (timeFilter === 'oldest') {
        link = `${link}&order[startTime]=asc`
      }
    }
    privateRequest.get(link).then((response) => {
      setAuctions(response.data['hydra:member'])
    })
  }

  const handleSetMake = (value) => {
    setMake(value)
    if (value && value.length >= 2) {
      getMakes(value)
    } else {
      setMakeId('')
    }
  }

  const getMakes = (inputLine) => {
    privateRequest.get(`${API_ENDPOINTS.makes}?name=${inputLine}`).then((response) => {
      setMakes(response.data['hydra:member'])
      setMakesLoading(false)
      const matchMake = response.data['hydra:member'].filter((item) => item.name === inputLine)[0]
      if (matchMake) {
        setMakeId(matchMake['@id'])
      } else {
        setMakeId('')
      }
    })
  }

  useEffect(() => {
    getAuctions()
    getAuctionsAmount()
  }, [myCarsOnly, searchLine, makeId, year, emirate, timeFilter])

  useEffect(() => {
    dispatch(setCurrentPageTitle('Cars on Auction'))
  }, [])

  return (
    <Box
      className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
      sx={{
        '&.xs-only': {
          px: '16px',
          py: '25px',
        },
        '&.tablet-only': {
          px: '16px',
          pb: '30px',
        },
      }}
    >
      {xsOnly ? (
        <Box
          sx={{
            mb: '19px',
          }}
        >
          <TextField
            fullWidth
            id="search"
            label="Search"
            size="small"
            value={searchLine}
            onChange={(e) => {
              setSearchLine(e.target.value)
            }}
            sx={{
              '& .MuiInputBase-input': {
                width: '100%',
                height: '38px',
                boxSizing: 'border-box',
                borderRadius: '6px',
                borderColor: colorsConfig.mainBlack,
                color: colorsConfig.mainBlack,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: '6px',
                borderColor: colorsConfig.mainBlack,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img src={iconSearch} alt="" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ) : null}
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          padding: '30px',
          borderRadius: '4px',
          boxShadow: '0 4px 15px 0 rgba(0,0,0,.1)',
          background: 'white',
          mb: '30px',
          '&.xs-only': {
            borderRadius: '6px',
            py: '35px',
            pb: '30px',
          },
        }}
      >
        <Grid container columns={5} spacing={xsOnly ? '18px' : '10px'}>
          <Grid item sm={1} xs={5}>
            <FormControl fullWidth>
              <InputLabel
                id="label-year"
                sx={{
                  color: colorsConfig.lightGray,
                  fontWeight: 400,
                  '&.MuiFormLabel-root': {
                    transform: 'translate(14px, 13px) scale(1)',
                  },
                  '&.MuiInputLabel-shrink': {
                    transform: 'translate(14px, -9px) scale(0.75)',
                  },
                }}
              >
                Year
              </InputLabel>
              <Select
                labelId="label-year"
                id="simple-table-filter"
                label="Year"
                value={year}
                onChange={(e) => {
                  setYear(e.target.value)
                }}
                sx={{
                  borderRadius: '4px',
                  height: '50px',
                  fontSize: '16px',
                  borderColor: colorsConfig.lightGray,
                }}
              >
                <MenuItem value="">Not Chosen</MenuItem>
                <MenuItem value="&order[car.firstRegistrationYear]=asc">Show Old First</MenuItem>
                <MenuItem value="&order[car.firstRegistrationYear]=desc">Show New First</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={1} xs={5}>
            <Autocomplete
              required
              fullWidth
              id="car-make"
              options={makes.map((item) => {
                return item.name
              })}
              value={make}
              loading={!make ? true : makesLoading}
              loadingText={!make ? 'Start typing...' : 'Loading makes...'}
              onChange={(e, value) => {
                handleSetMake(value)
              }}
              freeSolo
              sx={{
                borderRadius: '4px',
                height: '50px',
                fontSize: '16px',
                borderColor: colorsConfig.lightGray,
                color: colorsConfig.lightGray,
                fontWeight: 400,
                '& .MuiOutlinedInput-root': {
                  '& .MuiAutocomplete-input': {
                    pt: '6.5px',
                  },
                },
                '& .MuiAutocomplete-input': {
                  pt: '6.5px',
                },
                '& .MuiFormLabel-root': {
                  transform: 'translate(14px, 13px) scale(1)',
                },
                '& .MuiInputLabel-shrink': {
                  transform: 'translate(14px, -9px) scale(0.75)',
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Make"
                  value={make}
                  fullWidth
                  onChange={(e) => {
                    handleSetMake(e.target.value)
                  }}
                  sx={{
                    borderRadius: '4px',
                    height: '50px',
                    fontSize: '16px',
                    color: colorsConfig.lightGray,
                    fontWeight: 400,
                    borderColor: colorsConfig.lightGray,
                    '& .MuiInputBase-root': {
                      height: '50px',
                      pt: '6.5px',
                    },
                    '& .MuiFormLabel-root': {
                      fontSize: '16px',
                      color: colorsConfig.lightGray,
                      fontWeight: 400,
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {makesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={2} xs={5}>
            <Box
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                height: '50px',
                justifyContent: 'flex-start',
                alignItems: 'center',
                ml: '20px',
                '&.xs-only': {
                  ml: 0,
                  justifyContent: 'space-between',
                },
              }}
            >
              <Button
                className={[
                  timeFilter === 'expiring' ? 'active' : '',
                  xsOnly ? 'xs-only' : '',
                ].join(' ')}
                sx={{
                  height: '28px',
                  pl: '5px',
                  pr: '5px',
                  borderRadius: '4px',
                  background: colorsConfig.lightGray,
                  mr: '10px',
                  color: 'white',
                  fontSize: '16px',
                  textTransform: 'none',
                  '&:hover': {
                    background: colorsConfig.iconGray,
                  },
                  '&.active': {
                    background: colorsConfig.iconGray,
                  },
                  '&.xs-only': {
                    mr: 0,
                  },
                }}
                onClick={() => {
                  if (timeFilter === 'expiring') {
                    setTimeFilter('')
                  } else {
                    setTimeFilter('expiring')
                  }
                }}
              >
                Expiring
              </Button>
              <Button
                className={[
                  timeFilter === 'expiring' ? 'active' : '',
                  xsOnly ? 'xs-only' : '',
                ].join(' ')}
                sx={{
                  height: '28px',
                  pl: '5px',
                  pr: '5px',
                  borderRadius: '4px',
                  background: colorsConfig.lightGray,
                  mr: '10px',
                  fontSize: '16px',
                  color: 'white',
                  textTransform: 'none',
                  '&:hover': {
                    background: colorsConfig.iconGray,
                  },
                  '&.active': {
                    background: colorsConfig.iconGray,
                  },
                  '&.xs-only': {
                    mr: 0,
                  },
                }}
                onClick={() => {
                  if (timeFilter === 'newest') {
                    setTimeFilter('')
                  } else {
                    setTimeFilter('newest')
                  }
                }}
              >
                Newest
              </Button>
              <Button
                className={[
                  timeFilter === 'expiring' ? 'active' : '',
                  xsOnly ? 'xs-only' : '',
                ].join(' ')}
                sx={{
                  height: '28px',
                  pl: '5px',
                  pr: '5px',
                  borderRadius: '4px',
                  background: colorsConfig.lightGray,
                  color: 'white',
                  textTransform: 'none',
                  fontFamily: '"Helvetica", sans-serif',
                  fontSize: '16px',
                  fontWeight: 400,
                  '&:hover': {
                    background: colorsConfig.iconGray,
                  },
                  '&.active': {
                    background: colorsConfig.iconGray,
                  },
                  '&.xs-only': {
                    mr: 0,
                  },
                }}
                onClick={() => {
                  if (timeFilter === 'oldest') {
                    setTimeFilter('')
                  } else {
                    setTimeFilter('oldest')
                  }
                }}
              >
                Oldest
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: '40px',
          '&.xs-only': {
            mb: '30px',
          },
        }}
      >
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            '&.xs-only': {
              display: 'none',
            },
          }}
        >
          <TextField
            id="search"
            label="Search"
            size="small"
            value={searchLine}
            onChange={(e) => {
              setSearchLine(e.target.value)
            }}
            sx={{
              '& .MuiInputBase-input': {
                width: '293px',
                height: '38px',
                boxSizing: 'border-box',
                borderRadius: '6px',
                borderColor: colorsConfig.mainBlack,
                color: colorsConfig.mainBlack,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: '6px',
                borderColor: colorsConfig.mainBlack,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img src={iconSearch} alt="" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '&.xs-only': {
              width: '100%',
              justifyContent: 'center',
            },
          }}
        >
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              mr: '20px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              '&.xs-only': {
                mr: 0,
              },
            }}
          >
            <SimpleCheckbox value={myCarsOnly} setValue={setMyCarsOnly} />
            <TextNormal>Show my cars</TextNormal>
          </Box>
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              '&.xs-only': {
                display: 'none',
              },
            }}
          >
            <Box
              sx={{
                width: '95px',
                height: '48px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid',
                borderColor: colorsConfig.mainBlack,
                borderRadius: '6px',
                position: 'relative',
                boxSizing: 'border-box',
                '&::after': {
                  content: '" "',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  left: '50%',
                  marginLeft: '-0.5px',
                  background: colorsConfig.mainBlack,
                  height: '100%',
                  width: '1px',
                },
              }}
            >
              <IconButton
                sx={{
                  width: '50%',
                  height: '100%',
                  position: 'relative',
                  borderRadius: '0 !important',
                  '&:hover': {
                    borderRadius: '0',
                  },
                }}
                onClick={() => {
                  setDisplayMode('list')
                }}
              >
                <div className={`burger-line-wrapper ${displayMode === 'list' ? 'active' : ''}`}>
                  <div className="burger-line" />
                  <div className="burger-line" />
                  <div className="burger-line" />
                </div>
              </IconButton>
              <IconButton
                sx={{
                  width: '50%',
                  height: '100%',
                  position: 'relative',
                  borderRadius: '0 !important',
                  '&:hover': {
                    borderRadius: '0',
                  },
                }}
                onClick={() => {
                  setDisplayMode('table')
                }}
              >
                <GridView
                  sx={{
                    fill: displayMode === 'table' ? colorsConfig.mainBlue : colorsConfig.mainBlack,
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
      {auctions.length > 0 ? (
        <Grid container spacing={xsOnly ? '8px' : 3}>
          {auctions.map((item, index) => {
            return <AuctionCarsItem key={index} auction={item} displayMode={displayMode} />
          })}
        </Grid>
      ) : (
        <Typography>Couldn't find any cars</Typography>
      )}
    </Box>
  )
}

export default AuctionCars
