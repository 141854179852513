import React from 'react'
import { Box, IconButton, Modal } from '@mui/material'
import { Close } from '@mui/icons-material'
import { colorsConfig } from '../../../../config/themeConfig'
import TextH1 from '../../../../ui/Text/TextH1'
import TextNormal from '../../../../ui/Text/TextNormal'
import ButtonHollow from '../../../../ui/Buttons/ButtonHollow'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'

const ConfirmationModal = (props) => {
  const { open, setOpen, title, confirmButton, confirmAction } = props

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 688,
          background: 'white',
          borderRadius: '10px',
          padding: '30px',
          maxHeight: '90vh',
          overflow: 'auto',
        }}
        className={'disable-scrollbar'}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          <Close
            style={{
              fill: colorsConfig.mainRed,
            }}
          />
        </IconButton>
        <TextH1
          sx={{
            mb: '20px',
            lineHeight: 'normal',
          }}
        >
          {title}
        </TextH1>
        <TextNormal
          sx={{
            mb: '45px',
          }}
        >
          Editing this step is not possible
        </TextNormal>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ButtonHollow
            sx={{
              width: 'calc(50% - 4px)',
            }}
            onClick={() => {
              setOpen(false)
            }}
          >
            Double-check
          </ButtonHollow>
          <ButtonLG
            sx={{
              width: 'calc(50% - 4px)',
            }}
            onClick={() => {
              confirmAction()
            }}
          >
            {confirmButton}
          </ButtonLG>
        </Box>
      </Box>
    </Modal>
  )
}

export default ConfirmationModal
