import React from 'react'
import UIFormInputSelect from '../../../ui/UIFormComponents/UIFormInputSelect/UIFormInputSelect'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

const FormSelect = ({ label, id, required, validateOnChange, options, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers
  const { t } = useTranslation()

  const handleChange = ({ target }) => {
    setValue(target.value)
  }

  return (
    <UIFormInputSelect
      fullWidth
      error={meta.error && meta.touched}
      label={t(label)}
      name={field.name}
      value={field.value}
      onChange={handleChange}
      showHelperText={meta.error && meta.touched}
      helperText={meta.error}
      options={options}
      {...props}
    />
  )
}

export default FormSelect
