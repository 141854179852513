import React, { useState } from 'react'
import { Checkbox } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setCarMainData } from '../../../store/features/addNewCarSlice/addNewCar.reducer'

function lowerCaseFirstChar(str) {
  if (!str) return str // Handle empty strings
  return str.charAt(0).toLowerCase() + str.slice(1)
}

const CarSpecOption = (props) => {
  const { type, name, id, changeCarSpec, value, readOnly } = props
  const carData = useSelector((state) => state.addCar.carMainData)
  const dispatch = useDispatch()

  const specString = ` ${name};`

  return (
    <div className="car-option">
      <div className="car-option-name">{name}</div>
      {type === 'checkbox' ? (
        <Checkbox
          color="primary"
          onChange={(e) => {
            changeCarSpec(id, e.target.checked)
            if (e.target.checked && !carData.additionalSpecsDetails.includes(specString)) {
              dispatch(
                setCarMainData({
                  ...carData,
                  additionalSpecsDetails: `${carData?.additionalSpecsDetails}${specString}`,
                }),
              )
            } else {
              dispatch(
                setCarMainData({
                  ...carData,
                  additionalSpecsDetails: carData.additionalSpecsDetails.replace(specString, ''),
                }),
              )
            }
          }}
          value={value}
          disabled={readOnly}
          checked={value !== null ? value : false}
        />
      ) : null}
    </div>
  )
}

export default CarSpecOption
