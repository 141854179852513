import React from 'react'
import { Box, Button, useMediaQuery } from '@mui/material'
import { CDN_BASE_URL } from '../../../config/default'
import './WarningLight.scss'
import { useDispatch } from 'react-redux'
import { updatePilotLamps } from '../../../store/features/carSlice/carSlice'
import { colorsConfig } from '../../../config/themeConfig'

const WarningLightItem = (props) => {
  const { warningLight, readonly } = props

  const xsOnly = useMediaQuery('(max-width: 600px)')

  const dispatch = useDispatch()

  const handleSetWarningLight = () => {
    if (!readonly) {
      if (warningLight.value === null) {
        dispatch(
          updatePilotLamps({
            ...warningLight,
            newValue: warningLight.newValue === null || warningLight.newValue === 0 ? 1 : 0,
          }),
        )
      } else if (warningLight.newValue === null) {
        dispatch(
          updatePilotLamps({
            ...warningLight,
            newValue: warningLight.value === 1 ? 0 : 1,
          }),
        )
      } else {
        dispatch(
          updatePilotLamps({
            ...warningLight,
            newValue: warningLight.newValue === 0 ? 1 : 0,
          }),
        )
      }
    }
  }

  return (
    <Box
      className={xsOnly ? 'xs-only' : ''}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        mr: 'calc((100% - 330px) / 4)',
        '&:nth-child(5n)': {
          mr: 0,
        },
        mb: '20px',
        '&:nth-child(6), &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10)': {
          mb: 0,
        },
        '&.xs-only': {
          mt: '19px !important',
          mb: '0 !important',
          mr: '15px !important',
          width: 'calc(25% - 15px)',
        },
      }}
    >
      <Button
        sx={{
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          background: colorsConfig.mainBlack,
          transition: '0.25s all ease-in-out',
          '&:hover': {
            background: colorsConfig.mainBlack,
            opacity: 0.7,
            transition: '0.25s all ease-in-out',
          },
          pointerEvents: readonly ? 'none' : 'auto',
        }}
        onClick={() => {
          handleSetWarningLight()
        }}
      >
        <img
          src={`${CDN_BASE_URL}${warningLight.icon}`}
          alt={warningLight.name}
          style={{
            width: '70%',
            fill: colorsConfig.lightGray,
          }}
          className={
            warningLight.newValue !== null
              ? warningLight.newValue === 1
                ? 'lit'
                : 'not-lit'
              : warningLight.value === 1
                ? 'lit'
                : 'not-lit'
          }
        />
      </Button>
    </Box>
  )
}

export default WarningLightItem
