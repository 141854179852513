import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import IconVirtualGarage from '../../assests/img/icons/icon-status-virtual-garage.svg'
import IconAppraisal from '../../assests/img/icons/icon-status-appraisal.svg'
import IconAppraisalFailed from '../../assests/img/icons/icon-status-appraisal-fail.svg'
import IconAuction from '../../assests/img/icons/icon-status-auction.svg'
import IconAwaitingPayment from '../../assests/img/icons/icon-status-awaiting-payment.svg'
import IconAwaitingPaymentFailed from '../../assests/img/icons/icon-status-awaiting-payment-failed.svg'
import IconTransportation from '../../assests/img/icons/icon-status-transportation-in-progress.svg'
import IconCarSold from '../../assests/img/icons/icon-status-car-sold.svg'
import IconStatusFinance from '../../assests/img/icons/icon-status-finance.svg'
import IconStatusInsurance from '../../assests/img/icons/icon-status-insurance.svg'
import IconStatusWarranty from '../../assests/img/icons/icon-status-warranty.svg'
import { useSelector } from 'react-redux'
import { colorsConfig } from '../../config/themeConfig'
import TextNormal from '../../ui/Text/TextNormal'

const VirtualGarageStatusIcons = (props) => {
  const { isInsuranceRelated, isFinanceRelated, isWarrantyRelated, combinedStatus } = props

  const carWorkflows = useSelector((state) => state.workflows.carWorkflowStates)
  const auctionWorkflows = useSelector((state) => state.workflows.auctionWorkflowStates)
  const dealWorkflows = useSelector((state) => state.workflows.dealWorkflowStates)

  const [mainIcon, setMainIcon] = useState('')
  const [mainIconText, setMainIconText] = useState('')

  const mainIcons = [
    {
      icon: IconVirtualGarage,
      statuses: ['car_workflow.new', 'car_workflow.ready_for_sale'],
    },
    {
      icon: IconAppraisal,
      statuses: [
        'car_workflow.appraisal_on_site',
        'car_workflow.quality_check',
        'car_workflow.requested_appraisal_compound',
        'car_workflow.awaiting_appraisal_compound',
        'car_workflow.appraisal_compound',
      ],
    },
    {
      icon: IconAuction,
      statuses: [
        'auction_workflow.pending',
        'auction_workflow.active',
        'auction_workflow.completed',
      ],
    },
    {
      icon: IconAwaitingPayment,
      statuses: [
        'deal_workflow.pending',
        'deal_workflow.invoice_sent',
        'deal_workflow.money_received',
        'deal_workflow.money_payed',
        'deal_workflow.',
      ],
    },
    {
      icon: IconTransportation,
      statuses: ['deal_workflow.transportation_in_progress'],
    },
    {
      icon: IconCarSold,
      statuses: ['car_workflow.sale_succeed'],
    },
    {
      icon: IconAppraisalFailed,
      statuses: [
        'car_workflow.moderation_failed',
        'car_workflow.requested_appraisal_compound_failed',
      ],
    },
    {
      icon: IconAwaitingPaymentFailed,
      statuses: ['deal_workflow.cancel'],
    },
  ]

  const getCarStatus = (combinedStatus, isArchived) => {
    if (isArchived) {
      return 'Archived'
    }
    if (combinedStatus === 'car_workflow.requested_appraisal_compound') {
      return (
        <TextNormal>
          Request for appraisal <br />
          on compound
        </TextNormal>
      )
    }
    if (combinedStatus === 'car_workflow.requested_appraisal_compound_failed') {
      return (
        <TextNormal>
          Request for appraisal <br />
          on compound failed
        </TextNormal>
      )
    }
    if (combinedStatus.includes('car_workflow')) {
      return carWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('car_workflow.', ''),
      )[0].value
    }
    if (combinedStatus.includes('auction_workflow')) {
      return auctionWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('auction_workflow.', ''),
      )[0].value
    }
    if (combinedStatus.includes('deal_workflow')) {
      return dealWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('deal_workflow.', ''),
      )[0].value
    }
  }

  const handleSetMainIcon = () => {
    const matchIcon = mainIcons.filter((item) =>
      Boolean(item.statuses.filter((status) => status === combinedStatus)[0]),
    )[0]
    if (matchIcon) {
      setMainIcon(matchIcon.icon)
    }
  }

  useEffect(() => {
    if (combinedStatus) {
      handleSetMainIcon()
      setMainIconText(getCarStatus(combinedStatus))
    }
  }, [combinedStatus])

  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 2,
        display: 'flex',
        gap: '9px',
        alignItems: 'center',
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      {mainIcon ? (
        <Box
          sx={{
            position: 'relative',
            '&:hover': {
              '& .popover': {
                opacity: 1,
                transform: 'scale(1)',
                transition: '0.25s all ease-in-out',
              },
            },
          }}
        >
          <img
            src={mainIcon}
            alt=""
            style={{
              width: '20px',
              verticalAlign: 'top',
            }}
          />
          <TextNormal
            className={'popover'}
            sx={{
              py: '4px',
              px: '6px',
              borderRadius: '6px',
              background: 'white',
              position: 'absolute',
              boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
              bottom: '100%',
              left: '-16px',
              whiteSpace: 'nowrap',
              pointerEvents: 'none',
              fontSize: '14px',
              maxWidth: '210px',
              opacity: 0,
              transform: 'scale(0)',
              transformOrigin: '20px 100%',
              transition: '0.25s all ease-in-out',
              color: `${colorsConfig.mainBlack} !important`,
              display: 'inline-block',
              '&::after': {
                position: 'absolute',
                content: '" "',
                display: 'block',
                top: '100%',
                left: '20px',
                width: '0px',
                height: '0px',
                borderStyle: 'solid',
                borderWidth: '0 6px 9px 6px',
                borderColor: 'transparent transparent white transparent',
                transform: 'rotate(180deg)',
              },
            }}
          >
            {mainIconText}
          </TextNormal>
        </Box>
      ) : null}
      {isFinanceRelated ? (
        <Box
          sx={{
            position: 'relative',
            '&:hover': {
              '& .popover': {
                opacity: 1,
                transform: 'scale(1)',
                transition: '0.25s all ease-in-out',
              },
            },
          }}
        >
          <img
            src={IconStatusFinance}
            alt=""
            style={{
              width: '20px',
              verticalAlign: 'top',
            }}
          />
          <TextNormal
            className={'popover'}
            sx={{
              py: '4px',
              px: '6px',
              borderRadius: '6px',
              background: 'white',
              position: 'absolute',
              boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
              bottom: '100%',
              left: '-18px',
              whiteSpace: 'nowrap',
              pointerEvents: 'none',
              opacity: 0,
              transform: 'scale(0)',
              transformOrigin: '20px 100%',
              transition: '0.25s all ease-in-out',
              color: `${colorsConfig.mainBlack} !important`,
              '&::after': {
                position: 'absolute',
                content: '" "',
                display: 'block',
                top: '100%',
                left: '20px',
                width: '0px',
                height: '0px',
                borderStyle: 'solid',
                borderWidth: '0 6px 9px 6px',
                borderColor: 'transparent transparent white transparent',
                transform: 'rotate(180deg)',
              },
            }}
          >
            Finance in progress
          </TextNormal>
        </Box>
      ) : null}
      {isInsuranceRelated ? (
        <Box
          sx={{
            position: 'relative',
            '&:hover': {
              '& .popover': {
                opacity: 1,
                transform: 'scale(1)',
                transition: '0.25s all ease-in-out',
              },
            },
          }}
        >
          <img
            src={IconStatusInsurance}
            alt=""
            style={{
              width: '20px',
              verticalAlign: 'top',
            }}
          />
          <TextNormal
            className={'popover'}
            sx={{
              py: '4px',
              px: '6px',
              borderRadius: '6px',
              background: 'white',
              position: 'absolute',
              boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
              bottom: '100%',
              left: '-18px',
              whiteSpace: 'nowrap',
              pointerEvents: 'none',
              opacity: 0,
              transform: 'scale(0)',
              transformOrigin: '20px 100%',
              transition: '0.25s all ease-in-out',
              color: `${colorsConfig.mainBlack} !important`,
              '&::after': {
                position: 'absolute',
                content: '" "',
                display: 'block',
                top: '100%',
                left: '20px',
                width: '0px',
                height: '0px',
                borderStyle: 'solid',
                borderWidth: '0 6px 9px 6px',
                borderColor: 'transparent transparent white transparent',
                transform: 'rotate(180deg)',
              },
            }}
          >
            Insurance in progress
          </TextNormal>
        </Box>
      ) : null}
      {isWarrantyRelated ? (
        <Box
          sx={{
            position: 'relative',
            '&:hover': {
              '& .popover': {
                opacity: 1,
                transform: 'scale(1)',
                transition: '0.25s all ease-in-out',
              },
            },
          }}
        >
          <img
            src={IconStatusWarranty}
            alt=""
            style={{
              width: '20px',
              verticalAlign: 'top',
            }}
          />
          <TextNormal
            className={'popover'}
            sx={{
              py: '4px',
              px: '6px',
              borderRadius: '6px',
              background: 'white',
              position: 'absolute',
              boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
              bottom: '100%',
              left: '-18px',
              whiteSpace: 'nowrap',
              pointerEvents: 'none',
              opacity: 0,
              transform: 'scale(0)',
              transformOrigin: '20px 100%',
              transition: '0.25s all ease-in-out',
              color: `${colorsConfig.mainBlack} !important`,
              '&::after': {
                position: 'absolute',
                content: '" "',
                display: 'block',
                top: '100%',
                left: '20px',
                width: '0px',
                height: '0px',
                borderStyle: 'solid',
                borderWidth: '0 6px 9px 6px',
                borderColor: 'transparent transparent white transparent',
                transform: 'rotate(180deg)',
              },
            }}
          >
            Warranty
          </TextNormal>
        </Box>
      ) : null}
    </Box>
  )
}

export default VirtualGarageStatusIcons
