import React from 'react'
import { Checkbox, Stack } from '@mui/material'
import { useField } from 'formik'
import UITextBodySmall from '../../../ui/UIText/UITextBodySmall'
import { colorsConfig } from '../../../config/themeConfig'

const FormAgreement = ({ label, id, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers

  return (
    <Stack direction="row" alignItems="center" sx={{ position: 'relative', ml: '-7px' }}>
      <Checkbox
        color="primary"
        onChange={(e) => {
          setValue(e.target.checked)
        }}
        name={field.name}
        checked={field.value}
      />
      <UITextBodySmall
        sx={{
          color: colorsConfig.iconGray,
        }}
      >
        {label}
      </UITextBodySmall>
    </Stack>
  )
}

export default FormAgreement
