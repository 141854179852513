import { getLoanPrograms, getLoanProgramsBanks } from '../loanPrograms.actions'
import { apiConstants } from '../../../../../api/api'
import { API_ERROR_CODES } from '../../../../../api/APIErrors'

export default (builder) => {
  builder
    .addCase(getLoanPrograms.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getLoanPrograms.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = null
      state.data = action.payload?.[apiConstants.hydraMember]
      state.total = action.payload?.[apiConstants.hydraTotal]
    })
    .addCase(getLoanPrograms.rejected, (state, action) => {
      if (action.payload?.code !== API_ERROR_CODES.ERR_CANCELED) {
        state.isLoading = false
        state.error = action.payload
      }
    })
    .addCase(getLoanProgramsBanks.fulfilled, (state, action) => {
      state.data = state.data.map((item) => {
        const matchItem = action.payload?.find((e) => e?.id === item.id)
        if (matchItem) return matchItem
        return item
      })
    })
}
