import { getInsuranceStatusInfo, insuranceWorkflowsEnums } from '../Insurance/insurance.constants'

export const getInsuranceStatusList = (data) => {
  const carDetailsSavedItems = data.filter(
    (item) => getInsuranceStatusInfo(item).name === insuranceWorkflowsEnums.CAR_DETAILS_SAVED,
  )
  const driverDetailsSavedItems = data.filter(
    (item) => getInsuranceStatusInfo(item).name === insuranceWorkflowsEnums.DRIVER_DETAILS_SAVED,
  )
  const awaitingQuotesItems = data.filter(
    (item) => getInsuranceStatusInfo(item).name === insuranceWorkflowsEnums.AWAITING_QUOTES,
  )
  const quotesReceivedItems = data.filter(
    (item) => getInsuranceStatusInfo(item).name === insuranceWorkflowsEnums.QUOTES_RECEIVED,
  )
  const awaitingCustomerItems = data.filter(
    (item) => getInsuranceStatusInfo(item).name === insuranceWorkflowsEnums.AWAITING_CUSTOMER,
  )
  const insuredItems = data.filter(
    (item) => getInsuranceStatusInfo(item).name === insuranceWorkflowsEnums.INSURED,
  )
  return [
    {
      name: insuranceWorkflowsEnums.CAR_DETAILS_SAVED,
      count: carDetailsSavedItems.length,
      items: carDetailsSavedItems,
    },
    {
      name: insuranceWorkflowsEnums.DRIVER_DETAILS_SAVED,
      count: driverDetailsSavedItems.length,
      items: driverDetailsSavedItems,
    },
    {
      name: insuranceWorkflowsEnums.AWAITING_QUOTES,
      count: awaitingQuotesItems.length,
      items: awaitingQuotesItems,
    },
    {
      name: insuranceWorkflowsEnums.QUOTES_RECEIVED,
      count: quotesReceivedItems.length,
      items: quotesReceivedItems,
    },
    {
      name: insuranceWorkflowsEnums.AWAITING_CUSTOMER,
      count: awaitingCustomerItems.length,
      items: awaitingCustomerItems,
    },
    {
      name: insuranceWorkflowsEnums.INSURED,
      count: insuredItems.length,
      items: insuredItems,
    },
  ]
}
