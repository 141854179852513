import { privateRequest } from '../../utils/request'

class WarrantyProductsService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      warrantyProducts: '/reference/warranty/warranty-products',
      warrantyProductById: (id) => `/reference/warranty/warranty-products/${id}`,
    }
  }

  getWarrantyProducts = async () => {
    const response = await this.#api.get(this.#endPoints.warrantyProducts)
    return response
  }

  getWarrantyProductById = async (id) => {
    const response = await this.#api.get(this.#endPoints.warrantyProductById(id))
    return response
  }

  updateWarrantyProduct = async (id, data) => {
    const response = await this.#api.put(this.#endPoints.warrantyProductById(id), data)
    return response
  }
}

const warrantyProductsService = new WarrantyProductsService({ api: privateRequest })

export default warrantyProductsService
