import React, { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import UITextTitleSmall from '../../../ui/UIText/UITextTitleSmall'
import UIIconButtonRoundedBorder from '../../../ui/UIIconButtons/UIIconButtonRoundedBorder'
import { AddRounded } from '@mui/icons-material'
import AddLocationModal from '../../../components/Modals/AddLocationModal/AddLocationModal'
import { useDispatch, useSelector } from 'react-redux'
import { getCompanyAddresses } from '../../../store/features/settings/settings.actions'
import UIPreloader from '../../../ui/UIPreloader/UIPreloader'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import { colorsConfig } from '../../../config/themeConfig'
import CompanyAddressItem from './CompanyAddressItem'
import { getAddressTypes } from '../../../store/features/references/references.actions'
import { getCompanyAddressesListData } from './companyAddresses.helpers'
import { useTranslation } from 'react-i18next'

const CompanyAddresses = () => {
  const { t } = useTranslation()
  const isLoading = useSelector((state) => state.settings.companyAddresses.isLoading)
  const companyAddresses = useSelector((state) => state.settings.companyAddresses.data)
  const addressTypes = useSelector((state) => state.ref.addressTypes.data)
  const dispatch = useDispatch()

  const [addLocationModal, setAddLocationModal] = useState(false)

  useEffect(() => {
    if (addressTypes?.length === 0) {
      dispatch(getAddressTypes())
    }
  }, [addressTypes])

  useEffect(() => {
    dispatch(getCompanyAddresses())
  }, [])

  return (
    <Stack gap="16px" sx={{ mt: '30px' }}>
      <Stack gap="4px" direction="row" alignItems="center">
        <UITextTitleSmall text="title_manage_locations" />
        <UIIconButtonRoundedBorder
          text="button_add_new_location"
          onClick={() => setAddLocationModal(true)}
        >
          <AddRounded />
        </UIIconButtonRoundedBorder>
      </Stack>
      <Box sx={{ minHeight: '100px', position: 'relative' }}>
        {isLoading && <UIPreloader />}
        {companyAddresses?.length === 0 && !isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100px',
            }}
          >
            <UITextRegular
              center
              color={colorsConfig.lightGray}
              text="account_settings_locations_empty"
            />
          </Box>
        ) : null}
        {getCompanyAddressesListData(companyAddresses, addressTypes, t).map((item) => (
          <CompanyAddressItem key={item?.id} data={item} />
        ))}
      </Box>
      {addLocationModal && (
        <AddLocationModal
          open={addLocationModal}
          setOpen={setAddLocationModal}
          onSubmit={() => dispatch(getCompanyAddresses())}
        />
      )}
    </Stack>
  )
}

export default CompanyAddresses
