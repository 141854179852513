import { getCarLoanApplications } from '../financing.actions'
import { apiConstants } from '../../../../api/api'
import { API_ERROR_CODES } from '../../../../api/APIErrors'
import { getLoanApplicationSectionsState } from '../../../../utils/helpers/financing.helpers'

export default (builder) => {
  builder
    .addCase(getCarLoanApplications.pending, (state) => {
      state.loanApplications.isLoading = true
    })
    .addCase(getCarLoanApplications.fulfilled, (state, action) => {
      state.loanApplications.isLoading = false
      state.loanApplications.error = null
      const loanApplications = action.payload?.[apiConstants.hydraMember]
      const currentLoanApplication = loanApplications?.[state.currentTab] ?? loanApplications?.[0]
      state.loanApplications.data = loanApplications
      state.currentLoanApplication.data = currentLoanApplication
      state.currentLoanApplication.sectionsState = getLoanApplicationSectionsState(
        currentLoanApplication?.currentWorkflowState,
      )
    })
    .addCase(getCarLoanApplications.rejected, (state, action) => {
      if (action.payload?.code !== API_ERROR_CODES.ERR_CANCELED) {
        state.loanApplications.isLoading = false
        state.loanApplications.error = action.payload
      }
    })
}
