import React from 'react'
import { Box, Stack } from '@mui/material'
import TextH1 from '../../../../ui/Text/TextH1'
import TextNormal from '../../../../ui/Text/TextNormal'
import TextBoldNormal from '../../../../ui/Text/TextBoldNormal'
import IconCheckmark from '../../../../assests/img/icons/icon-checkmark-black.svg'
import { useSelector } from 'react-redux'
import { handleFormatDate } from '../../../../utils/formatDate'
import { sumDelimiter } from '../../../../utils/sumDelimiter'
import { displayRequestedCoverage } from '../operator-insurance.helpers'
import { generateUID } from '../../../../utils/generateUID'
import { formatDate } from '../../../../utils/date.helpers'

const InsuranceRequestSummary = () => {
  const application = useSelector((state) => state.insuranceOperator.application)

  return (
    <Box>
      <TextH1 sx={{ mb: '20px' }}>
        Customer: {application?.customer?.person?.firstName || `#${application?.id}`}
      </TextH1>
      <Box
        sx={{
          mb: '30px',
          display: 'flex',
          gap: '40px',
        }}
      >
        <Box>
          <TextNormal>Start date</TextNormal>
          <TextBoldNormal>{formatDate(application?.startDate)}</TextBoldNormal>
        </Box>
        <Box>
          <TextNormal>Insurance type</TextNormal>
          <TextBoldNormal>Third party only</TextBoldNormal>
        </Box>
        <Box>
          <TextNormal>Annual Mileage</TextNormal>
          <TextBoldNormal>
            {sumDelimiter(application?.annualMileage)?.replace("'", ' ')} km/year
          </TextBoldNormal>
        </Box>
        <Box>
          <TextNormal>Additional request</TextNormal>
          <TextBoldNormal>{application?.additionalRequestNote || '-'}</TextBoldNormal>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: '16px',
          mb: '25px',
        }}
      >
        <TextBoldNormal>Requested coverage:</TextBoldNormal>
        <TextNormal>Third party liability +</TextNormal>
        {displayRequestedCoverage(application?.selectedRisks).map((item) => {
          return (
            <Box
              key={generateUID()}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <img src={IconCheckmark} alt="" />
              <TextNormal>{item}</TextNormal>
            </Box>
          )
        })}
      </Box>
      <Box>
        <TextNormal>Dealer</TextNormal>
        <TextBoldNormal>{application?.company?.name}</TextBoldNormal>
      </Box>
    </Box>
  )
}

export default InsuranceRequestSummary
