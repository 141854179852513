import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import ModalWrap from '../../../../components/Modals/ModalWrap'
import TextH1 from '../../../../ui/Text/TextH1'
import ButtonHollow from '../../../../ui/Buttons/ButtonHollow'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import { colorsConfig } from '../../../../config/themeConfig'
import TextNormal from '../../../../ui/Text/TextNormal'
import TextBoldNormal from '../../../../ui/Text/TextBoldNormal'

const BankResponseSummaryModal = (props) => {
  const { open, setOpen, tableData, note, handleAction } = props

  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <ModalWrap
      open={open}
      setOpen={setOpen}
      wrapperStyles={{
        background: 'white',
      }}
    >
      <TextH1
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          mb: '20px',
          '&.xs-only': {
            fontSize: '24px',
          },
        }}
      >
        Send bank response
      </TextH1>
      <Box
        sx={{
          mb: '20px',
          borderRadius: '6px',
        }}
      >
        {tableData.map((item, index) => {
          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: '4px',
                background: index === 0 || (index + 1) % 2 ? colorsConfig.bgLightBlue : 'white',
                px: '7px',
                py: '2.5px',
              }}
            >
              <TextNormal>{item.title}</TextNormal>
              <TextNormal>{item.value ? item.value : '-'}</TextNormal>
            </Box>
          )
        })}
      </Box>
      {note ? (
        <Box sx={{ mb: '20px' }}>
          <TextBoldNormal sx={{ mb: '10px' }}>Note from the bank:</TextBoldNormal>
          <TextNormal>{note}</TextNormal>
        </Box>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
        }}
      >
        <ButtonHollow
          sx={{
            width: 'calc(50% - 5px)',
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          Cancel
        </ButtonHollow>
        <ButtonLG
          sx={{
            width: 'calc(50% - 5px)',
          }}
          onClick={() => {
            handleAction()
          }}
        >
          Confirm
        </ButtonLG>
      </Box>
    </ModalWrap>
  )
}

export default BankResponseSummaryModal
