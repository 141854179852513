import React, { useEffect, useState } from 'react'
import { InputAdornment, Stack } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import ModalWrap from '../../../components/Modals/ModalWrap'
import UITextHeadlineLarge from '../../../ui/UIText/UITextHeadlineLarge'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import ButtonAttention from '../../../ui/Buttons/ButtonAttention'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { sumDelimiter } from '../../../utils/sumDelimiter'
import UITextBodySmall from '../../../ui/UIText/UITextBodySmall'
import UIFormInputSelect from '../../../ui/UIFormComponents/UIFormInputSelect/UIFormInputSelect'
import { useDispatch, useSelector } from 'react-redux'
import { getCompanyAddresses } from '../../../store/features/companyAddressesSlice/companyAddresses.actions'
import { addWorkingDaysToCurrentDate } from '../../../utils/helpers/date.helpers'
import { rviStartRestitution } from '../../../store/features/rviPolicy/rviPolicy.actions'
import { useTranslation } from 'react-i18next'

const expertiseOptions = [
  {
    label: 'form_input_select_on_spot_at_dealership',
    value: 'dealership',
  },
  {
    label: 'form_input_select_after_transporting_to_expert_center',
    value: 'expertCenter',
  },
]

export const getAddressType = (addressType) => {
  if (!addressType) return 'All'
  return addressType
}

export const getCompanyAddressesOptions = (companyAddresses) => {
  return companyAddresses.map((item) => {
    const address = item?.address ?? {}
    const addressLine = [
      getAddressType(address?.addressType),
      address?.addressLine1,
      address?.state,
      address?.country,
      address?.postcode,
    ].join(', ')
    return {
      value: addressLine,
      label: addressLine,
    }
  })
}

const RestituteVehicleModal = ({ policy, open, setOpen, onClose }) => {
  // const isLoading = useSelector((state) => state.rvi.startRestitution.isLoading)
  // const isLoadingCompanyAddresses = useSelector((state) => state.companyAddresses.isLoading)
  const companyAddresses = useSelector((state) => state.companyAddresses.data)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const details = policy?.details || {}
  const carTitle = details?.make && details?.model ? `${details?.make} ${details.model}` : '-'

  const [formData, setFormData] = useState({
    restitutionDate: null,
    location: '',
    mileage: details?.mileage ?? '',
    vehicleExpertise: '',
  })
  const [showErrors, setShowErrors] = useState(false)
  const [formValid, setFormValid] = useState(false)

  const handleValidate = () => {
    const { mileage, restitutionDate, location, vehicleExpertise } = formData
    let isValid = true
    if (!mileage) isValid = false
    if (!restitutionDate) isValid = false
    if (!location) isValid = false
    if (!vehicleExpertise) isValid = false
    setFormValid(isValid)
    return isValid
  }

  const handleStartRestitution = async () => {
    const isValid = handleValidate()
    if (!isValid) {
      setShowErrors(true)
      return
    }
    const data = {
      ...formData,
      mileage: parseInt(formData.mileage),
    }
    const res = await dispatch(rviStartRestitution({ formData: data, policyId: policy?.id }))
    if (rviStartRestitution.fulfilled.match(res)) {
      onClose()
    }
  }

  useEffect(() => {
    if (showErrors) {
      handleValidate()
    }
  }, [showErrors, formData])

  useEffect(() => {
    dispatch(getCompanyAddresses())
  }, [])

  return (
    <ModalWrap open={open} setOpen={setOpen} wrapperStyles={{ maxWidth: '575px' }}>
      <Stack gap="40px">
        <UITextHeadlineLarge sx={{ fontSize: '28px' }}>
          Restitute vehicle "{carTitle}"
        </UITextHeadlineLarge>
        <Stack gap="24px">
          <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label={t('form_input_label_restitute_date')}
              inputFormat="DD/MM/YYYY"
              mask={'__/__/____'}
              disableMaskedInput={false}
              minDate={addWorkingDaysToCurrentDate()}
              renderInput={(params) => (
                <TextFieldSmallEdible
                  {...params}
                  fullWidth
                  required
                  value={formData.restitutionDate}
                  error={!formData.restitutionDate && showErrors}
                  helperText={!formData.restitutionDate && showErrors ? 'Required field' : ''}
                />
              )}
              onChange={(newVal) => {
                setFormData({
                  ...formData,
                  restitutionDate: newVal,
                })
              }}
              value={formData?.restitutionDate}
              views={['year', 'month', 'day']}
              disablePast
            />
          </LocalizationProvider>
          <UIFormInputSelect
            required
            label="form_input_label_location_of_car"
            options={getCompanyAddressesOptions(companyAddresses)}
            value={formData.location}
            onChange={(e) => {
              setFormData({
                ...formData,
                location: e.target.value,
              })
            }}
            showHelperText={!formData.location && showErrors}
            error={!formData.location && showErrors}
            helperText={!formData.location && showErrors ? 'Required field' : ''}
          />
          {/*<TextFieldSmallEdible*/}
          {/*  required*/}
          {/*  label="Location of the car"*/}
          {/*  value={formData.location}*/}
          {/*  onChange={(e) => {*/}
          {/*    setFormData({*/}
          {/*      ...formData,*/}
          {/*      location: e.target.value,*/}
          {/*    })*/}
          {/*  }}*/}
          {/*  error={!formData.location && showErrors}*/}
          {/*  helperText={!formData.location && showErrors ? 'Required field' : ''}*/}
          {/*/>*/}
          <TextFieldSmallEdible
            required
            label="form_input_label_mileage"
            value={sumDelimiter(formData.mileage)}
            inputProps={{
              inputMode: 'numeric',
            }}
            onChange={(e) => {
              setFormData({
                ...formData,
                mileage: e.target.value.replace(/[^0-9]/g, ''),
              })
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <UITextBodySmall>km</UITextBodySmall>
                </InputAdornment>
              ),
            }}
            error={!formData.mileage && showErrors}
            helperText={!formData.mileage && showErrors ? 'Required field' : ''}
          />
          <UIFormInputSelect
            required
            label="form_input_label_vehicle_expertise"
            options={expertiseOptions}
            value={formData.vehicleExpertise}
            onChange={(e) =>
              setFormData({
                ...formData,
                vehicleExpertise: e.target.value,
              })
            }
            showHelperText={!formData.vehicleExpertise && showErrors}
            error={!formData.vehicleExpertise && showErrors}
            helperText={!formData.vehicleExpertise && showErrors ? 'Required field' : ''}
          />
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} gap="8px">
          <ButtonHollow sx={{ flex: 1 }} onClick={() => setOpen(false)}>
            {t('button_cancel')}
          </ButtonHollow>
          <ButtonAttention
            disabled={!formValid && showErrors}
            sx={{ flex: 1 }}
            onClick={() => handleStartRestitution()}
          >
            {t('button_request_restitution')}
          </ButtonAttention>
        </Stack>
      </Stack>
    </ModalWrap>
  )
}

export default RestituteVehicleModal
