import React, { useEffect, useState } from 'react'
import { Box, Button, IconButton, InputAdornment, useMediaQuery } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import ModalWrap from './ModalWrap'
import TextH1 from '../../ui/Text/TextH1'
import IconWA from '../../assests/img/icons/icon-wa.svg'
import IconTG from '../../assests/img/icons/icon-tg.png'
import TextFieldSmallEdible from '../../ui/TextFieldSmallEdible'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import TextNormal from '../../ui/Text/TextNormal'
import { colorsConfig } from '../../config/themeConfig'

const FinanceLinkModal = ({ open, setOpen, link, customerName }) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const [linkCopySuccess, setLinkCopySuccess] = useState(false)

  const linkText =
    `Dear ${customerName}, \n\n` +
    'The banks have responded to your application. Please look and choose the offer that suits you best. \n\n' +
    link

  const templateText =
    `%0ADear ${customerName}, %0A%0A` +
    'The banks have responded to your application. Please look and choose the offer that suits you best.'

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(link)
    setLinkCopySuccess(true)
  }

  return (
    <ModalWrap open={open} setOpen={setOpen}>
      <TextH1
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          fontSize: '32px',
          mb: '15px',
          '&.xs-only': {
            fontSize: '24px',
          },
        }}
      >
        Copy approval link
      </TextH1>
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          position: 'relative',
          mb: '30px',
          '&.xs-only': {
            mb: '15px',
          },
        }}
      >
        <TextFieldSmallEdible
          fullWidth
          value={link}
          readOnly
          sx={{
            '& .MuiInputBase-root': {
              pr: 0,
            },
          }}
          onFocus={(event) => {
            event.target.select()
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position={'end'}>
                <Button
                  sx={{
                    textTransform: 'none',
                    background: colorsConfig.mainBlue,
                    color: 'white',
                    borderRadius: '0 6px 6px 0',
                    transition: '0.25s all ease-in-out',
                    '&:hover': {
                      background: colorsConfig.buttonHover,
                      transition: '0.25s all ease-in-out',
                    },
                  }}
                  onClick={() => {
                    handleCopyLink()
                  }}
                >
                  <ContentCopy
                    sx={{
                      transition: '0.25s all ease-in-out',
                      fill: 'white',
                    }}
                  />
                  <TextNormal
                    sx={{
                      fontSize: '14px',
                      color: 'white',
                      ml: '5px',
                      transition: '0.25s all ease-in-out',
                    }}
                  >
                    Copy
                  </TextNormal>
                </Button>
              </InputAdornment>
            ),
          }}
        />
        <TextNormal
          className={linkCopySuccess ? 'success' : ''}
          sx={{
            color: colorsConfig.mainGreen,
            my: '5px',
            fontSize: '14px',
            position: 'absolute',
            left: 0,
            top: '100%',
            opacity: 0,
            transition: '0.25s all ease-in-out',
            '&.success': {
              opacity: 1,
              transition: '0.25s all ease-in-out',
            },
          }}
        >
          The link has been copied to your clipboard
        </TextNormal>
      </Box>
      <TextBoldNormal sx={{ mt: '15px' }}>Or share via:</TextBoldNormal>
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
        }}
      >
        <IconButton
          component={'a'}
          href={`https://wa.me/?text=${encodeURIComponent(linkText)}`}
          target={'_blank'}
          sx={{
            width: '50px',
            height: '50px',
            borderRadius: '6px',
          }}
        >
          <img
            src={IconWA}
            alt=""
            style={{
              width: '80%',
            }}
          />
        </IconButton>
        <IconButton
          component={'a'}
          href={`https://telegram.me/share/url?url=${link}&text=${templateText}`}
          target={'_blank'}
          sx={{
            width: '50px',
            height: '50px',
            borderRadius: '6px',
          }}
        >
          <img
            src={IconTG}
            alt=""
            style={{
              width: '80%',
            }}
          />
        </IconButton>
      </Box>
    </ModalWrap>
  )
}

export default FinanceLinkModal
