import { getAllCompanies } from '../companies.actions'
import { apiConstants } from '../../../../api/api'

export default (builder) => {
  builder
    .addCase(getAllCompanies.pending, (state) => {
      state.allCompanies.isLoading = true
    })
    .addCase(getAllCompanies.fulfilled, (state, action) => {
      state.allCompanies.isLoading = false
      state.allCompanies.data = action.payload?.[apiConstants.hydraMember]
    })
    .addCase(getAllCompanies.rejected, (state, action) => {
      state.allCompanies.isLoading = false
    })
}
