import { rviCloseRestitution } from '../rviOperator.actions'

export default (builder) => {
  builder
    .addCase(rviCloseRestitution.pending, (state) => {
      state.closeRestitution.isLoading = true
    })
    .addCase(rviCloseRestitution.fulfilled, (state, action) => {
      state.closeRestitution.isLoading = false
      state.closeRestitution.error = null
    })
    .addCase(rviCloseRestitution.rejected, (state, action) => {
      state.closeRestitution.isLoading = false
      state.closeRestitution.error = action.payload
    })
}
