import React from 'react'
import { Box, Autocomplete } from '@mui/material'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { countries } from '../../../default-data/coutries'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'

const FormSelectCountry = ({
  label,
  id,
  required,
  suffix,
  regExp,
  validateOnChange,
  maxLength,
  valueFormatter,
  onChangeCallback,
  ...props
}) => {
  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers
  const { t } = useTranslation()

  const handleChange = (value) => {
    if (maxLength && value.length > maxLength) return
    if (regExp) {
      setValue(value.replace(regExp, ''))
      if (onChangeCallback) onChangeCallback(value.replace(regExp, ''))
      return
    }
    setValue(value)
    if (onChangeCallback) onChangeCallback(value)
  }

  return (
    <Autocomplete
      error={meta.error}
      id="address-country"
      options={[
        {
          label: null,
        },
        ...countries,
      ]}
      getOptionLabel={(option) => option.label || ''}
      fullWidth
      autoHighlight
      value={field.value}
      onChange={(e, newVal) => {
        handleChange(newVal)
      }}
      renderOption={(props, option) => {
        if (!option.label) return null
        return (
          <Box
            component="li"
            sx={{
              '& > img': {
                mr: 2,
                flexShrink: 0,
              },
            }}
            {...props}
          >
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              alt=""
            />
            {option.label}
          </Box>
        )
      }}
      renderInput={(params) => (
        <TextFieldSmallEdible
          {...params}
          error={meta.error}
          helperText={meta.error}
          required={required}
          isAutocomplete
          label={t(label)}
        />
      )}
      {...props}
    />
  )
}

export default FormSelectCountry
