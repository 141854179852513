import { createSlice } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'
import {
  privateRequest,
  privateMultipartRequest,
  patchRequest,
  publicRequest,
} from '../../utils/request'
import { localesConfig } from '../../i18n/langConfig'
import { getToken, localStorageKeys, removeToken } from '../../utils/localStorage.helpers'
import { ROLES } from '../../utils/constants/global.constants'

const initialState = {
  isAuthenticated: false,
  token: '',
  refreshToken: '',
  mercureToken: '',
  role: '',
  roles: [],
  companyId: null,
  login: '',
  avatar: null,
  balance: 0,
  auctionCarsAmount: null,
  company: null,
  unreadNotificationsCount: 0,
  currentPageTitle: 'Virtual Garage',
  coinPricings: [],
  locale: localesConfig.find((item) => item.code === 'en'),
  user: {},
  signedIn: false,
  userSettings: {
    firstName: '',
    surname: '',
    phoneNumber: '',
  },
  companySettings: {
    name: '',
    landlineNumber: '',
    tradeLicenseNumber: '',
    taxRegistrationNumber: '',
    tradeLicenseExpiryDate: '',
  },
  companyAddress: {
    country: '',
    state: '',
    postcode: '',
    addressLine1: '',
    addressLine2: '',
  },
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    signIn: (state, action) => {
      state.token = action.payload.token
      state.refreshToken = action.payload.refreshToken
      state.mercureToken = action.payload.mercureToken
      const userInfo = jwtDecode(action.payload.token)
      state.login = userInfo.login
      state.roles = userInfo.roles
      state.companyId = userInfo.companyId
      privateRequest.defaults.headers['Authorization'] = `Bearer ${action.payload.token}`
      privateMultipartRequest.defaults.headers['Authorization'] = `Bearer ${action.payload.token}`
      patchRequest.defaults.headers['Authorization'] = `Bearer ${action.payload.token}`
      if (userInfo.roles.find((item) => item === 'ROLE_DEALER')) {
        state.role = 'COMPANY'
      }
      if (userInfo.roles.filter((item) => item === 'ROLE_OPERATOR')[0]) {
        state.role = 'OPERATOR'
      }
      if (userInfo.roles.filter((item) => item === 'ROLE_EXPERT')[0]) {
        state.role = 'EXPERT'
      }
      if (userInfo.roles.filter((item) => item === 'ROLE_ADMIN')[0]) {
        state.role = 'ADMIN'
      }
      state.isAuthenticated = true
      state.signedIn = true
    },
    checkAuth: (state) => {
      const token = getToken(localStorageKeys.token)
      if (token) {
        state.isAuthenticated = true
        const userInfo = jwtDecode(token)

        state.login = userInfo.login
        state.companyId = userInfo.companyId
        if (userInfo.roles.filter((item) => item === 'ROLE_DEALER')[0]) {
          state.role = 'COMPANY'
        }
        if (userInfo.roles.filter((item) => item === 'ROLE_OPERATOR')[0]) {
          state.role = 'OPERATOR'
        }
        if (userInfo.roles.filter((item) => item === 'ROLE_EXPERT')[0]) {
          state.role = 'EXPERT'
        }
        if (userInfo.roles.filter((item) => item === 'ROLE_ADMIN')[0]) {
          state.role = 'ADMIN'
        }
      }
    },
    logOut: (state) => {
      state.isAuthenticated = false
      state.token = ''
      state.refreshToken = ''
      state.role = ''
      state.roles = []
      state.avatar = null
      removeToken(localStorageKeys.token)
      removeToken(localStorageKeys.refreshToken)
      removeToken(localStorageKeys.mercureToken)
      window.localStorage.removeItem('userData')
      privateRequest.defaults.headers['Accept-Language'] = 'en'
      privateMultipartRequest.defaults.headers['Accept-Language'] = 'en'
      patchRequest.defaults.headers['Accept-Language'] = 'en'
    },
    setAvatar: (state, action) => {
      state.avatar = action.payload
    },
    setAuctionCarsAmount: (state, action) => {
      state.auctionCarsAmount = action.payload.auctionCarsAmount
    },
    setBalance: (state, action) => {
      state.balance = action.payload
    },
    setCompany: (state, action) => {
      state.company = action.payload
    },
    setCurrentPageTitle: (state, action) => {
      state.currentPageTitle = action.payload
    },
    setUnreadNotificationsCount: (state, action) => {
      state.unreadNotificationsCount = action.payload
    },
    setToken: (state, action) => {
      state.token = action.payload
      privateRequest.defaults.headers['Authorization'] = `Bearer ${action.payload}`
      privateMultipartRequest.defaults.headers['Authorization'] = `Bearer ${action.payload}`
      patchRequest.defaults.headers['Authorization'] = `Bearer ${action.payload}`
      const userData = JSON.parse(window.localStorage.getItem('userData'))
      userData.accessToken = action.payload
      window.localStorage.setItem('userData', JSON.stringify(userData))
    },
    setCoinPricings: (state, action) => {
      state.coinPricings = action.payload
    },
    setUser: (state, action) => {
      state.user = action.payload
      state.userSettings.firstName = action.payload.manager?.firstName
      state.userSettings.surname = action.payload.manager?.surname
      state.userSettings.phoneNumber = action.payload.manager?.phoneNumber
      state.companySettings.name = action.payload.manager?.company?.name
      state.companySettings.externalDealerId = action.payload.manager?.company?.externalDealerId
      state.companySettings.tradeLicenseNumber = action.payload.manager?.company?.tradeLicenseNumber
      state.companySettings.tradeLicenseExpiryDate =
        action.payload.manager?.company?.tradeLicenseExpiryDate
      state.companySettings.taxRegistrationNumber =
        action.payload.manager?.company?.taxRegistrationNumber
      state.companySettings.landlineNumber = action.payload.manager?.company?.landlineNumber
      state.companyAddress.country = action.payload.manager?.company?.address?.country
      state.companyAddress.city = action.payload.manager?.company?.address?.city
      state.companyAddress.state = action.payload.manager?.company?.address?.state
      state.companyAddress.postcode = action.payload.manager?.company?.address?.postcode
      state.companyAddress.addressLine1 = action.payload.manager?.company?.address?.addressLine1
      state.companyAddress.addressLine2 = action.payload.manager?.company?.address?.addressLine2
    },
    updateUserSettings: (state, action) => {
      state.userSettings = action.payload
    },
    updateManagerEmail: (state, action) => {
      state.user.email = action.payload
    },
    updateCompanySettings: (state, action) => {
      state.companySettings = action.payload
    },
    updateCompanyAddress: (state, action) => {
      state.companyAddress = action.payload
    },
    patchUser: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
        manager: {
          ...state.user?.manager,
          ...action.payload?.manager,
          company: {
            ...state.user?.manager?.company,
            ...action.payload?.manager?.company,
          },
        },
      }
    },
  },
})

export const {
  signIn,
  checkAuth,
  logOut,
  setAvatar,
  setAuctionCarsAmount,
  setBalance,
  setCompany,
  setCurrentPageTitle,
  setUnreadNotificationsCount,
  setToken,
  setCoinPricings,
  setUser,
  updateUserSettings,
  updateManagerEmail,
  updateCompanySettings,
  updateCompanyAddress,
  patchUser,
} = authSlice.actions

export default authSlice.reducer
