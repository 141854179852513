import { createSlice } from '@reduxjs/toolkit'
import getCoinPricingsBuilder from './builder/getCoinPricings.builder'
import updateCoinPricingBuilder from './builder/updateCoinPricing.builder'

const initialState = {
  coinPricings: [],
  loading: false,
  error: null,
  updateCoinPricing: {
    isLoading: false,
    error: null,
  },
}

const coinPricingsSlice = createSlice({
  name: 'coinPricings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getCoinPricingsBuilder(builder)
    updateCoinPricingBuilder(builder)
  },
})

export default coinPricingsSlice.reducer
