import { privateRequest } from '../../utils/request'

class ProfileApiService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      manager: '/user/profile/manager/credentials',
      company: '/user/profile/manager/company/credentials',
      address: '/user/profile/manager/company/address/credentials',
      email: '/user/profile/change-email',
      password: '/user/profile/change-password',
      avatar: '/user/profile/change-avatar',
    }
  }

  updateCompanyCredentials = async (data) => {
    const response = await this.#api.put(this.#endPoints.company, data)
    return response
  }
}

const profileApiService = new ProfileApiService({ api: privateRequest })

export default profileApiService
