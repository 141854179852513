import { createSlice } from '@reduxjs/toolkit'
import createCustomerAppraisalBuilder from './builders/createCustomerAppraisal.builder'
import sendAppraisalLinkByEmailBuilder from './builders/sendAppraisalLinkByEmail.builder'
import createCustomerCarBuilder from './builders/createCustomerCar.builder'
import updateCustomerCarBuilder from './builders/updateCustomerCar.builder'
import submitCustomerAppraisalBuilder from './builders/submitCustomerAppraisal.builder'
import getCustomerAppraisalBuilder from './builders/getCustomerAppraisal.builder'
import deleteCustomerAppraisalBuilder from './builders/deleteCustomerAppraisal.builder'

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  create: {
    isLoading: false,
    error: null,
    data: null,
  },
  sendEmail: {
    isLoading: false,
    error: null,
  },
  submitAppraisal: {
    isLoading: false,
    error: null,
    success: false,
  },
  delete: {
    isLoading: false,
    error: null,
  },
  customer: {
    isLoading: false,
    error: null,
    data: null,
    car: {
      data: null,
      create: {
        isLoading: false,
        error: null,
      },
      update: {
        isLoading: false,
        error: null,
      },
    },
  },
}

const customerAppraisalSlice = createSlice({
  name: 'customerAppraisal',
  initialState,
  reducers: {
    resetAddedCustomerAppraisal: (state) => {
      state.sendEmail = initialState.sendEmail
      state.create = initialState.create
    },
  },
  extraReducers: (builder) => {
    getCustomerAppraisalBuilder(builder)
    createCustomerAppraisalBuilder(builder)
    sendAppraisalLinkByEmailBuilder(builder)
    createCustomerCarBuilder(builder)
    updateCustomerCarBuilder(builder)
    submitCustomerAppraisalBuilder(builder)
    deleteCustomerAppraisalBuilder(builder)
  },
})

export const { resetAddedCustomerAppraisal } = customerAppraisalSlice.actions

export default customerAppraisalSlice.reducer
