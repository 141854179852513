import React from 'react'
import { Radio, styled } from '@mui/material'
import { colorsConfig } from '../config/themeConfig'

const CustomRadio = (props) => {
  const Icon = styled('span')(({ theme }) => ({
    width: '23px',
    height: '23px',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: colorsConfig.mainBlack,
    background: 'white',
  }))

  const CheckedIcon = styled('span')(({ theme }) => ({
    width: '23px',
    height: '23px',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: colorsConfig.mainBlack,
    position: 'relative',
    background: 'white',
    '&::before': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      background: colorsConfig.mainBlue,
      top: '50%',
      left: '50%',
      marginLeft: '-8px',
      marginTop: '-8px',
    },
  }))

  return <Radio checkedIcon={<CheckedIcon />} icon={<Icon />} {...props} />
}

export default CustomRadio
