import React, { useEffect, useState } from 'react'
import { Box, FormControl, FormControlLabel, Grid, RadioGroup } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setCurrentPageTitle } from '../../../../store/features/authSlice'
import TextH1 from '../../../../ui/Text/TextH1'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import SimpleCheckbox from '../../../../ui/SimpleCheckbox'
import TextNormal from '../../../../ui/Text/TextNormal'
import { adminRequest } from '../../../../utils/request'
import { API_FINANCE_ADMIN_ENDPOINTS } from '../../../../config/default'
import { toast } from 'react-toastify'
import IconBank from '../../../../assests/img/icons/icon-bank.svg'
import BreadcrumbsComponent from '../../../../components/BreadcrumbsComponent'
import UITextRegular from '../../../../ui/UIText/UITextRegular'
import CustomRadio from '../../../../ui/CustomRadio'

const AddBank = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [bankName, setBankName] = useState('')
  const [bankShortName, setBankShortName] = useState('')
  const [bankEmail, setBankEmail] = useState('')
  const [bankCode, setBankCode] = useState('')
  const [isActive, setIsActive] = useState(false)
  const [logoLink, setLogoLink] = useState('')
  const [packageType, setPackageType] = useState('normal')

  const validateEmail = (value) => {
    if (
      !value ||
      !value.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
    )
      return false
    return true
  }

  const hasErrors = !bankName || !bankCode || !validateEmail(bankEmail)

  const handleAddBank = () => {
    const formData = {
      bank: {
        email: bankEmail,
      },
      title: bankName,
      code: bankCode,
      type: 'bank',
      isActive: isActive,
      shortTitle: bankShortName,
      logo: logoLink,
      isBasic: packageType === 'basic',
      isFastest: packageType === 'fastest',
    }
    adminRequest
      .post(API_FINANCE_ADMIN_ENDPOINTS.productProviders, formData)
      .then((response) => {
        toast.success('Added bank successfully', { autoClose: 1000 })
        navigate('/admin/financing/banks')
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data['hydra:description']) {
          toast.error(error.response.data['hydra:description'])
        } else toast.error('Unexpected error')
      })
  }

  useEffect(() => {
    dispatch(setCurrentPageTitle('Add a new bank'))
  }, [])

  return (
    <Box>
      <BreadcrumbsComponent
        currentPage={{ name: `Add a new bank` }}
        parentPages={[
          {
            name: 'Banks',
            icon: (
              <img src={IconBank} style={{ filter: 'brightness(73%)', width: '13px' }} alt="" />
            ),
            link: '/admin/financing/banks',
          },
        ]}
      />
      <TextH1
        sx={{
          fontSize: '36px',
          mb: '30px',
          mt: '10px',
        }}
      >
        Add a new bank
      </TextH1>
      <Grid container spacing={'20px'}>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Bank name'}
            value={bankName}
            onChange={(e) => {
              setBankName(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            fullWidth
            label={'Bank short name'}
            value={bankShortName}
            onChange={(e) => {
              setBankShortName(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Bank email'}
            value={bankEmail}
            onChange={(e) => {
              setBankEmail(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Bank code'}
            value={bankCode}
            onChange={(e) => {
              setBankCode(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            fullWidth
            label={'Logo link'}
            value={logoLink}
            onChange={(e) => {
              setLogoLink(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <SimpleCheckbox value={isActive} setValue={(value) => setIsActive(value)} />
            <TextNormal
              sx={{
                pl: '6px',
                cursor: 'pointer',
              }}
              onClick={() => setIsActive(!isActive)}
            >
              Is active
            </TextNormal>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <UITextRegular sx={{ fontWeight: 700 }} text="Set bank offers as" />
          <FormControl>
            <RadioGroup
              row
              value={packageType}
              onChange={(e) => {
                setPackageType(e.target.value)
              }}
              sx={{
                gap: '30px',
              }}
            >
              <FormControlLabel control={<CustomRadio />} value="normal" label="Normal" />
              <FormControlLabel control={<CustomRadio />} value="basic" label="Basic" />
              <FormControlLabel control={<CustomRadio />} value="fastest" label="Fastest" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: '40px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <ButtonLG
          sx={{
            minWidth: '187px',
          }}
          disabled={hasErrors}
          onClick={() => {
            handleAddBank()
          }}
        >
          Save
        </ButtonLG>
      </Box>
    </Box>
  )
}

export default AddBank
