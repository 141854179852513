import React from 'react'
import { Button } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const ButtonLinkSecondary = ({ children, sx, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        fontFamily: '"Helvetica", sans-serif',
        fontWeight: 700,
        fontSize: '16px',
        color: colorsConfig.mainBlack,
        textTransform: 'none',
        pl: '15px',
        py: '3px',
        '&::after': {
          content: '" "',
          position: 'absolute',
          display: 'block',
          left: '6px',
          top: '50%',
          mt: '-7.5px',
          width: '3px',
          height: '15px',
          background: colorsConfig.mainBlue,
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  )
}

export default ButtonLinkSecondary
