import React, { useState, useEffect } from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { setWorkflowState } from '../../../store/features/carSlice/carSlice'
import { useDispatch } from 'react-redux'
import { colorsConfig } from '../../../config/themeConfig'
import IconClocks from '../../../assests/img/icons/icon-clocks.svg'
import TextNormal from '../../../ui/Text/TextNormal'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'

const AuctionTimer = (props) => {
  const { startTime, endTime, currentStatus, mode } = props

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const dispatch = useDispatch()

  const [timeTillStart, setTimeTillStart] = useState(null)
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    let timer
    if (currentStatus === 'auction_workflow.pending') {
      const currentTime = new Date().getTime()
      const startTimeDate = new Date(startTime).getTime()
      setTimeTillStart(startTimeDate - currentTime)
      timer = setInterval(() => {
        setTimeTillStart((prev) => prev - 1000)
      }, 1000)
    } else if (currentStatus === 'auction_workflow.active') {
      const currentTime = new Date().getTime()
      const endTimeDate = new Date(endTime).getTime()
      setTimeTillStart(endTimeDate - currentTime)
      timer = setInterval(() => {
        setTimeTillStart((prev) => prev - 1000)
      }, 1000)
    } else if (currentStatus === 'public') {
      const currentTime = new Date().getTime()
      const endTimeDate = endTime.getTime()
      setTimeTillStart(endTimeDate - currentTime)
      timer = setInterval(() => {
        setTimeTillStart((prev) => prev - 1000)
      }, 1000)
    }
    return () => {
      clearInterval(timer)
    }
  }, [currentStatus])

  useEffect(() => {
    setDays(Math.floor(timeTillStart / 86400000))
    setHours(Math.floor(timeTillStart / (60 * 60 * 1000)) % 24)
    setMinutes(Math.floor(timeTillStart / (60 * 1000)) % 60)
    setSeconds(Math.floor(timeTillStart / 1000) % 60)
    if (timeTillStart && parseFloat(timeTillStart) <= 1000 && currentStatus !== 'public') {
      if (currentStatus === 'auction_workflow.pending') {
        dispatch(setWorkflowState('auction_workflow.active'))
      }
      if (currentStatus === 'auction_workflow.active') {
        dispatch(setWorkflowState('auction_workflow.completed'))
      }
    }
  }, [timeTillStart])

  return (
    <>
      {mode === 'small' ? (
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            display: timeTillStart && parseFloat(timeTillStart) <= 1000 ? 'none' : 'flex',
            height: '48px',
            px: '60px',
            border: '1px solid',
            borderColor: colorsConfig.mainBlue,
            borderRadius: '4px',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            '&.xs-only': {
              px: '10px',
              width: '100%',
            },
          }}
        >
          <img src={IconClocks} style={{ marginRight: '9px' }} alt="" />
          <TextNormal
            sx={{
              mr: '5px',
              whiteSpace: 'nowrap',
            }}
          >
            {currentStatus === 'auction_workflow.pending' ? 'Time before start:' : ''}
            {currentStatus === 'auction_workflow.active' ? 'Auction will end:' : ''}
          </TextNormal>
          <TextBoldNormal
            sx={{
              color: colorsConfig.mainBlue,
              whiteSpace: 'nowrap',
            }}
          >
            {days} {days === 1 ? 'day' : 'days'} {hours < 10 ? `0${hours}` : hours}:
            {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </TextBoldNormal>
          {/*<Typography*/}
          {/*    variant='body2'*/}
          {/*    sx={{*/}
          {/*        fontSize: '15px',*/}
          {/*        color: '#a2a2a2',*/}
          {/*        fontWeight: 300,*/}
          {/*        textAlign: 'center',*/}
          {/*        mb: 1*/}
          {/*    }}*/}
          {/*>*/}
          {/*    Auction will {currentStatus === 'auction_workflow.pending' ? 'start' : ''} {currentStatus === 'auction_workflow.active' ? 'end' : ''} in*/}
          {/*</Typography>*/}
          {/*<Box*/}
          {/*    sx={{*/}
          {/*        display: 'flex',*/}
          {/*        flexDirection: 'row',*/}
          {/*        justifyContent: 'space-between',*/}
          {/*        alignItems: 'center'*/}
          {/*    }}*/}
          {/*>*/}
          {/*    /!*DAYS*!/*/}
          {/*    <Box*/}
          {/*        sx={{*/}
          {/*            display: 'flex',*/}
          {/*            flexDirection: 'column',*/}
          {/*            justifyContent: 'center',*/}
          {/*            alignItems: 'center'*/}
          {/*        }}*/}
          {/*    >*/}
          {/*        <Box*/}
          {/*            sx={{*/}
          {/*                display: 'flex',*/}
          {/*                flexDirection: 'row',*/}
          {/*                justifyContent: 'center',*/}
          {/*                alignItems: 'center'*/}
          {/*            }}*/}
          {/*        >*/}
          {/*            <Typography*/}
          {/*                sx={{*/}
          {/*                    fontWeight: 500,*/}
          {/*                    fontSize: '20px',*/}
          {/*                    background: 'black',*/}
          {/*                    px: '6px',*/}
          {/*                    py: '1px',*/}
          {/*                    borderRadius: '4px',*/}
          {/*                    color: 'white'*/}
          {/*                }}*/}
          {/*            >*/}
          {/*                {days}*/}
          {/*            </Typography>*/}
          {/*        </Box>*/}
          {/*        <Typography*/}
          {/*            variant='body2'*/}
          {/*            sx={{*/}
          {/*                fontWeight: 300,*/}
          {/*                fontSize: '14px'*/}
          {/*            }}*/}
          {/*        >*/}
          {/*            {days === 1 ? 'day' : 'days'}*/}
          {/*        </Typography>*/}
          {/*    </Box>*/}
          {/*    /!*HOURS*!/*/}
          {/*    <Box*/}
          {/*        sx={{*/}
          {/*            display: 'flex',*/}
          {/*            flexDirection: 'column',*/}
          {/*            justifyContent: 'center',*/}
          {/*            alignItems: 'center'*/}
          {/*        }}*/}
          {/*    >*/}
          {/*        <Box*/}
          {/*            sx={{*/}
          {/*                display: 'flex',*/}
          {/*                flexDirection: 'row',*/}
          {/*                justifyContent: 'center',*/}
          {/*                alignItems: 'center'*/}
          {/*            }}*/}
          {/*        >*/}
          {/*            <Typography*/}
          {/*                sx={{*/}
          {/*                    fontWeight: 500,*/}
          {/*                    fontSize: '20px',*/}
          {/*                    background: 'black',*/}
          {/*                    px: '6px',*/}
          {/*                    py: '1px',*/}
          {/*                    borderRadius: '4px',*/}
          {/*                    color: 'white',*/}
          {/*                    marginRight: '3px'*/}
          {/*                }}*/}
          {/*            >*/}
          {/*                {hours < 10 ? '0' : hours.toString().charAt(0)}*/}
          {/*            </Typography>*/}
          {/*            <Typography*/}
          {/*                sx={{*/}
          {/*                    fontWeight: 500,*/}
          {/*                    fontSize: '20px',*/}
          {/*                    background: 'black',*/}
          {/*                    px: '6px',*/}
          {/*                    py: '1px',*/}
          {/*                    borderRadius: '4px',*/}
          {/*                    color: 'white'*/}
          {/*                }}*/}
          {/*            >*/}
          {/*                {hours.toString().charAt(hours.toString().length - 1)}*/}
          {/*            </Typography>*/}
          {/*        </Box>*/}
          {/*        <Typography*/}
          {/*            variant='body2'*/}
          {/*            sx={{*/}
          {/*                fontWeight: 300,*/}
          {/*                fontSize: '14px'*/}
          {/*            }}*/}
          {/*        >*/}
          {/*            {hours === 1 ? 'hour' : 'hours'}*/}
          {/*        </Typography>*/}
          {/*    </Box>*/}
          {/*    /!*MINUTES*!/*/}
          {/*    <Box*/}
          {/*        sx={{*/}
          {/*            display: 'flex',*/}
          {/*            flexDirection: 'column',*/}
          {/*            justifyContent: 'center',*/}
          {/*            alignItems: 'center'*/}
          {/*        }}*/}
          {/*    >*/}
          {/*        <Box*/}
          {/*            sx={{*/}
          {/*                display: 'flex',*/}
          {/*                flexDirection: 'row',*/}
          {/*                justifyContent: 'center',*/}
          {/*                alignItems: 'center'*/}
          {/*            }}*/}
          {/*        >*/}
          {/*            <Typography*/}
          {/*                sx={{*/}
          {/*                    fontWeight: 500,*/}
          {/*                    fontSize: '20px',*/}
          {/*                    background: 'black',*/}
          {/*                    px: '6px',*/}
          {/*                    py: '1px',*/}
          {/*                    borderRadius: '4px',*/}
          {/*                    color: 'white',*/}
          {/*                    marginRight: '3px'*/}
          {/*                }}*/}
          {/*            >*/}
          {/*                {minutes < 10 ? '0' : minutes.toString().charAt(0)}*/}
          {/*            </Typography>*/}
          {/*            <Typography*/}
          {/*                sx={{*/}
          {/*                    fontWeight: 500,*/}
          {/*                    fontSize: '20px',*/}
          {/*                    background: 'black',*/}
          {/*                    px: '6px',*/}
          {/*                    py: '1px',*/}
          {/*                    borderRadius: '4px',*/}
          {/*                    color: 'white'*/}
          {/*                }}*/}
          {/*            >*/}
          {/*                {minutes.toString().charAt(minutes.toString().length - 1)}*/}
          {/*            </Typography>*/}
          {/*        </Box>*/}
          {/*        <Typography*/}
          {/*            variant='body2'*/}
          {/*            sx={{*/}
          {/*                fontWeight: 300,*/}
          {/*                fontSize: '14px'*/}
          {/*            }}*/}
          {/*        >*/}
          {/*            {minutes === 1 ? 'minute' : 'minutes'}*/}
          {/*        </Typography>*/}
          {/*    </Box>*/}
          {/*    /!*SECONDS*!/*/}
          {/*    <Box*/}
          {/*        sx={{*/}
          {/*            display: 'flex',*/}
          {/*            flexDirection: 'column',*/}
          {/*            justifyContent: 'center',*/}
          {/*            alignItems: 'center'*/}
          {/*        }}*/}
          {/*    >*/}
          {/*        <Box*/}
          {/*            sx={{*/}
          {/*                display: 'flex',*/}
          {/*                flexDirection: 'row',*/}
          {/*                justifyContent: 'center',*/}
          {/*                alignItems: 'center'*/}
          {/*            }}*/}
          {/*        >*/}
          {/*            <Typography*/}
          {/*                sx={{*/}
          {/*                    fontWeight: 500,*/}
          {/*                    fontSize: '20px',*/}
          {/*                    background: 'black',*/}
          {/*                    px: '6px',*/}
          {/*                    py: '1px',*/}
          {/*                    borderRadius: '4px',*/}
          {/*                    color: 'white',*/}
          {/*                    marginRight: '3px'*/}
          {/*                }}*/}
          {/*            >*/}
          {/*                {seconds < 10 ? '0' : seconds.toString().charAt(0)}*/}
          {/*            </Typography>*/}
          {/*            <Typography*/}
          {/*                sx={{*/}
          {/*                    fontWeight: 500,*/}
          {/*                    fontSize: '20px',*/}
          {/*                    background: 'black',*/}
          {/*                    px: '6px',*/}
          {/*                    py: '1px',*/}
          {/*                    borderRadius: '4px',*/}
          {/*                    color: 'white'*/}
          {/*                }}*/}
          {/*            >*/}
          {/*                {seconds.toString().charAt(seconds.toString().length - 1)}*/}
          {/*            </Typography>*/}
          {/*        </Box>*/}
          {/*        <Typography*/}
          {/*            variant='body2'*/}
          {/*            sx={{*/}
          {/*                fontWeight: 300,*/}
          {/*                fontSize: '14px'*/}
          {/*            }}*/}
          {/*        >*/}
          {/*            {seconds === 1 ? 'second' : 'seconds'}*/}
          {/*        </Typography>*/}
          {/*    </Box>*/}
          {/*</Box>*/}
        </Box>
      ) : null}
      {mode === 'large' ? (
        <Box
          sx={{
            display: timeTillStart && parseFloat(timeTillStart) <= 1000 ? 'none' : 'block',
            mb: '20px',
          }}
        >
          <TextNormal
            sx={{
              mb: '10px',
            }}
          >
            {timeTillStart < 1000 ? (
              'Auction is over'
            ) : (
              <>
                Auction will {currentStatus === 'auction_workflow.pending' ? 'start' : ''}{' '}
                {currentStatus === 'auction_workflow.active' ? 'end' : ''} in
              </>
            )}
          </TextNormal>
          {timeTillStart < 1000 ? null : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {/*DAYS*/}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: '10px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: '"Helvetica", sans-serif',
                      fontWeight: 700,
                      fontSize: '18px',
                      background: colorsConfig.mainBlack,
                      width: '28px',
                      height: '32px',
                      borderRadius: '6px',
                      color: 'white',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {days}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '12px',
                    fontFamily: '"Helvetica", sans-serif',
                    color: colorsConfig.mainBlack,
                  }}
                >
                  {days === 1 ? 'day' : 'days'}
                </Typography>
              </Box>
              {/*HOURS*/}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: '10px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: '"Helvetica", sans-serif',
                      fontWeight: 700,
                      fontSize: '18px',
                      background: colorsConfig.mainBlack,
                      width: '28px',
                      height: '32px',
                      borderRadius: '6px',
                      color: 'white',
                      marginRight: '3px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {hours < 10 ? '0' : hours.toString().charAt(0)}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: '"Helvetica", sans-serif',
                      fontWeight: 700,
                      fontSize: '18px',
                      background: colorsConfig.mainBlack,
                      width: '28px',
                      height: '32px',
                      borderRadius: '6px',
                      color: 'white',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {hours.toString().charAt(hours.toString().length - 1)}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 400,
                    fontSize: '12px',
                    fontFamily: '"Helvetica", sans-serif',
                    color: colorsConfig.mainBlack,
                  }}
                >
                  {hours === 1 ? 'hour' : 'hours'}
                </Typography>
              </Box>
              {/*MINUTES*/}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: '10px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: '"Helvetica", sans-serif',
                      fontWeight: 700,
                      fontSize: '18px',
                      background: colorsConfig.mainBlack,
                      width: '28px',
                      height: '32px',
                      borderRadius: '6px',
                      color: 'white',
                      marginRight: '3px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {minutes < 10 ? '0' : minutes.toString().charAt(0)}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: '"Helvetica", sans-serif',
                      fontWeight: 700,
                      fontSize: '18px',
                      background: colorsConfig.mainBlack,
                      width: '28px',
                      height: '32px',
                      borderRadius: '6px',
                      color: 'white',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {minutes.toString().charAt(minutes.toString().length - 1)}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 400,
                    fontSize: '12px',
                    fontFamily: '"Helvetica", sans-serif',
                    color: colorsConfig.mainBlack,
                  }}
                >
                  {minutes === 1 ? 'minute' : 'minutes'}
                </Typography>
              </Box>
              {/*SECONDS*/}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: '"Helvetica", sans-serif',
                      fontWeight: 700,
                      fontSize: '18px',
                      background: colorsConfig.mainBlack,
                      width: '28px',
                      height: '32px',
                      borderRadius: '6px',
                      color: 'white',
                      marginRight: '3px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {seconds < 10 ? '0' : seconds.toString().charAt(0)}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: '"Helvetica", sans-serif',
                      fontWeight: 700,
                      fontSize: '18px',
                      background: colorsConfig.mainBlack,
                      width: '28px',
                      height: '32px',
                      borderRadius: '6px',
                      color: 'white',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {seconds.toString().charAt(seconds.toString().length - 1)}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 400,
                    fontSize: '12px',
                    fontFamily: '"Helvetica", sans-serif',
                    color: colorsConfig.mainBlack,
                  }}
                >
                  {seconds === 1 ? 'second' : 'seconds'}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      ) : null}
      {mode === 'mini' ? (
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            height: '36px',
            px: '7px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0,0,0,0.5)',
            borderRadius: '4px',
            '&.xs-only': {
              borderRadius: '2px',
              height: '16px',
              px: '2px',
            },
          }}
        >
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              marginRight: '6px',
              width: '18px',
              height: '18px',
              '&.xs-only': {
                width: '9px',
                height: '9px',
                mr: '3px',
                verticalAlign: 'top',
              },
            }}
          >
            <img
              src={IconClocks}
              alt=""
              style={{
                width: '100%',
                filter: 'brightness(1000%)',
                verticalAlign: 'top',
              }}
            />
          </Box>
          <Typography
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              fontFamily: '"Helvetica", sans-serif',
              fontWeight: 700,
              color: 'white',
              fontSize: '16px',
              '&.xs-only': {
                fontSize: '12px',
                fontWeight: 400,
              },
            }}
          >
            {days}:{hours < 10 ? `0${hours}` : hours}:{minutes < 10 ? `0${minutes}` : minutes}:
            {seconds < 10 ? `0${seconds}` : seconds}
          </Typography>
        </Box>
      ) : null}
    </>
  )
}

export default AuctionTimer
