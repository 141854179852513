import React from 'react'
import { Box } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const SendToOurCompoundHint = () => {
  return (
    <Box
      sx={{
        borderRadius: '20px',
        padding: '20px',
        maxWidth: '380px',
        background: 'white',
        boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
        color: colorsConfig.mainBlack,
        fontWeight: 400,
        fontSize: '14px',
        position: 'relative',
        '&::after': {
          content: '" "',
          position: 'absolute',
          display: 'block',
          background: 'white',
          transform: 'rotate(45deg)',
          borderRadius: '4px',
          right: '30px',
          bottom: '-8px',
          width: '18px',
          height: '18px',
        },
      }}
    >
      Purchase the care free package with just one click for appraisal and quality check. This will
      save you time and earn you a 'Fleatify Verified' badge.
      <br />
      <br />
      Package includes:
      <br />
      <ul className="normal-list">
        <li>Transportation</li>
        <li>Cleaning</li>
        <li>Photoshoot</li>
        <li>Storage</li>
        <li>Appraisal</li>
        <li>Quality check</li>
      </ul>
    </Box>
  )
}

export default SendToOurCompoundHint
