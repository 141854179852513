import React from 'react'
import { Box, IconButton, Modal, useMediaQuery } from '@mui/material'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import TextNormal from '../../ui/Text/TextNormal'
import IconCoin from '../../assests/img/icons/icon-fleetify-coin.svg'
import { colorsConfig } from '../../config/themeConfig'
import ButtonAttention from '../../ui/Buttons/ButtonAttention'
import TextH1 from '../../ui/Text/TextH1'
import { Close } from '@mui/icons-material'
import { useSelector } from 'react-redux'

const ApplyForQualityCheckModal = (props) => {
  const { open, setOpen, handleSubmit } = props

  const xsOnly = useMediaQuery('(max-width: 600px)')

  const coinPricings = useSelector((state) => state.auth.coinPricings)

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 688,
          background: 'white',
          borderRadius: '10px',
          padding: '30px',
          maxHeight: '80vh',
          overflow: 'auto',
          '&.xs-only': {
            width: 'calc(100vw - 30px)',
            px: '10px',
            py: '20px',
          },
        }}
        className={['disable-scrollbar', xsOnly ? 'xs-only' : ''].join(' ')}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          <Close
            style={{
              fill: colorsConfig.mainRed,
            }}
          />
        </IconButton>
        <TextH1
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            mb: '16px',
            '&.xs-only': {
              fontSize: '24px',
            },
          }}
        >
          Apply the car for quality check?
        </TextH1>
        <TextNormal
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            mb: '55px',
            '&.xs-only': {
              fontSize: '14px',
              mb: '30px',
            },
          }}
        >
          The Fleetify operator will verify the details of your car, enabling you to access services
          that necessitate a clearance and inspection process.
        </TextNormal>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: '9px',
            borderBottom: '1px solid',
            borderColor: colorsConfig.lightGray,
            mb: '54px',
          }}
        >
          <TextBoldNormal sx={{ textTransform: 'uppercase' }}>Total</TextBoldNormal>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src={IconCoin} alt="" />
            <TextBoldNormal sx={{ ml: '5px' }}>
              {coinPricings.filter((item) => item.identifier === 'quality_check')[0]?.amount}
            </TextBoldNormal>
          </Box>
        </Box>
        <Box>
          <ButtonAttention
            fullWidth
            onClick={() => {
              handleSubmit()
            }}
          >
            Apply for quality check
          </ButtonAttention>
        </Box>
      </Box>
    </Modal>
  )
}

export default ApplyForQualityCheckModal
