import React from 'react'
import Preloader from './Preloader'
import { Box } from '@mui/material'

const WrapWithPreloader = (props) => {
  const { loadingText, loading, hideContent, children } = props

  return (
    <Box
      className={loading ? 'loading' : ''}
      sx={{
        '&.loading': {
          minHeight: '400px',
          position: 'relative',
        },
      }}
    >
      {loading ? (
        <Preloader
          type={'gooey'}
          text={loadingText}
          preloaderStyles={{
            background: 'rgba(255,255,255,.7)',
          }}
        />
      ) : null}
      {hideContent && loading ? null : children}
    </Box>
  )
}

export default WrapWithPreloader
