import React from 'react'
import { Box, IconButton, Modal, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { Close } from '@mui/icons-material'

const ModalWrap = ({ children, open, setOpen, wrapperStyles }) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 650,
          background: colorsConfig.bgLightBlue,
          borderRadius: '10px',
          padding: '30px',
          maxHeight: '80vh',
          overflow: 'auto',
          '&.xs-only': {
            width: 'calc(100vw - 30px)',
            px: '10px',
            py: '20px',
          },
          ...wrapperStyles,
        }}
        className={[
          'disable-scrollbar',
          xsOnly ? 'xs-only' : '',
          tabletOnly ? 'tablet-only' : '',
        ].join(' ')}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          <Close
            style={{
              fill: colorsConfig.mainRed,
            }}
          />
        </IconButton>
        {children}
      </Box>
    </Modal>
  )
}

export default ModalWrap
