export const getMultiQueryParam = (key, values) => {
  let query = `?`
  values.forEach((val, index) => {
    query = `${query}${key}[]=${val}`
    if (index !== values.length - 1) {
      query = `${query}&`
    }
  })
  return query
}
