import React, { useEffect, useState } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import VehicleDetailsSection from '../../../CarPage/CarPageSections/VehicleDetailsSection'
import DocumentsSection from '../../../CarPage/CarPageSections/DocumentsSection'
import PhotosSection from '../../../CarPage/CarPageSections/PhotosSection'
import DamagesSection from '../../../CarPage/CarPageSections/DamagesSection'
import WarningLights from '../../../Appraisal/WarningLights/WarningLights'
import { privateRequest } from '../../../../utils/request'
import {
  getCarParts,
  getControlPoints,
  getForeshortenings,
  getPilotLamps,
} from '../../../Appraisal/getAppraisalReferences'
import { setReferences } from '../../../../store/features/appraisalReferencesSlices'
import {
  resetCar,
  setAppraisal,
  setAppraisalTab,
  setCarData,
  setControlPoints,
  setDamages,
  setPhotos,
  setPilotLamps,
} from '../../../../store/features/carSlice/carSlice'
import { setCurrentPageTitle } from '../../../../store/features/authSlice'
import { API_ENDPOINTS } from '../../../../config/default'
import AppraisalBlock from '../../../Appraisal/AppraisalBlock'
import Preloader from '../../../../components/Preloader'
import BreadcrumbsComponent from '../../../../components/BreadcrumbsComponent'
import IconVirtualGarage from '../../../../assests/img/icons/icon-virtual-garage-home.svg'
import { colorsConfig } from '../../../../config/themeConfig'
import CarPageTabTitle from '../../../CarPage/CarPageTabTitle'
import DealSummary from './DealSummary'

const OperatorDealPage = () => {
  const carAppraisalChecksResource = useSelector((state) => state.car.carAppraisalChecksResource)

  const [loading, setLoading] = useState(true)
  const [blocks, setBlocks] = useState([])

  const sections = [
    {
      id: 1,
      name: 'Vehicle Details',
      component: <VehicleDetailsSection />,
      isCompleted: carAppraisalChecksResource.vehicleDetailsCheck,
    },
    {
      id: 2,
      name: 'Documents',
      component: <DocumentsSection />,
      isCompleted: carAppraisalChecksResource.documentsCheck,
    },
    {
      id: 3,
      name: 'Photos',
      component: <PhotosSection />,
      isCompleted: carAppraisalChecksResource.photosCheck,
    },
    {
      id: 4,
      name: 'Damages',
      component: <DamagesSection />,
      isCompleted: carAppraisalChecksResource.damagesCheck,
    },
    {
      id: 5,
      name: 'Warning Lights',
      component: <WarningLights readonly={true} />,
      isCompleted: carAppraisalChecksResource.warningLightsCheck,
    },
    {
      id: 6,
      name: 'Interior Conditions',
      component: blocks[1] ? blocks[1].component : <></>,
      isCompleted: carAppraisalChecksResource.interiorConditionsCheck,
    },
    {
      id: 7,
      name: 'Mechanical Conditions',
      component: blocks[2] ? blocks[2].component : <></>,
      isCompleted: carAppraisalChecksResource.mechanicalConditionsCheck,
    },
  ]

  const [car, setCar] = useState(null)
  const [carTitle, setCarTitle] = useState('')
  const [tab, setTab] = useState(0)

  const carData = useSelector((state) => state.car.carData)
  const appraisalReferences = useSelector((state) => state.references)
  const combinedStatus = useSelector((state) => state.car.carData.combinedStatus)

  const dispatch = useDispatch()

  const { id } = useParams()

  function compare(a, b) {
    if (a.direct < b.direct) {
      return -1
    }
    if (a.direct > b.direct) {
      return 1
    }
    return 0
  }

  const getReferences = () => {
    const referencePromises = [
      getForeshortenings(),
      getCarParts(),
      getPilotLamps(),
      getControlPoints(),
    ]
    Promise.all(referencePromises).then((result) => {
      dispatch(setReferences(result))
    })
  }

  useEffect(() => {
    getCar()
    getCarAppraisal()
    return () => {
      dispatch(resetCar())
    }
  }, [])

  useEffect(() => {
    if (!appraisalReferences.loaded) {
      getReferences()
    }
  }, [appraisalReferences.loaded])

  useEffect(() => {
    if (appraisalReferences.loaded && carData.loaded && carData.id?.toString() === id) {
      setControlPointsBlocks()
      dispatch(
        setDamages({
          carDamages: carData.damages,
          carParts: appraisalReferences.carParts,
        }),
      )
      dispatch(setPilotLamps(appraisalReferences.pilotLamps))
      dispatch(setControlPoints(appraisalReferences.controlPoints))
      dispatch(setPhotos(appraisalReferences.foreshortenings))
      setTimeout(() => {
        setLoading(false)
      }, 700)
    }
  }, [appraisalReferences.loaded, carData])

  const getCar = () => {
    privateRequest.get(API_ENDPOINTS.carId(id)).then((response) => {
      setCar(response.data)
      dispatch(setCarData(response.data))
      setCarTitle(`${response.data.make.name} ${response.data.model.name}`)
      dispatch(
        setCurrentPageTitle(
          `Deal clearing: ${response.data.make.name} ${response.data.model.name}`,
        ),
      )
      if (response.data.combinedStatus === 'car_workflow.new') {
        dispatch(setAppraisalTab(0))
      } else {
        dispatch(setAppraisalTab(1))
      }
    })
  }

  const getCarAppraisal = () => {
    privateRequest.get(API_ENDPOINTS.appraisalByCar(id)).then((response) => {
      dispatch(
        setAppraisal({
          foreshortenings: response.data.foreshortenings.sort(compare),
          damages: response.data.damages,
          pilotLamps: response.data.pilotLamps,
          controlPoints: response.data.controlPoints,
        }),
      )
    })
  }

  const setControlPointsBlocks = () => {
    const newBlocks = appraisalReferences.controlPoints.map((blockItem, index) => {
      return {
        id: index === 0 ? 3 : index + 5,
        title: blockItem.name,
        component: (
          <AppraisalBlock key={index === 0 ? 3 : index + 5} blockIndex={index} readonly={true} />
        ),
      }
    })
    setBlocks(newBlocks)
  }

  return (
    <Box
      sx={{
        height: loading ? 'calc(100vh - 102px)' : 'auto',
        position: 'relative',
      }}
    >
      {loading ? (
        <Preloader type="gooey" text={'Loading the deal...'} />
      ) : (
        <Box>
          <Box
            sx={{
              position: 'relative',
              pb: '59px',
              '&::after': {
                content: '" "',
                display: 'block',
                position: 'absolute',
                width: 'calc(100% + 130px)',
                left: '-23px',
                height: 'calc(100% + 30px)',
                top: '-30px',
                background: colorsConfig.bgLightBlue,
                zIndex: -1,
                pointerEvents: 'none',
              },
            }}
          >
            <BreadcrumbsComponent
              currentPage={{ name: carTitle }}
              parentPages={[
                {
                  name: `Auctions`,
                  icon: <img src={IconVirtualGarage} alt="" />,
                  link: '/operator/auctions',
                },
              ]}
            />
            <DealSummary />
          </Box>
          <Box
            sx={{
              mt: '30px',
            }}
          >
            <Box
              sx={{
                mb: 2,
              }}
            >
              <Tabs
                value={tab}
                onChange={(e, newVal) => {
                  setTab(newVal)
                }}
                variant="scrollable"
                sx={{
                  width: '100%',
                  '& .MuiTabs-indicator': {
                    background: colorsConfig.mainGreen,
                  },
                  '& .MuiTab-textColorPrimary': {
                    color: colorsConfig.mainBlack,
                    textTransform: 'none',
                    fontFamily: '"HelveticaNeue", sans-serif',
                    fontSize: '16px',
                    fontWeight: 500,
                  },
                  '& .MuiButtonBase-root': {
                    background: colorsConfig.bgLightBlue,
                  },
                  '& .Mui-selected': {
                    color: colorsConfig.mainBlack,
                  },
                }}
              >
                {sections.map((section, index) => {
                  return (
                    <Tab
                      key={index}
                      label={
                        combinedStatus === 'car_workflow.new' ||
                        combinedStatus === 'car_workflow.appraisal_on_site' ? (
                          <CarPageTabTitle title={section.name} isCompleted={section.isCompleted} />
                        ) : (
                          section.name
                        )
                      }
                      sx={{
                        fontSize: '13px',
                      }}
                    />
                  )
                })}
              </Tabs>
            </Box>
            {sections[tab].component}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default OperatorDealPage
