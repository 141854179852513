import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loanApplication: {
    customer: null,
  },
  loanDeals: [],
}

export const financeSlice = createSlice({
  name: 'finance',
  initialState: initialState,
  reducers: {
    setLoanApplication: (state, action) => {
      state.loanApplication = action.payload
    },
    setLoanDeals: (state, action) => {
      state.loanDeals = action.payload
    },
    updateLoanDeal: (state, action) => {
      state.loanDeals[action.payload.index] = action.payload.loanDeal
    },
    updatedApprovedParams: (state, action) => {
      state.loanDeals[action.payload.index].approvedParams = action.payload.approvedParams
    },
    resetFinance: (state) => {
      state.loanApplication = { customer: null }
      state.loanDeals = []
    },
  },
})

export const {
  setLoanApplication,
  setLoanDeals,
  updateLoanDeal,
  updatedApprovedParams,
  resetFinance,
} = financeSlice.actions

export default financeSlice.reducer
