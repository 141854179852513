import React, { useEffect } from 'react'
import UIBreadcrumbs from '../../../ui/UIBreadcrumbs/UIBreadcrumbs'
import { BusinessRounded, EditRounded } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import EditCompanyForm from '../../../components/Forms/FormTemplates/EditCompanyForm/EditCompanyForm'
import { setCurrentPageTitle } from '../../../store/features/authSlice'

const EditCompany = () => {
  const company = useSelector((state) => state.company.data)
  const dispatch = useDispatch()

  useEffect(() => {
    if (company) {
      dispatch(setCurrentPageTitle(`Edit company "${company?.name ?? '-'}"`))
    }
  }, [company])

  return (
    <Stack gap="30px">
      <UIBreadcrumbs
        pages={[
          {
            name: 'Companies',
            icon: <BusinessRounded />,
            link: '/admin/companies',
          },
          {
            name: `Edit company "${company?.name ?? '-'}"`,
            icon: <EditRounded />,
          },
        ]}
      />
      <EditCompanyForm />
    </Stack>
  )
}

export default EditCompany
