import React, { useEffect, useState } from 'react'
import { Box, Button, useMediaQuery, IconButton, Modal, Stack } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPageTitle } from '../../store/features/authSlice'
import CarTable from '../VirtualGarage/CarTable'
import { API_ENDPOINTS, CDN_BASE_URL } from '../../config/default'
import carDefaultPicture from '../../assests/img/car-not-found.png'
import { privateRequest } from '../../utils/request'
import AppraisalModal from './AppraisalModal'
import { useTranslation } from 'react-i18next'
import UITabs from '../../ui/UITabs/UITabs'
import UITab from '../../ui/UITabs/UITab'
import { appraisalServiceActions as actions } from './appraisalService.helpers'
import AddCustomerAppraisalModal from '../../components/Modals/AddCustomerAppraisalModal/AddCustomerAppraisalModal'
import CustomerAppraisals from './CustomerAppraisals/CustomerAppraisals'
import SearchInput from '../../components/Search/SearchInput'
import useBreakpoints from '../../hooks/useBreakpoints'

const modalCloseIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M15 1.02344L8 8.02344M8 8.02344L1 15.0234M8 8.02344L1 1.02344M8 8.02344L15 15.0234"
      stroke="#AC0000"
      strokeWidth="2"
    />
  </svg>
)

const AppraisalService = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { smDown } = useBreakpoints()
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const [tab, setTab] = useState(0)
  const [customerAppraisalModal, setCustomerAppraisalModal] = useState(false)

  const carWorkflows = useSelector((state) => state.workflows.carWorkflowStates)
  const auctionWorkflows = useSelector((state) => state.workflows.auctionWorkflowStates)
  const dealWorkflows = useSelector((state) => state.workflows.dealWorkflowStates)
  const workflowsLoaded = useSelector((state) => state.workflows.loaded)
  const role = useSelector((state) => state.auth.role)

  const [cars, setCars] = useState([])
  const [statuses, setStatuses] = useState([])
  const [filterStatus, setFilterStatus] = useState(null)
  const [sort, setSort] = useState('&order[createdAt]=desc')
  const [searchLine, setSearchLine] = useState('')

  const handleSetCarPhoto = (photo) => {
    if (photo && photo.photo && photo.photo.path) {
      return `${CDN_BASE_URL}${photo.photo.path}`
    } else {
      return carDefaultPicture
    }
  }

  const getDaysInStock = (date) => {
    const date1 = new Date()
    const date2 = new Date(date)
    const diffTime = Math.abs(date2 - date1)
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
    return diffDays === 0 ? '0 days' : diffDays === 1 ? '1 day' : `${diffDays} days`
  }

  const getCarStatus = (combinedStatus, isArchived) => {
    if (combinedStatus === 'car_workflow.ready_for_sale') {
      return 'Appraised'
    }
    if (!combinedStatus) {
      return 'Outdated car'
    }
    if (isArchived) {
      return 'Archived'
    }
    if (combinedStatus.includes('car_workflow')) {
      return carWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('car_workflow.', ''),
      )[0].value
    }
    if (combinedStatus.includes('auction_workflow')) {
      return auctionWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('auction_workflow.', ''),
      )[0].value
    }
    if (combinedStatus.includes('deal_workflow')) {
      return dealWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('deal_workflow.', ''),
      )[0].value
    }
  }

  const getActionList = (carStatus, isArchived, isChecksDone) => {
    if (isArchived) {
      return []
    }
    const availableActions = actions.filter((item) => {
      if (item.specialConditions === 'appraisalChecks' && !isChecksDone) {
        return false
      }
      return item.conditions.filter(
        (condition) => role === condition.role && carStatus === condition.status,
      )[0]
    })
    return availableActions
  }

  const handleSetFilters = (data, mode) => {
    if (mode === 'cars') {
      let filteredData
      if (role === 'OPERATOR') {
        filteredData = data.filter(
          (item) => item.name === 'car_workflow.quality_check' && item.count !== 0,
        )
      } else if (role === 'COMPANY') {
        filteredData = data.filter((item) => item.count !== 0)
      }
      setStatuses(
        filteredData.map((item, index) => {
          return {
            id: index + 1,
            systemName: item.name,
            label: getCarStatus(item.name),
            count: item.count,
          }
        }),
      )
    }
    if (mode === 'appraisals') {
      const filteredData = data.filter(
        (item) =>
          (item.name === 'car_workflow.appraisal_on_site' ||
            item.name === 'car_workflow.quality_check' ||
            item.name === 'car_workflow.moderation_failed' ||
            item.name === 'car_workflow.requested_appraisal_compound') &&
          item.count !== 0,
      )
      setStatuses(
        filteredData.map((item, index) => {
          return {
            id: index + 1,
            systemName: item.name,
            label: getCarStatus(item.name),
            count: item.count,
          }
        }),
      )
    }
  }

  const getAppraisals = () => {
    let link
    if (!filterStatus) {
      link = API_ENDPOINTS.cars.carsForAppraisal
    } else if (filterStatus !== 'Archived') {
      link = `${API_ENDPOINTS.cars.companyCars}&combinedStatus[]=${filterStatus}`
    }
    if (searchLine) {
      link = `${link}&or[make.name]=${searchLine}&or[model.name]=${searchLine}&or[referenceNumber]=${searchLine}&or[vin]=${searchLine}&or[combinedStatus]=${searchLine}&or[id]=${searchLine}`
    }
    if (sort) {
      link = `${link}${sort}`
    }
    privateRequest.get(link).then((response) => {
      setCars(
        response.data['hydra:member'].map((item) => {
          return {
            id: item.id,
            link: `/cars/${item.id}`,
            greenCar: item.engineType === 'electric' || item.engineType === 'hybrid',
            cells: [
              {
                type: 'text',
                value: item.referenceNumber ? item.referenceNumber : item.id,
              },
              {
                type: 'image',
                value: handleSetCarPhoto(item.mainForeshortening),
              },
              {
                type: 'text-with-caption',
                value: `${item.make.name} ${item.model.name}`,
                caption: `VIN ${item.vin}`,
              },
              {
                type: 'text',
                value: item?.isCompounded
                  ? t('table_cell_value_fleatify_compounds')
                  : t('table_cell_value_your_garage'),
              },
              {
                type: 'text',
                value: getDaysInStock(item.createdAt),
              },
              {
                type: 'text',
                value: getCarStatus(item.combinedStatus, item.isArchived),
              },
              {
                type: 'actions',
                value: getActionList(
                  item.combinedStatus,
                  item.isArchived,
                  item.isAppraisalChecksDone,
                ),
              },
            ],
          }
        }),
      )
    })
  }

  const getCarStatusCount = () => {
    privateRequest.get(API_ENDPOINTS.cars.statusCount).then((response) => {
      const data = response.data['hydra:member']
      handleSetFilters(data, 'appraisals')
    })
  }

  useEffect(() => {
    if (workflowsLoaded) {
      getAppraisals()
      getCarStatusCount()
    }
  }, [workflowsLoaded, filterStatus, searchLine, sort])

  useEffect(() => {
    if (xsOnly) {
      dispatch(setCurrentPageTitle('title_ongoing_appraisals'))
    }
    if (!xsOnly) {
      dispatch(setCurrentPageTitle('page_title_appraisal'))
    }
  }, [xsOnly])

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => {
    if (tab === 0) {
      setIsModalOpen(true)
    } else {
      setCustomerAppraisalModal(true)
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <Box
      sx={{
        '& > :last-child > * > :nth-child(2)': {
          display: xsOnly && tab === 0 && 'none',
        },
        pb: {
          sm: '30px',
          md: 0,
        },
      }}
    >
      <Stack
        direction={{
          xs: 'column',
          md: 'row',
        }}
        justifyContent="space-between"
        alignItems="center"
        gap={{
          xs: '24px',
          md: 0,
        }}
        sx={{
          px: {
            xs: '16px',
            md: 0,
          },
        }}
      >
        <UITabs value={tab} onChange={(e, val) => setTab(val)}>
          <UITab label={t('tab_my_appraisals')} />
          <UITab label={t('tab_customer_appraisals')} />
        </UITabs>
        <Button
          className={xsOnly ? 'xs-only' : ''}
          variant="contained"
          sx={{
            background: colorsConfig.mainGreen,
            width: '271px',
            height: '54px',
            borderRadius: '4px',
            fontSize: '16px',
            fontWeight: 700,
            textTransform: 'none',
            px: '50px',
            py: '15px',
            mr: '25px',
            whiteSpace: 'nowrap',
            color: colorsConfig.mainBlack,
            transition: '0.25s all ease-in-out',
            '&:hover': {
              background: colorsConfig.mainGreen,
              opacity: '0.8',
              transition: '0.25s all ease-in-out',
            },
            '&.xs-only': {
              width: '100%',
              boxShadow: 'none',
              mr: 0,
            },
          }}
          onClick={handleOpenModal}
        >
          + {t('button_new_appraisal')}
        </Button>
        {tab === 0 && smDown && (
          <SearchInput
            fullWidth
            value={searchLine}
            onChange={(e) => setSearchLine(e.target.value)}
          />
        )}
      </Stack>
      {tab === 0 ? (
        <Box
          className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
          id={'car-table'}
          sx={{
            background: 'white',
            boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
            borderRadius: '6px',
            py: '37px',
            px: '40px',
            mt: '40px',
            '&.xs-only': {
              p: 0,
              boxShadow: 'none',
              background: 'none',
              mt: 0,
            },
            '&.tablet-only': {
              p: 0,
              boxShadow: 'none',
              background: 'none',
              mt: 0,
            },
          }}
        >
          <CarTable
            type={'appraisal'}
            tableTitle={t('title_ongoing_appraisals')}
            content={cars}
            filters={statuses}
            setFilterStatus={setFilterStatus}
            getCars={getAppraisals}
            searchLine={searchLine}
            setSearchLine={setSearchLine}
            sort={sort}
            setSort={setSort}
            cars={cars}
            setCars={setCars}
            getActionList={getActionList}
          />
        </Box>
      ) : (
        <CustomerAppraisals />
      )}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: 2,
            width: '1030px',
            minHeight: '410px',
            maxHeight: '710px',
            borderRadius: '10px',
            background: 'white',
            '&.xs-only': {
              width: 'calc(100vw - 30px)',
              maxHeight: 'calc(100vh - 30px)',
              overflowY: 'auto',
              minHeight: '0',
              padding: '8px',
            },
          }}
        >
          <AppraisalModal />
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
            }}
          >
            {modalCloseIcon}
          </IconButton>
        </Box>
      </Modal>
      <AddCustomerAppraisalModal
        open={customerAppraisalModal}
        setOpen={setCustomerAppraisalModal}
      />
      {/*<FinanceLinkModal open={true} setOpen={() => {}} link="alala" customerName="lala" />*/}
    </Box>
  )
}

export default AppraisalService
