import React from 'react'
import { Box, useMediaQuery } from '@mui/material'

const RBox = ({ className, children, ...props }) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')
  const extraLarge = useMediaQuery('(min-width: 1400px)')

  return (
    <Box
      className={[
        className,
        xsOnly ? 'xs-only' : '',
        tabletOnly ? 'tablet-only' : '',
        extraLarge ? 'extra-large' : '',
      ].join(' ')}
      {...props}
    >
      {children}
    </Box>
  )
}

export default RBox
