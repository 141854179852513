import React from 'react'
import { TableHead, TableRow, TableCell } from '@mui/material'

const SimpleTableHead = (props) => {
  const { tableHeadCells } = props

  return (
    <TableHead>
      <TableRow>
        {tableHeadCells.map((item, index) => {
          return (
            <TableCell
              key={index}
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              {item.title}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export default SimpleTableHead
