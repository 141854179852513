import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { PieChart } from '@mui/x-charts/PieChart'
import TextH1 from '../../../../ui/Text/TextH1'
import UIPreloaderCircular from '../../../../ui/UIPreloader/UIPreloaderCircular'
import StatusList from '../StatusList'
import { privateRequest } from '../../../../utils/request'
import { API_ENDPOINTS } from '../../../../config/default'
import { getInsuranceStatusList } from '../../dashboard.helpers'
import { insuranceStatusColors, pieChartConfig } from '../../dashboard.constants'
import InsuranceStatusCakeModal from './InsuranceStatusCakeModal'
import { useTranslation } from 'react-i18next'

const InsuranceStatusCake = () => {
  const { t } = useTranslation()
  const [applications, setApplications] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [statusCakeModal, setStatusCakeModal] = useState(false)
  const [modalData, setModalData] = useState(null)

  const total = applications?.length ?? 0

  const handleOpenModal = (data) => {
    setModalData(data)
    setStatusCakeModal(true)
  }

  const getApplications = () => {
    privateRequest
      .get(API_ENDPOINTS.insurance.applications, {
        params: {
          isArchived: false,
          pagination: false,
        },
      })
      .then((response) => {
        setApplications(response.data['hydra:member'])
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getApplications()
  }, [])

  return (
    <Box
      sx={{
        width: '100%',
        height: '369px',
        padding: '32px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <TextH1>{t('title_insurance_status_cake')}</TextH1>
      {isLoading ? (
        <UIPreloaderCircular sx={{ mt: '50px' }} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            gap: '25px',
            position: 'relative',
          }}
        >
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ fontSize: '32px', fontWeight: 700, color: '#11263C' }}>
                {total}
              </Typography>
              <Typography
                sx={{ fontSize: '12px', color: 'rgba(60, 60, 67, 0.60)', marginTop: '-5px' }}
              >
                {t('common_total')}
              </Typography>
            </Box>
            <PieChart
              colors={insuranceStatusColors}
              series={[
                {
                  data: getInsuranceStatusList(applications).map((item) => {
                    return {
                      value: item?.count,
                      label: item?.name,
                    }
                  }),
                  ...pieChartConfig,
                },
              ]}
              width={210}
              height={210}
              sx={{
                '& .MuiChartsLegend-root': {
                  display: 'none',
                },
              }}
            />
          </Box>

          <StatusList
            data={getInsuranceStatusList(applications)}
            colors={insuranceStatusColors}
            handleOpenModal={handleOpenModal}
          />
        </Box>
      )}
      {modalData ? (
        <InsuranceStatusCakeModal
          open={statusCakeModal}
          setOpen={setStatusCakeModal}
          data={modalData}
        />
      ) : null}
    </Box>
  )
}

export default InsuranceStatusCake
