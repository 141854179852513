import React, { useRef, useEffect } from 'react'
import MaskedInput from 'react-text-mask'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'

const CheNumberInput = React.forwardRef(function CheNumberInput(props, ref) {
  const { inputRef: parentInputRef, ...other } = props

  const inputRef = useRef(null)

  useEffect(() => {
    if (parentInputRef) {
      if (typeof parentInputRef === 'function') {
        parentInputRef(inputRef.current)
      } else {
        parentInputRef.current = inputRef.current
      }
    }
  }, [parentInputRef])

  return (
    <MaskedInput
      {...other}
      mask={['C', 'H', 'E', '-', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/]}
      placeholderChar="_"
      showMask
      inputRef={inputRef}
    />
  )
})

const FormUIDMaskedInputBase = ({ ...props }) => {
  const inputRef = useRef(null)

  const handleFocus = (event) => {
    const inputElement = event.target
    const firstUnderscoreIndex = inputElement.value.indexOf('_')
    const position = firstUnderscoreIndex !== -1 ? firstUnderscoreIndex : inputElement.value.length

    setTimeout(() => {
      inputElement.setSelectionRange(position, position)
    }, 0)
  }

  return (
    <TextFieldSmallEdible
      label="form_input_label_uid"
      variant="outlined"
      InputProps={{
        inputComponent: CheNumberInput,
        inputRef: inputRef,
      }}
      onFocus={handleFocus}
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    />
  )
}

export default FormUIDMaskedInputBase
