import { createAsyncThunk } from '@reduxjs/toolkit'
import coveredComponentsService from '../../../api/warrantyApplicationsService/coveredComponentsApiService'

export const getCoveredComponents = createAsyncThunk(
  'coveredComponents/getCoveredComponents',
  async (_, { rejectWithValue }) => {
    try {
      const response = await coveredComponentsService.getCoveredComponents()
      return response.data
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e.response.data)
    }
  },
)
