import React, { useState } from 'react'
import { Box } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import { PersonRounded } from '@mui/icons-material'
import TextH1 from '../../../ui/Text/TextH1'
import IconCoin from '../../../assests/img/icons/icon-fleetify-coin.svg'
import { sumDelimiter } from '../../../utils/sumDelimiter'
import TextNormal from '../../../ui/Text/TextNormal'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import { localesConfig } from '../../../i18n/langConfig'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import ButtonDanger from '../../../ui/Buttons/ButtonDanger'
import ModalWrap from '../../../components/Modals/ModalWrap'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import ButtonSuccess from '../../../ui/Buttons/ButtonSuccess'
import { privateRequest } from '../../../utils/request'
import { API_ENDPOINTS } from '../../../config/default'
import { toast } from 'react-toastify'

const AdminPanelUserManagerInfo = (props) => {
  const { user, avatar, balance, refresh } = props

  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false)
  const [manageUserModal, setManageUserModal] = useState(false)
  const [resetPasswordModal, setResetPasswordModal] = useState(false)

  const handleBlockUser = () => {
    setConfirmButtonDisabled(true)
    privateRequest
      .put(API_ENDPOINTS.blockUser(user?.user?.id), {})
      .then(() => {
        toast.success(`User ${user?.user?.login} has been successfully deactivated`)
        refresh()
        setManageUserModal(false)
      })
      .finally(() => {
        setConfirmButtonDisabled(false)
      })
  }

  const handleUnblockUser = () => {
    setConfirmButtonDisabled(true)
    privateRequest
      .put(API_ENDPOINTS.unblockUser(user?.user?.id), {})
      .then(() => {
        toast.success(`User ${user?.user?.login} has been successfully activated`)
        refresh()
        setManageUserModal(false)
      })
      .finally(() => {
        setConfirmButtonDisabled(false)
      })
  }

  const handleResetPassword = () => {
    setConfirmButtonDisabled(true)
    privateRequest
      .put(API_ENDPOINTS.resetUserPassword(user?.user?.id), {})
      .then(() => {
        toast.success(`An email with a new password has been sent to ${user?.user?.login}`)
        refresh()
        setResetPasswordModal(false)
      })
      .finally(() => {
        setConfirmButtonDisabled(false)
      })
  }

  return (
    <Box
      sx={{
        mt: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          alignItems: 'flex-start',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            width: '120px',
            height: '120px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            border: '2px solid',
            borderColor: colorsConfig.mainBlue,
            overflow: 'hidden',
          }}
        >
          {avatar ? (
            <img
              src={avatar}
              alt=""
              style={{
                width: '100%',
              }}
            />
          ) : (
            <PersonRounded
              sx={{
                fill: colorsConfig.lightGray,
                fontSize: '70px',
              }}
            />
          )}
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              mt: '10px',
            }}
          >
            <TextH1
              sx={{
                fontSize: '32px',
              }}
            >
              {user?.firstName} {user?.surname}
            </TextH1>
            <Box
              sx={{
                color: colorsConfig.mainBlack,
                background: 'white',
                border: '1px solid',
                borderColor: colorsConfig.mainBlack,
                fontSize: '16px',
                fontWeight: 700,
                py: '6px',
                px: '15px',
                boxShadow: 'none',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '4px',
              }}
            >
              <img
                src={IconCoin}
                style={{ width: '18px', height: '18px', marginRight: '6px' }}
                alt=""
              />
              {sumDelimiter(balance)}
            </Box>
            {!user?.user?.isBlocked ? (
              <TextNormal
                sx={{
                  borderRadius: '20px',
                  py: '3px',
                  px: '13px',
                  background: colorsConfig.mainGreen,
                  color: 'white',
                  fontSize: '13px',
                }}
              >
                Active
              </TextNormal>
            ) : (
              <TextNormal
                sx={{
                  borderRadius: '20px',
                  py: '4px',
                  px: '10px',
                  background: colorsConfig.darkRed,
                  color: 'white',
                  fontSize: '14px',
                }}
              >
                Deactivated
              </TextNormal>
            )}
          </Box>
          <Box
            sx={{
              mt: '20px',
              display: 'flex',
              gap: '30px',
            }}
          >
            <Box>
              <TextBoldNormal>User name</TextBoldNormal>
              <TextNormal>{user?.user?.login}</TextNormal>
            </Box>
            <Box>
              <TextBoldNormal>Mobile number</TextBoldNormal>
              <TextNormal>+ {user?.phoneNumber?.replace('971', '971 ')}</TextNormal>
            </Box>
            <Box>
              <TextBoldNormal>E-mail</TextBoldNormal>
              <TextNormal>{user?.user?.email}</TextNormal>
            </Box>
            <Box>
              <TextBoldNormal>Account language</TextBoldNormal>
              <TextNormal>
                {localesConfig.find((item) => item.code === user?.user?.locale)?.name}
              </TextNormal>
            </Box>
          </Box>
        </Box>
        <TextNormal
          sx={{
            position: 'absolute',
            top: '10px',
            right: '0',
            color: colorsConfig.iconGray,
            fontSize: '14px',
          }}
        >
          Joined on {new Date(user?.createdAt).toLocaleString('default', { month: 'long' })}{' '}
          {new Date(user?.createdAt).getDate()}, {new Date(user?.createdAt).getFullYear()}
        </TextNormal>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          mt: '30px',
        }}
      >
        {user?.user?.isBlocked ? (
          <ButtonLG
            onClick={() => {
              setManageUserModal(true)
            }}
          >
            Activate
          </ButtonLG>
        ) : (
          <ButtonDanger
            onClick={() => {
              setManageUserModal(true)
            }}
          >
            Deactivate
          </ButtonDanger>
        )}
        <ButtonLG
          onClick={() => {
            setResetPasswordModal(true)
          }}
        >
          Reset password
        </ButtonLG>
      </Box>
      <ModalWrap open={manageUserModal} setOpen={setManageUserModal}>
        <TextH1
          sx={{
            fontSize: '32px',
            mb: '25px',
          }}
        >
          Are you you want to {user?.user?.isBlocked ? 'activate' : 'deactivate'} <br />{' '}
          {user?.user?.login}?
        </TextH1>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
          }}
        >
          <ButtonHollow
            sx={{
              width: 'calc(50% - 5px)',
            }}
            onClick={() => {
              setManageUserModal(false)
            }}
          >
            Cancel
          </ButtonHollow>
          {user?.user?.isBlocked ? (
            <ButtonSuccess
              disabled={confirmButtonDisabled}
              sx={{
                width: 'calc(50% - 5px)',
              }}
              onClick={() => {
                handleUnblockUser()
              }}
            >
              Activate
            </ButtonSuccess>
          ) : (
            <ButtonDanger
              disabled={confirmButtonDisabled}
              sx={{
                width: 'calc(50% - 5px)',
              }}
              onClick={() => {
                handleBlockUser()
              }}
            >
              Deactivate
            </ButtonDanger>
          )}
        </Box>
      </ModalWrap>
      <ModalWrap open={resetPasswordModal} setOpen={setResetPasswordModal}>
        <TextH1
          sx={{
            fontSize: '32px',
            mb: '25px',
          }}
        >
          Are you you want to reset <br /> {user?.user?.login}'s password?
        </TextH1>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
          }}
        >
          <ButtonHollow
            sx={{
              width: 'calc(50% - 5px)',
            }}
            onClick={() => {
              setResetPasswordModal(false)
            }}
          >
            Cancel
          </ButtonHollow>
          <ButtonSuccess
            disabled={confirmButtonDisabled}
            sx={{
              width: 'calc(50% - 5px)',
            }}
            onClick={() => {
              handleResetPassword()
            }}
          >
            Confirm
          </ButtonSuccess>
        </Box>
      </ModalWrap>
    </Box>
  )
}

export default AdminPanelUserManagerInfo
