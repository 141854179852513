export const insuranceRequestsTableHead = [
  {
    title: 'Number',
    sortable: false,
  },
  {
    title: 'Client',
    sortable: false,
  },
  {
    title: 'Insurance company',
    sortable: false,
  },
  {
    title: 'Brand/Model',
    sortable: false,
  },
  {
    title: 'Request date',
    sortable: true,
    sortableAsc: 'desc',
    sortableDesc: 'asc',
  },
]

export const insuranceDealWorkflowTypes = [
  {
    systemName: 'approved',
    name: 'Accepted',
  },
  {
    systemName: 'insurance_company_request_info',
    name: 'Request info',
  },
  {
    systemName: 'insurance_company_declined',
    name: 'Refused',
  },
  {
    systemName: 'insurance_company_sent',
    name: 'Sent to insurance company',
  },
]

export const initialDealApprovedData = {
  dealName: '',
  comment: '',
  attachments: [],
}

export const initialRiskDetails = {
  checked: true,
  coverage: 0,
  premium: '',
  dealerCommission: '',
}
