import { getManager } from '../manager.actions'

export default (builder) => {
  builder
    .addCase(getManager.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getManager.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = null
      state.data = action.payload
    })
    .addCase(getManager.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
}
