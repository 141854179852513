export const FONT = {
  PRIMARY: '"Helvetica", sans-serif',
}

export const UISize = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
}

export const UIVariant = {
  primary: 'primary',
  secondary: 'secondary',
  success: 'success',
  attention: 'attention',
  attention_hollow: 'attention_hollow',
  danger: 'danger',
  hollow: 'hollow',
}

export const customBorder = {
  mono: {
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='black' stroke-width='1' stroke-dasharray='10%2c 10' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e")`,
  },
}
