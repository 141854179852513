import React, { useEffect, useState } from 'react'
import { privateRequest } from '../../../utils/request'
import { API_ENDPOINTS } from '../../../config/default'
import { toast } from 'react-toastify'
import TextH1 from '../../../ui/Text/TextH1'
import { Autocomplete, Box, Grid, useMediaQuery } from '@mui/material'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import TextFieldSmall from '../../../ui/TextFieldSmall'
import ButtonSmall from '../../../ui/Buttons/ButtonSmall'
import ConfirmDepositModal from '../../../components/Modals/ConfirmDepositModal'
import ConfirmWithdrawalModal from '../../../components/Modals/ConfirmWithdrawalModal'
import companiesApiService from '../../../api/companiesApiService/companiesApiService'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'

const ManualCoinsManagement = () => {
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const [companies, setCompanies] = useState([])
  const [depositToCompany, setDepositToCompany] = useState('')
  const [depositAmount, setDepositAmount] = useState('')
  const [depositCoinsModal, setDepositCoinsModal] = useState(false)
  const [withdrawFromCompany, setWithdrawFromCompany] = useState('')
  const [withdrawAmount, setWithdrawAmount] = useState('')
  const [withdrawCoinsModal, setWithdrawCoinsModal] = useState(false)

  const getCompanies = async () => {
    try {
      const response = await companiesApiService.getCompanies({ pagination: false })
      setCompanies(response.data['hydra:member'])
    } catch (error) {
      setCompanies([])
    }
  }

  const handleDepositCoins = () => {
    const formData = {
      company: companies.filter((item) => item.name === depositToCompany)[0]['@id'],
      amount: parseFloat(depositAmount),
    }
    privateRequest
      .post(API_ENDPOINTS.coins.deposit, formData)
      .then((response) => {
        setDepositCoinsModal(false)
        setDepositToCompany('')
        setDepositAmount('')
        toast.success('You have deposited Fleatify coins successfully')
      })
      .catch((error) => {
        toast.error('Deposit failed. Please try again later')
      })
  }

  const handleWithdrawCoins = () => {
    const formData = {
      company: companies.filter((item) => item.name === withdrawFromCompany)[0]['@id'],
      amount: parseFloat(withdrawAmount),
    }
    privateRequest
      .post(API_ENDPOINTS.coins.withdraw, formData)
      .then((response) => {
        setWithdrawFromCompany('')
        setWithdrawAmount('')
        setWithdrawCoinsModal(false)
        toast.success('You have withdrawn Fleatify coins successfully')
      })
      .catch((error) => {
        toast.error('Withdrawal failed. Please try again later')
      })
  }

  useEffect(() => {
    getCompanies()
  }, [])

  return (
    <Box>
      <TextH1
        sx={{
          mb: xsOnly ? '20px' : '30px',
          fontSize: xsOnly ? '24px' : '30px',
        }}
      >
        Manage Fleatify coins manually
      </TextH1>
      <Box>
        <TextBoldNormal
          sx={{
            fontSize: '18px',
            mb: '10px',
          }}
        >
          Deposit coins
        </TextBoldNormal>
        <Box
          sx={{
            padding: '25px',
            borderRadius: '4px',
            boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
            mb: '20px',
          }}
        >
          <Grid container spacing={3}>
            <Grid item sm={4} xs={12}>
              <Autocomplete
                fullWidth
                id="company-name"
                freeSolo
                options={companies.map((item) => item.name)}
                value={depositToCompany}
                onChange={(e, newVal) => {
                  setDepositToCompany(newVal)
                }}
                renderInput={(params) => (
                  <TextFieldSmallEdible
                    {...params}
                    isAutocomplete
                    label="Company name"
                    value={depositToCompany}
                    onChange={(e) => {
                      setDepositToCompany(e.target.value)
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextFieldSmall
                label={'Amount'}
                fullWidth
                type="text"
                value={depositAmount}
                onChange={(e) => {
                  setDepositAmount(e.target.value.replace(/[^0-9]/g, ''))
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <ButtonSmall
                fullWidth
                sx={{
                  height: '37px',
                }}
                disabled={!depositAmount || !depositToCompany}
                onClick={() => {
                  setDepositCoinsModal(true)
                }}
              >
                Deposit
              </ButtonSmall>
            </Grid>
          </Grid>
        </Box>
        <TextBoldNormal
          sx={{
            fontSize: '18px',
            mb: '10px',
          }}
        >
          Withdraw coins
        </TextBoldNormal>
        <Box
          sx={{
            padding: '25px',
            borderRadius: '4px',
            boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
            mb: '20px',
          }}
        >
          <Grid container spacing={3}>
            <Grid item sm={4} xs={12}>
              <Autocomplete
                fullWidth
                id="company-name"
                freeSolo
                options={companies.map((item) => item.name)}
                value={withdrawFromCompany}
                onChange={(e, newVal) => {
                  setWithdrawFromCompany(newVal)
                }}
                renderInput={(params) => (
                  <TextFieldSmallEdible
                    {...params}
                    isAutocomplete
                    label="Company name"
                    value={withdrawFromCompany}
                    onChange={(e) => {
                      setWithdrawFromCompany(e.target.value)
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextFieldSmall
                label={'Amount'}
                fullWidth
                type="text"
                value={withdrawAmount}
                onChange={(e) => {
                  setWithdrawAmount(e.target.value.replace(/[^0-9]/g, ''))
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <ButtonSmall
                fullWidth
                sx={{
                  height: '37px',
                }}
                disabled={!withdrawAmount || !withdrawFromCompany}
                onClick={() => {
                  setWithdrawCoinsModal(true)
                }}
              >
                Withdraw
              </ButtonSmall>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ConfirmDepositModal
        open={depositCoinsModal}
        setOpen={setDepositCoinsModal}
        handleSubmit={handleDepositCoins}
        companyName={depositToCompany}
        amount={depositAmount}
      />
      <ConfirmWithdrawalModal
        open={withdrawCoinsModal}
        setOpen={setWithdrawCoinsModal}
        handleSubmit={handleWithdrawCoins}
        companyName={withdrawFromCompany}
        amount={withdrawAmount}
      />
    </Box>
  )
}

export default ManualCoinsManagement
