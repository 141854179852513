import React, { useEffect, useState } from 'react'
import { Stack, Grid } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import BreadcrumbsComponent from '../../../components/BreadcrumbsComponent'
import { HomeRounded } from '@mui/icons-material'
import { colorsConfig } from '../../../config/themeConfig'
import TextH1 from '../../../ui/Text/TextH1'
import { toast } from 'react-toastify'
import { apiConstants } from '../../../api/api'
import coveredComponentsService from '../../../api/warrantyApplicationsService/coveredComponentsApiService'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import ButtonLG from '../../../ui/Buttons/ButtonLG'

const AddCoveredComponent = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')
  const [systemName, setSystemName] = useState('')

  const handleSubmit = async () => {
    setIsLoading(true)
    const formData = {
      name,
      sysName: systemName,
    }
    try {
      await coveredComponentsService.createCoveredComponent(formData)
      toast.success('Covered component has been created successfully')
      navigate('/admin/warranty/components')
    } catch (e) {
      if (!e.response) toast.error('Unknown error')
      if (e?.response?.data) toast.error(e?.response?.data?.[apiConstants.hydraDescription])
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    dispatch(setCurrentPageTitle('Add covered component'))
  }, [])

  return (
    <Stack>
      <BreadcrumbsComponent
        currentPage={{ name: 'Add covered component' }}
        parentPages={[
          {
            name: 'Covered components',
            icon: <HomeRounded sx={{ fill: colorsConfig.lightGray }} />,
            link: '/admin/warranty/components',
          },
        ]}
      />
      <TextH1
        sx={{
          fontSize: '36px',
          mb: '30px',
          mt: '10px',
        }}
      >
        Add a new covered component
      </TextH1>
      <Grid container spacing="30px">
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            fullWidth
            label="System name"
            value={systemName}
            onChange={(e) => setSystemName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <ButtonLG
            sx={{ minWidth: '200px' }}
            disabled={!name || !systemName || isLoading}
            onClick={() => handleSubmit()}
          >
            Save
          </ButtonLG>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default AddCoveredComponent
