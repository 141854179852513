import React from 'react'
import { Box } from '@mui/material'
import IconCheck from '../../assests/img/icons/icon-checkmark-black.svg'
import TextNormal from '../Text/TextNormal'
import { colorsConfig } from '../../config/themeConfig'

const UiMarkerChecked = ({ text }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        border: '1px solid',
        borderColor: colorsConfig.buttonDisabled,
        background: colorsConfig.bgLightBlue,
        px: '12px',
        py: '9px',
        borderRadius: '100px',
      }}
    >
      <TextNormal sx={{ lineHeight: 'normal' }}>{text}</TextNormal>
      <img src={IconCheck} alt="" />
    </Box>
  )
}

export default UiMarkerChecked
