import React from 'react'
import { Box, IconButton, Modal } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { Close } from '@mui/icons-material'
import TextH1 from '../../ui/Text/TextH1'
import TextNormal from '../../ui/Text/TextNormal'
import ButtonAttention from '../../ui/Buttons/ButtonAttention'

const ServiceHintInfoModal = (props) => {
  const { open, setOpen, title, description } = props

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 686,
          background: colorsConfig.bgLightBlue,
          borderRadius: '10px',
          padding: '30px',
          pb: '35px',
          maxHeight: '80vh',
          overflow: 'auto',
        }}
        className={'disable-scrollbar'}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          <Close
            style={{
              fill: colorsConfig.mainRed,
            }}
          />
        </IconButton>
        <TextH1
          sx={{
            mb: '16px',
          }}
        >
          {title}
        </TextH1>
        <TextNormal
          sx={{
            mb: '60px',
          }}
        >
          {description}
        </TextNormal>
        <ButtonAttention
          fullWidth
          onClick={() => {
            setOpen(false)
          }}
        >
          OK
        </ButtonAttention>
      </Box>
    </Modal>
  )
}

export default ServiceHintInfoModal
