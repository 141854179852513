import React, { useState } from 'react'
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Button,
  useMediaQuery,
  Typography,
} from '@mui/material'
import TextH1 from '../../../../ui/Text/TextH1'
import ButtonMedium from '../../../../ui/Buttons/ButtonMedium'
import { useSelector, useDispatch } from 'react-redux'
import { updateLoanDeal, updatedApprovedParams } from '../../../../store/features/financeSlice'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import TextNormal from '../../../../ui/Text/TextNormal'
import { colorsConfig } from '../../../../config/themeConfig'
import TextAreaSmall from '../../../../ui/TextAreaSmall'
import DragAndDropFiles from '../../../../ui/DragAndDropFiles'
import IconAppraisalDocument from '../../../../assests/img/icons/icon-appraisal-document.svg'
import { CancelRounded } from '@mui/icons-material'
import { downloadFileFromPrivateBucket } from '../../../../utils/downloadFile'
import TextBoldNormal from '../../../../ui/Text/TextBoldNormal'
import { bundledProductsTypes } from '../../../../config/formsConfig'
import IconAddRound from '../../../../assests/img/icons/icon-add-round.svg'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import { patchRequest, privateRequest } from '../../../../utils/request'
import { API_FINANCE_ENDPOINTS, FILE_TYPES } from '../../../../config/default'
import { uploadFile } from '../../../../utils/uploadFile'
import BankLogo from '../../../../assests/img/bank-example.svg'
import { sumDelimiter } from '../../../../utils/sumDelimiter'
import IconFileWithCounter from '../../../../assests/img/icons/icon-file-with-counter.svg'
import BankResponseSummaryModal from './BankResponseSummaryModal'
import UITextRegular from '../../../../ui/UIText/UITextRegular'

const BankResponseForm = (props) => {
  const { currentTab } = props

  const smallDesktop = useMediaQuery('(max-width: 1500px) and (min-width: 1000px)')

  const dispatch = useDispatch()

  const loanApplication = useSelector((state) => state.finance.loanApplication)
  const loanDeal = useSelector((state) => state.finance.loanDeals[currentTab])
  const approvedParams = useSelector((state) => state.finance.loanDeals[currentTab].approvedParams)

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
  const [bankResponseSummaryModal, setBankResponseSummaryModal] = useState(false)
  const [showErrors, setShowErrors] = useState(false)

  const displayBankResponseForm =
    (loanDeal.localStatus === 'accepted' || loanDeal.localStatus === 'accepted_with_changes') &&
    loanDeal.currentWorkflowState === 'bank_sent'
  const displayRejectionForm =
    loanDeal.localStatus === 'rejected' && loanDeal.currentWorkflowState === 'bank_sent'
  const displayBankResponse =
    loanDeal.currentWorkflowState === 'approved' ||
    loanDeal.currentWorkflowState === 'bank_request_info' ||
    loanDeal.currentWorkflowState === 'bank_declined'

  const handleValidate = () => {
    if (!approvedParams.loanTerm) return false
    if (!approvedParams.monthlyInstallment) return false
    if (!approvedParams.downPayment) return false
    if (!approvedParams.interestRate) return false
    if (approvedParams.dealerCommissionType === 'in AED' && !approvedParams.dealerCommission)
      return false
    if (approvedParams.dealerCommissionType === 'in %' && !approvedParams.dealerCommissionPercents)
      return false
    if (!approvedParams.totalLoanAmount) return false
    return true
  }

  const getDownPaymentPercentage = () => {
    return Math.round((loanDeal.approvedParams.downPayment * 100) / loanApplication.carPrice)
  }

  const handleSetLocalStatus = (status) => {
    dispatch(
      updateLoanDeal({
        index: currentTab,
        loanDeal: {
          ...loanDeal,
          localStatus: status,
        },
      }),
    )
  }

  const handleSetFiles = (files) => {
    dispatch(
      updatedApprovedParams({
        index: currentTab,
        approvedParams: {
          ...approvedParams,
          documents: [...approvedParams.documents, ...Array.from(files)],
        },
      }),
    )
  }

  const handleDownloadFile = (file, index) => {
    downloadFileFromPrivateBucket(file, file.name)
  }

  const handleDownloadFiles = (files) => {
    files.forEach((item, index) => {
      if (files.length > 1) {
        downloadFileFromPrivateBucket(item.attachment.signedUrl, `Bank document ${index + 1}`)
        return
      }
      downloadFileFromPrivateBucket(item.attachment.signedUrl, `Bank document`)
    })
  }

  const handleDeleteFile = (index) => {
    dispatch(
      updatedApprovedParams({
        index: currentTab,
        approvedParams: {
          ...approvedParams,
          documents: approvedParams.documents.filter((item, i) => i !== index),
        },
      }),
    )
  }

  const handleSendBankResponse = async () => {
    setSubmitButtonDisabled(true)
    const dealerCommission =
      approvedParams.dealerCommissionType === 'in AED'
        ? approvedParams.dealerCommission
        : (parseInt(approvedParams.dealerCommissionPercents) / 100) *
          parseFloat(approvedParams.totalLoanAmount)
    const formData = {
      approvedParamsData: {
        offerName: approvedParams.offerName,
        loanTerm: parseInt(approvedParams.loanTerm),
        dp: approvedParams.downPayment,
        rvWarranty: approvedParams.rwWarranty,
        interestRate: approvedParams.interestRate.toString(),
        monthlyInstallment: approvedParams.monthlyInstallment,
        dealerCommission: dealerCommission.toString(),
        loanAmount: approvedParams.totalLoanAmount,
      },
    }
    if (approvedParams.documents.length > 0) {
      const uploadedFiles = await Promise.all(
        approvedParams.documents.map((item) => {
          return uploadFile(item, FILE_TYPES.default)
        }),
      )
      formData.attachments = uploadedFiles.map((item) => {
        return { attachment: item }
      })
    }
    if (approvedParams.comment !== '') {
      formData.comments = [
        {
          comment: approvedParams.comment,
        },
      ]
    }
    if (loanDeal.localStatus === 'rejected') {
      formData.comments = [
        {
          comment: approvedParams?.rejectionReason,
        },
      ]
      patchRequest
        .patch(API_FINANCE_ENDPOINTS.loanDealById(loanDeal.id), {
          comments: formData.comments,
        })
        .then((response) => {
          const link = API_FINANCE_ENDPOINTS.loanDealWorkflowTransitions.loanDealDeclined(
            loanApplication.id,
            loanDeal.id,
          )
          privateRequest.post(link, {}).then(() => {
            dispatch(
              updateLoanDeal({
                index: currentTab,
                loanDeal: {
                  ...loanDeal,
                  currentWorkflowState: 'bank_declined',
                },
              }),
            )
            setBankResponseSummaryModal(false)
            setSubmitButtonDisabled(false)
          })
        })
      return
    }
    patchRequest
      .patch(API_FINANCE_ENDPOINTS.loanDealById(loanDeal.id), formData)
      .then((response) => {
        let link
        if (loanDeal.localStatus === 'accepted') {
          link = API_FINANCE_ENDPOINTS.loanDealWorkflowTransitions.loanDealApproved(
            loanApplication.id,
            loanDeal.id,
          )
          privateRequest.post(link, {}).then(() => {
            dispatch(
              updateLoanDeal({
                index: currentTab,
                loanDeal: {
                  ...loanDeal,
                  currentWorkflowState: 'approved',
                  approvedParams: {
                    ...approvedParams,
                    dealerCommission,
                  },
                },
              }),
            )
          })
        }
        if (loanDeal.localStatus === 'accepted_with_changes') {
          link = API_FINANCE_ENDPOINTS.loanDealWorkflowTransitions.loanDealRequestInfo(
            loanApplication.id,
            loanDeal.id,
          )
          privateRequest.post(link, {}).then(() => {
            dispatch(
              updateLoanDeal({
                index: currentTab,
                loanDeal: {
                  ...loanDeal,
                  currentWorkflowState: 'bank_request_info',
                  approvedParams: {
                    ...approvedParams,
                    dealerCommission,
                  },
                },
              }),
            )
          })
        }
        if (loanDeal.localStatus === 'rejected') {
          link = API_FINANCE_ENDPOINTS.loanDealWorkflowTransitions.loanDealDeclined(
            loanApplication.id,
            loanDeal.id,
          )
          privateRequest.post(link, {}).then(() => {
            dispatch(
              updateLoanDeal({
                index: currentTab,
                loanDeal: {
                  ...loanDeal,
                  currentWorkflowState: 'bank_declined',
                },
              }),
            )
          })
        }
        setBankResponseSummaryModal(false)
      })
      .finally(() => {
        setSubmitButtonDisabled(false)
      })
  }

  return (
    <Box
      sx={{
        mt: '37px',
        px: '21px',
        py: '33px',
        boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
        borderRadius: '6px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <TextH1
          sx={{
            mr: '20px',
          }}
        >
          {loanDeal.currentWorkflowState === 'bank_sent'
            ? 'Create bank response'
            : 'Saved bank response'}
        </TextH1>
        <ButtonMedium
          type={'danger'}
          className={[
            loanDeal.localStatus !== 'not_selected' && loanDeal.localStatus !== 'rejected'
              ? 'inactive'
              : '',
            loanDeal.currentWorkflowState !== 'bank_sent' ? 'blocked' : '',
          ].join(' ')}
          sx={{
            mr: '10px',
          }}
          onClick={() => {
            handleSetLocalStatus('rejected')
          }}
        >
          Refused
        </ButtonMedium>
        <ButtonMedium
          type={'success'}
          className={[
            loanDeal.localStatus !== 'not_selected' && loanDeal.localStatus !== 'accepted'
              ? 'inactive'
              : '',
            loanDeal.currentWorkflowState !== 'bank_sent' ? 'blocked' : '',
          ].join(' ')}
          sx={{
            mr: '10px',
          }}
          onClick={() => {
            handleSetLocalStatus('accepted')
          }}
        >
          Accepted
        </ButtonMedium>
        <ButtonMedium
          type={'attention'}
          className={[
            loanDeal.localStatus !== 'not_selected' &&
            loanDeal.localStatus !== 'accepted_with_changes'
              ? 'inactive'
              : '',
            loanDeal.currentWorkflowState !== 'bank_sent' ? 'blocked' : '',
          ].join(' ')}
          onClick={() => {
            handleSetLocalStatus('accepted_with_changes')
          }}
        >
          Bank request
        </ButtonMedium>
      </Box>
      {displayBankResponseForm ? (
        <Box>
          <Box
            sx={{
              mt: '30px',
            }}
          >
            <Grid container spacing={'24px'}>
              <Grid item md={4} sm={6} xs={12}>
                <TextFieldSmallEdible
                  fullWidth
                  label={'Offer name'}
                  value={approvedParams.offerName}
                  onChange={(e) => {
                    dispatch(
                      updatedApprovedParams({
                        index: currentTab,
                        approvedParams: {
                          ...approvedParams,
                          offerName: e.target.value,
                        },
                      }),
                    )
                  }}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextFieldSmallEdible
                  fullWidth
                  label={'Loan term'}
                  value={approvedParams.loanTerm}
                  onChange={(e) => {
                    dispatch(
                      updatedApprovedParams({
                        index: currentTab,
                        approvedParams: {
                          ...approvedParams,
                          loanTerm: e.target.value.replace(/[^0-9]/g, ''),
                        },
                      }),
                    )
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        <TextNormal
                          sx={{
                            fontSize: '14px',
                            color: colorsConfig.iconGray,
                          }}
                        >
                          months
                        </TextNormal>
                      </InputAdornment>
                    ),
                  }}
                  helperText={
                    showErrors && !approvedParams.loanTerm ? 'This field can not be empty' : ''
                  }
                  error={showErrors && !approvedParams.loanTerm}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextFieldSmallEdible
                  fullWidth
                  label={'Monthly installment'}
                  value={approvedParams.monthlyInstallment}
                  onChange={(e) => {
                    dispatch(
                      updatedApprovedParams({
                        index: currentTab,
                        approvedParams: {
                          ...approvedParams,
                          monthlyInstallment: e.target.value.replace(/[^0-9.]/g, ''),
                        },
                      }),
                    )
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        <TextNormal
                          sx={{
                            fontSize: '14px',
                            color: colorsConfig.iconGray,
                          }}
                        >
                          AED
                        </TextNormal>
                      </InputAdornment>
                    ),
                  }}
                  helperText={
                    showErrors && !approvedParams.monthlyInstallment
                      ? 'This field can not be empty'
                      : ''
                  }
                  error={showErrors && !approvedParams.monthlyInstallment}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextFieldSmallEdible
                  fullWidth
                  label={'Down payment'}
                  value={approvedParams.downPayment}
                  onChange={(e) => {
                    dispatch(
                      updatedApprovedParams({
                        index: currentTab,
                        approvedParams: {
                          ...approvedParams,
                          downPayment: e.target.value.replace(/[^0-9.]/g, ''),
                        },
                      }),
                    )
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        <TextNormal
                          sx={{
                            fontSize: '14px',
                            color: colorsConfig.iconGray,
                          }}
                        >
                          AED
                        </TextNormal>
                      </InputAdornment>
                    ),
                  }}
                  helperText={
                    showErrors && !approvedParams.downPayment ? 'This field can not be empty' : ''
                  }
                  error={showErrors && !approvedParams.downPayment}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextFieldSmallEdible
                  fullWidth
                  label={'Residual value'}
                  value={approvedParams.rwWarranty}
                  onChange={(e) => {
                    if (parseFloat(e.target.value) < 100 || e.target.value === '') {
                      dispatch(
                        updatedApprovedParams({
                          index: currentTab,
                          approvedParams: {
                            ...approvedParams,
                            rwWarranty: e.target.value.replace(/[^0-9.]/g, ''),
                          },
                        }),
                      )
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        <TextNormal
                          sx={{
                            fontSize: '14px',
                            color: colorsConfig.iconGray,
                          }}
                        >
                          %
                        </TextNormal>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextFieldSmallEdible
                  fullWidth
                  label={'Interest rate'}
                  value={approvedParams.interestRate}
                  onChange={(e) => {
                    if (parseFloat(e.target.value) < 100 || e.target.value === '') {
                      dispatch(
                        updatedApprovedParams({
                          index: currentTab,
                          approvedParams: {
                            ...approvedParams,
                            interestRate: e.target.value.replace(/[^0-9.]/g, ''),
                          },
                        }),
                      )
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        <TextNormal
                          sx={{
                            fontSize: '14px',
                            color: colorsConfig.iconGray,
                          }}
                        >
                          %
                        </TextNormal>
                      </InputAdornment>
                    ),
                  }}
                  helperText={
                    showErrors && !approvedParams.interestRate ? 'This field can not be empty' : ''
                  }
                  error={showErrors && !approvedParams.interestRate}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12} container spacing={1}>
                <Grid item xs={8}>
                  {approvedParams.dealerCommissionType === 'in AED' ? (
                    <TextFieldSmallEdible
                      fullWidth
                      label={'Dealer commission'}
                      value={approvedParams.dealerCommission}
                      onChange={(e) => {
                        dispatch(
                          updatedApprovedParams({
                            index: currentTab,
                            approvedParams: {
                              ...approvedParams,
                              dealerCommission: e.target.value.replace(/[^0-9.]/g, ''),
                            },
                          }),
                        )
                      }}
                      helperText={
                        showErrors && !approvedParams.dealerCommission
                          ? 'This field can not be empty'
                          : ''
                      }
                      error={showErrors && !approvedParams.dealerCommission}
                    />
                  ) : (
                    <TextFieldSmallEdible
                      fullWidth
                      label={'Dealer commission'}
                      value={approvedParams.dealerCommissionPercents || ''}
                      onChange={(e) => {
                        if (parseFloat(e.target.value) < 99) {
                          dispatch(
                            updatedApprovedParams({
                              index: currentTab,
                              approvedParams: {
                                ...approvedParams,
                                dealerCommissionPercents: e.target.value.replace(/[^0-9.]/g, ''),
                              },
                            }),
                          )
                        }
                      }}
                      helperText={
                        showErrors && !approvedParams.dealerCommissionPercents
                          ? 'This field can not be empty'
                          : ''
                      }
                      error={showErrors && !approvedParams.dealerCommissionPercents}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    fullWidth
                    sx={{
                      color: colorsConfig.iconGray,
                      fontWeight: 400,
                      fontSize: '13px',
                      border: 'none',
                    }}
                    size={'small'}
                  >
                    <Select
                      value={approvedParams.dealerCommissionType || 'in AED'}
                      onChange={(e) => {
                        dispatch(
                          updatedApprovedParams({
                            index: currentTab,
                            approvedParams: {
                              ...approvedParams,
                              dealerCommissionType: e.target.value,
                            },
                          }),
                        )
                      }}
                      sx={{
                        borderRadius: '4px',
                        height: '38px',
                        fontSize: '13px',
                        border: 'none',
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                          boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                        },
                      }}
                      size={'small'}
                    >
                      <MenuItem value={'in AED'}>AED</MenuItem>
                      <MenuItem value={'in %'}>%</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextFieldSmallEdible
                  fullWidth
                  label={'Total loan amount'}
                  value={approvedParams.totalLoanAmount}
                  onChange={(e) => {
                    dispatch(
                      updatedApprovedParams({
                        index: currentTab,
                        approvedParams: {
                          ...approvedParams,
                          totalLoanAmount: e.target.value.replace(/[^0-9.]/g, ''),
                        },
                      }),
                    )
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        <TextNormal
                          sx={{
                            fontSize: '14px',
                            color: colorsConfig.iconGray,
                          }}
                        >
                          AED
                        </TextNormal>
                      </InputAdornment>
                    ),
                  }}
                  helperText={
                    showErrors && !approvedParams.totalLoanAmount
                      ? 'This field can not be empty'
                      : ''
                  }
                  error={showErrors && !approvedParams.totalLoanAmount}
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              mt: '30px',
            }}
          >
            <Grid container spacing={'24px'}>
              <Grid item md={6} xs={12}>
                <TextAreaSmall
                  fullWidth
                  label={'Note'}
                  placeholder={'Enter additional information'}
                  value={approvedParams.comment}
                  onChange={(e) => {
                    dispatch(
                      updatedApprovedParams({
                        index: currentTab,
                        approvedParams: {
                          ...approvedParams,
                          comment: e.target.value,
                        },
                      }),
                    )
                  }}
                  minRows={5}
                  multiline
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '24px',
                  }}
                >
                  <Box
                    sx={{
                      width: '154px',
                      minWidth: '154px',
                    }}
                  >
                    <DragAndDropFiles handleSetFiles={handleSetFiles} uploading={false} />
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '24px',
                      flexWrap: 'wrap',
                    }}
                  >
                    {approvedParams.documents.map((item, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            minWidth: '45px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            cursor: 'pointer',
                            maxHeight: '70px',
                          }}
                          onClick={() => {
                            handleDownloadFile(item, index)
                          }}
                        >
                          <img
                            src={IconAppraisalDocument}
                            alt=""
                            style={{
                              width: '100%',
                            }}
                            title={item.name}
                          />
                          <IconButton
                            sx={{
                              position: 'absolute',
                              top: '-4px',
                              right: '0px',
                              width: '20px',
                              height: '20px',
                              opacity: '1',
                              transition: '0.25s all ease-in-out',
                              '&:hover': {
                                transition: '0.25s all ease-in-out',
                                opacity: '1',
                              },
                            }}
                            color="error"
                            onClick={(e) => {
                              e.stopPropagation()
                              handleDeleteFile(index)
                            }}
                          >
                            <CancelRounded
                              sx={{
                                fontSize: '18px',
                                fill: colorsConfig.mainRed,
                              }}
                            />
                          </IconButton>
                        </Box>
                      )
                    })}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/*<TextBoldNormal sx={{mt: '30px'}}>*/}
          {/*    Bundled products:*/}
          {/*</TextBoldNormal>*/}
          {/*<Box sx={{mt: '30px'}}>*/}
          {/*    <Grid container spacing={'24px'}>*/}
          {/*        {*/}
          {/*            approvedParams.bundledProducts.map((item, index) => {*/}
          {/*                return (*/}
          {/*                    <Grid key={index} item xs={6} md={3}>*/}
          {/*                        <FormControl*/}
          {/*                            fullWidth*/}
          {/*                            sx={{*/}
          {/*                                color: colorsConfig.iconGray,*/}
          {/*                                fontWeight: 400,*/}
          {/*                                fontSize: '14px',*/}
          {/*                                border: 'none'*/}
          {/*                            }}*/}
          {/*                            size={'small'}*/}
          {/*                        >*/}
          {/*                            <InputLabel*/}
          {/*                                id={`label-product-name-${index + 1}`}*/}
          {/*                                sx={{*/}
          {/*                                    color: colorsConfig.iconGray,*/}
          {/*                                    fontWeight: 400,*/}
          {/*                                    fontSize: '14px',*/}
          {/*                                    '&.Mui-focused': {*/}
          {/*                                        background: 'white',*/}
          {/*                                        color: colorsConfig.mainBlue,*/}
          {/*                                        boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',*/}
          {/*                                        overflow: 'visible'*/}
          {/*                                    }*/}
          {/*                                }}*/}
          {/*                            >*/}
          {/*                                Product name*/}
          {/*                            </InputLabel>*/}
          {/*                            <Select*/}
          {/*                                labelId={`label-product-name-${index + 1}`}*/}
          {/*                                id={`product-name-${index + 1}`}*/}
          {/*                                value={approvedParams.bundledProducts[index]}*/}
          {/*                                label="Product name"*/}
          {/*                                onChange={(e) => {*/}
          {/*                                    const newBundledProducts = [...approvedParams.bundledProducts]*/}
          {/*                                    newBundledProducts[index] = e.target.value*/}
          {/*                                    dispatch(updatedApprovedParams({*/}
          {/*                                        index: currentTab,*/}
          {/*                                        approvedParams: {*/}
          {/*                                            ...approvedParams,*/}
          {/*                                            bundledProducts: newBundledProducts*/}
          {/*                                        }*/}
          {/*                                    }))*/}
          {/*                                }}*/}
          {/*                                sx={{*/}
          {/*                                    borderRadius: '4px',*/}
          {/*                                    height: '38px',*/}
          {/*                                    fontSize: '14px',*/}
          {/*                                    border: 'none',*/}
          {/*                                    '& .MuiOutlinedInput-notchedOutline': {*/}
          {/*                                        border: 'none',*/}
          {/*                                        boxShadow: '0 0 10px 0 rgba(0,0,0,.1)'*/}
          {/*                                    }*/}
          {/*                                }}*/}
          {/*                                size={'small'}*/}
          {/*                            >*/}
          {/*                                <MenuItem value={null}>*/}
          {/*                                    Not chosen*/}
          {/*                                </MenuItem>*/}
          {/*                                {bundledProductsTypes.map((item, index) => {*/}
          {/*                                    return (*/}
          {/*                                        <MenuItem*/}
          {/*                                            key={index}*/}
          {/*                                            value={item}*/}
          {/*                                        >*/}
          {/*                                            {item}*/}
          {/*                                        </MenuItem>*/}
          {/*                                    )*/}
          {/*                                })}*/}
          {/*                            </Select>*/}
          {/*                            {*/}
          {/*                                index !== approvedParams.bundledProducts.length - 1 ? (*/}
          {/*                                    <IconButton*/}
          {/*                                        sx={{*/}
          {/*                                            borderRadius: '50%',*/}
          {/*                                            border: '1px solid',*/}
          {/*                                            borderColor: colorsConfig.darkRed,*/}
          {/*                                            position: 'absolute',*/}
          {/*                                            width: '18px',*/}
          {/*                                            height: '18px',*/}
          {/*                                            top: '50%',*/}
          {/*                                            mt: '-9px',*/}
          {/*                                            right: '35px',*/}
          {/*                                            p: 0*/}
          {/*                                        }}*/}
          {/*                                        onClick={() => {*/}
          {/*                                            dispatch(updatedApprovedParams({*/}
          {/*                                                index: currentTab,*/}
          {/*                                                approvedParams: {*/}
          {/*                                                    ...approvedParams,*/}
          {/*                                                    bundledProducts: approvedParams.bundledProducts.filter((product, i) => i !== index)*/}
          {/*                                                }*/}
          {/*                                            }))*/}
          {/*                                        }}*/}
          {/*                                    >*/}
          {/*                                        <Box*/}
          {/*                                            sx={{*/}
          {/*                                                width: '10px',*/}
          {/*                                                height: '1px',*/}
          {/*                                                background: colorsConfig.darkRed*/}
          {/*                                            }}*/}
          {/*                                        />*/}
          {/*                                    </IconButton>*/}
          {/*                                ) : null*/}
          {/*                            }*/}
          {/*                        </FormControl>*/}
          {/*                    </Grid>*/}
          {/*                )*/}
          {/*            })*/}
          {/*        }*/}
          {/*        <Grid item xs={6} md={3}>*/}
          {/*            <IconButton*/}
          {/*                sx={{*/}
          {/*                    mt: '2px',*/}
          {/*                    width: '33px',*/}
          {/*                    height: '33px',*/}
          {/*                    padding: '0',*/}
          {/*                    '&:hover': {*/}
          {/*                        background: 'none'*/}
          {/*                    },*/}
          {/*                    '&.Mui-disabled': {*/}
          {/*                        opacity: '0.5'*/}
          {/*                    }*/}
          {/*                }}*/}
          {/*                disabled={!approvedParams.bundledProducts[approvedParams.bundledProducts.length - 1]}*/}
          {/*                onClick={() => {*/}
          {/*                    dispatch(updatedApprovedParams({*/}
          {/*                        index: currentTab,*/}
          {/*                        approvedParams: {*/}
          {/*                            ...approvedParams,*/}
          {/*                            bundledProducts: [...approvedParams.bundledProducts, '']*/}
          {/*                        }*/}
          {/*                    }))*/}
          {/*                }}*/}
          {/*            >*/}
          {/*                <img style={{width: '100%'}} src={IconAddRound} alt=""/>*/}
          {/*            </IconButton>*/}
          {/*        </Grid>*/}
          {/*    </Grid>*/}
          {/*</Box>*/}
        </Box>
      ) : null}
      {displayRejectionForm ? (
        <Box
          sx={{
            mt: '30px',
          }}
        >
          <TextAreaSmall
            label={'Reason of refusal (if provided by the bank)'}
            fullWidth
            value={approvedParams.rejectionReason}
            onChange={(e) => {
              dispatch(
                updatedApprovedParams({
                  index: currentTab,
                  approvedParams: {
                    ...approvedParams,
                    rejectionReason: e.target.value,
                  },
                }),
              )
            }}
            minRows={5}
            multiline
          />
        </Box>
      ) : null}
      {displayBankResponseForm || displayRejectionForm ? (
        <Box
          sx={{
            mt: '60px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <ButtonLG
            onClick={() => {
              if (handleValidate()) {
                setBankResponseSummaryModal(true)
              } else if (loanDeal.localStatus === 'rejected') {
                handleSendBankResponse()
              } else {
                setShowErrors(true)
              }
            }}
            disabled={submitButtonDisabled}
          >
            Send bank response
          </ButtonLG>
        </Box>
      ) : null}
      {displayBankResponse ? (
        <Box
          className={loanDeal.currentWorkflowState}
          sx={{
            mt: '30px',
            borderRadius: '6px',
            boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
            overflow: 'hidden',
            p: '15px',
            position: 'relative',
            '&::after': {
              content: '" "',
              display: 'block',
              position: 'absolute',
              zIndex: -1,
              width: '100%',
              height: '50px',
              top: 0,
              left: 0,
            },
            '&.approved': {
              '&::after': {
                background:
                  'linear-gradient(180deg, rgba(77, 237, 109, 0.15) 0%, rgba(77, 237, 109, 0.00) 100%)',
              },
            },
            '&.bank_request_info': {
              '&::after': {
                background:
                  'linear-gradient(180deg, rgba(245, 189, 79, 0.25) 0%, rgba(245, 189, 79, 0.00) 100%)',
              },
            },
            '&.bank_declined': {
              '&::after': {
                background:
                  'linear-gradient(180deg, rgba(172, 0, 0, 0.15) 0%, rgba(172, 0, 0, 0.00) 100%)',
              },
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <img src={BankLogo} alt="" />
              <TextBoldNormal sx={{ ml: '10px' }}>{loanDeal.bankTitle}:</TextBoldNormal>
              <TextBoldNormal
                sx={{
                  ml: '6px',
                  color: colorsConfig.mainBlue,
                }}
              >
                {loanDeal.approvedParams.offerName}
              </TextBoldNormal>
              <TextBoldNormal
                className={loanDeal.currentWorkflowState}
                sx={{
                  ml: '10px',
                  height: '24px',
                  px: '10px',
                  borderRadius: '100px',
                  color: 'white',
                  fontSize: '14px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '&.approved': {
                    background: colorsConfig.mainGreen,
                  },
                  '&.bank_request_info': {
                    background: colorsConfig.buttonYellow,
                  },
                  '&.bank_declined': {
                    background: colorsConfig.mainRed,
                  },
                }}
              >
                {loanDeal.currentWorkflowState === 'approved' ? 'Accepted' : ''}
                {loanDeal.currentWorkflowState === 'bank_declined' ? 'Refused' : ''}
                {loanDeal.currentWorkflowState === 'bank_request_info' ? 'Bank request' : ''}
              </TextBoldNormal>
            </Box>
          </Box>
          <Box
            className={smallDesktop ? 'sm-desktop' : ''}
            sx={{
              mt: '24px',
              display: 'flex',
              flexDirection: 'row',
              gap: '24px',
              flexWrap: 'wrap',
            }}
          >
            <Box>
              <TextBoldNormal
                className={smallDesktop ? 'sm-desktop' : ''}
                sx={{
                  fontSize: '24px',
                }}
              >
                {sumDelimiter(Math.round(loanDeal.approvedParams.monthlyInstallment)).replace(
                  "'",
                  ' ',
                )}{' '}
                AED
              </TextBoldNormal>
              <TextNormal>Total monthly instalment</TextNormal>
            </Box>
            <Box>
              {approvedParams?.dealerCommission ? (
                <TextBoldNormal
                  className={smallDesktop ? 'sm-desktop' : ''}
                  sx={{
                    fontSize: '24px',
                  }}
                >
                  {sumDelimiter(
                    Math.round(parseInt(approvedParams?.dealerCommission ?? 0)),
                  ).replace("'", ' ')}{' '}
                  AED (
                  {Math.round(
                    (parseFloat(approvedParams.dealerCommission) * 100) /
                      parseFloat(approvedParams.totalLoanAmount),
                  )}
                  %)
                </TextBoldNormal>
              ) : (
                <TextBoldNormal
                  className={smallDesktop ? 'sm-desktop' : ''}
                  sx={{
                    fontSize: '24px',
                  }}
                >
                  -
                </TextBoldNormal>
              )}

              <TextNormal>Total dealer commission</TextNormal>
            </Box>
            <Box>
              <TextBoldNormal
                sx={{
                  mt: '4px',
                  mb: '6px',
                }}
              >
                {loanDeal.approvedParams.loanTerm} months
              </TextBoldNormal>
              <TextNormal>Loan term</TextNormal>
            </Box>
            <Box>
              <TextBoldNormal
                sx={{
                  mt: '4px',
                  mb: '6px',
                }}
              >
                {getDownPaymentPercentage()}%
              </TextBoldNormal>
              <TextNormal>Down Payment</TextNormal>
            </Box>
            <Box>
              <TextBoldNormal
                sx={{
                  mt: '4px',
                  mb: '6px',
                }}
              >
                {loanDeal.approvedParams.rwWarranty
                  ? `${loanDeal.approvedParams.rwWarranty} %`
                  : '-'}
              </TextBoldNormal>
              <TextNormal>Residual value</TextNormal>
            </Box>
            <Box>
              <TextBoldNormal
                sx={{
                  mt: '4px',
                  mb: '6px',
                }}
              >
                {loanDeal.approvedParams.interestRate}%
              </TextBoldNormal>
              <TextNormal>Interest rate</TextNormal>
            </Box>
            <Box>
              <TextBoldNormal
                sx={{
                  mt: '4px',
                  mb: '6px',
                }}
              >
                {sumDelimiter(Math.round(approvedParams.totalLoanAmount)).replace("'", ' ')} AED
              </TextBoldNormal>
              <TextNormal>Total amount</TextNormal>
            </Box>
          </Box>
          {loanDeal?.approvedParams?.comment ? (
            <Box sx={{ mt: '25px' }}>
              <TextBoldNormal sx={{ mb: '2px' }}>Note from the bank:</TextBoldNormal>
              <TextNormal>{loanDeal.approvedParams.comment}</TextNormal>
            </Box>
          ) : null}
          {loanDeal.attachments.length > 0 ? (
            <Box sx={{ mt: '25px' }}>
              <TextBoldNormal sx={{ mb: '2px' }}>Bank provided documents:</TextBoldNormal>
              <TextNormal>Here are some documents to consider</TextNormal>
              <Button
                sx={{
                  textTransform: 'none',
                }}
                onClick={() => {
                  handleDownloadFiles(loanDeal.attachments)
                }}
              >
                <Box
                  sx={{
                    verticalAlign: 'top',
                    position: 'relative',
                  }}
                >
                  <img
                    src={IconFileWithCounter}
                    alt=""
                    style={{
                      verticalAlign: 'top',
                    }}
                  />
                  <TextNormal
                    sx={{
                      width: '15px',
                      height: '15px',
                      fontSize: '10px',
                      color: colorsConfig.mainBlue,
                      position: 'absolute',
                      bottom: '0.5px',
                      right: '1.5px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {loanDeal.attachments.length}
                  </TextNormal>
                </Box>
                <Typography
                  sx={{
                    fontFamily: '"HelveticaNeue", sans-serif',
                    fontSize: '16px',
                    fontWeight: 500,
                    color: colorsConfig.mainBlack,
                    lineHeight: 'normal',
                    ml: '6px',
                  }}
                >
                  Download
                </Typography>
              </Button>
            </Box>
          ) : null}
        </Box>
      ) : null}
      <BankResponseSummaryModal
        open={bankResponseSummaryModal}
        setOpen={setBankResponseSummaryModal}
        tableData={[
          {
            title: 'Offer name',
            value: approvedParams.offerName,
          },
          {
            title: 'Loan term',
            value: `${approvedParams.loanTerm} months`,
          },
          {
            title: 'Monthly installment',
            value: `${approvedParams.monthlyInstallment} AED`,
          },
          {
            title: 'Down payment',
            value: `${approvedParams.downPayment} AED`,
          },
          {
            title: 'Residual value',
            value: approvedParams.rwWarranty ? `${approvedParams.rwWarranty} %` : '',
          },
          {
            title: 'Interest rate',
            value: `${approvedParams.interestRate} %`,
          },
          {
            title: 'Dealer commission',
            value:
              approvedParams.dealerCommissionType === 'in AED'
                ? `${approvedParams.dealerCommission} AED`
                : `${approvedParams.dealerCommissionPercents} %`,
          },
          {
            title: 'Total loan amount',
            value: `${approvedParams.totalLoanAmount} AED`,
          },
        ]}
        note={
          loanDeal.localStatus === 'rejected'
            ? approvedParams.rejectionReason
            : approvedParams.comment
        }
        documents={approvedParams.documents}
        handleAction={handleSendBankResponse}
      />
    </Box>
  )
}

export default BankResponseForm
