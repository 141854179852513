import { getAddressTypes } from '../references.actions'
import { apiConstants } from '../../../../api/api'

export default (builder) => {
  builder
    .addCase(getAddressTypes.pending, (state) => {
      state.addressTypes.isLoading = true
    })
    .addCase(getAddressTypes.fulfilled, (state, action) => {
      state.addressTypes.isLoading = false
      state.addressTypes.error = null
      state.addressTypes.data = action.payload?.[apiConstants.hydraMember]
    })
    .addCase(getAddressTypes.rejected, (state, action) => {
      state.addressTypes.isLoading = false
      state.addressTypes.error = action.payload
    })
}
