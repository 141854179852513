import { getCompanyAddresses } from '../companyAddresses.actions'
import { apiConstants } from '../../../../api/api'

export default (builder) => {
  builder
    .addCase(getCompanyAddresses.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getCompanyAddresses.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload?.[apiConstants.hydraMember]
    })
    .addCase(getCompanyAddresses.rejected, (state, action) => {
      state.isLoading = false
    })
}
