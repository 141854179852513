import React from 'react'
import { Box, Typography } from '@mui/material'
import { BarChart, Bar, XAxis, YAxis, LabelList } from 'recharts'
import { colorsConfig } from '../../../config/themeConfig'
import AppleBackground2 from '../../../assests/img/dealer-dashboard-apple-bg-2.png'
import useAllCars from '../../../hooks/useAllCars'
import useBreakpoints from '../../../hooks/useBreakpoints'

const { mainBlack } = colorsConfig

export const DealerCarEngineRatio = ({ title }) => {
  const { isMinWidth1440, isMaxWidth1440 } = useBreakpoints()
  const { handleCarEngineTypes } = useAllCars()

  const data = handleCarEngineTypes()

  const CustomTick = ({ x, y, payload }) => {
    const nameLines = payload.value.split(' ')
    const lineHeight = 14
    const totalHeight = nameLines.length * lineHeight
    const adjustedY = y - totalHeight / 2
    return (
      <g transform={`translate(${x},${adjustedY})`}>
        {nameLines.map((line, index) => (
          <text
            fontFamily="Helvetica"
            fontSize={14}
            key={index}
            x={0}
            y={index * lineHeight + 12}
            textAnchor="end"
            fill="#666"
          >
            {line}
          </text>
        ))}
      </g>
    )
  }

  return (
    <>
      {isMinWidth1440 ? (
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '360px',
            margin: 0,
            padding: '33px 36px',
            background: 'linear-gradient(192deg, #F5F5F5 0%, #F4FFF2 100%)',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: mainBlack,
              fontSize: '40px',
              fontWeight: 700,
              lineHeight: 'normal',
              marginRight: '20px',
              width: 1,
            }}
          >
            {title}
          </Typography>
          <Box sx={{ position: 'relative', width: 'max-content' }}>
            <BarChart
              width={600}
              height={275}
              data={data}
              layout="vertical"
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis type="number" axisLine={false} tickLine={false} tick={{ display: 'none' }} />
              <YAxis
                type="category"
                axisLine={false}
                tickLine={false}
                dataKey="name"
                tick={<CustomTick />}
                width={90}
              />
              <Bar
                dataKey={(entry) => entry.value}
                radius={10}
                barSize={34}
                maxBarSize={380}
                minPointSize={34}
              >
                <LabelList
                  dataKey="value"
                  position="insideRight"
                  fill="white"
                  offset={10}
                  fontSize={14}
                />
              </Bar>
            </BarChart>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            <img
              src={AppleBackground2}
              alt="apple-bg"
              style={{ mixBlendMode: 'darken', borderRadius: '6px' }}
            />
          </Box>
        </Box>
      ) : isMaxWidth1440 ? (
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '360px',
            margin: 0,
            padding: '33px 36px',
            background: 'linear-gradient(192deg, #F5F5F5 0%, #F4FFF2 100%)',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: mainBlack,
              fontSize: '32px',
              fontWeight: 700,
              lineHeight: 'normal',
              marginRight: '20px',
              width: 1,
            }}
          >
            {title}
          </Typography>
          <Box sx={{ position: 'relative', width: 'max-content' }}>
            <BarChart
              width={600}
              height={275}
              data={data}
              layout="vertical"
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis type="number" axisLine={false} tickLine={false} tick={{ display: 'none' }} />
              <YAxis
                type="category"
                axisLine={false}
                tickLine={false}
                dataKey="name"
                tick={<CustomTick />}
                width={90}
              />
              <Bar
                dataKey={(entry) => entry.value}
                radius={10}
                barSize={34}
                maxBarSize={380}
                minPointSize={34}
              >
                <LabelList
                  dataKey="value"
                  position="insideRight"
                  fill="white"
                  offset={10}
                  fontSize={14}
                />
              </Bar>
            </BarChart>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            <img
              src={AppleBackground2}
              alt="apple-bg"
              style={{ mixBlendMode: 'darken', borderRadius: '6px' }}
            />
          </Box>
        </Box>
      ) : null}
    </>
  )
}
