import {
  CALCULATION_METHOD,
  CALCULATION_PRICE_METHOD,
  CREDIT_TYPE,
  DEALER_PRODUCT_CLASSIFICATION,
} from '../../../../utils/constants/enums.constants'
import formDate from '../../../../components/Forms/FormComponents/FormDate'

export const getDurationOptions = () => {
  const options = []
  for (let i = 12; i < 61; i++) {
    options.push({
      label: `${i} months`,
      value: i,
    })
  }
  return options
}

export const getTermMinimalAmount = (term, summaryItems) => {
  if (!summaryItems) return 0
  const matchItem = summaryItems?.find((item) => item?.term === term)
  if (matchItem) {
    return matchItem?.monthlyPayment?.units
  }
  return 0
}

export const getTermCalcMeta = (term, summaryItems) => {
  if (!summaryItems) return 0
  const matchItem = summaryItems?.find((item) => item?.term === term)
  if (matchItem) {
    return {
      bankName: matchItem?.bankName,
      loanProgramName: matchItem?.loanProgramName,
    }
  }
  return 0
}

export const termsList = [12, 24, 36, 48, 60]

export const getCalculatorTermsList = (selectedTerm) => {
  if (termsList.find((e) => e === selectedTerm)) {
    return termsList
  }
  return [...termsList, selectedTerm]
}

export const displayTermsAndPackages = (formData) => {
  const { duration, annualMileage, sellingPrice, downPayment } = formData
  const isDownPaymentCurrencyFilled =
    parseInt(formData.downPayment) > 0 && !formData.downPaymentInPercents
  const isDownPaymentPercentageFilled =
    formData.downPaymentInPercents && parseInt(formData.downPaymentPercentage) > 0
  return (
    parseInt(duration) > 0 &&
    parseInt(annualMileage) > 0 &&
    parseInt(sellingPrice) > 1000 &&
    (isDownPaymentCurrencyFilled || isDownPaymentPercentageFilled)
  )
}

export const displayRvSlider = (formData) => {
  const { duration, annualMileage, sellingPrice, downPayment } = formData
  const isDownPaymentCurrencyFilled =
    parseInt(formData.downPayment) > 0 && !formData.downPaymentInPercents
  const isDownPaymentPercentageFilled =
    formData.downPaymentInPercents && parseInt(formData.downPaymentPercentage) > 0
  return (
    parseInt(duration) > 0 &&
    parseInt(annualMileage) > 0 &&
    parseInt(sellingPrice) > 1000 &&
    (isDownPaymentCurrencyFilled || isDownPaymentPercentageFilled)
  )
}

export const loanCalculatorFormInitialState = {
  typeOfContractor: 'personal',
  sellingPrice: '',
  downPayment: '',
  downPaymentPercentage: '',
  downPaymentInPercents: true,
  annualMileage: 2,
  rvi: 18,
  customCoverage: false,
  selectedPackage: 'full',
  customOptions: [],
  selectedBank: null,
  customInterestRate: null,
  customTerm: false,
  customRviIncluded: false,
  selectedTerm: 24,
  duration: 24,
}

export const getLoanCalculatorFormState = (loanApplication) => {
  if (!loanApplication || !loanApplication?.currentWorkflowState)
    return loanCalculatorFormInitialState
  const { carPrice, dp, annualMileage, residualValue, term, offerPreference } =
    loanApplication ?? {}
  return {
    typeOfContractor: 'personal',
    sellingPrice: carPrice ?? '',
    downPayment: dp ?? '',
    annualMileage: annualMileage ? annualMileage / 5000 : 2,
    rvi: residualValue ? residualValue / 1000 : 18,
    customCoverage: false,
    selectedPackage:
      offerPreference === 1
        ? 'basic'
        : offerPreference === 2
          ? 'full'
          : offerPreference === 3
            ? 'custom'
            : 'full',
    customOptions: [],
    selectedBank: null,
    customInterestRate: null,
    customTerm: false,
    customRviIncluded: false,
    selectedTerm: term ?? 12,
    duration: term ?? 12,
  }
}

export const getCommonCalcParams = (car, formData, rvSliderData) => {
  const { downPayment, downPaymentPercentage, downPaymentInPercents, sellingPrice } = formData
  const { grossPriceWithoutOptions } = car
  const price =
    grossPriceWithoutOptions && grossPriceWithoutOptions !== ''
      ? parseInt(grossPriceWithoutOptions)
      : parseInt(sellingPrice)
  const downPaymentValue = downPaymentInPercents
    ? Math.ceil((price * parseInt(downPaymentPercentage)) / 100)
    : parseInt(downPayment)
  return {
    makeId: car?.make?.['@id'],
    modelId: car?.model?.['@id'],
    mileage: car?.mileage,
    annualMileage: formData?.annualMileage * 5000,
    carAge: car?.carAge,
    carType: car?.carType,
    dp: {
      units: downPaymentValue,
      nanos: 0,
    },
    carPrice: {
      units: price,
      nanos: 0,
    },
    creditType: CREDIT_TYPE.CREDIT_TYPE_BALLOON,
    residualValue: {
      units: parseInt(formData.rvi),
      nanos: 0,
    },
    calculationPriceMethod: CALCULATION_METHOD.CALCULATION_PRICE_METHOD_GROSS,
  }
}

export const getTermSlides = (termsCalculation, formData) => {
  return [
    {
      price: getTermMinimalAmount(12, termsCalculation[formData.selectedPackage] ?? []),
      term: 12,
    },
    {
      price: getTermMinimalAmount(24, termsCalculation[formData.selectedPackage] ?? []),
      term: 24,
    },
    {
      price: getTermMinimalAmount(36, termsCalculation[formData.selectedPackage] ?? []),
      term: 36,
    },
    {
      price: getTermMinimalAmount(48, termsCalculation[formData.selectedPackage] ?? []),
      term: 48,
    },
    {
      price: getTermMinimalAmount(60, termsCalculation[formData.selectedPackage] ?? []),
      term: 60,
    },
  ]
}

export const getTermsCalculationData = (formData, car) => {
  const { downPayment, downPaymentPercentage, downPaymentInPercents, sellingPrice } = formData
  const { grossPriceWithoutOptions } = car
  const price =
    grossPriceWithoutOptions && grossPriceWithoutOptions !== ''
      ? parseInt(grossPriceWithoutOptions)
      : parseInt(sellingPrice)
  const downPaymentValue = downPaymentInPercents
    ? Math.ceil((price * parseInt(downPaymentPercentage)) / 100)
    : parseInt(downPayment)
  return {
    commonCalcParams: {
      makeId: car?.make?.['@id'],
      modelId: car?.make?.['@id'],
      mileage: parseInt(car?.mileage),
      annualMileage: formData?.annualMileage * 5000,
      carAge: car?.carAge,
      carType: car?.carType,
      dp: {
        units: downPaymentValue,
        nanos: 0,
      },
      carPrice: {
        units: sellingPrice,
        nanos: 0,
      },
      creditType: CREDIT_TYPE.CREDIT_TYPE_BALLOON,
      residualValue: {
        units: parseInt(formData?.rvi),
        nanos: 0,
      },
      calculationPriceMethod:
        car?.grossPriceWithoutOptions && car?.grossPriceWithoutOptions !== ''
          ? CALCULATION_METHOD.CALCULATION_PRICE_METHOD_GROSS
          : CALCULATION_METHOD.CALCULATION_PRICE_METHOD_NET,
    },
  }
}

export const getCustomPackageData = (car, formData, rvSliderData) => {
  return {
    term: formData.selectedTerm,
    loanOffer: {
      title: '',
      bank: {
        id: formData.selectedBank,
      },
      rate: formData.customInterestRate,
      formulaType: 'annuity',
    },
    commonCalcParams: {
      ...getCommonCalcParams(car, formData, rvSliderData),
      dealerProducts: formData.customOptions.map((item) => ({ classification: item })),
    },
  }
}

export const getRvCalculationData = (car, formData) => {
  const hasGrossPrice = car?.grossPriceWithoutOptions
  return {
    term: parseInt(formData.duration),
    carAge: car?.carAge,
    mileage: car?.mileage,
    annualMileage: parseInt(formData.annualMileage) * 5000,
    carPrice: {
      units: hasGrossPrice ? parseInt(car?.grossPriceWithOptions) : parseInt(formData.sellingPrice),
      nanos: 0,
    },
    calculationPriceMethod: hasGrossPrice
      ? CALCULATION_METHOD.CALCULATION_PRICE_METHOD_GROSS
      : CALCULATION_METHOD.CALCULATION_PRICE_METHOD_NET,
  }
}

export const getLoanApplicationFormData = (data, car) => {
  const { downPayment, downPaymentPercentage, downPaymentInPercents } = data

  const hasGrossPrice =
    car?.grossPriceWithoutOptions &&
    car?.grossPriceWithoutOptions?.toString() !== '0' &&
    car?.grossPriceWithoutOptions !== ''

  const carPrice = hasGrossPrice ? car?.grossPriceWithoutOptions.toString() : data.sellingPrice

  const residualValue = data?.rvi ? parseInt(data?.rvi) * 1000 : 0
  const downPaymentValue = downPaymentInPercents
    ? Math.ceil((parseInt(carPrice) * parseInt(downPaymentPercentage)) / 100)
    : parseInt(downPayment)
  const formData = {
    car: car?.['@id'],
    carPrice,
    dp: downPaymentValue?.toString(),
    term: parseInt(data.selectedTerm),
    residualValue,
    creditType: CREDIT_TYPE.CREDIT_TYPE_BALLOON,
    calculationPriceMethod: hasGrossPrice
      ? CALCULATION_PRICE_METHOD.CALCULATION_PRICE_METHOD_GROSS
      : CALCULATION_PRICE_METHOD.CALCULATION_PRICE_METHOD_NET,
    annualMileage: (data?.annualMileage + 1) * 5000,
    offerPreference:
      data?.selectedPackage === 'full' ? 2 : data?.selectedPackage === 'basic' ? 1 : 3,
    requestedDealerProducts:
      data?.selectedPackage === 'full'
        ? [
            DEALER_PRODUCT_CLASSIFICATION.DEALER_PRODUCT_CLASSIFICATION_PPI,
            DEALER_PRODUCT_CLASSIFICATION.DEALER_PRODUCT_CLASSIFICATION_CASCO,
            DEALER_PRODUCT_CLASSIFICATION.DEALER_PRODUCT_CLASSIFICATION_EXTENDED_WARRANTY,
          ]
        : [],
    additionalAmount: 0,
    //requestedBankId: 'string',
    //requestedRate: 0,
    //stagedDeals: true,
  }
  return formData
}
