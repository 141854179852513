import React from 'react'

const LDSRoller = (props) => {
  const { wrapperStyles } = props

  return (
    <div
      className="lds-roller-wrapper"
      style={{
        ...wrapperStyles,
      }}
    >
      <div className="lds-roller">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

export default LDSRoller
