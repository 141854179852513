import React from 'react'
import { colorsConfig } from '../../config/themeConfig'
import UITextHeadlineSmall from './UITextHeadlineSmall'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

const UITextTitleBordered = ({ text, sx, ...props }) => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        width: '100%',
        borderBottom: '1px solid',
        borderColor: colorsConfig.buttonDisabled,
        pb: '10px',
        ...sx,
      }}
      {...props}
    >
      <UITextHeadlineSmall text={t(text)} />
    </Box>
  )
}

export default UITextTitleBordered
