import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { IconButton } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { UIVariant } from '../../utils/constants/ui.constants'

const UIIconButton = ({
  text = '',
  children,
  sx,
  variant = UIVariant.primary,
  className,
  ...props
}) => {
  return (
    <Tooltip title={text}>
      <IconButton
        className={[className, `ui-variant-${variant}`].join(' ')}
        sx={{
          '& svg': {
            color: colorsConfig.iconGray,
            transition: '0.25s all ease-in-out',
          },
          '&:hover': {
            '& svg': {
              color: colorsConfig.mainBlue,
              transition: '0.25s all ease-in-out',
            },
          },
          '&.ui-variant-danger': {
            '&:hover': {
              '& svg': {
                color: colorsConfig.darkRed,
                transition: '0.25s all ease-in-out',
              },
            },
          },
          ...sx,
        }}
        {...props}
      >
        {children}
      </IconButton>
    </Tooltip>
  )
}

export default UIIconButton
