import React, { useEffect } from 'react'
import { Grid, Stack } from '@mui/material'
import { useField } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { colorsConfig } from '../../../../config/themeConfig'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import UITextTitleSmall from '../../../../ui/UIText/UITextTitleSmall'
import UICheckPoint from '../../../../ui/UICheckPoint/UICheckPoint'
import UITextRegular from '../../../../ui/UIText/UITextRegular'
import UIPackageTile from '../../../../ui/UIBoxes/UIPackageTile'
import { calculateWarrantyProducts } from '../../../../store/features/warrantyApplicationSlice/warrantyApplication.actions'
import { sumDelimiter } from '../../../../utils/sumDelimiter'
import { CURRENCY, REGION_LOCALE } from '../../../../utils/constants/global.constants'

export const parseCoveredComponents = (selectedComponents, referenceComponents) => {
  return referenceComponents.filter((comp) => {
    const matchComp = selectedComponents.find((item) => item === comp?.['@id'])
    return !!matchComp
  })
}

const checkPointHeight = 18.5

export const getPremiumPrice = (refProducts, product) => {
  const matchProduct = refProducts.find((item) => item?.warrantyProduct === product)
  if (matchProduct) {
    return sumDelimiter(parseFloat(matchProduct?.finalPremium).toFixed(2)).replace("'", ' ')
  }
  return 0
}

export const getMonthlyPayment = (refProducts, product) => {
  const matchProduct = refProducts.find((item) => item?.warrantyProduct === product)
  if (matchProduct) {
    const monthlyAmount = parseFloat(matchProduct?.finalPremium) / 12
    return sumDelimiter(monthlyAmount.toFixed(2)).replace("'", ' ')
  }
  return 0
}

const CarWarrantyPackages = ({ disabled, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers

  const isLoadingProducts = useSelector((state) => state.warrantyProducts.isLoading)
  const isLoadingComponents = useSelector((state) => state.coveredComponents.isLoading)
  const warrantyProducts = useSelector((state) => state.warrantyProducts.warrantyProducts)
  const coveredComponents = useSelector((state) => state.coveredComponents.coveredComponents)
  const warrantyApplication = useSelector((state) => state.warrantyApplication.warrantyApplication)
  const calculatedProducts = useSelector(
    (state) => state.warrantyApplication.calculation.calculatedProducts,
  )
  const car = useSelector((state) => state.car.car)

  const dispatch = useDispatch()

  const basicProduct = warrantyProducts.find((item) => item?.sysName === 'basic')
  const silverProduct = warrantyProducts.find((item) => item?.sysName === 'silver')
  const platinumProduct = warrantyProducts.find((item) => item?.sysName === 'platinum')
  const basicProductComps = parseCoveredComponents(
    basicProduct?.coveredComponents,
    coveredComponents,
  )
  const silverProductComps = parseCoveredComponents(
    silverProduct?.coveredComponents,
    coveredComponents,
  )
  const platinumProductComps = parseCoveredComponents(
    platinumProduct?.coveredComponents,
    coveredComponents,
  )

  const displayPackages =
    !isLoadingComponents &&
    !isLoadingProducts &&
    warrantyProducts?.length >= 3 &&
    calculatedProducts.length > 0
  const blockHeight = platinumProductComps.length * (checkPointHeight + 15) - 15

  useEffect(() => {
    if (warrantyApplication && calculatedProducts.length === 0) {
      const hp = (car?.independentCarInfo?.hp && car?.independentCarInfo?.hp !== "") ?
        parseInt(car?.independentCarInfo?.hp) : parseInt(car?.hp)
      const calculationData = {
        mileage: parseInt(warrantyApplication.mileage),
        term: 12,
        hp,
        currency: CURRENCY[REGION_LOCALE],
        carAge: 12,
      }
      dispatch(
        calculateWarrantyProducts({
          data: calculationData,
        }),
      )
    }
  }, [warrantyApplication])

  useEffect(() => {
    if (basicProduct) {
      setValue(basicProduct?.['@id'])
    }
  }, [basicProduct])

  return displayPackages ? (
    <Grid container spacing="16px">
      <Grid item xs={12} md={4}>
        {basicProduct ? (
          <UIPackageTile isSelected={field.value === basicProduct?.['@id']} disabled={disabled}>
            <UITextTitleSmall text={basicProduct?.name} sx={{ mb: '30px' }} />
            <Stack gap="15px" sx={{ mb: '30px', height: { md: `${blockHeight}px`, xs: 'auto' } }}>
              {basicProductComps.map((item, index) => {
                return <UICheckPoint key={index} text={item?.name} />
              })}
            </Stack>
            <UITextRegular>
              From{' '}
              <span style={{ fontWeight: 700, fontSize: '24px' }}>
                {getMonthlyPayment(calculatedProducts, basicProduct?.['@id'])}
              </span>{' '}
              {CURRENCY[REGION_LOCALE]}/month
            </UITextRegular>
            <UITextRegular sx={{ color: colorsConfig.lightGray }}>
              From {getPremiumPrice(calculatedProducts, basicProduct?.['@id'])} per year
            </UITextRegular>
            <ButtonLG
              disabled={disabled}
              sx={{ mt: '30px' }}
              onClick={() => setValue(basicProduct?.['@id'])}
            >
              {field.value === basicProduct?.['@id'] ? 'Selected' : 'Choose'}
            </ButtonLG>
          </UIPackageTile>
        ) : null}
      </Grid>
      <Grid item xs={12} md={4}>
        {silverProduct ? (
          <UIPackageTile isSelected={field.value === silverProduct?.['@id']} disabled={disabled}>
            <UITextTitleSmall text={silverProduct?.name} sx={{ mb: '30px' }} />
            <Stack gap="15px" sx={{ mb: '30px', height: { md: `${blockHeight}px`, xs: 'auto' } }}>
              {silverProductComps.map((item, index) => {
                return <UICheckPoint key={index} text={item?.name} />
              })}
            </Stack>
            <UITextRegular>
              From{' '}
              <span style={{ fontWeight: 700, fontSize: '24px' }}>
                {getMonthlyPayment(calculatedProducts, silverProduct?.['@id'])}
              </span>{' '}
              {CURRENCY[REGION_LOCALE]}/month
            </UITextRegular>
            <UITextRegular sx={{ color: colorsConfig.lightGray }}>
              From {getPremiumPrice(calculatedProducts, silverProduct?.['@id'])} per year
            </UITextRegular>
            <ButtonLG
              disabled={disabled}
              sx={{ mt: '30px' }}
              onClick={() => setValue(silverProduct?.['@id'])}
            >
              {field.value === silverProduct?.['@id'] ? 'Selected' : 'Choose'}
            </ButtonLG>
          </UIPackageTile>
        ) : null}
      </Grid>
      <Grid item xs={12} md={4}>
        {platinumProduct ? (
          <UIPackageTile isSelected={field.value === platinumProduct?.['@id']} disabled={disabled}>
            <UITextTitleSmall text={platinumProduct?.name} sx={{ mb: '30px' }} />
            <Stack gap="15px" sx={{ mb: '30px', height: { md: `${blockHeight}px`, xs: 'auto' } }}>
              {platinumProductComps.map((item, index) => {
                return <UICheckPoint key={index} text={item?.name} />
              })}
            </Stack>
            <UITextRegular>
              From{' '}
              <span style={{ fontWeight: 700, fontSize: '24px' }}>
                {getMonthlyPayment(calculatedProducts, platinumProduct?.['@id'])}
              </span>{' '}
              {CURRENCY[REGION_LOCALE]}/month
            </UITextRegular>
            <UITextRegular sx={{ color: colorsConfig.lightGray }}>
              From {getPremiumPrice(calculatedProducts, platinumProduct?.['@id'])} per year
            </UITextRegular>
            <ButtonLG
              disabled={disabled}
              sx={{ mt: '30px' }}
              onClick={() => setValue(platinumProduct?.['@id'])}
            >
              {field.value === platinumProduct?.['@id'] ? 'Selected' : 'Choose'}
            </ButtonLG>
          </UIPackageTile>
        ) : null}
      </Grid>
    </Grid>
  ) : null
}

export default CarWarrantyPackages
