import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { PieChart } from '@mui/x-charts/PieChart'
import TextH1 from '../../../../ui/Text/TextH1'
import UIPreloaderCircular from '../../../../ui/UIPreloader/UIPreloaderCircular'
import StatusList from '../StatusList'
import { getInsuranceStatusList } from '../../dashboard.helpers'
import {
  insuranceStatusColors,
  pieChartConfig,
  warrantyStatusColors,
} from '../../dashboard.constants'
import warrantyApplicationsApiService from '../../../../api/warrantyApplicationsService/warrantyApplicationsApiService'
import { apiConstants } from '../../../../api/api'
import { insuranceWorkflowsEnums } from '../../../Insurance/insurance.constants'
import WarrantyStatusCakeModal from './WarrantyStatusCakeModal'
import { useTranslation } from 'react-i18next'

const WarrantyStatusCake = () => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(true)
  const [statusCakeModal, setStatusCakeModal] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [activeWarranties, setActiveWarranties] = useState([])
  const [expiredWarranties, setExpiredWarranties] = useState([])
  const [expiringWarranties, setExpiringWarranties] = useState([])

  const total = activeWarranties?.length + expiringWarranties?.length + expiredWarranties?.length

  const handleOpenModal = (data) => {
    setModalData(data)
    setStatusCakeModal(true)
  }

  const getActiveWarranties = (warrantyApplications) => {
    return warrantyApplications.filter((item) => {
      const currentDate = new Date()
      const startDate = new Date(item?.startDate)
      const expiryDate = new Date(item?.startDate)
      expiryDate.setDate(expiryDate.getFullYear() + 1)
      return currentDate < expiryDate && currentDate > startDate
    })
  }

  const getExpiredWarranties = (warrantyApplications) => {
    return warrantyApplications.filter((item) => {
      const currentDate = new Date()
      const expiryDate = new Date(item?.startDate)
      expiryDate.setDate(expiryDate.getFullYear() + 1)
      return currentDate > expiryDate
    })
  }

  const getExpiringWarranties = (warrantyApplications) => {
    return warrantyApplications.filter((item) => {
      const currentDate = new Date()
      const expiryDate = new Date(item?.startDate)
      expiryDate.setDate(expiryDate.getFullYear() + 1)
      const preExpiryDate = new Date(item?.startDate)
      preExpiryDate.setDate(preExpiryDate.getFullYear() + 1)
      preExpiryDate.setDate(preExpiryDate.getDate() - 30)
      return currentDate > preExpiryDate && currentDate < expiryDate
    })
  }

  const getApplications = async () => {
    setIsLoading(true)
    try {
      const response = await warrantyApplicationsApiService.getApplications({
        pagination: false,
        isArchived: false,
        currentWorkflowState: 'issued',
      })
      const data = response.data[apiConstants.hydraMember]
      setActiveWarranties(getActiveWarranties(data))
      setExpiredWarranties(getExpiredWarranties(data))
      setExpiringWarranties(getExpiringWarranties(data))
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getApplications()
  }, [])

  return (
    <Box
      sx={{
        width: '100%',
        height: '369px',
        padding: '32px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <TextH1>{t('title_warranty_status_cake')}</TextH1>
      {isLoading ? (
        <UIPreloaderCircular sx={{ mt: '50px' }} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            gap: '25px',
          }}
        >
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ fontSize: '32px', fontWeight: 700, color: '#11263C' }}>
                {total}
              </Typography>
              <Typography
                sx={{ fontSize: '12px', color: 'rgba(60, 60, 67, 0.60)', marginTop: '-5px' }}
              >
                {t('common_total')}
              </Typography>
            </Box>
            <PieChart
              colors={warrantyStatusColors}
              series={[
                {
                  data: [
                    {
                      label: 'Active',
                      value: activeWarranties.length,
                    },
                    {
                      label: 'Expiring',
                      value: expiringWarranties.length,
                    },
                    {
                      label: 'Expired',
                      value: expiredWarranties.length,
                    },
                  ],
                  ...pieChartConfig,
                },
              ]}
              width={210}
              height={210}
              sx={{
                '& .MuiChartsLegend-root': {
                  display: 'none',
                },
              }}
            />
          </Box>

          <StatusList
            data={[
              {
                name: 'Active warranties',
                count: activeWarranties.length,
                items: activeWarranties,
              },
              {
                name: 'Expiring warranties',
                count: expiringWarranties.length,
                items: expiringWarranties,
              },
              {
                name: 'Expired warranties',
                count: expiredWarranties.length,
                items: expiredWarranties,
              },
            ]}
            colors={warrantyStatusColors}
            handleOpenModal={handleOpenModal}
          />
        </Box>
      )}
      {modalData ? (
        <WarrantyStatusCakeModal
          open={statusCakeModal}
          setOpen={setStatusCakeModal}
          data={modalData}
        />
      ) : null}
    </Box>
  )
}

export default WarrantyStatusCake
