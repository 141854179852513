import React, { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Autocomplete,
  Typography,
  createTheme,
  ThemeProvider,
  Button,
  IconButton,
  Stack,
  Divider,
} from '@mui/material'
import { CircularProgress } from '@mui/material'
import { API_ENDPOINTS } from '../../../config/default'
import VINValidator from '../../../utils/VinValidator'
import { privateRequest } from '../../../utils/request'
import '../AddNewVehicle.scss'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import {
  setVin,
  setMake,
  setModel,
  setCustomerCar,
} from '../../../store/features/addNewCarSlice/addNewCar.reducer'
import TextFieldSmall from '../../../ui/TextFieldSmall'
import { colorsConfig } from '../../../config/themeConfig'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { createWorker } from 'tesseract.js'
import 'react-image-crop/dist/ReactCrop.css'
import IconAlbumPhoto from '../../../assests/img/icons/icon-album-photo.svg'
import { setCarMainData } from '../../../store/features/addNewCarSlice/addNewCar.reducer'
import { useTranslation } from 'react-i18next'
import { setBalance } from '../../../store/features/authSlice'
import RequestCoinsModal from '../../../components/Modals/RequestCoinsModal'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import dayjs from 'dayjs'
import UIBadgeSuccess from '../../../ui/Badges/UIBadgeSuccess'
import IconCarAutofilled from '../../../assests/img/icons/icon-car-autofilled.svg'
import VINTesseractReadoutModal from '../Modals/VINTesseractReadoutModal'
import VINAutofillSucceededModal from '../Modals/VINAutofillSucceededModal'
import VINAutofillFailedModal from '../Modals/VINAutofillFailedModal'
import VINAutofillModal from '../Modals/VINAutofillModal'
import { getCarDetailsFromVINProvider } from '../../../store/features/addNewCarSlice/addNewCar.actions'
import { setRviCarCompany } from '../../../store/features/addNewCarSlice/addNewCar.reducer'
import ButtonSuccess from '../../../ui/Buttons/ButtonSuccess'
import { AddRounded } from '@mui/icons-material'
import { LOCAL_ROLE } from '../../../utils/constants/global.constants'
import { getAllCompanies } from '../../../store/features/companiesSlice/companies.actions'
import { formErrors } from '../../../components/Forms/FormTemplates/formErrors'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import UITextBodySmall from '../../../ui/UIText/UITextBodySmall'
import CarRequiredFields from '../FormTemplates/CarRequiredFields'
import { getMainValuesFormData } from '../addNewVehicleForm.helpers'
import { CAR_ENUMS } from '../../../utils/constants/enums.constants'

const CoreDataStep = ({ showErrors, handleNext, formData, setFormData }) => {
  const { t } = useTranslation()

  const theme = createTheme({
    spacing: [0, 5, 10, 20],
  })

  const { isMaxWidth600, isMinWidth600 } = useBreakpoints()

  const companies = useSelector((state) => state.companies.allCompanies.data)
  const role = useSelector((state) => state.auth?.role)
  const vin = useSelector((state) => state.addCar.carFirstData.vin)
  const make = useSelector((state) => state.addCar.carFirstData.make)
  const model = useSelector((state) => state.addCar.carFirstData.model)
  const company = useSelector((state) => state.addCar.carFirstData.company)
  const carCreatedId = useSelector((state) => state.addCar.carCreatedId)
  const vinProviderData = useSelector((state) => state.addCar.vinProvider.data)
  const policy = useSelector((state) => state.addCar.policy)
  const enumDetails = useSelector((state) => state.ref.enumDetails.data)
  const customerAppraisal = useSelector((state) => state.customerAppraisal.customer.data)
  const isCarDataAutofilled = !!vinProviderData
  const dispatch = useDispatch()

  const [makes, setMakes] = useState([])
  const [models, setModels] = useState([])
  const [makesLoading, setMakesLoading] = useState(false)
  const [firstBlur, setFirstBlur] = useState(false)
  const [requestCoinsModal, setRequestCoinsModal] = useState(false)
  const [isVINTesseractReadoutModalOpen, setIsVINTesseractReadoutModalOpen] = useState(false)
  const [vinCode, setVINCode] = useState('')
  const [vinCodeLoading, setVinCodeLoading] = useState(false)
  const [imagePath, setImagePath] = useState('')
  const [showVIN, setShowVIN] = useState(false)
  const [crop, setCrop] = useState({
    unit: '%',
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  })
  const [rotation, setRotation] = useState(0)
  const [rotatedImage, setRotatedImage] = useState(null)
  const [rotationIntervalId, setRotationIntervalId] = useState(0)
  const [isVINAutofillModalOpen, setIsVINAutofillModalOpen] = useState(false)
  const [isVINAutofillSucceededModalOpen, setIsVINAutofillSucceededModalOpen] = useState(false)
  const [isVINAutofillFailedModalOpen, setIsVINAutofillFailedModalOpen] = useState(false)

  const handleSetVin = (value) => {
    const validateVin = VINValidator().validate(value)
    const newVin = {
      valid: validateVin.valid,
      errorText: validateVin.error ? validateVin.error : '',
      value: value.toUpperCase(),
    }
    dispatch(setVin(newVin))
  }

  const handleSetMake = (value) => {
    dispatch(
      setMake({
        ...make,
        name: value,
      }),
    )
  }

  const handleSetModel = (value) => {
    let matchModel = null
    if (value) {
      matchModel = models.find((item) => value === item?.name)
    }
    dispatch(
      setModel({
        name: value,
        id: matchModel ? matchModel['@id'] : '',
      }),
    )
  }

  const getMakes = (searchLine) => {
    privateRequest.get(`${API_ENDPOINTS.makes}?name=${searchLine}`).then((response) => {
      setMakes(response.data['hydra:member'])
      setMakesLoading(false)
      const matchMake = response.data['hydra:member'].find((item) => item?.name === make?.name)
      if (matchMake) {
        dispatch(
          setMake({
            ...make,
            id: matchMake['@id'],
          }),
        )
        getModels(matchMake['@id'])
      }
    })
  }

  const handleSetMakeAndModel = (makeName, modelName, callback) => {
    privateRequest
      .get(`${API_ENDPOINTS.makes}?name=${makeName}`)
      .then((response) => {
        const makesArray = response.data['hydra:member']
        setMakes(makesArray)
        setMakesLoading(false)
        let matchMake = null
        if (makeName) {
          matchMake = makesArray.find((item) => item?.name === makeName)
        }
        privateRequest
          .get(`${API_ENDPOINTS.models}?make=${matchMake['@id']}&pagination=false`)
          .then((response) => {
            const modelsArray = response.data['hydra:member']
            setModels(modelsArray)
            if (modelsArray.length === 0) {
              toast.error('No models found for this make')
            }
            let matchModel
            matchModel = modelsArray.find((item) => item?.name === modelName)
            dispatch(
              setMake({
                name: makeName,
                id: matchModel ? matchModel.make : matchMake ? matchMake['@id'] : '',
              }),
            )
            dispatch(
              setModel({
                name: modelName,
                id: matchModel ? matchModel['@id'] : '',
              }),
            )
          })
      })
      .catch((error) => {
        setIsVINAutofillModalOpen(false)
        setIsVINAutofillFailedModalOpen(true)
      })
  }

  const getModels = (makePath) => {
    privateRequest
      .get(`${API_ENDPOINTS.models}?make=${makePath}&pagination=false`)
      .then((response) => {
        setModels(response.data['hydra:member'])
        if (response.data['hydra:member'].length === 0) {
          toast.error('Can not add this make: no models found')
        }
      })
  }

  useEffect(() => {
    if (firstBlur) {
      if (make.name) {
        const matchMake = makes.find((item) => {
          return item?.name === make?.name
        })
        if (matchMake) {
          setMakesLoading(false)
          dispatch(
            setMake({
              ...make,
              id: matchMake['@id'],
            }),
          )
          getModels(matchMake['@id'])
        } else if (make.name.length >= 2) {
          // if (!isVINAutofillModalOpen && !isVINAutofillSucceededModalOpen) {
          //   dispatch(
          //     setMake({
          //       ...make,
          //       id: null,
          //     }),
          //   )
          // }

          setModels([])
          setMakesLoading(true)
          getMakes(make.name)
        }
      } else {
        dispatch(
          setMake({
            ...make,
            id: null,
          }),
        )
        setModels([])
      }
    }
    setFirstBlur(true)
  }, [make.name])

  useEffect(() => {
    if (carCreatedId) {
      getModels(make.id)
    }
  }, [])

  const closeVINTesseractReadoutModal = () => {
    setIsVINTesseractReadoutModalOpen(false)

    const vinFromFileInputs = document.querySelectorAll('input[id^="VINFrom"][type="file"]')
    vinFromFileInputs.forEach((input) => {
      input.value = ''
    })
  }

  useEffect(() => {
    const updateRotatedImage = async () => {
      if (imagePath) {
        const rotated = await rotateImage(imagePath, rotation)
        setRotatedImage(rotated)
      }
    }

    updateRotatedImage()
  }, [rotation, imagePath])

  const handleFileChange = async (event) => {
    const file = event.target.files[0]
    if (file) {
      const imageDataUrl = await convertImageToDataUrl(file)
      setImagePath(imageDataUrl)
      setShowVIN(false)

      const img = new Image()
      img.src = imageDataUrl

      img.onload = () => {
        setIsVINTesseractReadoutModalOpen(true)

        setTimeout(() => {
          const containerWidth = document.getElementById('vinCheckImageContainer').clientWidth
          const aspect = img.width / containerWidth
          const offset = 5

          setCrop({
            unit: 'px',
            x: offset,
            y: offset,
            width: img.width / aspect - offset * 2,
            height: img.height / aspect - offset * 2,
          })
        })
      }
    }
  }

  const readVINFromImage = async (croppedImage) => {
    try {
      const worker = await createWorker('eng')

      await worker.loadLanguage('eng')
      await worker.initialize('eng')
      const {
        data: { text },
      } = await worker.recognize(croppedImage)
      setVINCode(text)
      setShowVIN(true)

      await worker.terminate()
    } catch (error) {
      console.error('Error reading VIN:', error.message)
    }
  }

  const convertImageToDataUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => resolve(event.target.result)
      reader.onerror = (error) => reject(error)
      reader.readAsDataURL(file)
    })
  }

  const handleTesseractReadoutClick = async () => {
    if (rotatedImage) {
      const croppedImage = await getCroppedImage(rotatedImage, crop)
      readVINFromImage(croppedImage)
      setVinCodeLoading(true)
      closeVINTesseractReadoutModal()
    } else {
      console.warn('No image selected for readout from img.')
    }
  }

  const getCroppedImage = async (rotatedImage, crop) => {
    const image = new Image()
    image.src = rotatedImage

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    canvas.width = crop.width
    canvas.height = crop.height

    const vinCheckImageContainer = document.getElementById('vinCheckImageContainer')
    const imageContainerWidth = vinCheckImageContainer.clientWidth
    const aspect = image.width / imageContainerWidth

    ctx.drawImage(
      image,
      crop.x * aspect,
      crop.y * aspect,
      crop.width * aspect,
      crop.height * aspect,
      0,
      0,
      crop.width,
      crop.height,
    )

    return canvas.toDataURL('image/jpeg')
  }

  const rotateImage = async (originalImage, degrees) => {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const { naturalWidth, naturalHeight } = image

        canvas.width = naturalWidth
        canvas.height = naturalHeight

        ctx.translate(naturalWidth / 2, naturalHeight / 2)
        ctx.rotate((degrees * Math.PI) / 180)
        ctx.drawImage(image, -naturalWidth / 2, -naturalHeight / 2, naturalWidth, naturalHeight)

        const rotatedImage = canvas.toDataURL('image/jpeg')
        resolve(rotatedImage)
      }

      image.onerror = (error) => reject(error)

      image.src = originalImage
    })
  }

  const handleRotateHold = (direction) => {
    const intervalId = setInterval(() => {
      direction === 'left' && setRotation((prevRotation) => (prevRotation - 1) % 360)
      direction === 'right' && setRotation((prevRotation) => (prevRotation + 1) % 360)
    }, 40)

    setRotationIntervalId(intervalId)
  }

  const handleRotateLeft = () => handleRotateHold('left')
  const handleRotateRight = () => handleRotateHold('right')

  const handleRotateRelease = () => {
    clearInterval(rotationIntervalId)
  }

  useEffect(() => {
    if (vinCode) {
      const cleanedVinCode = vinCode.replace(/[^a-zA-Z0-9]/g, '')
      const modifiedVinCode = cleanedVinCode.slice(0, 17)

      handleSetVin(modifiedVinCode)
      setVinCodeLoading(false)
    }
  }, [vinCode])

  const getUserBalance = () => {
    privateRequest.get(API_ENDPOINTS.userInfo).then((response) => {
      dispatch(setBalance(response.data.manager.company.coinBalance.balance))
    })
  }

  useEffect(() => {
    if (vinProviderData && vinProviderData?.make?.name && vinProviderData?.model?.name) handleNext()
  }, [vinProviderData])

  useEffect(() => {
    if (role && role === LOCAL_ROLE.OPERATOR) {
      dispatch(getAllCompanies())
    }
  }, [role])

  useEffect(() => {
    if (customerAppraisal && customerAppraisal?.car && enumDetails?.length > 0) {
      const origin = enumDetails
        .find((item) => item?.name === CAR_ENUMS.CAR_ORIGIN)
        ?.caseDetails?.find((item) => item?.value === customerAppraisal?.car?.origin)
      setFormData(customerAppraisal.car)
      setMakes([customerAppraisal?.car?.make])
      setModels([customerAppraisal?.car?.model])
      dispatch(setCustomerCar(customerAppraisal))
    }
  }, [customerAppraisal, enumDetails])

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          mt: {
            xs: '20px',
            sm: '40px',
            position: 'relative',
          },
        }}
      >
        <Grid
          container
          sx={{
            position: 'relative',
            mb: '20px',
          }}
          spacing="20px"
        >
          <Grid item sm={4} xs={12}>
            <TextFieldSmallEdible
              required
              fullWidth
              size={'small'}
              id="car-vin"
              label="VIN"
              value={vin.value}
              onChange={(e) => {
                if (e.target.value.length > 17) {
                  e.preventDefault()
                } else {
                  handleSetVin(e.target.value.replace(/[ioqIOQ]/g, ''))
                }
              }}
              helperText={
                showErrors && !vin.errorText && !vin.valid ? 'error_enter_vin' : vin.errorText
              }
              error={showErrors || vin.value ? !vin.valid : false}
              InputProps={{
                endAdornment: (
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap="5px"
                    sx={{ position: 'relative' }}
                  >
                    <IconButton
                      sx={{
                        height: '36px',
                        borderRadius: '4px',
                      }}
                    >
                      <label
                        htmlFor="VINFromGallery"
                        style={{
                          width: '100%',
                          cursor: 'pointer',
                          display: 'block',
                          height: '30px',
                        }}
                      >
                        <img src={IconAlbumPhoto} alt="" />

                        <input
                          id="VINFromGallery"
                          type="file"
                          accept="image/*"
                          hidden
                          onChange={handleFileChange}
                        />
                      </label>
                    </IconButton>
                    <Typography
                      variant="body2"
                      sx={{
                        color:
                          vin.value === null || vin.value.length === 17 || vin.value.length === 0
                            ? 'lightgray'
                            : '#d32f2f',
                      }}
                    >
                      {vin.value ? vin.value.length : 0}/17
                    </Typography>
                  </Stack>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              required
              fullWidth
              id="car-make"
              options={makes.map((item) => {
                return item.name
              })}
              value={make.name}
              loading={!make.name ? true : makesLoading}
              loadingText={!make.name ? t('common_start_typing') : t('preloader_loading_makes')}
              onChange={(e, value) => {
                handleSetMake(value)
              }}
              freeSolo
              renderInput={(params) => (
                <TextFieldSmallEdible
                  {...params}
                  // isAutocomplete
                  label={t('form_input_label_make')}
                  size={'small'}
                  value={make.name}
                  fullWidth
                  onChange={(e) => {
                    handleSetMake(e.target.value)
                  }}
                  error={showErrors && !make.id}
                  helperText={showErrors && !make.id ? formErrors.selectMake : ''}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {makesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box sx={{ position: 'relative' }}>
              {!policy?.details?.model && policy?.details?.modelName && (
                <UITextBodySmall
                  sx={{
                    position: 'absolute',
                    top: '-20px',
                    right: '10px',
                    fontSize: '12px',
                    color: colorsConfig.iconGray,
                  }}
                  text={`Original model name: ${policy?.details?.modelName}`}
                />
              )}
              <Autocomplete
                required
                disablePortal
                id="car-model"
                options={models.map((item) => {
                  return item.name
                })}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={props.id}>
                      {option}
                    </li>
                  )
                }}
                fullWidth
                value={model.name}
                loading={models.length === 0}
                freeSolo
                loadingText={
                  !firstBlur && carCreatedId
                    ? t('common_no_options')
                    : !make.id
                      ? t(formErrors.selectMakeFirst)
                      : ''
                }
                onChange={(e, newVal) => {
                  handleSetModel(newVal)
                }}
                renderInput={(params) => (
                  <TextFieldSmallEdible
                    {...params}
                    //isAutocomplete
                    label={t('form_input_label_model')}
                    size={'small'}
                    value={model.name}
                    onChange={(e) => {
                      handleSetModel(e.target.value)
                    }}
                    className={isCarDataAutofilled && !model.id ? 'attention' : ''}
                    error={showErrors && !model.id}
                    helperText={showErrors && !model.id ? t(formErrors.selectModel) : ''}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
        <CarRequiredFields formData={formData} setFormData={setFormData} showErrors={showErrors} />

        <VINTesseractReadoutModal
          isVINTesseractReadoutModalOpen={isVINTesseractReadoutModalOpen}
          closeVINTesseractReadoutModal={closeVINTesseractReadoutModal}
          handleRotateLeft={handleRotateLeft}
          handleRotateRelease={handleRotateRelease}
          handleRotateRight={handleRotateRight}
          rotatedImage={rotatedImage}
          crop={crop}
          setCrop={setCrop}
          handleTesseractReadoutClick={handleTesseractReadoutClick}
        />
        {vinCodeLoading ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: '1000',
              width: 'calc(100% + 40px)',
              height: isMinWidth600 ? '300px' : '200px',
              background: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
              color: '#007DFF',
              marginLeft: '-20px',

              '& .MuiCircularProgress-circle': {
                stroke: '#007DFF',
              },
            }}
          >
            <CircularProgress size={130} thickness={6} />
            <Typography>Loading</Typography>
          </Box>
        ) : null}
      </Box>
    </ThemeProvider>
  )
}

export default CoreDataStep
