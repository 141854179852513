import React from 'react'
import { FormControl } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const FormControlTFS = ({ children, sx, required, error, ...rest }) => {
  return (
    <FormControl
      sx={{
        color: colorsConfig.iconGray,
        fontWeight: 400,
        fontSize: '14px',
        border: 'none',
        ...sx,
      }}
      size="small"
      required={required}
      error={error}
      {...rest}
    >
      {children}
    </FormControl>
  )
}

export default FormControlTFS
