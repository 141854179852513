import { closeDueToEndOfContract } from '../rviPolicy.actions'

export default (builder) => {
  builder
    .addCase(closeDueToEndOfContract.pending, (state) => {
      state.closeDueToEndOfContract.isLoading = true
    })
    .addCase(closeDueToEndOfContract.fulfilled, (state, action) => {
      state.closeDueToEndOfContract.isLoading = false
      state.closeDueToEndOfContract.error = null
    })
    .addCase(closeDueToEndOfContract.rejected, (state, action) => {
      state.closeDueToEndOfContract.isLoading = false
      state.closeDueToEndOfContract.error = action.payload
    })
}
