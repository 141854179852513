import { getWarrantyApplicationsWorkflowStateCounts } from '../warrantyApplications.actions'
import { apiConstants } from '../../../../api/api'

export default (builder) => {
  builder
    .addCase(getWarrantyApplicationsWorkflowStateCounts.pending, (state) => {
      state.workflowStateCounts.isLoading = true
    })
    .addCase(getWarrantyApplicationsWorkflowStateCounts.fulfilled, (state, action) => {
      state.workflowStateCounts.isLoading = false
      state.workflowStateCounts.error = null
      state.workflowStateCounts.applicationPreparationCount = action.payload?.[
        apiConstants.hydraMember
      ].find((item) => item?.workflowState === 'application_preparation')?.count
      state.workflowStateCounts.issuedCount = action.payload?.[apiConstants.hydraMember].find(
        (item) => item?.workflowState === 'issued',
      )?.count
    })
    .addCase(getWarrantyApplicationsWorkflowStateCounts.rejected, (state, action) => {
      state.workflowStateCounts.isLoading = false
      state.workflowStateCounts.error = action.payload
    })
}
