import React, { useEffect, useState } from 'react'
import { Box, InputAdornment, Table, TextField } from '@mui/material'
import ModalWrap from '../../../components/Modals/ModalWrap'
import TextH1 from '../../../ui/Text/TextH1'
import { colorsConfig } from '../../../config/themeConfig'
import iconSearch from '../../../assests/img/icons/icon-search.svg'
import CarTableHead from '../../VirtualGarage/CarTableHead'
import { tableHeadCellsFinancing } from '../../../config/tablesConfig'
import CarTableBody from '../../VirtualGarage/CarTableBody'
import TextNormal from '../../../ui/Text/TextNormal'
import { useTranslation } from 'react-i18next'

const StatusCakeFinanceModal = (props) => {
  const { open, setOpen, title, data } = props

  const { t } = useTranslation()

  const [searchLine, setSearchLine] = useState('')
  const [loanApplications, setLoanApplications] = useState([])

  const renderBankReplies = (loanDeals) => {
    const acceptedRepliesCount = loanDeals.filter(
      (item) => item.currentWorkflowState === 'approved',
    ).length
    const bankRequestRepliesCount = loanDeals.filter(
      (item) => item.currentWorkflowState === 'bank_request_info',
    ).length
    const rejectedRepliesCount = loanDeals.filter(
      (item) => item.currentWorkflowState === 'bank_declined',
    ).length

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '14px',
        }}
      >
        <Box
          className={'success'}
          sx={{
            width: '35px',
            height: '35px',
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            background: colorsConfig.buttonDisabled,
            position: 'relative',
            '& p': { color: 'white' },
            '&.danger': {
              background: colorsConfig.darkRed,
            },
            '&.success': {
              background: colorsConfig.mainGreen,
              '& p': { color: colorsConfig.mainBlack },
            },
            '&.attention': {
              background: colorsConfig.buttonYellow,
              '& p': { color: colorsConfig.mainBlack },
            },
            '&:hover': {
              '& .popover': {
                opacity: 1,
                transform: 'scale(1)',
                transition: '0.25s all ease-in-out',
              },
            },
          }}
        >
          <TextNormal>{acceptedRepliesCount}</TextNormal>
          <TextNormal
            className={'popover'}
            sx={{
              py: '4px',
              px: '6px',
              borderRadius: '6px',
              background: 'white',
              position: 'absolute',
              boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
              bottom: '100%',
              left: '-9px',
              opacity: 0,
              transform: 'scale(0)',
              transformOrigin: '20px 100%',
              transition: '0.25s all ease-in-out',
              '&::after': {
                position: 'absolute',
                content: '" "',
                display: 'block',
                top: '100%',
                left: '20px',
                width: '0px',
                height: '0px',
                borderStyle: 'solid',
                borderWidth: '0 6px 9px 6px',
                borderColor: 'transparent transparent white transparent',
                transform: 'rotate(180deg)',
              },
            }}
          >
            Accepted
          </TextNormal>
        </Box>
        <Box
          className={'attention'}
          sx={{
            width: '35px',
            height: '35px',
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            background: colorsConfig.buttonDisabled,
            position: 'relative',
            '& p': { color: 'white' },
            '&.danger': {
              background: colorsConfig.darkRed,
            },
            '&.success': {
              background: colorsConfig.mainGreen,
              '& p': { color: colorsConfig.mainBlack },
            },
            '&.attention': {
              background: colorsConfig.buttonYellow,
              '& p': { color: colorsConfig.mainBlack },
            },
            '&:hover': {
              '& .popover': {
                opacity: 1,
                transform: 'scale(1)',
                transition: '0.25s all ease-in-out',
              },
            },
          }}
        >
          <TextNormal>{bankRequestRepliesCount}</TextNormal>
          <TextNormal
            className={'popover'}
            sx={{
              py: '4px',
              px: '6px',
              borderRadius: '6px',
              background: 'white',
              position: 'absolute',
              boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
              bottom: '100%',
              left: '-9px',
              opacity: 0,
              transform: 'scale(0)',
              transformOrigin: '20px 100%',
              transition: '0.25s all ease-in-out',
              whiteSpace: 'nowrap',
              '&::after': {
                position: 'absolute',
                content: '" "',
                display: 'block',
                top: '100%',
                left: '20px',
                width: '0px',
                height: '0px',
                borderStyle: 'solid',
                borderWidth: '0 6px 9px 6px',
                borderColor: 'transparent transparent white transparent',
                transform: 'rotate(180deg)',
              },
            }}
          >
            Bank request
          </TextNormal>
        </Box>
        <Box
          className={'danger'}
          sx={{
            width: '35px',
            height: '35px',
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            background: colorsConfig.buttonDisabled,
            position: 'relative',
            '& p': { color: 'white' },
            '&.danger': {
              background: colorsConfig.darkRed,
            },
            '&.success': {
              background: colorsConfig.mainGreen,
              '& p': { color: colorsConfig.mainBlack },
            },
            '&.attention': {
              background: colorsConfig.buttonYellow,
              '& p': { color: colorsConfig.mainBlack },
            },
            '&:hover': {
              '& .popover': {
                opacity: 1,
                transform: 'scale(1)',
                transition: '0.25s all ease-in-out',
              },
            },
          }}
        >
          <TextNormal>{rejectedRepliesCount}</TextNormal>
          <TextNormal
            className={'popover'}
            sx={{
              py: '4px',
              px: '6px',
              borderRadius: '6px',
              background: 'white',
              position: 'absolute',
              boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
              bottom: '100%',
              left: '-9px',
              opacity: 0,
              transform: 'scale(0)',
              transformOrigin: '20px 100%',
              transition: '0.25s all ease-in-out',
              color: `${colorsConfig.mainBlack} !important`,
              '&::after': {
                position: 'absolute',
                content: '" "',
                display: 'block',
                top: '100%',
                left: '20px',
                width: '0px',
                height: '0px',
                borderStyle: 'solid',
                borderWidth: '0 6px 9px 6px',
                borderColor: 'transparent transparent white transparent',
                transform: 'rotate(180deg)',
              },
            }}
          >
            Refused
          </TextNormal>
        </Box>
      </Box>
    )
  }

  const getLoanApplicationStatus = (loanApplication) => {
    if (loanApplication.currentWorkflowState === 'new') {
      return 'Loan calculation saved'
    }
    if (loanApplication.currentWorkflowState === 'application_preparation') {
      return 'Customer data saved'
    }
    if (loanApplication.currentWorkflowState === 'approval_process_started') {
      if (
        loanApplication.loanDeals.filter((item) => item.currentWorkflowState === 'bank_sent')
          .length === loanApplication.loanDeals.length
      ) {
        return 'Awaiting bank replies'
      }
      if (loanApplication.loanDeals.filter((item) => item.isCustomerSelected)[0]) {
        return 'Financed'
      }
      if (loanApplication.loanDeals.filter((item) => item.isDealerSelected)[0]) {
        return "Awaiting customer's approval"
      }
      if (
        loanApplication.loanDeals.filter(
          (item) =>
            item.currentWorkflowState === 'approved' ||
            item.currentWorkflowState === 'bank_request_info' ||
            item.currentWorkflowState === 'bank_declined',
        )[0]
      ) {
        return 'Bank replies received'
      }
    }
    return loanApplication.currentWorkflowState
  }

  const handleSetLoanApplications = () => {
    let filteredData = data
    if (searchLine) {
      filteredData = data.filter((item) => {
        if (
          item.customer.firstName.toString().toLowerCase().includes(searchLine.toLowerCase()) ||
          item.customer.lastName.toString().toLowerCase().includes(searchLine.toLowerCase()) ||
          item.car.make.name.toString().toLowerCase().includes(searchLine.toLowerCase()) ||
          item.car.model.name.toString().toLowerCase().includes(searchLine.toLowerCase()) ||
          item.car.vin.toString().toLowerCase().includes(searchLine.toLowerCase()) ||
          item.id.toString().includes(searchLine)
        )
          return true
        return false
      })
    }
    setLoanApplications(
      filteredData.map((item) => {
        return {
          id: item.id,
          link: `/cars/${item.car.id}?service=Finance`,
          cells: [
            {
              type: 'text',
              value: item.id,
            },
            {
              type: 'text-with-caption',
              value: `${item.car.make.name} ${item.car.model.name}`,
              caption: `VIN ${item.car.vin}`,
            },
            {
              type: 'text',
              value:
                item?.customer?.firstName || item?.customer?.lastName
                  ? `${item?.customer?.firstName} ${item?.customer?.lastName}`
                  : `Customer #${item.customer.id}`,
            },
            {
              type: 'text',
              value: getLoanApplicationStatus(item),
            },
            {
              type: 'component',
              value: renderBankReplies(item.loanDeals),
            },
          ],
        }
      }),
    )
  }

  useEffect(() => {
    handleSetLoanApplications()
  }, [data, searchLine])

  return (
    <ModalWrap
      open={open}
      setOpen={setOpen}
      wrapperStyles={{
        width: 950,
        maxHeight: 'calc(100vh - 50px)',
        pt: '40px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: '30px',
        }}
      >
        <TextH1>{title}</TextH1>
        <Box>
          <TextField
            id="search"
            label={t('input.search')}
            size="small"
            value={searchLine}
            onChange={(e) => {
              setSearchLine(e.target.value)
            }}
            sx={{
              '& .MuiInputBase-input': {
                width: '205px',
                height: '38px',
                boxSizing: 'border-box',
                borderRadius: '6px',
                borderColor: colorsConfig.mainBlack,
                color: colorsConfig.mainBlack,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: '6px',
                borderColor: colorsConfig.mainBlack,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img src={iconSearch} alt="" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Box>
        <Table>
          <CarTableHead tableCells={tableHeadCellsFinancing} />
          <CarTableBody content={loanApplications} />
        </Table>
      </Box>
    </ModalWrap>
  )
}

export default StatusCakeFinanceModal
