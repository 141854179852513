import React, { useEffect } from 'react'
import { Table, Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import CarTableHead from '../../VirtualGarage/CarTableHead'
import CarTableBody from '../../VirtualGarage/CarTableBody'
import UITablePreloader from '../../../ui/UIPreloader/UITablePreloader'
import { getCoveredComponents } from '../../../store/features/coveredComponentsSlice/coveredComponents.actions'
import { coveredComponentsTableHeadCells } from '../../Warranty/warranty.constants'
import { getCoveredComponentsTableData } from '../../Warranty/warranty.helpers'
import { AddRounded } from '@mui/icons-material'
import { colorsConfig } from '../../../config/themeConfig'
import ButtonPlain from '../../../ui/Buttons/ButtonPlain'

const WarrantyCoveredComponents = () => {
  const navigate = useNavigate()
  const isLoading = useSelector((state) => state.coveredComponents.isLoading)
  const coveredComponents = useSelector((state) => state.coveredComponents.coveredComponents)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCoveredComponents())
    dispatch(setCurrentPageTitle('Warranty components'))
  }, [])

  return (
    <BoxShadowed>
      <Stack gap="30px">
        <Stack direction="row" justifyContent="flex-end">
          <ButtonPlain
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => {
              navigate('/admin/warranty/components/add')
            }}
          >
            <AddRounded
              sx={{
                fill: colorsConfig.mainBlue,
                mr: '4px',
                fontSize: '21px',
              }}
            />
            Add covered component
          </ButtonPlain>
        </Stack>
        <UITablePreloader loading={isLoading}>
          <Table>
            <CarTableHead tableCells={coveredComponentsTableHeadCells} />
            <CarTableBody content={getCoveredComponentsTableData(coveredComponents)} />
          </Table>
        </UITablePreloader>
      </Stack>
    </BoxShadowed>
  )
}

export default WarrantyCoveredComponents
