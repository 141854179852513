import companiesApiService from '../../../api/companiesApiService/companiesApiService'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { getApiError } from '../../../api/api'

export const getCompany = createAsyncThunk(
  'company/getCompany',
  async ({ companyId }, { rejectWithValue }) => {
    try {
      const response = await companiesApiService.getCompany(companyId)
      return response.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)

export const updateCompany = createAsyncThunk(
  'company/updateCompany',
  async ({ companyId, data }, { rejectWithValue }) => {
    try {
      const response = await companiesApiService.updateCompany(companyId, data)
      return response.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)
