import React from 'react'
import { Box, useMediaQuery, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import WarningLightItem from './WarningLightItem'
import TextBoldAccent from '../../../ui/Text/TextBoldAccent'
import { colorsConfig } from '../../../config/themeConfig'

const WarningLights = ({ readonly, hideGaps }) => {
  const pilotLamps = useSelector((state) => state.car.appraisal.pilotLamps)
  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <Box
      className={[xsOnly ? 'xs-only' : '', hideGaps ? 'hide-gaps' : ''].join(' ')}
      sx={{
        mt: '40px',
        px: '60px',
        py: '30px',
        boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
        borderRadius: '6px',
        '&.hide-gaps': {
          borderRadius: 0,
          boxShadow: 'none',
          mt: 0,
        },
        '&.xs-only': {
          px: '21px',
        },
      }}
    >
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          mb: '24px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: '104px',
          height: '20px',
          borderRadius: '6px',
          overflow: 'hidden',
          '&.xs-only': {
            mb: '39px',
          },
        }}
      >
        <TextBoldAccent
          variant="body1"
          sx={{
            height: '100%',
            width: '50%',
            background: colorsConfig.mainGreen,
            color: 'white',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {
            pilotLamps.filter(
              (item) => (item.value === 0 || item.value === null) && item.newValue !== 1,
            ).length
          }
        </TextBoldAccent>
        <Typography
          variant="body1"
          sx={{
            height: '100%',
            width: '50%',
            background: colorsConfig.mainRed,
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {pilotLamps.filter((item) => item.value === 1 || item.newValue === 1).length}
        </Typography>
      </Box>
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: {
            xs: 'flex-start',
            md: 'space-between',
          },
          alignItems: 'center',
          flexWrap: 'wrap',
          '&.xs-only': {
            position: 'relative',
            width: 'calc(100% + 15px)',
            height: 'calc(100% + 19px)',
            mt: '-19px',
          },
        }}
      >
        {pilotLamps.map((pilotLamp, index) => {
          return <WarningLightItem key={index} warningLight={pilotLamp} readonly={!!readonly} />
        })}
      </Box>
    </Box>
  )
}

export default WarningLights
