import React from 'react'
import MaskedInput from 'react-text-mask'
import { TextField } from '@mui/material'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'

const CheNumberInput = React.forwardRef(function CheNumberInput(props, ref) {
  const { ...other } = props

  const handleFocus = (event) => {
    const { value } = event.target
    const firstUnderscoreIndex = value.indexOf('_')

    if (firstUnderscoreIndex !== -1) {
      event.target.setSelectionRange(firstUnderscoreIndex, firstUnderscoreIndex)
    } else if (value === '') {
      event.target.setSelectionRange(2, 2)
    } else {
      event.target.setSelectionRange(value.length, value.length)
    }
  }

  return (
    <MaskedInput
      {...other}
      ref={ref}
      mask={['C', 'H', 'E', '-', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/]}
      placeholderChar={'_'}
      showMask
      onFocus={handleFocus}
    />
  )
})

const FormUIDMaskedInput = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers
  const { t } = useTranslation()

  const handleChange = (value) => {
    setValue(value)
  }

  return (
    <TextFieldSmallEdible
      fullWidth
      label={label}
      error={meta.error && meta.touched}
      helperText={meta.error && meta.touched ? meta.error : ''}
      variant="outlined"
      InputProps={{
        inputComponent: CheNumberInput,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      value={field.value}
      onChange={(e) => handleChange(e.target.value)}
      {...props}
    />
  )
}

export default FormUIDMaskedInput
