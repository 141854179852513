import { getActivePolicies } from '../rviDealer.actions'
import { apiConstants } from '../../../../api/api'

export default (builder) => {
  builder
    .addCase(getActivePolicies.pending, (state) => {
      state.activePolicies.isLoading = true
    })
    .addCase(getActivePolicies.fulfilled, (state, action) => {
      state.activePolicies.isLoading = false
      state.activePolicies.data = action.payload?.[apiConstants.hydraMember]
      state.activePolicies.total = action.payload?.[apiConstants.hydraTotal]
    })
    .addCase(getActivePolicies.rejected, (state, action) => {
      if (action.payload?.code !== 'ERR_CANCELED') {
        state.activePolicies.isLoading = false
      }
    })
}
