import { createAsyncThunk } from '@reduxjs/toolkit'
import carApiService from '../../../api/carService/carApiService'
import { apiConstants } from '../../../api/api'
import filesApiService from '../../../api/filesService/filesApiService'
import { fileTypeEnums } from '../../../utils/constants/enums.constants'

export const getInitialMakeAndModel = createAsyncThunk(
  'rviCar/getInitialMakeAndModel',
  async ({ makeName, modelName }, { rejectWithValue }) => {
    try {
      const makesParams = {
        pagination: false,
        name: makeName,
      }
      const makesRes = await carApiService.getMakes(makesParams)
      const makes = makesRes.data?.[apiConstants.hydraMember]
      const matchMake = makes.find((item) => item?.name?.toLowerCase() === makeName.toLowerCase())
      if (matchMake) {
        const modelsParams = {
          pagination: false,
          make: matchMake?.['@id'],
        }
        const modelsRes = await carApiService.getModels(modelsParams)
        const models = modelsRes.data?.[apiConstants.hydraMember]
        const matchModel = models.find(
          (item) => item?.name?.toLowerCase() === modelName.toLowerCase(),
        )
        return {
          make: matchMake,
          model: matchModel,
        }
      }
      return {
        make: matchMake,
        model: null,
      }
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e)
    }
  },
)

export const createCar = createAsyncThunk(
  'rviCar/createCar',
  async ({ carData, companyData }, { rejectWithValue }) => {
    try {
      const response = await carApiService.createCar(carData)
      await carApiService.changeWorkflowConfirmToDraft(response.data?.id, companyData)
      return response.data
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e)
    }
  },
)

export const updateCar = createAsyncThunk(
  'rviCar/updateCar',
  async (_, { rejectWithValue, getState }) => {
    try {
      const car = getState().rviCar.createdCarData?.['@id']
      if (!car) return
      const carMainData = getState().rviCar.carMainData
      const doorsNumber =
        carMainData.doorsNumber !== '' ? parseFloat(carMainData.doorsNumber.replace(/\D/g, '')) : 0
      let paintwork = ''
      if (carMainData.paintwork.length > 0) {
        for (let i = 0; i < carMainData.paintwork.length; i++) {
          paintwork += carMainData.paintwork[i]
          if (i !== carMainData.paintwork.length - 1) {
            paintwork += '_'
          }
        }
      }
      const carData = {
        ...carMainData,
        firstRegistrationDate: carMainData?.firstRegistrationDate,
        engineType: carMainData.engineType?.systemName,
        mileage: carMainData.mileage !== '' ? parseFloat(carMainData.mileage) : 0,
        hp: carMainData.hp ? parseFloat(carMainData.hp) : null,
        gearsNumber: carMainData.gearsNumber ? parseFloat(carMainData.gearsNumber) : 0,
        seatsNumber: carMainData.seatsNumber ? parseFloat(carMainData.seatsNumber) : 0,
        doorsNumber: doorsNumber,
        weight: carMainData.weight ? parseFloat(carMainData.weight) : 0,
        bookingValue: carMainData.bookingValue ? parseFloat(carMainData.bookingValue) : 0,
        newPriceWithOptions: carMainData.newPriceWithOptions
          ? parseFloat(carMainData.newPriceWithOptions)
          : 0,
        paintwork: paintwork,
        cylinder: carMainData.cylinder ? carMainData.cylinder.toString() : '0',
        CO2Emission: carMainData.CO2Emission ? parseFloat(carMainData.CO2Emission) : 0,
        fuelConsumption: carMainData.fuelConsumption ? carMainData.fuelConsumption.toString() : '0',
        energyEfficiencyCategory: carMainData.energyEfficiencyCategory,
        euroNorm: carMainData.euroNorm,
        carHiddenPriceShow: carMainData.carHiddenPriceShow,
        origin: carMainData.origin?.systemName,
        bodyType: carMainData.bodyType?.systemName,
        gearbox: carMainData.gearbox?.systemName,
        driveType: carMainData.driveType?.systemName,
        rims: carMainData.rims?.systemName,
      }
      const response = await carApiService.updateCar(car, carData)
      return response.data
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e)
    }
  },
)

export const attachAppraisalDocument = createAsyncThunk(
  'rviCar/attachAppraisalDocument',
  async ({ file }, { rejectWithValue, getState }) => {
    try {
      const carId = getState().rviCar.createdCarData?.id
      const fileRes = await filesApiService.createFile(file, fileTypeEnums.default)
      const response = await carApiService.attachAppraisalDocument(carId, {
        appraisalDocument: fileRes.data?.['@id'],
      })
      return response.data
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e)
    }
  },
)
