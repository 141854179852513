import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import ImageMapper from 'react-img-mapper'
import AddCarDamage from './AddCarDamage'
import { addDamage } from '../../../store/features/carSlice/carSlice'
import { useDispatch, useSelector } from 'react-redux'
import { privateRequest } from '../../../utils/request'
import { useParams } from 'react-router-dom'
import 'photoswipe/dist/photoswipe.css'
import { colorsConfig } from '../../../config/themeConfig'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import CarDamageItem from './CarDamageItem'
import { generateUID } from '../../../utils/generateUID'
import { useTranslation } from 'react-i18next'

const CarDamagesZone = ({ zone, carDummy, imgWidth, imgHeight, carPartsCoords }) => {
  const { t } = useTranslation()
  const carParts = useSelector((state) => state.references.carParts)
  const carDamages = useSelector((state) => state.car.appraisal.damages)
  const dispatch = useDispatch()

  const params = useParams()

  const mapperRef = useRef(null)

  const [mapHover, setMapHover] = useState(false)
  const [currentArea, setCurrentArea] = useState(t('common_not_selected'))
  const [currentCarPart, setCurrentCarPart] = useState('')
  const [currentWindowWidth, setCurrentWindowWidth] = useState(504)
  const [parentHeight, setParentHeight] = useState(0)
  const [zoom, setZoom] = useState(0)
  const [transform, setTransform] = useState(1)
  const [mapperKey, setMapperKey] = useState(generateUID())

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const handleMapHover = (area) => {
    setMapHover(true)
    setCurrentArea(carParts?.find((item) => item?.id === area?.carPartId)?.name ?? '-')
  }

  const handleMapBlur = () => {
    setMapHover(false)
    setCurrentArea(t('common_not_selected'))
  }

  const handleSelectArea = (area) => {
    const currentCarPart = carParts?.find((carPart) => carPart?.id === area?.carPartId)
    if (currentCarPart) {
      setCurrentCarPart(currentCarPart)
    } else {
      setCurrentCarPart(t('not_found'))
    }
  }

  const handleClear = () => {
    mapperRef.current.clearHighlightedArea()
  }

  const handleDeleteDamage = async (damageIndex) => {
    const newDamages = carDamages.filter((item, index) => index !== damageIndex)
    dispatch(addDamage(newDamages))
    const deletedDamages = JSON.parse(window.localStorage.getItem('deletedDamages'))
    if (deletedDamages && deletedDamages.length > 0) {
      deletedDamages.push({
        id: carDamages[damageIndex].id,
        carId: params.id,
      })
      window.localStorage.setItem('deletedDamages', JSON.stringify(deletedDamages))
    } else {
      window.localStorage.setItem(
        'deletedDamages',
        JSON.stringify([
          {
            id: carDamages[damageIndex].id,
            carId: params.id,
          },
        ]),
      )
    }
    privateRequest.delete(`/cars/${params.id}/damages/${carDamages[damageIndex].id}`).then(() => {
      const oldDeletedDamages = JSON.parse(window.localStorage.getItem('deletedDamages'))
      if (oldDeletedDamages && oldDeletedDamages.length > 0) {
        window.localStorage.setItem(
          'deletedDamages',
          JSON.stringify(
            oldDeletedDamages.filter((item) => item.id !== carDamages[damageIndex].id),
          ),
        )
      }
    })
  }

  const setImgMapperDimensions = () => {
    if (window.innerWidth < 1200) {
      setCurrentWindowWidth(window.innerWidth - 74)
      setParentHeight(((window.innerWidth - 74) * imgHeight) / imgWidth)
      return null
    }
    if (window.innerWidth > 600) {
      if (imgWidth > document.getElementById('img-mapper-outer').clientWidth) {
        setCurrentWindowWidth(document.getElementById('img-mapper-outer').clientWidth)
        setParentHeight(imgHeight)
      } else {
        setCurrentWindowWidth(imgWidth)
        setParentHeight(imgHeight)
      }
    }
  }

  useEffect(() => {
    setImgMapperDimensions()
    window.addEventListener('resize', setImgMapperDimensions)
    return () => {
      window.removeEventListener('click', setImgMapperDimensions)
    }
  }, [])

  useEffect(() => {
    setTransform(1 + zoom / 50)
  }, [zoom])

  return (
    <Box
      sx={{
        maxWidth: '100%',
        overflow: 'hidden',
      }}
    >
      <Typography
        className={xsOnly ? 'xs-only' : ''}
        variant="body1"
        sx={{
          fontSize: '16px',
          fontFamily: '"Helvetica", sans-serif',
          fontWeight: 400,
          color: colorsConfig.mainBlack,
          mt: '19px',
          mb: '30px',
          '&.xs-only': {
            display: 'none',
          },
        }}
      >
        {t('p_damage_location')}: <strong style={{ fontWeight: 700 }}>{currentArea}</strong>
      </Typography>
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          overflow: xsOnly ? 'auto' : 'hidden',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          mb: 2,
          alignItems: 'flex-start',
          width: xsOnly ? currentWindowWidth : '100%',
          height: xsOnly ? parentHeight : 'auto',
          position: 'relative',
          transformOrigin: '50% 0%',
          '&.xs-only': {
            mt: '50px',
            mb: '20px',
          },
        }}
        id="img-mapper-outer"
      >
        <ImageMapper
          key={mapperKey}
          containerRef={mapperRef}
          src={carDummy}
          map={{
            name: zone,
            areas: carPartsCoords.map((area) => {
              if (currentWindowWidth === imgWidth || !(xsOnly || tabletOnly)) {
                const newAreas = area.coords.map((coordinate) => 0.7 * coordinate)
                return {
                  ...area,
                  coords: newAreas,
                }
              }
              const newAreas = area.coords.map(
                (coordinate) => ((currentWindowWidth * transform) / imgWidth) * coordinate,
              )
              return {
                ...area,
                coords: newAreas,
              }
            }),
          }}
          lineWidth={3}
          width={xsOnly || tabletOnly ? currentWindowWidth * transform : imgWidth * 0.7}
          height={xsOnly || tabletOnly ? parentHeight * transform : imgHeight * 0.7}
          rerenderProps={[currentWindowWidth, parentHeight, transform]}
          onMouseEnter={(area) => {
            handleMapHover(area)
          }}
          onMouseLeave={() => {
            handleMapBlur()
          }}
          onClick={(area) => {
            handleSelectArea(area)
          }}
          stayHighlighted={!(tabletOnly || xsOnly)}
        />
      </Box>
      <Box>
        <Box
          className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            mb: '24px',
            '&.xs-only': {
              display: 'none',
            },
            '&.tablet-only': {
              display: 'none',
            },
          }}
        >
          <TextBoldNormal
            sx={{
              width: '23%',
              paddingLeft: '17px',
            }}
          >
            {t('form_input_label_car_part')}
          </TextBoldNormal>
          <TextBoldNormal
            sx={{
              width: '21%',
              paddingLeft: '17px',
            }}
          >
            {t('form_input_label_damage_type')}
          </TextBoldNormal>
          <TextBoldNormal
            sx={{
              width: '56%',
              paddingLeft: '17px',
            }}
          >
            {t('common_photos')}
          </TextBoldNormal>
        </Box>
      </Box>
      <AddCarDamage
        carPart={currentCarPart}
        clearArea={handleClear}
        zone={zone}
        setCarPart={setCurrentCarPart}
        setZoom={setZoom}
        carParts={carParts}
        setImgDimensions={setImgMapperDimensions}
        setMapperKey={setMapperKey}
      />
      {currentCarPart === t('common_not_found') ? (
        <Typography>{t('p_selected_car_part_not_found')}</Typography>
      ) : null}
      {carDamages && carDamages.length !== 0 ? (
        <Box
          sx={{
            mt: '12px',
          }}
        >
          {carDamages.map((carDamage, index) => {
            if (!carDamage.deleted) {
              return (
                <CarDamageItem
                  key={index}
                  index={index}
                  carDamage={carDamage}
                  handleDeleteDamage={handleDeleteDamage}
                  carParts={carParts}
                />
              )
            }
          })}
        </Box>
      ) : null}
    </Box>
  )
}

export default CarDamagesZone
