import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { setApprovedData, updateDeal } from '../../../../store/features/insuranceOperatorSlice'
import TextAreaSmall from '../../../../ui/TextAreaSmall'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import { privateRequest } from '../../../../utils/request'
import { API_ENDPOINTS } from '../../../../config/default'

const InsuranceRequestFormRefused = () => {
  const application = useSelector((state) => state.insuranceOperator.application)
  const selectedTab = useSelector((state) => state.insuranceOperator.selectedTab)
  const deal = useSelector((state) => state.insuranceOperator.deals[selectedTab])
  const approvedData = useSelector(
    (state) => state.insuranceOperator.deals[selectedTab].approvedData,
  )
  const dispatch = useDispatch()

  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleSubmit = () => {
    setButtonDisabled(true)
    const formData = {
      comments: [
        {
          comment: approvedData?.comment,
        },
      ],
    }
    privateRequest
      .put(`${API_ENDPOINTS.insurance.deals}/${deal?.id}`, formData)
      .then((response) => {
        privateRequest
          .post(API_ENDPOINTS.insurance.dealWorkflows.declined(application?.id, deal?.id), {})
          .then(() => {
            dispatch(
              updateDeal({
                index: selectedTab,
                data: {
                  ...deal,
                  currentWorkflowState: 'insurance_company_declined',
                },
              }),
            )
          })
          .finally(() => setButtonDisabled(false))
      })
      .catch((e) => {
        setButtonDisabled(false)
      })
  }

  return (
    <Box sx={{ mt: '30px' }}>
      <Grid item md={6} xs={12}>
        <TextAreaSmall
          fullWidth
          label={'Rejection reason'}
          placeholder={'Enter rejection reason'}
          minRows={5}
          multiline
          value={approvedData?.comment}
          onChange={(e) => {
            dispatch(
              setApprovedData({
                ...approvedData,
                comment: e.target.value,
              }),
            )
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '30px' }}>
          <ButtonLG disabled={buttonDisabled} onClick={() => handleSubmit()}>
            Send insurance response
          </ButtonLG>
        </Box>
      </Grid>
    </Box>
  )
}

export default InsuranceRequestFormRefused
