import { zippopotam } from '../api'

class ZipCodeApiService {
  #api

  #endPoints

  #abortControllers

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {}
    this.#abortControllers = {}
  }

  getGeoCodingByZipCode = async (countryCode, zipCode, prefix = 'getGeoCodingByZipCode') => {
    this.#abortControllers[prefix]?.abort()
    this.#abortControllers[prefix] = new AbortController()
    const response = await this.#api.get(`/${countryCode}/${zipCode}`, {
      signal: this.#abortControllers[prefix]?.signal,
    })
    return response
  }
}

const zipCodeApiService = new ZipCodeApiService({ api: zippopotam })

export default zipCodeApiService
