import React from 'react'
import { Box, CircularProgress, IconButton, Modal, Typography } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import TextNormal from '../../../ui/Text/TextNormal'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import IconCoin from '../../../assests/img/icons/icon-fleetify-coin.svg'
import ButtonAttention from '../../../ui/Buttons/ButtonAttention'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CloseRounded } from '@mui/icons-material'

const VINAutofillModal = ({ isVINAutofillModalOpen, closeVINAutofillModal, handleSubmit }) => {
  const { isMaxWidth600, isMaxWidth960 } = useBreakpoints()
  const { t } = useTranslation()

  const coinPricings = useSelector((state) => state.auth.coinPricings)
  const isLoading = useSelector((state) => state.addCar.vinProvider.isLoading)

  return (
    <Modal open={isVINAutofillModalOpen} onClose={closeVINAutofillModal}>
      <Box
        sx={{
          position: 'absolute',
          width: isMaxWidth600 ? '340px' : isMaxWidth960 ? '540px' : '688px',
          height: 'fit-content',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '30px',
          paddingBottom: '37px',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontFamily: '"Helvetica", sans-serif',
            fontSize: isMaxWidth600 ? '24px' : '40px',
            color: colorsConfig.mainBlack,
            lineHeight: 'normal',
            mb: '10px',
          }}
        >
          {t('modal_title_autofill_car_info')}
        </Typography>
        <TextNormal
          sx={{
            fontSize: isMaxWidth600 && '14px',
            mb: '20px',
          }}
        >
          {t('modal_p_autofill_car_info')}
        </TextNormal>
        {isLoading ? (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '1000',
              width: '100%',
              height: '100%',
              background: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
              gap: '10px',
              color: '#007DFF',

              '& .MuiCircularProgress-circle': {
                stroke: '#007DFF',
              },
            }}
          >
            <CircularProgress size={130} thickness={6} />
            <Typography>Loading</Typography>
          </Box>
        ) : null}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: '9px',
            borderBottom: '1px solid',
            borderColor: colorsConfig.lightGray,
            mb: '40px',
          }}
        >
          <TextBoldNormal sx={{ textTransform: 'uppercase' }}>{t('common_total')}</TextBoldNormal>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src={IconCoin} alt="" style={{ paddingBottom: '2px' }} />
            <TextBoldNormal sx={{ ml: '5px' }}>
              {coinPricings.filter((item) => item.identifier === 'vin_provider_request')[0]?.amount}
            </TextBoldNormal>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMaxWidth600 ? 'column' : 'row',
            gap: '10px',
          }}
        >
          <ButtonAttention
            variant="outlined"
            fullWidth
            onClick={closeVINAutofillModal}
            sx={{
              background: 'white',
              borderColor: colorsConfig.buttonYellow,

              '&:hover': {
                borderColor: colorsConfig.buttonYellow,
              },

              order: isMaxWidth600 ? 1 : -1,
            }}
          >
            {t('button_fill_in_manually')}
          </ButtonAttention>
          <ButtonAttention fullWidth onClick={handleSubmit}>
            {t('button_autofill')}
          </ButtonAttention>
        </Box>
        <IconButton
          onClick={closeVINAutofillModal}
          sx={{ position: 'absolute', top: '8px', right: '8px' }}
        >
          <CloseRounded sx={{ fill: colorsConfig.iconGray }} />
        </IconButton>
      </Box>
    </Modal>
  )
}

export default VINAutofillModal
