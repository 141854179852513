import { createAsyncThunk } from '@reduxjs/toolkit'
import controlPointsApiService from '../../../api/appraisalService/controlPointsApiService'
import { initialApplicationData as response } from '../../../pages/Insurance/insurance.constants'
import carService from '../../../api/carService/carApiService'
import { toast } from 'react-toastify'
import { getApiError } from '../../../api/api'

export const updateControlPoints = createAsyncThunk(
  'car/updateControlPoints',
  async ({ data }, { rejectWithValue, getState }) => {
    try {
      const carId = getState().car.carData.id
      await controlPointsApiService.updateControlPoints(carId, data)
      return data
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e.response.data)
    }
  },
)

export const getCarDetailsFromVIN = createAsyncThunk(
  'car/getVinProviderData',
  async ({ vin }, { rejectWithValue }) => {
    try {
      const response = await carService.getCarDetailsFromVIN({ vin })
      if (response.data?.responseStatus === 'missing_fields') {
        throw new Error('missing_fields')
      }
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const updateCar = createAsyncThunk(
  'car/updateCar',
  async ({ carIdUrl, data }, { rejectWithValue }) => {
    try {
      const response = await carService.updateCar(carIdUrl, data)
      return response.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)
