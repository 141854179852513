import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import { useDispatch } from 'react-redux'
import { privateRequest } from '../../../utils/request'
import { API_ENDPOINTS, currentLocales } from '../../../config/default'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import AdminPanelSubblock from './AdminPanelSubblock'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import controlPointsApiService from '../../../api/appraisalService/controlPointsApiService'
import { apiConstants } from '../../../api/api'

const AdminPanelMechanicalConditions = () => {
  const dispatch = useDispatch()

  const [subblocks, setSubblocks] = useState([])

  const handleSetSubblocks = (data) => {
    setSubblocks(
      data.map((subblock) => {
        // const newControlPoints = subblock.controlPoints.map((item) => {
        //   return {
        //     ...item,
        //     translations: currentLocales.map((locale) => {
        //       return {
        //         ...locale,
        //         value:
        //           item.translations.filter(
        //             (translation) => translation.locale === locale.systemName,
        //           )[0]?.value || ' ',
        //       }
        //     }),
        //   }
        // })
        return {
          ...subblock,
          //   controlPoints: newControlPoints,
          translations: currentLocales.map((locale) => {
            return {
              ...locale,
              value:
                subblock.translations.filter(
                  (translation) => translation.locale === locale.systemName,
                )[0]?.value || ' ',
            }
          }),
        }
      }),
    )
  }

  const getMechanicalConditions = async () => {
    try {
      const response = await controlPointsApiService.getControlPoints()
      handleSetSubblocks(response.data[apiConstants.hydraMember])
    } catch (e) {
      console.log(e)
    } finally {
      //
    }
  }

  useEffect(() => {
    getMechanicalConditions()
    dispatch(setCurrentPageTitle('Mechanical conditions'))
  }, [])

  return (
    <Box>
      <BoxShadowed
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <TextBoldNormal
          sx={{
            fontSize: '20px',
            textAlign: 'center',
            my: '10px',
          }}
        >
          Visual Check
        </TextBoldNormal>
        {subblocks.map((item, index) => {
          if (index < 7) {
            return (
              <AdminPanelSubblock key={index} subblock={item} refresh={getMechanicalConditions} />
            )
          }
          return null
        })}
        <TextBoldNormal
          sx={{
            fontSize: '20px',
            textAlign: 'center',
            my: '10px',
          }}
        >
          Test Drive
        </TextBoldNormal>
        {subblocks.map((item, index) => {
          if (index >= 7) {
            return (
              <AdminPanelSubblock key={index} subblock={item} refresh={getMechanicalConditions} />
            )
          }
          return null
        })}
      </BoxShadowed>
    </Box>
  )
}

export default AdminPanelMechanicalConditions
