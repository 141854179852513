import { createSlice } from '@reduxjs/toolkit'
import getAllCompaniesBuilder from './builders/getAllCompanies.builder'
import getCompaniesBuilder from './builders/getCompanies.builder'
import createCompanyBuilder from './builders/createCompany.builder'

const initialState = {
  companies: {
    isLoading: false,
    data: [],
    total: 0,
    params: {
      page: 1,
      itemsPerPage: 10,
      'order[createdAt]': 'desc',
    },
  },
  allCompanies: {
    isLoading: false,
    data: [],
  },
  create: {
    isLoading: false,
    error: null,
  },
}

const companiesSlice = createSlice({
  name: 'companiesReducer',
  initialState,
  reducers: {
    setCompaniesParams: (state, action) => {
      state.companies.params = action.payload
    },
  },
  extraReducers: (builder) => {
    getCompaniesBuilder(builder)
    getAllCompaniesBuilder(builder)
    createCompanyBuilder(builder)
  },
})

export const { setCompaniesParams } = companiesSlice.actions

export default companiesSlice.reducer
