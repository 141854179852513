import React from 'react'
import { Box } from '@mui/material'
import GreenPageHeader from './GreenPageHeader'
import GreenPageBanner from './GreenPageBanner'
import GreenPagePartners from './GreenPagePartners'
import GreenPageContent from './GreenPageContent'
import GreenPageServices from './GreenPageServices'
import Footer2 from '../../components/Footer2'

const GreenPage = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        pt: '84px',
      }}
    >
      <GreenPageHeader />
      <GreenPageBanner />
      <GreenPagePartners />
      <GreenPageContent />
      <GreenPageServices />
      <Footer2 />
    </Box>
  )
}

export default GreenPage
