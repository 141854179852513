import React from 'react'
import { Stack } from '@mui/material'
import UIBreadcrumbs from '../../../ui/UIBreadcrumbs/UIBreadcrumbs'
import { AddRounded, BusinessRounded } from '@mui/icons-material'
import AddCompanyForm from '../../../components/Forms/FormTemplates/AddCompanyForm/AddCompanyForm'

const AddCompany = () => {
  return (
    <Stack gap="30px">
      <UIBreadcrumbs
        pages={[
          {
            name: 'Companies',
            icon: <BusinessRounded />,
            link: '/admin/companies',
          },
          {
            name: 'Add a new company',
            icon: <AddRounded />,
          },
        ]}
      />
      <AddCompanyForm />
    </Stack>
  )
}

export default AddCompany
