import React from 'react'
import { Box, IconButton, Modal } from '@mui/material'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import TextNormal from '../../ui/Text/TextNormal'
import IconCoin from '../../assests/img/icons/icon-fleetify-coin.svg'
import { colorsConfig } from '../../config/themeConfig'
import ButtonAttention from '../../ui/Buttons/ButtonAttention'
import TextH1 from '../../ui/Text/TextH1'
import { Close } from '@mui/icons-material'
import ButtonHollow from '../../ui/Buttons/ButtonHollow'
import ButtonDanger from '../../ui/Buttons/ButtonDanger'
import ButtonSuccess from '../../ui/Buttons/ButtonSuccess'

const ConfirmWithdrawalModal = (props) => {
  const { open, setOpen, handleSubmit, companyName, amount } = props

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 650,
          background: 'white',
          borderRadius: '10px',
          padding: '30px',
          maxHeight: '80vh',
          overflow: 'auto',
        }}
        className={'disable-scrollbar'}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          <Close
            style={{
              fill: colorsConfig.mainRed,
            }}
          />
        </IconButton>
        <TextH1
          sx={{
            mb: '16px',
            fontSize: '30px',
            lineHeight: 'normal',
            textAlign: 'center',
          }}
        >
          Withdrawal confirmation
        </TextH1>
        <TextNormal
          sx={{
            mb: '30px',
            fontSize: '22px',
            lineHeight: 'normal',
            textAlign: 'center',
          }}
        >
          Are you sure you want to withdraw <br />
          <span style={{ fontWeight: 700 }}>{amount} Fleetify coins</span> from{' '}
          <span style={{ fontWeight: 700 }}>{companyName}</span>?
        </TextNormal>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ButtonDanger
            sx={{
              width: 'calc(50% - 4px)',
            }}
            onClick={() => {
              setOpen(false)
            }}
          >
            Cancel
          </ButtonDanger>
          <ButtonSuccess
            sx={{
              width: 'calc(50% - 4px)',
            }}
            onClick={() => {
              handleSubmit()
            }}
          >
            Confirm
          </ButtonSuccess>
        </Box>
      </Box>
    </Modal>
  )
}

export default ConfirmWithdrawalModal
