import React, { useState } from 'react'
import { Stack } from '@mui/material'
import TextFieldSmallEdible from '../ui/TextFieldSmallEdible'
import ButtonSuccess from '../ui/Buttons/ButtonSuccess'

const TestMessageWa = () => {
  const [message, setMessage] = useState('')

  return (
    <Stack
      spacing={'20px'}
      sx={{
        width: '600px',
        maxWidth: 'calc(100vw - 30px)',
        mx: 'auto',
        my: '100px',
      }}
    >
      <TextFieldSmallEdible
        fullWidth
        label={'Your message'}
        value={message}
        onChange={(e) => {
          setMessage(e.target.value)
        }}
        multiline
        rows={4}
      />
      <ButtonSuccess
        fullWidth
        component={'a'}
        target={'_blank'}
        href={`https://wa.me/971509601872?text=${encodeURIComponent(message)}`}
      >
        Send via WhatsApp
      </ButtonSuccess>
    </Stack>
  )
}

export default TestMessageWa
