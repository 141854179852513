import React from 'react'
import { colorsConfig } from '../../../config/themeConfig'
import { Box, TextField } from '@mui/material'

const RVMatrixCell = ({ value, onChange, wrapperSx, ...props }) => {
  return (
    <Box
      sx={{
        borderRight: '1px solid',
        borderColor: colorsConfig.buttonDisabled,
        '&:last-child': {
          border: 'none',
        },
        ...wrapperSx,
      }}
    >
      <TextField
        value={value}
        sx={{
          height: '100%',
          width: '100%',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
        onChange={onChange}
        {...props}
      />
    </Box>
  )
}

export default RVMatrixCell
