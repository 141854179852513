import React, { useState, useEffect } from 'react'
import { Box, Typography, Paper } from '@mui/material'
import { useParams } from 'react-router-dom'
import IconStatusGreen from '../../../../assests/img/icons/icon-status-green.svg'
import IconStatusOrange from '../../../../assests/img/icons/icon-status-orange.svg'
import { privateRequest } from '../../../../utils/request'
import { API_FINANCE_ENDPOINTS } from '../../../../config/default'
import { colorsConfig } from '../../../../config/themeConfig'
import { useSelector } from 'react-redux'

const LoanDealLoggingNodes = () => {
  const loanDeals = useSelector((state) => state.finance.loanDeals)

  const [loggingNodes, setLoggingNodes] = useState([])

  const getLoanDealsNodes = () => {
    const loggingNodesRequests = loanDeals.map((item) =>
      privateRequest.get(API_FINANCE_ENDPOINTS.loanDealLoggingNodes(item.id)),
    )
    Promise.all(loggingNodesRequests).then((result) => {
      console.log(result)
      let newLoggingNodes = []
      result.forEach((item) => {
        newLoggingNodes = [...newLoggingNodes, ...item.data['hydra:member']]
      })
      setLoggingNodes(
        newLoggingNodes.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt)
        }),
      )
    })
  }

  useEffect(() => {
    if (loanDeals.length > 0) {
      getLoanDealsNodes()
    }
  }, [loanDeals])

  return (
    <Box>
      <Typography
        variant="body1"
        sx={{
          fontWeight: 700,
          mb: '60px',
          fontSize: '40px',
          color: colorsConfig.mainBlack,
        }}
      >
        Logging Tree
      </Typography>

      <Box>
        {loggingNodes.map((log, index) => {
          const performer = log.actionPerformer
          const time = new Date(log.createdAt).toLocaleTimeString()
          const weekDay = new Date(log.createdAt).toLocaleDateString('en-US', { weekday: 'long' })
          const date = new Date(log.createdAt).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                mb: 2,
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: '45%',
                  top: '-2px',
                  width: '26px',
                  height: '26px',
                }}
              >
                <img src={performer === 'dealer' ? IconStatusGreen : IconStatusOrange} alt="" />
              </Box>
              {index === loggingNodes.length - 1 ? null : (
                <Box
                  sx={{
                    position: 'absolute',
                    left: '45%',
                    top: '32px',
                    width: '1px',
                    height: 'calc(100% - 23px)',
                    background: colorsConfig.mainBlack,
                    marginLeft: '12px',
                  }}
                />
              )}

              <Box
                elevation={1}
                sx={{
                  width: 'calc(50% - 25px)',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: colorsConfig.iconGray,
                    textAlign: 'left',
                  }}
                >
                  {weekDay}, {date} at {time}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: 'calc(50% - 25px)',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '16px',
                    textAlign: 'left',
                    color: colorsConfig.mainBlack,
                  }}
                >
                  {log.actionDescription}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '16px',
                    color: colorsConfig.iconGray,
                    textAlign: 'left',
                  }}
                >
                  {log.actionPerformer}
                </Typography>
              </Box>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default LoanDealLoggingNodes
