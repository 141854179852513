import React from 'react'
import './Preloader.scss'

const Preloader = (props) => {
  const { type, text, preloaderStyles } = props

  return (
    <div
      className={['preloader-container', type === 'spinner1' ? 'spinner-1' : ''].join(' ')}
      style={preloaderStyles ? preloaderStyles : null}
    >
      {text ? <div className="preloader-text">{text}</div> : null}
      {type === 'gooey' ? (
        <div className="gooey">
          <span className="dot" />
          <div className="dots">
            <span />
            <span />
            <span />
          </div>
        </div>
      ) : null}
      {type === 'spinner1' ? <span className="loader" /> : null}
    </div>
  )
}

export default Preloader
