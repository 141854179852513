import { getCompanyAddresses } from '../settings.actions'
import { API_ERROR_CODES } from '../../../../api/APIErrors'
import { apiConstants } from '../../../../api/api'

export default (builder) => {
  builder
    .addCase(getCompanyAddresses.pending, (state) => {
      state.companyAddresses.isLoading = true
    })
    .addCase(getCompanyAddresses.fulfilled, (state, action) => {
      state.companyAddresses.isLoading = false
      state.companyAddresses.error = null
      console.log({ builder: action.payload })
      state.companyAddresses.data = action.payload?.[apiConstants.hydraMember]
      state.companyAddresses.total = action.payload?.[apiConstants.hydraTotal]
    })
    .addCase(getCompanyAddresses.rejected, (state, action) => {
      if (action.payload?.code === API_ERROR_CODES.ERR_CANCELED) {
        state.companyAddresses.isLoading = false
        state.companyAddresses.error = action.payload
      }
    })
}
