import React from 'react'
import { Box, useMediaQuery, Typography } from '@mui/material'
import SubBlock from './SubBlock'
import { useSelector } from 'react-redux'
import { colorsConfig } from '../../../config/themeConfig'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'

const AppraisalBlock = (props) => {
  const { blockIndex, readonly } = props

  const subblocks = useSelector((state) => state.car.appraisal.controlPoints[blockIndex].subblocks)

  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <Box
      className={[
        xsOnly ? 'xs-only' : '',
        subblocks.length > 1 ? 'mechanical-conditions' : '',
      ].join(' ')}
      sx={{
        mt: '65px',
        borderRadius: '6px',
        padding: blockIndex === 0 ? null : '35px',
        boxShadow: blockIndex === 0 ? null : '0 0 24px 0 rgba(0, 0, 0, .1)',
        '&.xs-only': {
          px: '21px',
          py: '27px',
          mt: '30px',
          '&.mechanical-conditions': {
            p: '20px',
          },
        },
      }}
    >
      {subblocks.length === 1 ? (
        <SubBlock
          singleSubBlock={true}
          subblock={subblocks[0]}
          blockIndex={blockIndex}
          subBlockIndex={0}
          readonly={readonly ? readonly : false}
        />
      ) : null}

      {subblocks.length > 1 ? (
        <Box>
          <TextBoldNormal
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              fontSize: '18px',
              mb: '10px',
              textAlign: 'center',
              '&.xs-only': {
                textAlign: 'left',
                fontSize: '24px',
                mb: '15px',
                lineHeight: 'normal',
                fontWeight: 400,
              },
            }}
          >
            Visual Check
          </TextBoldNormal>
          {subblocks.map((subBlockItem, index) => {
            if (subBlockItem.controlPoints.length === 0) {
              return null
            }
            if (index < 7) {
              return (
                <SubBlock
                  key={index}
                  singleSubBlock={false}
                  subblock={subBlockItem}
                  blockIndex={blockIndex}
                  subBlockIndex={index}
                  readonly={readonly ? readonly : false}
                />
              )
            }
          })}
          <TextBoldNormal
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              fontSize: '18px',
              mb: '10px',
              textAlign: 'center',
              '&.xs-only': {
                textAlign: 'left',
                fontSize: '24px',
                mb: '15px',
                mt: '23px',
                lineHeight: 'normal',
                fontWeight: 400,
              },
            }}
          >
            Test Drive
          </TextBoldNormal>
          {subblocks.map((subBlockItem, index) => {
            if (index >= 7) {
              return (
                <SubBlock
                  key={index}
                  singleSubBlock={false}
                  subblock={subBlockItem}
                  blockIndex={blockIndex}
                  subBlockIndex={index}
                  readonly={readonly ? readonly : false}
                />
              )
            }
          })}
        </Box>
      ) : null}
    </Box>
  )
}

export default AppraisalBlock
