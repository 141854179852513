import React from 'react'
import TablePagination from '@mui/material/TablePagination'

const SimplePagination = (props) => {
  const { page, setPage, rowsPerPage, setRowsPerPage, count } = props

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <TablePagination
      component="div"
      count={count}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage={'Show entries:'}
    />
  )
}

export default SimplePagination
