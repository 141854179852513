import React from 'react'
import { Box } from '@mui/material'
import TextNormal from '../../../ui/Text/TextNormal'
import { colorsConfig } from '../../../config/themeConfig'
import EnergyEfficiencyCategoryItem from './EnergyEfficiencyCategoryItem'
import { useTranslation } from 'react-i18next'

const EnergyEfficiencyCategory = (props) => {
  const { value, onChange } = props

  const { t } = useTranslation()

  const categories = [
    {
      name: 'A+++',
      value: 5,
      color: '#4DED6D',
    },
    {
      name: 'A++',
      value: 10,
      color: '#4DED6D',
    },
    {
      name: 'A+',
      value: 20,
      color: '#4DED6D',
    },
    {
      name: 'A',
      value: 40,
      color: '#BDED4D',
    },
    {
      name: 'B',
      value: 80,
      color: '#BDED4D',
    },
    {
      name: 'C',
      value: 160,
      color: '#F5DC4F',
    },
    {
      name: 'D',
      value: 320,
      color: '#F5BD4F',
    },
    {
      name: 'E',
      value: 640,
      color: '#AC0000',
    },
    {
      name: 'F',
      value: 1280,
      color: '#AC0000',
    },
    {
      name: 'G',
      value: 2560,
      color: '#AC0000',
    },
  ]

  return (
    <Box
      sx={{
        borderRadius: '4px',
        pt: '11px',
        pb: '15px',
        px: '15px',
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.10)',
      }}
    >
      <TextNormal
        sx={{
          color: colorsConfig.iconGray,
          fontSize: '14px',
          mb: '8px',
        }}
      >
        {t('form_input_label_energy_efficiency_category')}
      </TextNormal>
      <TextNormal
        sx={{
          fontSize: '12px',
          color: colorsConfig.iconGray,
          mb: '12px',
        }}
      >
        {t('p_energy_efficiency_category_desc')}
      </TextNormal>
      <Box>
        {categories.map((item, index) => {
          return (
            <EnergyEfficiencyCategoryItem
              key={index}
              category={item}
              currentValue={value}
              onChange={onChange}
            />
          )
        })}
      </Box>
    </Box>
  )
}

export default EnergyEfficiencyCategory
