import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { FileUploader } from 'react-drag-drop-files'
import { useDispatch } from 'react-redux'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import * as XLSX from 'xlsx'

const Localization = () => {
  const dispatch = useDispatch()
  const handleSetFile = (file) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: 'array' })

        const localization = {
          en: { translation: {} },
          de: { translation: {} },
          fr: { translation: {} },
          it: { translation: {} },
        }

        workbook.SheetNames.forEach((sheetName) => {
          const worksheet = workbook.Sheets[sheetName]
          const jsonData = XLSX.utils.sheet_to_json(worksheet)

          jsonData.forEach((row) => {
            const { key, en, de, fr, it } = row
            if (!key || !en || !de || !fr || !it) {
              return
            }
            if (key) {
              localization.en.translation[key.trim()] = en.trim()
              localization.de.translation[key.trim()] = de.trim()
              localization.fr.translation[key.trim()] = fr.trim()
              localization.it.translation[key.trim()] = it.trim()
            }
          })
        })
        handleDownloadFile(localization)
      }
      reader.readAsArrayBuffer(file)
    }
  }

  const handleDownloadFile = (localization) => {
    const element = document.createElement('a')
    const file = new Blob([JSON.stringify(localization, null, 2)], {
      type: 'application/json',
    })
    element.href = URL.createObjectURL(file)
    element.download = 'localization.json'
    document.body.appendChild(element)
    element.click()
  }

  useEffect(() => {
    dispatch(setCurrentPageTitle('Update localization'))
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '50vh',
      }}
    >
      <FileUploader
        types={['xlsx']}
        hoverTitle="Drop here"
        label="Drag & Drop files here"
        classes="upload-picture"
        maxSize={10}
        onDrop={handleSetFile}
        onSelect={handleSetFile}
      />
    </Box>
  )
}

export default Localization
