import React from 'react'
import UITableActionsList from '../../../ui/UITable/UITableActionsList'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'

const ManagersActionItems = ({ data, handleActivate, handleResetPassword, handleDeactivate }) => {
  const navigate = useNavigate()
  console.log({ data })
  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <UITableActionsList
        actions={[
          {
            name: 'Edit manager',
            handleAction: () => navigate(`/admin/managers/${data?.initialData?.id}/edit`),
          },
          {
            name: data?.initialData?.user?.isBlocked ? 'Activate' : 'Deactivate',
            handleAction: () => {
              data?.initialData?.user?.isBlocked ? handleActivate() : handleDeactivate()
            },
          },
          {
            name: 'Reset password',
            handleAction: () => handleResetPassword(),
          },
        ]}
      />
    </Box>
  )
}

export default ManagersActionItems
