import companiesApiService from '../../../api/companiesApiService/companiesApiService'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { initialApplicationData as response } from '../../../pages/Insurance/insurance.constants'
import { toast } from 'react-toastify'
import filesApiService from '../../../api/filesService/filesApiService'
import { FILE_TYPES } from '../../../config/default'

export const getCompanies = createAsyncThunk(
  'companies/getCompanies',
  async (_, { rejectWithValue, getState }) => {
    try {
      const params = getState().companies.companies.params
      const response = await companiesApiService.getCompanies(params)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getAllCompanies = createAsyncThunk(
  'companies/getAllCompanies',
  async (_, { rejectWithValue }) => {
    try {
      const params = {
        pagination: false,
      }
      const response = await companiesApiService.getCompanies(params)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const createCompany = createAsyncThunk(
  'companies/createCompany',
  async ({ data }, { rejectWithValue }) => {
    try {
      const fileRes = await filesApiService.createFile(data?.tradeLicense, FILE_TYPES.tradeLicense)
      const formData = {
        ...data,
        tradeLicense: fileRes.data?.['@id'],
      }
      const response = await companiesApiService.createCompany(formData)
      return response.data
    } catch (e) {
      toast.error(e)
      return rejectWithValue(e)
    }
  },
)
