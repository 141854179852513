import { createSlice } from '@reduxjs/toolkit'
import calculateRVBuilder from './builders/calculateRV.builder'
import calculateTermsBuilder from './builders/calculateTerms.builder'
import calculateCustomPackageTermsBuilder from './builders/calculateCustomPackageTerms.builder'
import createLoanApplicationBuilder from './builders/createLoanApplication.builder'
import getCarLoanApplicationsBuilder from './builders/getCarLoanApplications.builder'
import saveLoanApplicationCustomerBuilder from './builders/saveLoanApplicationCustomer.builder'
import submitLoanApplicationToBanksBuilder from './builders/submitLoanApplicationToBanks.builder'
import { getLoanApplicationSectionsState } from '../../../utils/helpers/financing.helpers'

const initialState = {
  rvCalculation: {
    isLoading: false,
    error: null,
    data: null,
  },
  termsCalculation: {
    isLoading: false,
    error: null,
    isLoadingCustom: false,
    errorCustom: null,
    basic: [],
    full: [],
    custom: [],
    customPrice: 0,
  },
  loanApplications: {
    isLoading: false,
    error: null,
    data: [],
  },
  currentTab: 0,
  currentLoanApplication: {
    isLoading: false,
    error: null,
    data: null,
    sectionsState: {
      loanCalculator: {
        isOpen: true,
        isSaved: false,
        disabled: false,
        hidden: false,
      },
      customerDataForm: {
        isOpen: false,
        isSaved: false,
        disabled: true,
        hidden: false,
      },
      bankResponses: {
        isOpen: false,
        isSaved: false,
        disabled: true,
        hidden: true,
      },
    },
    create: {
      isLoading: false,
      error: null,
    },
    saveCustomerData: {
      isLoading: false,
      error: null,
    },
    sendToBanks: {
      isLoading: false,
      error: null,
    },
  },
}

const financingSlice = createSlice({
  name: 'financingReducer',
  initialState,
  reducers: {
    setCurrentLoanApplication: (state, action) => {
      state.currentLoanApplication.data = action.payload
      state.currentLoanApplication.sectionsState = getLoanApplicationSectionsState(
        action.payload?.currentWorkflowState,
      )
    },
    setCurrentLoanApplicationSectionsState: (state, action) => {
      const data = action.payload
      state.currentLoanApplication.sectionsState = {
        loanCalculator: {
          ...state.currentLoanApplication.sectionsState.loanCalculator,
          ...(data?.loanCalculator ?? {}),
        },
        customerDataForm: {
          ...state.currentLoanApplication.sectionsState.customerDataForm,
          ...(data?.customerDataForm ?? {}),
        },
        bankResponses: {
          ...state.currentLoanApplication.sectionsState.bankResponses,
          ...(data?.bankResponses ?? {}),
        },
      }
    },
    resetFinancingData: (state) => {
      state.currentLoanApplication = initialState.currentLoanApplication
      state.loanApplications = initialState.loanApplications
      state.currentTab = initialState.currentTab
      state.rvCalculation = initialState.rvCalculation
      state.termsCalculation = initialState.termsCalculation
    },
  },
  extraReducers: (builder) => {
    calculateRVBuilder(builder)
    calculateTermsBuilder(builder)
    calculateCustomPackageTermsBuilder(builder)
    createLoanApplicationBuilder(builder)
    getCarLoanApplicationsBuilder(builder)
    saveLoanApplicationCustomerBuilder(builder)
    submitLoanApplicationToBanksBuilder(builder)
  },
})

export const {
  setCurrentLoanApplicationSectionsState,
  setCurrentLoanApplication,
  resetFinancingData,
} = financingSlice.actions

export default financingSlice.reducer
