import zipCodeApiService from '../../api/zipcodeApiService/zipcodeApiService'

export const getCityByPostcode = async (countryCode, postcode, setValue) => {
  console.log({ countryCode, postcode })
  if (!countryCode || !postcode) {
    return
  }
  try {
    const response = await zipCodeApiService.getGeoCodingByZipCode(countryCode, postcode)
    const places = response.data?.places
    if (places?.[0]?.['place name']) {
      setValue(places?.[0]?.['place name'])
    }
  } catch (e) {
    console.error(e)
  }
}
