import React, { useEffect, useState } from 'react'
import CollapseBlock from '../../../../ui/Accordions/CollapseBlock'
import { useDispatch, useSelector } from 'react-redux'
import { Box, FormControl, FormControlLabel, Grid, RadioGroup, Stack } from '@mui/material'
import CustomRadio from '../../../../ui/CustomRadio'
import UITextRegular from '../../../../ui/UIText/UITextRegular'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import IconVinAutofill from '../../../../assests/img/icons/icon-car-autofilled.svg'
import UIButtonPlainGreen from '../../../../ui/UIButtons/UIButtonPlainGreen'
import FormCarSlider from '../../../../components/Forms/FormComponents/FormCarSlider'
import FormMoneySlider from '../../../../components/Forms/FormComponents/FormMoneySlider'
import UITextBodySmall from '../../../../ui/UIText/UITextBodySmall'
import SimpleCheckbox from '../../../../ui/SimpleCheckbox'
import FormTermsSelect from '../../../../components/Forms/FormComponents/FormTermsSelect/FormTermsSelect'
import FormPackages from '../../../../components/Forms/FormComponents/FormPackages/FormPackages'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import UIFormInputSelect from '../../../../ui/UIFormComponents/UIFormInputSelect/UIFormInputSelect'
import UIInputAdornmentText from '../../../../ui/UIFormComponents/UIInputAdornmentText'
import FormDurationSlider from '../../../../components/Forms/FormComponents/FormDurationSlider'
import { sumDelimiter } from '../../../../utils/sumDelimiter'
import {
  calculateCustomPackageTerms,
  calculateRV,
  calculateTerms,
  createLoanApplication,
} from '../../../../store/features/financing/financing.actions'
import UIPreloader from '../../../../ui/UIPreloader/UIPreloader'
import { getProductProviders } from '../../../../store/features/productProviders/productProviders.actions'
import {
  displayRvSlider,
  displayTermsAndPackages,
  getCalculatorTermsList,
  getCustomPackageData,
  getDurationOptions,
  getLoanApplicationFormData,
  getLoanCalculatorFormState,
  getRvCalculationData,
  getTermsCalculationData,
  getTermSlides,
  loanCalculatorFormInitialState,
} from './loanCalculator.helpers'
import { setCurrentLoanApplicationSectionsState } from '../../../../store/features/financing/financing.reducer'
import { useTranslation } from 'react-i18next'
import FormInputMoneyAndPercentage from '../../../../components/Forms/FormComponents/FormInputMoneyAndPercentage/FormInputMoneyAndPercentage'
import VINAutoFill from '../../../../components/VINAutoFill/VINAutoFill'
import { updateCar } from '../../../../store/features/carSlice/car.actions'

const LoanCalculator = () => {
  const { t } = useTranslation()
  const isLoadingRvCalculation = useSelector((state) => state.financing.rvCalculation.isLoading)
  const rvData = useSelector((state) => state.financing.rvCalculation.data)
  const loanApplication = useSelector((state) => state.financing.currentLoanApplication.data)
  const sectionsState = useSelector((state) => state.financing.currentLoanApplication.sectionsState)
  const { isOpen, isSaved, disabled } = sectionsState.loanCalculator
  const car = useSelector((state) => state.car.car)

  const isTermsCalculationLoading = useSelector(
    (state) => state.financing.termsCalculation.isLoading,
  )
  const termsCalculation = useSelector((state) => state.financing.termsCalculation)
  const basicCalcTerms = useSelector((state) => state.financing.termsCalculation.basic)
  const fullCalcTerms = useSelector((state) => state.financing.termsCalculation.full)
  const customCalcTerms = useSelector((state) => state.financing.termsCalculation.custom)
  const banks = useSelector((state) => state.productProviders.data)

  const [formData, setFormData] = useState(loanCalculatorFormInitialState)
  const [rvSliderData, setRvSliderData] = useState([])
  const [vinAutofillModals, setVinAutofillModals] = useState(false)

  const finalCarPrice = car?.grossPriceWithoutOptions
    ? parseInt(car?.grossPriceWithoutOptions)
    : parseInt(formData?.sellingPrice)

  const isDownPaymentFilled =
    (parseInt(formData.downPayment) > 0 && !formData.downPaymentInPercents) ||
    (formData.downPaymentInPercents && parseInt(formData.downPaymentPercentage) > 0)

  const slides = getTermSlides(termsCalculation, formData)

  const dispatch = useDispatch()

  const onVinProviderResponse = async (data) => {
    const carData = {
      grossPriceWithoutOptions: data?.grossPriceWithoutOptions ?? '',
      grossPriceWithOptions: data?.grossPriceWithOptions ?? '',
      optionsPrice: data?.optionsPrice ?? '',
      isProvidedByVinProvider: true,
    }
    await dispatch(updateCar({ carIdUrl: car?.['@id'], data: carData }))
  }

  const handleSetMoneySliderData = () => {
    const rvPriceString = rvData?.rvCalculationDetails?.rv?.units
    const rvPrice = rvPriceString ? parseInt(rvPriceString) : 3500
    const max =
      (car.grossPriceWithoutOptions
        ? parseInt(car.grossPriceWithoutOptions)
        : parseInt(formData.sellingPrice)) / 2
    let data = []
    for (let i = 0; i <= max; i += 1000) {
      data.push({
        price: i,
      })
      if (i + 1000 > rvPrice && i < rvPrice) {
        data.push({
          price: rvPrice,
        })
      }
    }
    data.push({
      value: data.length,
      price: max,
    })
    setRvSliderData(data.map((item, index) => ({ ...item, value: index })))
  }

  const handleCalculateRV = async () => {
    const data = getRvCalculationData(car, formData)
    await dispatch(calculateRV({ data }))
  }

  const handleCalculate = async () => {
    const data = getTermsCalculationData(formData, car)
    dispatch(calculateTerms({ data, terms: getCalculatorTermsList(formData.duration) }))
  }

  const handleCalculateCustomPack = async () => {
    const customPackageData = getCustomPackageData(car, formData, rvSliderData)
    dispatch(calculateCustomPackageTerms({ data: customPackageData }))
  }

  const handleSubmit = async () => {
    const data = getLoanApplicationFormData(formData, car)
    const res = await dispatch(createLoanApplication({ data }))
    if (createLoanApplication.fulfilled.match(res)) {
      dispatch(
        setCurrentLoanApplicationSectionsState({
          customerDataForm: {
            isOpen: true,
            isSaved: false,
            disabled: false,
          },
          loanCalculator: {
            isSaved: true,
            isOpen: false,
          },
        }),
      )
    }
  }

  useEffect(() => {
    if (
      parseInt(formData.duration) > 0 &&
      parseInt(formData.annualMileage) * 5000 > 0 &&
      (car?.grossPriceWithoutOptions ||
        (!car?.grossPriceWithoutOptions && parseInt(formData.sellingPrice) > 0)) &&
      rvData?.rvCalculationDetails?.rv?.units &&
      isDownPaymentFilled
    ) {
      handleCalculate()
    }
  }, [
    formData.downPayment,
    formData.duration,
    formData.annualMileage,
    formData.sellingPrice,
    car?.grossPriceWithoutOptions,
    formData.rvi,
    rvData,
  ])

  useEffect(() => {
    if (
      parseInt(formData.duration) > 0 &&
      parseInt(formData.annualMileage) * 5000 > 0 &&
      (car?.grossPriceWithoutOptions ||
        (!car?.grossPriceWithoutOptions && parseInt(formData.sellingPrice) > 0)) &&
      rvData?.rvCalculationDetails?.rv?.units &&
      isDownPaymentFilled &&
      formData.customInterestRate &&
      formData.selectedBank
    ) {
      handleCalculateCustomPack()
    }
  }, [
    formData.downPayment,
    formData.downPaymentPercentage,
    formData.downPaymentInPercents,
    formData.duration,
    formData.selectedTerm,
    formData.annualMileage,
    formData.sellingPrice,
    car?.grossPriceWithoutOptions,
    formData.rvi,
    formData.customInterestRate,
    formData.selectedBank,
    rvData,
    formData.customOptions,
    formData.customRviIncluded,
  ])

  useEffect(() => {
    handleSetMoneySliderData()
  }, [rvData])

  useEffect(() => {
    if (displayRvSlider(formData)) {
      handleCalculateRV()
    }
  }, [
    formData.duration,
    formData.annualMileage,
    formData.sellingPrice,
    formData.downPayment,
    formData.downPaymentPercentage,
    formData.downPaymentInPercents,
    car?.grossPriceWithoutOptions,
  ])

  useEffect(() => {
    setFormData(getLoanCalculatorFormState(loanApplication))
  }, [loanApplication])

  useEffect(() => {
    dispatch(getProductProviders())
  }, [])

  return (
    <CollapseBlock
      id="loan-calculator-block"
      title="Calculator"
      disabled={disabled}
      saved={isSaved}
      collapsed={!isOpen}
      onCollapseChange={(value) =>
        dispatch(
          setCurrentLoanApplicationSectionsState({
            loanCalculator: {
              isOpen: !value,
            },
          }),
        )
      }
    >
      <Grid container gap="24px">
        <Grid item xs={12} sx={{ mb: '30px' }}>
          <Stack direction="row" gap="27px" alignItems="center">
            <UITextRegular sx={{ fontWeight: 700 }} text="Type of contractor" />
            <FormControl>
              <RadioGroup
                row
                value={formData.typeOfContractor}
                onChange={(e) => {
                  setFormData({ ...formData, typeOfContractor: e.target.value })
                }}
                sx={{
                  gap: '30px',
                }}
              >
                <FormControlLabel control={<CustomRadio />} value="personal" label="Personal" />
                <FormControlLabel control={<CustomRadio />} value="company" label="Company" />
                <FormControlLabel
                  control={<CustomRadio />}
                  value="sole_company"
                  label="Sole company"
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldSmallEdible
            required
            fullWidth
            label="Selling Price"
            value={sumDelimiter(formData.sellingPrice)}
            onChange={(e) => {
              setFormData({
                ...formData,
                sellingPrice: e.target.value.replace(/[^0-9]/g, ''),
              })
            }}
            InputProps={{
              endAdornment: <UIInputAdornmentText text="CHF" />,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInputMoneyAndPercentage
            valuePercents={formData.downPaymentPercentage}
            valueMoney={formData.downPayment}
            inPercents={formData.downPaymentInPercents}
            setInPercents={(val) => setFormData({ ...formData, downPaymentInPercents: val })}
            onChangeMoney={(val) => setFormData({ ...formData, downPayment: val })}
            onChangePercents={(val) => setFormData({ ...formData, downPaymentPercentage: val })}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: '30px' }}>
          <Stack direction="row" gap="15px" alignItems="center">
            <UITextRegular sx={{ fontWeight: 700 }} text="Gross price" />
            <UIButtonPlainGreen
              text={
                car?.isProvidedByVinProvider ? t('badge_car_info_autofilled') : 'Use VIN-autofill'
              }
              disabled={car?.isProvidedByVinProvider}
              prefixIcon={<img src={IconVinAutofill} alt="" />}
              onClick={() => setVinAutofillModals(true)}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldSmallEdible
            fullWidth
            label="Gross price without options"
            value={car?.grossPriceWithoutOptions ?? ''}
            disabled
            InputProps={{
              endAdornment: <UIInputAdornmentText text="CHF" />,
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldSmallEdible
            fullWidth
            label="Options price"
            value={car?.optionsPrice ?? ''}
            disabled
            InputProps={{
              endAdornment: <UIInputAdornmentText text="CHF" />,
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextFieldSmallEdible
            fullWidth
            label="Gross price with options"
            value={car?.grossPriceWithOptions ?? ''}
            disabled
            InputProps={{
              endAdornment: <UIInputAdornmentText text="CHF" />,
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ height: '30px' }} />
        <Grid item xs={12}>
          <FormDurationSlider
            value={formData.duration}
            onChange={(val) => setFormData({ ...formData, duration: val })}
          />
        </Grid>
        <Grid item md={3} sx={{ mt: '10px' }}>
          <UIFormInputSelect
            label="Duration"
            options={getDurationOptions()}
            value={formData.duration}
            onChange={(e) => setFormData({ ...formData, duration: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sx={{ height: '30px' }} />
        <Grid item xs={12}>
          <FormCarSlider
            label="Annual mileage"
            value={formData.annualMileage}
            onChange={(val) => setFormData({ ...formData, annualMileage: val })}
          />
        </Grid>

        {rvData &&
        displayRvSlider(formData) &&
        !parseInt(rvData?.rvCalculationDetails?.rv?.units) ? (
          <Grid item xs={12} sx={{ position: 'relative', mt: '30px' }}>
            {isLoadingRvCalculation && <UIPreloader />}
            <Box sx={{ height: '200px', display: 'flex', justifyContent: 'justifyContent' }}>
              <UITextRegular text="No offers with RV coverage found" />
            </Box>
          </Grid>
        ) : rvData && displayRvSlider(formData) ? (
          <Grid item xs={12} sx={{ position: 'relative', mt: '30px' }}>
            {isLoadingRvCalculation && <UIPreloader />}
            <Stack direction="row" alignItems="center" gap="15px" sx={{ mb: '10px' }}>
              <UITextRegular fontWeight="700" text="Residual Value" />
              <Stack direction="row" alignItems="center" gap="5px">
                <SimpleCheckbox
                  value={formData.customCoverage}
                  setValue={(val) => setFormData({ ...formData, customCoverage: val })}
                />
                <UITextRegular text="No RV coverage by Insercle" />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: '5px', position: 'relative' }}
            >
              <UITextBodySmall
                text={`${sumDelimiter(parseInt(rvData?.rvCalculationDetails?.rv?.units)).replace("'", ' ')} CHF`}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: `calc(${(rvData?.rvCalculationDetails?.rv?.units / (finalCarPrice / 2)) * 100}% - 25px)`,
                }}
              />
              <UITextBodySmall text="0 CHF" />
              <UITextBodySmall text={`${sumDelimiter(finalCarPrice / 2).replace("'", ' ')} CHF`} />
            </Stack>
            <FormMoneySlider
              data={rvSliderData}
              covered={rvData?.rvCalculationDetails?.rv?.units}
              disabled={formData.customCoverage}
              value={formData.rvi}
              onChange={(val) => setFormData({ ...formData, rvi: val })}
            />
          </Grid>
        ) : null}

        {formData.customCoverage && (
          <Grid item md={3} sx={{ mt: '20px' }}>
            <TextFieldSmallEdible fullWidth label="Dealer Risk Input (RVI)" />
          </Grid>
        )}
        <Grid item xs={12} sx={{ height: '30px' }} />
        {displayTermsAndPackages(formData) ? (
          <>
            <Grid item xs={12}>
              <Box sx={{ position: 'relative' }}>
                {isTermsCalculationLoading && <UIPreloader text="Calculating..." />}
                <FormTermsSelect
                  disabled={formData.customTerm}
                  selectedTerm={formData.selectedTerm}
                  slides={slides}
                  onChange={(val) => setFormData({ ...formData, selectedTerm: val })}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ height: '30px' }} />
            <Grid item xs={12}>
              <FormPackages
                calcTerms={{ basicCalcTerms, fullCalcTerms }}
                customPrice={termsCalculation.customPrice}
                formData={formData}
                setFormData={setFormData}
                banks={banks ?? []}
              />
            </Grid>
          </>
        ) : null}
        <Grid item xs={12} sx={{ height: '20px' }} />
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <ButtonLG onClick={() => handleSubmit()}>Save and continue</ButtonLG>
        </Grid>
      </Grid>
      <VINAutoFill
        car={car}
        open={vinAutofillModals}
        setOpen={setVinAutofillModals}
        onSuccess={onVinProviderResponse}
        onError={() => {}}
        vinAutofillModalProps={{
          cancelButtonText: 'Do it later',
          title: 'Autofill gross price',
          description: 'Use Fleatify Magic Autofill tool to fill your gross price automatically!',
        }}
      />
    </CollapseBlock>
  )
}

export default LoanCalculator
