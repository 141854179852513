import React, { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { setCurrentPageTitle } from '../../../../store/features/authSlice'
import {
  setApplication,
  resetData,
  setReferenceCompanies,
  setReferenceRisks,
  setDeals,
  setSelectedTab,
} from '../../../../store/features/insuranceOperatorSlice'
import { colorsConfig } from '../../../../config/themeConfig'
import IconVirtualGarage from '../../../../assests/img/icons/icon-virtual-garage-home.svg'
import BreadcrumbsComponent from '../../../../components/BreadcrumbsComponent'
import InsuranceRequestSummary from './InsuranceRequestSummary'
import CustomTabs from '../../../../ui/CustomTabs'
import CustomTab from '../../../../ui/CustomTab'
import TextNormal from '../../../../ui/Text/TextNormal'
import InsuranceRequestForm from './InsuranceRequestForm'
import { privateRequest } from '../../../../utils/request'
import { API_ENDPOINTS } from '../../../../config/default'
import { generateUID } from '../../../../utils/generateUID'
import {
  getInsuranceCompanyName,
  parseInsuranceDeal,
  parseSelectedRisks,
} from '../operator-insurance.helpers'
import { initialDealApprovedData } from '../operator-insurance.constants'

const InsuranceRequest = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const location = useLocation()

  const selectedTab = useSelector((state) => state.insuranceOperator.selectedTab)
  const application = useSelector((state) => state.insuranceOperator.application)
  const deals = useSelector((state) => state.insuranceOperator.deals)
  const referenceCompanies = useSelector((state) => state.insuranceOperator.referenceCompanies)
  const referenceRisks = useSelector((state) => state.insuranceOperator.referenceRisks)

  const [applicationLoading, setApplicationLoading] = useState(true)
  const [dealsLoading, setDealsLoading] = useState(true)

  const setRequestedDeal = () => {
    if (location.state && location.state?.loanDeal) {
      dispatch(setSelectedTab(deals.findIndex((item) => item.id === location?.state?.loanDeal)))
    }
  }

  const handleSetInsuranceDeals = (data) => {
    dispatch(setDeals(data.map((item) => parseInsuranceDeal(item, application?.selectedRisks))))
  }

  const getInsuranceDeals = () => {
    privateRequest
      .get(API_ENDPOINTS.insurance.deals, {
        params: { insuranceApplication: `/insurance/insurance-applications/${params.id}` },
      })
      .then((response) => {
        handleSetInsuranceDeals(response.data['hydra:member'])
        setDealsLoading(false)
      })
  }

  const getInsuranceApplication = () => {
    privateRequest.get(API_ENDPOINTS.insurance.applicationById(params.id)).then((response) => {
      dispatch(setApplication(response.data))
      const person = response.data?.customer?.person
      if (person) {
        if (person?.firstName || person?.lastName) {
          dispatch(
            setCurrentPageTitle(
              `Insurance request: ${person?.firstName || ''} ${person?.lastName || ''}`,
            ),
          )
        } else {
          dispatch(setCurrentPageTitle(`Insurance request`))
        }
      }

      setApplicationLoading(false)
    })
  }

  const getReferenceCompanies = () => {
    privateRequest.get(API_ENDPOINTS.insurance.companies).then((response) => {
      dispatch(setReferenceCompanies(response.data['hydra:member']))
    })
  }

  const getReferenceRisks = () => {
    privateRequest.get(API_ENDPOINTS.insurance.risks).then((response) => {
      dispatch(setReferenceRisks(response.data['hydra:member']))
    })
  }

  useEffect(() => {
    if (!dealsLoading) {
      setRequestedDeal()
    }
  }, [dealsLoading])

  useEffect(() => {
    if (!applicationLoading) {
      getInsuranceDeals()
    }
  }, [applicationLoading])

  useEffect(() => {
    if (referenceCompanies.length !== 0 && referenceRisks.length !== 0) {
      getInsuranceApplication()
    }
  }, [referenceCompanies, referenceRisks])

  useEffect(() => {
    getReferenceRisks()
    getReferenceCompanies()
    return () => {
      dispatch(resetData())
    }
  }, [])

  return (
    <Box>
      {applicationLoading || dealsLoading ? (
        'Loading data...'
      ) : (
        <>
          <Box
            sx={{
              background: colorsConfig.bgLightBlue,
              ml: '-23px',
              mr: '-98px',
              pl: '23px',
              pr: '98px',
              pb: '46px',
              pt: '30px',
              mt: '-30px',
              mb: '20px',
            }}
          >
            <Stack gap="30px">
              <BreadcrumbsComponent
                currentPage={{
                  name: `${application?.customer?.person?.firstName || ''} ${application?.customer?.person?.lastName || ''}`,
                }}
                parentPages={[
                  {
                    name: 'Insurance requests',
                    icon: <img src={IconVirtualGarage} alt="" style={{ filter: 'grayscale(0)' }} />,
                    link: '/operator/insurance-requests',
                  },
                ]}
              />
              <InsuranceRequestSummary />
            </Stack>
          </Box>
          <CustomTabs
            value={selectedTab}
            onChange={(e, newVal) => dispatch(setSelectedTab(newVal))}
          >
            {deals.map((item) => {
              return (
                <CustomTab
                  key={generateUID()}
                  label={
                    <TextNormal>
                      {getInsuranceCompanyName(referenceCompanies, item?.insuranceCompany)}
                    </TextNormal>
                  }
                />
              )
            })}
          </CustomTabs>
          <InsuranceRequestForm />
        </>
      )}
    </Box>
  )
}

export default InsuranceRequest
