import React from 'react'
import { Box } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const UIPackageTile = ({ children, sx, isSelected, disabled, ...props }) => {
  return (
    <Box
      className={[isSelected ? 'active' : '', disabled ? 'disabled' : ''].join(' ')}
      sx={{
        borderRadius: '10px',
        border: '1px solid',
        borderColor: colorsConfig.lightGray,
        boxShadow: '0 0 12px 0 rgba(0,0,0,0.1)',
        p: '18px',
        transition: '0.25s all ease-in-out',
        '& button': {
          transition: '0.25s all ease-in-out',
        },
        '&:hover': {
          background: colorsConfig.mainGreen,
          transition: '0.25s all ease-in-out',
        },
        '&.active': {
          background: colorsConfig.mainGreen,
          transition: '0.25s all ease-in-out',
          '& button': {
            background: 'white',
            color: colorsConfig.mainBlack,
            transition: '0.25s all ease-in-out',
          },
        },
        '&.disabled': {
          '&:hover': {
            background: 'white',
            '&.active': {
              background: colorsConfig.mainGreen,
            },
          },
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

export default UIPackageTile
