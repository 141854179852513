import { createLoanApplication } from '../financing.actions'

export default (builder) => {
  builder
    .addCase(createLoanApplication.pending, (state) => {
      state.currentLoanApplication.create.isLoading = true
    })
    .addCase(createLoanApplication.fulfilled, (state, action) => {
      state.currentLoanApplication.create.isLoading = false
      state.currentLoanApplication.create.error = null
      state.currentLoanApplication.data = action.payload
    })
    .addCase(createLoanApplication.rejected, (state, action) => {
      state.currentLoanApplication.create.isLoading = false
      state.currentLoanApplication.create.error = action.payload
    })
}
