import { privateRequest } from '../../utils/request'

class CarPilotLampsApiService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      batch: (id) => `/cars/${id}/pilot-lamps/batch`,
      collection: (id) => `/cars/${id}/pilot-lamps`,
      instance: (carId, carPilotLampId) => `/cars/${carId}/pilot-lamps/${carPilotLampId}`,
    }
  }

  batchPilotLamps = async (carPilotLampId, data) => {
    const response = await this.#api.post(this.#endPoints.batch(carPilotLampId), data)
    return response
  }
}

const carPilotLampsApiService = new CarPilotLampsApiService({ api: privateRequest })

export default carPilotLampsApiService
