import React from 'react'
import { Box, Button, IconButton, Modal, Typography } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import TextNormal from '../../../ui/Text/TextNormal'
import { COMPANY_NAME, REGION_LOCALE } from '../../../utils/constants/global.constants'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { CloseRounded } from '@mui/icons-material'

const VINAutofillFailedModal = ({ isVINAutofillFailedModalOpen, closeVINAutofillFailedModal }) => {
  const { isMaxWidth600, isMaxWidth960 } = useBreakpoints()
  return (
    <Modal open={isVINAutofillFailedModalOpen} onClose={closeVINAutofillFailedModal}>
      <Box
        sx={{
          position: 'absolute',
          width: isMaxWidth600 ? '340px' : isMaxWidth960 ? '540px' : '688px',
          height: isMaxWidth600 ? '232px' : '305px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: colorsConfig.bgLightBlue,
          padding: isMaxWidth600 ? '25px 30px 30px' : '30px',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontFamily: '"Helvetica", sans-serif',
            fontSize: isMaxWidth600 ? '24px' : '40px',
            color: colorsConfig.mainBlack,
            fontStyle: 'normal',
            lineHeight: 'normal',
            mb: isMaxWidth600 ? '5px' : '10px',
            textAlign: isMaxWidth600 ? 'left' : 'center',
          }}
        >
          😔Sorry, we don’t have information on your VIN
        </Typography>
        <TextNormal
          sx={{
            fontSize: isMaxWidth600 && '14px',
            mb: isMaxWidth600 ? '30px' : '40px',
          }}
        >
          {COMPANY_NAME[REGION_LOCALE]} coins will be returned to your account promptly
        </TextNormal>
        <Button
          variant="contained"
          sx={{
            width: isMaxWidth600 ? '280px' : '310px',
            padding: '10px 60px',
            textTransform: 'none',
            background: '#007DFF',
            color: 'white',
            fontSize: '16px',
          }}
          onClick={closeVINAutofillFailedModal}
        >
          Got it
        </Button>
        <IconButton
          onClick={closeVINAutofillFailedModal}
          sx={{ position: 'absolute', top: '8px', right: '8px' }}
        >
          <CloseRounded sx={{ fill: colorsConfig.iconGray }} />
        </IconButton>
      </Box>
    </Modal>
  )
}

export default VINAutofillFailedModal
