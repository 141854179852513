import React, { useState } from 'react'
import { Box, Stack } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import UITextTitleSmall from '../../../ui/UIText/UITextTitleSmall'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import UITextBodySmall from '../../../ui/UIText/UITextBodySmall'
import UIEditIconButton from '../../../ui/UIIconButtons/UIEditIconButton'
import UITextButton from '../../../ui/UIButtons/UITextButton'
import { UISize, UIVariant } from '../../../utils/constants/ui.constants'
import EditLocationModal from '../../../components/Modals/EditLocationModal/EditLocationModal'
import { useDispatch } from 'react-redux'
import { getCompanyAddresses } from '../../../store/features/settings/settings.actions'
import DeleteLocationModal from '../../../components/Modals/DeleteModals/DeleteLocationModal'

const CompanyAddressItem = ({ data }) => {
  const { title, mainText, bottomText } = data?.displayData ?? {}

  const dispatch = useDispatch()

  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  return (
    <Box
      sx={{
        borderBottom: '1px solid',
        borderColor: colorsConfig.lightGray,
        py: '14px',
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
        <Stack gap="4px">
          <UITextTitleSmall sx={{ mb: '4px' }} text={title ?? '-'} />
          <UITextRegular text={mainText ?? '-'} />
          <UITextBodySmall color={colorsConfig.lightGray} text={bottomText ?? '-'} />
        </Stack>
        <Stack direction="row" alignItems="center" gap="4px">
          <UIEditIconButton onClick={() => setEditModal(true)} />
          <UITextButton
            size={UISize.sm}
            variant={UIVariant.danger}
            text="button_delete"
            onClick={() => setDeleteModal(true)}
          />
        </Stack>
      </Stack>
      {editModal && (
        <EditLocationModal
          open={editModal}
          setOpen={setEditModal}
          data={data}
          onSubmit={() => dispatch(getCompanyAddresses())}
        />
      )}
      {deleteModal && (
        <DeleteLocationModal
          open={deleteModal}
          setOpen={setDeleteModal}
          data={data}
          onSubmit={() => dispatch(getCompanyAddresses())}
        />
      )}
    </Box>
  )
}

export default CompanyAddressItem
