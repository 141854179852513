import React, { useEffect, useState } from 'react'
import { TextField, Box, InputAdornment, useMediaQuery } from '@mui/material'
import { changeBid } from '../../../store/features/carSlice/carSlice'
import { useDispatch, useSelector } from 'react-redux'
import { privateRequest } from '../../../utils/request'
import { API_ENDPOINTS } from '../../../config/default'
import { toast } from 'react-toastify'
import { sumDelimiter } from '../../../utils/sumDelimiter'
import { colorsConfig } from '../../../config/themeConfig'
import TextNormal from '../../../ui/Text/TextNormal'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import AnimationBiddingModal from '../../../components/Modals/AnimationBiddingModal'
import { useNavigate } from 'react-router-dom'
import { CURRENCY, REGION_LOCALE } from '../../../utils/constants/global.constants'

const BidForm = () => {
  const navigate = useNavigate()
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const [bid, setBid] = useState('')
  const [bidButtonDisabled, setBidButtonDisabled] = useState(false)
  const [biddingDisabled, setBiddingDisabled] = useState(false)
  const [biddingAnimation, setBiddingAnimation] = useState(false)

  const auctionId = useSelector((state) => state.car.auctionData['@id'])
  const endTime = useSelector((state) => state.car.auctionData.endTime)
  const companyBid = useSelector((state) => state.car.auctionData.companyBid)
  const dispatch = useDispatch()

  const handleSetBid = (value) => {
    setBid(sumDelimiter(value.replace(/[^0-9]/g, '')))
  }

  const handleSubmitBid = () => {
    setBidButtonDisabled(true)
    const formData = {
      value: parseFloat(bid.toString().replace(/'/g, '')),
    }
    privateRequest
      .post(`${auctionId}${API_ENDPOINTS.bids}`, formData)
      .then(() => {
        setBiddingAnimation(true)
        setTimeout(() => {
          setBiddingAnimation(false)
          navigate('/cars-on-auction')
        }, 3000)
        dispatch(
          changeBid({
            ...companyBid,
            value: bid,
          }),
        )
      })
      .catch(() => {
        toast.error('Auction time is up, can not place a bid')
      })
      .finally(() => {
        setBidButtonDisabled(false)
      })
  }

  useEffect(() => {
    console.log(endTime)
    if (endTime) {
      const timer = setInterval(() => {
        const currentTime = new Date()
        const endTimeDate = new Date(endTime)
        // console.log(currentTime)
        // console.log(endTimeDate)
        // console.log(currentTime > endTimeDate)
        if (currentTime > endTimeDate) {
          setBiddingDisabled(true)
        }
        if (currentTime < endTimeDate) {
          setBiddingDisabled(false)
        }
      }, 1000)

      return () => {
        clearInterval(timer)
      }
    }
  }, [endTime])

  useEffect(() => {
    console.log({ companyBid })
  }, [companyBid])

  return (
    <React.Fragment>
      {companyBid ? (
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '&.xs-only': {
              width: '100%',
            },
          }}
        >
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              px: '16.5px',
              width: '233px',
              mb: '18px',
              height: '48px',
              borderRadius: '4px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid',
              borderColor: colorsConfig.mainBlack,
              '&.xs-only': {
                width: '100%',
              },
            }}
          >
            <TextNormal sx={{ mr: '10px' }}>My current bid:</TextNormal>
            <TextBoldNormal>{sumDelimiter(companyBid.value)}</TextBoldNormal>
          </Box>
        </Box>
      ) : null}
      {biddingDisabled ? null : (
        <TextField
          className={xsOnly ? 'xs-only' : ''}
          label={companyBid ? 'Change your bid' : 'Enter your bid'}
          value={bid}
          type={'text'}
          autoFocus
          autoComplete="off"
          disabled={biddingDisabled}
          onChange={(e) => {
            if (e.target.value.length < 12) {
              handleSetBid(e.target.value)
            }
          }}
          onBlur={(e) => {
            if (e.target.value.length < 12) {
              handleSetBid(e.target.value.replace(/[^0-9]/g, ''))
            }
          }}
          sx={{
            height: '38px',
            width: '233px',
            borderColor: colorsConfig.buttonYellow,
            borderRadius: '4px',
            background: 'none',
            '& .Mui-focused': {
              borderColor: colorsConfig.buttonYellow,
              boxShadow: 'none',
              background: 'none',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: colorsConfig.buttonYellow,
                boxShadow: 'none',
                background: 'none',
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: colorsConfig.buttonYellow,
              boxShadow: 'none',
              background: 'none',
            },
            '&.xs-only': {
              width: '100%',
            },
          }}
          inputProps={{
            inputMode: 'numeric',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <span
                  style={{
                    color: colorsConfig.buttonYellow,
                    fontWeight: 700,
                    fontFamily: '"Helvetica", sans-serif',
                    fontSize: '16px',
                  }}
                >
                  {CURRENCY[REGION_LOCALE]}
                </span>
              </InputAdornment>
            ),
          }}
          helperText={`Minimum amount is 1000 ${CURRENCY[REGION_LOCALE]}`}
        />
      )}

      {biddingDisabled ? null : (
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            mt: '50px',
            '&.xs-only': {
              width: '100%',
            },
          }}
        >
          <ButtonLG
            className={xsOnly ? 'xs-only' : ''}
            disabled={
              !bid ||
              parseFloat(bid.toString().replace("'", '')) < 1000 ||
              bidButtonDisabled ||
              biddingDisabled
            }
            onClick={() => {
              handleSubmitBid()
            }}
            sx={{
              '&.xs-only': {
                width: '100%',
              },
            }}
          >
            Confirm
          </ButtonLG>
        </Box>
      )}
      <AnimationBiddingModal open={biddingAnimation} setOpen={setBiddingAnimation} />
    </React.Fragment>
  )
}

export default BidForm
