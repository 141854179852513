import { privateRequest } from '../../utils/request'

class CompanyAddressesApiService {
  #api

  #endPoints

  #abortControllers

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      collection: '/company-addresses',
      instance: (id) => `/company-addresses/${id}`,
    }
    this.#abortControllers = {}
  }

  getCompanyAddresses = async (params, prefix = 'getCompanyAddresses') => {
    this.#abortControllers[prefix]?.abort()
    this.#abortControllers[prefix] = new AbortController()
    const response = await this.#api.get(this.#endPoints.collection, {
      params,
      signal: this.#abortControllers[prefix]?.signal,
    })
    return response
  }

  createCompanyAddress = async (data) => {
    const response = await this.#api.post(this.#endPoints.collection, data)
    return response
  }

  deleteCompanyAddress = async (companyAddressId) => {
    const response = await this.#api.delete(this.#endPoints.instance(companyAddressId))
    return response
  }
}

const companyAddressesApiService = new CompanyAddressesApiService({ api: privateRequest })

export default companyAddressesApiService
