import React from 'react'
import { Select } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const SelectTFS = ({ children, sx, name, ...rest }) => {
  return (
    <Select
      name={name}
      sx={{
        borderRadius: '4px',
        height: '38px',
        fontSize: '14px',
        border: 'none',
        '& img': {
          width: '35px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
          boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
        },
        '&.attention': {
          '& .MuiOutlinedInput-notchedOutline': {
            boxShadow: `0 2px 8px 2px ${colorsConfig.shadowedYellow}`,
          },
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Select>
  )
}

export default SelectTFS
