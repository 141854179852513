import dayjs from 'dayjs'
import * as Yup from 'yup'
import { formErrors } from '../../formErrors'

export const getWarrantyDetailsFormInitialState = (warrantyApplication) => {
  const offsetDateTime = new Date().getTime() - 3600000 * 24 * 366
  const startDateTime = new Date(warrantyApplication?.startDate).getTime()
  return {
    startDate: startDateTime < offsetDateTime ? '' : dayjs(warrantyApplication?.startDate),
    selectedProduct: warrantyApplication?.selectedProduct,
    consent: !!warrantyApplication?.selectedProduct,
  }
}

export const warrantyDetailsFormValidationSchema = Yup.object().shape({
  startDate: Yup.date().required(formErrors.required),
})
