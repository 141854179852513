import React from 'react'
import { Box, Grid, Stack } from '@mui/material'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import SimpleCheckbox from '../../../../ui/SimpleCheckbox'
import PackageItemWrapper from './PackageItemWrapper'
import UITextTitleSmall from '../../../../ui/UIText/UITextTitleSmall'
import UITextRegular from '../../../../ui/UIText/UITextRegular'
import IconDollarBlue from '../../../../assests/img/icons/icon-dollar-blue.svg'
import IconRocket from '../../../../assests/img/icons/icon-rocket.svg'
import IconToolkit from '../../../../assests/img/icons/icon-toolkit.svg'
import IconCheckmark from '../../../../assests/img/icons/icon-checkmark-black.svg'
import UIFormInputSelect from '../../../../ui/UIFormComponents/UIFormInputSelect/UIFormInputSelect'
import { sumDelimiter, sumDelimiterSpaced } from '../../../../utils/sumDelimiter'
import {
  getTermCalcMeta,
  getTermMinimalAmount,
} from '../../../../pages/CarPage/DealerFinancing/LoanCalculator/loanCalculator.helpers'
import { DEALER_PRODUCT_CLASSIFICATION } from '../../../../utils/constants/enums.constants'
import { colorsConfig } from '../../../../config/themeConfig'

const getCustomInterestRate = () => {
  let rates = []
  for (let i = 1; i <= 41; i++) {
    rates.push({
      value: i * 0.5,
      label: `${i * 0.5} %`,
    })
  }
  return rates
}

const FormPackages = ({ formData, setFormData, calcTerms, customPrice, banks }) => {
  const { basicCalcTerms, fullCalcTerms } = calcTerms
  const basicPrice = getTermMinimalAmount(formData.selectedTerm, basicCalcTerms)
  const fullPrice = getTermMinimalAmount(formData.selectedTerm, fullCalcTerms)
  const basicMeta = getTermCalcMeta(formData.selectedTerm, basicCalcTerms)
  const fullMeta = getTermCalcMeta(formData.selectedTerm, fullCalcTerms)

  const hasCustomOption = (option) => !!formData.customOptions.find((e) => e === option)

  const handleCustomOption = (value, option) => {
    const hasOption = !!formData.customOptions.find((e) => e === option)
    if (value && !hasOption) {
      setFormData({ ...formData, customOptions: [...formData.customOptions, option] })
    } else if (!value && hasOption) {
      setFormData({
        ...formData,
        customOptions: formData.customOptions.filter((e) => e !== option),
      })
    }
  }

  return (
    <Grid container spacing="16px">
      <Grid container spacing={'16px'} alignItems={'stretch'}>
        <Grid item xs={12} md={4}>
          <PackageItemWrapper className={formData.selectedPackage === 'basic' ? 'active' : ''}>
            <Stack
              gap="30px"
              justifyContent="space-between"
              sx={{ minHeight: '100%', alignSelf: 'stretch' }}
            >
              <Stack gap="20px">
                <Stack direction="row" gap="5px" alignItems="center">
                  <img src={IconDollarBlue} alt="" />
                  <UITextTitleSmall text="Basic Offer" />
                </Stack>
                <Stack direction="row" alignItems="center" gap="9px">
                  <UITextRegular fontWeight="700">{basicMeta?.bankName}:</UITextRegular>
                  <UITextRegular
                    fontWeight="700"
                    sx={{ color: colorsConfig.mainBlue }}
                    text={basicMeta?.loanProgramName}
                  />
                </Stack>
                <Stack gap="10px">
                  <Stack direction="row" gap="5px" alignItems="center">
                    <img src={IconCheckmark} alt="" />
                    <UITextRegular text="RVI" />
                  </Stack>
                </Stack>
              </Stack>
              <Stack gap="20px" alignItems="center">
                <UITextRegular>
                  From{' '}
                  <span style={{ fontWeight: 700, fontSize: '24px' }}>
                    {sumDelimiterSpaced(basicPrice)}
                  </span>{' '}
                  CHF/month
                </UITextRegular>
                <ButtonLG
                  className="select-button"
                  sx={{
                    minWidth: '180px',
                  }}
                  onClick={() => setFormData({ ...formData, selectedPackage: 'basic' })}
                >
                  {formData.selectedPackage === 'basic' ? 'Selected' : 'Choose'}
                </ButtonLG>
              </Stack>
            </Stack>
          </PackageItemWrapper>
        </Grid>
        <Grid item xs={12} md={4}>
          <PackageItemWrapper
            className={[formData.selectedPackage === 'full' ? 'active' : ''].join(' ')}
          >
            <Stack
              gap="30px"
              justifyContent="space-between"
              sx={{ minHeight: '100%', alignSelf: 'stretch' }}
            >
              <Stack gap="20px">
                <Stack direction="row" gap="5px" alignItems="center">
                  <img src={IconRocket} alt="" />
                  <UITextTitleSmall text="Fastest Deal" />
                </Stack>
                <Stack direction="row" alignItems="center" gap="5px">
                  <UITextRegular fontWeight="700">{fullMeta?.bankName}:</UITextRegular>
                  <UITextRegular
                    fontWeight="700"
                    sx={{ color: colorsConfig.mainBlue }}
                    text={fullMeta?.loanProgramName}
                  />
                </Stack>
                <Stack gap="18px">
                  <Stack direction="row" gap="5px" alignItems="center">
                    <img src={IconCheckmark} alt="" />
                    <UITextRegular text="RVI" />
                  </Stack>
                  <Stack direction="row" gap="5px" alignItems="center">
                    <img src={IconCheckmark} alt="" />
                    <UITextRegular text="Warranty" />
                  </Stack>
                  <Stack direction="row" gap="5px" alignItems="center">
                    <img src={IconCheckmark} alt="" />
                    <UITextRegular text="Casco" />
                  </Stack>
                  <Stack direction="row" gap="5px" alignItems="center">
                    <img src={IconCheckmark} alt="" />
                    <UITextRegular text="PPI" />
                  </Stack>
                </Stack>
              </Stack>
              <Stack gap="20px" alignItems="center">
                <UITextRegular>
                  From{' '}
                  <span style={{ fontWeight: 700, fontSize: '24px' }}>
                    {sumDelimiterSpaced(fullPrice).replace("'", ' ')}
                  </span>{' '}
                  CHF/month
                </UITextRegular>
                <ButtonLG
                  className="select-button"
                  sx={{
                    minWidth: '180px',
                  }}
                  onClick={() => setFormData({ ...formData, selectedPackage: 'full' })}
                >
                  {formData.selectedPackage === 'full' ? 'Selected' : 'Choose'}
                </ButtonLG>
              </Stack>
            </Stack>
          </PackageItemWrapper>
        </Grid>
        <Grid item xs={12} md={4}>
          <PackageItemWrapper className={formData.selectedPackage === 'custom' ? 'active' : ''}>
            <Stack
              gap="30px"
              justifyContent="space-between"
              sx={{ minHeight: '100%', alignSelf: 'stretch', width: '100%' }}
            >
              <Stack gap="20px">
                <Stack direction="row" gap="5px" alignItems="center">
                  <img src={IconToolkit} alt="" />
                  <UITextTitleSmall text="Customized Pack" />
                </Stack>
                <UIFormInputSelect
                  fullWidth
                  label="Select bank"
                  options={banks.map((item) => ({
                    value: item?.id?.toString(),
                    label: item?.title,
                  }))}
                  sx={{
                    background: 'white',
                  }}
                  value={formData.selectedBank}
                  onChange={(e) => setFormData({ ...formData, selectedBank: e.target.value })}
                />
                <UIFormInputSelect
                  label="Select interest rate"
                  options={getCustomInterestRate()}
                  sx={{
                    background: 'white',
                  }}
                  value={formData.customInterestRate}
                  onChange={(e) => setFormData({ ...formData, customInterestRate: e.target.value })}
                />
                <Stack gap="15px">
                  <Stack direction="row" gap="5px" alignItems="center">
                    <SimpleCheckbox
                      icon={IconCheckmark}
                      value={formData.customRviIncluded}
                      setValue={(newVal) => {
                        setFormData({
                          ...formData,
                          customRviIncluded: newVal,
                        })
                      }}
                      boxStyles={{
                        width: '24px',
                        height: '24px',
                      }}
                      checkboxStyles={{
                        p: '4px',
                      }}
                    />
                    <UITextRegular text="RVI" />
                  </Stack>
                  <Stack direction="row" gap="5px" alignItems="center">
                    <SimpleCheckbox
                      icon={IconCheckmark}
                      value={hasCustomOption(
                        DEALER_PRODUCT_CLASSIFICATION.DEALER_PRODUCT_CLASSIFICATION_EXTENDED_WARRANTY,
                      )}
                      setValue={(newVal) => {
                        handleCustomOption(
                          newVal,
                          DEALER_PRODUCT_CLASSIFICATION.DEALER_PRODUCT_CLASSIFICATION_EXTENDED_WARRANTY,
                        )
                      }}
                      boxStyles={{
                        width: '24px',
                        height: '24px',
                      }}
                      checkboxStyles={{
                        p: '4px',
                      }}
                    />
                    <UITextRegular text="Warranty" />
                  </Stack>
                  <Stack direction="row" gap="5px" alignItems="center">
                    <SimpleCheckbox
                      icon={IconCheckmark}
                      value={hasCustomOption(
                        DEALER_PRODUCT_CLASSIFICATION.DEALER_PRODUCT_CLASSIFICATION_CASCO,
                      )}
                      setValue={(newVal) => {
                        handleCustomOption(
                          newVal,
                          DEALER_PRODUCT_CLASSIFICATION.DEALER_PRODUCT_CLASSIFICATION_CASCO,
                        )
                      }}
                      boxStyles={{
                        width: '24px',
                        height: '24px',
                      }}
                      checkboxStyles={{
                        p: '4px',
                      }}
                    />
                    <UITextRegular text="Casco" />
                  </Stack>
                  <Stack direction="row" gap="5px" alignItems="center">
                    <SimpleCheckbox
                      icon={IconCheckmark}
                      value={hasCustomOption(
                        DEALER_PRODUCT_CLASSIFICATION.DEALER_PRODUCT_CLASSIFICATION_PPI,
                      )}
                      setValue={(newVal) => {
                        handleCustomOption(
                          newVal,
                          DEALER_PRODUCT_CLASSIFICATION.DEALER_PRODUCT_CLASSIFICATION_PPI,
                        )
                      }}
                      boxStyles={{
                        width: '24px',
                        height: '24px',
                      }}
                      checkboxStyles={{
                        p: '4px',
                      }}
                    />
                    <UITextRegular text="PPI" />
                  </Stack>
                </Stack>
              </Stack>

              <Stack gap="20px" alignItems="center">
                <UITextRegular>
                  From{' '}
                  <span style={{ fontWeight: 700, fontSize: '24px' }}>
                    {sumDelimiterSpaced(customPrice)}
                  </span>{' '}
                  CHF/month
                </UITextRegular>
                <ButtonLG
                  className="select-button"
                  sx={{
                    minWidth: '180px',
                  }}
                  onClick={() => setFormData({ ...formData, selectedPackage: 'custom' })}
                >
                  {formData.selectedPackage === 'custom' ? 'Selected' : 'Choose'}
                </ButtonLG>
              </Stack>
            </Stack>
          </PackageItemWrapper>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FormPackages
