import { privateRequest } from '../../utils/request'

class InsuranceApplicationService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      workflowTransitions: {
        confirmToApplicationPreparation: (id) =>
          `/insurance/insurance-applications/${id}/workflow/confirm-to-application-preparation`,
        confirmToApprovalProcessStarted: (id) =>
          `/insurance/insurance-applications/${id}/workflow/confirm-to-approval-process-started`,
        confirmToRepliesReceived: (id) =>
          `/insurance/insurance-applications/${id}/workflow/confirm-to-replies-received`,
        confirmToDealerSelected: (id) =>
          `/insurance/insurance-applications/${id}/workflow/confirm-to-dealer-selected`,
        confirmToCustomerSelected: (id) =>
          `/insurance/insurance-applications/${id}/workflow/confirm-to-customer-selected`,
      },
    }
  }

  transitionToApplicationPreparation = async (id) => {
    const response = await this.#api.post(
      this.#endPoints.workflowTransitions.confirmToApplicationPreparation(id),
      {},
    )
    return response
  }

  transitionToApprovalProcessStarted = async (id) => {
    const response = await this.#api.post(
      this.#endPoints.workflowTransitions.confirmToApprovalProcessStarted(id),
      {},
    )
    return response
  }

  transitionToRepliesReceived = async (id) => {
    const response = await this.#api.post(
      this.#endPoints.workflowTransitions.confirmToRepliesReceived(id),
      {},
    )
    return response
  }

  transitionToDealerSelected = async (id) => {
    const response = await this.#api.post(
      this.#endPoints.workflowTransitions.confirmToDealerSelected(id),
      {},
    )
    return response
  }

  transitionToCustomerSelected = async (id) => {
    const response = await this.#api.post(
      this.#endPoints.workflowTransitions.confirmToCustomerSelected(id),
      {},
    )
    return response
  }
}

const insuranceApplicationService = new InsuranceApplicationService({ api: privateRequest })

export default insuranceApplicationService
