import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import TextH1 from '../../ui/Text/TextH1'
import TextNormal from '../../ui/Text/TextNormal'
import ButtonLG from '../../ui/Buttons/ButtonLG'

const BannerSingle = ({ banner, link }) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const navigate = useNavigate()

  return !xsOnly ? (
    <Box
      sx={{
        height: '200px',
        backgroundImage: `url(${banner})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'cover',
        backgroundSize: 'cover',
        py: '15px',
        pt: '10px',
        px: '30px',
        borderRadius: '4px',
        overflow: 'hidden',
        mb: '30px',
      }}
    >
      <TextH1
        sx={{
          marginRight: '10px',
          lineHeight: 'normal',
          mb: '5px',
          '&.xs-only': {
            mr: 0,
            px: '20px',
            mt: '30px',
            textAlign: 'center',
            mb: '21px',
          },
        }}
      >
        Helping the world getting greener
      </TextH1>
      <TextNormal
        sx={{
          mb: '20px',
        }}
      >
        We are dedicated to promoting eco-friendly cars and raising awareness about the <br />{' '}
        importance of green transportation
      </TextNormal>
      <ButtonLG
        onClick={() => {
          navigate(link)
        }}
      >
        Learn more
      </ButtonLG>
    </Box>
  ) : null
}

export default BannerSingle
