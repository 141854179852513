export const formErrors = {
  required: 'error_required_field',
  notEmpty: 'error_field_can_not_be_empty',
  invalidEmail: 'error_invalid_email',
  chooseEmirate: 'error_choose_emirate',
  selectModel: 'error_select_model',
  selectMake: 'error_select_make',
  selectMakeFirst: 'error_select_make_first',
  enterVin: 'error_enter_vin',
  vinInvalidChars: 'error_vin_invalid_chars',
  vinLength: 'error_vin_length',
  enterValidDate: 'error_enter_valid_date',
  privacyPolicyConsent: 'error_privacy_policy_consent',
}
