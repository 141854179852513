import React, { useEffect, useState } from 'react'
import { Box, Grid, IconButton } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { setApprovedData, updateDeal } from '../../../../store/features/insuranceOperatorSlice'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import DragAndDropFiles from '../../../../ui/DragAndDropFiles'
import IconAppraisalDocument from '../../../../assests/img/icons/icon-appraisal-document.svg'
import { CancelRounded } from '@mui/icons-material'
import { colorsConfig } from '../../../../config/themeConfig'
import TextAreaSmall from '../../../../ui/TextAreaSmall'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import InsuranceRequestRisk from './InsuranceRequestRisk'
import { uploadFile } from '../../../../utils/uploadFile'
import { patchRequest, privateRequest } from '../../../../utils/request'
import { API_ENDPOINTS } from '../../../../config/default'
import { toast } from 'react-toastify'
import { formatRiskDetails } from '../operator-insurance.helpers'
import insuranceApplicationService from '../../../../api/insuranceService/insuranceApplicationService'

const InsuranceRequestFormAccepted = () => {
  const application = useSelector((state) => state.insuranceOperator.application)
  const selectedTab = useSelector((state) => state.insuranceOperator.selectedTab)
  const deals = useSelector((state) => state.insuranceOperator.deals)
  const deal = useSelector((state) => state.insuranceOperator.deals[selectedTab])
  const approvedData = useSelector(
    (state) => state.insuranceOperator.deals[selectedTab].approvedData,
  )
  const dispatch = useDispatch()

  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [showErrors, setShowErrors] = useState(false)

  const hasErrors = () => {
    if (
      !!deal.risks.find(
        (item) =>
          item.risksDetails.checked &&
          (!item.risksDetails.dealerCommission || !item.risksDetails.premium),
      )
    ) {
      return true
    }
    if (!approvedData.dealName) return true
    return false
  }

  const handleSubmit = async () => {
    if (hasErrors()) {
      setShowErrors(true)
      return
    }
    setButtonDisabled(true)
    const attachments = await Promise.all(
      approvedData.attachments.map((item) => uploadFile(item, 'default')),
    )
    const formData = {
      dealName: approvedData?.dealName,
      riskDetails: formatRiskDetails(deal?.risks),
      attachments: attachments.map((item) => ({
        attachment: item,
      })),
      comments: [
        {
          comment: approvedData?.comment,
        },
      ],
    }
    privateRequest
      .put(`${API_ENDPOINTS.insurance.deals}/${deal.id}`, formData)
      .then((response) => {
        if (deal?.currentStatus === 'Accepted') {
          privateRequest
            .post(API_ENDPOINTS.insurance.dealWorkflows.approved(application.id, deal.id), {})
            .then(() => {
              toast.success('You have sent the insurance response to the dealer')
              dispatch(
                updateDeal({
                  index: selectedTab,
                  data: {
                    ...response.data,
                    currentStatus: 'Accepted',
                    currentWorkflowState: 'approved',
                  },
                }),
              )
            })
            .finally(() => setButtonDisabled(false))
        }
        if (deal?.currentStatus === 'Request info') {
          privateRequest
            .post(API_ENDPOINTS.insurance.dealWorkflows.requestInfo(application.id, deal.id), {})
            .then(() => {
              toast.success('You have sent the insurance response to the dealer')
              dispatch(
                updateDeal({
                  index: selectedTab,
                  data: {
                    ...response.data,
                    currentStatus: 'Request info',
                    currentWorkflowState: 'insurance_company_request_info',
                  },
                }),
              )
            })
            .finally(() => setButtonDisabled(false))
        }
        if (application.currentWorkflowState === 'approval_process_started') {
          insuranceApplicationService.transitionToRepliesReceived(application.id)
        }
      })
      .finally(() => setButtonDisabled(false))
  }

  return (
    <Box sx={{ mt: '30px' }}>
      <Grid container spacing="24px" sx={{ alignItems: 'flex-start' }}>
        <Grid item xs={12} md={3}>
          <TextFieldSmallEdible
            fullWidth
            label="Offer name"
            value={approvedData?.dealName}
            onChange={(e) => {
              dispatch(
                setApprovedData({
                  ...approvedData,
                  dealName: e.target.value,
                }),
              )
            }}
            error={showErrors && !approvedData?.dealName}
            helperText={showErrors && !approvedData?.dealName ? 'This field can not be empty' : ''}
          />
        </Grid>
        <Grid item md={9} />
        {deal.risks.map((item, index) => {
          return <InsuranceRequestRisk key={index} index={index} showErrors={showErrors} />
        })}
      </Grid>
      <Grid container spacing="24px" sx={{ mt: '30px' }}>
        <Grid item md={6} xs={12}>
          <TextAreaSmall
            fullWidth
            label={'Note'}
            placeholder={'Enter additional information'}
            minRows={5}
            multiline
            value={approvedData.comment}
            onChange={(e) => {
              dispatch(
                setApprovedData({
                  ...approvedData,
                  comment: e.target.value,
                }),
              )
            }}
            error={showErrors && !approvedData?.comment && deal?.currentStatus === 'Request info'}
            helperText={
              showErrors && !approvedData?.comment && deal?.currentStatus === 'Request info'
                ? 'This field can not be empty when status is "Request info"'
                : ''
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '24px',
            }}
          >
            <Box
              sx={{
                width: '154px',
                minWidth: '154px',
              }}
            >
              <DragAndDropFiles
                handleSetFiles={(files) => {
                  dispatch(
                    setApprovedData({
                      ...approvedData,
                      attachments: [...Array.from(files), ...approvedData.attachments],
                    }),
                  )
                }}
                uploading={false}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                gap: '24px',
                flexWrap: 'wrap',
              }}
            >
              {approvedData.attachments.map((item, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      minWidth: '45px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                      cursor: 'pointer',
                      maxHeight: '70px',
                    }}
                    onClick={() => {
                      //handleDownloadFile(item, index)
                    }}
                  >
                    <img
                      src={IconAppraisalDocument}
                      alt=""
                      style={{
                        width: '100%',
                      }}
                    />
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: '-4px',
                        right: '0px',
                        width: '20px',
                        height: '20px',
                        opacity: '1',
                        transition: '0.25s all ease-in-out',
                        '&:hover': {
                          transition: '0.25s all ease-in-out',
                          opacity: '1',
                        },
                      }}
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation()
                        dispatch(
                          setApprovedData({
                            ...approvedData,
                            attachments: approvedData.attachments.filter((item, i) => i !== index),
                          }),
                        )
                      }}
                    >
                      <CancelRounded
                        sx={{
                          fontSize: '18px',
                          fill: colorsConfig.mainRed,
                        }}
                      />
                    </IconButton>
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '30px' }}>
            <ButtonLG
              disabled={buttonDisabled}
              onClick={() => {
                handleSubmit()
              }}
            >
              Send insurance response
            </ButtonLG>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default InsuranceRequestFormAccepted
