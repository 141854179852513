import { createSlice } from '@reduxjs/toolkit'
import updateControlPointsBuilder from './builders/updateControlPoints.builder'
import getCarDetailsFromVINBuilder from './builders/getCarDetailsFromVIN.builder'
import updateCarBuilder from './builders/updateCar.builder'

const getNullableCarState = (value) => {
  if (value === undefined || value === null) return ''
  if (value === true) return 'Used'
  if (value === false) return 'New'
}

const initialState = {
  currentTab: 0,
  currentAppraisalTab: 0,
  auctionData: {
    company: null,
    '@id': null,
    bids: [],
    endTime: null,
    currentWorkflowState: null,
  },
  carAppraisalChecksResource: {
    vehicleDetailsCheck: true,
    documentsCheck: false,
    photosCheck: false,
    damagesCheck: false,
    warningLightsCheck: false,
    interiorConditionsCheck: false,
    mechanicalConditionsCheck: false,
  },
  dealId: null,
  car: null,
  carData: {
    '@id': null,
    referenceNumber: null,
    company: null,
    id: null,
    loaded: false,
    make: null,
    model: null,
    vin: null,
    isArchived: false,
    combinedStatus: '',
    isOnCompound: '',
    currentWorkflowState: null,
    mainPhoto: null,
    carMainSpecs: {
      type: '',
      details: '',
      bodyType: '',
      mileage: '',
      engineNumber: '',
      firstRegistrationYear: '',
      firstRegistrationMonth: '',
      seatsNumber: '',
      doorsNumber: '',
      gearsNumber: '',
      hp: '',
      engineType: '',
      gearbox: '',
      interiorColor: '',
      exteriorColor: '',
      upholstery: '',
      bookingValue: '',
      newPriceWithOptions: '',
      weight: '',
      additionalInfo: '',
      paintwork: '',
      wheelLocation: '',
      rims: '',
      origin: '',
      engineSize: '',
      cylinder: '',
      driveType: '',
      CO2Emission: '',
      fuelConsumption: '',
      energyEfficiencyCategory: '',
      euroNorm: '',
      carHiddenPriceShow: false,
      modelYear: '',
      carState: '',
      isModified: null,
    },
    foreshortenings: [],
    damages: [],
    controlPoints: [],
    pilotLamps: [],
  },
  appraisal: {
    currentTab: 0,
    deletedDamages: [],
    damagesUpdated: false,
    foreshorteningsUpdated: false,
    foreshortenings: [],
    damages: [],
    controlPoints: [],
    pilotLamps: [],
    documents: [],
    interiorConditions: [],
    mechanicalConditions: {
      testDrive: [],
      visualCheck: [],
    },
    loaded: false,
    updateControlPoints: {
      loading: false,
      error: null,
    },
  },
  vinProvider: {
    isLoading: false,
    error: null,
    data: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
}

export const carSlice = createSlice({
  name: 'car',
  initialState: initialState,
  reducers: {
    setCarData: (state, action) => {
      state.car = action.payload
      state.carData.make = action.payload.make
      state.carData.model = action.payload.model
      state.carData.vin = action.payload.vin
      state.carData.combinedStatus = action.payload.combinedStatus
        ? action.payload.combinedStatus
        : null
      state.carData.isOnCompound = action.payload?.isCompounded
      state.carData.isArchived = action.payload.isArchived ? action.payload.isArchived : false
      state.carData.carMainSpecs.details = action.payload.details
      state.carData.carMainSpecs.mileage = action.payload.mileage
      state.carData.carMainSpecs.engineNumber = action.payload.engineNumber
      state.carData.carMainSpecs.hp = action.payload.hp
      state.carData.carMainSpecs.gearbox = action.payload.gearbox
      state.carData.carMainSpecs.firstRegistrationYear = action.payload.firstRegistrationYear
      state.carData.carMainSpecs.firstRegistrationMonth = action.payload.firstRegistrationMonth
      state.carData.carMainSpecs.seatsNumber = action.payload.seatsNumber
      state.carData.carMainSpecs.type = action.payload.type
      state.carData.carMainSpecs.engineType = action.payload.engineType
      state.carData.carMainSpecs.bodyType = action.payload.bodyType
      state.carData.carMainSpecs.driveType = action.payload.driveType
      state.carData.carMainSpecs.cylinder = action.payload.cylinder
      state.carData.carMainSpecs.engineSize = action.payload.engineSize
      state.carData.carMainSpecs.rims = action.payload.rims
      state.carData.carMainSpecs.wheelLocation = action.payload.wheelLocation
      state.carData.carMainSpecs.origin = action.payload.origin
      state.carData.carMainSpecs.gearsNumber = action.payload.gearsNumber
      state.carData.carMainSpecs.doorsNumber = action.payload.doorsNumber
      state.carData.carMainSpecs.weight = action.payload.weight
      state.carData.carMainSpecs.interiorColor = action.payload.interiorColor
      state.carData.carMainSpecs.upholstery = action.payload.upholstery
      state.carData.carMainSpecs.exteriorColor = action.payload.exteriorColor
      state.carData.carMainSpecs.paintwork = action.payload.paintwork
      state.carData.carMainSpecs.newPriceWithOptions = action.payload.newPriceWithOptions
      state.carData.carMainSpecs.bookingValue = action.payload.bookingValue
      state.carData.carMainSpecs.additionalInfo = action.payload.additionalInfo
      state.carData.carMainSpecs.carHiddenPriceShow = action.payload.carHiddenPriceShow
      state.carData.id = action.payload.id
      state.carData.referenceNumber = action.payload.referenceNumber
        ? action.payload.referenceNumber
        : action.payload.id
      state.carData['@id'] = action.payload['@id']
      state.carData.currentWorkflowState = action.payload.currentWorkflowState
      state.carData.company = action.payload.company
      state.carData.mainPhoto = action.payload.mainForeshortening
        ? action.payload.mainForeshortening
        : null
      state.carData.carMainSpecs.CO2Emission = action.payload.CO2Emission
        ? action.payload.CO2Emission
        : null
      state.carData.carMainSpecs.fuelConsumption = action.payload.fuelConsumption
        ? parseFloat(parseFloat(action.payload.fuelConsumption).toFixed(1))
        : null
      state.carData.carMainSpecs.energyEfficiencyCategory = action.payload.energyEfficiencyCategory
        ? action.payload.energyEfficiencyCategory
        : null
      state.carData.carMainSpecs.euroNorm = action.payload.euroNorm ? action.payload.euroNorm : null
      state.carData.carMainSpecs.modelYear = action.payload?.modelYear?.toString() ?? ''
      state.carData.carMainSpecs.carState = getNullableCarState(action.payload?.isUsed)
      state.carData.carMainSpecs.isModified =
        action.payload?.isModified !== undefined ? action.payload?.isModified : null
      state.carData.independentCarInfo = action.payload.independentCarInfo
    },
    setCarDataPhotos: (state, action) => {
      state.carData.foreshortenings = action.payload
    },
    setAppraisal: (state, action) => {
      state.carData.foreshortenings = action.payload.foreshortenings
      state.carData.damages = action.payload.damages
      state.carData.pilotLamps = action.payload.pilotLamps
      state.carData.controlPoints = action.payload.controlPoints
      state.carData.loaded = true
    },
    setCarAppraisalChecksResource: (state, action) => {
      if (action.payload.hasOwnProperty('vehicleDetailsCheck')) {
        state.carAppraisalChecksResource.vehicleDetailsCheck = action.payload.vehicleDetailsCheck
      }
      if (action.payload.hasOwnProperty('documentsCheck')) {
        state.carAppraisalChecksResource.documentsCheck = action.payload.documentsCheck
      }
      if (action.payload.hasOwnProperty('photosCheck')) {
        state.carAppraisalChecksResource.photosCheck = action.payload.photosCheck
      }
      if (action.payload.hasOwnProperty('damagesCheck')) {
        state.carAppraisalChecksResource.damagesCheck = action.payload.damagesCheck
      }
      if (action.payload.hasOwnProperty('warningLightsCheck')) {
        state.carAppraisalChecksResource.warningLightsCheck = action.payload.warningLightsCheck
      }
      if (action.payload.hasOwnProperty('interiorConditionsCheck')) {
        state.carAppraisalChecksResource.interiorConditionsCheck =
          action.payload.interiorConditionsCheck
      }
      if (action.payload.hasOwnProperty('mechanicalConditionsCheck')) {
        state.carAppraisalChecksResource.mechanicalConditionsCheck =
          action.payload.mechanicalConditionsCheck
      }
    },
    setAppraisalTab: (state, action) => {
      state.currentAppraisalTab = action.payload
    },
    setPhotos: (state, action) => {
      state.appraisal.foreshortenings = action.payload.map((foreshortening) => {
        const matchCarForeshortening = state.carData.foreshortenings.filter(
          (item) => item.foreshortening && item.foreshortening.id === foreshortening.id,
        )[0]
        return {
          foreshortening: foreshortening['@id'],
          foreshorteningFile: foreshortening.file.path,
          carForeshortening: matchCarForeshortening ? matchCarForeshortening['@id'] : null,
          carPhoto: matchCarForeshortening ? matchCarForeshortening.photo : null,
          newCarPhoto: null,
          direct: matchCarForeshortening ? matchCarForeshortening.direct : 0,
          status: 'idle',
        }
      })
    },
    setDeletedDamages: (state, action) => {
      state.appraisal.deletedDamages = action.payload
    },
    setDamages: (state, action) => {
      // if (state.appraisal.deletedDamages.length > 0) {
      //     const damages = action.payload
      //     const deletedDamages = state.appraisal.deletedDamages
      //     const finalDamages = damages.filter(damage => {
      //         return !deletedDamages.filter(deletedDamage => {
      //             return damage.id.toString() === deletedDamage.id.toString() && state.carData.id.toString() === deletedDamage.carId.toString()
      //         })[0]
      //     })
      //     state.appraisal.damages = finalDamages
      // } else {
      const filteredDamages = action.payload.carDamages.filter((item) => item.damage)
      state.appraisal.damages = filteredDamages.map((carDamage) => {
        let damageName = ''
        const matchCarPart = action.payload.carParts.filter((carPart) => {
          const matchDamageId = carPart.damages.filter(
            (carPartDamage) => carPartDamage['@id'] === carDamage.damage,
          )[0]
          if (matchDamageId) {
            damageName = matchDamageId.name
          }
          return matchDamageId
        })[0]
        return {
          ...carDamage,
          damage: {
            '@id': carDamage.damage,
            name: damageName,
            part: {
              '@id': matchCarPart['@id'],
              name: matchCarPart.name,
            },
          },
        }
      })
      //}
    },
    setPilotLamps: (state, action) => {
      state.appraisal.pilotLamps = action.payload.map((pilotLampItem) => {
        const matchPilotLamp = state.carData.pilotLamps.filter(
          (lamp) => lamp.pilotLamp === pilotLampItem['@id'],
        )[0]
        return {
          newValue: null,
          lampViewIri: pilotLampItem['@id'],
          icon: pilotLampItem.icon.path,
          value: matchPilotLamp ? matchPilotLamp.value : null,
          lampIri: matchPilotLamp ? matchPilotLamp['@id'] : null,
          lampId: matchPilotLamp ? matchPilotLamp.id : null,
        }
      })
    },
    setControlPoints: (state, action) => {
      state.appraisal.controlPoints = action.payload.map((block) => {
        const subBlocks = block.subblocks.map((subBlock) => {
          const filterSingleFileControlPoints = subBlock.controlPoints.filter(
            (item) => item.type !== 'file',
          )
          const filteredControlPoints = filterSingleFileControlPoints.filter(
            (item) => item.distinction !== 'archived',
          )
          const controlPoints = filteredControlPoints.map((controlPointItem) => {
            let value = null
            const matchControlPoint = state.carData.controlPoints.filter(
              (cpItem) =>
                cpItem.controlPoint.toString() ===
                `/reference/appraisal/control-points/${controlPointItem.id}`,
            )[0]
            if (
              matchControlPoint &&
              (matchControlPoint.value === true || matchControlPoint.value === false)
            ) {
              value = matchControlPoint.value
            }
            if (controlPointItem.type === 'file_collection') {
              if (
                matchControlPoint &&
                matchControlPoint.files &&
                matchControlPoint.files.length > 0
              ) {
                return {
                  ...controlPointItem,
                  files: matchControlPoint.files,
                  value: null,
                  newFiles: [],
                  updated: false,
                  uploading: false,
                }
              } else {
                return {
                  ...controlPointItem,
                  files: [],
                  value: null,
                  updated: false,
                  uploading: false,
                }
              }
            }

            if (controlPointItem.type === 'boolean') {
              return {
                ...controlPointItem,
                value: value,
                updated: false,
              }
            }
          })
          return {
            ...subBlock,
            controlPoints: controlPoints,
          }
        })
        return {
          ...block,
          subblocks: subBlocks,
        }
      })
    },
    addCarPhoto: (state, action) => {
      state.appraisal.foreshortenings = action.payload
    },
    setCarPhotoStatus: (state, action) => {
      state.appraisal.foreshortenings[action.payload.index].status = action.payload.value
    },
    updatePhotos: (state, action) => {
      state.appraisal.foreshortenings = action.payload
    },
    updateSingleForeshortening: (state, action) => {
      state.appraisal.foreshortenings[action.payload.index].carForeshortening =
        action.payload.carForeshortening
      state.appraisal.foreshortenings[action.payload.index].updated = false
      state.appraisal.foreshortenings[action.payload.index].carPhotos = action.payload.photos
    },
    updateDeletedForeshortening: (state, action) => {
      state.appraisal.foreshortenings[action.payload.index].updated = false
      state.appraisal.foreshortenings[action.payload.index].deleted = false
      state.appraisal.foreshortenings[action.payload.index].direct = false
      state.appraisal.foreshortenings[action.payload.index].carPhotos = []
      state.appraisal.foreshortenings[action.payload.index].newCarPhotos = []
    },
    setMainForeshortening: (state, action) => {
      state.appraisal.foreshortenings.forEach((item) => {
        if (item.mainPhoto) {
          item.mainPhoto = null
        }
      })
      state.appraisal.foreshortenings[action.payload.index].mainPhoto = action.payload.mainPhoto
    },
    setDamagesUpdated: (state, action) => {
      state.appraisal.damagesUpdated = action.payload
    },
    updatePilotLamps: (state, action) => {
      state.appraisal.pilotLamps = state.appraisal.pilotLamps.map((oldLamp) => {
        if (oldLamp.lampViewIri === action.payload.lampViewIri) {
          return action.payload
        }
        return oldLamp
      })
    },
    updateControlPoint: (state, action) => {
      const data = action.payload
      state.appraisal.controlPoints[data.block].subblocks[data.subBlock].controlPoints[
        data.controlPoint
      ].value = data.newValue
      state.appraisal.controlPoints[data.block].subblocks[data.subBlock].controlPoints[
        data.controlPoint
      ].updated = true
      state.appraisal.controlPoints[data.block].subblocks[data.subBlock].controlPoints[
        data.controlPoint
      ].deleted = false
    },
    setControlPointFileCollection: (state, action) => {
      const data = action.payload
      state.appraisal.controlPoints[data.block].subblocks[data.subBlock].controlPoints[
        data.controlPoint
      ].files = data.files
    },
    deleteControlPointFile: (state, action) => {
      const data = action.payload
      const newFiles = state.appraisal.controlPoints[data.block].subblocks[
        data.subBlock
      ].controlPoints[data.controlPoint].files.filter((item, index) => data.fileIndex !== index)
      state.appraisal.controlPoints[data.block].subblocks[data.subBlock].controlPoints[
        data.controlPoint
      ].files = newFiles
      state.appraisal.controlPoints[data.block].subblocks[data.subBlock].controlPoints[
        data.controlPoint
      ].newValue = null
      state.appraisal.controlPoints[data.block].subblocks[data.subBlock].controlPoints[
        data.controlPoint
      ].file = null
      state.appraisal.controlPoints[data.block].subblocks[data.subBlock].controlPoints[
        data.controlPoint
      ].updated = true
      state.appraisal.controlPoints[data.block].subblocks[data.subBlock].controlPoints[
        data.controlPoint
      ].deleted = true
    },
    addDamage: (state, action) => {
      state.appraisal.damages = action.payload
    },
    deleteCarDamagePhoto: (state, action) => {
      state.appraisal.damages[action.payload.carDamageIndex].photos = action.payload.carDamagePhotos
    },
    setNewDamageId: (state, action) => {
      state.appraisal.damages[action.payload.index].id = action.payload.id
      state.appraisal.damages[action.payload.index].photos = action.payload.photos
      state.appraisal.damages[action.payload.index].uploading = false
      state.appraisal.damages[action.payload.index].newDamage = false
      state.appraisal.damages[action.payload.index].damage = action.payload.damage
      state.appraisal.damages[action.payload.index].damagePhotos = []
      state.appraisal.damages[action.payload.index]['@id'] = action.payload['@id']
    },
    setDamageLoadingState: (state, action) => {
      state.appraisal.damages[action.payload.index].uploading = action.payload.value
    },
    deleteDamage: (state, action) => {
      // window.localStorage.setItem('')
    },
    setWorkflowState: (state, action) => {
      state.carData.combinedStatus = action.payload
    },
    changeBid: (state, action) => {
      state.auctionData.companyBid = action.payload
    },
    setAuctionData: (state, action) => {
      state.auctionData['@id'] = action.payload['@id']
      state.auctionData.companyBid = action.payload.companyBid
      state.auctionData.currentWorkflowState = action.payload.currentWorkflowState
      state.auctionData.endTime = action.payload.endTime
      state.auctionData.company =
        action.payload.car.company && !Array.isArray(action.payload.car.company)
          ? action.payload.car.company
          : null
    },
    setArchive: (state, action) => {
      state.carData.isArchived = action.payload
    },
    setDealId: (state, action) => {
      state.dealId = action.payload
    },
    setDocuments: (state, action) => {
      state.appraisal.documents = action.payload
      state.appraisal.loaded = true
    },
    setAppraisalDocument: (state, action) => {
      const updateDocument = state.appraisal.documents.find(
        (item) => item['@id'] === action.payload.controlPoint,
      )
      if (updateDocument) {
        updateDocument.carControlPoint = action.payload.carControlPoint
      }
    },
    setDocumentFiles: (state, action) => {
      const updateDocument = state.appraisal.documents.find(
        (item) => item['@id'] === action.payload.controlPoint,
      )
      if (updateDocument) {
        updateDocument.carControlPoint.files = action.payload.files
      }
    },
    setInteriorConditions: (state, action) => {
      state.appraisal.interiorConditions = action.payload
    },
    setMechanicalConditions: (state, action) => {
      state.appraisal.mechanicalConditions = action.payload
    },
    updateMechanicalCondition: (state, action) => {
      action.payload.forEach((updatedCP) => {
        state.appraisal.mechanicalConditions.testDrive.forEach((subBlock) => {
          const matchCP = subBlock.controlPoints.find(
            (item) => item['@id'] === updatedCP.controlPoint,
          )
          if (matchCP) {
            matchCP.carControlPoint.value = updatedCP.value
          }
        })
        state.appraisal.mechanicalConditions.visualCheck.forEach((subBlock) => {
          const matchCP = subBlock.controlPoints.find(
            (item) => item['@id'] === updatedCP.controlPoint,
          )
          if (matchCP) {
            matchCP.carControlPoint.value = updatedCP.value
          }
        })
      })
    },
    updateInteriorCondition: (state, action) => {
      const updateControlPoint = state.appraisal.interiorConditions.find(
        (item) => item['@id'] === action.payload.controlPoint,
      )
      if (updateControlPoint) {
        updateControlPoint.carControlPoint.value = action.payload.value
      }
    },
    adjustCar: (state, action) => {
      state.car = action.payload
    },
    resetCar: (state) => {
      state.car = null
      state.carData = initialState.carData
      state.appraisal = initialState.appraisal
      state.auctionData = initialState.auctionData
      state.carAppraisalChecksResource = initialState.carAppraisalChecksResource
    },
  },
  extraReducers: (builder) => {
    updateControlPointsBuilder(builder)
    getCarDetailsFromVINBuilder(builder)
    updateCarBuilder(builder)
  },
})

export const {
  setCarData,
  setPhotos,
  setDamages,
  setPilotLamps,
  setControlPoints,
  addDamage,
  updatePilotLamps,
  updateControlPoint,
  setWorkflowState,
  setAuctionData,
  resetCar,
  setNewDamageId,
  setDeletedDamages,
  deleteControlPointFile,
  setAppraisal,
  addCarPhoto,
  setCarDataPhotos,
  setAppraisalTab,
  changeBid,
  setCarAppraisalChecksResource,
  deleteCarDamagePhoto,
  setControlPointFileCollection,
  setDocuments,
  setAppraisalDocument,
  setDocumentFiles,
  setInteriorConditions,
  setMechanicalConditions,
  updateMechanicalCondition,
  updateInteriorCondition,
  adjustCar,
} = carSlice.actions

export default carSlice.reducer
