import { activateWarranty } from '../warrantyApplication.actions'

export default (builder) => {
  builder
    .addCase(activateWarranty.pending, (state) => {
      state.warrantyDetails.isLoading = true
    })
    .addCase(activateWarranty.fulfilled, (state, action) => {
      state.warrantyDetails.isLoading = false
      state.warrantyDetails.saved = true
      state.warrantyDetails.error = action.payload
      state.warrantyApplication = action.payload
      state.showActivatedPopup = true
    })
    .addCase(activateWarranty.rejected, (state, action) => {
      state.warrantyDetails.isLoading = false
      state.warrantyDetails.error = action.payload
    })
}
