import carBodyTypeCompact from '../../assests/img/icon-car-compact.png'
import carBodyTypeConvertible from '../../assests/img/icon-car-convertible.png'
import carBodyTypeCoupe from '../../assests/img/icon-car-coupe.png'
import carBodyTypeOffRoad from '../../assests/img/icon-car-offRoad.png'
import carBodyTypeStationWagon from '../../assests/img/icon-car-stationWagon.png'
import carBodyTypeSedan from '../../assests/img/icon-car-sedan.png'
import carBodyTypeVan from '../../assests/img/icon-car-van.png'
import carBodyTypeTransporter from '../../assests/img/icon-car-transporter.png'
import carBodyTypeOther from '../../assests/img/icon-car-other.png'

export const bodyTypes = [
  { value: 'compact', label: 'Compact', img: carBodyTypeCompact },
  { value: 'convertible', label: 'Convertible', img: carBodyTypeConvertible },
  { value: 'coupe', label: 'Coupe', img: carBodyTypeCoupe },
  { value: 'off-road', label: 'SUV/Off-Road/Pick-up', img: carBodyTypeOffRoad },
  { value: 'station-wagon', label: 'Station wagon', img: carBodyTypeStationWagon },
  { value: 'sedan', label: 'Sedan', img: carBodyTypeSedan },
  { value: 'van', label: 'Van', img: carBodyTypeVan },
  { value: 'transporter', label: 'Transporter', img: carBodyTypeTransporter },
  { value: 'other', label: 'Other', img: carBodyTypeOther },
]

export const fuelTypes = [
  { value: 'gasoline', label: 'Gasoline' },
  { value: 'diesel', label: 'Diesel' },
  { value: 'ethanol', label: 'Ethanol' },
  { value: 'electric', label: 'Electric' },
  { value: 'hydrogen', label: 'Hydrogen' },
  { value: 'lpg', label: 'LPG' },
  { value: 'cng', label: 'CNG' },
  { value: 'hybrid-electric-gasoline', label: 'Hybrid (Electric/Gasoline' },
  { value: 'hybrid-electric-diesel', label: 'Hybrid (Electric/Diesel' },
  { value: 'others', label: 'Others' },
]

export const engineTypes = ['Petrol', 'Diesel', 'Hybrid', 'Plug-in Hybrid Electric', 'Electric']

export const rimsTypes = [
  'enums.rims.steel_wheels',
  'enums.rims.light_alloy_wheels',
  'enums.rims.forged_wheels',
  'enums.rims.carbon_wheels',
]

// export const cylinderTypes = [
//     {alignment: 'Straight engines', value: 'Single Cylinder', label: 'Single Cylinder'},
//     {alignment: 'Straight engines', value: 'Straight-2', label: 'Straight-2'},
//     {alignment: 'Straight engines', value: 'Straight-3', label: 'Straight-3'},
//     {alignment: 'Straight engines', value: 'Straight-4', label: 'Straight-4'},
//     {alignment: 'Straight engines', value: 'Straight-5', label: 'Straight-5'},
//     {alignment: 'Straight engines', value: 'Straight-6', label: 'Straight-6'},
//     {alignment: 'Straight engines', value: 'Straight-8', label: 'Straight-8'},
//     {alignment: 'V engines', value: 'V2', label: 'V2'},
//     {alignment: 'V engines', value: 'V3', label: 'V3'},
//     {alignment: 'V engines', value: 'V4', label: 'V4'},
//     {alignment: 'V engines', value: 'V6', label: 'V6'},
//     {alignment: 'V engines', value: 'V8', label: 'V8'},
//     {alignment: 'V engines', value: 'V10', label: 'V10'},
//     {alignment: 'V engines', value: 'V12', label: 'V12'},
//     {alignment: 'V engines', value: 'V14', label: 'V14'},
//     {alignment: 'V engines', value: 'V16', label: 'V16'},
//     {alignment: 'V engines', value: 'V18', label: 'V18'}
// ]
export const cylinderTypes = ['', '0', '1', '2', '3', '4', '5', '6', '8', '10', '12']

export const fuelConsumptionOptions = [
  '2.0',
  '2.5',
  '3.0',
  '3.5',
  '4.0',
  '4.5',
  '5.0',
  '5.5',
  '6.0',
  '6.5',
  '7.0',
  '7.5',
  '8.0',
  '8.5',
  '9.0',
  '9.5',
  '10.0',
  '10.5',
  '11.0',
  '11.5',
  '12.0',
  '13.0',
  '14.0',
  '15.0',
  '16.0',
  '18.0',
  '20.0',
  '25.0',
]

export const CO2EmissionOptions = [
  '50',
  '60',
  '70',
  '80',
  '90',
  '100',
  '110',
  '120',
  '130',
  '140',
  '150',
  '160',
  '170',
  '180',
  '190',
  '200',
  '210',
  '220',
  '230',
  '240',
  '250',
  '260',
  '280',
  '300',
  '320',
  '340',
  '360',
  '380',
  '400',
  '420',
  '440',
  '460',
  '480',
  '500',
  '520',
  '540',
  '560',
]
