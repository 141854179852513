import React, { useState } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import TextNormal from '../../ui/Text/TextNormal'
import BankLogo from '../../assests/img/bank-example.svg'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import { colorsConfig } from '../../config/themeConfig'
import { sumDelimiter } from '../../utils/sumDelimiter'
import ButtonHollow from '../../ui/Buttons/ButtonHollow'
import FinanceCustomerApproveLoanDealModal from '../../components/Modals/FinanceCustomerApproveLoanDealModal'
import { publicPatchRequest } from '../../utils/request'
import { API_FINANCE_ENDPOINTS } from '../../config/default'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import loanDealService from '../../api/finance/loanDealService/loanDealService'

const CustomerBankResponsesItem = (props) => {
  const { loanDeal, loanApplication, banks, hasCustomerSelectedOffer } = props

  const navigate = useNavigate()
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const [confirmationModal, setConfirmationModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const getDownPaymentPercentage = () => {
    return Math.round((parseInt(loanDeal.dp) * 100) / parseInt(loanApplication?.carPrice))
  }

  const handleApproveDeal = async () => {
    try {
      setIsLoading(true)
      await loanDealService.customerApprove(loanApplication.secureHash, loanDeal.id)
      toast.success(
        'Your approval has been sent to the dealer and the bank. Soon you will be informed with the next steps',
      )
      setConfirmationModal(false)
      navigate('/')
    } catch (e) {
      if (!e.response) {
        toast.error('Unknown error')
        return
      }
      toast.error(e.response.data['hydra:description'])
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box
      className={[
        'approved',
        hasCustomerSelectedOffer && !loanDeal.isCustomerSelected ? 'disabled' : '',
      ].join(' ')}
      sx={{
        borderRadius: '6px',
        boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
        overflow: 'hidden',
        p: '15px',
        position: 'relative',
        '&::after': {
          content: '" "',
          pointerEvents: 'none',
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '50px',
          top: 0,
          left: 0,
        },
        '&.approved': {
          '&::after': {
            background:
              'linear-gradient(180deg, rgba(77, 237, 109, 0.15) 0%, rgba(77, 237, 109, 0.00) 100%)',
          },
        },
        '&.disabled': {
          '&::after': {
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            background: 'rgba(255,255,255, 0.5)',
            borderRadius: '6px',
            zIndex: 10,
          },
        },
      }}
    >
      {hasCustomerSelectedOffer && loanDeal?.isCustomerSelected ? (
        <TextBoldNormal
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            position: 'absolute',
            top: '21px',
            right: '27px',
            color: colorsConfig.mainGreen,
            '&.xs-only': {
              position: 'static',
              top: 'auto',
              left: 'auto',
            },
          }}
        >
          Your choice
        </TextBoldNormal>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '&.xs-only': {
              display: 'block',
            },
          }}
        >
          <img
            src={
              banks.filter((item) => item.id.toString() === loanDeal.bankId)[0]?.logo || BankLogo
            }
            alt=""
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '4px',
            }}
          />
          <TextBoldNormal
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              ml: '10px',
              '&.xs-only': {
                ml: 0,
              },
            }}
          >
            {loanDeal.bankTitle}:
          </TextBoldNormal>
          <TextBoldNormal
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              ml: '6px',
              color: colorsConfig.mainBlue,
              '&.xs-only': {
                ml: 0,
              },
            }}
          >
            {loanDeal?.offerName || loanDeal?.title || ''}
          </TextBoldNormal>
          {!xsOnly ? (
            <TextBoldNormal
              className={loanDeal.currentWorkflowState}
              sx={{
                ml: '10px',
                height: '24px',
                px: '10px',
                borderRadius: '100px',
                color: 'white',
                fontSize: '14px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                '&.approved': {
                  background: colorsConfig.mainGreen,
                },
                '&.bank_request_info': {
                  background: colorsConfig.buttonYellow,
                },
                '&.bank_declined': {
                  background: colorsConfig.mainRed,
                },
              }}
            >
              Accepted
            </TextBoldNormal>
          ) : null}
        </Box>
      </Box>
      <Box
        sx={{
          mt: '24px',
          display: 'flex',
          flexDirection: 'row',
          gap: '24px',
          flexWrap: 'wrap',
        }}
      >
        <Box>
          <TextBoldNormal
            sx={{
              fontSize: '24px',
            }}
          >
            {sumDelimiter(Math.round(loanDeal.monthlyPayment)).replace("'", ' ')} AED
          </TextBoldNormal>
          <TextNormal>Total monthly instalment</TextNormal>
        </Box>
        <Box>
          <TextBoldNormal
            sx={{
              mt: '4px',
              mb: '6px',
            }}
          >
            {loanDeal?.term} month
          </TextBoldNormal>
          <TextNormal>Loan term</TextNormal>
        </Box>
        <Box>
          <TextBoldNormal
            sx={{
              mt: '4px',
              mb: '6px',
            }}
          >
            {getDownPaymentPercentage()}%
          </TextBoldNormal>
          <TextNormal>Down Payment</TextNormal>
        </Box>
        <Box>
          <TextBoldNormal
            sx={{
              mt: '4px',
              mb: '6px',
            }}
          >
            {loanDeal?.rvWarranty ? loanDeal?.approvedParamsData?.rvWarranty : '-'}
          </TextBoldNormal>
          <TextNormal>Residual value</TextNormal>
        </Box>
        <Box>
          <TextBoldNormal
            sx={{
              mt: '4px',
              mb: '6px',
            }}
          >
            {loanDeal.rate}%
          </TextBoldNormal>
          <TextNormal>Interest rate</TextNormal>
        </Box>
        <Box>
          <TextBoldNormal
            sx={{
              mt: '4px',
              mb: '6px',
            }}
          >
            {sumDelimiter(Math.round(loanDeal?.loanAmount)).replace("'", ' ')} AED
          </TextBoldNormal>
          <TextNormal>Total amount</TextNormal>
        </Box>
      </Box>
      {!hasCustomerSelectedOffer ? (
        <ButtonHollow
          sx={{
            position: 'absolute',
            width: '108px',
            right: '15px',
            bottom: '21px',
          }}
          onClick={() => {
            setConfirmationModal(true)
          }}
        >
          Approve
        </ButtonHollow>
      ) : null}
      <FinanceCustomerApproveLoanDealModal
        open={confirmationModal}
        setOpen={setConfirmationModal}
        handleAction={handleApproveDeal}
        submitButtonDisabled={isLoading}
      />
    </Box>
  )
}

export default CustomerBankResponsesItem
