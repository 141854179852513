export const parseControlPointsDocuments = (refControlPoints, carControlPoints) => {
  const documentsControlPoints = refControlPoints.filter(
    (item) => item?.parent?.sysName === 'documents',
  )
  return documentsControlPoints.map((refControlPoint) => {
    const matchCarControlPoint = carControlPoints.find(
      (carControlPoint) => carControlPoint?.controlPoint === refControlPoint['@id'],
    )
    return {
      ...refControlPoint,
      carControlPoint: matchCarControlPoint || { files: [] },
    }
  })
}

export const parseControlPointsInteriorConditions = (refControlPoints, carControlPoints) => {
  const interiorConditionsControlPoints = refControlPoints.filter(
    (item) => item?.parent?.sysName === 'interior_conditions',
  )
  return interiorConditionsControlPoints.map((refControlPoint) => {
    const matchCarControlPoint = carControlPoints.find(
      (carControlPoint) => carControlPoint?.controlPoint === refControlPoint['@id'],
    )
    return {
      ...refControlPoint,
      carControlPoint: matchCarControlPoint || { value: null },
    }
  })
}

export const parseMechanicalConditions = (refControlPoints, carControlPoints) => {
  const testDriveSubBlocks = refControlPoints.filter(
    (item) => item?.parent?.sysName === 'test_drive',
  )
  const visualCheckSubBlocks = refControlPoints.filter(
    (item) => item?.parent?.sysName === 'visual_check',
  )
  const testDriveSubBlocksExtended = testDriveSubBlocks.map((item) => {
    return {
      ...item,
      controlPoints: refControlPoints
        .filter((cp) => cp?.parent?.sysName === item?.sysName)
        .map((cp) => {
          const matchCarControlPoint = carControlPoints.find(
            (ccp) => ccp?.controlPoint === cp?.['@id'],
          )
          return {
            ...cp,
            carControlPoint: matchCarControlPoint ?? { value: null },
          }
        }),
    }
  })
  const visualCheckSubBlocksExtended = visualCheckSubBlocks.map((item) => {
    return {
      ...item,
      controlPoints: refControlPoints
        .filter((cp) => cp?.parent?.sysName === item?.sysName)
        .map((cp) => {
          const matchCarControlPoint = carControlPoints.find(
            (ccp) => ccp?.controlPoint === cp?.['@id'],
          )
          return {
            ...cp,
            carControlPoint: matchCarControlPoint ?? { value: null },
          }
        }),
    }
  })
  console.log({
    testDrive: testDriveSubBlocksExtended,
    visualCheck: visualCheckSubBlocksExtended,
  })
  return {
    testDrive: testDriveSubBlocksExtended,
    visualCheck: visualCheckSubBlocksExtended,
  }
}
