import { createSlice } from '@reduxjs/toolkit'
import createCompanyAddressBuilder from './builders/createCompanyAddress.builder'
import getCompanyAddressesBuilder from './builders/getCompanyAddresses.builder'
import updateCompanyAddressBuilder from './builders/updateCompanyAddress.builder'
import deleteCompanyAddressBuilder from './builders/deleteCompanyAddress.builder'
import updateTradeLicenseBuilder from './builders/updateTradeLicense.builder'

const initialState = {
  companyAddresses: {
    isLoading: false,
    error: null,
    data: [],
    params: {
      pagination: false,
      isPrimary: false,
      isMain: true,
    },
    create: {
      isLoading: false,
      error: null,
    },
    update: {
      isLoading: false,
      error: null,
    },
    delete: {
      isLoading: false,
      error: null,
    },
  },
  tradeLicense: {
    update: {
      isLoading: false,
      error: null,
    },
  },
}

const settingsSlice = createSlice({
  name: 'settingsReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createCompanyAddressBuilder(builder)
    getCompanyAddressesBuilder(builder)
    updateCompanyAddressBuilder(builder)
    deleteCompanyAddressBuilder(builder)
    updateTradeLicenseBuilder(builder)
  },
})

export default settingsSlice.reducer
