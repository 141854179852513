import React, { useEffect, useState } from 'react'
import { Box, Button, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import IconCrossRed from '../../../assests/img/icons/icon-cross-red.svg'
import TextNormal from '../../../ui/Text/TextNormal'
import TextAreaSmall from '../../../ui/TextAreaSmall'
import { useSelector, useDispatch } from 'react-redux'
import {
  setSubBlocks,
  updateSubBlock,
  rejectSubBlock,
} from '../../../store/features/qualityCheckSlice'

const QualityCheckSubblock = (props) => {
  const { setBlockExpanded, blockExpanded, subBlockId, subBlockIndex } = props

  const qualityCheckSubBlock = useSelector((state) => state.qualityCheck.subBlocks[subBlockIndex])
  const dispatch = useDispatch()

  const [comment, setComment] = useState('')
  const [firstRender, setFirstRender] = useState(false)

  const handleReject = () => {
    setBlockExpanded(!blockExpanded)
    dispatch(
      rejectSubBlock({
        index: subBlockIndex,
        value: !qualityCheckSubBlock.approved,
      }),
    )
  }

  const handleSetComment = (value) => {
    dispatch(
      updateSubBlock({
        index: subBlockIndex,
        value: value,
      }),
    )
  }

  useEffect(() => {
    setComment(qualityCheckSubBlock.hint)
  }, [])

  // useEffect(() => {
  //     if (!blockExpanded && firstRender) {
  //         let foundSubBlock = false
  //         const newSubBlocks = qualityCheckSubBlocks.map(item => {
  //             const matchSubBlock = qualityCheckSubBlocks.filter(item => item['@id'] === subBlockId)[0]
  //             if (matchSubBlock) {
  //                 foundSubBlock = true
  //                 return {
  //                     id: subBlockId,
  //                     hint: comment
  //                 }
  //             }
  //             return item
  //         })
  //         if (!foundSubBlock) {
  //             newSubBlocks.push({id: subBlockId, hint: comment})
  //         }
  //         dispatch(setSubBlocks(newSubBlocks))
  //     }
  //     if (!firstRender) {
  //         setFirstRender(true)
  //     }
  // }, [blockExpanded])

  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        width: '35%',
        // marginTop: xsOnly ? '5.5px' : ''
        marginTop: xsOnly ? '13px' : '',
      }}
    >
      <Button
        sx={{
          mb: xsOnly ? 0 : '10px',
          textTransform: 'none',
          '&:hover': {
            '& .button-icon-cross': {
              transition: '0.25s all ease-in-out',
              borderColor: colorsConfig.mainRed,
              '& img': {
                filter: 'grayscale(0%)',
                transition: '0.25s all ease-in-out',
              },
            },
          },
        }}
        onClick={() => {
          handleReject()
        }}
      >
        <Box
          className={`button-icon-cross ${!qualityCheckSubBlock.approved ? 'active' : ''}`}
          sx={{
            border: '1px solid',
            borderColor: colorsConfig.iconGray,
            width: '20px',
            height: '20px',
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            mr: '5px',
            alignItems: 'center',
            '& img': {
              transition: '0.25s all ease-in-out',
              filter: 'grayscale(100%)',
            },
            cursor: 'pointer',
            transition: '0.25s all ease-in-out',
            '&.active': {
              transition: '0.25s all ease-in-out',
              borderColor: colorsConfig.mainRed,
              '& img': {
                filter: 'grayscale(0%)',
                transition: '0.25s all ease-in-out',
              },
            },
          }}
        >
          <img src={IconCrossRed} alt="" />
        </Box>
        <TextNormal>Reject</TextNormal>
      </Button>
      {blockExpanded ? (
        <TextAreaSmall
          fullWidth
          multiline
          rows={4}
          placeholder={'Write the reason of rejection'}
          label={'Comment'}
          value={qualityCheckSubBlock.hint}
          onChange={(e) => {
            handleSetComment(e.target.value)
          }}
        />
      ) : null}
    </Box>
  )
}

export default QualityCheckSubblock
