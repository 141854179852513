import React, { useState, useEffect } from 'react'

const useCheckAllErrors = (values) => {
  const [hasErrors, setHasErrors] = useState(false)

  useEffect(() => {
    const errors = values.filter((item) => item !== false)
    setHasErrors(errors.length !== 0)
  }, values)

  return hasErrors
}

export default useCheckAllErrors
