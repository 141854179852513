import { updateControlPoints } from '../car.actions'

export default (builder) => {
  builder
    .addCase(updateControlPoints.pending, (state) => {
      state.appraisal.updateControlPoints.loading = true
    })
    .addCase(updateControlPoints.fulfilled, (state) => {
      state.appraisal.updateControlPoints.loading = false
      state.appraisal.updateControlPoints.error = null
    })
    .addCase((state, action) => {
      state.appraisal.updateControlPoints.loading = false
      state.appraisal.updateControlPoints.error = action.payload
    })
}
