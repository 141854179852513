import React from 'react'
import AppraisalDocumentDownload from '../../assests/img/icons/icon-appraisal-download.svg'
import { Button } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const UIButtonDownloadAppraisal = ({ sx, ...props }) => {
  return (
    <Button
      sx={{
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        px: '15px !important',
        fontFamily: '"Helvetica", sans-serif',
        textTransform: 'none',
        fontWeight: 700,
        fontSize: '16px',
        py: '0px',
        height: '40px',
        borderRadius: '8px',
        minHeight: 0,
        color: colorsConfig.mainBlue,
        ...sx,
      }}
      {...props}
    >
      <img style={{ width: '35px' }} src={AppraisalDocumentDownload} alt="" />
      Appraisal document
    </Button>
  )
}

export default UIButtonDownloadAppraisal
