import React from 'react'
import { TableCell } from '@mui/material'

const UITableBodyCell = ({ sx, children, ...props }) => {
  return (
    <TableCell
      sx={{
        border: 'none',
        paddingTop: '12px',
        paddingBottom: '12px',
        '&:first-of-type': {
          paddingLeft: 0,
        },
        '&:last-of-type': {
          paddingRight: 0,
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </TableCell>
  )
}

export default UITableBodyCell
