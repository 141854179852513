import { createSlice } from '@reduxjs/toolkit'
import getEntriesBuilder from './builders/getEntries.builder'
import updateEntryBuilder from './builders/updateEntry.builder'
import createEntryBuilder from './builders/createEntry.builder'

const initialState = {
  isLoading: false,
  error: null,
  data: [],
  params: {
    pagination: false,
  },
  total: 0,
  create: {
    isLoading: false,
    error: null,
  },
  update: {
    id: null,
    isLoading: false,
    error: null,
  },
  delete: {
    id: null,
    isLoading: false,
    error: null,
  },
}

const residualValueMatrixEntriesSlice = createSlice({
  name: 'residualValueMatrixEntriesReducer',
  initialState,
  reducers: {
    setResidualValueMatrixEntriesParams: (state, action) => {
      state.params = action.payload
    },
  },
  extraReducers: (builder) => {
    getEntriesBuilder(builder)
    updateEntryBuilder(builder)
    createEntryBuilder(builder)
  },
})

export const { setResidualValueMatrixEntriesParams } = residualValueMatrixEntriesSlice.actions

export default residualValueMatrixEntriesSlice.reducer
