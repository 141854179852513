import axios from 'axios'
import { API_BASE_URL, CDN_BASE_URL } from '../config/default'
import { cleanObject } from '../utils/helpers/common.helpers'

export const IUBENDA_BASE_URL = 'https://www.iubenda.com'

export const $api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const apiConstants = {
  hydraMember: 'hydra:member',
  hydraDescription: 'hydra:description',
  hydraTotal: 'hydra:totalItems',
}

export const putHeaders = {
  'Content-Type': 'application/ld+json',
}

export const patchHeaders = {
  'Content-Type': 'application/merge-patch+json',
}

export const customerAppraisalHeaders = (token) => {
  return {
    'X-Appraisal-Token': token,
    'Content-Type': 'application/ld+json',
  }
}

export const multiPartHeaders = {
  'Content-Type': 'multipart/form-data',
}

export const filterParams = (params) => {
  return Object.keys(params)
    .filter((key) => params[key] !== null && params[key] !== undefined && params[key] !== '')
    .reduce((obj, key) => {
      obj[key] = params[key]
      return obj
    }, {})
}

export const getApiError = (e, placeholder = 'Unknown error') => {
  return e?.response?.data?.[apiConstants.hydraDescription] ?? placeholder
}

export const adaptParams = (params) => {
  const filteredParams = cleanObject(params)
  if (filteredParams?.sort && filteredParams?.sort?.key && filteredParams.sort?.order) {
    filteredParams[`order[${filteredParams.sort?.key}]`] = filteredParams.sort?.order
  }
  delete filteredParams.sort
  return filteredParams
}

export const getCDNFileLink = (path) => `${CDN_BASE_URL}${path}`

export const iubendaApi = axios.create({
  baseURL: 'https://www.iubenda.com/api/',
})

export const zippopotam = axios.create({
  baseURL: 'https://api.zippopotam.us',
})
