import { createSlice } from '@reduxjs/toolkit'
import getCompanyBuilder from './builders/getCompany.builder'
import updateCompanyBuilder from './builders/updateCompany.builder'

const initialState = {
  isLoading: false,
  error: false,
  data: null,
  create: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
  delete: {
    isLoading: false,
    error: null,
  },
}

const companySlice = createSlice({
  name: 'companyReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getCompanyBuilder(builder)
    updateCompanyBuilder(builder)
  },
})

export default companySlice.reducer
