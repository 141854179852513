import React from 'react'
import UIModal from '../../../ui/UIModal/UIModal'
import { Stack } from '@mui/material'
import UITextHeadlineLarge from '../../../ui/UIText/UITextHeadlineLarge'
import ManageLocationForm from '../../Forms/FormTemplates/ManageLocationForm/ManageLocationForm'

const EditLocationModal = ({ open, setOpen, onSubmit, data }) => {
  return (
    <UIModal width="800px" open={open} setOpen={setOpen}>
      <Stack gap="30px">
        <UITextHeadlineLarge text="modal_title_change_address" />
        <ManageLocationForm initialData={data} onClose={() => setOpen(false)} onSubmit={onSubmit} />
      </Stack>
    </UIModal>
  )
}

export default EditLocationModal
