import React, { useEffect, useState } from 'react'
import UIModal from '../../../ui/UIModal/UIModal'
import { Box, Button, InputAdornment, Stack } from '@mui/material'
import UITextHeadlineSmall from '../../../ui/UIText/UITextHeadlineSmall'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import { isValidEmail } from '../../../utils/helpers/validation.helpers'
import { useDispatch, useSelector } from 'react-redux'
import {
  createCustomerAppraisal,
  sendAppraisalLinkByEmail,
} from '../../../store/features/customerAppraisal/customerAppraisal.actions'
import { colorsConfig } from '../../../config/themeConfig'
import { ContentCopy } from '@mui/icons-material'
import TextNormal from '../../../ui/Text/TextNormal'
import { APP_BASE_URL } from '../../../config/default'
import UIPreloader from '../../../ui/UIPreloader/UIPreloader'
import { copyToClipboard } from '../../../utils/helpers/common.helpers'
import { toast } from 'react-toastify'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import ButtonLinkWA from '../../Buttons/Social/ButtonLinkWA'
import ButtonLinkTG from '../../Buttons/Social/ButtonLinkTG'
import ButtonLinkMail from '../../Buttons/Social/ButtonLinkMail'

const customerAppraisalTextWA = (link) =>
  `Dear customer, \n\n` +
  'Please follow the link below to start the appraisal for your vehicle. It will help us to fairly estimate its value and give you a proper feedback \n\n' +
  link

const customerAppraisalTextTG =
  `Dear customer, \n\n` +
  'Please follow the attached link to start the appraisal for your vehicle. This will help us to fairly estimate its value and give you a proper feedback'

const ResendCustomerAppraisalLinkModal = ({ open, setOpen, customerAppraisal, isSendingEmail }) => {
  const dispatch = useDispatch()

  const appraisalToken = customerAppraisal?.appraisalToken?.token
  const appraisalLink = `${window.location.protocol}//${window.location.host}/customer/car-appraisal/${appraisalToken}`

  const [linkCopySuccess, setLinkCopySuccess] = useState(false)

  const handleCopyLink = async () => {
    await copyToClipboard(appraisalLink)
    setLinkCopySuccess(true)
    setTimeout(() => {
      setLinkCopySuccess(false)
    }, 3000)
  }

  const handleSendEmail = async () => {
    const res = await dispatch(sendAppraisalLinkByEmail({ idUrl: customerAppraisal?.id }))
    if (sendAppraisalLinkByEmail.fulfilled.match(res)) {
      toast.success('E-mail with appraisal link has been sent successfully')
      setOpen(false)
    }
  }

  return (
    <UIModal width="600px" open={open} setOpen={setOpen}>
      {isSendingEmail && <UIPreloader text="Sending the e-mail..." />}
      <Stack gap={{ xs: '24px', md: '30px' }}>
        <UITextHeadlineSmall text="Send the link to the customer" />
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <TextFieldSmallEdible
            label="Appraisal link"
            fullWidth
            value={appraisalLink}
            readOnly
            sx={{
              '& .MuiInputBase-root': {
                pr: 0,
              },
            }}
            onFocus={(event) => {
              event.target.select()
            }}
            inputProps={{
              readOnly: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'}>
                  <Button
                    sx={{
                      textTransform: 'none',
                      background: colorsConfig.mainBlue,
                      color: 'white',
                      borderRadius: '0 6px 6px 0',
                      transition: '0.25s all ease-in-out',
                      '&:hover': {
                        background: colorsConfig.buttonHover,
                        transition: '0.25s all ease-in-out',
                      },
                    }}
                    onClick={() => handleCopyLink()}
                  >
                    <ContentCopy
                      sx={{
                        transition: '0.25s all ease-in-out',
                        fill: 'white',
                      }}
                    />
                    <TextNormal
                      sx={{
                        fontSize: '14px',
                        color: 'white',
                        ml: '5px',
                        transition: '0.25s all ease-in-out',
                      }}
                    >
                      Copy
                    </TextNormal>
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          <TextNormal
            className={linkCopySuccess ? 'success' : ''}
            sx={{
              color: colorsConfig.mainGreen,
              my: '5px',
              fontSize: '12px',
              position: 'absolute',
              left: 0,
              top: '100%',
              opacity: 0,
              transition: '0.25s all ease-in-out',
              '&.success': {
                opacity: 1,
                transition: '0.25s all ease-in-out',
              },
            }}
          >
            The link has been copied to your clipboard
          </TextNormal>
        </Box>
        <Stack gap="10px">
          <UITextRegular text="Or share via:" />
          <Stack direction="row" gap="8px">
            <ButtonLinkWA message={customerAppraisalTextWA(appraisalLink)} />
            <ButtonLinkTG message={customerAppraisalTextTG} link={appraisalLink} />
            <ButtonLinkMail handleSendEmail={handleSendEmail} />
          </Stack>
        </Stack>
      </Stack>
    </UIModal>
  )
}

export default ResendCustomerAppraisalLinkModal
