import React, { useState } from 'react'
import { Box } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import { updateCoinPricing } from '../../../store/features/coinPricings/coinPricings.actions'
import TextNormal from '../../../ui/Text/TextNormal'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import ButtonBlueSmall from '../../../ui/Buttons/ButtonBlueSmall'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import IconCoin from '../../../assests/img/icons/icon-fleetify-coin.svg'
import { colorsConfig } from '../../../config/themeConfig'
import { COIN_PRICING_NAMES } from './coinPricings.constants'

const CoinPricingsItem = ({ data }) => {
  const isLoading = useSelector((state) => state.coinPricings.updateCoinPricing.isLoading)
  const dispatch = useDispatch()
  const [editMode, setEditMode] = useState(false)
  const [price, setPrice] = useState('0')
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)

  const handleUpdateCoinPricing = async () => {
    const response = await dispatch(
      updateCoinPricing({ coinPricing: data?.['@id'], data: { amount: price } }),
    )
    if (updateCoinPricing.fulfilled.match(response)) {
      setEditMode(false)
    }
    // setSaveButtonDisabled(true)
    // privateRequest
    //   .put(data['@id'], { amount: parseInt(price) })
    //   .then(() => {
    //     toast.success('Coin pricing has been updated')
    //     setEditMode(false)
    //   })
    //   .finally(() => {
    //     setSaveButtonDisabled(false)
    //   })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid',
        borderColor: colorsConfig.lightGray,
        py: '10px',
        '&:last-child': {
          border: 'none',
          pb: 0,
        },
        '&:first-child': {
          pt: 0,
        },
      }}
    >
      <TextNormal
        sx={{
          fontSize: '20px',
        }}
      >
        {COIN_PRICING_NAMES[data?.identifier] || data?.identifier}
      </TextNormal>
      {editMode ? (
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
          }}
        >
          <TextFieldSmallEdible
            label={'Price'}
            value={price}
            disabled={isLoading}
            onChange={(e) => {
              setPrice(e.target.value.replace(/[^0-9]/g, ''))
            }}
          />
          <ButtonBlueSmall
            disabled={isLoading}
            onClick={() => {
              handleUpdateCoinPricing()
            }}
          >
            Save
          </ButtonBlueSmall>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <TextBoldNormal
            sx={{
              fontSize: '20px',
            }}
          >
            {data?.amount}
          </TextBoldNormal>
          <img src={IconCoin} alt="" />
          <ButtonBlueSmall
            sx={{
              '&:hover': {
                color: 'white',
                background: colorsConfig.mainBlue,
                transition: '0.25s all ease-in-out',
                '& svg': {
                  fill: 'white',
                  transition: '0.25s all ease-in-out',
                },
              },
            }}
            onClick={() => {
              setEditMode(true)
              setPrice(data?.amount)
            }}
          >
            <Edit
              sx={{
                fill: colorsConfig.mainBlue,
                fontSize: '20px',
                mr: '5px',
                transition: '0.25s all ease-in-out',
              }}
            />
            Edit
          </ButtonBlueSmall>
        </Box>
      )}
    </Box>
  )
}

export default CoinPricingsItem
