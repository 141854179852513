import React, { useState } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import TextH1 from '../../ui/Text/TextH1'
import TextNormal from '../../ui/Text/TextNormal'
import IconHome from '../../assests/img/icons/icon-home-blue.svg'
import IconVirtualGarage from '../../assests/img/icons/icon-virtual-garage-blue.svg'
import IconAuctions from '../../assests/img/icons/icon-auctions-blue.svg'
import IconFinancing from '../../assests/img/icons/icon-financing-blue.svg'
import IconCarSubscription from '../../assests/img/icons/icon-car-subscription-blue.svg'
import IconStockFinancing from '../../assests/img/icons/icon-stock-financing-blue.svg'
import IconCarWarranty from '../../assests/img/icons/icon-car-warranty-blue.svg'
import IconInsurance from '../../assests/img/icons/icon-insurance-blue.svg'
import ButtonRoundedSmall from '../../ui/Buttons/ButtonRoundedSmall'
import SignIn from '../SignIn'
import ModalWrap from '../../components/Modals/ModalWrap'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ButtonLG from '../../ui/Buttons/ButtonLG'
import SignUp from '../SignUp'
import PasswordRecovery from '../PasswordRecovery'

const GreenPageServices = () => {
  const auth = useSelector((state) => state.auth.isAuthenticated)
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const navigate = useNavigate()

  const [authPage, setAuthPage] = useState('sign-in')

  const services = [
    {
      title: 'Dashboard',
      icon: IconHome,
      link: '/dashboard',
    },
    {
      title: 'Virtual Garage',
      icon: IconVirtualGarage,
      link: '/',
    },
    {
      title: 'Auctions',
      icon: IconAuctions,
      link: '/auctions',
    },
    {
      title: 'Financing',
      icon: IconFinancing,
      link: '/financing',
    },
    {
      title: 'Car Subscription',
      icon: IconCarSubscription,
      link: '/car-subscription',
    },
    {
      title: 'Stock Financing',
      icon: IconStockFinancing,
      link: '/stock-financing',
    },
    {
      title: 'Car Warranty',
      icon: IconCarWarranty,
      link: '/car-warranty',
    },
    {
      title: 'Insurance',
      icon: IconInsurance,
      link: '/insurance',
    },
  ]

  const [signInModal, setSignInModal] = useState(false)

  return (
    <Box
      sx={{
        pt: '50px',
        pb: '120px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <TextH1 sx={{ mb: '10px' }}>Automotive Services with a Conscience</TextH1>
      <TextNormal sx={{ mb: '30px' }}>
        Beyond buying and selling, we offer a range of eco-conscious automotive services.
      </TextNormal>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '16px',
          maxWidth: '800px',
          mx: 'auto',
        }}
      >
        {services.map((item, index) => {
          return (
            <ButtonRoundedSmall
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '13px',
              }}
              key={index}
              onClick={() => {
                if (auth) {
                  navigate(item.link)
                } else {
                  setSignInModal(true)
                }
              }}
            >
              <img src={item.icon} alt="" />
              {item.title}
            </ButtonRoundedSmall>
          )
        })}
      </Box>
      <Box
        sx={{
          mt: '60px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ButtonLG
          onClick={() => {
            if (auth) {
              navigate(-1)
              return
            }
            navigate('/')
          }}
        >
          Back to Fleatify
        </ButtonLG>
      </Box>
      <ModalWrap
        open={signInModal}
        setOpen={setSignInModal}
        className={xsOnly ? 'xs-only' : ''}
        wrapperStyles={{
          width: {
            xs: 'calc(100% - 30px)',
            sm: authPage === 'sign-up' ? '1000px' : '600px',
          },
          px: {
            xs: '15px',
            md: 0,
          },
          background: 'white',
        }}
      >
        <Box
          sx={{
            mt: '10px',
            position: 'relative',
            display: 'flex',
          }}
        >
          {authPage === 'sign-in' ? (
            <SignIn
              handleSignUp={() => {
                setAuthPage('sign-up')
              }}
              handleForgotPassword={() => {
                setAuthPage('forgot-password')
              }}
            />
          ) : null}
          {authPage === 'sign-up' ? (
            <Box sx={{ width: '100%', px: '40px' }}>
              <TextH1 sx={{ mb: '20px', ml: '35px' }}>Sign Up</TextH1>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <SignUp
                  handleSignIn={() => {
                    setAuthPage('sign-in')
                  }}
                />
              </Box>
            </Box>
          ) : null}
          {authPage === 'forgot-password' ? (
            <PasswordRecovery
              handleSignIn={() => {
                setAuthPage('sign-in')
              }}
              handleSignUp={() => {
                setAuthPage('sign-up')
              }}
            />
          ) : null}
        </Box>
      </ModalWrap>
    </Box>
  )
}

export default GreenPageServices
