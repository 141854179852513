import { createSlice } from '@reduxjs/toolkit'
import getProductProvidersBuilder from './builders/getProductProviders.builder'

const initialState = {
  isLoading: false,
  error: null,
  data: [],
  params: {
    pagination: false,
    type: 'bank',
  },
}

const productProvidersSlice = createSlice({
  name: 'productProvidersReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getProductProvidersBuilder(builder)
  },
})

export default productProvidersSlice.reducer
