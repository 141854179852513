import React from 'react'
import { RemoveRounded } from '@mui/icons-material'
import { colorsConfig } from '../../config/themeConfig'

const UINoDataIcon = ({ isNormalized }) => {
  return (
    <RemoveRounded
      className={isNormalized ? 'normalized' : ''}
      sx={{
        color: colorsConfig.iconGray,
        fontSize: '24px',
        '&.normalized': {
          position: 'relative',
          ml: '-3px',
        },
      }}
    />
  )
}

export default UINoDataIcon
