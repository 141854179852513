import React from 'react'
import { Stack } from '@mui/material'
import { useDispatch } from 'react-redux'
import { updateInteriorCondition } from '../../../store/features/carSlice/carSlice'
import { updateControlPoints } from '../../../store/features/carSlice/car.actions'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import SimpleCheckbox from '../../../ui/SimpleCheckbox'

const ControlPointCheckbox = ({ data, readonly }) => {
  const { name, carControlPoint } = data
  const { value } = carControlPoint

  const dispatch = useDispatch()

  const handleSetValue = (newValue) => {
    if (readonly) return
    dispatch(updateControlPoints({ data: [{ controlPoint: data['@id'], value: newValue }] }))
    dispatch(updateInteriorCondition({ controlPoint: data['@id'], value: newValue }))
  }

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <UITextRegular text={name} />
      <SimpleCheckbox value={value} setValue={handleSetValue} />
    </Stack>
  )
}

export default ControlPointCheckbox
