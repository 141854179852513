import { createSlice } from '@reduxjs/toolkit'
import getCoveredComponentsBuilder from './builders/getCoveredComponents.builder'

const initialState = {
  isLoading: false,
  error: null,
  coveredComponents: [],
}

const coveredComponentsSlice = createSlice({
  name: 'coveredComponents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getCoveredComponentsBuilder(builder)
  },
})

export default coveredComponentsSlice.reducer
