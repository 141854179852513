import React from 'react'
import { Box, Grid, TextField } from '@mui/material'
import { setCarMainData } from '../../../store/features/addNewCarSlice/addNewCar.reducer'
import { useSelector, useDispatch } from 'react-redux'
import TextFieldSmall from '../../../ui/TextFieldSmall'
import { useTranslation } from 'react-i18next'

const AdditionalInfoStep = () => {
  const { t } = useTranslation()

  const carData = useSelector((state) => state.addCar.carMainData)
  const dispatch = useDispatch()

  return (
    <Box
      sx={{
        mt: '40px',
        mb: '100px',
        width: '100%',
      }}
    >
      <Grid container>
        <Grid xs={12} item>
          <TextFieldSmall
            fullWidth
            label={t('form_input_label_additional_info')}
            type="textarea"
            multiline
            rows={4}
            maxRows={10}
            value={carData.additionalInfo}
            onChange={(e) => {
              dispatch(
                setCarMainData({
                  ...carData,
                  additionalInfo: e.target.value,
                }),
              )
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default AdditionalInfoStep
