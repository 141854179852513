import React from 'react'
import { Stack } from '@mui/material'
import ModalWrap from '../../../../components/Modals/ModalWrap'
import UITextHeadlineLarge from '../../../../ui/UIText/UITextHeadlineLarge'
import ButtonHollow from '../../../../ui/Buttons/ButtonHollow'
import ButtonSuccess from '../../../../ui/Buttons/ButtonSuccess'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import ButtonAttention from '../../../../ui/Buttons/ButtonAttention'

const CarWarrantyConfirmationModal = ({ open, setOpen, handleSubmit, disabled }) => {
  return (
    <ModalWrap open={open} setOpen={setOpen}>
      <Stack gap="30px">
        <UITextHeadlineLarge
          text="Are you sure you want to activate warranty?"
          sx={{
            fontSize: {
              md: '40px',
              xs: '24px',
            },
          }}
        />
        <Stack gap="10px" direction={{ xs: 'column', md: 'row' }}>
          <ButtonHollow onClick={() => setOpen(false)} sx={{ flex: 1 }}>
            Double-check application
          </ButtonHollow>
          <ButtonAttention
            onClick={() => handleSubmit()}
            disabled={disabled}
            type="submit"
            sx={{ flex: 1 }}
          >
            Activate
          </ButtonAttention>
        </Stack>
      </Stack>
    </ModalWrap>
  )
}

export default CarWarrantyConfirmationModal
