import * as Yup from 'yup'
import { formErrors } from '../../formErrors'

export const getEditManagerFormInitialState = (data) => ({
  phoneNumber: data?.phoneNumber ?? '',
  firstName: data?.firstName ?? '',
  surname: data?.surname ?? '',
  company: data?.company?.['@id'] ?? data?.company ?? '',
  login: data?.user?.login ?? '',
  email: data?.user?.email ?? '',
  locale: data?.user?.locale ?? 'en',
})

export const editManagerFormValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string().required(formErrors.required),
  firstName: Yup.string().required(formErrors.required),
  surname: Yup.string().required(formErrors.required),
  company: Yup.string().required(formErrors.required),
  login: Yup.string().required(formErrors.required),
  email: Yup.string().required(formErrors.required).email(formErrors.invalidEmail),
})
