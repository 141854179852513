import React from 'react'
import { Stack } from '@mui/material'
import UITextHeadlineLarge from '../../../../ui/UIText/UITextHeadlineLarge'
import ButtonHollow from '../../../../ui/Buttons/ButtonHollow'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import ModalWrap from '../../../../components/Modals/ModalWrap'
import { useSelector } from 'react-redux'
import UITextRegular from '../../../../ui/UIText/UITextRegular'

const CloseDueToEndOfContractModal = ({ open, setOpen, data, handleAction }) => {
  const isLoading = useSelector((state) => state.rviPolicy.closeDueToEndOfContract.isLoading)

  return (
    <ModalWrap open={open} setOpen={setOpen}>
      <Stack gap="30px">
        <Stack gap="10px  ">
          <UITextHeadlineLarge
            sx={{ fontSize: '32px' }}
            text={`Close policy for "${data?.details?.make} ${data?.details?.model}"`}
          />
          <UITextRegular
            text={`Are you sure you want to close this policy due to the end of the contract?`}
          />
        </Stack>

        <Stack gap="10px" direction="row">
          <ButtonHollow disabled={isLoading} sx={{ flex: 1 }} onClick={() => setOpen(false)}>
            Cancel
          </ButtonHollow>
          <ButtonLG disabled={isLoading} sx={{ flex: 1 }} onClick={() => handleAction()}>
            Confirm
          </ButtonLG>
        </Stack>
      </Stack>
    </ModalWrap>
  )
}

export default CloseDueToEndOfContractModal
