import React from 'react'
import ModalWrap from '../../../components/Modals/ModalWrap'
import UITextHeadlineLarge from '../../../ui/UIText/UITextHeadlineLarge'
import { Stack } from '@mui/material'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import ButtonDanger from '../../../ui/Buttons/ButtonDanger'
import { useSelector } from 'react-redux'
import UIPreloader from '../../../ui/UIPreloader/UIPreloader'

const CloseRVIContractModal = ({ open, setOpen, handleAction }) => {
  const isLoading = useSelector((state) => state.rviPolicy.closeAsDealer.isLoading)

  return (
    <ModalWrap open={open} setOpen={setOpen}>
      {isLoading && <UIPreloader />}
      <Stack gap="30px">
        <UITextHeadlineLarge
          sx={{ fontSize: '32px' }}
          text="Are you sure you want to close the contract for this vehicle?"
        />
        <Stack gap="10px" direction="row">
          <ButtonHollow sx={{ flex: 1 }} onClick={() => setOpen(false)}>
            Cancel
          </ButtonHollow>
          <ButtonDanger sx={{ flex: 1 }} onClick={() => handleAction()}>
            Close
          </ButtonDanger>
        </Stack>
      </Stack>
    </ModalWrap>
  )
}

export default CloseRVIContractModal
