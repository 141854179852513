import React, { useState } from 'react'
import UITableActionsList from '../../../ui/UITable/UITableActionsList'
import UsePdfAppraisal from '../../../hooks/UsePdfAppraisal'
import ResendCustomerAppraisalLinkModal from '../../../components/Modals/ResendCustomerAppraisalLinkModal/ResendCustomerAppraisalLinkModal'
import { useDispatch, useSelector } from 'react-redux'
import DeleteCustomerAppraisalModal from '../../../components/Modals/DeleteModals/DeleteCustomerAppraisalModal'
import { getCustomerAppraisals } from '../../../store/features/customerAppraisals/customerAppraisals.actions'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const CustomerAppraisalsActionItems = ({ data }) => {
  const { isGeneratingPdf, isDownloading, startDownloadingAppraisalPdf } = UsePdfAppraisal()
  const { t } = useTranslation()

  const isSendingEmail = useSelector((state) => state.customerAppraisal.sendEmail.isLoading)
  const dispatch = useDispatch()

  const customerAppraisal = data?.initialData
  const car = customerAppraisal?.car

  const [resendCustomerAppraisalLinkModal, setResendCustomerAppraisalLinkModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const actions = [
    {
      name: 'button_download_appraisal_as_pdf',
      handleAction: () => startDownloadingAppraisalPdf(data?.initialData?.car),
      workflows: ['car_workflow.customer_appraisal_completed'],
    },
    {
      name: 'button_resend_the_link',
      handleAction: () => setResendCustomerAppraisalLinkModal(true),
    },
    {
      name: 'button_delete_appraisal',
      handleAction: () => setDeleteModal(true),
    },
  ]

  const filteredActions = actions.filter(
    (action) => !action?.workflows || action.workflows.includes(car?.combinedStatus),
  )

  return (
    <>
      <UITableActionsList isLoading={isGeneratingPdf || isDownloading} actions={filteredActions} />
      {resendCustomerAppraisalLinkModal && (
        <ResendCustomerAppraisalLinkModal
          open={resendCustomerAppraisalLinkModal}
          setOpen={setResendCustomerAppraisalLinkModal}
          isSendingEmail={isSendingEmail}
          customerAppraisal={customerAppraisal}
        />
      )}
      {deleteModal && (
        <DeleteCustomerAppraisalModal
          open={deleteModal}
          setOpen={setDeleteModal}
          data={customerAppraisal}
          onClose={() => {
            dispatch(getCustomerAppraisals())
            toast.success(t('notification_customer_appraisal_delete_appraisal_success'))
          }}
        />
      )}
    </>
  )
}

export default CustomerAppraisalsActionItems
