import { downloadPrivateFile } from '../files.actions'

export default (builder) => {
  builder
    .addCase(downloadPrivateFile.pending, (state) => {
      state.download.isLoading = true
    })
    .addCase(downloadPrivateFile.fulfilled, (state, action) => {
      state.download.isLoading = false
      state.download.error = null
      state.download.data = action.payload
    })
    .addCase(downloadPrivateFile.rejected, (state, action) => {
      state.download.isLoading = false
      state.download.error = action.payload
    })
}
