import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { useDispatch } from 'react-redux'
import { setCurrentPageTitle } from '../../store/features/authSlice'
import ComingSoon from '../../components/ComingSoon'

const CarSubscription = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setCurrentPageTitle('Car Subscription'))
  }, [])

  return <ComingSoon />
}

export default CarSubscription
