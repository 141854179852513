import React, { useState } from 'react'
import EnhancedTabs from '../../../../ui/EnhancedTabs'
import { useSelector, useDispatch } from 'react-redux'
import {
  setSelectedTab,
  addApplication,
  deleteApplication,
} from '../../../../store/features/insuranceSlice'
import EnhancedTab from '../../../../ui/EnhancedTab'
import DeleteApplicationModal from './DeleteApplicationModal'
import { privateRequest } from '../../../../utils/request'
import { API_ENDPOINTS } from '../../../../config/default'
import { toast } from 'react-toastify'
import { insuranceWorkflowsEnums } from '../../insurance.constants'

const CarInsuranceTabs = () => {
  const applications = useSelector((state) => state.insurance.applications)
  const selectedTab = useSelector((state) => state.insurance.selectedTab)
  const dispatch = useDispatch()

  const [deleteApplicationModal, setDeleteApplicationModal] = useState(false)
  const [deleteApplicationData, setDeleteApplicationData] = useState(null)

  const handleDelete = () => {
    if (deleteApplicationData === null) return
    if (
      applications[deleteApplicationData].currentWorkflowState !== insuranceWorkflowsEnums.BLANK
    ) {
      privateRequest
        .put(API_ENDPOINTS.insurance.archiveApplication(applications[deleteApplicationData].id), {})
        .then(() => {
          dispatch(setSelectedTab(0))
          dispatch(deleteApplication(applications[deleteApplicationData].id))
          setDeleteApplicationModal(false)
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message)
        })
    } else {
      dispatch(setSelectedTab(0))
      dispatch(deleteApplication(applications[deleteApplicationData].id))
      setDeleteApplicationModal(false)
    }
  }

  const displayCustomerName = (index) => {
    if (applications[index]?.customer?.firstName || applications[index]?.customer?.lastName) {
      return `${applications[index]?.customer?.firstName || ''} ${applications[index]?.customer?.lastName || ''}`
    } else {
      return `Customer #${index + 1}`
    }
  }

  const renderTab = (index) => {
    return (
      <EnhancedTab
        index={index}
        handleDelete={() => {
          setDeleteApplicationData(index)
          setDeleteApplicationModal(true)
        }}
        tabsAmount={applications.length}
        text={displayCustomerName(index)}
        caption={applications[index].currentWorkflowState}
      />
    )
  }

  return (
    <>
      <EnhancedTabs
        tab={selectedTab}
        setTab={(value) => {
          dispatch(setSelectedTab(value))
        }}
        tabs={applications}
        handleAddTab={() => {
          dispatch(addApplication())
        }}
        renderTab={renderTab}
      />
      <DeleteApplicationModal
        open={deleteApplicationModal}
        setOpen={setDeleteApplicationModal}
        handleSubmit={handleDelete}
      />
    </>
  )
}

export default CarInsuranceTabs
