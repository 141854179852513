import { createAsyncThunk } from '@reduxjs/toolkit'
import referencesApiService from '../../../api/referencesApiService/referencesApiService'
import { initialApplicationData as response } from '../../../pages/Insurance/insurance.constants'
import addressTypesApiService from '../../../api/addressesApiService/addressTypesApiService'

export const getEnumDetails = createAsyncThunk(
  'references/getEnumDetails',
  async (_, { rejectWithValue }) => {
    try {
      const response = await referencesApiService.getEnumDetails()
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getAddressTypes = createAsyncThunk(
  'references/getAddressTypes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await addressTypesApiService.getAddressTypes()
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)
