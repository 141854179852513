import { updateEntry } from '../residualValueMatrixEntries.actions'

export default (builder) => {
  builder
    .addCase(updateEntry.pending, (state, action) => {
      const { entryId } = action.meta.arg
      state.update.isLoading = true
      state.update.id = entryId
    })
    .addCase(updateEntry.fulfilled, (state, action) => {
      state.update.isLoading = false
      state.update.error = null
      state.update.id = null
    })
    .addCase(updateEntry.rejected, (state, action) => {
      state.update.isLoading = false
      state.update.error = action.payload
      state.update.id = null
    })
}
