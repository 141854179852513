import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { IconButton } from '@mui/material'
import { AlternateEmailRounded } from '@mui/icons-material'
import { colorsConfig } from '../../../config/themeConfig'
import { useTranslation } from 'react-i18next'

const ButtonLinkMail = ({ title = 'button_share_via_email', sx, handleSendEmail, ...props }) => {
  const { t } = useTranslation()
  return (
    <Tooltip title={t(title)}>
      <IconButton
        sx={{
          width: '50px',
          height: '50px',
          borderRadius: '6px',
          '& svg': {
            fill: colorsConfig.mainBlue,
            transition: '0.25s all ease-in-out',
          },
          '&:hover': {
            '& svg': {
              fill: colorsConfig.buttonHover,
              transition: '0.25s all ease-in-out',
            },
          },
          ...sx,
        }}
        onClick={() => handleSendEmail()}
        {...props}
      >
        <AlternateEmailRounded />
      </IconButton>
    </Tooltip>
  )
}

export default ButtonLinkMail
