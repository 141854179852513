import React from 'react'
import { Box } from '@mui/material'
import TextNormal from '../../../ui/Text/TextNormal'
import IconAppleGreen from '../../../assests/img/icons/icon-apple-green.svg'
import IconAppleLightGreen from '../../../assests/img/icons/icon-apple-light-green.svg'
import IconAppleYellow from '../../../assests/img/icons/icon-apple-yellow.svg'
import IconAppleOrange from '../../../assests/img/icons/icon-apple-orange.svg'
import IconAppleRed from '../../../assests/img/icons/icon-apple-red.svg'
import { colorsConfig } from '../../../config/themeConfig'

const EnergyEfficiencyCategoryItem = (props) => {
  const { category, currentValue, onChange } = props

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '223px',
        height: '20px',
        mb: '6px',
        background: category.color,
        borderRadius: '4px',
        px: '6px',
        cursor: 'pointer',
        '&:hover': {
          '& .icon-apple': {
            opacity: 1,
            transition: '0.25s all ease-in-out',
          },
        },
        '&:last-child': {
          mb: 0,
        },
      }}
      onClick={() => {
        onChange(category.name)
      }}
    >
      <TextNormal
        sx={{
          fontSize: '12px',
          color: 'white',
        }}
      >
        {category.name}
      </TextNormal>
      <TextNormal
        sx={{
          fontSize: '12px',
          color: 'white',
        }}
      >
        ({category.value}g/km)
      </TextNormal>
      <Box
        className={`icon-apple ${currentValue === category.name ? 'active' : ''}`}
        sx={{
          position: 'absolute',
          width: '27px',
          left: '100%',
          ml: '9px',
          opacity: 0,
          transition: '0.25s all ease-in-out',
          '&.active': {
            opacity: 1,
            transition: '0.25s all ease-in-out',
          },
        }}
      >
        {category.name === 'A+++' || category.name === 'A++' || category.name === 'A+' ? (
          <img src={IconAppleGreen} style={{ width: '100%' }} alt="" />
        ) : null}
        {category.name === 'A' || category.name === 'B' ? (
          <img src={IconAppleLightGreen} style={{ width: '100%' }} alt="" />
        ) : null}
        {category.name === 'C' ? (
          <img src={IconAppleYellow} style={{ width: '100%' }} alt="" />
        ) : null}
        {category.name === 'D' ? (
          <img src={IconAppleOrange} style={{ width: '100%' }} alt="" />
        ) : null}
        {category.name === 'E' || category.name === 'F' || category.name === 'G' ? (
          <img src={IconAppleRed} style={{ width: '100%' }} alt="" />
        ) : null}
        <TextNormal
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            top: 0,
            left: 0,
            fontSize: '7px',
            fontWeight: 700,
            color:
              category.name === 'E' || category.name === 'F' || category.name === 'G'
                ? 'white'
                : colorsConfig.mainBlack,
          }}
        >
          {category.name}
        </TextNormal>
      </Box>
    </Box>
  )
}

export default EnergyEfficiencyCategoryItem
