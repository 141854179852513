import React, { useEffect, useState } from 'react'
import VehicleDetailsSection from '../../../CarPage/CarPageSections/VehicleDetailsSection'
import DocumentsSection from '../../../CarPage/CarPageSections/DocumentsSection'
import PhotosSection from '../../../CarPage/CarPageSections/PhotosSection'
import DamagesSection from '../../../CarPage/CarPageSections/DamagesSection'
import WarningLights from '../../../Appraisal/WarningLights/WarningLights'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { privateRequest } from '../../../../utils/request'
import { API_FINANCE_ENDPOINTS } from '../../../../config/default'
import Preloader from '../../../../components/Preloader'
import { Box } from '@mui/material'
import { colorsConfig } from '../../../../config/themeConfig'
import BreadcrumbsComponent from '../../../../components/BreadcrumbsComponent'
import IconVirtualGarage from '../../../../assests/img/icons/icon-virtual-garage-home.svg'
import {
  setLoanApplication,
  setLoanDeals,
  resetFinance,
} from '../../../../store/features/financeSlice'
import { setCurrentPageTitle } from '../../../../store/features/authSlice'
import LoanApplicationSummary from './LoanApplicationSummary'
import BankTabs from './BankTabs'
import BankResponseForm from './BankResponseForm'
import { loanDealWorkflowStates } from '../../../../utils/constants/workflows.constants'

const OperatorFinancingRequest = () => {
  const [loading, setLoading] = useState(true)

  const [bankTab, setBankTab] = useState(0)
  const [banks, setBanks] = useState([])

  const loanApplication = useSelector((state) => state.finance.loanApplication)
  const customer = useSelector((state) => state.finance.loanApplication.customer)

  const dispatch = useDispatch()

  const params = useParams()

  const getLoanApplication = () => {
    privateRequest.get(API_FINANCE_ENDPOINTS.loanApplicationById(params.id)).then((response) => {
      dispatch(setLoanApplication(response.data))
      response.data.loanDeals.forEach((item, index) => {
        if (item.id.toString() === window.location.search.replace(/[^0-9.]/g, '')) {
          setBankTab(index)
        }
      })
      dispatch(setCurrentPageTitle('Loan application'))
      setLoading(false)
    })
  }

  const handleSetBanks = () => {
    setBanks(
      loanApplication.loanDeals
        .filter(
          (item) =>
            item?.currentWorkflowState !== loanDealWorkflowStates.new &&
            item?.currentWorkflowState !== loanDealWorkflowStates.approval_request &&
            item?.currentWorkflowState !== loanDealWorkflowStates.bank_error,
        )
        .map((item) => {
          return {
            loanDealId: item.id,
            name: item.bankTitle,
          }
        }),
    )
  }

  const handleSetLoanDeals = () => {
    dispatch(
      setLoanDeals(
        loanApplication.loanDeals
          .filter(
            (item) =>
              item?.currentWorkflowState !== loanDealWorkflowStates.new &&
              item?.currentWorkflowState !== loanDealWorkflowStates.approval_request &&
              item?.currentWorkflowState !== loanDealWorkflowStates.bank_error,
          )
          .map((item) => {
            let localStatus = 'not_selected'
            if (item.currentWorkflowState === 'approved') {
              localStatus = 'accepted'
            }
            if (item.currentWorkflowState === 'bank_request_info') {
              localStatus = 'accepted_with_changes'
            }
            if (item.currentWorkflowState === 'bank_declined') {
              localStatus = 'rejected'
            }
            const approvedParams = item.approvedParamsData
            const comment =
              item.comments && item.comments.length > 0 ? item.comments[0].comment : ''
            return {
              ...item,
              localStatus: localStatus, // not_selected, accepted, rejected, accepted_with_changes
              approvedParams: {
                offerName: approvedParams?.offerName || item.title || '',
                loanTerm: item.term,
                monthlyInstallment: item.monthlyPayment,
                downPayment: loanApplication.dp,
                rwWarranty: approvedParams?.rvWarranty ? approvedParams?.rvWarranty : '',
                interestRate: item.rate,
                dealerCommission: approvedParams?.dealerCommission
                  ? approvedParams?.dealerCommission
                  : '',
                dealerCommissionPercents: '',
                dealerCommissionType: 'in AED',
                totalLoanAmount: item.loanAmount,
                documentsProvided: false,
                comment: localStatus !== 'rejected' ? comment : '',
                documents: [],
                bundledProducts: [null],
                rejectionReason: localStatus === 'rejected' ? comment : '',
                uploadedDocuments: item.attachments,
              },
            }
          }),
      ),
    )
  }

  useEffect(() => {
    getLoanApplication()
    return () => {
      dispatch(resetFinance())
    }
  }, [])

  useEffect(() => {
    if (loanApplication.customer) {
      handleSetBanks()
      handleSetLoanDeals()
    }
  }, [loanApplication])

  return (
    <Box
      sx={{
        height: loading ? 'calc(100vh - 102px)' : 'auto',
        position: 'relative',
      }}
    >
      {loading ? (
        <Preloader type="gooey" text={'Loading the client info...'} />
      ) : (
        <Box>
          <Box
            sx={{
              background: colorsConfig.bgLightBlue,
              position: 'relative',
              ml: '-23px',
              mr: '-98px',
              pl: '23px',
              pr: '98px',
              pb: '46px',
              mt: '-30px',
            }}
          >
            <Box
              sx={{
                pt: '30px',
                mb: '30px',
              }}
            >
              <BreadcrumbsComponent
                currentPage={{
                  name: `${customer?.person?.firstName} ${customer?.person?.lastName}`,
                }}
                parentPages={[
                  {
                    name: 'Financing',
                    icon: <img src={IconVirtualGarage} alt="" />,
                    link: '/admin/financing/loan-applications',
                  },
                ]}
              />
            </Box>
            <LoanApplicationSummary />
          </Box>
          <Box sx={{ mt: '8px' }}>
            <BankTabs tab={bankTab} setTab={setBankTab} tabs={banks} />
          </Box>
          <BankResponseForm currentTab={bankTab} />
          {/*<Box*/}
          {/*    sx={{*/}
          {/*        pt: '40px'*/}
          {/*    }}*/}
          {/*>*/}
          {/*    <TextH1*/}
          {/*        sx={{*/}
          {/*            mb: '20px'*/}
          {/*        }}*/}
          {/*    >*/}
          {/*        Provide bank responses*/}
          {/*    </TextH1>*/}
          {/*    <Box*/}
          {/*        sx={{*/}
          {/*            mb: '40px'*/}
          {/*        }}*/}
          {/*    >*/}
          {/*        <OperatorBankResponse/>*/}
          {/*    </Box>*/}
          {/*    <Box*/}
          {/*        sx={{*/}
          {/*            mb: '30px'*/}
          {/*        }}*/}
          {/*    >*/}
          {/*        <OperatorBankDocuments/>*/}
          {/*    </Box>*/}
          {/*    <Box*/}
          {/*        sx={{*/}
          {/*            mb: '30px'*/}
          {/*        }}*/}
          {/*    >*/}
          {/*        <OperatorIssueReportForm/>*/}
          {/*    </Box>*/}
          {/*    <Box*/}
          {/*        sx={{*/}
          {/*            boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',*/}
          {/*            borderRadius: '6px',*/}
          {/*            padding: '22px',*/}
          {/*            background: 'white',*/}
          {/*        }}*/}
          {/*    >*/}
          {/*        <TextBoldNormal*/}
          {/*            sx={{*/}
          {/*                fontSize: '18px',*/}
          {/*                mb: '24px'*/}
          {/*            }}*/}
          {/*        >*/}
          {/*            Customer data*/}
          {/*        </TextBoldNormal>*/}
          {/*        <Grid container spacing={2}>*/}
          {/*            <Grid item xs={12} md={3} sm={4}>*/}
          {/*                <TextNormal sx={{mb: '5px'}}>*/}
          {/*                    Name: Bob Ross*/}
          {/*                </TextNormal>*/}
          {/*                <TextNormal sx={{mb: '5px'}}>*/}
          {/*                    Address: 5 Camel st.*/}
          {/*                </TextNormal>*/}
          {/*                <TextNormal sx={{mb: '5px'}}>*/}
          {/*                    City: Dubai*/}
          {/*                </TextNormal>*/}
          {/*                <TextNormal sx={{mb: '5px'}}>*/}
          {/*                    Country: UAE*/}
          {/*                </TextNormal>*/}
          {/*                <TextNormal sx={{mb: '5px'}}>*/}
          {/*                    E-mail: mymail@gmail.com*/}
          {/*                </TextNormal>*/}
          {/*            </Grid>*/}
          {/*            <Grid item xs={12} md={3} sm={4}>*/}
          {/*                <TextNormal sx={{mb: '5px'}}>*/}
          {/*                    Phone number: +7 432 88 76*/}
          {/*                </TextNormal>*/}
          {/*                <TextNormal sx={{mb: '5px'}}>*/}
          {/*                    Nationality: Italian*/}
          {/*                </TextNormal>*/}
          {/*                <TextNormal sx={{mb: '5px'}}>*/}
          {/*                    Employment status: Employed*/}
          {/*                </TextNormal>*/}
          {/*                <TextNormal sx={{mb: '5px'}}>*/}
          {/*                    Employer name: Johnny*/}
          {/*                </TextNormal>*/}
          {/*                <TextNormal sx={{mb: '5px'}}>*/}
          {/*                    Years in employment: 15*/}
          {/*                </TextNormal>*/}
          {/*            </Grid>*/}
          {/*            <Grid item xs={12} md={3} sm={4}>*/}
          {/*                <TextNormal sx={{mb: '5px'}}>*/}
          {/*                    Fixed Salary / month: 120 000*/}
          {/*                </TextNormal>*/}
          {/*                <TextNormal sx={{mb: '5px'}}>*/}
          {/*                    Annual Salary: 1000 000 AED*/}
          {/*                </TextNormal>*/}
          {/*                <TextNormal sx={{mb: '5px'}}>*/}
          {/*                    Salary Transfer Bank: monobank*/}
          {/*                </TextNormal>*/}
          {/*            </Grid>*/}
          {/*            <Grid item xs={12} md={3} sm={4}/>*/}
          {/*        </Grid>*/}
          {/*        <Box*/}
          {/*            sx={{*/}
          {/*                position: 'relative',*/}
          {/*                ml: '-22px',*/}
          {/*                mr: '-22px',*/}
          {/*                mb: '-22px',*/}
          {/*                display: 'flex',*/}
          {/*                flexDirection: 'row',*/}
          {/*                justifyContent: 'flex-start',*/}
          {/*                alignItems: 'flex-start',*/}
          {/*                flexWrap: 'wrap',*/}
          {/*                mt: '30px'*/}
          {/*            }}*/}
          {/*        >*/}
          {/*            <Box*/}
          {/*                sx={{*/}
          {/*                    width: 'calc(100% / 4)',*/}
          {/*                    border: '1px solid',*/}
          {/*                    borderColor: colorsConfig.lightGray,*/}
          {/*                    borderLeft: 'none',*/}
          {/*                    borderBottom: 'none',*/}
          {/*                    py: '13px',*/}
          {/*                    display: 'flex',*/}
          {/*                    flexDirection: 'column',*/}
          {/*                    justifyContent: 'center',*/}
          {/*                    alignItems: 'center'*/}
          {/*                }}*/}
          {/*            >*/}
          {/*                <TextBoldNormal sx={{mb: '5px'}}>*/}
          {/*                    Valid Emirates ID*/}
          {/*                </TextBoldNormal>*/}
          {/*                <IconButton*/}
          {/*                    fullWidth*/}
          {/*                    sx={{*/}
          {/*                        borderRadius: '6px',*/}
          {/*                        padding: 0,*/}
          {/*                        '&:last-child': {*/}
          {/*                            mr: 0*/}
          {/*                        },*/}
          {/*                        color: colorsConfig.mainBlue,*/}
          {/*                        position: 'relative',*/}
          {/*                        height: '57px'*/}
          {/*                    }}*/}
          {/*                >*/}
          {/*                    <a download href={''} target="_blank">*/}
          {/*                        <FileOpen*/}
          {/*                            sx={{*/}
          {/*                                width: '60px',*/}
          {/*                                fontSize: '50px',*/}
          {/*                                fill: colorsConfig.mainBlue,*/}
          {/*                                color: colorsConfig.mainBlue*/}
          {/*                            }}*/}
          {/*                        />*/}
          {/*                    </a>*/}

          {/*                </IconButton>*/}
          {/*            </Box>*/}
          {/*            <Box*/}
          {/*                sx={{*/}
          {/*                    width: 'calc(100% / 4)',*/}
          {/*                    border: '1px solid',*/}
          {/*                    borderColor: colorsConfig.lightGray,*/}
          {/*                    borderLeft: 'none',*/}
          {/*                    borderBottom: 'none',*/}
          {/*                    py: '13px',*/}
          {/*                    display: 'flex',*/}
          {/*                    flexDirection: 'column',*/}
          {/*                    justifyContent: 'center',*/}
          {/*                    alignItems: 'center'*/}
          {/*                }}*/}
          {/*            >*/}
          {/*                <TextBoldNormal sx={{mb: '5px'}}>*/}
          {/*                    Valid UAE driving licence copy*/}
          {/*                </TextBoldNormal>*/}
          {/*                <IconButton*/}
          {/*                    fullWidth*/}
          {/*                    sx={{*/}
          {/*                        borderRadius: '6px',*/}
          {/*                        padding: 0,*/}
          {/*                        '&:last-child': {*/}
          {/*                            mr: 0*/}
          {/*                        },*/}
          {/*                        color: colorsConfig.mainBlue,*/}
          {/*                        position: 'relative',*/}
          {/*                        height: '57px'*/}
          {/*                    }}*/}
          {/*                >*/}
          {/*                    <a download href={''} target="_blank">*/}
          {/*                        <FileOpen*/}
          {/*                            sx={{*/}
          {/*                                width: '60px',*/}
          {/*                                fontSize: '50px',*/}
          {/*                                fill: colorsConfig.mainBlue,*/}
          {/*                                color: colorsConfig.mainBlue*/}
          {/*                            }}*/}
          {/*                        />*/}
          {/*                    </a>*/}

          {/*                </IconButton>*/}
          {/*            </Box>*/}
          {/*            <Box*/}
          {/*                sx={{*/}
          {/*                    width: 'calc(100% / 4)',*/}
          {/*                    border: '1px solid',*/}
          {/*                    borderColor: colorsConfig.lightGray,*/}
          {/*                    borderLeft: 'none',*/}
          {/*                    borderBottom: 'none',*/}
          {/*                    py: '13px',*/}
          {/*                    display: 'flex',*/}
          {/*                    flexDirection: 'column',*/}
          {/*                    justifyContent: 'center',*/}
          {/*                    alignItems: 'center'*/}
          {/*                }}*/}
          {/*            >*/}
          {/*                <TextBoldNormal sx={{mb: '5px'}}>*/}
          {/*                    Valid UAE driving licence copy*/}
          {/*                </TextBoldNormal>*/}
          {/*                <IconButton*/}
          {/*                    fullWidth*/}
          {/*                    sx={{*/}
          {/*                        borderRadius: '6px',*/}
          {/*                        padding: 0,*/}
          {/*                        '&:last-child': {*/}
          {/*                            mr: 0*/}
          {/*                        },*/}
          {/*                        color: colorsConfig.mainBlue,*/}
          {/*                        position: 'relative',*/}
          {/*                        height: '57px'*/}
          {/*                    }}*/}
          {/*                >*/}
          {/*                    <a download href={''} target="_blank">*/}
          {/*                        <FileOpen*/}
          {/*                            sx={{*/}
          {/*                                width: '60px',*/}
          {/*                                fontSize: '50px',*/}
          {/*                                fill: colorsConfig.mainBlue,*/}
          {/*                                color: colorsConfig.mainBlue*/}
          {/*                            }}*/}
          {/*                        />*/}
          {/*                    </a>*/}

          {/*                </IconButton>*/}
          {/*            </Box>*/}
          {/*            <Box*/}
          {/*                sx={{*/}
          {/*                    width: 'calc(100% / 4)',*/}
          {/*                    border: '1px solid',*/}
          {/*                    borderColor: colorsConfig.lightGray,*/}
          {/*                    borderLeft: 'none',*/}
          {/*                    borderBottom: 'none',*/}
          {/*                    borderRight: 'none',*/}
          {/*                    py: '13px',*/}
          {/*                    display: 'flex',*/}
          {/*                    flexDirection: 'column',*/}
          {/*                    justifyContent: 'center',*/}
          {/*                    alignItems: 'center'*/}
          {/*                }}*/}
          {/*            >*/}
          {/*                <TextBoldNormal sx={{mb: '5px'}}>*/}
          {/*                    Proof of income*/}
          {/*                </TextBoldNormal>*/}
          {/*                <IconButton*/}
          {/*                    fullWidth*/}
          {/*                    sx={{*/}
          {/*                        borderRadius: '6px',*/}
          {/*                        padding: 0,*/}
          {/*                        '&:last-child': {*/}
          {/*                            mr: 0*/}
          {/*                        },*/}
          {/*                        color: colorsConfig.mainBlue,*/}
          {/*                        position: 'relative',*/}
          {/*                        height: '57px'*/}
          {/*                    }}*/}
          {/*                >*/}
          {/*                    <a download href={''} target="_blank">*/}
          {/*                        <FileOpen*/}
          {/*                            sx={{*/}
          {/*                                width: '60px',*/}
          {/*                                fontSize: '50px',*/}
          {/*                                fill: colorsConfig.mainBlue,*/}
          {/*                                color: colorsConfig.mainBlue*/}
          {/*                            }}*/}
          {/*                        />*/}
          {/*                    </a>*/}

          {/*                </IconButton>*/}
          {/*            </Box>*/}
          {/*            <Box*/}
          {/*                sx={{*/}
          {/*                    width: 'calc(100% / 2)',*/}
          {/*                    border: '1px solid',*/}
          {/*                    borderColor: colorsConfig.lightGray,*/}
          {/*                    borderBottom: 'none',*/}
          {/*                    borderLeft: 'none',*/}
          {/*                    py: '13px',*/}
          {/*                    display: 'flex',*/}
          {/*                    flexDirection: 'column',*/}
          {/*                    justifyContent: 'center',*/}
          {/*                    alignItems: 'center'*/}
          {/*                }}*/}
          {/*            >*/}
          {/*                <TextBoldNormal sx={{mb: '5px'}}>*/}
          {/*                    Copy of the last 3 months' salary or income transfer bank statements*/}
          {/*                </TextBoldNormal>*/}
          {/*                <IconButton*/}
          {/*                    fullWidth*/}
          {/*                    sx={{*/}
          {/*                        borderRadius: '6px',*/}
          {/*                        padding: 0,*/}
          {/*                        '&:last-child': {*/}
          {/*                            mr: 0*/}
          {/*                        },*/}
          {/*                        color: colorsConfig.mainBlue,*/}
          {/*                        position: 'relative',*/}
          {/*                        height: '57px'*/}
          {/*                    }}*/}
          {/*                >*/}
          {/*                    <a download href={''} target="_blank">*/}
          {/*                        <FileOpen*/}
          {/*                            sx={{*/}
          {/*                                width: '60px',*/}
          {/*                                fontSize: '50px',*/}
          {/*                                fill: colorsConfig.mainBlue,*/}
          {/*                                color: colorsConfig.mainBlue*/}
          {/*                            }}*/}
          {/*                        />*/}
          {/*                    </a>*/}

          {/*                </IconButton>*/}
          {/*            </Box>*/}
          {/*            <Box*/}
          {/*                sx={{*/}
          {/*                    width: 'calc(100% / 2)',*/}
          {/*                    border: '1px solid',*/}
          {/*                    borderColor: colorsConfig.lightGray,*/}
          {/*                    borderBottom: 'none',*/}
          {/*                    borderRight: 'none',*/}
          {/*                    borderLeft: 'none',*/}
          {/*                    py: '13px',*/}
          {/*                    display: 'flex',*/}
          {/*                    flexDirection: 'column',*/}
          {/*                    justifyContent: 'center',*/}
          {/*                    alignItems: 'center'*/}
          {/*                }}*/}
          {/*            >*/}
          {/*                <TextBoldNormal sx={{mb: '5px'}}>*/}
          {/*                    Valid passport copy (valid UAE visa for expatriates)*/}
          {/*                </TextBoldNormal>*/}
          {/*                <IconButton*/}
          {/*                    fullWidth*/}
          {/*                    sx={{*/}
          {/*                        borderRadius: '6px',*/}
          {/*                        padding: 0,*/}
          {/*                        '&:last-child': {*/}
          {/*                            mr: 0*/}
          {/*                        },*/}
          {/*                        color: colorsConfig.mainBlue,*/}
          {/*                        position: 'relative',*/}
          {/*                        height: '57px'*/}
          {/*                    }}*/}
          {/*                >*/}
          {/*                    <a download href={''} target="_blank">*/}
          {/*                        <FileOpen*/}
          {/*                            sx={{*/}
          {/*                                width: '60px',*/}
          {/*                                fontSize: '50px',*/}
          {/*                                fill: colorsConfig.mainBlue,*/}
          {/*                                color: colorsConfig.mainBlue*/}
          {/*                            }}*/}
          {/*                        />*/}
          {/*                    </a>*/}

          {/*                </IconButton>*/}
          {/*            </Box>*/}
          {/*        </Box>*/}
          {/*    </Box>*/}
          {/*</Box>*/}

          {/*<CollapseWrapper*/}
          {/*    title={'Car details'}*/}
          {/*>*/}
          {/*    {*/}
          {/*        combinedStatus === 'car_workflow.new' ? null : (*/}
          {/*            <Box*/}
          {/*                sx={{*/}
          {/*                    mb: 2*/}
          {/*                }}*/}
          {/*            >*/}
          {/*                <Tabs*/}
          {/*                    value={tab}*/}
          {/*                    onChange={(e, newVal) => {*/}
          {/*                        setTab(newVal)*/}
          {/*                    }}*/}
          {/*                    variant="scrollable"*/}
          {/*                    sx={{*/}
          {/*                        width: '100%',*/}
          {/*                        '& .MuiTabs-indicator': {*/}
          {/*                            background: colorsConfig.mainGreen*/}
          {/*                        },*/}
          {/*                        '& .MuiTab-textColorPrimary': {*/}
          {/*                            color: colorsConfig.mainBlack,*/}
          {/*                            textTransform: 'none',*/}
          {/*                            fontFamily: '"HelveticaNeue", sans-serif',*/}
          {/*                            fontSize: '16px',*/}
          {/*                            fontWeight: 500*/}
          {/*                        },*/}
          {/*                        '& .MuiButtonBase-root': {*/}
          {/*                            background: colorsConfig.bgLightBlue*/}
          {/*                        },*/}
          {/*                        '& .Mui-selected': {*/}
          {/*                            color: colorsConfig.mainBlack*/}
          {/*                        }*/}
          {/*                    }}*/}
          {/*                >*/}
          {/*                    {*/}
          {/*                        sections.map((section, index) => {*/}
          {/*                            return <Tab*/}
          {/*                                key={index}*/}
          {/*                                label={section.name}*/}
          {/*                                sx={{*/}
          {/*                                    fontSize: '13px'*/}
          {/*                                }}*/}
          {/*                            />*/}
          {/*                        })*/}
          {/*                    }*/}
          {/*                </Tabs>*/}
          {/*            </Box>*/}
          {/*        )*/}
          {/*    }*/}

          {/*    { sections[tab].component }*/}
          {/*</CollapseWrapper>*/}
        </Box>
      )}
    </Box>
  )
}

export default OperatorFinancingRequest
