import { createSlice } from '@reduxjs/toolkit'
import { addNewCarReducer } from '../addNewCarSlice/addNewCar.reducer'
import getInitialMakeAndModelBuilder from './builders/getInitialMakeAndModel.builder'
import createCarBuilder from './builders/createCar.builder'
import attachAppraisalDocumentBuilder from './builders/attachAppraisalDocument.builder'

const initialState = {
  init: {
    isLoading: false,
  },
  createdCarData: null,
  carCreatedId: null,
  carFirstData: {
    valid: false,
    vin: {
      value: '',
      valid: false,
      errorText: '',
    },
    make: {
      name: '',
      id: null,
    },
    model: {
      name: '',
      id: null,
    },
    company: null,
  },
  policy: null,
  carMainData: {
    referenceNumber: '',
    type: '',
    details: '',
    mileage: '',
    engineNumber: '',
    interiorColor: '',
    exteriorColor: '',
    paintwork: [],
    upholstery: '',
    wheelLocation: 'left',
    rims: '',
    bodyType: null,
    engineType: null,
    hp: '',
    origin: null,
    engineSize: '',
    cylinder: '',
    gearbox: null,
    driveType: null,
    newPriceWithOptions: '',
    gearsNumber: '',
    seatsNumber: '',
    doorsNumber: '',
    bookingValue: '',
    weight: '',
    additionalInfo: '',
    CO2Emission: '',
    fuelConsumption: null,
    energyEfficiencyCategory: '',
    electricityConsumption: '',
    euroNorm: '',
    carHiddenPriceShow: false,
    firstRegistrationDate: '',
  },
  carSpecsOptions: null,
}

const rviCarSlice = createSlice({
  name: 'rviCarReducer',
  initialState,
  reducers: {
    // setRviCarCompany: (state, action) => {
    //   state.carFirstData.company = action.payload
    // },
    setRviCarPolicy: (state, action) => {
      state.policy = action.payload
      const details = action.payload.details
      state.carFirstData.make = {
        id: details.make?.['@id'],
        name: details.make?.name,
      }
      state.carFirstData.model = {
        id: details.model?.['@id'],
        name: details.model?.name,
      }
      state.carFirstData.vin.value = details.vin
      state.carFirstData.vin.valid = true
      state.carMainData.mileage = details?.mileage
      state.carMainData.firstRegistrationDate = details?.firstRegistrationDate
      state.carMainData.exteriorColor = details?.color
    },
    setVin: (state, action) => {
      state.carFirstData.vin = action.payload
      state.carFirstData.valid = !!(
        state.carFirstData.vin.valid &&
        state.carFirstData.make.id &&
        state.carFirstData.model.id
      )
    },
    setMake: (state, action) => {
      state.carFirstData.make = action.payload
      if (action.payload.id === null || action.payload.name === '') {
        state.carFirstData.model.name = ''
        state.carFirstData.model.id = null
      }
      state.carFirstData.valid = !!(
        state.carFirstData.vin.valid &&
        state.carFirstData.make.id &&
        state.carFirstData.model.id
      )
    },
    setModel: (state, action) => {
      state.carFirstData.model = action.payload
      if (action.payload.name === '') {
        state.carFirstData.model.id = null
      }
      state.carFirstData.valid = !!(
        state.carFirstData.vin.valid &&
        state.carFirstData.make.id &&
        state.carFirstData.model.id
      )
    },
    setCarCreatedId: (state, action) => {
      state.carCreatedId = action.payload
    },
    setCarMainData: (state, action) => {
      state.carMainData = action.payload
    },
    resetCarData: (state) => {
      state.car = initialState.car
      state.carCreatedId = initialState.carCreatedId
      state.carFirstData = initialState.carFirstData
      state.carMainData = initialState.carMainData
      state.carSpecsOptions = initialState.carSpecsOptions
    },
    setFirstDataValid: (state) => {
      state.carFirstData.valid = true
    },
  },
  extraReducers: (builder) => {
    getInitialMakeAndModelBuilder(builder)
    createCarBuilder(builder)
    attachAppraisalDocumentBuilder(builder)
  },
})

export const {
  setRviCarCompany,
  setRviCarPolicy,
  setVin,
  setMake,
  setModel,
  setCarCreatedId,
  setCarMainData,
  resetCarData,
  setFirstDataValid,
} = rviCarSlice.actions

export default rviCarSlice.reducer
