import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, IconButton, Modal } from '@mui/material'
import { Close } from '@mui/icons-material'
import { colorsConfig } from '../../config/themeConfig'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import TextNormal from '../../ui/Text/TextNormal'
import TextFieldSmallEdible from '../../ui/TextFieldSmallEdible'
import ButtonBlueSmall from '../../ui/Buttons/ButtonBlueSmall'
import { setToken } from '../../store/features/authSlice'

const DevModal = (props) => {
  const { open, setOpen } = props

  const token = useSelector((state) => state.auth.token)
  const mercureToken = useSelector((state) => state.auth.mercureToken)
  const dispatch = useDispatch()

  const [localToken, setLocalToken] = useState('')
  const [editing, setEditing] = useState(false)

  const handleCopy = () => {
    navigator.clipboard.writeText(localToken)
  }

  useEffect(() => {
    setLocalToken(token)
  }, [token])

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '95vw',
          background: 'white',
          borderRadius: '10px',
          padding: '30px',
          maxHeight: '80vh',
          overflow: 'auto',
        }}
        className={'disable-scrollbar'}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          <Close
            style={{
              fill: colorsConfig.mainRed,
            }}
          />
        </IconButton>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextBoldNormal
              sx={{
                fontSize: '24px',
              }}
            >
              Configuration
            </TextBoldNormal>
          </Grid>
          <Grid item xs={10}>
            <TextFieldSmallEdible
              fullWidth
              label={'token'}
              value={localToken}
              onChange={(e) => {
                setLocalToken(e.target.value)
              }}
              disabled={!editing}
            />
          </Grid>
          <Grid item xs={1}>
            <ButtonBlueSmall
              fullWidth
              onClick={() => {
                handleCopy()
              }}
            >
              Copy
            </ButtonBlueSmall>
          </Grid>
          <Grid item xs={1}>
            {editing ? (
              <ButtonBlueSmall
                fullWidth
                onClick={() => {
                  setEditing(false)
                  dispatch(setToken(localToken))
                }}
              >
                Save
              </ButtonBlueSmall>
            ) : (
              <ButtonBlueSmall
                fullWidth
                onClick={() => {
                  setEditing(true)
                }}
              >
                Edit
              </ButtonBlueSmall>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextBoldNormal>Mercure token:</TextBoldNormal>
            <TextNormal>{mercureToken}</TextNormal>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default DevModal
