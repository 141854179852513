import React from 'react'
import { CloseRounded } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const UICloseButton = ({ sx, ...props }) => {
  return (
    <IconButton
      sx={{
        borderRadius: '8px',
        width: '30px',
        height: '30px',
        '& svg': {
          fontSize: '20px',
          fill: colorsConfig.mainRed,
          transition: '0.25s all ease-in-out',
        },
        '&:hover': {
          '& svg': {
            fill: colorsConfig.darkRed,
            transition: '0.25s all ease-in-out',
          },
        },
        ...sx,
      }}
      {...props}
    >
      <CloseRounded />
    </IconButton>
  )
}

export default UICloseButton
