import React from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'

const FormDate = ({ label, id, required, disableFuture, disablePast, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers
  const { t } = useTranslation()

  const handleChange = (value) => {
    setValue(value)
  }

  return (
    <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        label={t(label)}
        inputFormat="DD.MM.YYYY"
        mask={'__.__.____'}
        disableMaskedInput={false}
        renderInput={(params) => (
          <TextFieldSmallEdible
            {...params}
            fullWidth
            id={id}
            name={field.name}
            value={field.value}
            error={meta.error && meta.touched}
            helperText={meta.error && meta.touched ? meta.error : ''}
          />
        )}
        onChange={handleChange}
        value={field.value}
        views={['year', 'month', 'day']}
        disableFuture={disableFuture}
        disablePast={disablePast}
        {...props}
      />
    </LocalizationProvider>
  )
}

export default FormDate
