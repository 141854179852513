import React, { useEffect } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { checkAuth } from '../../store/features/authSlice'

const AuthMobile = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleAuthFromMobile = async () => {
    const queryLine = location.search
    const token = queryLine.slice(queryLine.indexOf('?tkn=') + 5, queryLine.indexOf('&page'))
    const page = queryLine.slice(queryLine.indexOf('&page=') + 6)
    if (token) {
      await localStorage.setItem('token', token)
      await localStorage.setItem('mobile_app_link', page)
      dispatch(checkAuth())
     // navigate(page)
    }
  }

  useEffect(() => {
    handleAuthFromMobile()
  }, [])

  return (
    <Box
      sx={{
        position: 'fixed',
        height: '100vh',
        width: '100vh',
        top: 0,
        left: 0,
      }}
    >
      <CircularProgress />
    </Box>
  )
}

export default AuthMobile
