import React, { useState } from 'react'
import { Box, Button, IconButton, Modal, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import TextNormal from '../../../ui/Text/TextNormal'
import IconCoin from '../../../assests/img/icons/icon-fleetify-coin.svg'
import ButtonDangerSmall from '../../../ui/Buttons/ButtonDangerSmall'
import ButtonSmall from '../../../ui/Buttons/ButtonSmall'
import ButtonBlueSmall from '../../../ui/Buttons/ButtonBlueSmall'
import TextAreaSmall from '../../../ui/TextAreaSmall'
import { privateRequest } from '../../../utils/request'
import { API_ENDPOINTS } from '../../../config/default'
import { downloadFileFromPrivateBucket } from '../../../utils/downloadFile'
import IconDownload from '../../../assests/img/icons/icon-download.svg'
import { Close } from '@mui/icons-material'
import TextH1 from '../../../ui/Text/TextH1'
import ButtonDanger from '../../../ui/Buttons/ButtonDanger'
import ButtonSuccess from '../../../ui/Buttons/ButtonSuccess'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import { CURRENCY, REGION_LOCALE } from '../../../utils/constants/global.constants'

const CoinRequestItem = (props) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const mdOnly = useMediaQuery('(max-width: 1000px)')
  const xLarge = useMediaQuery('(max-width: 1400px)')

  const { request, handleReject, handleApprove, approved } = props

  const [rejected, setRejected] = useState(false)
  const [comment, setComment] = useState('')
  const [confirmModal, setConfirmModal] = useState(false)
  const [rejectModal, setRejectModal] = useState(false)

  const handleDownloadFile = () => {
    privateRequest.get(request.invoice).then((response) => {
      privateRequest
        .get(API_ENDPOINTS.privateFiles(response.data.file['@id'].replace('/files/', '')))
        .then(async (fileResponse) => {
          await downloadFileFromPrivateBucket(
            fileResponse.data.signedUrl,
            request.companyName
              ? `Invoice for ${request.companyName} (${request.amount * 4} ${CURRENCY[REGION_LOCALE]})`
              : `Invoice (${request.amount * 4} AED)`,
          )
        })
    })
  }

  const getMarkerBackgroundColor = (createdAt) => {
    const createdAtTime = new Date(createdAt).getTime()
    const currentTime = new Date().getTime()
    const timeDifferenceInHours = (currentTime - createdAtTime) / 3600000

    if (timeDifferenceInHours > 24) {
      return '#AC0000'
    } else if (timeDifferenceInHours > 20) {
      return '#F5BD4F'
    } else {
      return '#4DED6D'
    }
  }

  const getFormattedDate = (date) => {
    const yyyy = date.getFullYear()
    let mm = date.getMonth() + 1
    let dd = date.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm
    return dd + '.' + mm + '.' + yyyy
  }

  return (
    <Box
      sx={{
        mb: '24px',
        borderRadius: xsOnly && '6px',
        border: xsOnly && '1px solid',
        borderColor: xsOnly && '#DCDCDC',
      }}
    >
      <Box
        sx={{
          // width: (approved && !xsOnly) ? '70%' : '100%',
          width: '100%',
          height: !xLarge && '69px',
          background: colorsConfig.bgLightBlue,
          px: xsOnly ? '21px' : '30px',
          py: xLarge ? '10px' : xsOnly ? '21px' : 'initial',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          alignItems: 'center',
          borderRadius: !xsOnly && '6px',
          border: !xsOnly && '1px solid',
          borderColor: !xsOnly && '#DCDCDC',
          pt: xLarge && !mdOnly ? '10px' : mdOnly && !xsOnly ? '25px' : xsOnly && '21px',
          pb: xLarge && !mdOnly ? '10px' : mdOnly && !xsOnly ? 0 : xsOnly && '21px',
        }}
      >
        <Box
          sx={{
            width: xsOnly ? '100%' : mdOnly ? '450px' : 'fit-content',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: xLarge ? 'flex-start' : 'space-between',
            alignItems: 'center',
            mb: xLarge && '10px',
            flexWrap: 'wrap',
          }}
        >
          <TextBoldNormal
            sx={{
              fontSize: '18px',
              mr: '6px',
            }}
          >
            {request.companyName}
          </TextBoldNormal>
          <TextNormal>({request.managerName})</TextNormal>
        </Box>
        <Box
          sx={{
            width: xLarge ? '100%' : 'fit-content',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: xsOnly && '10px',
          }}
        >
          <Box
            sx={{
              width: xsOnly ? '100%' : 'fit-content',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              mr: '9px',
              minWidth: '175px',
            }}
          >
            <img
              src={IconCoin}
              alt=""
              style={{
                width: '18px',
                height: '18px',
                mr: '5px',
              }}
            />
            <TextBoldNormal
              sx={{
                fontSize: '18px',
                ml: '4px',
                mr: '6px',
              }}
            >
              Amount:
            </TextBoldNormal>
            <TextNormal
              sx={{
                px: '19px',
                py: '6px',
                borderRadius: '6px',
                border: '1px solid',
                borderColor: colorsConfig.mainBlack,
              }}
            >
              {request.amount}
            </TextNormal>
          </Box>
          <Box
            sx={{
              width: xsOnly ? '100%' : 'fit-content',
              display: 'flex',
              justifyContent: xsOnly ? 'flex-start' : 'space-between',
            }}
          >
            <Box
              sx={{
                mr: !xsOnly && '9px',
                ml: xsOnly && '-8px',
              }}
            >
              <IconButton
                onClick={() => {
                  handleDownloadFile()
                }}
              >
                <img src={IconDownload} alt="" />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mr: !approved && !xLarge ? '40px' : 0,
              }}
            >
              {!approved ? (
                <Box
                  sx={{
                    width: '18px',
                    height: '18px',
                    borderRadius: '50%',
                    background: getMarkerBackgroundColor(request.date),
                    mr: '9px',
                  }}
                />
              ) : null}
              <TextNormal>{getFormattedDate(new Date(request.date))}</TextNormal>
            </Box>
          </Box>
          {!approved ? (
            <Box
              sx={{
                position: 'relative',
                top: xLarge && !mdOnly ? '-18px' : mdOnly && !xsOnly && '-32px',
                gap: xLarge && !xsOnly && '19px',
                marginLeft: xLarge && !xsOnly && 'auto',
                width: xsOnly ? '100%' : 'fit-content',
                display: 'flex',
                flexDirection: mdOnly && !xsOnly ? 'column' : 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mt: xsOnly && '5px',
              }}
            >
              <ButtonDangerSmall
                className={rejected ? 'active' : ''}
                sx={{
                  width: xsOnly ? '50%' : '115px',
                  mr: mdOnly && !xsOnly ? 0 : '16px',
                  borderColor: '#AC0000',
                  color: '#AC0000',
                  '&:hover': {
                    color: 'white',
                    background: '#AC0000',
                  },
                  '&.active': {
                    color: 'white',
                    background: '#AC0000',
                  },
                }}
                onClick={() => {
                  setRejected(!rejected)
                }}
              >
                Reject
              </ButtonDangerSmall>
              <ButtonBlueSmall
                sx={{
                  width: xsOnly ? '50%' : '115px',
                }}
                onClick={() => {
                  setConfirmModal(true)
                }}
              >
                Approve
              </ButtonBlueSmall>
            </Box>
          ) : null}
        </Box>
      </Box>
      {rejected ? (
        <Box
          sx={{
            background: colorsConfig.bgLightBlue,
            padding: xsOnly && '9px 21px 21px',
            mt: !xsOnly && '10px',
            position: 'relative',
          }}
        >
          <TextAreaSmall
            fullWidth
            multiline
            rows={7}
            placeholder={'Write the reason of rejection'}
            label={'Write the reason of rejection'}
            value={comment}
            onChange={(e) => {
              setComment(e.target.value)
            }}
            style={{
              background: 'white',
            }}
          />
          <Button
            sx={{
              textTransform: 'none',
              position: 'absolute',
              bottom: xsOnly ? '28px' : '7px',
              right: xsOnly ? '28px' : '7px',
              textDecoration: 'underline',
              color: colorsConfig.mainBlue,
              fontWeight: 700,
            }}
            disabled={!comment}
            onClick={() => {
              setRejectModal(true)
            }}
          >
            Send
          </Button>
        </Box>
      ) : null}
      <Modal
        open={confirmModal}
        onClose={() => {
          setConfirmModal(false)
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: xsOnly ? '90%' : 650,
            background: 'white',
            borderRadius: '10px',
            padding: '30px',
            maxHeight: '80vh',
            overflow: 'auto',
          }}
          className={'disable-scrollbar'}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: '5px',
              right: '5px',
            }}
            onClick={() => {
              setConfirmModal(false)
            }}
          >
            <Close
              style={{
                fill: colorsConfig.mainRed,
              }}
            />
          </IconButton>
          <TextH1
            sx={{
              mb: '16px',
              fontSize: xsOnly ? '24px' : '30px',
              lineHeight: 'normal',
              textAlign: 'center',
            }}
          >
            Deposit confirmation
          </TextH1>
          <TextNormal
            sx={{
              mb: '30px',
              fontSize: xsOnly ? '18px' : '22px',
              lineHeight: 'normal',
              textAlign: 'center',
            }}
          >
            Are you sure you want to approve <br />
            <span style={{ fontWeight: 700 }}>{request.amount} Fleatify coins</span> for the company{' '}
            <span style={{ fontWeight: 700 }}>{request.companyName}</span>?
          </TextNormal>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <ButtonDanger
              sx={{
                width: 'calc(50% - 4px)',
              }}
              onClick={() => {
                setConfirmModal(false)
              }}
            >
              Cancel
            </ButtonDanger>
            <ButtonSuccess
              sx={{
                width: 'calc(50% - 4px)',
              }}
              onClick={() => {
                handleApprove(request['@id'])
              }}
            >
              Approve
            </ButtonSuccess>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={rejectModal}
        onClose={() => {
          setRejectModal(false)
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: xsOnly ? '90%' : 650,
            background: 'white',
            borderRadius: '10px',
            padding: '30px',
            maxHeight: '80vh',
            overflow: 'auto',
          }}
          className={'disable-scrollbar'}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: '5px',
              right: '5px',
            }}
            onClick={() => {
              setRejectModal(false)
            }}
          >
            <Close
              style={{
                fill: colorsConfig.mainRed,
              }}
            />
          </IconButton>
          <TextH1
            sx={{
              mb: '16px',
              fontSize: xsOnly ? '24px' : '30px',
              lineHeight: 'normal',
              textAlign: 'center',
            }}
          >
            Confirm rejection
          </TextH1>
          <TextNormal
            sx={{
              mb: '30px',
              fontSize: xsOnly ? '18px' : '22px',
              lineHeight: 'normal',
              textAlign: 'center',
            }}
          >
            Are you sure you want to reject <br />
            the coin request?
          </TextNormal>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <ButtonHollow
              sx={{
                width: 'calc(50% - 4px)',
              }}
              onClick={() => {
                setRejectModal(false)
              }}
            >
              Cancel
            </ButtonHollow>
            <ButtonDanger
              sx={{
                width: 'calc(50% - 4px)',
              }}
              onClick={() => {
                setRejected(false)
                handleReject(request['@id'], comment)
              }}
            >
              Reject
            </ButtonDanger>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default CoinRequestItem
