import { getCompanies } from '../companies.actions'
import { apiConstants } from '../../../../api/api'

export default (builder) => {
  builder
    .addCase(getCompanies.pending, (state) => {
      state.companies.isLoading = true
    })
    .addCase(getCompanies.fulfilled, (state, action) => {
      state.companies.isLoading = false
      state.companies.error = null
      state.companies.data = action.payload?.[apiConstants.hydraMember]
      state.companies.total = action.payload?.[apiConstants.hydraTotal]
    })
    .addCase(getCompanies.rejected, (state, action) => {
      state.companies.isLoading = false
      state.companies.error = action.payload
    })
}
