import React from 'react'
import { colorsConfig } from '../../config/themeConfig'
import TextBoldNormal from '../Text/TextBoldNormal'

const UiMarker = (props) => {
  const { text, sx, markerVariant, ...rest } = props

  return (
    <TextBoldNormal
      className={markerVariant}
      sx={{
        height: '24px',
        px: '10px',
        borderRadius: '100px',
        color: 'white',
        fontSize: '14px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        '&.success': {
          background: colorsConfig.mainGreen,
        },
        '&.attention': {
          background: colorsConfig.buttonYellow,
        },
        '&.error': {
          background: colorsConfig.mainRed,
        },
        ...sx,
      }}
      {...rest}
    >
      {text}
    </TextBoldNormal>
  )
}

export default UiMarker
