export const appraisalsRequestsTableHeadCells = [
  {
    title: 'Number',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Image',
    sortable: false,
  },
  {
    title: 'Brand/Model',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Client',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Request date',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
]

export const auctionsRequestsTableHeadCells = [
  {
    title: 'Number',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Image',
    sortable: false,
  },
  {
    title: 'Brand/Model',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Client',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
  {
    title: 'Request date',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
  },
]
