import React from 'react'
import { Button } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const ButtonLink = ({ sx, children, ...props }) => {
  return (
    <Button
      sx={{
        textTransform: 'none',
        textDecoration: 'underline',
        color: colorsConfig.mainBlue,
        transition: '0.25s all ease-in-out',
        '&:hover': {
          textDecoration: 'underline',
          color: colorsConfig.buttonHover,
          transition: '0.25s all ease-in-out',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  )
}

export default ButtonLink
