import React from 'react'
import { Grid, Box } from '@mui/material'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import UIInputAdornmentText from '../../../ui/UIFormComponents/UIInputAdornmentText'
import { sumDelimiter } from '../../../utils/sumDelimiter'
import TextNormal from '../../../ui/Text/TextNormal'
import CheckmarkBlack from '../../../ui/CheckmarkBlack'
import { useTranslation } from 'react-i18next'
import { colorsConfig } from '../../../config/themeConfig'

const CarPriceFields = ({ isCarDataAutofilled, formData, setFormData }) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={3}>
      {isCarDataAutofilled && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <TextFieldSmallEdible
              fullWidth
              label="form_input_label_gross_price_without_options"
              value={
                formData?.grossPriceWithoutOptions
                  ? sumDelimiter(formData?.grossPriceWithoutOptions)
                  : ''
              }
              inputProps={{
                readOnly: true,
              }}
              InputProps={{
                endAdornment: <UIInputAdornmentText text="CHF" />,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextFieldSmallEdible
              fullWidth
              label="form_input_label_options_price"
              value={formData?.optionsPrice ? sumDelimiter(formData?.optionsPrice) : ''}
              inputProps={{
                readOnly: true,
              }}
              InputProps={{
                endAdornment: <UIInputAdornmentText text="CHF" />,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextFieldSmallEdible
              fullWidth
              label="form_input_label_gross_price_with_options"
              value={
                formData?.grossPriceWithOptions ? sumDelimiter(formData?.grossPriceWithOptions) : ''
              }
              inputProps={{
                readOnly: true,
              }}
              InputProps={{
                endAdornment: <UIInputAdornmentText text="CHF" />,
              }}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Box
          sx={{
            height: {
              xs: 'auto',
              sm: '71px',
            },
            borderRadius: '4px',
            display: {
              xs: 'block',
              sm: 'flex',
            },
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            px: '21px',
            py: {
              xs: '23px',
              sm: 0,
            },
            border: '1px solid',
            borderColor: colorsConfig.buttonDisabled,
            mb: '40px',
          }}
        >
          <TextNormal
            sx={{
              mr: {
                xs: 0,
                sm: '17px',
              },
              mb: {
                xs: '12px',
                sm: 0,
              },
            }}
          >
            {t('title_private_information')}:
          </TextNormal>
          <Box
            sx={{
              width: {
                xs: '100%',
                sm: '30%',
              },
              mr: {
                xs: 0,
                sm: '20px',
              },
              mb: {
                xs: '10px',
                sm: 0,
              },
            }}
          >
            <TextFieldSmallEdible
              fullWidth
              label="form_input_label_purchase_price"
              type={'text'}
              inputProps={{
                inputMode: 'numeric',
              }}
              id="gross-price-with-options"
              value={sumDelimiter(formData.purchasePrice)}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  purchasePrice: e.target.value.replace(/[^0-9]/g, ''),
                })
              }}
              InputProps={{
                endAdornment: <UIInputAdornmentText text="CHF" />,
              }}
            />
          </Box>
          <Box
            sx={{
              width: {
                xs: '100%',
                sm: '30%',
              },
              mr: {
                xs: 0,
                sm: '20px',
              },
              mb: {
                xs: '12px',
                sm: 0,
              },
            }}
          >
            <TextFieldSmallEdible
              fullWidth
              label="form_input_label_booking_value"
              type={'text'}
              id="booking value"
              value={sumDelimiter(formData.bookingValue)}
              inputProps={{
                inputMode: 'numeric',
              }}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  bookingValue: e.target.value.replace(/[^0-9]/g, ''),
                })
              }}
              InputProps={{
                endAdornment: <UIInputAdornmentText text="CHF" />,
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: {
                xs: 'flex-start',
                sm: 'center',
              },
              alignItems: 'center',
            }}
          >
            <CheckmarkBlack
              value={formData.carHiddenPriceShow}
              setValue={(newVal) => {
                setFormData({
                  ...formData,
                  carHiddenPriceShow: newVal,
                })
              }}
            />
            <TextNormal
              sx={{
                ml: '6px',
              }}
            >
              {t('form_input_label_show_to_others')}
            </TextNormal>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default CarPriceFields
