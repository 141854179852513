import * as Yup from 'yup'
import { formErrors } from '../../formErrors'

export const addManagerFormValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string().required(formErrors.required),
  firstName: Yup.string().required(formErrors.required),
  surname: Yup.string().required(formErrors.required),
  company: Yup.string().required(formErrors.required),
  login: Yup.string().required(formErrors.required),
  email: Yup.string().required(formErrors.required).email(formErrors.invalidEmail),
})
