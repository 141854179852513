import React from 'react'
import UIIconEdit from '../UIIcons/UIIconEdit'
import UIIconButton from '../UIButtons/UIIconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { useTranslation } from 'react-i18next'

const UIEditIconButton = ({ sx, text, ...props }) => {
  const { t } = useTranslation()
  return (
    <Tooltip title={t(text)}>
      <UIIconButton
        sx={{ width: '40px', height: '40px', p: '8px', pr: '2px', borderRadius: '4px', ...sx }}
        {...props}
      >
        <UIIconEdit />
      </UIIconButton>
    </Tooltip>
  )
}

export default UIEditIconButton
