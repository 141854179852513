import React from 'react'
import { Button } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const ButtonHollow = ({ children, sx, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        height: '48px',
        borderRadius: '4px',
        paddingLeft: {
          xl: '50px',
          md: '30px',
        },
        paddingRight: {
          xl: '50px',
          md: '30px',
        },
        background: 'transparent',
        color: colorsConfig.mainBlue,
        textTransform: 'none',
        fontWeight: 700,
        fontSize: '16px',
        border: '1px solid',
        borderColor: colorsConfig.mainBlue,
        whiteSpace: 'nowrap',
        transition: '0.25s all ease-in-out',
        '& svg': {
          fill: colorsConfig.mainBlue,
          transition: '0.25s all ease-in-out',
        },
        '&:hover': {
          background: colorsConfig.mainBlue,
          color: 'white',
          transition: '0.25s all ease-in-out',
          '& svg': {
            fill: colorsConfig.white,
            transition: '0.25s all ease-in-out',
          },
        },
        '&.Mui-disabled': {
          background: colorsConfig.buttonDisabled,
          borderColor: colorsConfig.buttonDisabled,
          color: 'white',
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  )
}

export default ButtonHollow
