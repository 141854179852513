import React from 'react'
import { Box, Grid } from '@mui/material'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import TextNormal from '../../../ui/Text/TextNormal'
import { handleFormatDate } from '../../../utils/formatDate'
import { colorsConfig } from '../../../config/themeConfig'

const AdminPanelUserCompanyInfo = (props) => {
  const { company } = props

  return (
    <Box>
      <TextBoldNormal
        sx={{
          fontSize: '24px',
          mb: '20px',
        }}
      >
        Company information
      </TextBoldNormal>
      <BoxShadowed>
        <Grid container spacing={'20px'}>
          <Grid item xs={12} sm={6} md={3}>
            <TextNormal
              sx={{
                fontSize: '14px',
                color: colorsConfig.lightGray,
              }}
            >
              Company name
            </TextNormal>
            <TextNormal>{company?.name}</TextNormal>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextNormal
              sx={{
                fontSize: '14px',
                color: colorsConfig.lightGray,
              }}
            >
              Landline number
            </TextNormal>
            <TextNormal>{company?.landlineNumber?.replace('971', '+ 971 ')}</TextNormal>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextNormal
              sx={{
                fontSize: '14px',
                color: colorsConfig.lightGray,
              }}
            >
              Trade license number
            </TextNormal>
            <TextNormal>{company?.tradeLicenseNumber}</TextNormal>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextNormal
              sx={{
                fontSize: '14px',
                color: colorsConfig.lightGray,
              }}
            >
              Trade license expiry date
            </TextNormal>
            <TextNormal>{handleFormatDate(company?.tradeLicenseExpiryDate, '.')}</TextNormal>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextNormal
              sx={{
                fontSize: '14px',
                color: colorsConfig.lightGray,
              }}
            >
              Tax registration number
            </TextNormal>
            <TextNormal>{company?.taxRegistrationNumber || '-'}</TextNormal>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextNormal
              sx={{
                fontSize: '14px',
                color: colorsConfig.lightGray,
              }}
            >
              Country
            </TextNormal>
            <TextNormal>{company?.address?.country || '-'}</TextNormal>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextNormal
              sx={{
                fontSize: '14px',
                color: colorsConfig.lightGray,
              }}
            >
              {company?.address?.country === 'United Arab Emirates' ? 'Emirate' : 'State'}
            </TextNormal>
            <TextNormal>{company?.address?.state || '-'}</TextNormal>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextNormal
              sx={{
                fontSize: '14px',
                color: colorsConfig.lightGray,
              }}
            >
              Postcode
            </TextNormal>
            <TextNormal>{company?.address?.postcode || '-'}</TextNormal>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextNormal
              sx={{
                fontSize: '14px',
                color: colorsConfig.lightGray,
              }}
            >
              Address Line 1
            </TextNormal>
            <TextNormal>{company?.address?.addressLine1 || '-'}</TextNormal>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextNormal
              sx={{
                fontSize: '14px',
                color: colorsConfig.lightGray,
              }}
            >
              Address Line 2
            </TextNormal>
            <TextNormal>{company?.address?.addressLine2 || '-'}</TextNormal>
          </Grid>
        </Grid>
      </BoxShadowed>
    </Box>
  )
}

export default AdminPanelUserCompanyInfo
