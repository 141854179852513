import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  updatesCompleted: false,
  hints: [],
  sections: [
    {
      name: 'Vehicle Details',
      systemName: 'vehicle_details',
      approved: true,
      hint: '',
      checked: false,
    },
    {
      name: 'Documents',
      systemName: 'subblock',
      approved: true,
      hint: '',
      checked: false,
      entityId: '/reference/appraisal/subblocks/1',
    },
    {
      name: 'Photos',
      systemName: 'photos',
      approved: true,
      hint: '',
      checked: false,
    },
    {
      name: 'Damages',
      systemName: 'damages',
      approved: true,
      hint: '',
      checked: false,
    },
    {
      name: 'Warning Lights',
      systemName: 'pilot_lamps',
      approved: true,
      hint: '',
      checked: false,
    },
    {
      name: 'Interior Conditions',
      systemName: 'subblock',
      approved: true,
      hint: '',
      checked: false,
      entityId: '/reference/appraisal/subblocks/2',
    },
    {
      name: 'Mechanical Conditions',
      systemName: 'subblock',
      approved: true,
      checked: true,
    },
  ],
  subBlocks: [],
  damages: [],
  photos: [],
}

export const qualityCheck = createSlice({
  name: 'qualityCheck',
  initialState: initialState,
  reducers: {
    setSectionHint: (state, action) => {
      state.sections[action.payload.index].hint = action.payload.value
    },
    setSectionApproved: (state, action) => {
      state.sections[action.payload.index].approved = action.payload.value
      state.sections[action.payload.index].checked = true
    },
    setSectionUnchecked: (state, action) => {
      state.sections[action.payload.index].approved = true
      state.sections[action.payload.index].checked = false
    },
    setSubBlocks: (state, action) => {
      state.subBlocks = action.payload
    },
    updateSubBlock: (state, action) => {
      state.subBlocks[action.payload.index].hint = action.payload.value
    },
    rejectSubBlock: (state, action) => {
      state.subBlocks[action.payload.index].approved = action.payload.value
    },
    setQualityCheckDamages: (state, action) => {
      state.damages = action.payload
    },
    setQualityCheckDamageHint: (state, action) => {
      state.damages[action.payload.index].hint = action.payload.value
      state.sections[3].checked = true
    },
    setQualityCheckDamageApproved: (state, action) => {
      state.damages[action.payload.index].approved = action.payload.value
    },
    setQualityCheckPhotos: (state, action) => {
      state.photos = action.payload
    },
    setQualityCheckPhotoHint: (state, action) => {
      state.photos[action.payload.index].hint = action.payload.value
      state.sections[2].checked = true
    },
    setQualityCheckPhotoApproved: (state, action) => {
      state.photos[action.payload.index].approved = action.payload.value
    },
    setHints: (state, action) => {
      state.hints = action.payload
    },
    fixHint: (state, action) => {
      state.hints[action.payload.index] = {
        ...state.hints[action.payload.index],
        fixed: true,
      }
    },
    resetQualityCheck: (state) => {
      state.sections = initialState.sections
      state.subBlocks = initialState.subBlocks
      state.damages = initialState.damages
      state.photos = initialState.photos
      state.hints = initialState.hints
    },
  },
})

export const {
  setSectionApproved,
  setSectionHint,
  setSubBlocks,
  updateSubBlock,
  rejectSubBlock,
  setQualityCheckDamages,
  setQualityCheckDamageHint,
  setQualityCheckDamageApproved,
  setQualityCheckPhotos,
  setQualityCheckPhotoHint,
  setQualityCheckPhotoApproved,
  setHints,
  resetQualityCheck,
  fixHint,
  setSectionUnchecked,
} = qualityCheck.actions

export default qualityCheck.reducer
