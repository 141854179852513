import React from 'react'
import UIModal from '../../../ui/UIModal/UIModal'
import { colorsConfig } from '../../../config/themeConfig'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import useBreakpoints from '../../../hooks/useBreakpoints'
import UITextHeadlineLarge from '../../../ui/UIText/UITextHeadlineLarge'

const VINAutofillSuccessModal = ({ open, setOpen }) => {
  const { isMaxWidth600 } = useBreakpoints()

  return (
    <UIModal width="688px" open={open} setOpen={setOpen}>
      <UITextHeadlineLarge
        sx={{
          fontSize: isMaxWidth600 ? '24px' : '40px',
          color: colorsConfig.mainBlack,
          textAlign: 'center',
        }}
        text="modal_p_vin_autofill_success"
      />
      <UITextRegular
        fontWeight="700"
        sx={{
          fontSize: isMaxWidth600 ? '84px' : '115px',
          textAlign: 'center',
        }}
      >
        👍
      </UITextRegular>
    </UIModal>
  )
}

export default VINAutofillSuccessModal
