import React from 'react'
import CarSpecCategory from './CarSpecCategory'
import { Grid } from '@mui/material'

const CarSpecs = (props) => {
  const { blocks, changeCarSpec, readOnly } = props

  return (
    <Grid item xs={12} spacing={'10px'}>
      {blocks.map((cat, index) => {
        if (readOnly && !cat.specs.filter((spec) => spec.value)[0]) return null
        return (
          <CarSpecCategory
            key={index}
            index={index}
            title={cat.name}
            catId={cat['@id']}
            specs={cat.specs}
            changeCarSpec={changeCarSpec}
            readOnly={readOnly}
          />
        )
      })}
    </Grid>
  )
}

export default CarSpecs
