import React from 'react'
import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'
import FormInput from '../../FormComponents/FormInput'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import FormUIDMaskedInput from '../../FormComponents/FormUIDMaskedInput/FormUIDMaskedInput'
import FormPhoneNumber from '../../FormComponents/FormPhoneNumber/FormPhoneNumber'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import FormUploadFile from '../../FormComponents/FormUploadFile/FormUploadFile'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createCompany } from '../../../../store/features/companiesSlice/companies.actions'
import { toast } from 'react-toastify'
import { addCompanyFormFormValidationSchema } from './addCompanyForm.helpers'

const AddCompanyForm = () => {
  const isLoading = useSelector((state) => state.companies.create.isLoading)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSubmit = async (values) => {
    const data = {
      ...values,
      taxRegistrationNumber: `${values?.tradeLicenseNumber} MWST`,
    }
    const res = await dispatch(createCompany({ data }))
    if (createCompany.fulfilled.match(res)) {
      toast.success('Company has been added successfully')
      navigate('/admin/companies')
    }
  }

  return (
    <Formik
      initialValues={{
        name: '',
        invoiceEmail: '',
        tradeLicenseNumber: '',
        tradeLicense: null,
        landlineNumber: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={addCompanyFormFormValidationSchema}
    >
      {(props) => (
        <Form>
          <Grid container spacing="30px">
            <Grid item xs={4}>
              <FormInput required label="Company name" name="name" id="name" />
            </Grid>
            <Grid item xs={4}>
              <FormInput required label="E-mail" name="invoiceEmail" id="email" />
            </Grid>
            <Grid item xs={4}>
              <FormPhoneNumber label="form_input_label_landline_number" name="landlineNumber" />
            </Grid>
            <Grid item xs={4}>
              <FormUIDMaskedInput
                required
                label="form_input_label_uid"
                name="tradeLicenseNumber"
                id="tradeLicenseNumber"
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldSmallEdible
                fullWidth
                required
                label="form_input_label_vat_nr"
                inputProps={{
                  inputMode: 'numeric',
                  readOnly: true,
                }}
                value={`${props.values?.tradeLicenseNumber} MWST`}
              />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={6}>
              <FormUploadFile label="form_input_label_upload_registry_copy" name="tradeLicense" />
            </Grid>
            <Grid xs={12} item display="flex" justifyContent="flex-end">
              <ButtonLG
                type="submit"
                disabled={isLoading || (props.touched && !props.isValid && props.submitCount > 0)}
              >
                Save company
              </ButtonLG>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default AddCompanyForm
