import React, { useState } from 'react'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import ButtonHollow from '../../../../../ui/Buttons/ButtonHollow'
import ButtonLG from '../../../../../ui/Buttons/ButtonLG'
import { colorsConfig } from '../../../../../config/themeConfig'
import IconAnchor from '../../../../../assests/img/icons/icon-anchor.svg'
import { patchRequest, privateRequest } from '../../../../../utils/request'
import { API_ENDPOINTS } from '../../../../../config/default'
import { toast } from 'react-toastify'
import FinanceLinkModal from '../../../../../components/Modals/FinanceLinkModal'
import {
  insuranceApplicationWorkflowEnums,
  insuranceWorkflowsEnums,
} from '../../../insurance.constants'
import insuranceApplicationService from '../../../../../api/insuranceService/insuranceApplicationService'

const CarInsuranceBlockResponsesControls = () => {
  const selectedTab = useSelector((state) => state.insurance.selectedTab)
  const application = useSelector((state) => state.insurance.applications[selectedTab])
  const deals = useSelector((state) => state.insurance.applications[selectedTab]?.deals)

  const [linkModal, setLinkModal] = useState(false)

  const handleSendEmailToCustomer = () => {
    Promise.all(
      deals.map((item) =>
        patchRequest.patch(API_ENDPOINTS.insurance.dealByApplication(application?.id, item?.id), {
          isDealerSelected: item.isDealerSelected,
        }),
      ),
    ).then((result) => {
      if (application?.currentWorkflowState === insuranceApplicationWorkflowEnums.repliesReceived) {
        insuranceApplicationService.transitionToDealerSelected(application.id)
      }
      privateRequest
        .post(API_ENDPOINTS.insurance.sendCustomerApprovalLink(application?.id), {})
        .then(() => {
          toast.success('The approval link has been sent to the customer')
        })
    })
  }

  const handleOpenLinkModal = () => {
    Promise.all(
      deals.map((item) =>
        patchRequest.patch(API_ENDPOINTS.insurance.dealByApplication(application?.id, item?.id), {
          isDealerSelected: item.isDealerSelected,
        }),
      ),
    ).then((result) => {
      if (application?.currentWorkflowState === insuranceWorkflowsEnums.QUOTES_RECEIVED) {
        insuranceApplicationService.transitionToDealerSelected(application.id)
      }
      setLinkModal(true)
    })
  }

  return !deals.find((item) => item.isCustomerSelected) ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '12px',
      }}
    >
      <ButtonHollow
        disabled={!deals.find((item) => item.isDealerSelected)}
        sx={{
          '&.xs-only': {
            width: '100%',
            mb: '8px',
          },
        }}
        onClick={() => handleSendEmailToCustomer()}
      >
        Send by email
      </ButtonHollow>
      <ButtonLG
        disabled={!deals.find((item) => item.isDealerSelected)}
        sx={{
          '&.Mui-disabled': {
            background: colorsConfig.buttonDisabled,
            color: 'white',
          },
          '&.xs-only': {
            width: '100%',
          },
        }}
        onClick={() => handleOpenLinkModal()}
      >
        <img
          src={IconAnchor}
          alt=""
          style={{
            marginRight: '6px',
          }}
        />
        Copy approval link
      </ButtonLG>
      <FinanceLinkModal
        open={linkModal}
        setOpen={setLinkModal}
        customerName={application?.customer?.person?.firstName}
        link={`https://sb-auction.insercle.com/insurance/customer/insurance-applications/${application?.secureHash}`}
      />
    </Box>
  ) : null
}

export default CarInsuranceBlockResponsesControls
