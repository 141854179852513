import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const UITablePreloader = ({ loading, children, sx, ...props }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        ...sx,
      }}
      {...props}
    >
      {loading ? (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '50%',
            mt: '-20px',
            left: '50%',
            ml: '-20px',
            color: colorsConfig.mainBlue,
          }}
        />
      ) : null}
      <Box
        sx={{
          opacity: loading ? 0.5 : 1,
          pointerEvents: loading ? 'none' : 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default UITablePreloader
