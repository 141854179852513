import React from 'react'
import UITextLabelLarge from '../../../../ui/UIText/UITextLabelLarge'
import { Box, Button, Grid, IconButton, styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { FileUploader } from 'react-drag-drop-files'
import IconDragAndDrop from '../../../../assests/img/icons/icon-drag-and-drop.svg'
import UITextRegular from '../../../../ui/UIText/UITextRegular'
import { colorsConfig } from '../../../../config/themeConfig'
import IconAppraisalDocument from '../../../../assests/img/icons/icon-appraisal-document.svg'
import { CancelRounded } from '@mui/icons-material'
import { attachAppraisalDocument } from '../../../../store/features/addNewCarSlice/addNewCar.actions'

const UploadAppraisalDocument = () => {
  const car = useSelector((state) => state.addCar.createdCarData)
  const dispatch = useDispatch()

  const handleUploadFile = (files) => {
    dispatch(attachAppraisalDocument({ file: Array.from(files)?.[0] }))
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  return (
    <Box>
      <UITextLabelLarge
        text="Upload the appraisal document"
        sx={{ mb: '20px', fontSize: '20px' }}
      />
      <Grid container>
        <Grid item xs={12} md={4}>
          <FileUploader
            handleChange={handleUploadFile}
            onSelect={handleUploadFile}
            onDrop={handleUploadFile}
            name="file"
            types={['JPG', 'PNG', 'jpeg', 'pdf', 'docs', 'xlsx']}
            hoverTitle="Drop here"
            label="Upload or drop all the photos here"
            classes="upload-picture"
            multiple
            maxSize={10}
            children={
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px',
                  height: '150px',
                  borderRadius: '8px',
                  backgroundColor: '#F7FAFB',
                  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23007DFF' stroke-width='3' stroke-dasharray='10%2c 10' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e")`,
                }}
              >
                <img src={IconDragAndDrop} alt="" />
                <UITextRegular
                  sx={{ color: colorsConfig.iconGray }}
                  text="Drag and drop file here"
                />
              </Box>
            }
            sx={{
              width: '100%',
              position: 'relative',
              'label.is-disabled': {
                border: 'none',
                backgroundColor: '#F7FAFB',
                backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23007DFF' stroke-width='3' stroke-dasharray='10%2c 10' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e")`,
                borderRadius: '6px',
              },
              '& .MuiTypography-root': {
                width: '100px',
                marginTop: '-3px',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={1} />
        <Grid item xs={12} md={4}>
          {car?.appraisalDocument ? (
            <Box
              //  className={xsOnly ? 'xs-only' : ''}
              // key={index}
              sx={{
                mt: '27px',
                width: '120px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                cursor: 'pointer',
                '&.xs-only': {
                  width: 'calc(25% - 5px)',
                },
              }}
              onClick={() => {
                //handleDownloadFile(item, index)
              }}
            >
              <img
                src={IconAppraisalDocument}
                alt=""
                style={{
                  width: '75%',
                }}
              />
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '-4px',
                  right: '0px',
                  width: '20px',
                  height: '20px',
                  opacity: '1',
                  transition: '0.25s all ease-in-out',
                  '&:hover': {
                    transition: '0.25s all ease-in-out',
                    opacity: '1',
                  },
                }}
                color="error"
                onClick={(e) => {
                  e.stopPropagation()
                  //handleDeleteFile(item['@id'])
                }}
              >
                <CancelRounded
                  sx={{
                    fontSize: '18px',
                    fill: colorsConfig.mainRed,
                  }}
                />
              </IconButton>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  )
}

export default UploadAppraisalDocument
