import { calculateTerms } from '../financing.actions'

export default (builder) => {
  builder
    .addCase(calculateTerms.pending, (state) => {
      state.termsCalculation.isLoading = true
    })
    .addCase(calculateTerms.fulfilled, (state, action) => {
      state.termsCalculation.isLoading = false
      state.termsCalculation.error = null
      const termsInfo = action.payload
      state.termsCalculation.basic = termsInfo.map((item) => ({
        term: item?.bestBasicLoanOfferCalculation?.term,
        monthlyPayment: item?.bestBasicLoanOfferCalculation?.calculationDetails?.monthlyPayment,
        bankName: item?.bestBasicLoanOfferCalculation?.loanOffer?.bank?.title ?? '-',
        loanProgramName: item?.bestBasicLoanOfferCalculation?.loanOffer?.title ?? '-',
      }))
      state.termsCalculation.full = termsInfo.map((item) => ({
        term: item?.bestFastestLoanOfferCalculation?.term,
        monthlyPayment: item?.bestFastestLoanOfferCalculation?.calculationDetails?.monthlyPayment,
        bankName: item?.bestFastestLoanOfferCalculation?.loanOffer?.bank?.title ?? '-',
        loanProgramName: item?.bestFastestLoanOfferCalculation?.loanOffer?.title ?? '-',
      }))
    })
    .addCase(calculateTerms.rejected, (state, action) => {
      if (action.payload?.code !== 'ERR_CANCELED') {
        state.termsCalculation.isLoading = false
        state.termsCalculation.error = action.payload
      }
    })
}
