import React from 'react'
import { Box } from '@mui/material'
import TextBoldNormal from './TextBoldNormal'
import TextNormal from './TextNormal'

const TextWithCaption = (props) => {
  const { text, caption, wrapperStyles, textStyles, captionStyles } = props

  return (
    <Box sx={wrapperStyles}>
      <TextBoldNormal
        sx={{
          fontSize: '24px',
          fontWeight: 700,
          lineHeight: 'normal',
          ...textStyles,
        }}
      >
        {text}
      </TextBoldNormal>
      <TextNormal
        sx={{
          lineHeight: 'normal',
          ...captionStyles,
        }}
      >
        {caption}
      </TextNormal>
    </Box>
  )
}

export default TextWithCaption
