import React, { useEffect, useState } from 'react'
import ModalWrap from '../../components/Modals/ModalWrap'
import { Box, IconButton, InputAdornment, Stack } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import ButtonLG from '../../ui/Buttons/ButtonLG'
import TextH1 from '../../ui/Text/TextH1'
import TextFieldSmallEdible from '../../ui/TextFieldSmallEdible'
import TextNormal from '../../ui/Text/TextNormal'
import IconAttention from '../../assests/img/icons/icon-attention-red.svg'
import { colorsConfig } from '../../config/themeConfig'
import { privateRequest } from '../../utils/request'
import { API_ENDPOINTS } from '../../config/default'
import { toast } from 'react-toastify'

const ChangePasswordModal = (props) => {
  const { open, setOpen } = props

  const [oldPassword, setOldPassword] = useState('')
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordVisible, setNewPasswordVisible] = useState(false)
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [confirmNewPasswordVisible, setConfirmNewPasswordVisible] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [showErrors, setShowErrors] = useState(false)

  const hasErrors = () => {
    if (oldPassword === '') return true
    if (newPassword === '') return true
    if (confirmNewPassword === '') return true
    if (confirmNewPassword !== newPassword) return true
    return false
  }

  const handleSubmit = () => {
    if (hasErrors()) {
      setShowErrors(true)
      return
    }
    setButtonDisabled(true)
    const formData = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    }
    privateRequest
      .put(API_ENDPOINTS.changePassword, formData)
      .then(() => {
        setOpen(false)
        setNewPassword('')
        setOldPassword('')
        setConfirmNewPassword('')
        setShowErrors(false)
        toast.success('Password has been changed successfully')
      })
      .catch(() => {
        toast.error('Old password is incorrect, please try again')
      })
      .finally(() => {
        setButtonDisabled(false)
      })
  }

  useEffect(() => {
    if (!open) {
      setNewPassword('')
      setOldPassword('')
      setConfirmNewPassword('')
      setShowErrors(false)
    }
  }, [open])

  return (
    <ModalWrap
      open={open}
      setOpen={setOpen}
      wrapperStyles={{
        maxWidth: '450px',
      }}
    >
      <TextH1
        sx={{
          fontSize: '32px',
          mb: '20px',
        }}
      >
        Change password
      </TextH1>
      <Stack spacing={'15px'}>
        <TextFieldSmallEdible
          fullWidth
          required
          label="Old Password"
          type={oldPasswordVisible ? 'text' : 'password'}
          value={oldPassword}
          onChange={(e) => {
            setOldPassword(e.target.value)
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setOldPasswordVisible(!oldPasswordVisible)
                  }}
                >
                  {oldPasswordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={showErrors && oldPassword === ''}
          helperText={showErrors && oldPassword === '' ? 'This field can not be empty' : ''}
        />
        <TextFieldSmallEdible
          fullWidth
          required
          label="New Password"
          type={newPasswordVisible ? 'text' : 'password'}
          value={newPassword}
          onChange={(e) => {
            setNewPassword(e.target.value)
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setNewPasswordVisible(!newPasswordVisible)
                  }}
                >
                  {newPasswordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={showErrors && newPassword === ''}
          helperText={showErrors && newPassword === '' ? 'This field can not be empty' : ''}
        />
        <TextFieldSmallEdible
          fullWidth
          required
          label="Confirm New Password"
          type={confirmNewPasswordVisible ? 'text' : 'password'}
          value={confirmNewPassword}
          onChange={(e) => {
            setConfirmNewPassword(e.target.value)
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setConfirmNewPasswordVisible(!confirmNewPasswordVisible)
                  }}
                >
                  {confirmNewPasswordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={showErrors && confirmNewPassword === ''}
          helperText={showErrors && confirmNewPassword === '' ? 'This field can not be empty' : ''}
        />
      </Stack>
      {showErrors && newPassword !== confirmNewPassword ? (
        <Box
          sx={{
            display: 'flex',
            gap: '7px',
            mt: '15px',
          }}
        >
          <img
            src={IconAttention}
            alt=""
            style={{
              width: '15px',
            }}
          />
          <TextNormal
            sx={{
              color: colorsConfig.mainRed,
              fontSize: '14px',
            }}
          >
            Passwords do not match
          </TextNormal>
        </Box>
      ) : null}
      <ButtonLG
        fullWidth
        disabled={buttonDisabled}
        onClick={() => {
          handleSubmit()
        }}
        sx={{
          mt: '30px',
        }}
      >
        Confirm
      </ButtonLG>
    </ModalWrap>
  )
}

export default ChangePasswordModal
