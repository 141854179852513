import React, { useEffect, useState } from 'react'
import { Box, Table } from '@mui/material'
import { useDispatch } from 'react-redux'
import debounce from 'lodash/debounce'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import { privateRequest } from '../../../utils/request'
import { API_ENDPOINTS, isDevEnv } from '../../../config/default'
import BannerSingle from '../../../components/Banner/BannerSingle'
import Banner from '../../../assests/img/banners/banner-green-insurance.png'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import CustomTabs from '../../../ui/CustomTabs'
import CustomTab from '../../../ui/CustomTab'
import TextNormal from '../../../ui/Text/TextNormal'
import SearchInput from '../../../components/Search/SearchInput'
import FilterByStatus from '../../../components/FilterByStatus'
import CarTableHead from '../../VirtualGarage/CarTableHead'
import CarTableBody from '../../VirtualGarage/CarTableBody'
import {
  getInsuranceStatusInfo,
  insuranceApplicationsTableHead,
  insuranceWorkflows,
  insuredInsuranceApplicationsTableHead,
} from '../insurance.constants'
import UIButtonAdd from '../../../ui/Buttons/UIButtonAdd'
import InsureCarModal from './InsureCarModal'
import { colorsConfig } from '../../../config/themeConfig'
import {
  getInsuranceApplicationsTableData,
  getInsuredInsuranceApplicationsTableData,
} from '../insurance.helpers'
import ComingSoon from '../../../components/ComingSoon'

const InsuranceService = () => {
  const dispatch = useDispatch()
  const [tab, setTab] = useState(0)
  const [searchLine, setSearchLine] = useState('')
  const [currentFilter, setCurrentFilter] = useState('all')
  const [totalItemsInProgress, setTotalItemsInProgress] = useState(0)
  const [totalItemsProcessed, setTotalItemsProcessed] = useState(0)
  const [insureCarModal, setInsureCarModal] = useState(false)
  const [insuranceApplications, setInsuranceApplications] = useState([])
  const [insuranceApplicationsProcessed, setInsuranceApplicationsProcessed] = useState([])

  const handleApplyFilters = (data) => {
    if (currentFilter === 'all' || !currentFilter) return data
    return data.filter((item) => getInsuranceStatusInfo(item).name === currentFilter)
  }

  const getInsuranceApplications = () => {
    privateRequest
      .get(API_ENDPOINTS.insurance.applications, {
        params: {
          isArchived: false,
          pagination: false,
          insuranceApplicationExtendedSearch: searchLine,
        },
      })
      .then((response) => {
        const applicationsInProgress = response.data['hydra:member'].filter(
          (item) => !item.insuranceDeals.find((deal) => deal.isCustomerSelected),
        )
        const insuredApplications = response.data['hydra:member'].filter((item) =>
          item.insuranceDeals.find((deal) => deal.isCustomerSelected),
        )
        setInsuranceApplications(applicationsInProgress)
        setInsuranceApplicationsProcessed(insuredApplications)
        if (totalItemsInProgress === 0) {
          setTotalItemsInProgress(applicationsInProgress.length)
        }
        if (totalItemsProcessed === 0) {
          setTotalItemsProcessed(insuredApplications.length)
        }
      })
  }

  useEffect(() => {
    dispatch(setCurrentPageTitle('Insurance'))
  }, [])

  useEffect(() => {
    getInsuranceApplications()
  }, [searchLine])

  return !isDevEnv ? (
    <ComingSoon />
  ) : (
    <Box>
      <BannerSingle banner={Banner} link={'/go-greener'} />
      <Box sx={{ my: '30px', display: 'flex', justifyContent: 'flex-end' }}>
        <UIButtonAdd onClick={() => setInsureCarModal(true)}>Insure a car</UIButtonAdd>
      </Box>
      <BoxShadowed>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: '30px',
          }}
        >
          <CustomTabs value={tab} onChange={(e, newVal) => setTab(newVal)}>
            <CustomTab
              label={
                <TextNormal>
                  Insurance in progress{' '}
                  <span style={{ color: colorsConfig.mainBlue }}>({totalItemsInProgress})</span>
                </TextNormal>
              }
            />
            <CustomTab
              label={
                <TextNormal>
                  Insured{' '}
                  <span style={{ color: colorsConfig.mainBlue }}>({totalItemsProcessed})</span>
                </TextNormal>
              }
            />
          </CustomTabs>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <SearchInput value={searchLine} onChange={(e) => setSearchLine(e.target.value)} />
            <FilterByStatus
              currentFilter={currentFilter}
              filters={insuranceWorkflows.filter((item) => item !== 'Insured')}
              handleFilter={(value) => {
                setCurrentFilter(value)
              }}
            />
          </Box>
        </Box>
        <Table>
          <CarTableHead
            tableCells={
              tab === 0 ? insuranceApplicationsTableHead : insuredInsuranceApplicationsTableHead
            }
          />
          <CarTableBody
            content={
              tab === 0
                ? getInsuranceApplicationsTableData(handleApplyFilters(insuranceApplications))
                : getInsuredInsuranceApplicationsTableData(insuranceApplicationsProcessed)
            }
          />
        </Table>
      </BoxShadowed>
      {insureCarModal ? <InsureCarModal open={insureCarModal} setOpen={setInsureCarModal} /> : null}
    </Box>
  )
}

export default InsuranceService
