import { getInitialMakeAndModel } from '../addNewCar.actions'

export default (builder) => {
  builder
    .addCase(getInitialMakeAndModel.pending, (state) => {
      //
    })
    .addCase(getInitialMakeAndModel.fulfilled, (state, action) => {
      const { make, model } = action.payload
      if (make) {
        state.carFirstData.make = {
          id: make?.['@id'],
          name: make?.name,
        }
      }
      if (model) {
        state.carFirstData.model = {
          id: model?.['@id'],
          name: model?.name,
        }
        state.carFirstData.valid = true
      }
    })
    .addCase(getInitialMakeAndModel.rejected, (state, action) => {
      //
    })
}
