import React from 'react'
import { Box, Grid, useMediaQuery } from '@mui/material'
import { CDN_BASE_URL } from '../../../config/default'
import { Gallery } from 'react-photoswipe-gallery'
import TextNormal from '../../../ui/Text/TextNormal'
import { colorsConfig } from '../../../config/themeConfig'
import { useTranslation } from 'react-i18next'
import CarPhotosItem from './CarPhotosItem'

const CarPhotos = ({ carPhotos }) => {
  const { t } = useTranslation()
  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <Box
      className={xsOnly ? 'xs-only' : ''}
      sx={{
        padding: '32px',
        boxShadow: '0 0 24px 0 rgba(0, 0, 0, .1)',
        borderRadius: '6px',
        '&.xs-only': {
          padding: '8px',
        },
      }}
    >
      {carPhotos.length > 0 ? (
        <Gallery>
          <Grid container spacing={xsOnly ? 1 : 2}>
            {carPhotos.map((item, index) => {
              return (
                <Grid item xs={6} md={3} key={index}>
                  <Box
                    className={[xsOnly ? 'xs-only' : ''].join(' ')}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '6px',
                      overflow: 'hidden',
                      height: '160px',
                      '&.xs-only': {
                        height: 'auto',
                      },
                    }}
                  >
                    <CarPhotosItem photo={`${CDN_BASE_URL}${item.photo.path}`} />
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Gallery>
      ) : (
        <TextNormal
          sx={{
            color: colorsConfig.iconGray,
            textAlign: 'center',
          }}
        >
          {t('empty_car_photos')}
        </TextNormal>
      )}
    </Box>
  )
}

export default CarPhotos
