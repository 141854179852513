import React, { useState } from 'react'
import useValidation from './useValidation'

const useInput = (defaultValue, validations) => {
  const [value, setValue] = useState(defaultValue)
  const [firstBlur, setFirstBlur] = useState(false)
  const valid = useValidation(value, validations)

  const onChange = (e) => {
    setValue(e.target.value)
  }

  const onChangeValue = (value) => {
    setValue(value)
  }

  const onBlur = (e) => {
    setFirstBlur(true)
  }

  return {
    value,
    onChange,
    onChangeValue,
    onBlur,
    firstBlur,
    ...valid,
  }
}

export default useInput
