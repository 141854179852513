import React from 'react'
import UIModal from '../../../ui/UIModal/UIModal'
import UIPreloader from '../../../ui/UIPreloader/UIPreloader'
import { Stack } from '@mui/material'
import UITextHeadlineSmall from '../../../ui/UIText/UITextHeadlineSmall'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import ButtonDanger from '../../../ui/Buttons/ButtonDanger'
import UIButton from '../../../ui/UIButtons/UIButton'
import { UIVariant } from '../../../utils/constants/ui.constants'
import UITextHeadlineLarge from '../../../ui/UIText/UITextHeadlineLarge'

const DeleteModal = ({
  open,
  setOpen,
  isLoading,
  preloaderText = '',
  handleSubmit,
  title,
  description,
  deleteButtonText = 'button_delete',
  cancelButtonText = 'button_cancel',
}) => {
  return (
    <UIModal open={open} setOpen={setOpen}>
      {isLoading && <UIPreloader text={preloaderText} />}
      <Stack gap="30px">
        <Stack gap="12px">
          <UITextHeadlineLarge text={title} sx={{ pr: { xs: '30px', md: 0 } }} />
          <UITextRegular text={description} />
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} gap="10px" sx={{ '& button': { flex: 1 } }}>
          <UIButton
            variant={UIVariant.hollow}
            text={cancelButtonText}
            onClick={() => setOpen(false)}
          />
          <UIButton
            variant={UIVariant.danger}
            disabled={isLoading}
            onClick={() => handleSubmit()}
            text={deleteButtonText}
          />
        </Stack>
      </Stack>
    </UIModal>
  )
}

export default DeleteModal
