import { privateRequest } from '../../utils/request'
import { patchHeaders } from '../api'

class ManagersApiService {
  #api

  #endPoints

  #abortControllers

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      collection: '/admin/managers',
      instance: (id) => `/admin/managers/${id}`,
    }
    this.#abortControllers = {}
  }

  getManagers = async (params, prefix = 'getManagers') => {
    this.#abortControllers[prefix]?.abort()
    this.#abortControllers[prefix] = new AbortController()
    const response = await this.#api.get(this.#endPoints.collection, {
      params,
      signal: this.#abortControllers[prefix]?.signal,
    })
    return response
  }

  getManager = async (managerId) => {
    const response = await this.#api.get(this.#endPoints.instance(managerId))
    return response
  }

  createManager = async (data) => {
    const response = await this.#api.post(this.#endPoints.collection, data)
    return response
  }

  updateManager = async (managerId, data) => {
    const response = await this.#api.put(this.#endPoints.instance(managerId), data)
    return response
  }

  patchManager = async (managerId, data) => {
    const response = await this.#api.patch(this.#endPoints.instance(managerId), data, {
      headers: patchHeaders,
    })
    return response
  }

  deleteManager = async (managerId) => {
    const response = await this.#api.delete(this.#endPoints.instance(managerId))
    return response
  }
}

const managersApiService = new ManagersApiService({ api: privateRequest })

export default managersApiService
