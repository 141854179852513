import React from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { colorsConfig } from '../../config/themeConfig'

const UITextLabelMedium = ({ children, sx, text, ...props }) => {
  const { t } = useTranslation()

  return (
    <Typography
      sx={{
        fontFamily: '"Helvetica", sans-serif',
        fontSize: '14px',
        fontWeight: 700,
        color: colorsConfig.mainBlack,
        lineHeight: 'normal',
        // '& span': {
        //   fontFamily: '"Helvetica", sans-serif',
        //   fontSize: '14px',
        //   fontWeight: 700,
        //   color: colorsConfig.mainBlack,
        // },
        ...sx,
      }}
      {...props}
    >
      {children || t(text)}
    </Typography>
  )
}

export default UITextLabelMedium
