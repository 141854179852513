import React from 'react'
import { colorsConfig } from '../../config/themeConfig'
import { Typography } from '@mui/material'

const TextBoldAccent = ({ children, sx, ...props }) => {
  return (
    <Typography
      sx={{
        fontFamily: '"HelveticaNeue", sans-serif',
        fontSize: '16px',
        fontWeight: 500,
        colors: colorsConfig.mainBlack,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  )
}

export default TextBoldAccent
