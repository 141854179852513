import { deleteCompanyAddress } from '../settings.actions'

export default (builder) => {
  builder
    .addCase(deleteCompanyAddress.pending, (state) => {
      state.companyAddresses.delete.isLoading = true
    })
    .addCase(deleteCompanyAddress.fulfilled, (state, action) => {
      state.companyAddresses.delete.isLoading = false
      state.companyAddresses.delete.error = null
    })
    .addCase(deleteCompanyAddress.rejected, (state, action) => {
      state.companyAddresses.delete.isLoading = false
      state.companyAddresses.delete.error = action.payload
    })
}
