import {getWarrantyProducts} from "../warrantyProducts.actions";

export default (builder) => {
  builder
    .addCase(getWarrantyProducts.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getWarrantyProducts.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = null
      state.warrantyProducts = action.payload?.['hydra:member']
    })
    .addCase(getWarrantyProducts.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
}