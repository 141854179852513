import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setCurrentPageTitle } from '../../../../store/features/authSlice'
import TextH1 from '../../../../ui/Text/TextH1'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import { privateRequest } from '../../../../utils/request'
import { API_ENDPOINTS } from '../../../../config/default'
import { toast } from 'react-toastify'
import { HomeRounded } from '@mui/icons-material'
import BreadcrumbsComponent from '../../../../components/BreadcrumbsComponent'
import { colorsConfig } from '../../../../config/themeConfig'

const AddRisk = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [riskFormula, setRiskFormula] = useState('RISK_FORMULA_UNSPECIFIED')

  const hasErrors = !name

  const handleAdd = () => {
    const formData = {
      name,
      riskFormula,
    }
    privateRequest
      .post(API_ENDPOINTS.insurance.risks, formData)
      .then(() => {
        toast.success('Added risk successfully', { autoClose: 1000 })
        navigate('/admin/insurance/risks')
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data['hydra:description']) {
          toast.error(error.response.data['hydra:description'])
        } else toast.error('Unexpected error')
      })
  }

  useEffect(() => {
    dispatch(setCurrentPageTitle('Add a new risk'))
  }, [])

  return (
    <Box>
      <BreadcrumbsComponent
        currentPage={{ name: `Add a new risk` }}
        parentPages={[
          {
            name: 'Risks',
            icon: <HomeRounded sx={{ fill: colorsConfig.lightGray }} />,
            link: '/admin/insurance/risks',
          },
        ]}
      />
      <TextH1
        sx={{
          fontSize: '36px',
          mb: '30px',
          mt: '10px',
        }}
      >
        Add a new risk
      </TextH1>
      <Grid container spacing={'20px'}>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Risk name'}
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            fullWidth
            label={'Risk formula'}
            value={riskFormula}
            disabled
            onChange={(e) => {
              setRiskFormula(e.target.value)
            }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: '40px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <ButtonLG
          sx={{
            minWidth: '187px',
          }}
          disabled={hasErrors}
          onClick={() => {
            handleAdd()
          }}
        >
          Save
        </ButtonLG>
      </Box>
    </Box>
  )
}

export default AddRisk
