import React from 'react'
import UITextRegular from '../UIText/UITextRegular'
import { Stack } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { useTranslation } from 'react-i18next'

const UIBadgeSuccess = ({ text, prefixIcon, sx, ...props }) => {
  const { t } = useTranslation()

  return (
    <Stack direction="row" gap="10px" alignItems="center" sx={{ ...sx }} {...props}>
      {prefixIcon ?? null}
      <UITextRegular
        sx={{
          color: colorsConfig.mainGreen,
          fontWeight: 700,
        }}
        text={t(text)}
      />
    </Stack>
  )
}

export default UIBadgeSuccess
