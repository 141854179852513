import React from 'react'
import { Box, Modal } from '@mui/material'
import AnimationGif from '../../assests/img/animations/send-to-compound.gif'
import TextH1 from '../../ui/Text/TextH1'

const AnimationSendToCompoundModal = (props) => {
  const { open, setOpen } = props

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 584,
          height: 213,
          background: 'white',
          borderRadius: '10px',
          padding: '30px',
          maxHeight: '80vh',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className={'disable-scrollbar'}
      >
        <img style={{ width: '152px' }} src={AnimationGif} alt="" />
        <TextH1
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          Compound <br />
          appraisal ordered
        </TextH1>
      </Box>
    </Modal>
  )
}

export default AnimationSendToCompoundModal
