import React, { useState } from 'react'
import {
  Box,
  Typography,
  Grid,
  TextField,
  Stack,
  Button,
  Checkbox,
  Link,
  FormControlLabel,
} from '@mui/material'
import useInput from '../../hooks/useInput'
import { useNavigate } from 'react-router-dom'
import { publicRequest } from '../../utils/request'
import { API_ENDPOINTS } from '../../config/default'
import { toast } from 'react-toastify'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import IconDocument from '../../assests/img/promo/icon-document.png'
import { COMPANY_NAME, REGION_LOCALE } from '../../utils/constants/global.constants'

const SignUpPromo = (props) => {
  const { setGetStartedSection } = props

  const themeColors = {
    mainBlack: '#3D3D3D',
    mainBlue: '#007DFF',
    gray: '#757575',
  }

  const inputStyles = {
    '& .MuiInputBase-input': {
      border: '1px solid transparent',
      boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
      borderRadius: '4px',
      fontSize: '14px',
      fontFamily: '"Helvetica", sans-serif',
    },
  }

  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 1)
  const [uploadedFile, setUploadedFile] = useState(null)
  const userName = useInput('', { isEmpty: true })
  const email = useInput('', { isEmpty: true, isEmail: true })
  const company = useInput('', { isEmpty: true })
  const firstName = useInput('', {})
  const lastName = useInput('', {})
  const emirate = useInput('Not Chosen', { isEmirateChosen: true })
  const licenseNumber = useInput('', { isEmpty: true })
  const phoneNumber = useInput('', { isEmpty: true, phoneNumberUAE: 9 })
  const landLineNumber = useInput('', { isEmpty: true, phoneNumberUAE: 8 })
  const addressLine1 = useInput('', { isEmpty: true })
  const addressLine2 = useInput('', { isEmpty: true })
  const date = useInput(null, { minDate: currentDate })
  const terms = useInput('', { isEmpty: true })
  const [uploadFile, setUploadFile] = useState('')
  const [disableOnSubmit, setDisableOnSubmit] = useState(false)

  const navigate = useNavigate()

  const handleUploadLicense = (value) => {
    const fileName = value.replace('C:\\fakepath\\', '')
    if (fileName) {
      setUploadedFile(fileName)
    }
  }

  const handleFormSubmit = () => {
    setDisableOnSubmit(true)
    const phonePrefix = '971'
    const phoneNumberFull = phonePrefix.concat(phoneNumber.value)
    const landLineNumberFull = phonePrefix.concat(landLineNumber.value)
    const roles = ['ROLE_COMPANY', 'ROLE_SELLER', 'ROLE_BUYER']
    let formData = new FormData()
    formData.append('login', userName.value)
    formData.append('email', email.value)
    formData.append('phoneNumber', phoneNumberFull)
    formData.append('companyName', company.value)
    if (landLineNumber && !landLineNumber.hasErrors) {
      formData.append('landlineNumber', landLineNumberFull)
    }
    if (firstName) {
      formData.append('firstName', firstName.value)
    }
    if (lastName) {
      formData.append('surname', lastName.value)
    }
    for (let i = 0; i < roles.length; i++) {
      formData.append(`roles[${i}]`, roles[i])
    }
    formData.append('state', emirate.value)
    if (addressLine1) {
      formData.append('addressLine1', addressLine1.value)
    }
    if (addressLine2) {
      formData.append('addressLine2', addressLine2.value)
    }
    formData.append('tradeLicenseNumber', licenseNumber.value.toString())
    formData.append('tradeLicenseExpiryDate', date.value)
    formData.append('tradeLicense', uploadFile)
    formData.append('postcode', 'postcode: 0992121')
    publicRequest
      .post(API_ENDPOINTS.register, formData, {
        headers: {
          accept: 'application/ld+json',
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        toast.success(`Thank you! Your request has been sent successfully!`)
        setGetStartedSection(2)
      })
      .catch((error) => {
        if (error.response.data['hydra:description'].indexOf('login') !== -1) {
          toast.error('This username has already been used')
        }
      })
      .finally(() => {
        setDisableOnSubmit(false)
      })
  }

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            sx={{
              color: themeColors.mainBlack,
              fontFamily: '"Helvetica", sans-serif',
              fontSize: {
                xs: '24px',
                sm: '40px',
              },
              fontWeight: 700,
              mb: '10px',
              textAlign: {
                xs: 'center',
                sm: 'left',
              },
            }}
          >
            Let’s get started
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: themeColors.mainBlack,
              fontFamily: '"Helvetica", sans-serif',
              fontSize: {
                xs: '14px',
                sm: '16px',
              },
              fontWeight: 400,
              mb: {
                sm: '24px',
                xs: '6px',
              },
              textAlign: {
                xs: 'center',
                sm: 'left',
              },
            }}
          >
            to get started with {COMPANY_NAME[REGION_LOCALE]}, we just need a few details.
          </Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Stack spacing={2}>
            <TextField
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              onChange={(e) => {
                userName.onChange(e)
              }}
              onBlur={(e) => {
                userName.onBlur(e)
              }}
              value={userName.value}
              helperText={userName.firstBlur && userName.hasErrors ? userName.errorText : ''}
              error={userName.firstBlur && userName.hasErrors}
              size="small"
              sx={inputStyles}
            />

            <TextField
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              autoComplete="given-name"
              onChange={(e) => {
                firstName.onChange(e)
              }}
              onBlur={(e) => {
                firstName.onBlur(e)
              }}
              value={firstName.value}
              size="small"
              sx={inputStyles}
            />
            <TextField
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="family-name"
              onChange={(e) => {
                lastName.onChange(e)
              }}
              onBlur={(e) => {
                lastName.onBlur(e)
              }}
              value={lastName.value}
              size="small"
              sx={inputStyles}
            />
            <TextField
              required
              fullWidth
              id="email"
              label="E-mail"
              name="email"
              autoComplete="email"
              onChange={(e) => {
                email.onChange(e)
              }}
              onBlur={(e) => {
                email.onBlur(e)
              }}
              value={email.value}
              helperText={email.firstBlur && email.hasErrors ? email.errorText : ''}
              error={email.firstBlur && email.hasErrors}
              size="small"
              sx={inputStyles}
            />
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField value="+971" readOnly sx={inputStyles} size="small" />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    type="number"
                    required
                    label="Landline Number"
                    onChange={(e) => {
                      landLineNumber.onChange(e)
                    }}
                    onBlur={(e) => {
                      landLineNumber.onBlur(e)
                    }}
                    value={landLineNumber.value}
                    helperText={
                      landLineNumber.firstBlur && landLineNumber.hasErrors
                        ? landLineNumber.errorText
                        : ''
                    }
                    error={landLineNumber.firstBlur && landLineNumber.hasErrors}
                    size="small"
                    sx={inputStyles}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField value="+971" readOnly size="small" sx={inputStyles} />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    type="number"
                    required
                    label="Mobile Number"
                    onChange={(e) => {
                      phoneNumber.onChange(e)
                    }}
                    onBlur={(e) => {
                      phoneNumber.onBlur(e)
                    }}
                    value={phoneNumber.value}
                    helperText={
                      phoneNumber.firstBlur && phoneNumber.hasErrors ? phoneNumber.errorText : ''
                    }
                    error={phoneNumber.firstBlur && phoneNumber.hasErrors}
                    size="small"
                    sx={inputStyles}
                  />
                </Grid>
              </Grid>
            </Box>
            <TextField
              required
              fullWidth
              id="company-name"
              label="Company Name"
              name="company-name"
              onChange={(e) => {
                company.onChange(e)
              }}
              onBlur={(e) => {
                company.onBlur(e)
              }}
              value={company.value}
              helperText={company.firstBlur && company.hasErrors ? company.errorText : ''}
              error={company.firstBlur && company.hasErrors}
              size="small"
              sx={inputStyles}
            />
          </Stack>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Address Line 1"
              onChange={(e) => {
                addressLine1.onChange(e)
              }}
              onBlur={(e) => {
                addressLine1.onBlur(e)
              }}
              value={addressLine1.value}
              size="small"
              sx={inputStyles}
            />
            <TextField
              fullWidth
              label="Address Line 2"
              onChange={(e) => {
                addressLine2.onChange(e)
              }}
              onBlur={(e) => {
                addressLine2.onBlur(e)
              }}
              value={addressLine2.value}
              size="small"
              sx={inputStyles}
            />
            <TextField
              required
              fullWidth
              id="trade-license-number"
              label="Trade license number"
              name="trade-license-number"
              onChange={(e) => {
                licenseNumber.onChange(e)
              }}
              onBlur={(e) => {
                licenseNumber.onBlur(e)
              }}
              value={licenseNumber.value}
              helperText={
                licenseNumber.firstBlur && licenseNumber.hasErrors ? licenseNumber.errorText : ''
              }
              error={licenseNumber.firstBlur && licenseNumber.hasErrors}
              size="small"
              sx={inputStyles}
            />
            <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Trade license expiry date"
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => (
                  <TextField {...params} fullWidth required size="small" sx={inputStyles} />
                )}
                onChange={(newVal) => {
                  date.onChangeValue(newVal)
                }}
                value={date.value}
                disableHighlightToday={true}
                mask={'__/__/____'}
                minDate={currentDate}
                helperText={date.firstBlur && date.hasErrors ? date.errorText : ''}
                error={date.hasErrors}
              />
            </LocalizationProvider>
            <Button
              fullWidth
              component="label"
              variant="contained"
              sx={{
                background: 'none',
                border: `1px solid ${themeColors.mainBlue}`,
                textTransform: 'none',
                textAlign: 'left',
                color: 'rgba(0,0,0,.55)',
                fontWeight: 400,
                fontSize: '14px',
                fontFamily: '"Helvetica", sans-serif',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                boxShadow: 'none',
                '&:hover': {
                  color: 'white',
                  background: themeColors.mainBlue,
                },
              }}
              className="upload-btn"
            >
              <img src={IconDocument} style={{ marginRight: '8px' }} alt="" /> Upload trade license
              <input
                type="file"
                hidden
                name="upload-license"
                onChange={(e) => {
                  handleUploadLicense(e.target.value)
                  setUploadFile(e.target.files[0])
                }}
              />
            </Button>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(e) => {
                    terms.onChangeValue(e.target.checked)
                  }}
                  value={terms.value}
                />
              }
              label={
                <Typography>
                  I agree with
                  <Link
                    onClick={() => {
                      navigate('/sign-in')
                    }}
                    sx={{
                      cursor: 'pointer',
                      marginLeft: '7px',
                    }}
                  >
                    terms and conditions
                  </Link>
                </Typography>
              }
            >
              I agree with terms and conditions
            </FormControlLabel>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: {
                  sm: 'flex-start',
                  xs: 'center',
                },
                alignItems: {
                  sm: 'flex-end',
                  xs: 'center',
                },
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="success"
                sx={{
                  fontFamily: "'Helvetica', sans-serif",
                  fontWeight: 700,
                  py: '12px',
                  px: '57px',
                  borderRadius: '4px',
                  background: themeColors.mainBlue,
                  textTransform: 'none',
                }}
                onClick={() => {
                  handleFormSubmit()
                }}
                disabled={
                  disableOnSubmit ||
                  date.hasErrors ||
                  userName.hasErrors ||
                  email.hasErrors ||
                  !terms.value ||
                  !uploadedFile ||
                  company.hasErrors ||
                  phoneNumber.hasErrors ||
                  licenseNumber.hasErrors ||
                  emirate.hasErrors
                }
              >
                Submit
              </Button>
              <Button
                sx={{
                  fontFamily: "'Helvetica', sans-serif",
                  fontWeight: 400,
                  fontSize: '12px',
                  color: themeColors.mainBlue,
                  textTransform: 'none',
                  position: 'relative',
                  marginRight: '-7px',
                }}
                onClick={() => {
                  setGetStartedSection(2)
                }}
              >
                Already have an account? Sign in
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SignUpPromo
