import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import { useSelector } from 'react-redux'
import ControlPointFileCollection from '../AppraisalBlock/ControlPointFileCollection'

const AppraisalDocuments = ({ carId }) => {
  const documents = useSelector((state) => state.car.appraisal.documents)

  const filteredDocs = documents?.filter(
    (item) => item?.sysName !== 'uae_custom_cardpossession_card',
  )

  return (
    <BoxShadowed sx={{ mt: '30px' }}>
      <Grid container spacing="20px">
        {filteredDocs.map((item) => {
          return (
            <Grid key={item?.id} item md={3} xs={12}>
              <ControlPointFileCollection data={item} carId={carId} />
            </Grid>
          )
        })}
      </Grid>
    </BoxShadowed>
  )
}

export default AppraisalDocuments
