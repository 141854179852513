import React from 'react'
import UIModal from '../../../ui/UIModal/UIModal'
import { Stack } from '@mui/material'
import UITextHeadlineLarge from '../../../ui/UIText/UITextHeadlineLarge'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import UIButton from '../../../ui/UIButtons/UIButton'
import { Trans } from 'react-i18next'
import UIButtonLink from '../../../ui/UIButtons/UIButtonLink'
import { useNavigate } from 'react-router-dom'
import useBreakpoints from '../../../hooks/useBreakpoints'

const WelcomeModal = ({ open, setOpen }) => {
  const { isMaxWidth600 } = useBreakpoints()
  const navigate = useNavigate()

  return (
    <UIModal
      width="600px"
      open={open}
      setOpen={setOpen}
      wrapperStyles={{
        ...(isMaxWidth600 && {
          pt: '30px',
        }),
      }}
    >
      <Stack gap={{ xs: '20px', md: '30px' }}>
        <UITextHeadlineLarge
          sx={{ fontSize: { xs: '26px', md: '40px' }, mt: { xs: '24px', md: '0' } }}
          text="modal_title_welcome_to_fleatify"
        />
        <UITextRegular>
          <Trans
            components={{
              l: (
                <UIButtonLink
                  onClick={() => {
                    navigate('/profile-management')
                    setOpen(false)
                  }}
                />
              ),
            }}
          >
            modal_p_welcome_to_fleatify
          </Trans>
        </UITextRegular>
        <Stack direction="row" justifyContent="center">
          <UIButton
            sx={{ width: { xs: '100%', md: 'auto' } }}
            text="button_continue"
            onClick={() => setOpen(false)}
          />
        </Stack>
      </Stack>
    </UIModal>
  )
}

export default WelcomeModal
