import React, { useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import VehicleDetailsReduced from '../../../CarPage/CarPageSections/VehicleDetailsReduced'
import { useDispatch, useSelector } from 'react-redux'
import UITextTitleBordered from '../../../../ui/UIText/UITextTitleBordered'
import CarPhotos from '../../../../components/CarComponents/CarPhotos/CarPhotos'
import CarDocuments from '../../../../components/CarComponents/CarDocuments/CarDocuments'
import CarDamages from '../../../../components/CarComponents/CarDamages/CarDamages'
import WarningLights from '../../WarningLights/WarningLights'
import AppraisalInteriorConditions from '../../AppraisalInteriorConditions/AppraisalInteriorConditions'
import AppraisalMechanicalConditions from '../../AppraisalMechanicalConditions/AppraisalMechanicalConditions'
import { useTranslation } from 'react-i18next'
import { getCarParts, getForeshortenings, getPilotLamps } from '../../getAppraisalReferences'
import {
  setReferenceControlPoints,
  setReferences,
} from '../../../../store/features/appraisalReferencesSlices'
import { privateRequest } from '../../../../utils/request'
import { API_ENDPOINTS } from '../../../../config/default'
import {
  setAppraisal,
  setCarAppraisalChecksResource,
  setDamages,
  setDocuments,
  setInteriorConditions,
  setMechanicalConditions,
} from '../../../../store/features/carSlice/carSlice'
import {
  parseControlPointsDocuments,
  parseControlPointsInteriorConditions,
  parseMechanicalConditions,
} from '../../appraisal.helpers'

const AppraisalOverview = () => {
  const { t, i18n } = useTranslation()
  const car = useSelector((state) => state.customerAppraisal.customer.car.data)
  const carPhotos = useSelector((state) => state.car.carData.foreshortenings)
  const carDocuments = useSelector((state) => state.car.appraisal.documents)
  const carDamages = useSelector((state) => state.car.appraisal.damages)
  const dispatch = useDispatch()

  const carData = useSelector((state) => state.car.carData)
  const appraisalReferences = useSelector((state) => state.references)

  const [appraisalLoaded, setAppraisalLoaded] = useState(false)

  const getControlPoints = () => {
    privateRequest
      .get(API_ENDPOINTS.controlPoints, {
        params: {
          pagination: false,
        },
      })
      .then((response) => {
        dispatch(setReferenceControlPoints(response.data['hydra:member']))
      })
  }

  const getReferences = () => {
    const referencePromises = [getForeshortenings(), getCarParts(), getPilotLamps()]
    Promise.all(referencePromises).then((result) => {
      dispatch(setReferences(result))
    })
  }

  const handleSetCarControlPoints = () => {
    dispatch(
      setDocuments(
        parseControlPointsDocuments(appraisalReferences.controlPoints, carData.controlPoints),
      ),
    )
    dispatch(
      setInteriorConditions(
        parseControlPointsInteriorConditions(
          appraisalReferences.controlPoints,
          carData.controlPoints,
        ),
      ),
    )
    dispatch(
      setMechanicalConditions(
        parseMechanicalConditions(appraisalReferences.controlPoints, carData.controlPoints),
      ),
    )
  }

  useEffect(() => {
    dispatch(
      setDamages({
        carDamages: carData.damages,
        carParts: appraisalReferences.carParts,
      }),
    )
  }, [appraisalReferences.carParts])

  useEffect(() => {
    if (appraisalReferences.controlPoints.length > 0) {
      handleSetCarControlPoints()
    }
  }, [appraisalReferences.controlPoints])

  useEffect(() => {
    if (appraisalLoaded) {
      getReferences()
      getControlPoints()
    }
  }, [i18n.language, appraisalLoaded])

  function compare(a, b) {
    if (a.direct < b.direct) {
      return -1
    }
    if (a.direct > b.direct) {
      return 1
    }
    return 0
  }

  const getCarAppraisal = () => {
    privateRequest.get(API_ENDPOINTS.appraisalByCar(car?.id)).then((response) => {
      setAppraisalLoaded(true)
      dispatch(
        setAppraisal({
          foreshortenings: response.data.foreshortenings.sort(compare),
          damages: response.data.damages,
          pilotLamps: response.data.pilotLamps,
          controlPoints: response.data.controlPoints,
        }),
      )
    })
  }

  useEffect(() => {
    getCarAppraisal()
  }, [])

  return (
    <Stack gap="40px" sx={{ width: '100%' }}>
      <Stack gap="30px">
        <UITextTitleBordered text="tab_vehicle_details" />
        <VehicleDetailsReduced car={car} />
      </Stack>
      <Stack gap="30px">
        <UITextTitleBordered text="tab_photos" />
        <CarPhotos carPhotos={carPhotos} />
      </Stack>
      <Stack gap="30px">
        <UITextTitleBordered text="tab_documents" />
        <CarDocuments carDocuments={carDocuments} />
      </Stack>
      <Stack gap="30px">
        <UITextTitleBordered text="tab_damages" />
        <CarDamages carDamages={carDamages} />
      </Stack>
      <Stack>
        <UITextTitleBordered text="tab_warning_lights" />
        <WarningLights readonly={true} />
      </Stack>
      <Stack>
        <UITextTitleBordered text="tab_interior_conditions" />
        <AppraisalInteriorConditions readonly={true} />
      </Stack>
      <Stack>
        <UITextTitleBordered text="tab_mechanical_conditions" />
        <AppraisalMechanicalConditions readonly={true} />
      </Stack>
    </Stack>
  )
}

export default AppraisalOverview
