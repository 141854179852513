export const getCarEnumName = (value, carEnum, enumDetails) => {
  if (!enumDetails || value === undefined || value === null) return '-'
  const currentEnum = enumDetails?.find((item) => item?.name === carEnum)
  if (!currentEnum) return '-'
  const filterCaseDetails = currentEnum?.caseDetails?.filter((item) => item?.name !== 'UNSPECIFIED')
  const enumName = filterCaseDetails.find(
    (item) => item?.value.toString() === value.toString(),
  )?.readableValue
  return enumName ?? '-'
}

export const getEnumSystemName = (value, carEnum, enumDetails) => {
  if (!enumDetails || value === undefined || value === null) return '-'
  const currentEnum = enumDetails?.find((item) => item?.name === carEnum)
  if (!currentEnum) return '-'
  const filterCaseDetails = currentEnum?.caseDetails?.filter((item) => item?.name !== 'UNSPECIFIED')
  const enumName = filterCaseDetails.find(
    (item) => item?.value.toString() === value.toString(),
  )?.name
  return enumName ?? '-'
}
