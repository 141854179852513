import React, { useState, useEffect } from 'react'
import { Box, Typography, Grid, IconButton, Modal, Button, useMediaQuery } from '@mui/material'
import { Edit, CameraAlt } from '@mui/icons-material'
import EditIcon from './EditIcon'
import { privateRequest } from '../../utils/request'
import { API_ENDPOINTS, CDN_BASE_URL, FILE_TYPES } from '../../config/default'
import { FileUploader } from 'react-drag-drop-files'
import { toast } from 'react-toastify'
import { uploadFile } from '../../utils/uploadFile'
import Avatar1 from '../../assests/img/avatars/avatar-1.png'
import Avatar2 from '../../assests/img/avatars/avatar-2.png'
import Avatar3 from '../../assests/img/avatars/avatar-3.png'
import Avatar4 from '../../assests/img/avatars/avatar-4.png'
import Avatar5 from '../../assests/img/avatars/avatar-5.png'
import Avatar6 from '../../assests/img/avatars/avatar-6.png'
import Avatar7 from '../../assests/img/avatars/avatar-7.png'
import Avatar8 from '../../assests/img/avatars/avatar-8.png'
import Avatar9 from '../../assests/img/avatars/avatar-9.png'
import Avatar10 from '../../assests/img/avatars/avatar-10.png'
import Avatar11 from '../../assests/img/avatars/avatar-11.png'
import Avatar12 from '../../assests/img/avatars/avatar-12.png'
import Avatar13 from '../../assests/img/avatars/avatar-13.png'
import Avatar14 from '../../assests/img/avatars/avatar-14.png'
import Avatar15 from '../../assests/img/avatars/avatar-15.png'
import Avatar16 from '../../assests/img/avatars/avatar-16.png'
import { useDispatch } from 'react-redux'
import { setCurrentPageTitle } from '../../store/features/authSlice'
import { setAvatar as setProfilePicture } from '../../store/features/authSlice'
import RBox from '../../ui/RBox'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import UserSettings from './UserSettings'
import CompanySettings from './CompanySettings'
import BoxShadowed from '../../ui/Layout/BoxShadowed'
import { useTranslation } from 'react-i18next'
import CompanyAddresses from './CompanyAddresses/CompanyAddresses'
import TradeLicense from './TradeLicense'

const ProfileManagement = () => {
  const dispatch = useDispatch()
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')
  const { t } = useTranslation()

  const defaultIcons = [
    Avatar1,
    Avatar2,
    Avatar3,
    Avatar4,
    Avatar5,
    Avatar6,
    Avatar7,
    Avatar8,
    Avatar9,
    Avatar10,
    Avatar11,
    Avatar12,
    Avatar13,
    Avatar14,
    Avatar15,
    Avatar16,
  ]

  const [user, setUser] = useState(null)
  const [avatarModal, setAvatarModal] = useState(false)
  const [avatar, setAvatar] = useState(null)
  const [avatarButtonDisabled, setAvatarButtonDisabled] = useState(false)

  const getUser = () => {
    privateRequest.get(API_ENDPOINTS.userInfo).then((response) => {
      setUser(response.data)
      if (response.data.avatar) {
        dispatch(setProfilePicture(`${CDN_BASE_URL}${response.data.avatar.avatar.path}`))
      } else {
        dispatch(setProfilePicture(Avatar1))
      }
    })
  }

  const handleSetAvatar = (file, type) => {
    if (type && type === 'icon') {
      setAvatar({
        value: file,
        type: 'icon',
      })
      return
    }
    setAvatar({
      value: file,
      type: 'file',
    })
  }

  function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }

  const handleUploadAvatar = async () => {
    setAvatarButtonDisabled(true)
    let fileId
    if (avatar.type === 'icon') {
      fileId = await uploadFile(dataURLtoFile(avatar.value, 'icon.png'), FILE_TYPES.avatar)
    } else {
      fileId = await uploadFile(avatar.value, FILE_TYPES.avatar)
    }
    privateRequest
      .put(API_ENDPOINTS.changeAvatar, {
        avatar: fileId,
      })
      .then((response) => {
        toast.success('The profile has been uploaded successfully')
        setAvatarModal(false)
        setAvatar(null)
        getUser()
      })
      .catch((error) => {
        toast.error('An error occurred while uploading a profile picture')
      })
      .finally(() => {
        setAvatarButtonDisabled(false)
      })
  }

  useEffect(() => {
    getUser()
    dispatch(setCurrentPageTitle('page_title_account_settings'))
  }, [])

  return (
    <RBox
      className={tabletOnly ? 'tablet-only' : ''}
      sx={{
        '&.extra-large': {
          pl: '37px',
          pt: '15px',
        },
        '&.tablet-only': {
          px: '16px',
          pb: '30px',
        },
      }}
    >
      <TextBoldNormal
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          fontSize: '24px',
          marginBottom: '10px',
          '&.xs-only': {
            fontSize: '18px',
            mt: '20px',
            mb: '30px',
          },
        }}
      >
        {t('title_user_settings')}
      </TextBoldNormal>
      <RBox
        sx={{
          marginBottom: '50px',
          boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
          borderRadius: '6px',
          padding: '20px',
          paddingTop: '15px',
          paddingBottom: '30px',
          '&.xs-only': {
            boxShadow: 'none',
          },
        }}
      >
        {user ? (
          <Grid
            container
            spacing={xsOnly ? 0 : 3}
            sx={{
              marginTop: '-15px',
            }}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  mb: 2,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '75px',
                    height: '75px',
                    marginBottom: '15px',
                    border: '5px solid #007DFF',
                    borderRadius: '50%',
                    background: 'white',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                    position: 'relative',
                    cursor: 'pointer',
                    '&:hover': {
                      '& .edit-avatar': {
                        opacity: 1,
                        transition: '0.25s all ease-in-out',
                      },
                    },
                  }}
                >
                  {user.avatar ? (
                    <img
                      src={`${CDN_BASE_URL}${user.avatar.avatar.path}`}
                      style={{ width: '100%' }}
                      alt=""
                    />
                  ) : (
                    <img src={Avatar1} style={{ width: '70%' }} alt="" />
                  )}

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      borderRadius: '50%',
                      background: 'rgba(0,0,0,0.25)',
                      opacity: 0,
                      transition: '0.25s all ease-in-out',
                    }}
                    className="edit-avatar"
                    onClick={() => {
                      setAvatarModal(true)
                    }}
                  >
                    <IconButton
                      className="edit-avatar"
                      sx={{
                        width: '30px',
                        height: '30px',
                      }}
                    >
                      <Edit sx={{ color: 'white' }} />
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginLeft: '10px',
                    alignSelf: 'start',
                    display: 'flex',
                  }}
                  onClick={() => {
                    setAvatarModal(true)
                  }}
                >
                  <IconButton
                    sx={{
                      background: 'none',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    <EditIcon editIconWidth={15} editIconHeight={15} editIconColour={'#007DFF'} />
                    <Typography
                      sx={{
                        fontSize: '16px',
                        textDecorationLine: 'underline',
                        color: '#007DFF',
                        marginLeft: '3px',
                      }}
                    >
                      {t('button_change')}
                    </Typography>
                  </IconButton>
                </Box>
              </Box>
              <UserSettings />
            </Grid>
          </Grid>
        ) : null}
      </RBox>
      <TextBoldNormal
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          fontSize: '24px',
          marginBottom: '10px',
          '&.xs-only': {
            fontSize: '18px',
            mb: '30px',
          },
        }}
      >
        {t('title_company_settings')}
      </TextBoldNormal>
      <BoxShadowed>
        <CompanySettings />
        <TradeLicense />
        <CompanyAddresses />
      </BoxShadowed>
      <Modal
        open={avatarModal}
        onClose={() => {
          setAvatarModal(false)
        }}
      >
        <Box
          className={[xsOnly ? 'xs-only' : '', 'disable-scrollbar'].join(' ')}
          sx={{
            position: 'absolute',
            top: '50px',
            left: '50%',
            marginLeft: {
              xs: '-150px',
              sm: '-250px',
            },
            width: {
              xs: '300px',
              sm: '500px',
            },
            background: 'white',
            border: '1px solid lightgray',
            borderRadius: '8px',
            padding: '20px',
            height: 'calc(100vh - 100px)',
            overflowY: 'auto',
          }}
        >
          <FileUploader
            handleChange={handleSetAvatar}
            name="file"
            types={['JPG', 'PNG', 'jpeg', 'pdf']}
            hoverTitle="Drop here"
            label="Upload or drop all the photos here"
            maxSize={10}
            children={
              <IconButton
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  background: avatar ? 'none' : 'rgba(0,0,0,0.2)',
                  minWidth: '75px',
                  width: '75px',
                  height: '75px',
                  borderRadius: '50%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  overflow: avatar && avatar.type === 'icon' ? 'visible' : 'hidden',
                  padding: 0,
                  '&:hover': {
                    background: 'rgba(0,0,0,0.2)',
                  },
                }}
              >
                {avatar ? (
                  <React.Fragment>
                    {avatar.type === 'icon' ? (
                      <Box>
                        <img
                          src={avatar.value}
                          style={{
                            maxWidth: '70%',
                            maxHeight: '100%',
                            objectFit: 'cover',
                          }}
                          alt=""
                        />
                      </Box>
                    ) : (
                      <Box>
                        <img
                          src={URL.createObjectURL(avatar.value)}
                          style={{
                            width: '100%',
                            objectFit: 'fill',
                          }}
                          alt=""
                        />
                      </Box>
                    )}
                  </React.Fragment>
                ) : (
                  <CameraAlt
                    sx={{
                      color: 'white',
                      fontSize: '30px',
                    }}
                  />
                )}
              </IconButton>
            }
          />
          <Typography
            sx={{
              color: '#a3a3a3',
              mb: 2,
              mt: 2,
              fontSize: '14px',
              textAlign: 'center',
            }}
          >
            Upload or Drop your profile picture here or select an icon
          </Typography>
          <Box>
            <Grid container spacing={3}>
              {defaultIcons.map((item, index) => {
                return (
                  <Grid key={index} item sm={3} xs={4}>
                    <Box
                      sx={{
                        width: '80px',
                        height: '80px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        '&:hover': {
                          '& .icon-img': {
                            filter: 'grayscale(0%) !important',
                            transform: 'scale(1.1)',
                            transition: '0.25s all ease-in-out',
                          },
                        },
                      }}
                      onClick={() => {
                        handleSetAvatar(item, 'icon')
                      }}
                    >
                      <img
                        style={{
                          maxWidth: '90%',
                          maxHeight: '100%',
                          objectFit: 'cover',
                          filter:
                            avatar && avatar.value === item ? 'grayscale(0%)' : 'grayscale(100%)',
                          transition: '0.25s all ease-in-out',
                        }}
                        src={item}
                        alt=""
                        className="icon-img"
                      />
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
          <Button
            fullWidth
            variant="contained"
            color="success"
            sx={{
              mt: 2,
            }}
            disabled={!avatar || avatarButtonDisabled}
            onClick={() => {
              handleUploadAvatar()
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>
    </RBox>
  )
}

export default ProfileManagement
