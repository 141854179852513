import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import UITextRegular from '../UIText/UITextRegular'

const UIPreloaderLG = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '1000',
        width: '100%',
        height: '100%',
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        gap: '10px',
        color: colorsConfig.mainBlue,
        '& .MuiCircularProgress-circle': {
          stroke: colorsConfig.mainBlue,
        },
      }}
    >
      <CircularProgress size={130} thickness={6} />
      <UITextRegular text="preloader_loading" />
    </Box>
  )
}

export default UIPreloaderLG
