export const colorsConfig = {
  white: '#FFFFFF',
  mainBlack: '#3D3D3D',
  mainBlue: '#007DFF',
  fadedBlue: '#4FA5FF',
  mediumBlue: '#E1F0FF',
  mainGreen: '#4DED6D',
  buttonGreenHover: '#30733a',
  lightGreen: '#F5FFF3',
  mediumGreen: '#D6FFDE',
  mainRed: '#EB2E1C',
  darkRed: '#AC0000',
  redTransparent: 'rgba(172,0,0,0.09)',
  darkRedTransparent: 'rgba(172,0,0,0.2)',
  darkRedHover: '#690000',
  buttonYellow: '#F5BD4F',
  shadowedYellow: 'rgba(241,183,77,0.4)',
  buttonYellowHover: '#b18838',
  bgLightBlue: '#F7FAFB',
  buttonHover: '#0057B2',
  buttonDisabled: '#D9D9D9',
  iconGray: '#757575',
  lightGray: '#B8B8B8',
  darkGray: '#3D3D3D',
  lightYellow: '#FFF9ED',
  bgGreen: '#F9FCF4',
  gray20: '#D0D1D2',
  darkBlue: '#11263C',
}
