export const swissCantons = [
  'geo_canton_zurich',
  'geo_canton_bern',
  'geo_canton_vaud',
  'geo_canton_aargau',
  'geo_canton_st_gallen',
  'geo_canton_geneva',
  'geo_canton_lucerne',
  'geo_canton_ticino',
  'geo_canton_valais',
  'geo_canton_basel_landschaft',
  'geo_canton_fribourg',
  'geo_canton_thurgau',
  'geo_canton_basel_stadt',
  'geo_canton_schwyz',
  'geo_canton_zug',
  'geo_canton_solothurn',
  'geo_canton_neuchatel',
  'geo_canton_graubunden',
  'geo_canton_schaffhausen',
  'geo_canton_jura',
  'geo_canton_nidwalden',
  'geo_canton_glarus',
  'geo_canton_obwalden',
  'geo_canton_appenzell_ausserrhoden',
  'geo_canton_appenzell_innerrhoden',
  'geo_canton_uri',
]

export const emirates = [
  'Abu Dhabi',
  'Dubai',
  'Sharjah',
  'Ajman',
  'Ras Al Khaimah',
  'Fujairah',
  'Umm Al Quwain',
]
