import {createSlice} from "@reduxjs/toolkit";
import getWarrantyProductsBuilder from "./builders/getWarrantyProducts.builder";

const initialState = {
  isLoading: false,
  error: null,
  warrantyProducts: []
}

const warrantyProductsSlice = createSlice({
  name: 'warrantyProducts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getWarrantyProductsBuilder(builder)
  }
})

export default warrantyProductsSlice.reducer