import React from 'react'
import { colorsConfig } from '../config/themeConfig'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'

const SelectSmall = (props) => {
  const { FormControlProps, label, value, setValue, id, options, ...otherProps } = props

  return (
    <FormControl
      {...otherProps}
      fullWidth
      sx={{
        color: colorsConfig.iconGray,
        fontWeight: 400,
        fontSize: '14px',
        border: 'none',
      }}
      size={'small'}
    >
      <InputLabel
        id={label?.toLowerCase().replace(' ', '-')}
        sx={{
          color: colorsConfig.iconGray,
          fontWeight: 400,
          fontSize: '14px',
          '&.Mui-focused': {
            background: 'white',
            color: colorsConfig.mainBlue,
            boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
            overflow: 'visible',
          },
        }}
      >
        {label ? label : ''}
      </InputLabel>
      <Select
        labelId={label?.toLowerCase().replace(' ', '-')}
        id={id}
        label={label}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        sx={{
          borderRadius: '4px',
          height: '38px',
          fontSize: '14px',
          border: 'none',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
          },
        }}
      >
        {options.map((item, index) => {
          return (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default SelectSmall
