import React, { useEffect, useState } from 'react'
import { colorsConfig } from '../../../config/themeConfig'
import { Tab, Tabs, Box, IconButton, useMediaQuery } from '@mui/material'
import IconAddRound from '../../../assests/img/icons/icon-add-round-blue.svg'
import { useSelector, useDispatch } from 'react-redux'
import {
  setSelectedTab,
  createLoanApplication,
  deleteLoanApplication,
} from '../../../store/features/financeCar'
import { Clear } from '@mui/icons-material'
import TextNormal from '../../../ui/Text/TextNormal'
import { privateRequest } from '../../../utils/request'
import { API_FINANCE_ENDPOINTS } from '../../../config/default'
import DeleteLoanApplicationModal from '../../../components/Modals/DeleteLoanApplicationModal'
import { setCurrentLoanApplication } from '../../../store/features/financing/financing.reducer'
import { generateUID } from '../../../utils/generateUID'

const FinancingTabs = () => {
  const dispatch = useDispatch()

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const loanApplications = useSelector((state) => state.carFinance.loanApplications)
  const tab = useSelector((state) => state.carFinance.selectedTab)

  const [deleteModal, setDeleteModal] = useState(false)
  const [currentLoanApplicationId, setCurrentLoanApplicationId] = useState('')
  const [currentCustomerName, setCurrentCustomerName] = useState('')
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false)

  const getLoanApplicationStatus = (loanApplication) => {
    if (loanApplication.currentWorkflowState === 'customer_selected') {
      return 'Financed'
    }
    if (loanApplication.currentWorkflowState === 'new') {
      return 'Loan calculation saved'
    }
    if (loanApplication.currentWorkflowState === 'application_preparation') {
      return 'Customer data saved'
    }
    if (loanApplication.currentWorkflowState === 'approval_process_started') {
      if (
        loanApplication.loanDeals.filter((item) => item.currentWorkflowState === 'bank_sent')
          .length === loanApplication.loanDeals.length
      ) {
        return 'Awaiting bank replies'
      }
      if (loanApplication.loanDeals.filter((item) => item.isCustomerSelected)[0]) {
        return 'Financed'
      }
      if (loanApplication.loanDeals.filter((item) => item.isDealerSelected)[0]) {
        return "Awaiting customer's approval"
      }
      if (
        loanApplication.loanDeals.filter(
          (item) =>
            item.currentWorkflowState === 'approved' ||
            item.currentWorkflowState === 'bank_request_info' ||
            item.currentWorkflowState === 'bank_declined',
        )[0]
      ) {
        return 'Bank replies received'
      }
      return 'Awaiting bank replies'
    }
    return loanApplication.currentWorkflowState
  }

  const handleArchiveLoanApplication = (loanApplicationId) => {
    if (loanApplications[tab].id === loanApplicationId) {
      dispatch(setSelectedTab(0))
      dispatch(setCurrentLoanApplication(loanApplications[0]))
    } else if (loanApplications.length - 1 === tab) {
      dispatch(setSelectedTab(tab - 1))
      dispatch(setCurrentLoanApplication(loanApplications[tab - 1]))
    }
    if (loanApplicationId.toString().includes('blank_')) {
      dispatch(deleteLoanApplication(loanApplicationId))
      setDeleteModal(false)
      setCurrentLoanApplicationId('')
      setCurrentCustomerName('')
      dispatch(setCurrentLoanApplication(null))
      return
    }
    setDeleteButtonDisabled(true)
    privateRequest
      .put(API_FINANCE_ENDPOINTS.loanApplicationArchive(loanApplicationId), {})
      .then(() => {
        setDeleteModal(false)
        setCurrentLoanApplicationId('')
        setCurrentCustomerName('')
        dispatch(deleteLoanApplication(loanApplicationId))
      })
      .finally(() => {
        setDeleteButtonDisabled(false)
      })
  }

  const renderFinanceTab = (index) => {
    let customerName
    if (loanApplications[index]?.customer.firstName || loanApplications[index]?.customer.lastName) {
      customerName = `${loanApplications[index]?.customer.firstName} ${loanApplications[index]?.customer.lastName}`
    } else {
      customerName = `Customer #${index + 1}`
    }
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            mr: '5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <TextNormal
            sx={{
              whiteSpace: 'nowrap',
              fontFamily: '"HelveticaNeue", sans-serif',
              fontWeight: 500,
              textAlign: 'left',
            }}
          >
            {customerName}
          </TextNormal>
          <TextNormal
            sx={{
              whiteSpace: 'nowrap',
              fontFamily: '"HelveticaNeue", sans-serif',
              fontWeight: 500,
              fontSize: '12px',
              color: colorsConfig.mainBlue,
            }}
          >
            {getLoanApplicationStatus(loanApplications[index])}
          </TextNormal>
        </Box>
        {loanApplications.length > 1 ? (
          <IconButton
            sx={{
              width: '28px',
              height: '28px',
              '&:hover': {
                '& > svg': {
                  fill: colorsConfig.darkRed,
                  transition: '0.25s all ease-in-out',
                },
              },
            }}
            onClick={(e) => {
              e.stopPropagation()
              setDeleteModal(true)
              setCurrentLoanApplicationId(loanApplications[index].id)
              setCurrentCustomerName(customerName)
            }}
          >
            <Clear
              sx={{
                fill: colorsConfig.lightGray,
                fontSize: '20px',
                transition: '0.25s all ease-in-out',
              }}
            />
          </IconButton>
        ) : null}
      </Box>
    )
  }

  const handleChangeTab = (e, newVal) => {
    dispatch(setSelectedTab(newVal))
    dispatch(setCurrentLoanApplication(loanApplications[newVal]))
  }

  const handleCreateLoanApplication = () => {
    dispatch(createLoanApplication())
    dispatch(
      setCurrentLoanApplication({
        hash: generateUID(),
      }),
    )
  }

  return (
    <Box
      className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
      sx={{
        background: colorsConfig.bgLightBlue,
        py: '7px',
        pr: '17px',
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: '30px',
        '&.xs-only': {
          pr: 0,
        },
        '&.tablet-only': {
          pr: '10px',
        },
      }}
    >
      <Box
        className={[
          'disable-scroll-bar',
          xsOnly ? 'xs-only' : '',
          tabletOnly ? 'tablet-only' : '',
        ].join(' ')}
        sx={{
          maxWidth: 'calc(100vw - 128px - 250px - 46px)',
          overflow: 'hidden',
          '&.xs-only': {
            maxWidth: '100%',
          },
          '&.tablet-only': {
            maxWidth: '100%',
          },
        }}
      >
        <Tabs
          className={[tabletOnly ? 'tablet-only' : ''].join(' ')}
          value={tab}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons={tabletOnly ? true : 'auto'}
          sx={{
            width: '100%',
            '& .MuiTabs-indicator': {
              background: colorsConfig.mainGreen,
            },
            '& .MuiTab-textColorPrimary': {
              color: colorsConfig.mainBlack,
              textTransform: 'none',
              fontFamily: '"HelveticaNeue", sans-serif',
              fontSize: '16px',
              fontWeight: 500,
            },
            '& .MuiButtonBase-root': {
              background: colorsConfig.bgLightBlue,
            },
            '& .Mui-selected': {
              color: colorsConfig.mainBlack,
            },
            '&.tablet-only': {
              '& .Mui-disabled.MuiTabs-scrollButtons.MuiTabs-scrollButtonsHideMobile': {
                opacity: 0.3,
              },
            },
          }}
        >
          {loanApplications.map((item, index) => {
            return (
              <Tab
                key={index}
                label={renderFinanceTab(index)}
                sx={{
                  fontSize: '13px',
                  pr: '10px',
                  pb: '6px',
                  pt: '6px',
                  minWidth: 0,
                }}
              />
            )
          })}
        </Tabs>
      </Box>

      <IconButton
        sx={{
          width: '38px',
          height: '38px',
          padding: '0',
          '&:hover': {
            background: 'none',
          },
          '&.Mui-disabled': {
            opacity: '0.5',
          },
        }}
        onClick={() => {
          handleCreateLoanApplication()
        }}
      >
        <img style={{ width: '100%' }} src={IconAddRound} alt="" />
      </IconButton>
      <DeleteLoanApplicationModal
        open={deleteModal}
        setOpen={(value) => {
          if (value) setDeleteModal(true)
          if (!value) {
            setCurrentCustomerName('')
            setCurrentLoanApplicationId('')
            setDeleteModal(false)
          }
        }}
        customerName={currentCustomerName}
        handleAction={() => {
          handleArchiveLoanApplication(currentLoanApplicationId)
        }}
        deleteButtonDisabled={deleteButtonDisabled}
      />
    </Box>
  )
}

export default FinancingTabs
