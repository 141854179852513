import {createAsyncThunk} from "@reduxjs/toolkit";
import warrantyProductsService from "../../../api/warrantyApplicationsService/warrantyProductsApiService";

export const getWarrantyProducts = createAsyncThunk(
  'warrantyProducts/getWarrantyProducts',
  async (_, {rejectWithValue}) => {
    try {
      const response = await warrantyProductsService.getWarrantyProducts()
      return response.data
    } catch (e) {
      if (!e.response) throw (e)
      return rejectWithValue(e.response.data)
    }
  }
)