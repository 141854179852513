import React, { useState, useEffect } from 'react'
import {
  Box,
  Container,
  Typography,
  Tabs,
  Tab,
  Pagination,
  TablePagination,
  Table,
  useMediaQuery,
  Button,
} from '@mui/material'
import { API_ENDPOINTS, API_FINANCE_ENDPOINTS, CDN_BASE_URL } from '../../../config/default'
import { privateRequest } from '../../../utils/request'
import { colorsConfig } from '../../../config/themeConfig'
import Preloader from '../../../components/Preloader'
import { OperatorTableHead, OperatorTableBody } from '../OperatorTable'
import {
  appraisalsRequestsTableHeadCells,
  auctionsRequestsTableHeadCells,
  financingRequestsTableHeadCells,
  signUpRequestsTableHeadCells,
} from './OperatorPanelTableData'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import carDefaultPicture from '../../../assests/img/car-not-found.png'
import DashboardAwaitingRestitution from './sections/DashboardAwaitingRestitution'

const OperatorDashboard = () => {
  const statusCount = useSelector((state) => state.rviOperator.statusCount)
  const dispatch = useDispatch()

  const extraLarge = useMediaQuery('(min-width: 1400px)')
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const [sort, setSort] = useState({
    field: 0, // Field to sort by
    order: 'desc', // 'asc' or 'desc'
  })

  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState(0)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [auctionsRequests, setAuctionsRequests] = useState([])
  const [appraisalsRequests, setAppraisalsRequests] = useState([])
  const [financingRequests, setFinancingRequests] = useState([])
  const [signUpRequests, setSignUpRequests] = useState([])
  const [paginatedRequests, setPaginatedRequests] = useState([])

  const [numAppraisalsRequests, setNumAppraisalsRequests] = useState(null)
  const [numAuctionsRequests, setNumAuctionsRequests] = useState(null)
  const [numFinancingRequests, setNumFinancingRequests] = useState(null)
  const [numSignUpRequests, setNumSignUpRequests] = useState(null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  const sections = [
    {
      id: 5,
      name: `Awaiting restitution (${statusCount.policiesInRestitution})`,
    },
    {
      id: 1,
      name: `Appraisals (${appraisalsRequests.length})`,
    },
    {
      id: 2,
      name: `Auction clearing (${auctionsRequests.length})`,
    },
    {
      id: 3,
      name: `Financing Requests (${financingRequests.length})`,
    },
    {
      id: 4,
      name: `Sign Up Requests (${signUpRequests.length})`,
    },
  ]

  function handleFormatDate(date, symbol) {
    const regex = /^(\d{4})-(\d{2})-(\d{2}).*$/

    const match = date.match(regex)

    const day = match[3]
    const month = match[2]
    const year = match[1]

    return `${day}${symbol}${month}${symbol}${year}`
  }

  const handleSetCarPhoto = (car) => {
    if (car && car.mainForeshortening && car.mainForeshortening.photo) {
      return `${CDN_BASE_URL}${car.mainForeshortening.photo.path}`
    } else {
      return carDefaultPicture
    }
  }

  const getAuctionsRequests = () => {
    setLoading(true)

    privateRequest
      .get(
        API_ENDPOINTS.deals,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        },
      )
      .then((response) => {
        const filteredArray = response.data[`hydra:member`]
        const auctionsRequestsArray = filteredArray
          .filter(
            (request) =>
              request.currentWorkflowState !== 'succeed' &&
              request.currentWorkflowState !== 'cancel',
          )
          .map((item) => {
            const {
              buyer: { name: buyerName },
              seller: { name: sellerName },
              car: {
                id: carId,
                make: { name: makeName },
                model: { name: modelName },
                vin,
              },
              value: amount,
              updatedAt: date,
            } = item

            return {
              id: carId,
              link: `/operator/auctions/${carId}`,
              date: date,
              cells: [
                {
                  type: 'text-with-caption',
                  value: sellerName,
                },
                {
                  type: 'text-with-caption',
                  value: buyerName,
                },
                {
                  type: 'text-with-caption',
                  value: `${makeName} ${modelName}`,
                  caption: `VIN ${vin}`,
                },
                {
                  type: 'text',
                  value: amount,
                },
                {
                  type: 'text',
                  value: date,
                },
              ],
            }
          })
        setTimeout(() => setAuctionsRequests(auctionsRequestsArray), 0)
      })
  }

  const getAppraisalsRequests = () => {
    setLoading(true)

    const path = `?pagination=false&isArchived=false&combinedStatus[]=car_workflow.quality_check&combinedStatus[]=deal_workflow.pending&combinedStatus[]=deal_workflow.money_received&combinedStatus[]=deal_workflow.money_payed&combinedStatus[]=deal_workflow.transportation_in_progress&combinedStatus[]=deal_workflow.invoice_sent&order[createdAt]=desc`

    privateRequest
      .get(
        `${API_ENDPOINTS.cars.default}${path}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        },
      )
      .then((response) => {
        const filteredArray = response.data[`hydra:member`]
        const appraisalsRequestsArray = filteredArray.map((item) => {
          const {
            id,
            company: { name: companyName },
            make: { name: makeName },
            model: { name: modelName },
            vin,
            lastUpdatedAt: date,
          } = item

          const cells = [
            {
              type: 'text',
              value: id,
            },
            {
              type: 'image',
              value: handleSetCarPhoto(item),
            },
            {
              type: 'text-with-caption',
              value: `${makeName} ${modelName}`,
              caption: `VIN ${vin}`,
            },
            {
              type: 'text',
              value: companyName,
            },
            {
              type: 'text',
              value: date,
            },
          ].filter(Boolean)

          return {
            id: id,
            link: `/cars/${id}`,
            date: date,
            cells: cells,
          }
        })
        setTimeout(() => setAppraisalsRequests(appraisalsRequestsArray), 0)
      })
  }

  const getSignUpRequests = () => {
    setLoading(true)

    privateRequest
      .get(
        API_ENDPOINTS.registerManagerDemands,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        const filteredArray = response.data[`hydra:member`].filter(
          (request) => !request.disapproveMessage && !request.approved,
        )
        const signUpRequestsArray = filteredArray.map((item) => {
          const { id, login, firstName, surname, companyName, updatedAt: date } = item

          return {
            id: id,
            link: `/operator/sign-up-requests/${id}`,
            date: date,
            cells: [
              {
                type: 'text',
                value: id,
              },
              {
                type: 'text',
                value: login,
              },
              {
                type: 'text',
                value: `${firstName} ${surname}`,
              },
              {
                type: 'text',
                value: companyName,
              },
              {
                type: 'text',
                value: date,
              },
            ],
          }
        })
        setTimeout(() => setSignUpRequests(signUpRequestsArray), 0)
      })
  }

  const LoanDealWorkflowTypes = [
    {
      id: 1,
      systemName: 'bank_sent',
      name: 'Waiting for bank response',
    },
    {
      id: 2,
      systemName: 'bank_declined',
      name: 'Refused',
    },
    {
      id: 3,
      systemName: 'bank_request_info',
      name: 'Bank request',
    },
    {
      id: 4,
      systemName: 'approved',
      name: 'Accepted',
    },
    {
      id: 5,
      systemName: 'approval_request_error',
      name: 'Sending to banks error',
    },
    {
      id: 6,
      systemName: 'approval_request',
      name: 'Preparing to send ',
    },
  ]

  const getFormattedDate = (date) => {
    const yyyy = date.getFullYear()
    let mm = date.getMonth() + 1
    let dd = date.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm
    return dd + '.' + mm + '.' + yyyy
  }

  const getFinancingRequests = () => {
    privateRequest.get(API_FINANCE_ENDPOINTS.loanDeals).then((response) => {
      const filteredRequests = response.data['hydra:member'].filter(
        (item) => item.currentWorkflowState === 'bank_sent',
      )
      setFinancingRequests(
        filteredRequests.map((item, index) => {
          const person = item.loanApplication?.customer?.person
          const loanApplicationId = item.loanApplication['@id'].replace(/[^0-9]/g, '')
          return {
            id: index + 1,
            link: `/operator/finance-requests/${loanApplicationId}?loanDeal=${item.id}`,
            cells: [
              {
                type: 'text',
                value: loanApplicationId,
              },
              {
                type: 'text',
                minWidth: '100px',
                value: person
                  ? `${person?.firstName} ${person?.lastName}`
                  : `Customer #${item.loanApplication?.customer?.id}`,
              },
              {
                type: 'text',
                value: item.bankTitle,
              },
              {
                type: 'text-with-caption',
                noWrap: true,
                value: `${item.loanApplication.car.make.name} ${item.loanApplication.car.model.name}`,
                caption: `VIN ${item.loanApplication.car.vin}`,
              },
              {
                type: 'text',
                value: LoanDealWorkflowTypes.filter(
                  (workflow) => workflow.systemName === item.currentWorkflowState,
                )[0]?.name,
              },
              {
                type: 'text',
                value: getFormattedDate(new Date(item.updatedAt)),
              },
            ],
          }
        }),
      )
    })
  }

  const getPaginatedData = (data, page, rowsPerPage) => {
    const sortedData = [...data]
    if (sort.field !== null) {
      sortedData.sort((a, b) => {
        const aValue = a.cells[sort.field].value
        const bValue = b.cells[sort.field].value

        if (isDateWithFormat(aValue) && isDateWithFormat(bValue)) {
          if (sort.order === 'asc') {
            return compareDateStrings(aValue, bValue)
          } else {
            return compareDateStrings(bValue, aValue)
          }
        }

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          if (sort.order === 'asc') {
            return aValue.localeCompare(bValue)
          } else {
            return bValue.localeCompare(aValue)
          }
        } else {
          // Handle comparison for non-string values
          if (sort.order === 'asc') {
            return aValue - bValue
          } else {
            return bValue - aValue
          }
        }
      })
    }

    const startIndex = (page - 1) * rowsPerPage
    const endIndex = startIndex + rowsPerPage
    return sortedData.slice(startIndex, endIndex)
  }

  const getTableHeadCells = () => {
    switch (tab) {
      case 1:
        return appraisalsRequestsTableHeadCells
      case 2:
        return auctionsRequestsTableHeadCells
      case 3:
        return financingRequestsTableHeadCells
      case 4:
        return signUpRequestsTableHeadCells
      default:
        return appraisalsRequestsTableHeadCells
    }
  }

  const getDataLength = () => {
    switch (tab) {
      case 1:
        return appraisalsRequests.length
      case 2:
        return auctionsRequests.length
      case 3:
        return financingRequests.length
      case 4:
        return signUpRequests.length
      default:
        return 0
    }
  }

  const updatePagination = () => {
    setPage(1)
  }

  useEffect(() => {
    if (tab !== 0) {
      updatePagination()
    }
  }, [tab, rowsPerPage, auctionsRequests, appraisalsRequests, financingRequests])

  const getCurrentData = () => {
    switch (tab) {
      case 1:
        return appraisalsRequests
      case 2:
        return auctionsRequests
      case 3:
        return financingRequests
      case 4:
        return signUpRequests
      default:
        return []
    }
  }

  const getPaginatedRequests = () => {
    const sortedData = getPaginatedData(getCurrentData(), page, rowsPerPage)
    setPaginatedRequests(handleShowContent(sortedData))
  }

  useEffect(() => {
    getAuctionsRequests()
    getAppraisalsRequests()
    getSignUpRequests()
    getFinancingRequests()
    getPaginatedRequests()
    dispatch(setCurrentPageTitle('Task list'))
  }, [])

  useEffect(() => {
    setNumAppraisalsRequests(appraisalsRequests.length)
    setNumAuctionsRequests(auctionsRequests.length)
    setNumFinancingRequests(financingRequests.length)
    setNumSignUpRequests(signUpRequests.length)
    setLoading(false)
  }, [signUpRequests, auctionsRequests, loading])

  const getMarkerColor = (createdAt, fill, stroke) => {
    const empty = !fill || !stroke

    const createdAtTime = new Date(createdAt).getTime()
    const currentTime = new Date().getTime()
    const timeDifferenceInHours = (currentTime - createdAtTime) / 3600000

    if (empty) {
      if (timeDifferenceInHours > 24) {
        fill = '#AC0000'
        stroke = '#B83629'
      } else if (timeDifferenceInHours > 20) {
        fill = '#F5BD4F'
        stroke = '#D69E3C'
      } else {
        fill = '#4DED6D'
        stroke = '#3CC457'
      }
    }

    return xsOnly ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
      >
        <circle cx="8" cy="7.11719" r="7" fill={fill} stroke={stroke} stroke-width="0.25" />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <circle cx="10" cy="10" r="9" fill={fill} stroke={stroke} strokeWidth="0.25" />
      </svg>
    )
  }

  const hasRequestsOver24Hours = (tab) => {
    const requests =
      tab === 0
        ? []
        : tab === 1
          ? appraisalsRequests
          : tab === 2
            ? auctionsRequests
            : tab === 3
              ? financingRequests
              : tab === 4
                ? signUpRequests
                : null
    const currentTime = new Date()
    const twentyFourHoursAgo = new Date(currentTime.getTime() - 24 * 60 * 60 * 1000)

    return requests?.some((request) => {
      const requestTime = new Date(request.cells[request.cells.length - 1].value)
      return requestTime < twentyFourHoursAgo
    })
  }

  const isDateWithFormat = (dateStr) => {
    const regex = /^(\d{2})\.(\d{2})\.(\d{4})$/
    return regex.test(dateStr)
  }

  const compareDateStrings = (dateStr1, dateStr2) => {
    const [day1, month1, year1] = dateStr1.split('.')
    const [day2, month2, year2] = dateStr2.split('.')

    if (year1 !== year2) {
      return parseInt(year1) - parseInt(year2)
    }

    if (month1 !== month2) {
      return parseInt(month1) - parseInt(month2)
    }

    return parseInt(day1) - parseInt(day2)
  }

  const handleShowContent = (data) => {
    const res = data.map((request) => {
      if (request?.link?.includes('/operator/finance')) return request
      const modifiedCells = request.cells.map((cell, index) => {
        if (index === request.cells.length - 1) {
          return {
            ...cell,
            value: (
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  // fontSize: xsOnly ? '14px' : '16px',
                  fontSize: '16px',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    marginTop: xsOnly ? '3px' : '1px',
                  }}
                >
                  {getMarkerColor(request.date)}
                </Box>
                {handleFormatDate(cell.value, '.')}
              </Box>
            ),
          }
        }
        return cell
      })
      return {
        ...request,
        cells: modifiedCells,
      }
    })

    return res
  }

  const handleSortRequest = (field) => {
    if (sort.field === field) {
      setSort({
        ...sort,
        order: sort.order === 'asc' ? 'desc' : 'asc',
      })
    } else {
      setSort({
        field,
        order: 'asc',
      })
    }
  }

  const handleSortClick = (columnIndex, order) => {
    setSort({
      field: columnIndex,
      order: order,
    })
  }

  return (
    <Box
      sx={{
        py: xsOnly ? '17px' : '24px',
        px: xsOnly ? '16px' : '24px',
        borderRadius: '6px',
        boxShadow: '0px 0px 24px 0px rgba(0, 0, 0, 0.10)',
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          px: '0 !important',
        }}
      >
        {loading ? (
          <Preloader type="gooey" text={'Loading the requests...'} />
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                marginBottom: '20px',
                justifyContent: xsOnly ? 'flex-start' : 'flex-end',
                order: xsOnly ? 1 : 0,
              }}
            >
              <Box
                sx={{
                  marginTop: xsOnly ? '25px' : '',
                  display: 'flex',
                  gap: xsOnly ? '12px' : '24px',
                  flexDirection: xsOnly ? 'column' : 'row',
                  '& .MuiBox-root': {
                    display: 'flex',
                    gap: xsOnly ? '2px' : '6px',
                    alignItems: 'center',
                    '& .MuiTypography-root': {
                      whiteSpace: 'nowrap',
                      // fontSize: xsOnly ? '14px' : '16px'
                      fontSize: '16px',
                    },
                  },
                }}
              >
                <Box>
                  {getMarkerColor('', '#4DED6D', '#3CC457')}
                  <Typography>—New request</Typography>
                </Box>
                <Box>
                  {getMarkerColor('', '#F5BD4F', '#D69E3C')}
                  <Typography>—Approaching the deadline</Typography>
                </Box>
                <Box>
                  {getMarkerColor('', '#AC0000', '#B83629')}
                  <Typography>—Action required</Typography>
                </Box>
              </Box>
            </Box>
            {xsOnly ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  position: 'relative',
                  width: 'calc(100% + 4px)',
                  margin: '20px 0',
                }}
              >
                {sections.map((section, index) => {
                  return (
                    <Button
                      key={index}
                      className={index === tab ? 'active' : ''}
                      sx={{
                        background: colorsConfig.bgLightBlue,
                        color: colorsConfig.mainBlack,
                        position: 'relative',
                        height: '48px',
                        textTransform: 'none',
                        fontFamily: '"Helvetica", sans-serif',
                        fontSize: '16px',
                        fontWeight: 500,
                        mb: '4px',
                        mr: '4px',
                        whiteSpace: 'nowrap',
                        flexGrow: 1,
                        '&::after': {
                          content: '" "',
                          display: 'block',
                          width: '100%',
                          height: '3px',
                          bottom: 0,
                          left: 0,
                          position: 'absolute',
                          background: colorsConfig.mainGreen,
                          transformOrigin: '50% 50%',
                          transform: 'scale(0)',
                          transition: '0.25s all ease-in-out',
                        },
                        '&.active': {
                          background: colorsConfig.bgLightBlue,
                          color: colorsConfig.mainBlack,
                          '&::after': {
                            transform: 'scale(1)',
                            transition: '0.25s all ease-in-out',
                          },
                        },
                        '&.Mui-disabled > div > p': {
                          color: 'rgba(0, 0, 0, 0.38) !important',
                        },
                      }}
                      onClick={() => {
                        setTab(index)
                      }}
                    >
                      {section.name}
                      {hasRequestsOver24Hours(index) && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          style={{ margin: '0 0 6px 2px' }}
                        >
                          <circle cx="5" cy="5" r="5" fill="#AC0000" />
                        </svg>
                      )}
                    </Button>
                  )
                })}
              </Box>
            ) : (
              <Tabs
                value={tab}
                onChange={(e, newVal) => {
                  setTab(newVal)
                }}
                variant="scrollable"
                sx={{
                  position: 'relative',
                  marginBottom: '40px',
                  width: '100%',
                  '& .MuiTabs-indicator': {
                    background: colorsConfig.mainGreen,
                  },
                  '& .MuiTab-textColorPrimary': {
                    color: colorsConfig.mainBlack,
                    textTransform: 'none',
                    fontFamily: '"Helvetica", sans-serif',
                    fontSize: '16px',
                    fontWeight: 500,
                    paddingLeft: '15px',
                  },
                  '& .Mui-selected': {
                    color: colorsConfig.mainBlack,
                  },
                  '& .MuiButtonBase-root': {
                    background: colorsConfig.bgLightBlue,
                    fontSize: '10px',
                  },
                  ' .MuiButtonBase-root': {
                    height: '41px',
                    padding: xsOnly ? '0 10px' : '0 30px',
                  },
                  '& .MuiTabScrollButton-root': {
                    height: '48px',
                  },
                  '& .MuiTouchRipple-root': {
                    height: '48px',
                  },
                }}
              >
                {sections.map((section, index) => {
                  return (
                    <Tab
                      key={index}
                      label={
                        <Box>
                          {section.name}
                          {hasRequestsOver24Hours(index) && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              fill="none"
                              style={{ margin: '0 0 6px 2px' }}
                            >
                              <circle cx="5" cy="5" r="5" fill="#AC0000" />
                            </svg>
                          )}
                        </Box>
                      }
                      sx={{
                        fontSize: '13px',
                      }}
                    />
                  )
                })}
              </Tabs>
            )}
            <Box
              sx={{
                '& .MuiPaper-root': {
                  marginBottom: '30px',
                },
                '& .MuiAccordionSummary-gutters': {
                  height: '69px',
                },
              }}
            >
              <Box
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  marginBottom: xsOnly ? '20px' : '40px',
                  '&.xs-only': {
                    width: 'calc(100vw - 20px)',
                    overflow: 'auto', // Enable horizontal scrolling
                    // scrollBehavior: 'smooth',
                    // scrollbarGutter: 'stable',
                    // scrollbarWidth: 'thin', // For Firefox
                    // '&::-webkit-scrollbar': {
                    //     width: '5px',
                    //     height: '5px',
                    //     borderRadius: '5px',
                    // },
                    // '&::-webkit-scrollbar-thumb': {
                    //     background: colorsConfig.buttonDisabled,
                    //     borderRadius: '4px', // Border radius of the thumb
                    // },
                  },
                }}
              >
                {tab === 0 && <DashboardAwaitingRestitution />}
                {tab === 1 && (
                  <Table>
                    <OperatorTableHead
                      tableCells={getTableHeadCells()}
                      sort={sort}
                      setSort={setSort}
                      onSortRequest={handleSortRequest}
                      handleSortClick={handleSortClick}
                    />
                    <OperatorTableBody
                      content={handleShowContent(
                        getPaginatedData(appraisalsRequests, page, rowsPerPage),
                      )}
                    />
                  </Table>
                )}
                {tab === 2 && (
                  <Table>
                    <OperatorTableHead
                      tableCells={getTableHeadCells()}
                      sort={sort}
                      setSort={setSort}
                      onSortRequest={handleSortRequest}
                      handleSortClick={handleSortClick}
                    />
                    <OperatorTableBody
                      content={handleShowContent(
                        getPaginatedData(auctionsRequests, page, rowsPerPage),
                      )}
                    />
                  </Table>
                )}
                {tab === 3 && (
                  <Table>
                    <OperatorTableHead
                      tableCells={getTableHeadCells()}
                      sort={sort}
                      setSort={setSort}
                      onSortRequest={handleSortRequest}
                      handleSortClick={handleSortClick}
                    />
                    <OperatorTableBody
                      content={handleShowContent(
                        getPaginatedData(financingRequests, page, rowsPerPage),
                      )}
                    />
                  </Table>
                )}
                {tab === 4 && (
                  <Table>
                    <OperatorTableHead
                      tableCells={getTableHeadCells()}
                      sort={sort}
                      setSort={setSort}
                      onSortRequest={handleSortRequest}
                      handleSortClick={handleSortClick}
                    />
                    <OperatorTableBody
                      content={handleShowContent(
                        getPaginatedData(signUpRequests, page, rowsPerPage),
                      )}
                    />
                  </Table>
                )}
              </Box>
              {tab !== 0 && (
                <Box
                  sx={{
                    '& .MuiToolbar-root': {
                      paddingLeft: 0,
                      display: 'flex',
                      flexWrap: xsOnly ? 'wrap' : '',
                      gap: xsOnly ? '10px 0' : '',
                    },
                    '& .MuiTablePagination-spacer': {
                      display: 'none',
                    },
                    '& .MuiTablePagination-displayedRows': {
                      display: xsOnly ? 'none' : 'flex',
                      flexGrow: 1,
                      justifyContent: 'flex-end',
                      fontSize: '12px',
                    },
                    '& .MuiInputBase-root': {
                      order: -1,
                      margin: 'auto 10px',
                      fontSize: '12px',
                      '& .MuiSvgIcon-root': {
                        fontSize: '16px',
                        marginRight: '10px',
                      },
                      background: 'white',
                      border: `1px solid ${colorsConfig.bgLightBlue}`,
                      borderRadius: '4px',
                      padding: '7px 15px 7px 13px',
                    },
                    '& .MuiPagination-root': {
                      marginLeft: xsOnly ? 'auto' : '12px',
                    },
                    '& .MuiPaginationItem-previousNext': {
                      padding: '8px 12px',
                      '& .MuiSvgIcon-root': {
                        display: 'none',
                      },
                      "&[aria-label='Go to previous page']": {
                        '&::before': {
                          content: "'Previous'",
                        },
                      },
                      "&[aria-label='Go to next page']": {
                        '&::before': {
                          content: "'Next'",
                        },
                      },
                      '&.Mui-disabled': {
                        opacity: 1,
                      },
                    },
                    '& .MuiPaginationItem-root': {
                      background: colorsConfig.bgLightBlue,
                      border: 'none',
                      fontSize: '12px',

                      '&.MuiPaginationItem-previousNext': {
                        display: xsOnly && 'none',
                        background: 'white',
                        border: `1px solid ${colorsConfig.bgLightBlue}`,
                      },
                    },
                    '& .MuiPaginationItem-page.Mui-selected': {
                      background: colorsConfig.mainBlue,
                      color: 'white',
                      '&:hover': {
                        background: 'rgb(21, 101, 192)',
                      },
                    },
                    '& .MuiTablePagination-selectLabel': {
                      fontSize: '12px',
                    },
                    '& .MuiTablePagination-select': {
                      // paddingRight: '18px',
                    },
                    '& .MuiSelect-select': {
                      padding: 0,
                      paddingRight: '12px',
                      '&:focus': {
                        background: 'none',
                      },
                    },
                  }}
                >
                  <TablePagination
                    component="div"
                    count={getDataLength()}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    showFirstButton={false}
                    showLastButton={false}
                    ActionsComponent={() => (
                      <>
                        <p
                          className="MuiTablePagination-selectLabel css-pdct74-MuiTablePagination-selectLabel"
                          id=":rh:"
                          style={{
                            display: 'flex',
                            order: -2,
                          }}
                        >
                          Show
                        </p>
                        <Pagination
                          count={Math.ceil(getDataLength() / rowsPerPage)}
                          variant="outlined"
                          shape="rounded"
                          page={page}
                          onChange={(event, newPage) => handleChangePage(event, newPage)}
                        />
                      </>
                    )}
                    labelRowsPerPage="entries"
                    labelDisplayedRows={({ count }) => {
                      const from = (page - 1) * rowsPerPage + 1
                      const to = Math.min(page * rowsPerPage, getDataLength())

                      if (!count) return `Show 0 entries`
                      return `Show ${from}-${to} of ${count} entries`
                    }}
                  />
                </Box>
              )}
            </Box>
          </>
        )}
      </Container>
    </Box>
  )
}

export default OperatorDashboard
