import React from 'react'
import { Box, Fade, Modal, Typography, Button } from '@mui/material'

const DeleteModal = (props) => {
  const { deleteModal, setDeleteModal, itemToDelete } = props

  return (
    <Modal
      open={deleteModal}
      onClose={() => {
        setDeleteModal(false)
      }}
    >
      <Fade in={deleteModal}>
        <Box
          sx={{
            width: '400px',
            position: 'absolute',
            top: '50%',
            marginTop: '-100px',
            left: '50%',
            marginLeft: '-200px',
            background: '#ffffff',
            borderRadius: '10px',
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            sx={{
              borderBottom: '1px solid lightgray',
              paddingBottom: '10px',
              paddingTop: '15px',
              paddingLeft: '15px',
              paddingRight: '15px',
              textAlign: 'center',
            }}
          >
            Confirm action
          </Typography>
          <Typography
            sx={{
              mt: 2,
              paddingLeft: '15px',
              paddingRight: '15px',
              paddingBottom: '15px',
              textAlign: 'center',
              mb: 2,
            }}
          >
            Are you sure you want to delete
            <br />
            <strong>{itemToDelete ? itemToDelete[1].content : ''}?</strong>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: '20px',
            }}
          >
            <Button
              variant="contained"
              color="success"
              sx={{
                marginRight: '10px',
              }}
              onClick={() => {
                setDeleteModal(false)
              }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setDeleteModal(false)
              }}
            >
              No
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}

export default DeleteModal
