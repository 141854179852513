import { useState } from 'react'
import carApiService from '../api/carService/carApiService'
import { toast } from 'react-toastify'
import { getApiError } from '../api/api'
import { mercureTopics } from '../api/mercure.constants'
import { EventSourcePolyfill } from 'event-source-polyfill'
import filesApiService from '../api/filesService/filesApiService'
import { downloadFileFromPrivateBucket } from '../utils/downloadFile'

const UsePdfAppraisal = () => {
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)

  const downloadPdfFile = async (fileId, fileName) => {
    try {
      const response = await filesApiService.getPrivateFile(fileId)
      const { signedUrl } = response.data
      if (signedUrl) {
        await downloadFileFromPrivateBucket(signedUrl, fileName)
      }
    } catch (e) {
      toast.error(getApiError(e))
    } finally {
      setIsDownloading(false)
    }
  }

  const startDownloadingAppraisalPdf = async (car) => {
    if (!car || !car.id) return null
    const { make, model, vin } = car
    const fileName =
      `${make?.name?.replace('.', '_')}_${model?.name?.replace('.', '_')}_${vin}`.replace(' ', '-')

    setIsGeneratingPdf(true)
    try {
      await carApiService.getPdfAppraisalResult(car?.id)
    } catch (e) {
      toast.error(getApiError(e))
      return
    } finally {
      setIsGeneratingPdf(false)
    }

    const url = mercureTopics.pdfAppraisalResult(car?.id)
    const tokenMerc = localStorage.getItem('mercure_token')
    const eventSource = new EventSourcePolyfill(url, {
      headers: {
        Authorization: 'Bearer ' + tokenMerc,
      },
    })

    setIsDownloading(true)

    eventSource.onmessage = function (event) {
      const { fileId } = JSON.parse(event.data)
      if (fileId) {
        downloadPdfFile(fileId, fileName)
      }
      eventSource.close()
    }

    eventSource.onerror = function (error) {
      toast.error(error)
      setIsDownloading(false)
    }
    eventSource.connect()
  }

  return {
    isGeneratingPdf,
    isDownloading,
    startDownloadingAppraisalPdf,
  }
}

export default UsePdfAppraisal
