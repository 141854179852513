import { createCar } from '../rviCar.actions'

export default (builder) => {
  builder
    // .addCase(createCar.pending, (state) => {
    //
    // })
    .addCase(createCar.fulfilled, (state, action) => {
      state.createdCarData = action.payload
    })
  // .addCase(createCar.rejected, (state, action) => {
  //
  // })
}
