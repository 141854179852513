import React from 'react'
import { Box, IconButton, styled, Typography, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { useNavigate } from 'react-router-dom'
import CarMiniAction from './CarMiniAction'
import IconAppleRounded from '../../assests/img/icons/icon-apple-rounded.svg'

const CarList = (props) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const StyledBox = styled(Box)(({ theme }) => ({
    width: 'calc(50% - 40px)',
    borderRadius: '6px',
    padding: '15px',
    boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
    marginBottom: '24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    cursor: 'pointer',
    '&.green': {
      background: colorsConfig.lightGreen,
    },
    '&.xs-only': {
      width: '100%',
      display: 'block',
    },
  }))

  const navigate = useNavigate()

  const { content, cars, setCars, getActionList, tableCells } = props

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      }}
    >
      {content.map((item, index) => {
        return (
          <StyledBox
            key={index}
            className={[item.greenCar ? 'green' : '', xsOnly ? 'xs-only' : ''].join(' ')}
          >
            <Box
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                width: 'calc(50% - 7.5px)',
                height: '150px',
                borderRadius: '6px',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                '&.xs-only': {
                  width: '100%',
                  height: 'auto',
                },
              }}
              onClick={() => {
                navigate(item.link)
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                }}
              >
                <img
                  style={{
                    width: '100%',
                    borderRadius: '6px',
                    verticalAlign: 'top',
                  }}
                  src={item.cells[1].value}
                  alt=""
                />
                {item.greenCar ? (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: '4px',
                      right: '4px',
                    }}
                  >
                    <img
                      src={IconAppleRounded}
                      alt=""
                      style={{
                        verticalAlign: 'top',
                      }}
                    />
                  </Box>
                ) : null}
              </Box>
            </Box>
            <Box
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                width: 'calc(50% - 7.5px)',
                height: '150px',
                position: 'relative',
                '&.xs-only': {
                  width: '100%',
                  height: 'auto',
                  mt: '15px',
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '12px',
                  color: colorsConfig.mainBlack,
                  position: 'absolute',
                  bottom: '2px',
                  right: 0,
                }}
              >
                {item.cells[0].value}
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  color: colorsConfig.mainBlack,
                }}
                onClick={() => {
                  navigate(item.link)
                }}
              >
                {item.cells[2].value}
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: colorsConfig.lightGray,
                  fontWeight: 400,
                  mb: '2px',
                }}
              >
                {item.cells[2].caption}
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  color: colorsConfig.mainBlack,
                }}
              >
                <span style={{ fontWeight: 700 }}>{`${tableCells[3].title}:`}</span>{' '}
                {item.cells[3].value}
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  color: colorsConfig.mainBlack,
                }}
              >
                <span style={{ fontWeight: 700 }}>{`${tableCells[4].title}:`}</span>{' '}
                {item.cells[4].value}
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  color: colorsConfig.mainBlack,
                }}
              >
                <span style={{ fontWeight: 700 }}>{`${tableCells[5].title}:`}</span>{' '}
                {item.cells[5].value}
              </Typography>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '-10px',
                  right: '-5px',
                  padding: 0,
                }}
                className={'action-button'}
              >
                <CarMiniAction
                  cars={cars}
                  setCars={setCars}
                  cell={item.cells[6]}
                  carId={item.id}
                  getActionList={getActionList}
                  car={item.car}
                />
              </IconButton>
            </Box>
          </StyledBox>
        )
      })}
    </Box>
  )
}

export default CarList
