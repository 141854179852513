import { createAsyncThunk } from '@reduxjs/toolkit'
import customerAppraisalsApiService from '../../../api/customerAppraisalsApiService/customerAppraisalsApiService'
import carApiService from '../../../api/carService/carApiService'
import { adaptParams } from '../../../api/api'

export const getCustomerAppraisals = createAsyncThunk(
  'customerAppraisals/getCustomerAppraisals',
  async (_, { rejectWithValue, getState }) => {
    try {
      const params = getState().customerAppraisals.params
      console.log({ params })
      const response = await customerAppraisalsApiService.getCustomerAppraisals(adaptParams(params))
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getCustomerAppraisalCars = createAsyncThunk(
  'customerAppraisals/getCustomerAppraisalCars',
  async (_, { rejectWithValue, getState }) => {
    try {
      const params = getState().customerAppraisals.params
      const response = await carApiService.getCars(params)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)
