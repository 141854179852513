import React from 'react'
import { Box, Stack } from '@mui/material'
import UITextHeadlineLarge from '../../ui/UIText/UITextHeadlineLarge'
import UITextRegular from '../../ui/UIText/UITextRegular'
import ImageTrafficLights from '../../assests/img/img-traffic-lights.svg'
import ButtonHollow from '../../ui/Buttons/ButtonHollow'
import { useNavigate } from 'react-router-dom'
import { colorsConfig } from '../../config/themeConfig'
import UITextTitleSmall from '../../ui/UIText/UITextTitleSmall'
import { SHOW_DEV_COMPONENTS } from '../../config/default'

const ErrorPlaceholderFullScreen = ({ error, resetErrorBoundary }) => {
  const navigate = useNavigate()

  console.log({ error })

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack gap="30px" alignItems="center">
        <img src={ImageTrafficLights} alt="" />
        <Stack gap="10px" alignItems="center">
          <UITextHeadlineLarge text="Sorry, something went wrong" />
          <UITextRegular text="Our team is already working to fix the issue" />
        </Stack>
        {SHOW_DEV_COMPONENTS && (
          <Box
            sx={{
              borderRadius: '6px',
              border: '1px solid',
              borderColor: colorsConfig.darkRed,
              background: colorsConfig.darkRedTransparent,
              p: '30px',
            }}
          >
            <Stack gap="20px">
              <UITextTitleSmall text={error?.message} />
              <UITextRegular>
                <pre>{error?.stack}</pre>
              </UITextRegular>
            </Stack>
          </Box>
        )}
        <Stack direction="row">
          <ButtonHollow
            onClick={() => {
              navigate(-1)
              resetErrorBoundary()
            }}
          >
            Go back
          </ButtonHollow>
        </Stack>
      </Stack>
    </Box>
  )
}

export default ErrorPlaceholderFullScreen
