import React, { useState } from 'react'
import { Box, Button, Divider, Grid, IconButton, Stack, TextField, Typography } from '@mui/material'
import { setCarMainData } from '../../../store/features/rviCarSlice/rviCar.reducer'
import { useSelector, useDispatch } from 'react-redux'
import TextFieldSmall from '../../../ui/TextFieldSmall'
import { useTranslation } from 'react-i18next'
import UploadAppraisalDocument from '../Operator/AppraisalComponents/UploadAppraisalDocument'
import MainAppraisal from '../Operator/AppraisalComponents/MainAppraisal'

const AppraisalStep = () => {
  const { t } = useTranslation()

  const createdCarData = useSelector((state) => state.addCar.createdCarData)
  const carData = useSelector((state) => state.addCar.carMainData)
  const dispatch = useDispatch()

  const [showGuidelines, setShowGuidelines] = useState(false)
  const [photosUpdated, setPhotosUpdated] = useState(false)
  const [disableSaveOrderButton, setDisableSaveOrderButton] = useState(false)

  return (
    <Box
      sx={{
        mt: '40px',
        mb: '100px',
      }}
    >
      <UploadAppraisalDocument />
      <Divider sx={{ mt: '30px', mb: '30px' }} />
      <MainAppraisal carId={createdCarData?.id} />
      {/*<Box*/}
      {/*  sx={{*/}
      {/*    mt: '30px',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Typography*/}
      {/*    sx={{*/}
      {/*      fontWeight: 700,*/}
      {/*      fontSize: '18px',*/}
      {/*      color: colorsConfig.mainBlack,*/}
      {/*      mb: '12px',*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    Upload 11 photos of the exterior and interior of the car*/}
      {/*  </Typography>*/}
      {/*  <Button*/}
      {/*    sx={{*/}
      {/*      textTransform: 'none',*/}
      {/*      fontSize: '16px',*/}
      {/*      fontWeight: 700,*/}
      {/*      color: colorsConfig.mainBlue,*/}
      {/*      mb: '40px',*/}
      {/*      px: '0',*/}
      {/*    }}*/}
      {/*    onClick={() => {*/}
      {/*      setShowGuidelines(!showGuidelines)*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {showGuidelines ? 'Hide image guidelines' : 'See image guidelines'}*/}
      {/*  </Button>*/}
      {/*  {showGuidelines ? (*/}
      {/*    <Box*/}
      {/*      sx={{*/}
      {/*        mb: '40px',*/}
      {/*        position: 'relative',*/}
      {/*        mt: '-50px',*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <Typography*/}
      {/*        sx={{*/}
      {/*          fontSize: '16px',*/}
      {/*          fontWeight: 400,*/}
      {/*          fontFamily: '"Helvetica", sans-serif',*/}
      {/*          mb: '25px',*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        Upload one photo for each angle*/}
      {/*      </Typography>*/}
      {/*      <Grid container spacing={3}>*/}
      {/*        /!*{carPhotos.map((carPhotoItem, index) => {*!/*/}
      {/*        /!*  let image, noPhoto*!/*/}
      {/*        /!*  if (carPhotoItem.newCarPhoto) {*!/*/}
      {/*        /!*    image = `${URL.createObjectURL(carPhotoItem.newCarPhoto)}`*!/*/}
      {/*        /!*    noPhoto = false*!/*/}
      {/*        /!*  } else if (carPhotoItem.carPhoto) {*!/*/}
      {/*        /!*    image = `${CDN_BASE_URL}${carPhotoItem.carPhoto.path}`*!/*/}
      {/*        /!*    noPhoto = false*!/*/}
      {/*        /!*  } else {*!/*/}
      {/*        /!*    image = `${CDN_BASE_URL}${carPhotoItem.foreshorteningFile}`*!/*/}
      {/*        /!*    noPhoto = true*!/*/}
      {/*        /!*  }*!/*/}
      {/*        /!*  return (*!/*/}
      {/*        /!*    <Grid item md={3} xs={6} key={index}>*!/*/}
      {/*        /!*      <CarPhotoItem*!/*/}
      {/*        /!*        key={index}*!/*/}
      {/*        /!*        index={index}*!/*/}
      {/*        /!*        image={image}*!/*/}
      {/*        /!*        noPhoto={noPhoto}*!/*/}
      {/*        /!*        status={carPhotoItem.status}*!/*/}
      {/*        /!*        handleDeletePhoto={handleDeletePhoto}*!/*/}
      {/*        /!*        carView={carPhotoItem.foreshortening}*!/*/}
      {/*        /!*        foreshorteningId={carPhotoItem.carForeshortening}*!/*/}
      {/*        /!*        mainPhoto={carPhotoItem.mainPhoto}*!/*/}
      {/*        /!*        photoId={carPhotoItem.carPhoto ? carPhotoItem.carPhoto['@id'] : null}*!/*/}
      {/*        /!*        refresh={getCarPhotos}*!/*/}
      {/*        /!*      />*!/*/}
      {/*        /!*    </Grid>*!/*/}
      {/*        /!*  )*!/*/}
      {/*        /!*})}*!/*/}
      {/*      </Grid>*/}
      {/*    </Box>*/}
      {/*  ) : null}*/}
      {/*  <Typography*/}
      {/*    sx={{*/}
      {/*      fontSize: '16px',*/}
      {/*      fontWeight: 400,*/}
      {/*      color: colorsConfig.mainBlack,*/}
      {/*      mb: '18px',*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    Upload multiple files or drag and drop 1 or more files below*/}
      {/*  </Typography>*/}
      {/*  <Box*/}
      {/*    sx={{*/}
      {/*      display: 'flex',*/}
      {/*      flexDirection: 'row',*/}
      {/*      justifyContent: 'flex-start',*/}
      {/*      alignItems: 'center',*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Box*/}
      {/*      sx={{*/}
      {/*        marginRight: '24px',*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <FileUploader*/}
      {/*        // handleChange={handleSetCarPhotos}*/}
      {/*        name="file"*/}
      {/*        types={['JPG', 'PNG', 'jpeg']}*/}
      {/*        hoverTitle="Drop here"*/}
      {/*        label="Upload or drop all the photos here"*/}
      {/*        classes="upload-picture"*/}
      {/*        multiple*/}
      {/*        maxSize={10}*/}
      {/*        children={*/}
      {/*          <Box*/}
      {/*            sx={{*/}
      {/*              width: '239px',*/}
      {/*              height: '160px',*/}
      {/*              background: colorsConfig.darkGray,*/}
      {/*              borderRadius: '6px',*/}
      {/*              display: 'flex',*/}
      {/*              flexDirection: 'column',*/}
      {/*              justifyContent: 'center',*/}
      {/*              alignItems: 'center',*/}
      {/*              cursor: 'pointer',*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            <Box*/}
      {/*              sx={{*/}
      {/*                mb: '5px',*/}
      {/*              }}*/}
      {/*            >*/}
      {/*              <img src={IconUpload} alt="" />*/}
      {/*            </Box>*/}
      {/*            <Typography*/}
      {/*              sx={{*/}
      {/*                color: 'white',*/}
      {/*                fontSize: '18px',*/}
      {/*                fontWeight: 700,*/}
      {/*              }}*/}
      {/*            >*/}
      {/*              Upload*/}
      {/*            </Typography>*/}
      {/*          </Box>*/}
      {/*        }*/}
      {/*      />*/}
      {/*    </Box>*/}
      {/*  </Box>*/}
      {/*  <Box*/}
      {/*    sx={{*/}
      {/*      mt: '40px',*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Box*/}
      {/*      sx={{*/}
      {/*        display: 'flex',*/}
      {/*        flexDirection: 'row',*/}
      {/*        justifyContent: 'flex-start',*/}
      {/*        alignItems: 'center',*/}
      {/*        mb: '20px',*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <Box*/}
      {/*        sx={{*/}
      {/*          display: 'flex',*/}
      {/*          flexDirection: 'row',*/}
      {/*          justifyContent: 'flex-start',*/}
      {/*          alignItems: 'center',*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        <Box*/}
      {/*          sx={{*/}
      {/*            marginRight: '5px',*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <img src={IconUploaded} alt="" />*/}
      {/*        </Box>*/}
      {/*        <Typography*/}
      {/*          //className={xsOnly ? 'xs-only' : ''}*/}
      {/*          sx={{*/}
      {/*            fontWeight: 700,*/}
      {/*            color: colorsConfig.mainBlack,*/}
      {/*            fontSize: '18px',*/}
      {/*            '&.xs-only': {*/}
      {/*              mb: '20px',*/}
      {/*            },*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          Uploaded*/}
      {/*        </Typography>*/}
      {/*      </Box>*/}
      {/*      /!*<Box>*!/*/}
      {/*      /!*    <ButtonLG*!/*/}
      {/*      /!*        onClick={() => {*!/*/}
      {/*      /!*            handleSaveOrder()*!/*/}
      {/*      /!*        }}*!/*/}
      {/*      /!*        disabled={disableSaveOrderButton}*!/*/}
      {/*      /!*    >*!/*/}
      {/*      /!*        Save order*!/*/}
      {/*      /!*    </ButtonLG>*!/*/}
      {/*      /!*</Box>*!/*/}
      {/*    </Box>*/}
      {/*    /!*<Grid container spacing={xsOnly ? '8px' : 3}>*!/*/}
      {/*    /!*  {carDataPhotos.map((carPhotoItem, index) => {*!/*/}
      {/*    /!*    if (!carPhotoItem.photo) return null*!/*/}
      {/*    /!*    return (*!/*/}
      {/*    /!*      <CarPhotoSorted*!/*/}
      {/*    /!*        key={index}*!/*/}
      {/*    /!*        index={index}*!/*/}
      {/*    /!*        image={`${CDN_BASE_URL}${carPhotoItem.photo.path}`}*!/*/}
      {/*    /!*        handleDeletePhoto={handleDeletePhoto}*!/*/}
      {/*    /!*        carView={carPhotoItem.foreshortening}*!/*/}
      {/*    /!*        setOrder={handleSetOrder}*!/*/}
      {/*    /!*        foreshorteningId={carPhotoItem['@id']}*!/*/}
      {/*    /!*        mainPhoto={carPhotoItem.mainPhoto}*!/*/}
      {/*    /!*        lastPhoto={carDataPhotos.length === index + 1}*!/*/}
      {/*    /!*        photoId={carPhotoItem.photo ? carPhotoItem.photo['@id'] : null}*!/*/}
      {/*    /!*      />*!/*/}
      {/*    /!*    )*!/*/}
      {/*    /!*  })}*!/*/}
      {/*    /!*</Grid>*!/*/}
      {/*  </Box>*/}
      {/*</Box>*/}
      {/*<Stack sx={{ mt: '50px' }} gap="30px">*/}
      {/*  <Stack gap="10px">*/}
      {/*    <Stack direction="row" alignItems="center" justifyContent="space-between">*/}
      {/*      <UITextLabelLarge sx={{ fontSize: '18px' }} text="Documents" />*/}
      {/*      <IconButton>*/}
      {/*        <KeyboardArrowDownRounded />*/}
      {/*      </IconButton>*/}
      {/*    </Stack>*/}

      {/*    <Divider />*/}
      {/*  </Stack>*/}
      {/*  <Stack gap="10px">*/}
      {/*    <Stack direction="row" alignItems="center" justifyContent="space-between">*/}
      {/*      <UITextLabelLarge sx={{ fontSize: '18px' }} text="Damages" />*/}
      {/*      <IconButton>*/}
      {/*        <KeyboardArrowDownRounded />*/}
      {/*      </IconButton>*/}
      {/*    </Stack>*/}

      {/*    <Divider />*/}
      {/*  </Stack>*/}
      {/*  <Stack gap="10px">*/}
      {/*    <Stack direction="row" alignItems="center" justifyContent="space-between">*/}
      {/*      <UITextLabelLarge sx={{ fontSize: '18px' }} text="Warning lights" />*/}
      {/*      <IconButton>*/}
      {/*        <KeyboardArrowDownRounded />*/}
      {/*      </IconButton>*/}
      {/*    </Stack>*/}

      {/*    <Divider />*/}
      {/*  </Stack>*/}
      {/*  <Stack gap="10px">*/}
      {/*    <Stack direction="row" alignItems="center" justifyContent="space-between">*/}
      {/*      <UITextLabelLarge sx={{ fontSize: '18px' }} text="Interior conditions" />*/}
      {/*      <IconButton>*/}
      {/*        <KeyboardArrowDownRounded />*/}
      {/*      </IconButton>*/}
      {/*    </Stack>*/}

      {/*    <Divider />*/}
      {/*  </Stack>*/}
      {/*  <Stack gap="10px">*/}
      {/*    <Stack direction="row" alignItems="center" justifyContent="space-between">*/}
      {/*      <UITextLabelLarge sx={{ fontSize: '18px' }} text="Mechanical conditions" />*/}
      {/*      <IconButton>*/}
      {/*        <KeyboardArrowDownRounded />*/}
      {/*      </IconButton>*/}
      {/*    </Stack>*/}

      {/*    <Divider />*/}
      {/*  </Stack>*/}
    </Box>
  )
}

export default AppraisalStep
