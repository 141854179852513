import React from 'react'
import { Box } from '@mui/material'
import IconCheckmark from '../../assests/img/icons/icon-checkmark-black.svg'
import UITextRegular from '../UIText/UITextRegular'

const UICheckPoint = ({ text }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '12px',
      }}
    >
      <img
        src={IconCheckmark}
        alt=""
        style={{
          width: '16px',
        }}
      />
      <UITextRegular text={text} />
    </Box>
  )
}

export default UICheckPoint
