import { createCarOperator } from '../addNewCar.actions'

export default (builder) => {
  builder
    // .addCase(createCar.pending, (state) => {
    //
    // })
    .addCase(createCarOperator.fulfilled, (state, action) => {
      state.createdCarData = action.payload
      state.carCreatedId = action.payload?.['@id']
    })
  // .addCase(createCar.rejected, (state, action) => {
  //
  // })
}
