import React from 'react'
import { TableHead, TableRow, TableCell, Box, Typography } from '@mui/material'
import { styled } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import IconArrowDown from '../../assests/img/icons/icon-arrow-down.svg'
import IconArrowDownBlue from '../../assests/img/icons/icon-arrow-down-blue.svg'
import { useTranslation } from 'react-i18next'

const CarTableHead = ({ tableCells, sort, setSort, small, disableColumns }) => {
  const { t } = useTranslation()

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    position: 'relative',
    paddingTop: '12px',
    paddingBottom: '12px',
    border: 'none',
    '&:first-of-type': {
      paddingLeft: 0,
    },
    '&:last-of-type': {
      paddingRight: 0,
    },
  }))

  const TriangleButtonUp = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '2px',
    right: '-17px',
    width: '13px',
    height: '10px',
    cursor: 'pointer',
    '&::after': {
      position: 'absolute',
      content: '" "',
      display: 'block',
      width: '11px',
      height: '6px',
      top: '50%',
      marginTop: '-3px',
      left: '50%',
      marginLeft: '-5.5px',
      background: `url(${IconArrowDown}) center center no-repeat`,
      transform: 'rotate(180deg)',
      transformOrigin: '50% 50%',
    },
    '&::before': {
      position: 'absolute',
      content: '" "',
      display: 'block',
      width: '11px',
      height: '6px',
      top: '50%',
      marginTop: '-3px',
      left: '50%',
      marginLeft: '-5.5px',
      background: `url(${IconArrowDownBlue}) center center no-repeat`,
      opacity: 0,
      transform: 'rotate(180deg)',
      transformOrigin: '50% 50%',
    },
    '&:hover': {
      '&::after': {
        opacity: 0,
        transition: '0.25s all ease-in-out',
      },
      '&::before': {
        opacity: 1,
        transition: '0.25s all ease-in-out',
      },
    },
    '&.active': {
      '&::after': {
        opacity: 0,
        transition: '0.25s all ease-in-out',
      },
      '&::before': {
        opacity: 1,
        transition: '0.25s all ease-in-out',
      },
    },
  }))

  const TriangleButtonDown = styled(Box)(() => ({
    position: 'absolute',
    bottom: '2px',
    right: '-17px',
    width: '13px',
    height: '10px',
    cursor: 'pointer',
    '&::after': {
      position: 'absolute',
      content: '" "',
      display: 'block',
      width: '11px',
      height: '6px',
      top: '50%',
      marginTop: '-3px',
      left: '50%',
      marginLeft: '-5.5px',
      background: `url(${IconArrowDown}) center center no-repeat`,
    },
    '&::before': {
      position: 'absolute',
      content: '" "',
      display: 'block',
      width: '11px',
      height: '6px',
      top: '50%',
      marginTop: '-3px',
      left: '50%',
      marginLeft: '-5.5px',
      background: `url(${IconArrowDownBlue}) center center no-repeat`,
      opacity: 0,
    },
    '&:hover': {
      '&::after': {
        opacity: 0,
        transition: '0.25s all ease-in-out',
      },
      '&::before': {
        opacity: 1,
        transition: '0.25s all ease-in-out',
      },
    },
    '&.active': {
      '&::after': {
        opacity: 0,
        transition: '0.25s all ease-in-out',
      },
      '&::before': {
        opacity: 1,
        transition: '0.25s all ease-in-out',
      },
    },
  }))

  const TableCellTitle = styled(Typography)(({ theme }) => ({
    color: colorsConfig.mainBlack,
    fontSize: '18px',
    fontWeight: 700,
    position: 'relative',
    display: 'inline',
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
    '&.variant-sm': {
      fontSize: '15px',
    },
  }))

  return (
    <TableHead>
      <TableRow>
        {tableCells.map((item, index) => {
          if (disableColumns && disableColumns?.length > 0) {
            if (disableColumns.find((item) => item === index + 1)) return null
          }
          return (
            <StyledTableCell key={index}>
              <TableCellTitle className={small ? 'variant-sm' : ''}>
                {item.translation ? t(item.translation) : item.title}
                {item.sortable ? (
                  <React.Fragment>
                    <TriangleButtonDown
                      className={sort === item.sortableAsc ? 'active' : ''}
                      onClick={() => {
                        if (sort === item.sortableAsc) {
                          setSort('')
                        } else {
                          setSort(item.sortableAsc)
                        }
                      }}
                    />
                    <TriangleButtonUp
                      className={sort === item.sortableDesc ? 'active' : ''}
                      onClick={() => {
                        if (sort === item.sortableDesc) {
                          setSort('')
                        } else {
                          setSort(item.sortableDesc)
                        }
                      }}
                    />
                  </React.Fragment>
                ) : null}
              </TableCellTitle>
            </StyledTableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export default CarTableHead
