import React, { useEffect, useState } from 'react'
import { Box, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import IconAttention from '../../assests/img/icons/icon-attention-sm.svg'
import TextBoldNormal from '../Text/TextBoldNormal'
import TextNormal from '../Text/TextNormal'
import RBox from '../RBox'
import { colorsConfig } from '../../config/themeConfig'

const UiBadgeCollapsable = (props) => {
  const { title, description, variant } = props

  const [open, setOpen] = useState(true)

  return (
    <RBox
      className={open ? 'open' : ''}
      sx={{
        position: 'relative',
        background: variant === 'primary' ? colorsConfig.mediumBlue : colorsConfig.mediumGreen,
        px: '24px',
        py: '13px',
        borderRadius: '6px',
        display: 'flex',
        alignItems: 'center',
        gap: '11px',
        opacity: 0.5,
        transform: 'scaleY(0)',
        mt: '-84px',
        transformOrigin: '50% 0%',
        transition: '0.25s transform ease-in-out, 0.25s margin linear 0.25s',
        '&.xs-only': {
          alignItems: 'flex-start',
          '& > img': {
            mt: '12px',
          },
        },
        '&.open': {
          mt: '0px',
          opacity: 1,
          transform: 'scaleY(1) translateY(0px)',
          transition: '0.25s transform ease-in-out, 0.25s margin linear 0.25s',
        },
      }}
    >
      <img src={IconAttention} alt="" />
      <Box>
        <TextBoldNormal>{title}</TextBoldNormal>
        <TextNormal>{description}</TextNormal>
      </Box>
      <IconButton
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
        onClick={() => {
          console.log('open')
          setOpen(!open)
        }}
      >
        <Close
          sx={{
            fill: colorsConfig.mainBlack,
            fontSize: '18px',
          }}
        />
      </IconButton>
    </RBox>
  )
}

export default UiBadgeCollapsable
