import React, { useState, useEffect } from 'react'
import { Box, Typography, Grid, CircularProgress, useMediaQuery } from '@mui/material'
import { PieChart } from '@mui/x-charts/PieChart'
import { colorsConfig } from '../../../config/themeConfig'
import useAllCars from '../../../hooks/useAllCars'
import { API_ENDPOINTS } from '../../../config/default'
import { privateRequest } from '../../../utils/request'

const { mainBlue, mainBlack } = colorsConfig

const ExpertTasksCake = ({ tab, appraisalsRequests }) => {
  const { carWorkflowFilters, loading } = useAllCars()

  const xsOnly = useMediaQuery('(max-width: 600px)')

  const { filterByReadyForSale } = carWorkflowFilters

  const handleChangeData = () => (tab === 1 ? filterByReadyForSale() : appraisalsRequests)

  const readyForSaleTotal = handleChangeData().length

  const greenMarkerColor = '#3CC457' // Green color
  const yellowMarkerColor = '#D69E3C' // Yellow color
  const redMarkerColor = '#B83629' // Red color

  const getRequestMarkerColor = (createdAt, fill, stroke) => {
    const empty = !fill || !stroke

    const createdAtTime = new Date(createdAt).getTime()
    const currentTime = new Date().getTime()
    const timeDifferenceInHours = (currentTime - createdAtTime) / 3600000

    if (timeDifferenceInHours > 24) {
      return redMarkerColor
    } else if (timeDifferenceInHours > 20) {
      return yellowMarkerColor
    } else {
      return greenMarkerColor
    }
  }

  const getRequestsFilteredByColor = (requests, color) =>
    requests.filter((request) => getRequestMarkerColor(request.createdAt) === color)

  const greenMarkerColorRequestsTotal = getRequestsFilteredByColor(
    handleChangeData(),
    greenMarkerColor,
  ).length
  const yellowMarkerColorRequestsTotal = getRequestsFilteredByColor(
    handleChangeData(),
    yellowMarkerColor,
  ).length
  const redMarkerColorRequestsTotal = getRequestsFilteredByColor(
    handleChangeData(),
    redMarkerColor,
  ).length

  return (
    <Box
      sx={{
        py: '37px',
        px: '30px',
        borderRadius: '6px',
        boxShadow: '0px 0px 24px 0px rgba(0, 0, 0, 0.10)',
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: mainBlack,
          fontSize: xsOnly ? '24px' : '40px',
          fontWeight: 700,
          marginRight: '20px',
          marginBottom: '25px',
          width: 1,
          textAlign: xsOnly ? 'center' : 'left',
        }}
      >
        Tasks cake
      </Typography>
      <Box
        sx={{
          // height: '245px',
          display: 'flex',
          alignItems: 'center',
          gap: '30px',
          flexDirection: xsOnly ? 'column' : 'row',
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              margin: '0 auto',
            }}
          >
            <CircularProgress
              size={60}
              sx={{
                '.css-176wh8e-MuiCircularProgress-circle': {
                  color: mainBlue,
                },
                margin: '0 8px',
              }}
            />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                width: 'fit-content',
                position: 'relative',
              }}
            >
              <PieChart
                colors={[greenMarkerColor, yellowMarkerColor, redMarkerColor]}
                series={[
                  {
                    data: [
                      { value: greenMarkerColorRequestsTotal },
                      { value: yellowMarkerColorRequestsTotal },
                      { value: redMarkerColorRequestsTotal },
                    ],
                    innerRadius: 78,
                    outerRadius: 100,
                    paddingAngle: 4,
                    cornerRadius: 50,
                    startAngle: -180,
                    endAngle: 180,
                    cx: 100,
                    cy: 100,
                  },
                ]}
                width={210}
                height={210}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <Typography
                  sx={{ fontSize: '32px', fontWeight: 700, color: '#11263C', marginLeft: '2.5px' }}
                >
                  {readyForSaleTotal}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: 'rgba(60, 60, 67, 0.60)',
                    marginTop: '-5px',
                    textAlign: 'center',
                  }}
                >
                  total
                </Typography>
              </Box>
            </Box>
            <Grid
              container
              sx={{
                width: 'fit-content',
                gap: '30px',
                flexDirection: xsOnly ? 'column' : 'row',
              }}
            >
              <Grid
                item
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}>
                  <Box
                    sx={{
                      width: '30px',
                      height: '4px',
                      background: greenMarkerColor,
                      borderRadius: '100px',
                      marginBottom: '6px',
                    }}
                  ></Box>
                  <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}> New request </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}>
                  <Typography sx={{ fontWeight: 700 }}>{greenMarkerColorRequestsTotal}</Typography>
                </Box>
              </Grid>
              <Grid
                item
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}>
                  <Box
                    sx={{
                      width: '30px',
                      height: '4px',
                      background: yellowMarkerColor,
                      borderRadius: '100px',
                      marginBottom: '6px',
                    }}
                  ></Box>
                  <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}>
                    {' '}
                    Approaching the deadline{' '}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}>
                  <Typography sx={{ fontWeight: 700 }}>{yellowMarkerColorRequestsTotal}</Typography>
                </Box>
              </Grid>
              <Grid
                item
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}>
                  <Box
                    sx={{
                      width: '30px',
                      height: '4px',
                      background: redMarkerColor,
                      borderRadius: '100px',
                      marginBottom: '6px',
                    }}
                  ></Box>
                  <Typography sx={{ fontSize: '14px', color: '#D0D1D2' }}>
                    {' '}
                    Urgent tasks{' '}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '4px', marginLeft: '45px' }}>
                  <Typography sx={{ fontWeight: 700 }}>{redMarkerColorRequestsTotal}</Typography>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Box>
  )
}

export default ExpertTasksCake
