import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { store } from './store'
import { Provider } from 'react-redux'
import './i18n/i18n'
import { appVersion } from './utils/constants/global.constants'

const storedVersion = localStorage.getItem('appVersion')

if (!storedVersion || storedVersion !== appVersion) {
  localStorage.clear()
  localStorage.setItem('appVersion', appVersion)
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)
