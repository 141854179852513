import React from 'react'
import UITableActionsList from '../../ui/UITable/UITableActionsList'
import { useNavigate } from 'react-router-dom'

const CompaniesActionItems = ({ data }) => {
  const navigate = useNavigate()

  return (
    <UITableActionsList
      actions={[
        {
          name: 'Edit company',
          handleAction: () => navigate(`/admin/companies/${data?.initialData?.id}/edit`),
        },
        {
          name: 'Add manager',
          handleAction: () =>
            navigate('/admin/managers/add', {
              state: {
                company: data?.initialData?.['@id'],
              },
            }),
        },
        // {
        //   name: 'Delete company',
        //   handleAction: () => {},
        // },
      ]}
    />
  )
}

export default CompaniesActionItems
