import React from 'react'
import { Box, Button } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import IconCrossRed from '../../../assests/img/icons/icon-cross-red.svg'
import TextNormal from '../../../ui/Text/TextNormal'
import TextAreaSmall from '../../../ui/TextAreaSmall'
import { useSelector, useDispatch } from 'react-redux'
import {
  setQualityCheckDamageApproved,
  setQualityCheckDamageHint,
} from '../../../store/features/qualityCheckSlice'

const QualityCheckDamage = (props) => {
  const { damageIndex } = props

  const qualityCheckDamage = useSelector((state) => state.qualityCheck.damages[damageIndex])
  const dispatch = useDispatch()

  const handleReject = () => {
    dispatch(
      setQualityCheckDamageApproved({
        index: damageIndex,
        value: !qualityCheckDamage.approved,
      }),
    )
  }

  const handleSetComment = (value) => {
    dispatch(
      setQualityCheckDamageHint({
        index: damageIndex,
        value: value,
      }),
    )
  }

  return (
    <Box
      sx={{
        width: '25%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        pr: '17px',
      }}
    >
      <Button
        sx={{
          mb: '10px',
          textTransform: 'none',
          '&:hover': {
            '& .button-icon-cross': {
              transition: '0.25s all ease-in-out',
              borderColor: colorsConfig.mainRed,
              '& img': {
                filter: 'grayscale(0%)',
                transition: '0.25s all ease-in-out',
              },
            },
          },
        }}
        onClick={() => {
          handleReject()
        }}
      >
        <Box
          className={`button-icon-cross ${!qualityCheckDamage.approved ? 'active' : ''}`}
          sx={{
            border: '1px solid',
            borderColor: colorsConfig.iconGray,
            width: '20px',
            height: '20px',
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            mr: '5px',
            alignItems: 'center',
            '& img': {
              transition: '0.25s all ease-in-out',
              filter: 'grayscale(100%)',
            },
            cursor: 'pointer',
            transition: '0.25s all ease-in-out',
            '&.active': {
              transition: '0.25s all ease-in-out',
              borderColor: colorsConfig.mainRed,
              '& img': {
                filter: 'grayscale(0%)',
                transition: '0.25s all ease-in-out',
              },
            },
          }}
        >
          <img src={IconCrossRed} alt="" />
        </Box>
        <TextNormal>Reject</TextNormal>
      </Button>
      {!qualityCheckDamage.approved ? (
        <Box
          sx={{
            mb: '7px',
            width: '100%',
          }}
        >
          <TextAreaSmall
            fullWidth
            multiline
            rows={4}
            placeholder={'Write the reason of rejection'}
            label={'Comment'}
            value={qualityCheckDamage.hint}
            onChange={(e) => {
              handleSetComment(e.target.value)
            }}
          />
        </Box>
      ) : null}
    </Box>
  )
}

export default QualityCheckDamage
