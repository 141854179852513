import React from 'react'
import { FormControl, FormControlLabel, RadioGroup, useMediaQuery } from '@mui/material'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import CustomRadio from '../../../ui/CustomRadio'
import { colorsConfig } from '../../../config/themeConfig'

const FormRadio = ({ label, id, required, options, ...props }) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers
  const { t } = useTranslation()

  return (
    <FormControl error={meta.touched && meta.error}>
      <RadioGroup
        row
        value={field.value}
        name={field.name}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        sx={{
          gap: {
            lg: '30px',
            md: '25px',
            xs: '10px',
          },
        }}
        {...props}
      >
        {options.map((item, index) => {
          return (
            <FormControlLabel
              key={index}
              control={<CustomRadio />}
              value={item.value}
              label={item.label}
              sx={{
                fontSize: '16px',
                color: colorsConfig.mainBlack,
                lineHeight: 'normal',
                fontWeight: 400,
                fontFamily: '"Helvetica", sans-serif',
              }}
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}

export default FormRadio
