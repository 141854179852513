import React, { useEffect, useState } from 'react'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import { useDispatch } from 'react-redux'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import { privateRequest } from '../../../utils/request'
import { API_ENDPOINTS, currentLocales } from '../../../config/default'
import AdminPanelSubblock from './AdminPanelSubblock'
import AdminPanelCarPart from './AdminPanelCarPart'

const AdminPanelCarParts = () => {
  const dispatch = useDispatch()

  const [carParts, setCarParts] = useState([])

  const handleSetCarParts = (data) => {
    setCarParts(
      data.map((item) => {
        const newDamages = item.damages.map((damage) => {
          return {
            ...damage,
            translations: currentLocales.map((locale) => {
              return {
                ...locale,
                value:
                  damage.translations.filter(
                    (translation) => translation.locale === locale.systemName,
                  )[0]?.value || ' ',
              }
            }),
          }
        })
        return {
          ...item,
          damages: newDamages,
          translations: currentLocales.map((locale) => {
            return {
              ...locale,
              value:
                item.translations.filter(
                  (translation) => translation.locale === locale.systemName,
                )[0]?.value || ' ',
            }
          }),
        }
      }),
    )
  }

  const getCarParts = () => {
    privateRequest.get(`${API_ENDPOINTS.carParts}?pagination=false`).then((response) => {
      handleSetCarParts(response.data['hydra:member'])
    })
  }

  useEffect(() => {
    dispatch(setCurrentPageTitle('Car parts & Damages'))
    getCarParts()
  }, [])

  return (
    <BoxShadowed
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      {carParts.map((item, index) => {
        return <AdminPanelCarPart key={index} carPart={item} refresh={getCarParts} />
      })}
    </BoxShadowed>
  )
}

export default AdminPanelCarParts
