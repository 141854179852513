import { createSlice } from '@reduxjs/toolkit'
import createManagerBuilder from './builders/createManager.builder'
import getManagerBuilder from './builders/getManager.builder'
import updateManagerBuilder from './builders/updateManager.builder'

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  create: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
  delete: {
    isLoading: false,
    error: null,
  },
}

const managerSlice = createSlice({
  name: 'managerReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createManagerBuilder(builder)
    getManagerBuilder(builder)
    updateManagerBuilder(builder)
  },
})

export default managerSlice.reducer
