import React, { useEffect, useState } from 'react'
import RBox from '../../../../ui/RBox'
import { Box, Grid, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../../../config/themeConfig'
import TextBoldNormal from '../../../../ui/Text/TextBoldNormal'
import TextNormal from '../../../../ui/Text/TextNormal'
import IconCheckmark from '../../../../assests/img/icons/icon-checkmark-black.svg'
import { sumDelimiter } from '../../../../utils/sumDelimiter'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import SimpleCheckbox from '../../../../ui/SimpleCheckbox'
import { privateRequest } from '../../../../utils/request'
import { API_ENDPOINTS } from '../../../../config/default'
import { useSelector, useDispatch } from 'react-redux'
import {
  setReferenceRisks,
  setSelectedPackage,
  setAllCalculations,
  setSelectedRisks,
  setCustomPackageCalculation,
} from '../../../../store/features/insuranceSlice'
import { getPackageRisks, getTotalPremium } from '../../insurance.helpers'
import { CURRENCY, REGION_LOCALE } from '../../../../utils/constants/global.constants'

const defaultRisks = {
  comprehensiveCasco: 'Comprehensive casco',
  driverAccident: 'Personal Accident for driver',
  passengerAccident: 'Personal Accident for passengers',
  roadsideAssistance: 'Roadside Assistance',
  offroadCover: 'Off-road Cover',
  omanCover: 'Oman Cover',
}

const CarInsurancePackages = () => {
  const selectedTab = useSelector((state) => state.insurance.selectedTab)
  const packages = useSelector((state) => state.insurance.applications[selectedTab].packages)
  const customPackageSelectedRisks = useSelector(
    (state) => state.insurance.applications[selectedTab].packages[2].selectedRisks,
  )
  const referenceRisks = useSelector((state) => state.insurance.referenceRisks)
  const dispatch = useDispatch()

  const getRisks = () => {
    privateRequest.get(API_ENDPOINTS.insurance.risks).then((response) => {
      dispatch(setReferenceRisks(response.data['hydra:member']))
    })
  }

  const getCalculation = async (risks) => {
    const response = await privateRequest.post(API_ENDPOINTS.insurance.preCalcRisks, { risks })
    return response
  }

  const getAllCalculations = () => {
    Promise.all(
      packages.map((item) => getCalculation(getPackageRisks(referenceRisks, item.selectedRisks))),
    ).then((result) => {
      dispatch(setAllCalculations(result.map((item) => getTotalPremium(item.data.calculatedRisks))))
    })
  }

  const getCustomPackageCalculation = () => {
    getCalculation(getPackageRisks(referenceRisks, packages[2].selectedRisks)).then((response) => {
      dispatch(setCustomPackageCalculation(getTotalPremium(response.data.calculatedRisks)))
    })
  }

  useEffect(() => {
    if (packages[2].loaded && referenceRisks.length !== 0) {
      getCustomPackageCalculation()
    }
  }, [packages[2].selectedRisks])

  useEffect(() => {
    if (referenceRisks.length !== 0) {
      getAllCalculations()
    }
  }, [referenceRisks, selectedTab])

  useEffect(() => {
    if (referenceRisks.length === 0) {
      getRisks()
    }
  }, [])

  return (
    <RBox>
      <Grid container spacing={'16px'} alignItems={'stretch'}>
        <Grid item xs={12} md={4}>
          <Box
            className={packages.find((item) => item.name === 'full')?.selected ? 'active' : ''}
            sx={{
              position: 'relative',
              borderRadius: '10px',
              border: '1px solid',
              borderColor: colorsConfig.lightGray,
              background: 'white',
              px: '18px',
              py: '20px',
              pb: '140px',
              minHeight: '100%',
              boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
              transition: '0.25s all ease-in-out',
              '&:hover': {
                borderColor: colorsConfig.mainGreen,
                background: colorsConfig.mainGreen,
                transition: '0.25s all ease-in-out',
                '& > button': {
                  background: 'white',
                  color: colorsConfig.mainBlack,
                  transition: '0.25s all ease-in-out',
                },
              },
              '&.active': {
                borderColor: colorsConfig.mainGreen,
                background: colorsConfig.mainGreen,
                '& > div > button': {
                  background: 'white',
                  color: colorsConfig.mainBlack,
                },
              },
            }}
          >
            <TextBoldNormal
              sx={{
                fontSize: '18px',
                mb: '10px',
              }}
            >
              Premium Pack
            </TextBoldNormal>
            <TextNormal
              sx={{
                mb: '15px',
              }}
            >
              Third party liability +
            </TextNormal>
            <Box
              sx={{
                mb: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '6px',
                }}
              >
                <img
                  src={IconCheckmark}
                  alt=""
                  style={{
                    width: '16px',
                  }}
                />
                <TextNormal>Comprehensive Casco</TextNormal>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '6px',
                }}
              >
                <img
                  src={IconCheckmark}
                  alt=""
                  style={{
                    width: '16px',
                  }}
                />
                <TextNormal>Personal Accident for driver</TextNormal>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '6px',
                  alignItems: 'flex-start',
                }}
              >
                <img
                  src={IconCheckmark}
                  alt=""
                  style={{
                    width: '16px',
                    marginTop: '4px',
                  }}
                />
                <TextNormal sx={{ lineHeight: 'normal' }}>
                  Personal Accident for passengers
                </TextNormal>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '6px',
                }}
              >
                <img
                  src={IconCheckmark}
                  alt=""
                  style={{
                    width: '16px',
                  }}
                />
                <TextNormal>Roadside Assistance</TextNormal>
              </Box>
            </Box>
            {packages[0].currentPrice ? (
              <Box
                sx={{
                  position: 'absolute',
                  width: '100%',
                  bottom: 0,
                  left: 0,
                  px: '18px',
                  pb: '20px',
                }}
              >
                <TextNormal>
                  From{' '}
                  <span style={{ fontWeight: 700, fontSize: '24px' }}>
                    {(packages[0].currentPrice / 12).toFixed(2)}
                  </span>{' '}
                  {CURRENCY[REGION_LOCALE]}/month
                </TextNormal>
                <TextNormal
                  sx={{
                    opacity: 0.5,
                    ml: '35px',
                    mb: '20px',
                  }}
                >
                  {packages[0].currentPrice} {CURRENCY[REGION_LOCALE]} per year
                </TextNormal>
                <ButtonLG
                  sx={{
                    minWidth: '180px',
                  }}
                  onClick={() => {
                    if (!packages.find((item) => item.name === 'full')?.selected) {
                      dispatch(setSelectedPackage('full'))
                    }
                  }}
                >
                  {packages.find((item) => item.name === 'full')?.selected ? 'Selected' : 'Choose'}
                </ButtonLG>
              </Box>
            ) : null}
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <RBox
            className={[
              packages.find((item) => item.name === 'basic')?.selected ? 'active' : '',
            ].join(' ')}
            sx={{
              position: 'relative',
              borderRadius: '10px',
              border: '1px solid',
              borderColor: colorsConfig.lightGray,
              background: 'white',
              px: '18px',
              py: '20px',
              pb: '140px',
              minHeight: '100%',
              boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
              transition: '0.25s all ease-in-out',
              '&:hover': {
                borderColor: colorsConfig.mainGreen,
                background: colorsConfig.mainGreen,
                transition: '0.25s all ease-in-out',
                '& > button': {
                  background: 'white',
                  color: colorsConfig.mainBlack,
                  transition: '0.25s all ease-in-out',
                },
              },
              '&.active': {
                borderColor: colorsConfig.mainGreen,
                background: colorsConfig.mainGreen,
                '& > div >  button': {
                  background: 'white',
                  color: colorsConfig.mainBlack,
                },
              },
              '&.xs-only, &.tablet-only': {
                height: 'auto',
              },
            }}
          >
            <TextBoldNormal
              sx={{
                fontSize: '18px',
                mb: '10px',
              }}
            >
              Basic Pack
            </TextBoldNormal>
            <TextNormal>Third party liability</TextNormal>
            {packages[1].currentPrice ? (
              <Box
                sx={{
                  position: 'absolute',
                  width: '100%',
                  bottom: 0,
                  left: 0,
                  px: '18px',
                  pb: '20px',
                }}
              >
                <TextNormal>
                  From{' '}
                  <span style={{ fontWeight: 700, fontSize: '24px' }}>
                    {(packages[1].currentPrice / 12).toFixed(2)}
                  </span>{' '}
                  {CURRENCY[REGION_LOCALE]}/month
                </TextNormal>
                <TextNormal
                  sx={{
                    opacity: 0.5,
                    ml: '35px',
                    mb: '20px',
                  }}
                >
                  {packages[1].currentPrice} {CURRENCY[REGION_LOCALE]} per year
                </TextNormal>
                <ButtonLG
                  sx={{
                    minWidth: '180px',
                  }}
                  onClick={() => {
                    if (!packages.find((item) => item.name === 'basic')?.selected) {
                      dispatch(setSelectedPackage('basic'))
                    }
                  }}
                >
                  {packages.find((item) => item.name === 'basic')?.selected ? 'Selected' : 'Choose'}
                </ButtonLG>
              </Box>
            ) : null}
          </RBox>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            className={packages.find((item) => item.name === 'custom')?.selected ? 'active' : ''}
            sx={{
              position: 'relative',
              borderRadius: '10px',
              border: '1px solid',
              borderColor: colorsConfig.lightGray,
              background: 'white',
              px: '18px',
              py: '20px',
              pb: '140px',
              minHeight: '100%',
              boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
              transition: '0.25s all ease-in-out',
              '&:hover': {
                borderColor: colorsConfig.mainGreen,
                background: colorsConfig.mainGreen,
                transition: '0.25s all ease-in-out',
                '& > button': {
                  background: 'white',
                  color: colorsConfig.mainBlack,
                  transition: '0.25s all ease-in-out',
                },
              },
              '&.active': {
                borderColor: colorsConfig.mainGreen,
                background: colorsConfig.mainGreen,
                '& > div > button': {
                  background: 'white',
                  color: colorsConfig.mainBlack,
                },
              },
            }}
          >
            <TextBoldNormal
              sx={{
                fontSize: '18px',
                mb: '10px',
              }}
            >
              Custom Pack
            </TextBoldNormal>
            <TextNormal
              sx={{
                mb: '15px',
              }}
            >
              Third party liability +
            </TextNormal>
            <Box
              sx={{
                mb: '21px',
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  position: 'relative',
                  ml: '-4px',
                }}
              >
                <SimpleCheckbox
                  icon={IconCheckmark}
                  value={customPackageSelectedRisks.find(
                    (item) => item === defaultRisks.comprehensiveCasco,
                  )}
                  setValue={(newVal) => {
                    dispatch(
                      setSelectedRisks({
                        isActive: newVal,
                        risk: defaultRisks.comprehensiveCasco,
                      }),
                    )
                  }}
                  boxStyles={{
                    width: '24px',
                    height: '24px',
                  }}
                  checkboxStyles={{
                    p: '4px',
                  }}
                />
                <TextNormal>{defaultRisks.comprehensiveCasco}</TextNormal>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  position: 'relative',
                  ml: '-4px',
                }}
              >
                <SimpleCheckbox
                  icon={IconCheckmark}
                  value={customPackageSelectedRisks.find(
                    (item) => item === defaultRisks.driverAccident,
                  )}
                  setValue={(newVal) => {
                    dispatch(
                      setSelectedRisks({
                        isActive: newVal,
                        risk: defaultRisks.driverAccident,
                      }),
                    )
                  }}
                  boxStyles={{
                    width: '24px',
                    height: '24px',
                  }}
                  checkboxStyles={{
                    p: '4px',
                  }}
                />
                <TextNormal>{defaultRisks.driverAccident}</TextNormal>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  position: 'relative',
                  ml: '-4px',
                }}
              >
                <SimpleCheckbox
                  icon={IconCheckmark}
                  value={customPackageSelectedRisks.find(
                    (item) => item === defaultRisks.passengerAccident,
                  )}
                  setValue={(newVal) => {
                    dispatch(
                      setSelectedRisks({
                        isActive: newVal,
                        risk: defaultRisks.passengerAccident,
                      }),
                    )
                  }}
                  boxStyles={{
                    width: '24px',
                    height: '24px',
                  }}
                  checkboxStyles={{
                    p: '4px',
                  }}
                />
                <TextNormal>{defaultRisks.passengerAccident}</TextNormal>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  position: 'relative',
                  ml: '-4px',
                }}
              >
                <SimpleCheckbox
                  icon={IconCheckmark}
                  value={customPackageSelectedRisks.find(
                    (item) => item === defaultRisks.roadsideAssistance,
                  )}
                  setValue={(newVal) => {
                    dispatch(
                      setSelectedRisks({
                        isActive: newVal,
                        risk: defaultRisks.roadsideAssistance,
                      }),
                    )
                  }}
                  boxStyles={{
                    width: '24px',
                    height: '24px',
                  }}
                  checkboxStyles={{
                    p: '4px',
                  }}
                />
                <TextNormal>{defaultRisks.roadsideAssistance}</TextNormal>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  position: 'relative',
                  ml: '-4px',
                }}
              >
                <SimpleCheckbox
                  icon={IconCheckmark}
                  value={customPackageSelectedRisks.find(
                    (item) => item === defaultRisks.offroadCover,
                  )}
                  setValue={(newVal) => {
                    dispatch(
                      setSelectedRisks({
                        isActive: newVal,
                        risk: defaultRisks.offroadCover,
                      }),
                    )
                  }}
                  boxStyles={{
                    width: '24px',
                    height: '24px',
                  }}
                  checkboxStyles={{
                    p: '4px',
                  }}
                />
                <TextNormal>{defaultRisks.offroadCover}</TextNormal>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  position: 'relative',
                  ml: '-4px',
                }}
              >
                <SimpleCheckbox
                  icon={IconCheckmark}
                  value={customPackageSelectedRisks.find((item) => item === defaultRisks.omanCover)}
                  setValue={(newVal) => {
                    dispatch(
                      setSelectedRisks({
                        isActive: newVal,
                        risk: defaultRisks.omanCover,
                      }),
                    )
                  }}
                  boxStyles={{
                    width: '24px',
                    height: '24px',
                  }}
                  checkboxStyles={{
                    p: '4px',
                  }}
                />
                <TextNormal>{defaultRisks.omanCover}</TextNormal>
              </Box>
            </Box>
            {packages[2].currentPrice ? (
              <Box
                sx={{
                  position: 'absolute',
                  width: '100%',
                  bottom: 0,
                  left: 0,
                  px: '18px',
                  pb: '20px',
                }}
              >
                <TextNormal>
                  From{' '}
                  <span style={{ fontWeight: 700, fontSize: '24px' }}>
                    {(packages[2].currentPrice / 12).toFixed(2)}
                  </span>{' '}
                  {CURRENCY[REGION_LOCALE]}/month
                </TextNormal>
                <TextNormal
                  sx={{
                    opacity: 0.5,
                    ml: '35px',
                    mb: '20px',
                  }}
                >
                  {packages[2].currentPrice} AED per year
                </TextNormal>
                <ButtonLG
                  sx={{
                    minWidth: '180px',
                  }}
                  onClick={() => {
                    if (!packages.find((item) => item.name === 'custom')?.selected) {
                      dispatch(setSelectedPackage('custom'))
                    }
                  }}
                >
                  {packages.find((item) => item.name === 'custom')?.selected
                    ? 'Selected'
                    : 'Choose'}
                </ButtonLG>
              </Box>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </RBox>
  )
}

export default CarInsurancePackages
