import React, { useEffect, useState } from 'react'
import { privateRequest } from '../../utils/request'
import { API_ENDPOINTS } from '../../config/default'
import { toast } from 'react-toastify'
import { setWorkflowState } from '../../store/features/carSlice/carSlice'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { colorsConfig } from '../../config/themeConfig'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { useNavigate } from 'react-router-dom'

const StartAuctionModal = (props) => {
  const { startAuctionModal, setStartAuctionModal, carId, fromVirtualGarage, refreshData } = props

  const xsOnly = useMediaQuery('(max-width: 600px)')

  const combinedStatus = useSelector((state) => state.car.carData.combinedStatus)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const maxDuration = 7
  const maxDelay = 14

  const currentDate = new Date()
  const maxStartDate = new Date()
  maxStartDate.setDate(maxStartDate.getDate() + maxDelay)

  const [currentAuction, setCurrentAuction] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [startTime, setStartTime] = useState(null)
  const [startTimeError, setStartTimeError] = useState(false)
  const [endTimeError, setEndTimeError] = useState(false)
  const [endDate, setEndDate] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [startAuctionDateValid, setStartAuctionDateValid] = useState(false)
  const [startNow, setStartNow] = useState(true)
  const [maxEndDate, setMaxEndDate] = useState(null)

  const getCurrentAuction = () => {
    privateRequest.get(`/cars/${carId}${API_ENDPOINTS.lastAuction}`).then((response) => {
      setCurrentAuction(response.data)
    })
  }

  const handleStartAuction = () => {
    const currentEndDate = new Date(endDate['$d'])
    const currentEndTimeDate = new Date(endTime['$d'])
    currentEndDate.setHours(currentEndTimeDate.getHours())
    currentEndDate.setMinutes(currentEndTimeDate.getMinutes())
    const currentStartDate = new Date(startDate && startDate['$d'] ? startDate['$d'] : null)
    const currentStartTimeDate = new Date(startTime && startTime['$d'] ? startTime['$d'] : null)
    if (currentStartDate) {
      currentStartDate.setHours(currentStartTimeDate.getHours())
      currentStartDate.setMinutes(currentStartTimeDate.getMinutes())
    }
    const formData = {
      startTime: startNow ? null : currentStartDate,
      endTime: currentEndDate,
    }
    if (combinedStatus === 'auction_workflow.completed') {
      privateRequest
        .post(`/auctions/${currentAuction.id}/workflow/will-not-sell`, {})
        .then(() => {
          privateRequest
            .post(`/cars/${carId}${API_ENDPOINTS.startAuction}`, formData)
            .then(() => {
              toast.success('The car is set to auction')
              setStartAuctionModal(false)
              dispatch(setWorkflowState('auction_workflow.pending'))
              navigate('/virtual-garage')
            })
            .catch((error) => {
              toast.error('Something went wrong, please try again')
            })
        })
        .catch(() => {
          toast.error('Something went wrong, please try again')
        })
    } else if (combinedStatus === 'auction_workflow.pending') {
      privateRequest
        .put(`/auctions/${currentAuction.id}`, formData)
        .then(() => {
          toast.success('The car is set to auction')
          setStartAuctionModal(false)
          navigate('/virtual-garage')
        })
        .catch(() => {
          toast.error('Something went wrong, please try again')
        })
    } else if (combinedStatus === 'car_workflow.ready_for_sale') {
      privateRequest
        .post(`/cars/${carId}${API_ENDPOINTS.startAuction}`, formData)
        .then((response) => {
          toast.success('The car is set to auction')
          setStartAuctionModal(false)
          dispatch(setWorkflowState('auction_workflow.pending'))
          navigate('/virtual-garage')
        })
        .catch((error) => {
          toast.error('Something went wrong, please try again')
        })
    }
  }

  const handleStartAuctionFromVirtualGarage = () => {
    const currentEndDate = new Date(endDate['$d'])
    const currentEndTimeDate = new Date(endTime['$d'])
    currentEndDate.setHours(currentEndTimeDate.getHours())
    currentEndDate.setMinutes(currentEndTimeDate.getMinutes())
    const currentStartDate = new Date(startDate && startDate['$d'] ? startDate['$d'] : null)
    const currentStartTimeDate = new Date(startTime && startTime['$d'] ? startTime['$d'] : null)
    if (currentStartDate) {
      currentStartDate.setHours(currentStartTimeDate.getHours())
      currentStartDate.setMinutes(currentStartTimeDate.getMinutes())
    }
    const formData = {
      startTime: startNow ? null : currentStartDate,
      endTime: currentEndDate,
    }
    if (refreshData.currentStatus === 'auction_workflow.completed') {
      privateRequest
        .post(`/auctions/${currentAuction.id}/workflow/will-not-sell`, {})
        .then(() => {
          privateRequest
            .post(`/cars/${carId}${API_ENDPOINTS.startAuction}`, formData)
            .then(() => {
              toast.success('The car is set to auction')
              setStartAuctionModal(false)
              refreshData.setCars(
                refreshData.cars.map((carItem) => {
                  if (carItem.id === carId) {
                    carItem.cells[5].value = 'Auction pending'
                    carItem.cells[6].value = refreshData.getActionList(
                      'auction_workflow.pending',
                      false,
                    )
                  }
                  return carItem
                }),
              )
            })
            .catch((error) => {
              toast.error('Something went wrong, please try again')
            })
        })
        .catch(() => {
          toast.error('Something went wrong, please try again')
        })
      return
    }
    if (refreshData.currentStatus === 'auction_workflow.pending') {
      privateRequest
        .put(`/auctions/${carId}`, formData)
        .then(() => {
          toast.success('The car is set to auction')
          setStartAuctionModal(false)
        })
        .catch(() => {
          toast.error('Something went wrong, please try again')
        })
      return
    }
    if (refreshData.currentStatus === 'car_workflow.ready_for_sale') {
      privateRequest
        .post(`/cars/${carId}${API_ENDPOINTS.startAuction}`, formData)
        .then((response) => {
          toast.success('The car is set to auction')
          setStartAuctionModal(false)
          refreshData.setCars(
            refreshData.cars.map((carItem) => {
              if (carItem.id === carId) {
                carItem.cells[5].value = 'Auction pending'
                carItem.cells[6].value = refreshData.getActionList(
                  'auction_workflow.pending',
                  false,
                )
              }
              return carItem
            }),
          )
        })
        .catch((error) => {
          toast.error('Something went wrong, please try again')
        })
    }
  }

  const endTimeEarlierThenStartTime = () => {
    if (
      startTime &&
      startDate &&
      endTime &&
      endDate &&
      startTime['$d'] &&
      startDate['$d'] &&
      endTime['$d'] &&
      endDate['$d'] &&
      new Date(startDate['$d']).setHours(0, 0, 0, 0) ===
        new Date(endDate['$d']).setHours(0, 0, 0, 0) &&
      !startNow
    ) {
      const tempStartDate = new Date(startDate['$d']).setHours(0, 0, 0, 0)
      const tempEndDate = new Date(endDate['$d']).setHours(0, 0, 0, 0)
      if (tempStartDate === tempEndDate) {
        if (new Date(startTime['$d']).getTime() > new Date(endTime['$d'])) {
          setEndTimeError("The end time can't be in the past")
          return true
        }
      }
    }

    if (
      startNow &&
      endTime &&
      endDate &&
      endTime['$d'] &&
      new Date(endDate['$d']).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0) &&
      endDate['$d']
    ) {
      if (new Date().getTime() > new Date(endTime['$d'])) {
        setEndTimeError("The end time can't be in the past")
        return true
      }
    }
    setEndTimeError(false)
    return false
  }

  useEffect(() => {
    if (fromVirtualGarage && refreshData.currentStatus.includes('auction_workflow')) {
      getCurrentAuction()
      return
    }
    if (combinedStatus && combinedStatus.includes('auction_workflow')) {
      getCurrentAuction()
    }
  }, [combinedStatus, refreshData])

  useEffect(() => {
    let hasErrors = false
    if ((!startDate || startDate['$d'].toString() === 'Invalid Date') && !startNow) {
      hasErrors = true
      setStartTimeError(false)
    } else if (!startNow) {
      const today = new Date()
      const currentStartDate = new Date(startDate['$d'])
      if (currentStartDate.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) {
        if (startTime && startTime['$d'].toString() !== 'Invalid Date') {
          if (new Date(startTime['$d']).getTime() < new Date().getTime()) {
            setStartTimeError(true)
            hasErrors = true
          } else {
            setStartTimeError(false)
          }
        } else {
          setStartTimeError(false)
        }
      } else {
        setStartTimeError(false)
      }
    }

    if ((!startTime || startTime['$d'].toString() === 'Invalid Date') && !startNow) {
      hasErrors = true
    }

    if (!endDate || endDate['$d'].toString() === 'Invalid Date') {
      hasErrors = true
    }
    if (!endTime || endTime['$d'].toString() === 'Invalid Date') {
      hasErrors = true
    }
    if (endTimeEarlierThenStartTime()) {
      hasErrors = true
    }

    setStartAuctionDateValid(!hasErrors)

    if (startDate && startDate['$d']) {
      const sDate = new Date(startDate['$d'])
      setMaxEndDate(sDate.setDate(sDate.getDate() + maxDuration))
    }

    if (startNow) {
      const newDate = new Date()
      setMaxEndDate(newDate.setDate(newDate.getDate() + maxDuration))
    }
  }, [startDate, startTime, endDate, endTime, startNow])

  return (
    <Modal
      open={startAuctionModal}
      onClose={() => {
        setStartAuctionModal(false)
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 430,
          background: 'white',
          borderRadius: '10px',
          padding: '25px',
          maxHeight: '80vh',
          overflow: 'auto',
          '&.xs-only': {
            width: 'calc(100vw - 30px)',
            px: '10px',
            py: '20px',
          },
        }}
        className={['disable-scrollbar', xsOnly ? 'xs-only' : ''].join(' ')}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
          onClick={() => {
            setStartAuctionModal(false)
          }}
        >
          <Close
            style={{
              fill: colorsConfig.mainRed,
            }}
          />
        </IconButton>
        <Typography
          variant={'body1'}
          sx={{
            fontSize: '24px',
            fontWeight: 700,
            mb: '15px',
          }}
        >
          Set the time
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={startNow}
                    onChange={(e) => {
                      setStartNow(e.target.checked)
                    }}
                  />
                }
                label="Start Now"
              />
            </FormGroup>
            {startNow ? null : (
              <React.Fragment>
                <DesktopDatePicker
                  fullWidth
                  label="Start Date"
                  inputFormat="DD/MM/YYYY"
                  mask={'__/__/____'}
                  minDate={currentDate}
                  maxDate={maxStartDate}
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <TimePicker
                  label="Start Time"
                  value={startTime}
                  onChange={(newValue) => {
                    setStartTime(newValue)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} error={startTime !== null && startTimeError} />
                  )}
                />
              </React.Fragment>
            )}
            <DesktopDatePicker
              fullWidth
              label="End Date"
              inputFormat="DD/MM/YYYY"
              mask={'__/__/____'}
              minDate={startDate && startDate['$d'] ? new Date(startDate['$d']) : new Date()}
              maxDate={maxEndDate}
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue)
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <TimePicker
              label="End Time"
              value={endTime}
              onChange={(newValue) => {
                setEndTime(newValue)
              }}
              renderInput={(params) => (
                <TextField {...params} error={endTimeError} helperText={endTimeError} />
              )}
            />
            <Button
              variant="contained"
              color="success"
              disabled={!startAuctionDateValid}
              onClick={() => {
                if (fromVirtualGarage) {
                  handleStartAuctionFromVirtualGarage()
                  return
                }
                handleStartAuction()
              }}
            >
              Confirm
            </Button>
          </Stack>
        </LocalizationProvider>
      </Box>
    </Modal>
  )
}

export default StartAuctionModal
