export const statusTheme = {
  readyForAppraisal: {
    label: 'Ready for Appraisal',
    color: '#0fa6f7',
  },
  submittedToAppraisal: {
    label: 'Submitted to Appraisal',
    color: '#fcdb03',
  },
  readyForAuction: {
    label: 'Ready for Auction',
    color: '#1ae89c',
  },
  inAuction: {
    label: 'In Auction',
    color: '#1a9166',
  },
  auctionEnded: {
    label: "Auction Ended (waiting for seller's approval)",
    color: '#f56f42',
  },
  waitingForPayment: {
    label: 'Waiting for Payment',
    color: '#e9f542',
  },
  inDelivery: {
    label: 'In Delivery',
    color: '#42f5e3',
  },
  sold: {
    label: 'Sold at Autrada Auction',
    color: '#42f587',
  },
  archived: {
    label: 'Archived',
    color: '#cee0d5',
  },
}

export const filters = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: statusTheme.readyForAppraisal.label,
    label: 'Newly Registered',
  },
  {
    value: statusTheme.submittedToAppraisal.label,
    label: statusTheme.submittedToAppraisal.label,
  },
  {
    value: statusTheme.readyForAuction.label,
    label: statusTheme.readyForAuction.label,
  },
  {
    value: statusTheme.inAuction.label,
    label: statusTheme.inAuction.label,
  },
  {
    value: statusTheme.auctionEnded.label,
    label: statusTheme.auctionEnded.label,
  },
  {
    value: statusTheme.waitingForPayment.label,
    label: statusTheme.waitingForPayment.label,
  },
  {
    value: statusTheme.inDelivery.label,
    label: statusTheme.inDelivery.label,
  },
  {
    value: statusTheme.sold.label,
    label: statusTheme.sold.label,
  },
  {
    value: statusTheme.archived.label,
    label: statusTheme.archived.label,
  },
]

export const tableHeadCells = [
  {
    title: 'Number',
    sortable: true,
    sortableAsc: '&order[referenceNumber]=asc',
    sortableDesc: '&order[referenceNumber]=desc',
    translation: 'table_col_number',
  },
  {
    title: 'Image',
    sortable: false,
    translation: 'table_col_image',
  },
  {
    title: 'Brand/Model',
    sortable: false,
    translation: 'table_col_brand_model',
  },
  {
    title: 'Location',
    sortable: false,
    translation: 'table_col_location',
  },
  {
    title: 'Days in stock',
    sortable: true,
    sortableAsc: '&order[createdAt]=asc',
    sortableDesc: '&order[createdAt]=desc',
    translation: 'table_col_days_in_stock',
  },
  {
    title: 'Status',
    sortable: false,
    translation: 'table_col_status',
  },
  {
    title: 'Action',
    sortable: false,
    translation: 'table_col_action',
  },
]

export const auctionTableCells = [
  {
    title: 'Reference Number',
    sortable: true,
  },
  {
    title: 'Image',
    sortable: false,
  },
  {
    title: 'Brand, Model',
    sortable: true,
  },
  {
    title: 'Auction Start Time',
    sortable: true,
  },
  {
    title: 'Auction End Time',
    sortable: true,
  },
  {
    title: 'Highest Bid',
    sortable: true,
  },
  {
    title: 'Status',
    sortable: false,
  },
]

export const dealsTableCells = [
  {
    title: 'Reference Number',
    sortable: true,
  },
  {
    title: 'Image',
    sortable: false,
  },
  {
    title: 'Brand, Model',
    sortable: true,
  },
  {
    title: 'Status',
    sortable: false,
  },
]
