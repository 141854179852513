import React, { useEffect, useState } from 'react'
import {
  Container,
  Box,
  Grid,
  Typography,
  Modal,
  IconButton,
  CircularProgress,
  useMediaQuery,
} from '@mui/material'
import AppraisalIntro from './Intro'
import CarPhotos from './AppraisalPhotos/CarPhotos'
import AppraisalDamages from './AppraisalDamages'
import WarningLights from './WarningLights/WarningLights'
import {
  API_ENDPOINTS,
  FILE_TYPES,
  HINT_TYPES,
  MERCURE_BASE_URL,
  CDN_BASE_URL,
} from '../../config/default'
import AppraisalBlock from './AppraisalBlock'
import { useSelector, useDispatch } from 'react-redux'
import {
  setReferenceControlPoints,
  setReferences,
} from '../../store/features/appraisalReferencesSlices'
import {
  setCarData,
  setPhotos,
  setDamages,
  setControlPoints,
  setPilotLamps,
  setDeletedDamages,
  setAppraisal,
  setAppraisalTab,
  setCarAppraisalChecksResource,
  setWorkflowState,
  setDocuments,
  setInteriorConditions,
  setMechanicalConditions,
} from '../../store/features/carSlice/carSlice'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { privateMultipartRequest, privateRequest } from '../../utils/request'
import {
  getCarParts,
  getControlPoints,
  getForeshortenings,
  getPilotLamps,
} from './getAppraisalReferences'
import { setHints, fixHint } from '../../store/features/qualityCheckSlice'
import { setCurrentPageTitle } from '../../store/features/authSlice'
import Preloader from '../../components/Preloader'
import BreadcrumbsComponent from '../../components/BreadcrumbsComponent'
import IconVirtualGarage from '../../assests/img/icons/icon-virtual-garage-home.svg'
import { colorsConfig } from '../../config/themeConfig'
import ButtonLG from '../../ui/Buttons/ButtonLG'
import ButtonMedium from '../../ui/Buttons/ButtonMedium'
import ButtonHollow from '../../ui/Buttons/ButtonHollow'
import QualityCheckHints from '../CarPage/QualityCheck/QualityCheckHints'
import TextNormal from '../../ui/Text/TextNormal'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import { Close } from '@mui/icons-material'
import ButtonDanger from '../../ui/Buttons/ButtonDanger'
import ButtonSuccess from '../../ui/Buttons/ButtonSuccess'
import CarPageTabTitle from '../CarPage/CarPageTabTitle'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { downloadFileFromPrivateBucket } from '../../utils/downloadFile'
import NotEnoughFleetifyCoins from '../../components/Modals/NotEnoughFleetifyCoins'
import { setBalance } from '../../store/features/authSlice'
import IconCoin from '../../assests/img/icons/icon-fleetify-coin.svg'
import ButtonAttention from '../../ui/Buttons/ButtonAttention'
import IconDoubleArrows from '../../assests/img/icons/icon-double-arrows.svg'
import TextFieldSmall from '../../ui/TextFieldSmall'
import AppraisalTabs from './AppraisalTabs'
import CarDefaultPicture from '../../assests/img/car-not-found.png'
import IconApple from '../../assests/img/icons/icon-apple-green.svg'
import IconCarStatus from '../../assests/img/icons/icon-car-status.svg'
import CarLoggingNodes from '../CarPage/CarLoggingNodes'
import TextH1 from '../../ui/Text/TextH1'
import {
  parseControlPointsDocuments,
  parseControlPointsInteriorConditions,
  parseMechanicalConditions,
} from './appraisal.helpers'
import AppraisalDocuments from './AppraisalDocuments/AppraisalDocuments'
import AppraisalInteriorConditions from './AppraisalInteriorConditions/AppraisalInteriorConditions'
import AppraisalMechanicalConditions from './AppraisalMechanicalConditions/AppraisalMechanicalConditions'
import { COMPANY_NAME, CURRENCY, REGION_LOCALE } from '../../utils/constants/global.constants'
import { useTranslation } from 'react-i18next'
import { carPartsBackLeft, carPartsFrontRight, carPartsInterior } from './AppraisalDamages/CarParts'

const Appraisal = () => {
  const { t } = useTranslation()
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const carData = useSelector((state) => state.car.carData)
  const carDataAppraisal = useSelector((state) => state.car.appraisal)
  const appraisalReferences = useSelector((state) => state.references)
  const hints = useSelector((state) => state.qualityCheck.hints)
  const role = useSelector((state) => state.auth.role)
  const mercureToken = useSelector((state) => state.auth.mercureToken)
  const currentTab = useSelector((state) => state.car.currentAppraisalTab)
  const qualityCheckHints = useSelector((state) => state.qualityCheck.hints)
  const carAppraisalChecksResource = useSelector((state) => state.car.carAppraisalChecksResource)
  const company = useSelector((state) => state.auth.company)
  const coinPricings = useSelector((state) => state.auth.coinPricings)

  const params = useParams()
  const navigate = useNavigate()
  const [blocks, setBlocks] = useState([])
  const [downloadingPDF, setDownloadingPDF] = useState(false)
  const [notEnoughCoinsModal, setNotEnoughCoinsModal] = useState(false)
  const [generatingInvoice, setGeneratingInvoice] = useState(false)

  const dispatch = useDispatch()

  const steps = [
    {
      id: 1,
      title:
        carData.combinedStatus && carData.combinedStatus === 'car_workflow.moderation_failed'
          ? 'Vehicle Details'
          : 'Intro',
      translation:
        carData.combinedStatus && carData.combinedStatus === 'car_workflow.moderation_failed'
          ? 'tab_vehicle_details'
          : 'Intro',
      component: <AppraisalIntro key={1} />,
    },
    {
      id: 2,
      title: 'Documents',
      translation: 'tab_documents',
      component: <AppraisalDocuments key="appraisal_documents" carId={carData?.id} />,
    },
    {
      id: 3,
      title: 'Photos',
      translation: 'tab_photos',
      component: (
        <CarPhotos
          key={3}
          // submitPhotos={submitPhotos}
        />
      ),
    },
    {
      id: 4,
      title: 'Damages',
      translation: 'tab_damages',
      component: <AppraisalDamages key={4} />,
    },
    {
      id: 5,
      title: 'Warning Lights',
      translation: 'tab_warning_lights',
      component: <WarningLights key={5} />,
    },
    {
      id: 6,
      title: 'Interior Conditions',
      translation: 'tab_interior_conditions',
      component: <AppraisalInteriorConditions key={6} />,
    },
    {
      id: 7,
      title: 'Mechanical Conditions',
      translation: 'tab_mechanical_conditions',
      component: <AppraisalMechanicalConditions key={7} />,
    },
  ]

  const [step, setStep] = useState(steps[currentTab])
  const [tab, setTab] = useState(0)
  const [alreadyReadIntro, setAlreadyReadIntro] = useState(false)

  const [files, setFiles] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingText, setLoadingText] = useState(t('preloader_loading_appraisal'))
  const [appraisalLoaded, setAppraisalLoaded] = useState(false)
  const [hintsFixedModal, setHintsFixedModal] = useState(false)
  const [finishAppraisalModal, setFinishAppraisalModal] = useState(false)
  const [qualityCheckCheckout, setQualityCheckCheckout] = useState(false)
  const [qualityCheckCheckoutFail, setQualityCheckCheckoutFail] = useState(false)

  const [coinsAmount, setCoinsAmount] = useState(50)
  const [localCurrencyAmount, setLocalCurrencyAmount] = useState(200)

  const handleSetLocalCurrencyAmount = () => {
    if (coinsAmount * 4 !== localCurrencyAmount) {
      setLocalCurrencyAmount(coinsAmount * 4)
    }
  }

  const handleSetCoinsAmount = () => {
    if (coinsAmount * 4 !== localCurrencyAmount) {
      setCoinsAmount(localCurrencyAmount / 4)
    }
  }

  useEffect(() => {
    handleSetLocalCurrencyAmount()
  }, [coinsAmount])

  useEffect(() => {
    handleSetCoinsAmount()
  }, [localCurrencyAmount])

  const handleSaveOrder = () => {
    if (carData.foreshortenings.length > 0) {
      const formData = {
        foreshortenings: carData.foreshortenings.map((item) => {
          return {
            direct: item.direct,
            carForeshortening: item['@id'],
          }
        }),
      }
      privateRequest.put(`/cars/${params.id}${API_ENDPOINTS.carPhotosDirectBatch}`, formData)
    }
  }

  function compare(a, b) {
    if (a.direct < b.direct) {
      return -1
    }
    if (a.direct > b.direct) {
      return 1
    }
    return 0
  }

  const handleStartAppraisal = () => {
    privateRequest.post(`/cars/${params.id}${API_ENDPOINTS.startOnSiteAppraisal}`, {}).then(() => {
      dispatch(setWorkflowState('car_workflow.appraisal_on_site'))
    })
  }

  const handleSaveChanges = (value) => {
    if (steps[value - 1].title === 'Photos') {
      handleSaveOrder()
    }
  }

  const handleSelectStep = (stepId, prev) => {
    handleSaveChanges(prev)
    if (step.id !== 0) {
      submitControlPoints()
      submitPilotLamps()
    }
    if (stepId === steps.length + 1) {
      saveData()
    } else {
      setStep(
        steps.filter((stepItem) => {
          return stepItem.id === stepId
        })[0],
      )
      setTab(stepId - 1)
      setAlreadyReadIntro(true)
      dispatch(setAppraisalTab(stepId - 1))
    }
    window.scrollTo(0, 0)
  }

  const handleChangeTab = (event, newValue) => {
    handleSaveChanges(step.id)
    if (tab !== 0) {
      submitPilotLamps()
      submitControlPoints()
    }
    dispatch(setAppraisalTab(newValue))
    setStep(steps[newValue])
    if (xsOnly) {
      window.scrollTo(0, 350)
    } else {
      window.scrollTo(0, 0)
    }
  }

  const getCar = () => {
    privateRequest.get(`/cars/${params.id}`).then((response) => {
      setCar(response.data)
      dispatch(
        setCurrentPageTitle(`Appraisal: ${response.data.make.name} ${response.data.model.name}`),
      )
      dispatch(setCarData(response.data))
      setCarTitle(`${response.data.make.name} ${response.data.model.name}`)
      if (response.data.combinedStatus === 'car_workflow.new') {
        dispatch(setAppraisalTab(0))
        setStep(steps[0])
        setTab(0)
      }
    })
  }

  const getHints = () => {
    privateRequest.get(`/cars/${params.id}${API_ENDPOINTS.qualityCheckHints}`).then((response) => {
      const activeHints = response.data['hydra:member'].filter((item) => item.isActive)
      dispatch(
        setHints(
          activeHints
            .map((item) => {
              switch (item['@type']) {
                case HINT_TYPES.vehicleDetails:
                  return {
                    ...item,
                    navigate: `/cars/${params.id}/appraisal`,
                    tab: 0,
                    fixed: item.isCompleted,
                    direct: 0,
                    linkText: 'Go to Vehicle Details',
                  }
                  break
                case HINT_TYPES.subBlocks:
                  let subBlockTab
                  let linkText
                  if (item.subblock === '/reference/appraisal/subblocks/1') {
                    subBlockTab = 1
                    linkText = 'Go to Documents'
                  } else if (item.subblock === '/reference/appraisal/subblocks/2') {
                    subBlockTab = 5
                    linkText = 'Go to Interior Conditions'
                  } else {
                    subBlockTab = 6
                    linkText = 'Go to Mechanical Conditions'
                  }
                  return {
                    ...item,
                    navigate: `/cars/${params.id}/appraisal`,
                    tab: subBlockTab,
                    fixed: item.isCompleted,
                    direct: subBlockTab,
                    linkText: linkText,
                  }
                  break
                case HINT_TYPES.warningLights:
                  return {
                    ...item,
                    navigate: `/cars/${params.id}/appraisal`,
                    tab: 4,
                    fixed: item.isCompleted,
                    direct: 4,
                    linkText: 'Go to Warning Lights',
                  }
                  break
                case HINT_TYPES.photos:
                  return {
                    ...item,
                    navigate: `/cars/${params.id}/appraisal`,
                    tab: 2,
                    fixed: item.isCompleted,
                    direct: 2,
                    linkText: 'Go to Photos',
                  }
                  break
                case HINT_TYPES.photo:
                  return {
                    ...item,
                    navigate: `/cars/${params.id}/appraisal`,
                    tab: 2,
                    fixed: item.isCompleted,
                    direct: 2,
                    linkText: 'Go to Photos',
                  }
                  break
                case HINT_TYPES.damages:
                  return {
                    ...item,
                    navigate: `/cars/${params.id}/appraisal`,
                    tab: 3,
                    fixed: item.isCompleted,
                    direct: 3,
                    linkText: 'Go to Damages',
                  }
                  break
                case HINT_TYPES.damage:
                  return {
                    ...item,
                    navigate: `/cars/${params.id}/appraisal`,
                    tab: 3,
                    fixed: item.isCompleted,
                    direct: 3,
                    linkText: 'Go to Damages',
                  }
                  break
                default:
                  return item
                  break
              }
            })
            .sort(compare),
        ),
      )
    })
  }

  const handleSubmitToQualityCheck = async () => {
    await submitControlPoints()
    if (carData.combinedStatus === 'car_workflow.moderation_failed') {
      setHintsFixedModal(false)
      privateRequest
        .post(`/cars/${params.id}${API_ENDPOINTS.startOnSiteAppraisal}`, {})
        .then(() => {
          privateRequest
            .post(`/cars/${params.id}${API_ENDPOINTS.completeOnSiteAppraisal}`, {})
            .then(() => {
              navigate(`/cars/${params.id}`)
              toast.success(t('notification_send_to_quality_check_success'))
            })
        })
    }
    if (
      carData.combinedStatus === 'car_workflow.appraisal_on_site' ||
      carData.combinedStatus === 'car_workflow.new'
    ) {
      privateRequest
        .post(`/cars/${params.id}${API_ENDPOINTS.completeOnSiteAppraisal}`, {})
        .then(() => {
          privateRequest.get(API_ENDPOINTS.userInfo).then((res) => {
            dispatch(setBalance(res.data.manager.company.coinBalance.balance))
          })
          navigate(`/cars/${params.id}`)
          toast.success('Your car has been sent to quality check successfully')
        })
        .catch((error) => {
          if (error.response.data['hydra:description'] === 'Not enough coins for withdraw') {
            //setFinishAppraisalModal(false)
            //setNotEnoughCoinsModal(true)
            setQualityCheckCheckout(false)
            setQualityCheckCheckoutFail(true)
          }
        })
    }
  }

  const handleSubmitCoinsDemand = (amount) => {
    setGeneratingInvoice(true)
    privateRequest
      .post(API_ENDPOINTS.coins.depositDemand, { amount: parseFloat(amount) })
      .then((response) => {
        privateRequest.get(response.data.invoice).then((responseInvoice) => {
          privateRequest
            .get(
              API_ENDPOINTS.privateFiles(responseInvoice.data.file['@id'].replace('/files/', '')),
            )
            .then(async (fileResponse) => {
              await downloadFileFromPrivateBucket(
                fileResponse.data.signedUrl,
                company.name
                  ? `Invoice for ${company.name} (${coinsAmount * 4} ${CURRENCY[REGION_LOCALE]})`
                  : `Invoice (${coinsAmount * 4} ${CURRENCY[REGION_LOCALE]})`,
              )
              setGeneratingInvoice(false)
              setNotEnoughCoinsModal(false)
              setFinishAppraisalModal(false)
            })
        })
      })
      .catch((error) => {
        toast.error(error.response.data['hydra:description'])
      })
  }

  const saveData = () => {
    setLoadingText('Saving Appraisal')
    setLoading(true)
    // const submitData = [
    //     submitPhotos(),
    //     submitDamages(),
    //     submitPilotLamps(),
    //     submitControlPoints()
    // ]

    setLoading(false)
    if (carData.combinedStatus === 'car_workflow.moderation_failed') {
      const unfixedHints = qualityCheckHints.filter(
        (item) =>
          !item.fixed &&
          !(
            item['@type'] === HINT_TYPES.subBlocks &&
            item.subblock &&
            item.subblock.includes('/reference/appraisal/blocks/3')
          ),
      )
      if (unfixedHints.length < 2) {
        setHintsFixedModal(true)
      }
      return
    }
    if (role === 'EXPERT') {
      navigate('/expert/dashboard')
      toast.success('Appraisal data has been saved')
      return
    }
    navigate(`/cars/${params.id}`)
    carData.combinedStatus === 'car_workflow.appraisal_compound'
      ? toast.success(
          'Your appraisal data has been saved. Submit it to quality check once you are ready',
        )
      : toast.success('Your appraisal data has been saved')
    // Promise.all(submitData).then(result => {
    //
    // })
  }

  const submitPilotLamps = async () => {
    for (let i = 0; i < carDataAppraisal.pilotLamps.length; i++) {
      if (
        carDataAppraisal.pilotLamps[i].newValue !== null &&
        carDataAppraisal.pilotLamps[i].value !== null
      ) {
        await updatePilotLamp(carDataAppraisal.pilotLamps[i])
      }
      if (
        carDataAppraisal.pilotLamps[i].newValue !== null &&
        carDataAppraisal.pilotLamps[i].value === null
      ) {
        await uploadPilotLamp(carDataAppraisal.pilotLamps[i])
      }
    }
  }

  const savePilotLamps = async () => {
    try {
      const formData = {
        controlPointAggregates: carDataAppraisal.pilotLamps.map((item) => ({
          //  co
        })),
      }
    } catch (e) {}
  }

  const submitControlPoints = async () => {
    const formData = {
      carControlPoints: [],
    }
    const curBlocks = carDataAppraisal.controlPoints
    let counter = 0
    for (let i = 0; i < curBlocks.length; i++) {
      const curSubBlocks = curBlocks[i].subblocks
      for (let j = 0; j < curSubBlocks.length; j++) {
        const curControlPoints = curSubBlocks[j].controlPoints
        for (let m = 0; m < curControlPoints.length; m++) {
          const curControlPoint = curControlPoints[m]
          if (curControlPoint.updated === true) {
            if (curControlPoint.type !== 'file') {
              formData.carControlPoints.push({
                controlPoint: curControlPoint['@id'],
                value: curControlPoint.value === null ? null : curControlPoint.value,
              })
              counter++
            } else {
              const formDataFile = new FormData()
              if (curControlPoint.deleted) {
                formData.carControlPoints.push({
                  controlPoint: curControlPoint['@id'],
                  value: null,
                })
              } else {
                formDataFile.append('file', curControlPoint.value)
                formDataFile.append('type', FILE_TYPES.controlPointDocument)
                const fileId = await privateMultipartRequest
                  .post(API_ENDPOINTS.file, formDataFile)
                  .then((response) => {
                    return response.data['@id']
                  })
                formData.carControlPoints.push({
                  controlPoint: curControlPoint['@id'],
                  value: fileId,
                })
              }
              counter++
            }
          }
        }
      }
    }
    if (formData.carControlPoints.length > 0) {
      await privateRequest.post(API_ENDPOINTS.submitCarControlPoints(params.id), formData)
    }
  }

  const uploadPilotLamp = async (pilotLamp) => {
    const formData = {
      pilotLamp: pilotLamp.lampViewIri,
      value: pilotLamp.newValue,
    }
    await privateRequest.post(API_ENDPOINTS.carPilotLamp(params.id), formData)
  }

  const updatePilotLamp = async (pilotLamp) => {
    const formData = {
      pilotLamp: pilotLamp.lampViewIri,
      value: pilotLamp.newValue,
    }
    await privateRequest.put(pilotLamp.lampIri, formData)
  }

  const getReferences = () => {
    const referencePromises = [getForeshortenings(), getCarParts(), getPilotLamps()]
    Promise.all(referencePromises).then((result) => {
      dispatch(setReferences(result))
    })
  }

  const getCarAppraisal = () => {
    privateRequest.get(API_ENDPOINTS.appraisalByCar(params.id)).then((response) => {
      dispatch(
        setAppraisal({
          foreshortenings: response.data.foreshortenings.sort(compare),
          damages: response.data.damages,
          pilotLamps: response.data.pilotLamps,
          controlPoints: response.data.controlPoints,
        }),
      )
      if (response.data.appraisalChecksResource) {
        dispatch(setCarAppraisalChecksResource(response.data.appraisalChecksResource))
      }
      setAppraisalLoaded(true)
    })
  }

  const handleSetCarAppraisalChecksResource = (stepId) => {
    const matchStep = steps.filter((item) => item.id === stepId)[0]
    if (matchStep && matchStep.title === 'Documents') {
      dispatch(
        setCarAppraisalChecksResource({
          documentsCheck: true,
        }),
      )
      privateRequest.post(`/cars/${params.id}${API_ENDPOINTS.carAppraisalChecksResource}`, {
        documentsCheck: true,
      })
    }
    if (matchStep && matchStep.title === 'Photos') {
      dispatch(
        setCarAppraisalChecksResource({
          photosCheck: true,
        }),
      )
      privateRequest.post(`/cars/${params.id}${API_ENDPOINTS.carAppraisalChecksResource}`, {
        photosCheck: true,
      })
    }
    if (matchStep && matchStep.title === 'Damages') {
      dispatch(
        setCarAppraisalChecksResource({
          damagesCheck: true,
        }),
      )
      privateRequest.post(`/cars/${params.id}${API_ENDPOINTS.carAppraisalChecksResource}`, {
        damagesCheck: true,
      })
    }
    if (matchStep && matchStep.title === 'Warning Lights') {
      dispatch(
        setCarAppraisalChecksResource({
          warningLightsCheck: true,
        }),
      )
      privateRequest.post(`/cars/${params.id}${API_ENDPOINTS.carAppraisalChecksResource}`, {
        warningLightsCheck: true,
      })
    }
    if (matchStep && matchStep.title === 'Interior Conditions') {
      dispatch(
        setCarAppraisalChecksResource({
          interiorConditionsCheck: true,
        }),
      )
      privateRequest.post(`/cars/${params.id}${API_ENDPOINTS.carAppraisalChecksResource}`, {
        interiorConditionsCheck: true,
      })
    }
    if (matchStep && matchStep.title === 'Mechanical Conditions') {
      dispatch(
        setCarAppraisalChecksResource({
          mechanicalConditionsCheck: true,
        }),
      )
      privateRequest.post(`/cars/${params.id}${API_ENDPOINTS.carAppraisalChecksResource}`, {
        mechanicalConditionsCheck: true,
      })
    }
  }

  const handleFinishAppraisal = () => {
    if (
      carAppraisalChecksResource.vehicleDetailsCheck &&
      carAppraisalChecksResource.documentsCheck &&
      carAppraisalChecksResource.photosCheck &&
      carAppraisalChecksResource.damagesCheck &&
      carAppraisalChecksResource.warningLightsCheck &&
      carAppraisalChecksResource.interiorConditionsCheck
    ) {
      setFinishAppraisalModal(true)
    } else {
      saveData()
    }
  }

  const handleSetCarReadyForSale = () => {
    privateRequest
      .post(`/cars/${params.id}${API_ENDPOINTS.completeAppraisalOnCompound}`, {})
      .then(() => {
        dispatch(setWorkflowState('car_workflow.ready_for_sale'))
        toast.success('Appraisal has been finished successfully')
        navigate('/expert/dashboard')
      })
  }

  let pdfFileId = null

  const handleDownloadPDF = async () => {
    privateRequest
      .post(
        API_ENDPOINTS.carPdfAppraisalResult(params.id),
        {},
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        const url = `${MERCURE_BASE_URL}?topic=/cars/${carData.id}/pdf-appraisal-result`
        const eventSource = new EventSourcePolyfill(url, {
          headers: {
            Authorization: 'Bearer ' + mercureToken,
          },
        })

        setDownloadingPDF(true)

        eventSource.onmessage = function (event) {
          const { fileId } = JSON.parse(event.data)
          pdfFileId = fileId
          initiateFileDownload(pdfFileId)
          setDownloadingPDF(false)
          eventSource.close()
        }

        eventSource.onerror = function (error) {
          setDownloadingPDF(false)
        }

        const { make, model, vin } = carData
        const fileName = `${make.name}_${model.name}_${vin}`.replace(' ', '-')

        async function initiateFileDownload(pdfFileId) {
          privateRequest
            .get(
              API_ENDPOINTS.privateFiles(pdfFileId),
              {},
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              },
            )
            .then((response) => {
              const { signedUrl } = response.data

              // const link = document.createElement('a')
              // link.href = signedUrl
              // link.target = '_blank'
              // link.rel = 'noopener noreferrer'
              // link.click()
              downloadFileFromPrivateBucket(signedUrl, fileName)
            })
        }
      })
  }

  useEffect(() => {
    if (currentTab !== step.id - 1) {
      setStep(steps[currentTab])
    }
  }, [currentTab])

  useEffect(() => {
    if (!appraisalReferences.loaded) {
      getReferences()
    }
  }, [appraisalReferences.loaded])

  useEffect(() => {
    if (appraisalReferences.loaded && carData.loaded && appraisalLoaded) {
      window.localStorage.getItem('deletedDamages')
      // setControlPointsBlocks()
      dispatch(
        setDamages({
          carDamages: carData.damages,
          carParts: appraisalReferences.carParts,
        }),
      )
      dispatch(setPilotLamps(appraisalReferences.pilotLamps))
      // dispatch(setControlPoints(appraisalReferences.controlPoints))
      dispatch(setPhotos(appraisalReferences.foreshortenings))

      setTimeout(() => {
        setLoading(false)
      }, 500)
    }
  }, [appraisalReferences.loaded, carData.loaded, appraisalLoaded])

  const checkDeletedDamages = () => {
    const deletedDamages = JSON.parse(window.localStorage.getItem('deletedDamages'))
    if (deletedDamages && deletedDamages.length > 0) {
      dispatch(setDeletedDamages(deletedDamages))
      deletedDamages.forEach(async (item) => {
        await privateRequest.delete(`/cars/${item.carId}/damages/${item.id}`).then(() => {
          const oldDeletedDamages = JSON.parse(window.localStorage.getItem('deletedDamages'))
          if (oldDeletedDamages && oldDeletedDamages.length > 0) {
            window.localStorage.setItem(
              'deletedDamages',
              JSON.stringify(
                oldDeletedDamages.filter(
                  (damageItem) => damageItem.id !== item.id && damageItem.carId !== item.carId,
                ),
              ),
            )
          }
        })
      })
    }
  }

  const renderMarkedTitle = (title, count) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TextNormal
          sx={{
            mr: '7px',
          }}
        >
          {t(title)}
        </TextNormal>
        <Box
          sx={{
            color: 'white',
            width: '25px',
            height: '25px',
            borderRadius: '50%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            background: colorsConfig.mainRed,
          }}
        >
          <TextNormal
            sx={{
              color: 'white',
              fontSize: '14px',
            }}
          >
            {count}
          </TextNormal>
        </Box>
      </Box>
    )
  }

  const renderTabTitle = (title) => {
    let matchHints = []
    let indexes = []
    switch (title) {
      case 'Vehicle Details':
        matchHints = qualityCheckHints.filter(
          (item) => item['@type'] === HINT_TYPES.vehicleDetails && !item.fixed,
        )
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle('tab_vehicle_details', matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        }
        return {
          component: t('tab_vehicle_details'),
          hasFixes: false,
        }
        break
      case 'Documents':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (
            item['@type'] === HINT_TYPES.subBlocks &&
            item.subblock === '/reference/appraisal/subblocks/1' &&
            !item.fixed
          ) {
            indexes.push(index)
            return true
          } else return false
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle('tab_documents', matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component:
              carData.combinedStatus === 'car_workflow.appraisal_on_site' ||
              carData.combinedStatus === 'car_workflow.appraisal_compound' ? (
                <CarPageTabTitle
                  title={t('tab_documents')}
                  isCompleted={carAppraisalChecksResource.documentsCheck}
                />
              ) : (
                title
              ),
            hasFixes: false,
          }
        }
        break
      case 'Photos':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (
            (item['@type'] === HINT_TYPES.photos || item['@type'] === HINT_TYPES.photo) &&
            !item.fixed
          ) {
            indexes.push(index)
            return true
          } else {
            return false
          }
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle('tab_photos', matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component:
              carData.combinedStatus === 'car_workflow.appraisal_on_site' ||
              carData.combinedStatus === 'car_workflow.appraisal_compound' ? (
                <CarPageTabTitle
                  title={t('tab_photos')}
                  isCompleted={carAppraisalChecksResource.photosCheck}
                />
              ) : (
                t('tab_photos')
              ),
            hasFixes: false,
          }
        }
        break
      case 'Damages':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (
            (item['@type'] === HINT_TYPES.damage && !item.fixed) ||
            (item['@type'] === HINT_TYPES.damages && !item.fixed)
          ) {
            indexes.push(index)
            return true
          } else return false
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle('tab_damages', matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component:
              carData.combinedStatus === 'car_workflow.appraisal_on_site' ||
              carData.combinedStatus === 'car_workflow.appraisal_compound' ? (
                <CarPageTabTitle
                  title={t('tab_damages')}
                  isCompleted={carAppraisalChecksResource.damagesCheck}
                />
              ) : (
                t('tab_damages')
              ),
            hasFixes: false,
          }
        }
        break
      case 'Warning Lights':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (item['@type'] === HINT_TYPES.warningLights && !item.fixed) {
            indexes.push(index)
            return true
          } else {
            return false
          }
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle('tab_warning_lights', matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component:
              carData.combinedStatus === 'car_workflow.appraisal_on_site' ||
              carData.combinedStatus === 'car_workflow.appraisal_compound' ? (
                <CarPageTabTitle
                  title={t('tab_warning_lights')}
                  isCompleted={carAppraisalChecksResource.warningLightsCheck}
                />
              ) : (
                t('tab_warning_lights')
              ),
            hasFixes: false,
          }
        }
        break
      case 'Interior Conditions':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (
            item['@type'] === HINT_TYPES.subBlocks &&
            item.subblock === '/reference/appraisal/subblocks/2' &&
            !item.fixed
          ) {
            indexes.push(index)
            return true
          } else return false
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle('tab_interior_conditions', matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component:
              carData.combinedStatus === 'car_workflow.appraisal_on_site' ||
              carData.combinedStatus === 'car_workflow.appraisal_compound' ? (
                <CarPageTabTitle
                  title={t('tab_interior_conditions')}
                  isCompleted={carAppraisalChecksResource.interiorConditionsCheck}
                />
              ) : (
                t('tab_interior_conditions')
              ),
            hasFixes: false,
          }
        }
        break
      case 'Mechanical Conditions':
        matchHints = qualityCheckHints.filter((item, index) => {
          if (
            item['@type'] === HINT_TYPES.subBlocks &&
            item.subblock !== '/reference/appraisal/subblocks/1' &&
            item.subblock !== '/reference/appraisal/subblocks/2' &&
            !item.fixed
          ) {
            indexes.push(index)
            return true
          } else {
            return false
          }
        })
        if (matchHints.length > 0) {
          return {
            component: renderMarkedTitle('tab_mechanical_conditions', matchHints.length),
            hasFixes: true,
            fixHints: () => {
              indexes.map((item) => {
                const currentHintId = qualityCheckHints[item].id
                privateRequest.put(
                  `/cars/${params.id}/quality-check-hints/${currentHintId}/complete`,
                  {},
                )
                dispatch(fixHint({ index: item }))
              })
            },
          }
        } else {
          return {
            component:
              carData.combinedStatus === 'car_workflow.appraisal_on_site' ||
              carData.combinedStatus === 'car_workflow.appraisal_compound' ? (
                <CarPageTabTitle
                  title={t('tab_mechanical_conditions')}
                  isCompleted={carAppraisalChecksResource.mechanicalConditionsCheck}
                />
              ) : (
                t('tab_mechanical_conditions')
              ),
            hasFixes: false,
          }
        }
        break
      default:
        return {
          component: title,
          hasFixes: false,
        }
        break
    }
  }

  const handleFixHint = (stepId) => {
    renderTabTitle(steps[stepId - 1].title).fixHints()
  }

  const checkQualityCheckFixed = () => {
    const findUnfixed = qualityCheckHints.filter((item) => !item.fixed)[0]
    if (findUnfixed) {
      return
    }
    setHintsFixedModal(true)
  }

  const getControlPoints = () => {
    privateRequest
      .get(API_ENDPOINTS.controlPoints, {
        params: {
          pagination: false,
        },
      })
      .then((response) => {
        dispatch(setReferenceControlPoints(response.data['hydra:member']))
      })
  }

  const handleSetCarControlPoints = () => {
    dispatch(
      setDocuments(
        parseControlPointsDocuments(appraisalReferences.controlPoints, carData.controlPoints),
      ),
    )
    dispatch(
      setInteriorConditions(
        parseControlPointsInteriorConditions(
          appraisalReferences.controlPoints,
          carData.controlPoints,
        ),
      ),
    )
    dispatch(
      setMechanicalConditions(
        parseMechanicalConditions(appraisalReferences.controlPoints, carData.controlPoints),
      ),
    )
  }

  useEffect(() => {
    if (appraisalReferences.controlPoints.length > 0 && carData.loaded) {
      handleSetCarControlPoints()
    }
  }, [appraisalReferences.controlPoints, carData.loaded])

  useEffect(() => {
    getCar()
    getControlPoints()
    getCarAppraisal()
    getHints()
    checkDeletedDamages()
    return () => {
      submitControlPoints()
      submitPilotLamps()
    }
    // return () => {
    //     dispatch(resetCar())
    // }
  }, [])

  useEffect(() => {
    if (
      carData.combinedStatus &&
      carData.combinedStatus === 'car_workflow.moderation_failed' &&
      currentTab === 1 &&
      step.id === 1
    ) {
      setStep(steps[1])
    }
  }, [currentTab])

  const [loggingTreeModal, setLoggingTreeModal] = useState(false)

  const [car, setCar] = useState(null)
  const [carTitle, setCarTitle] = useState('')
  const [pickupDemand, setPickupDemand] = useState(null)

  const [currentWorkflow, setCurrentWorkflow] = useState(null)
  const [currentStatusColor, setCurrentStatusColor] = useState('')
  const carWorkflows = useSelector((state) => state.workflows.carWorkflowStates)
  const auctionWorkflows = useSelector((state) => state.workflows.auctionWorkflowStates)
  const dealWorkflows = useSelector((state) => state.workflows.dealWorkflowStates)
  const workflowsLoaded = useSelector((state) => state.workflows.loaded)
  const combinedStatus = useSelector((state) => state.car.carData.combinedStatus)

  const getPickupDemand = () => {
    privateRequest.get(API_ENDPOINTS.pickupDemand(carData.id)).then((response) => {
      setPickupDemand(response.data)
    })
  }

  useEffect(() => {
    if (car && workflowsLoaded && combinedStatus) {
      let findWorkflow
      if (combinedStatus.includes('car_workflow')) {
        findWorkflow = carWorkflows.filter(
          (workflow) => workflow.sysName === combinedStatus.replace('car_workflow.', ''),
        )[0]
      }
      if (combinedStatus.includes('auction_workflow')) {
        findWorkflow = auctionWorkflows.filter(
          (workflow) => workflow.sysName === combinedStatus.replace('auction_workflow.', ''),
        )[0]
      }
      if (combinedStatus.includes('deal_workflow')) {
        findWorkflow = dealWorkflows.filter(
          (workflow) => workflow.sysName === combinedStatus.replace('deal_workflow.', ''),
        )[0]
        // getDeal()
      }

      if (combinedStatus === 'car_workflow.requested_appraisal_compound') {
        getPickupDemand()
      }

      if (findWorkflow) {
        setCurrentWorkflow(findWorkflow.value)
        setCurrentStatusColor(`#${findWorkflow.color}`)
      }
    }
  }, [car, workflowsLoaded, combinedStatus])

  // useEffect(() => {
  //   if (appraisalReferences.carParts.length > 0) {
  //     const newCarPartsFrontRight = carPartsFrontRight.map((item) => {
  //       const matchCarPart = appraisalReferences.carParts.find((e) => e?.name === item?.title)
  //       if (matchCarPart) {
  //         return { ...item, carPartId: matchCarPart?.id }
  //       }
  //       return item
  //     })
  //     console.log('front', JSON.stringify(newCarPartsFrontRight))
  //     const newCarPartsBackLeft = carPartsBackLeft.map((item) => {
  //       const matchCarPart = appraisalReferences.carParts.find((e) => e?.name === item?.title)
  //       if (matchCarPart) {
  //         return { ...item, carPartId: matchCarPart?.id }
  //       }
  //       return item
  //     })
  //     console.log('backLeft', JSON.stringify(newCarPartsBackLeft))
  //     const newCarPartsInterior = carPartsInterior.map((item) => {
  //       const matchCarPart = appraisalReferences.carParts.find((e) => e?.name === item?.title)
  //       if (matchCarPart) {
  //         return { ...item, carPartId: matchCarPart?.id }
  //       }
  //       return item
  //     })
  //     console.log('inte', JSON.stringify(newCarPartsInterior))
  //   }
  // }, [appraisalReferences.carParts])

  return (
    <Box
      className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
      sx={{
        '& .MuiContainer-root': {
          maxWidth: '1240px',
        },
        '&.xs-only': {
          pt: '25px',
          px: '16px',
        },
        '&.tablet-only': {
          px: '16px',
          pb: '30px',
        },
      }}
    >
      {loading ? (
        <Preloader type="gooey" text={loadingText} />
      ) : (
        <React.Fragment>
          {carData.loaded ? (
            <>
              {role === 'EXPERT' ? (
                <Box
                  sx={{
                    background: colorsConfig.bgLightBlue,
                    mb: xsOnly ? '20px' : '60px',
                    padding: xsOnly ? '20px' : '20px 20px 30px',
                  }}
                >
                  <BreadcrumbsComponent
                    currentPage={{
                      name: `${carData.make.name} ${carData.model.name}`,
                      link: `/cars/${params.id}`,
                    }}
                    parentPages={[
                      {
                        name: 'Appraisal applications',
                        link: '/expert-panel',
                      },
                    ]}
                  />
                  <Box
                    className={xsOnly ? 'xs-only' : ''}
                    sx={{
                      mt: '13px',
                      mb: '30px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      '&.xs-only': {
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                      },
                    }}
                  >
                    <Box
                      className={xsOnly ? 'xs-only' : ''}
                      sx={{
                        width: '118px',
                        height: '80px',
                        mr: '20px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '6px',
                        overflow: 'hidden',
                        '&.xs-only': {
                          mr: '10px',
                        },
                      }}
                    >
                      {carData.mainPhoto && carData.mainPhoto.photo ? (
                        <img
                          src={`${CDN_BASE_URL}${carData.mainPhoto.photo.path}`}
                          alt=""
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      ) : (
                        <img
                          src={CarDefaultPicture}
                          alt=""
                          style={{
                            width: '80%',
                          }}
                        />
                      )}
                    </Box>
                    <Box
                      className={xsOnly ? 'xs-only' : ''}
                      sx={{
                        mr: '15px',
                        '&.xs-only': {
                          width: 'calc(100% - 128px)',
                          mr: 0,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          className={xsOnly ? 'xs-only' : ''}
                          sx={{
                            fontWeight: 700,
                            color: colorsConfig.mainBlack,
                            fontSize: '40px',
                            lineHeight: 'normal',
                            '&.xs-only': {
                              fontSize: '24px',
                            },
                          }}
                        >
                          #{carData.referenceNumber} {carTitle}
                        </Typography>
                        {carData.carMainSpecs?.engineType === 'electric' ||
                        carData.carMainSpecs?.engineType === 'hybrid' ? (
                          <Box
                            sx={{
                              ml: '5px',
                              width: '28px',
                              position: 'relative',
                              mb: '-2.5px',
                            }}
                          >
                            <img
                              style={{
                                width: '100%',
                              }}
                              src={IconApple}
                              alt=""
                            />
                            <TextNormal
                              sx={{
                                fontSize: '8px',
                                fontWeight: 700,
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                top: 0,
                                left: 0,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              ECO
                            </TextNormal>
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                    <Box
                      className={xsOnly ? 'xs-only' : ''}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '48px',
                        borderRadius: '6px',
                        border: '1px solid',
                        borderColor: carData.isArchived
                          ? colorsConfig.buttonDisabled
                          : colorsConfig.mainGreen,
                        paddingLeft: '44px',
                        paddingRight: '44px',
                        cursor: 'pointer',
                        '&.xs-only': {
                          width: '100%',
                          mt: '20px',
                          px: 0,
                          py: 0,
                          height: '33px',
                        },
                      }}
                      onClick={() => {
                        setLoggingTreeModal(true)
                      }}
                    >
                      <img
                        src={IconCarStatus}
                        style={{
                          marginRight: '10px',
                          filter: carData.isArchived ? 'brightness(20%)' : '',
                        }}
                        alt=""
                      />
                      <Typography
                        sx={{
                          color: carData.isArchived
                            ? colorsConfig.iconGray
                            : colorsConfig.mainBlack,
                        }}
                      >
                        {carData.isArchived ? 'Archived' : currentWorkflow}
                      </Typography>
                    </Box>
                    <Modal
                      open={loggingTreeModal}
                      onClose={() => {
                        setLoggingTreeModal(false)
                      }}
                    >
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: 870,
                          background: 'white',
                          borderRadius: '10px',
                          padding: '55px',
                          maxHeight: '80vh',
                          overflow: 'auto',
                        }}
                        className={'disable-scrollbar'}
                      >
                        <CarLoggingNodes />
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                          }}
                          onClick={() => {
                            setLoggingTreeModal(false)
                          }}
                        >
                          <Close
                            style={{
                              fill: colorsConfig.mainRed,
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Modal>
                  </Box>
                  <ButtonMedium
                    fullWidth
                    onClick={async () => {
                      await submitControlPoints()
                      saveData()
                    }}
                    sx={{
                      width: xsOnly ? '100%' : '163px',
                    }}
                  >
                    Save and exit
                  </ButtonMedium>
                </Box>
              ) : (
                <Box>
                  <BreadcrumbsComponent
                    currentPage={{
                      name: `${carData.make.name} ${carData.model.name}`,
                      link: `/cars/${params.id}`,
                    }}
                    parentPages={[
                      {
                        name: t('page_title_virtual_garage'),
                        icon: <img src={IconVirtualGarage} alt="" />,
                        link: '/virtual-garage',
                      },
                    ]}
                  />
                  <Box
                    className={xsOnly ? 'xs-only' : ''}
                    sx={{
                      mt: '20px',
                      mb: '25px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      '&.xs-only': {
                        mt: '10px',
                        flexWrap: 'wrap',
                        mb: '20px',
                        justifyContent: 'flex-start',
                      },
                    }}
                  >
                    <TextBoldNormal
                      className={xsOnly ? 'xs-only' : ''}
                      sx={{
                        fontSize: '18px',
                        '&.xs-only': {
                          width: '100%',
                          fontSize: '24px',
                          mb: '10px',
                        },
                      }}
                    >
                      {carData.make.name} {carData.model.name}
                    </TextBoldNormal>
                    <Box
                      className={xsOnly ? 'xs-only' : ''}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        '&.xs-only': {
                          width: '100%',
                          flexWrap: 'wrap',
                        },
                      }}
                    >
                      <TextNormal
                        sx={{
                          marginRight: '10px',
                          whiteSpace: 'nowrap',
                          letterSpacing: 'normal',
                        }}
                      >
                        Reference #{carData.referenceNumber ? carData.referenceNumber : carData.id}
                      </TextNormal>
                      <TextNormal
                        sx={{
                          whiteSpace: 'nowrap',
                          letterSpacing: 'normal',
                        }}
                      >
                        VIN: {carData.vin}
                      </TextNormal>
                    </Box>
                  </Box>
                  {role === 'COMPANY' && hints.length > 0 ? (
                    <QualityCheckHints hints={hints} />
                  ) : null}
                </Box>
              )}
            </>
          ) : null}
          <AppraisalTabs
            currentTab={currentTab}
            handleChangeTab={handleChangeTab}
            steps={steps}
            renderTabTitle={renderTabTitle}
          />
          <Container
            className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
            maxWidth="lg"
            sx={{
              ml: 0,
              '&.xs-only': {
                px: 0,
              },
              '&.tablet-only': {
                px: 0,
              },
            }}
          >
            <Box
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                '&.xs-only': {
                  pb: '40px',
                },
              }}
            >
              <Grid container>
                <Grid xs={12} item>
                  {steps.map((stepItem) => {
                    const stepId =
                      carData.combinedStatus === 'car_workflow.appraisal_compound'
                        ? step.id + 1
                        : step.id
                    if (stepItem.id === stepId) {
                      return stepItem.component
                    }
                  })}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      mt: 3,
                      width: '100%',
                    }}
                  >
                    {step.id !== 1 ? (
                      <ButtonHollow
                        className={xsOnly ? 'xs-only' : ''}
                        sx={{
                          marginRight: '10px',
                          '&.xs-only': {
                            mr: '8px',
                            width: 'calc(50% - 4px)',
                          },
                        }}
                        onClick={() => {
                          handleSelectStep(step.id - 1, step.id)
                        }}
                      >
                        {t('button_back')}
                      </ButtonHollow>
                    ) : null}
                    {carData.combinedStatus &&
                    carData.combinedStatus === 'car_workflow.moderation_failed' &&
                    currentTab === 0 ? null : (
                      <ButtonLG
                        className={xsOnly ? 'xs-only' : ''}
                        onClick={() => {
                          if (renderTabTitle(steps[step.id - 1].title).hasFixes) {
                            handleFixHint(step.id)
                          }
                          if (
                            carData.combinedStatus === 'car_workflow.new' ||
                            carData.combinedStatus === 'car_workflow.appraisal_on_site'
                          ) {
                            handleSetCarAppraisalChecksResource(step.id)
                            if (step.id === 7) {
                              handleFinishAppraisal()
                              return
                            }
                          }
                          if (carData.combinedStatus === 'car_workflow.appraisal_compound') {
                            handleSetCarAppraisalChecksResource(step.id + 1)
                            if (step.id === 6) {
                              handleFinishAppraisal()
                              return
                            }
                          }
                          handleSelectStep(step.id + 1, step.id)
                          if (step.id === 1 && carData.combinedStatus === 'car_workflow.new') {
                            handleStartAppraisal()
                          }
                        }}
                        sx={{
                          '&.xs-only': {
                            width: 'calc(50% - 4px)',
                            whiteSpace: 'nowrap',
                          },
                        }}
                      >
                        {carData.combinedStatus === 'car_workflow.appraisal_compound' ? (
                          renderTabTitle(steps[step.id - 1].title).hasFixes ? (
                            'Apply & proceed'
                          ) : (
                            <>
                              {step.id !== 6 ? 'Confirm and continue' : ''}
                              {step.id === 6 ? 'Finish' : ''}
                            </>
                          )
                        ) : renderTabTitle(steps[step.id - 1].title).hasFixes ? (
                          'Apply & proceed'
                        ) : (
                          <>
                            {step.id === 1 ? t('button_lets_get_started') : ''}
                            {step.id > 1 && step.id !== 7 ? t('button_next') : ''}
                            {step.id === 7 ? t('button_finish_appraisal') : ''}
                          </>
                        )}
                      </ButtonLG>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
          <Modal
            open={hintsFixedModal}
            onClose={() => {
              setHintsFixedModal(false)
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 470,
                background: 'white',
                borderRadius: '10px',
                padding: '30px',
                maxHeight: '80vh',
                overflow: 'auto',
                '&.xs-only': {
                  width: 'calc(100vw - 30px)',
                  px: '10px',
                  py: '20px',
                },
              }}
              className={['disable-scrollbar', xsOnly ? 'xs-only' : '']}
            >
              <TextH1
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  textAlign: 'center',
                  mb: '10px',
                  '&.xs-only': {
                    fontSize: '24px',
                  },
                }}
              >
                All updates are done
              </TextH1>
              <TextNormal
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  mb: '40px',
                  textAlign: 'center',
                  '&.xs-only': {
                    fontSize: '14px',
                    mb: '30px',
                  },
                }}
              >
                All changes will be saved and sent to the operator
              </TextNormal>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ButtonLG
                  sx={{
                    width: '310px',
                  }}
                  onClick={() => {
                    handleSubmitToQualityCheck()
                  }}
                >
                  Finish appraisal
                </ButtonLG>
              </Box>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                }}
                onClick={() => {
                  setHintsFixedModal(false)
                }}
              >
                <Close
                  style={{
                    fill: colorsConfig.mainRed,
                  }}
                />
              </IconButton>
            </Box>
          </Modal>
          <Modal
            open={finishAppraisalModal}
            onClose={() => {
              setFinishAppraisalModal(false)
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: role === 'COMPANY' ? 550 : xsOnly ? 342 : 688,
                background: 'white',
                borderRadius: '10px',
                padding: xsOnly && role === 'EXPERT' ? '30px 20px 25px' : '30px',
                maxHeight: '80vh',
                overflow: 'auto',
                '&.xs-only': {
                  width: 'calc(100% - 30px)',
                  px: '10px',
                  py: '20px',
                },
              }}
              className={['disable-scrollbar', xsOnly ? 'xs-only' : '']}
            >
              {role === 'EXPERT' ? (
                <>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontFamily: '"Helvetica", sans-serif',
                      fontSize: xsOnly ? '24px' : '40px',
                      color: colorsConfig.mainBlack,
                      mb: '10px',
                    }}
                  >
                    Finish appraisal?
                  </Typography>
                  <TextNormal
                    sx={{
                      fontSize: xsOnly && '14px',
                      mb: '20px',
                    }}
                  >
                    Have you completed the appraisal of this vehicle and wish to proceed with
                    sending to the operator?
                  </TextNormal>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mb: '10px',
                      ml: 'auto',
                      mr: 'auto',
                    }}
                  >
                    <ButtonHollow
                      sx={{
                        mr: xsOnly ? '10px' : '5px',
                        width: xsOnly ? '50%' : '100%',
                      }}
                      fullWidth
                      onClick={() => {
                        setFinishAppraisalModal(false)
                      }}
                    >
                      Cancel
                    </ButtonHollow>
                    <ButtonLG
                      onClick={() => {
                        carData.combinedStatus === 'car_workflow.appraisal_compound'
                          ? handleSetCarReadyForSale()
                          : setQualityCheckCheckout(true)
                        //handleSubmitToQualityCheck()
                      }}
                      disabled={qualityCheckCheckoutFail || qualityCheckCheckout}
                      sx={{
                        width: xsOnly ? '50%' : '100%',
                        '&.Mui-disabled': {
                          background: colorsConfig.buttonDisabled,
                          color: 'white',
                        },
                      }}
                      fullWidth
                    >
                      Send
                    </ButtonLG>
                  </Box>
                </>
              ) : null}
              {role === 'COMPANY' ? (
                <>
                  <TextH1
                    className={xsOnly ? 'xs-only' : ''}
                    sx={{
                      mb: '10px',
                      '&.xs-only': {
                        fontSize: '24px',
                      },
                    }}
                  >
                    {t('title_appraisal_is_done')}
                  </TextH1>
                  <TextNormal
                    sx={{
                      mb: '20px',
                    }}
                  >
                    {carData.combinedStatus === 'car_workflow.appraisal_compound'
                      ? `Do you want to set car as ready for action?`
                      : t('p_send_to_quality_check_approval')}
                  </TextNormal>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mb: '10px',
                      ml: 'auto',
                      mr: 'auto',
                    }}
                  >
                    <ButtonDanger
                      fullWidth
                      sx={{
                        mr: '5px',
                      }}
                      onClick={async () => {
                        await submitControlPoints()
                        saveData()
                      }}
                    >
                      {t('common_no')}
                    </ButtonDanger>
                    <ButtonSuccess
                      fullWidth
                      onClick={() => {
                        carData.combinedStatus === 'car_workflow.appraisal_compound'
                          ? handleSetCarReadyForSale()
                          : setQualityCheckCheckout(true)
                        //handleSubmitToQualityCheck()
                      }}
                      disabled={qualityCheckCheckoutFail || qualityCheckCheckout}
                      sx={{
                        '&.Mui-disabled': {
                          background: colorsConfig.buttonDisabled,
                          color: 'white',
                        },
                      }}
                    >
                      {t('common_yes')}
                    </ButtonSuccess>
                  </Box>
                  <Box
                    sx={{
                      ml: 'auto',
                      mr: 'auto',
                    }}
                  >
                    <ButtonHollow
                      fullWidth
                      onClick={() => {
                        handleDownloadPDF()
                      }}
                    >
                      <span
                        style={{
                          marginRight: '5px',
                          fontFamily: '"Helvetica", sans-serif',
                          fontWeight: 700,
                        }}
                      >
                        {downloadingPDF
                          ? t('preloader_generating_pdf')
                          : t('button_download_appraisal_as_pdf')}
                      </span>
                      {downloadingPDF ? (
                        <CircularProgress
                          sx={{
                            '.css-176wh8e-MuiCircularProgress-circle': {
                              color: colorsConfig.mainBlue,
                            },
                            margin: '0 8px',
                            transform: 'scale(0.7)',
                          }}
                        />
                      ) : null}
                    </ButtonHollow>
                  </Box>
                  {qualityCheckCheckout ? (
                    <Box
                      sx={{
                        mt: '30px',
                        borderTop: '3px solid',
                        borderColor: colorsConfig.mainGreen,
                        position: 'relative',
                        ml: '-30px',
                        mr: '-30px',
                      }}
                    >
                      <Box
                        sx={{
                          padding: '30px',
                          pb: '0',
                        }}
                      >
                        <TextBoldNormal
                          sx={{
                            fontSize: '18px',
                            mb: '6px',
                          }}
                        >
                          {t('title_confirm_payment')}
                        </TextBoldNormal>
                        <TextNormal
                          sx={{
                            mb: '55px',
                          }}
                        >
                          {t('p_send_to_quality_check_info')}
                        </TextNormal>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            pb: '9px',
                            borderBottom: '1px solid',
                            borderColor: colorsConfig.lightGray,
                            mb: '54px',
                          }}
                        >
                          <TextBoldNormal sx={{ textTransform: 'uppercase' }}>
                            {t('common_total')}
                          </TextBoldNormal>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <img src={IconCoin} alt="" />
                            <TextBoldNormal sx={{ ml: '5px' }}>
                              {
                                coinPricings.filter(
                                  (item) => item.identifier === 'quality_check',
                                )[0]?.amount
                              }
                            </TextBoldNormal>
                          </Box>
                        </Box>
                        <Box>
                          <ButtonAttention
                            fullWidth
                            onClick={() => {
                              handleSubmitToQualityCheck()
                            }}
                          >
                            {t('button_apply_for_quality_check')}
                          </ButtonAttention>
                        </Box>
                      </Box>
                    </Box>
                  ) : null}
                  {qualityCheckCheckoutFail ? (
                    <Box
                      sx={{
                        mt: '30px',
                        borderTop: '3px solid',
                        borderColor: colorsConfig.mainGreen,
                        position: 'relative',
                        ml: '-30px',
                        mr: '-30px',
                      }}
                    >
                      <Box
                        sx={{
                          padding: '30px',
                          pb: 0,
                        }}
                      >
                        <TextBoldNormal
                          sx={{
                            fontSize: '18px',
                            mb: '10px',
                          }}
                        >
                          Not enough Fleatify Coins
                        </TextBoldNormal>
                        <TextNormal
                          sx={{
                            mb: '30px',
                          }}
                        >
                          Purchase Fleetify Coins in order to use that service
                          <br />
                          Or learn how to{' '}
                          <span style={{ color: colorsConfig.mainBlue }}>
                            Earn Fleetify Coins
                          </span>{' '}
                          for free
                        </TextNormal>
                        <TextBoldNormal
                          sx={{
                            mb: '16px',
                          }}
                        >
                          Enter Amount 1 FC = 4 {CURRENCY[REGION_LOCALE]}
                        </TextBoldNormal>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            mb: '31px',
                            position: 'relative',
                          }}
                        >
                          <Box
                            sx={{
                              position: 'absolute',
                              width: '30px',
                              height: '21px',
                              top: '50%',
                              mt: '-10.5px',
                              left: '50%',
                              ml: '-15px',
                            }}
                          >
                            <img src={IconDoubleArrows} alt="" />
                          </Box>
                          <Box
                            sx={{
                              mr: '70px',
                              position: 'relative',
                            }}
                          >
                            <TextFieldSmall
                              type="text"
                              value={coinsAmount}
                              onChange={(e) => {
                                setCoinsAmount(e.target.value)
                              }}
                              sx={{
                                width: '168px',
                              }}
                            />
                            <TextNormal
                              sx={{
                                color: colorsConfig.iconGray,
                                fontSize: '14px',
                                position: 'absolute',
                                top: '9px',
                                right: '11px',
                                '&::after': {
                                  content: '" "',
                                  display: 'block',
                                  position: 'absolute',
                                  height: '16px',
                                  width: '1px',
                                  background: colorsConfig.lightGray,
                                  left: '-10px',
                                  top: '50%',
                                  mt: '-8px',
                                },
                              }}
                            >
                              FC
                            </TextNormal>
                          </Box>
                          <Box
                            sx={{
                              position: 'relative',
                            }}
                          >
                            <TextFieldSmall
                              value={localCurrencyAmount}
                              onChange={(e) => {
                                setLocalCurrencyAmount(e.target.value)
                              }}
                              type="text"
                              sx={{
                                width: '168px',
                              }}
                            />
                            <TextNormal
                              sx={{
                                color: colorsConfig.iconGray,
                                fontSize: '14px',
                                position: 'absolute',
                                top: '9px',
                                right: '11px',
                                '&::after': {
                                  content: '" "',
                                  display: 'block',
                                  position: 'absolute',
                                  height: '16px',
                                  width: '1px',
                                  background: colorsConfig.lightGray,
                                  left: '-10px',
                                  top: '50%',
                                  mt: '-8px',
                                },
                              }}
                            >
                              AED
                            </TextNormal>
                          </Box>
                        </Box>
                        <TextNormal
                          sx={{
                            fontSize: '14px',
                            color: colorsConfig.lightGray,
                            mb: '18px',
                          }}
                        >
                          Wait until Fleeetify coins are transferred to your account (up to 24 hours
                          after payment)
                        </TextNormal>
                        <ButtonAttention
                          fullWidth
                          disabled={!coinsAmount || !localCurrencyAmount || generatingInvoice}
                          onClick={() => {
                            handleSubmitCoinsDemand(coinsAmount)
                          }}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {generatingInvoice ? 'Generating invoice...' : 'Get invoice'}
                          {generatingInvoice ? <Preloader type={'gooey'} /> : null}
                        </ButtonAttention>
                      </Box>
                    </Box>
                  ) : null}
                </>
              ) : null}
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                }}
                onClick={() => {
                  setFinishAppraisalModal(false)
                }}
              >
                <Close
                  style={{
                    fill: colorsConfig.mainRed,
                  }}
                />
              </IconButton>
            </Box>
          </Modal>
          <NotEnoughFleetifyCoins
            open={notEnoughCoinsModal}
            setOpen={setNotEnoughCoinsModal}
            handleSubmit={handleSubmitCoinsDemand}
            generatingInvoice={generatingInvoice}
          />
        </React.Fragment>
      )}
    </Box>
  )
}

export default Appraisal
