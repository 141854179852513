import React, { useEffect, useState } from 'react'
import { Box, Tab, Tabs, Button, useMediaQuery, Pagination, TablePagination } from '@mui/material'
import ManualCoinsManagement from './ManualCoinsManagement'
import TextH1 from '../../../ui/Text/TextH1'
import { colorsConfig } from '../../../config/themeConfig'
import CarPageTabTitle from '../../CarPage/CarPageTabTitle'
import TextNormal from '../../../ui/Text/TextNormal'
import { privateRequest } from '../../../utils/request'
import { API_ENDPOINTS } from '../../../config/default'
import CoinRequestItem from './CoinRequestItem'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import { COMPANY_NAME, REGION_LOCALE } from '../../../utils/constants/global.constants'

const OperatorCoinsRequests = () => {
  const dispatch = useDispatch()

  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  const sections = [
    {
      id: 1,
      name: 'Awaiting approval',
      component: (
        <Box
          sx={{
            position: 'absolute',
            top: '8px',
            right: '3px',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <circle cx="5" cy="5" r="5" fill="#AC0000" />
          </svg>
        </Box>
      ),
    },
    {
      id: 2,
      name: 'Approved',
    },
  ]

  const xsOnly = useMediaQuery('(max-width: 600px)')

  const [tab, setTab] = useState(0)
  const [pendingRequests, setPendingRequests] = useState([])
  const [approvedRequests, setApprovedRequests] = useState([])

  const getCoinDepositDemands = () => {
    privateRequest.get(`${API_ENDPOINTS.coins.depositDemand}?pagination=false`).then((response) => {
      const filteredRequests = response.data['hydra:member'].filter(
        (item) => !item.approved && !item.disapproveMessage,
      )
      setPendingRequests(
        filteredRequests.map((item) => {
          return {
            '@id': item['@id'],
            id: item.id,
            companyName: item.manager.company.name,
            managerName: `${item.manager.firstName} ${item.manager.surname}`,
            amount: item.amount,
            date: item.createdAt,
            invoice: item.invoice,
          }
        }),
      )
    })
  }

  const getApprovedCoinDemands = () => {
    privateRequest.get(`${API_ENDPOINTS.coins.depositDemand}?pagination=false`).then((response) => {
      const approvedRequests = response.data['hydra:member'].filter((item) => item.approved)
      setApprovedRequests(
        approvedRequests.map((item) => {
          return {
            '@id': item['@id'],
            id: item.id,
            companyName: item.manager.company.name,
            managerName: `${item.manager.firstName} ${item.manager.surname}`,
            amount: item.amount,
            date: item.createdAt,
            invoice: item.invoice,
          }
        }),
      )
    })
  }

  const hasRequestsOver24Hours = () => {
    const requests = pendingRequests
    const currentTime = new Date()
    const twentyFourHoursAgo = new Date(currentTime.getTime() - 24 * 60 * 60 * 1000)

    return requests.some((request) => {
      console.log(request.id, request.date)
      const requestTime = new Date(request.date)
      return requestTime < twentyFourHoursAgo
    })
  }

  const handleReject = (id, comment) => {
    privateRequest.post(`${id}/disapprove`, { message: comment }).then(() => {
      toast.success('Coin request has been rejected')
      setPendingRequests(pendingRequests.filter((item) => item['@id'] !== id))
    })
  }

  const handleApprove = (id) => {
    privateRequest.post(`${id}/approve`, {}).then(() => {
      toast.success('Coin request has been approved')
      setPendingRequests(pendingRequests.filter((item) => item['@id'] !== id))
    })
  }

  useEffect(() => {
    getCoinDepositDemands()
    getApprovedCoinDemands()
    dispatch(setCurrentPageTitle('Coins Requests'))
  }, [])

  const getPaginatedData = (data, page, rowsPerPage) => {
    const startIndex = (page - 1) * rowsPerPage
    const endIndex = startIndex + rowsPerPage
    return data.slice(startIndex, endIndex)
  }

  const getCurrentData = () => {
    switch (tab) {
      case 0:
        return pendingRequests
      case 1:
        return approvedRequests
      default:
        return pendingRequests
    }
  }

  const updatePagination = () => {
    setPage(1) // Reset page to 1 when changing tabs
  }

  useEffect(() => {
    // Update data length and paginated requests when switching tabs
    updatePagination()
    getCurrentData()
  }, [tab, rowsPerPage, pendingRequests, approvedRequests])

  return (
    <Box
      sx={
        xsOnly && {
          padding: '16px',
        }
      }
    >
      <ManualCoinsManagement />
      <TextH1
        sx={{
          mb: xsOnly ? '20px' : '40px',
          mt: '40px',
          fontSize: xsOnly ? '24px' : '40px',
        }}
      >
        {COMPANY_NAME[REGION_LOCALE]} Coins requests
      </TextH1>
      <Box sx={{ position: 'relative' }}>
        {xsOnly ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexWrap: 'wrap',
              position: 'relative',
              width: 'calc(100% + 4px)',
              margin: '20px 0',
            }}
          >
            {sections.map((section, index) => {
              return (
                <Button
                  key={index}
                  className={index === tab ? 'active' : ''}
                  sx={{
                    background: colorsConfig.bgLightBlue,
                    color: colorsConfig.mainBlack,
                    position: 'relative',
                    height: '48px',
                    textTransform: 'none',
                    fontFamily: '"Helvetica", sans-serif',
                    fontSize: '16px',
                    fontWeight: 500,
                    mb: '4px',
                    mr: '4px',
                    whiteSpace: 'nowrap',
                    flexGrow: 1,
                    '&::after': {
                      content: '" "',
                      display: 'block',
                      width: '100%',
                      height: '3px',
                      bottom: 0,
                      left: 0,
                      position: 'absolute',
                      background: colorsConfig.mainGreen,
                      transformOrigin: '50% 50%',
                      transform: 'scale(0)',
                      transition: '0.25s all ease-in-out',
                    },
                    '&.active': {
                      background: colorsConfig.bgLightBlue,
                      color: colorsConfig.mainBlack,
                      '&::after': {
                        transform: 'scale(1)',
                        transition: '0.25s all ease-in-out',
                      },
                    },
                    '&.Mui-disabled > div > p': {
                      color: 'rgba(0, 0, 0, 0.38) !important',
                    },
                  }}
                  onClick={() => {
                    setTab(index)
                  }}
                >
                  {section.name}
                  {hasRequestsOver24Hours(index) && section.component && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      style={{ margin: '0 0 6px 2px' }}
                    >
                      <circle cx="5" cy="5" r="5" fill="#AC0000" />
                    </svg>
                  )}
                </Button>
              )
            })}
          </Box>
        ) : (
          <Tabs
            value={tab}
            onChange={(e, newVal) => {
              setTab(newVal)
            }}
            variant="scrollable"
            sx={{
              width: '100%',
              '& .MuiTabs-indicator': {
                background: colorsConfig.mainGreen,
              },
              '& .MuiTab-textColorPrimary': {
                color: colorsConfig.mainBlack,
                textTransform: 'none',
                fontFamily: '"HelveticaNeue", sans-serif',
                fontSize: '16px',
                fontWeight: 500,
              },
              '& .MuiButtonBase-root': {
                background: colorsConfig.bgLightBlue,
              },
              '& .Mui-selected': {
                color: colorsConfig.mainBlack,
              },
            }}
          >
            {sections.map((section, index) => {
              return (
                <Tab
                  key={index}
                  label={
                    <Box>
                      {section.name}
                      {hasRequestsOver24Hours(index) && section.component && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          style={{ margin: '0 0 6px 2px' }}
                        >
                          <circle cx="5" cy="5" r="5" fill="#AC0000" />
                        </svg>
                      )}
                    </Box>
                  }
                  sx={{
                    fontSize: '13px',
                  }}
                />
              )
            })}
          </Tabs>
        )}
        <Box
          sx={{
            position: xsOnly ? 'relative' : 'absolute',
            top: xsOnly ? '' : 0,
            right: xsOnly ? '' : 0,
            height: !xsOnly && '48px',
            display: xsOnly ? 'none' : 'flex',
            flexDirection: xsOnly ? 'column' : 'row',
            justifyContent: 'flex-start',
            alignItems: xsOnly ? 'flex-start' : 'center',
            gap: xsOnly && '12px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              mr: '24px',
            }}
          >
            <Box
              sx={{
                width: '18px',
                minWidth: '18px',
                height: '18px',
                borderRadius: '50%',
                background: colorsConfig.mainGreen,
                mr: '6px',
              }}
            />
            <TextNormal
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              —New request
            </TextNormal>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              mr: '24px',
            }}
          >
            <Box
              sx={{
                width: '18px',
                minWidth: '18px',
                height: '18px',
                borderRadius: '50%',
                background: '#F5BD4F',
                mr: '6px',
              }}
            />
            <TextNormal
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              —Approaching the deadline
            </TextNormal>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '18px',
                minWidth: '18px',
                height: '18px',
                borderRadius: '50%',
                background: colorsConfig.mainRed,
                mr: '6px',
              }}
            />
            <TextNormal
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              —Action required
            </TextNormal>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          mt: xsOnly ? '15px' : '70px',
        }}
      >
        {tab === 0 ? (
          <Box>
            {getPaginatedData(pendingRequests, page, rowsPerPage).map((item, index) => {
              return (
                <CoinRequestItem
                  key={item.id}
                  request={item}
                  handleReject={handleReject}
                  handleApprove={handleApprove}
                />
              )
            })}
          </Box>
        ) : null}
        {tab === 1 ? (
          <Box>
            {getPaginatedData(approvedRequests, page, rowsPerPage).map((item, index) => {
              return (
                <CoinRequestItem
                  key={item.id}
                  request={item}
                  handleReject={handleReject}
                  handleApprove={handleApprove}
                  approved={true}
                />
              )
            })}
          </Box>
        ) : null}

        <Box
          sx={{
            '& .MuiToolbar-root': {
              paddingLeft: 0,
              display: 'flex',
              flexWrap: xsOnly ? 'wrap' : '',
              gap: xsOnly ? '10px 0' : '',
            },
            '& .MuiTablePagination-spacer': {
              display: 'none',
            },
            '& .MuiTablePagination-displayedRows': {
              display: xsOnly ? 'none' : 'flex',
              flexGrow: 1,
              justifyContent: 'flex-end',
              fontSize: '12px',
            },
            '& .MuiInputBase-root': {
              order: -1,
              margin: 'auto 10px',
              fontSize: '12px',
              '& .MuiSvgIcon-root': {
                fontSize: '16px',
                marginRight: '10px',
              },
              background: 'white',
              border: `1px solid ${colorsConfig.bgLightBlue}`,
              borderRadius: '4px',
              padding: '7px 15px 7px 13px',
            },
            '& .MuiPagination-root': {
              marginLeft: xsOnly ? 'auto' : '12px',
            },
            '& .MuiPaginationItem-previousNext': {
              padding: '8px 12px',
              '& .MuiSvgIcon-root': {
                display: 'none',
              },
              "&[aria-label='Go to previous page']": {
                '&::before': {
                  content: "'Previous'",
                },
              },
              "&[aria-label='Go to next page']": {
                '&::before': {
                  content: "'Next'",
                },
              },
              '&.Mui-disabled': {
                opacity: 1,
              },
            },
            '& .MuiPaginationItem-root': {
              background: colorsConfig.bgLightBlue,
              border: 'none',
              fontSize: '12px',

              '&.MuiPaginationItem-previousNext': {
                display: xsOnly && 'none',
                background: 'white',
                border: `1px solid ${colorsConfig.bgLightBlue}`,
              },
            },
            '& .MuiPaginationItem-page.Mui-selected': {
              background: colorsConfig.mainBlue,
              color: 'white',
              '&:hover': {
                background: 'rgb(21, 101, 192)',
              },
            },
            '& .MuiTablePagination-selectLabel': {
              fontSize: '12px',
            },
            '& .MuiTablePagination-select': {
              // paddingRight: '18px',
            },
            '& .MuiSelect-select': {
              padding: 0,
              paddingRight: '12px',
              '&:focus': {
                background: 'none',
              },
            },
          }}
        >
          <TablePagination
            component="div"
            count={getCurrentData().length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton={false}
            showLastButton={false}
            ActionsComponent={() => (
              <>
                <p
                  className="MuiTablePagination-selectLabel css-pdct74-MuiTablePagination-selectLabel"
                  id=":rh:"
                  style={{
                    display: 'flex',
                    order: -2,
                  }}
                >
                  Show
                </p>
                <Pagination
                  count={Math.ceil(getCurrentData().length / rowsPerPage)}
                  variant="outlined"
                  shape="rounded"
                  page={page}
                  onChange={(event, newPage) => handleChangePage(event, newPage)}
                />
              </>
            )}
            labelRowsPerPage="entries"
            labelDisplayedRows={({ count }) => {
              const from = (page - 1) * rowsPerPage + 1
              const to = Math.min(page * rowsPerPage, getCurrentData().length)

              if (!count) return `Show 0 entries`
              return `Show ${from}-${to} of ${count} entries`
            }}
            sx={{
              display: 'block',
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default OperatorCoinsRequests
