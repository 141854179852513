import { createAsyncThunk } from '@reduxjs/toolkit'
import customerAppraisalsApiService from '../../../api/customerAppraisalsApiService/customerAppraisalsApiService'
import { toast } from 'react-toastify'
import { apiConstants, getApiError } from '../../../api/api'
import { initialApplicationData as response } from '../../../pages/Insurance/insurance.constants'

export const createCustomerAppraisal = createAsyncThunk(
  'customerAppraisal/createCustomerAppraisal',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await customerAppraisalsApiService.createCustomerAppraisal(data)
      return response.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)

export const sendAppraisalLinkByEmail = createAsyncThunk(
  'customerAppraisal/sendAppraisalLinkByEmail',
  async ({ idUrl }, { rejectWithValue }) => {
    try {
      const response = await customerAppraisalsApiService.sendAppraisalLinkByEmail(idUrl)
      return response.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)

export const getCustomerAppraisal = createAsyncThunk(
  '/getCustomerAppraisal',
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await customerAppraisalsApiService.getCustomerAppraisalAsCustomer(token)
      const matchCustomerAppraisal = response.data?.[apiConstants.hydraMember]?.find(
        (item) => item?.appraisalToken?.token === token,
      )
      return matchCustomerAppraisal
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const createCustomerCar = createAsyncThunk(
  'customerAppraisal/createCustomerCar',
  async ({ coreData, mainData, token }, { rejectWithValue, getState }) => {
    try {
      const carRes = await customerAppraisalsApiService.createCustomerCar(coreData, token)
      const response = await customerAppraisalsApiService.updateCustomerCar(
        carRes?.data?.id,
        mainData,
        token,
      )
      await customerAppraisalsApiService.startAppraisal(carRes?.data?.id)
      return response.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)

export const updateCustomerCar = createAsyncThunk(
  'customerAppraisal/updateCustomerCar',
  async ({ data, token }, { rejectWithValue, getState }) => {
    try {
      const car = getState().customerAppraisal.customer.car.data
      const response = await customerAppraisalsApiService.updateCustomerCar(car?.id, data, token)
      return response.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)

export const submitCustomerAppraisal = createAsyncThunk(
  'customerAppraisal/submitCustomerAppraisal',
  async ({ token }, { rejectWithValue, getState }) => {
    try {
      const car = getState().customerAppraisal.customer.car.data
      const additionalInfo = getState().addCar.carMainData?.additionalInfo
      const carRes = await customerAppraisalsApiService.updateCustomerCar(
        car?.id,
        {
          additionalInfo,
        },
        token,
      )
      await customerAppraisalsApiService.completeAppraisal(car?.id)
      return carRes.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)

export const deleteCustomerAppraisal = createAsyncThunk(
  'customerAppraisal/deleteCustomerAppraisal',
  async ({ customerAppraisalId }, { rejectWithValue }) => {
    try {
      const response = await customerAppraisalsApiService.deleteAppraisal(customerAppraisalId)
      return response.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)
