import React from 'react'
import { colorsConfig } from '../../config/themeConfig'
import { InputAdornment, TextField } from '@mui/material'
import iconSearch from '../../assests/img/icons/icon-search.svg'
import { useTranslation } from 'react-i18next'

const SearchInput = ({ sx, ...rest }) => {
  const { t } = useTranslation()
  return (
    <TextField
      id="search"
      label={t('form_input_label_search')}
      size="small"
      sx={{
        '& .MuiInputBase-input': {
          width: {
            xs: '100%',
            md: '205px',
          },
          height: '38px',
          boxSizing: 'border-box',
          borderRadius: '6px',
          borderColor: colorsConfig.mainBlack,
          color: colorsConfig.mainBlack,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: '6px',
          borderColor: colorsConfig.mainBlack,
        },
        ...sx,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <img src={iconSearch} alt="" />
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  )
}

export default SearchInput
