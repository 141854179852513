import { importPolicies } from '../rviOperator.actions'

export default (builder) => {
  builder
    .addCase(importPolicies.pending, (state) => {
      state.importPolicies.isLoading = true
    })
    .addCase(importPolicies.fulfilled, (state, action) => {
      state.importPolicies.isLoading = false
      state.importPolicies.error = null
      state.importPolicies.importResult = action.payload
    })
    .addCase(importPolicies.rejected, (state, action) => {
      state.importPolicies.isLoading = false
      state.importPolicies.error = action.payload
    })
}
