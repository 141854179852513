// todo adjust pagination, debounce on search, responsive, cancelled deals
import React, { useEffect, useState } from 'react'
import { Box, InputAdornment, TextField, Table, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import CustomTabs from '../../../ui/CustomTabs'
import CustomTab from '../../../ui/CustomTab'
import TextNormal from '../../../ui/Text/TextNormal'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import { colorsConfig } from '../../../config/themeConfig'
import iconSearch from '../../../assests/img/icons/icon-search.svg'
import FilterByStatus from '../../../components/FilterByStatus'
import CarTableHead from '../../VirtualGarage/CarTableHead'
import { operatorDealsTableHeadCells } from '../../../config/tablesConfig'
import CarTableBody from '../../VirtualGarage/CarTableBody'
import { privateRequest } from '../../../utils/request'
import { API_ENDPOINTS } from '../../../config/default'
import { handleFormatDate } from '../../../utils/formatDate'
import { sumDelimiter } from '../../../utils/sumDelimiter'
import CustomTablePagination from '../../../ui/CustomTablePagination'
import { CURRENCY, REGION_LOCALE } from '../../../utils/constants/global.constants'

const OperatorDeals = () => {
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const dispatch = useDispatch()
  const dealWorkflows = useSelector((state) => state.workflows.dealWorkflowStates)

  const [tab, setTab] = useState(0)
  const [searchLine, setSearchLine] = useState('')
  const [sort, setSort] = useState('')
  const [filters, setFilters] = useState([])
  const [currentFilter, setCurrentFilter] = useState(null)
  const [activeDeals, setActiveDeals] = useState([])
  const [closedDeals, setClosedDeals] = useState([])
  const [activeDealsAmount, setActiveDealsAmount] = useState(0)
  const [closedDealsAmount, setClosedDealsAmount] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleFilter = (value) => {
    setCurrentFilter(value)
  }

  const hasRequestsOver24Hours = (data) => {
    const currentTime = new Date()
    const twentyFourHoursAgo = new Date(currentTime.getTime() - 24 * 60 * 60 * 1000)
    return data.some((request) => {
      const requestTime = new Date(request?.data?.updatedAt)
      return requestTime < twentyFourHoursAgo
    })
  }

  const handleBreakLine = (str, symbol) => {
    return (
      <>
        {str.slice(0, str.indexOf(symbol) + 1)}
        <br />
        {str.slice(str.indexOf(symbol) + 1)}
      </>
    )
  }

  const getMarkerColor = (date) => {
    let fill, stroke
    const createdAtTime = new Date(date).getTime()
    const currentTime = new Date().getTime()
    const timeDifferenceInHours = (currentTime - createdAtTime) / 3600000
    if (timeDifferenceInHours > 24) {
      fill = '#AC0000'
      stroke = '#B83629'
    } else if (timeDifferenceInHours > 20) {
      fill = '#F5BD4F'
      stroke = '#D69E3C'
    } else {
      fill = '#4DED6D'
      stroke = '#3CC457'
    }
    return xsOnly ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
      >
        <circle cx="8" cy="7.11719" r="7" fill={fill} stroke={stroke} stroke-width="0.25" />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <circle cx="10" cy="10" r="9" fill={fill} stroke={stroke} strokeWidth="0.25" />
      </svg>
    )
  }

  const renderMarkedDate = (date) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '6px',
        }}
      >
        {getMarkerColor(date)}
        <TextNormal>{handleFormatDate(date, '.')}</TextNormal>
      </Box>
    )
  }

  const handleSetDeals = (data, handler) => {
    handler(
      data.map((item) => {
        return {
          id: item?.id,
          link: `/operator/auctions/${item?.car?.id}`,
          data: item,
          cells: [
            {
              type: 'text',
              value: item?.car?.company?.name,
            },
            {
              type: 'text',
              value: item?.buyer?.name,
            },
            {
              type: 'text-with-caption',
              value: `${item?.car?.make?.name} ${item?.car?.model?.name}`,
              caption: `VIN ${item?.car?.vin}`,
            },
            {
              type: 'text',
              value: `${sumDelimiter(parseInt(item?.value))} ${CURRENCY[REGION_LOCALE]}`,
            },
            {
              type: 'text',
              value: handleBreakLine(
                dealWorkflows.find((workflow) => workflow.name === item.currentWorkflowState)
                  ?.value,
                ':',
              ),
              maxWidth: '205px',
            },
            {
              type: 'component',
              value: renderMarkedDate(item.updatedAt),
            },
          ],
        }
      }),
    )
  }

  const getDeals = () => {
    let link = API_ENDPOINTS.deals
    if (currentFilter) {
      link = `${link}&currentWorkflowState=${dealWorkflows.find((item) => item.value === currentFilter)?.name}`
    }
    if (searchLine !== '') {
      link = `${link}&dealExtendedSearch=${searchLine}`
    }
    if (sort) {
      link = `${link}${sort}`
    }
    privateRequest.get(link).then((response) => {
      handleSetDeals(response.data['hydra:member'], setActiveDeals)
      if (activeDealsAmount === 0) setActiveDealsAmount(response.data['hydra:totalItems'])
    })
  }

  const getClosedDeals = () => {
    privateRequest.get(`${API_ENDPOINTS.deals}&currentWorkflowState=succeed`).then((response) => {
      handleSetDeals(response.data['hydra:member'], setClosedDeals)
      if (closedDealsAmount === 0) setClosedDealsAmount(response.data['hydra:totalItems'])
    })
  }

  useEffect(() => {
    if (dealWorkflows.length > 0) {
      setFilters(dealWorkflows.map((item) => item.value))
      getDeals()
    }
  }, [dealWorkflows, currentFilter, searchLine, sort])

  useEffect(() => {
    if (dealWorkflows.length > 0) {
      getClosedDeals()
    }
  }, [dealWorkflows, searchLine, sort])

  useEffect(() => {
    dispatch(setCurrentPageTitle('Auctions'))
  }, [])

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: '30px',
        }}
      >
        <CustomTabs
          value={tab}
          onChange={(e, newVal) => {
            setPage(0)
            setTab(newVal)
          }}
        >
          <CustomTab
            label={
              <Box
                sx={{
                  position: 'relative',
                }}
              >
                <TextNormal>Waiting for clearing ({activeDealsAmount})</TextNormal>
                {hasRequestsOver24Hours(activeDeals) ? (
                  <Box
                    sx={{
                      background: '#AC0000',
                      borderRadius: '50%',
                      width: '10px',
                      height: '10px',
                      position: 'absolute',
                      top: '1px',
                      right: '-19px',
                    }}
                  />
                ) : null}
              </Box>
            }
          />
          <CustomTab label={<TextNormal>Deals cleared ({closedDealsAmount})</TextNormal>} />
        </CustomTabs>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
            }}
          >
            <Box
              sx={{
                width: '18px',
                minWidth: '18px',
                height: '18px',
                borderRadius: '50%',
                background: colorsConfig.mainGreen,
              }}
            />
            <TextNormal
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              —New request
            </TextNormal>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
            }}
          >
            <Box
              sx={{
                width: '18px',
                minWidth: '18px',
                height: '18px',
                borderRadius: '50%',
                background: '#F5BD4F',
              }}
            />
            <TextNormal
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              —Approaching the deadline
            </TextNormal>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
            }}
          >
            <Box
              sx={{
                width: '18px',
                minWidth: '18px',
                height: '18px',
                borderRadius: '50%',
                background: colorsConfig.mainRed,
              }}
            />
            <TextNormal
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              —Action required
            </TextNormal>
          </Box>
        </Box>
      </Box>
      <BoxShadowed>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: '30px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <TextBoldNormal
              sx={{
                fontSize: '24px',
              }}
            >
              {tab === 0 ? 'Waiting for clearing' : ''}
              {tab === 1 ? 'Deals cleared' : ''}
            </TextBoldNormal>
            <TextField
              label={'Search'}
              size="small"
              value={searchLine}
              onChange={(e) => {
                setSearchLine(e.target.value)
              }}
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
                '& .MuiInputBase-input': {
                  width: {
                    xs: '100%',
                    sm: '205px',
                  },
                  height: '38px',
                  boxSizing: 'border-box',
                  borderRadius: '6px',
                  borderColor: colorsConfig.mainBlack,
                  color: colorsConfig.mainBlack,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderRadius: '6px',
                  borderColor: colorsConfig.mainBlack,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={iconSearch} alt="" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {tab === 0 ? (
            <FilterByStatus
              currentFilter={currentFilter}
              filters={filters}
              handleFilter={handleFilter}
            />
          ) : null}
        </Box>
        <Box>
          <Table>
            <CarTableHead tableCells={operatorDealsTableHeadCells} sort={sort} setSort={setSort} />
            <CarTableBody content={tab === 0 ? activeDeals : closedDeals} />
          </Table>
        </Box>
      </BoxShadowed>
      {(tab === 0 && activeDeals.length > 10) || (tab === 1 && closedDeals.length > 10) ? (
        <CustomTablePagination
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          amount={tab === 0 ? activeDeals.length : closedDeals.length}
        />
      ) : null}
    </Box>
  )
}

export default OperatorDeals
