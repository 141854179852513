import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import TextH1 from '../../ui/Text/TextH1'
import TextNormal from '../../ui/Text/TextNormal'
import ButtonDanger from '../../ui/Buttons/ButtonDanger'
import ButtonSuccess from '../../ui/Buttons/ButtonSuccess'
import ModalWrap from './ModalWrap'

const FinanceCustomerApproveLoanDealModal = (props) => {
  const { open, setOpen, handleAction, submitButtonDisabled } = props

  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <ModalWrap open={open} setOpen={setOpen}>
      <TextH1
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          mb: '16px',
          fontSize: '30px',
          lineHeight: 'normal',
          textAlign: 'center',
          '&.xs-only': {
            mt: '20px',
          },
        }}
      >
        Approval confirmation
      </TextH1>
      <TextNormal
        sx={{
          mb: '30px',
          fontSize: '22px',
          lineHeight: 'normal',
          textAlign: 'center',
        }}
      >
        Are you sure you want to approve this offer?
      </TextNormal>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ButtonDanger
          sx={{
            width: 'calc(50% - 4px)',
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          Cancel
        </ButtonDanger>
        <ButtonSuccess
          disabled={submitButtonDisabled}
          sx={{
            width: 'calc(50% - 4px)',
          }}
          onClick={() => {
            handleAction()
          }}
        >
          Confirm
        </ButtonSuccess>
      </Box>
    </ModalWrap>
  )
}

export default FinanceCustomerApproveLoanDealModal
