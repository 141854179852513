import React from 'react'
import { Box, Stack } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import UITextTitleSmall from '../../../ui/UIText/UITextTitleSmall'
import UITextLabelMedium from '../../../ui/UIText/UITextLabelMedium'

const RVMatrixHead = () => {
  const headCells = [
    {
      name: 'Duration',
      wrapperSx: {
        width: '90px',
      },
    },
    {
      name: 'Car age (min)',
      wrapperSx: {
        width: '120px',
      },
    },
    {
      name: 'Car age (max)',
      wrapperSx: {
        width: '120px',
      },
    },
    {
      name: 'Total mileage (min)',
      wrapperSx: {
        width: '160px',
      },
    },
    {
      name: 'Total mileage (max)',
      wrapperSx: {
        width: '160px',
      },
    },
    {
      name: 'RV from Gross price',
      wrapperSx: {
        width: '125px',
      },
    },
    {
      name: 'RV from Net price',
      wrapperSx: {
        width: '125px',
      },
    },
    {
      name: 'Actions',
      wrapperSx: {
        flex: 1,
      },
    },
  ]

  return (
    <Stack
      direction="row"
      sx={{ borderBottom: '1px solid', borderColor: colorsConfig.buttonDisabled }}
    >
      {headCells.map((item) => {
        return (
          <Box
            key={item?.name}
            sx={{
              borderRight: '1px solid',
              borderColor: colorsConfig.buttonDisabled,
              '&:last-child': {
                border: 'none',
              },
              px: '15px',
              py: '10px',
              ...item?.wrapperSx,
            }}
          >
            <UITextLabelMedium text={item?.name} />
          </Box>
        )
      })}
    </Stack>
  )
}

export default RVMatrixHead
