import React from 'react'
import { colorsConfig } from '../../config/themeConfig'
import { Button } from '@mui/material'
import { FONT } from '../../utils/constants/ui.constants'

const UIButtonPlainGreen = ({ children, text, sx, prefixIcon, ...props }) => {
  return (
    <Button
      sx={{
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: FONT.PRIMARY,
        lineHeight: 'normal',
        textTransform: 'none',
        color: colorsConfig.mainGreen,
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        transition: '0.25s all ease-in-out',
        '&:hover': {
          transition: '0.25s all ease-in-out',
        },
        '&.Mui-disabled': {
          color: colorsConfig.mainGreen,
        },
        ...sx,
      }}
      {...props}
    >
      {prefixIcon}
      {text || children}
    </Button>
  )
}

export default UIButtonPlainGreen
