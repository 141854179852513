import { attachPolicyToDealer } from '../rviOperator.actions'

export default (builder) => {
  builder
    .addCase(attachPolicyToDealer.pending, (state) => {
      state.attachPolicy.isLoading = true
    })
    .addCase(attachPolicyToDealer.fulfilled, (state, action) => {
      state.attachPolicy.isLoading = false
    })
    .addCase(attachPolicyToDealer.rejected, (state, action) => {
      state.attachPolicy.isLoading = false
    })
}
