import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Table,
  useMediaQuery,
} from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import iconSearch from '../../assests/img/icons/icon-search.svg'
import iconFilter from '../../assests/img/icons/icon-filter.svg'
import { GridView, Close, AddRounded } from '@mui/icons-material'
import CarTableHead from './CarTableHead'
import { tableHeadCells } from './TableData'
import CarTableBody from './CarTableBody'
import CarList from './CarList'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import TextH1 from '../../ui/Text/TextH1'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import TextNormal from '../../ui/Text/TextNormal'
import UITextRegular from '../../ui/UIText/UITextRegular'
import UIPreloader from '../../ui/UIPreloader/UIPreloader'

const CarTable = (props) => {
  const {
    type,
    content,
    filters,
    setFilterStatus,
    searchLine,
    setSearchLine,
    sort,
    setSort,
    setCars,
    cars,
    getActionList,
    archivedCount,
    tableCells,
    tableTitle,
    loading,
  } = props

  const { t } = useTranslation()

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const navigate = useNavigate()

  const role = useSelector((state) => state.auth.role)

  const [displayMode, setDisplayMode] = useState('table')
  const [showFilters, setShowFilters] = useState(false)
  const [currentFilter, setCurrentFilter] = useState('all')

  const handleFilter = (filter) => {
    setShowFilters(false)
    if (!filter || currentFilter === filter.systemName) {
      setCurrentFilter('all')
      setFilterStatus(null)
      return
    }
    if (filter === 'Archived') {
      setCurrentFilter('Archived')
      setFilterStatus('Archived')
      return
    }
    if (filter === 'Archived' && currentFilter === 'Archived') {
      setCurrentFilter('all')
      setFilterStatus(null)
      return
    }
    setCurrentFilter(filter.systemName)
    setFilterStatus(filter.systemName)
  }

  useEffect(() => {
    if (xsOnly) {
      setDisplayMode('list')
    }
  }, [xsOnly])

  return (
    <Box>
      <Box
        className={[
          type === 'appraisal' ? 'table-header-appraisal' : '',
          type === 'appraisal-modal' ? 'table-header-appraisal-modal' : '',
          xsOnly ? 'xs-only' : '',
          tabletOnly ? 'tablet-only' : '',
        ].join(' ')}
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column-reverse',
            sm: 'row',
          },
          justifyContent: type === 'appraisal-modal' ? '' : 'space-between',
          alignItems: 'center',
          px: {
            xs: '16px',
            sm: 0,
          },
          mb: {
            xs: '30px',
            sm: 0,
          },
          '&.table-header-appraisal': {
            height: {
              xs: '0',
              sm: 'auto',
            },
            mb: {
              xs: 0,
              sm: '30px',
            },
            width: '100%',
            '& > :last-child': {
              width: '100%',
              justifyContent: 'space-between',
            },
          },
          '&.table-header-appraisal-modal': {
            '&.xs-only': {
              px: 0,
              mb: '0',
            },
          },
        }}
      >
        <Box
          sx={{
            display:
              type === 'appraisal'
                ? 'none'
                : {
                    xs: 'block',
                    sm: 'flex',
                  },
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: {
              xs: '100%',
              lg: 'auto',
            },
          }}
        >
          {type === 'cars' ? (
            <>
              <Typography
                variant="body1"
                sx={{
                  color: colorsConfig.mainBlack,
                  fontSize: '24px',
                  fontWeight: 700,
                  marginRight: '20px',
                  display: {
                    xs: 'none',
                    md: 'block',
                  },
                }}
              >
                {tableTitle ? tableTitle : t('virtual_garage_p_my_fleet')}
              </Typography>
              <TextField
                id="search"
                label={t('form_input_label_search')}
                size="small"
                value={searchLine}
                onChange={(e) => {
                  setSearchLine(e.target.value)
                }}
                sx={{
                  width: {
                    xs: '100%',
                    sm: 'auto',
                  },
                  '& .MuiInputBase-input': {
                    width: {
                      xs: '100%',
                      sm: '205px',
                    },
                    height: '38px',
                    boxSizing: 'border-box',
                    borderRadius: '6px',
                    borderColor: colorsConfig.mainBlack,
                    color: colorsConfig.mainBlack,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: '6px',
                    borderColor: colorsConfig.mainBlack,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src={iconSearch} alt="" />
                    </InputAdornment>
                  ),
                }}
              />
            </>
          ) : null}
          {type === 'auctions' ? (
            <Typography
              sx={{
                fontFamily: '"Helvetica", sans-serif',
                fontSize: '40px',
                fontWeight: 700,
                color: colorsConfig.mainBlack,
              }}
            >
              Auction
            </Typography>
          ) : null}
        </Box>
        <Box
          className={[
            xsOnly ? 'xs-only' : '',
            type === 'appraisal-modal' ? 'appraisal-modal' : '',
          ].join(' ')}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: {
              xs: 'space-between',
              sm: 'flex-start',
            },
            alignItems: 'center',
            width: {
              xs: '100%',
              sm: type === 'appraisal-modal' ? '100%' : 'auto',
            },
            mb: {
              xs: '16px',
              sm: 0,
            },
            '&.xs-only': {
              '&.appraisal-modal': {
                mb: 0,
              },
            },
          }}
        >
          {type === 'cars' ? (
            <Box
              sx={{
                width: '95px',
                height: '48px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid',
                borderColor: colorsConfig.mainBlack,
                borderRadius: '6px',
                position: 'relative',
                boxSizing: 'border-box',

                '&::after': {
                  content: '" "',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  left: '50%',
                  marginLeft: '-0.5px',
                  background: colorsConfig.mainBlack,
                  height: '100%',
                  width: '1px',
                },
              }}
            >
              <IconButton
                sx={{
                  width: '50%',
                  height: '100%',
                  position: 'relative',
                  borderRadius: '0 !important',
                  '&:hover': {
                    borderRadius: '0',
                  },
                }}
                onClick={() => {
                  setDisplayMode('table')
                }}
              >
                <div className={`burger-line-wrapper ${displayMode === 'table' ? 'active' : ''}`}>
                  <div className="burger-line" />
                  <div className="burger-line" />
                  <div className="burger-line" />
                </div>
              </IconButton>
              <IconButton
                sx={{
                  width: '50%',
                  height: '100%',
                  position: 'relative',
                  borderRadius: '0 !important',
                  '&:hover': {
                    borderRadius: '0',
                  },
                }}
                onClick={() => {
                  setDisplayMode('list')
                }}
              >
                <GridView
                  sx={{
                    fill: displayMode === 'list' ? colorsConfig.mainBlue : colorsConfig.mainBlack,
                  }}
                />
              </IconButton>
            </Box>
          ) : null}
          {type === 'auctions' ? (
            <TextField
              id="search"
              label={t('form_input_label_search')}
              size="small"
              value={searchLine}
              onChange={(e) => {
                setSearchLine(e.target.value)
              }}
              sx={{
                '& .MuiInputBase-input': {
                  width: '205px',
                  height: '38px',
                  boxSizing: 'border-box',
                  borderRadius: '6px',
                  borderColor: colorsConfig.mainBlack,
                  color: colorsConfig.mainBlack,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderRadius: '6px',
                  borderColor: colorsConfig.mainBlack,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={iconSearch} alt="" />
                  </InputAdornment>
                ),
              }}
            />
          ) : null}
          {type === 'appraisal' && !xsOnly ? (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              {tableTitle && (
                <Typography
                  variant="body1"
                  sx={{
                    color: colorsConfig.mainBlack,
                    fontSize: '24px',
                    fontWeight: 700,
                    marginRight: '20px',
                    display: {
                      xs: 'none',
                      md: 'block',
                    },
                  }}
                >
                  {tableTitle}
                </Typography>
              )}
              <TextField
                id="search"
                label={t('form_input_label_search')}
                size="small"
                value={searchLine}
                onChange={(e) => {
                  setSearchLine(e.target.value)
                }}
                sx={{
                  '& .MuiInputBase-input': {
                    width: '205px',
                    height: '38px',
                    boxSizing: 'border-box',
                    borderRadius: '6px',
                    borderColor: colorsConfig.mainBlack,
                    color: colorsConfig.mainBlack,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: '6px',
                    borderColor: colorsConfig.mainBlack,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src={iconSearch} alt="" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          ) : null}
          {type === 'appraisal-modal' ? (
            <Box
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '70px',
                '&.xs-only': {
                  display: 'block',
                  mb: '64px',
                  mt: '40px',
                },
              }}
            >
              <TextH1
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  fontSize: '32px',
                  '&.xs-only': {
                    fontSize: '24px',
                    mb: '10px',
                  },
                }}
              >
                {t('modal_title_choose_car_for_appraisal')}
              </TextH1>
              <TextField
                fullWidth={xsOnly}
                id="search"
                label={t('form_input_label_search')}
                size="small"
                value={searchLine}
                onChange={(e) => {
                  setSearchLine(e.target.value)
                }}
                sx={{
                  '& .MuiInputBase-input': {
                    width: !xsOnly ? '210px' : '100%',
                    height: '38px',
                    boxSizing: 'border-box',
                    borderRadius: '6px',
                    borderColor: colorsConfig.mainBlack,
                    color: colorsConfig.mainBlack,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: '6px',
                    borderColor: colorsConfig.mainBlack,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src={iconSearch} alt="" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          ) : null}
          <Box
            sx={{
              marginLeft: type === 'appraisal-modal' ? '' : '24px',
              position: 'relative',
            }}
          >
            {type === 'cars' || type === 'auctions' ? (
              <>
                <Button
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    px: '17px',
                    py: '11.2px',
                    border: '1px solid',
                    borderColor: colorsConfig.mainBlack,
                    textTransform: 'none',
                  }}
                  onClick={() => {
                    setShowFilters(!showFilters)
                  }}
                >
                  <Box
                    sx={{
                      width: '18px',
                      height: '18px',
                    }}
                  >
                    <img src={iconFilter} alt="" />
                  </Box>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: colorsConfig.mainBlack,
                      fontSize: '16px',
                      marginLeft: '10px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('form_input_label_filter_statuses')}
                  </Typography>
                </Button>
                <Box
                  className={showFilters ? 'active' : ''}
                  sx={{
                    position: 'absolute',
                    width: '375px',
                    maxHeight: '400px',
                    overflow: 'auto',
                    background: '#F7FAFB',
                    top: 'calc(100% + 2px)',
                    right: 0,
                    paddingTop: '33px',
                    zIndex: 20,
                    opacity: 0,
                    transform: 'scale(0.5)',
                    transformOrigin: '100% 0%',
                    pointerEvents: 'none',
                    transition: '0.25s all ease-in-out',
                    '&.active': {
                      opacity: 1,
                      transform: 'scale(1)',
                      pointerEvents: 'auto',
                      transition: '0.3s all ease-in-out',
                    },
                  }}
                >
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      zIndex: 10,
                    }}
                    onClick={() => {
                      setShowFilters(false)
                    }}
                  >
                    <Close
                      sx={{
                        color: colorsConfig.mainRed,
                      }}
                    />
                  </IconButton>
                  <Button
                    className={currentFilter === 'all' ? 'active' : ''}
                    sx={{
                      background: '#F7FAFB',
                      width: '100%',
                      color: colorsConfig.mainBlack,
                      textTransform: 'none',
                      textAlign: 'left',
                      justifyContent: 'flex-start',
                      fontWeight: 400,
                      paddingLeft: '30px',
                      paddingRight: '30px',
                      '&:hover': {
                        background: 'white',
                      },
                      '&.active': {
                        background: 'white',
                      },
                    }}
                    onClick={() => {
                      handleFilter(null)
                    }}
                  >
                    <Typography
                      sx={{
                        color: colorsConfig.mainBlack,
                        textTransform: 'none',
                      }}
                    >
                      {t('common_all')}
                    </Typography>
                  </Button>

                  {filters.map((item, index) => {
                    return (
                      <Button
                        key={index}
                        className={currentFilter === item.systemName ? 'active' : ''}
                        sx={{
                          background: '#F7FAFB',
                          width: '100%',
                          color: colorsConfig.mainBlack,
                          textTransform: 'none',
                          textAlign: 'left',
                          justifyContent: 'flex-start',
                          fontWeight: 400,
                          paddingLeft: '30px',
                          paddingRight: '30px',
                          '&:hover': {
                            background: 'white',
                          },
                          '&.active': {
                            background: 'white',
                          },
                        }}
                        onClick={() => {
                          handleFilter(item)
                        }}
                      >
                        <Typography
                          sx={{
                            color: colorsConfig.mainBlack,
                            textTransform: 'none',
                          }}
                        >
                          {item.label} <span style={{ fontWeight: 700 }}>({item.count})</span>
                        </Typography>
                      </Button>
                    )
                  })}
                  {archivedCount && archivedCount > 0 ? (
                    <Button
                      className={currentFilter === 'archived' ? 'active' : ''}
                      sx={{
                        background: '#F7FAFB',
                        width: '100%',
                        color: colorsConfig.mainBlack,
                        textTransform: 'none',
                        textAlign: 'left',
                        justifyContent: 'flex-start',
                        fontWeight: 400,
                        paddingLeft: '30px',
                        paddingRight: '30px',
                        '&:hover': {
                          background: 'white',
                        },
                        '&.active': {
                          background: 'white',
                        },
                      }}
                      onClick={() => {
                        handleFilter('Archived')
                      }}
                    >
                      <Typography
                        sx={{
                          color: colorsConfig.iconGray,
                          textTransform: 'none',
                        }}
                      >
                        {t('common_archived')}{' '}
                        <span style={{ fontWeight: 700 }}>({archivedCount})</span>
                      </Typography>
                    </Button>
                  ) : null}
                </Box>
              </>
            ) : null}
          </Box>
        </Box>
      </Box>

      {role === 'COMPANY' && xsOnly && type !== 'appraisal-modal' ? (
        <Box
          sx={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            display: 'flex',
            px: '16px',
            mt: '30px',
            mb: '10px',
          }}
        >
          <Button
            fullWidth={xsOnly}
            variant="contained"
            sx={{
              background: colorsConfig.mainGreen,
              borderRadius: '4px',
              fontSize: '16px',
              fontWeight: 700,
              textTransform: 'none',
              px: '60px',
              py: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              color: colorsConfig.mainBlack,
              transition: '0.25s all ease-in-out',
              '&:hover': {
                background: colorsConfig.mainGreen,
                opacity: '0.8',
                transition: '0.25s all ease-in-out',
              },
            }}
            onClick={() => {
              navigate('/add-new-vehicle')
            }}
          >
            <AddRounded
              sx={{
                fill: colorsConfig.mainBlack,
                mr: '11px',
                position: 'relative',
                mt: '-1px',
              }}
            />
            {t('button_add_vehicle')}
          </Button>
        </Box>
      ) : null}
      <Box
        className={tabletOnly ? 'tablet-only' : ''}
        sx={{
          mt: '20px',
          display: {
            lg: 'block',
          },
          maxWidth: '100vw',
          overflowY: {
            xs: displayMode === 'table' ? 'auto' : 'visible',
            lg: 'visible',
          },
          px: {
            xs: '16px',
            lg: 0,
          },
          py: {
            xs: displayMode === 'table' ? '20px' : 0,
            lg: 0,
          },
          position: 'relative',
          '&.tablet-only': {
            position: 'relative',
            left: '-16px',
            width: 'calc(100vw + 32px)',
          },
        }}
      >
        <Box
          sx={{
            minWidth: {
              lg: '0',
              xs: displayMode === 'table' ? '1000px' : 0,
            },
          }}
        >
          <Box
            className={[
              xsOnly ? 'xs-only' : '',
              xsOnly && displayMode !== 'table' ? 'wrapper-hidden' : '',
              tabletOnly ? 'tablet-only' : '',
            ].join(' ')}
            sx={{
              '&.xs-only': {
                p: '30px',
                borderRadius: '6px',
                boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
              },
              '&.tablet-only': {
                p: '30px',
                borderRadius: '6px',
                boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
              },
              '&.wrapper-hidden': {
                p: 0,
                boxShadow: 'none',
                borderRadius: 0,
              },
              ...(type === 'appraisal-modal' && {
                maxHeight: '490px',
                overflow: 'auto',
                scrollBehavior: 'smooth',
                scrollbarGutter: 'stable',
                scrollbarWidth: 'thin', // For Firefox
                '&::-webkit-scrollbar': {
                  width: '5px',
                  height: '120px',
                  background: colorsConfig.buttonDisabled,
                  borderRadius: '5px',
                },
                '&::-webkit-scrollbar-thumb': {
                  height: '70px',
                  background: colorsConfig.mainBlue,
                  borderRadius: '5px',
                },
                paddingRight: '40px',
              }),
            }}
          >
            {loading ? <UIPreloader /> : null}
            {xsOnly && type !== 'appraisal' && displayMode === 'table' ? (
              <TextBoldNormal
                sx={{
                  fontSize: '24px',
                  mb: '10px',
                }}
              >
                {t('virtual_garage.my_fleet')}
              </TextBoldNormal>
            ) : null}
            {content.length !== 0 ? (
              <>
                {displayMode === 'table' ? (
                  <Table>
                    <CarTableHead
                      tableCells={tableCells ? tableCells : tableHeadCells}
                      sort={sort}
                      setSort={setSort}
                    />
                    <CarTableBody
                      content={content}
                      setCars={setCars}
                      cars={cars}
                      getActionList={getActionList}
                    />
                  </Table>
                ) : (
                  <CarList
                    tableCells={tableCells ? tableCells : tableHeadCells}
                    content={content}
                    setCars={setCars}
                    cars={cars}
                    getActionList={getActionList}
                  />
                )}
              </>
            ) : (
              <UITextRegular
                sx={{
                  minHeight: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                text="table_cars_not_found"
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CarTable
