import React from 'react'
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Stack,
  Box,
  useMediaQuery,
  styled,
} from '@mui/material'
import UITextTitleSmall from '../UIText/UITextTitleSmall'
import UITriangleButton from '../UIButtons/UITriangleButton'
import { useTranslation } from 'react-i18next'
import UITableBodyCell from './UITableBodyCell'
import UITextRegular from '../UIText/UITextRegular'
import UITextBodySmall from '../UIText/UITextBodySmall'
import { colorsConfig } from '../../config/themeConfig'
import { useNavigate } from 'react-router-dom'
import { UISize } from '../../utils/constants/ui.constants'
import UITableNoDataIcon from './UITableNoDataIcon'
import IconAppleRounded from '../../assests/img/icons/icon-apple-rounded.svg'
import IconCarDefault from '../../assests/img/car-not-found.png'

const UITable = ({
  head,
  content,
  emptyText,
  sort,
  setSort,
  renderActionItems,
  minMobileWidth = '1000px',
  size = UISize.md,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const ImageWrapper = styled(Box)(({ theme }) => ({
    maxWidth: '86px',
    maxHeight: '52px',
    borderRadius: '6px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    zIndex: 2,
    '& img': {
      width: '100%',
      objectFit: 'fill',
    },
  }))

  return content && content?.length !== 0 ? (
    <Table
      sx={{
        minWidth: {
          xs: minMobileWidth,
          sm: 'auto',
        },
      }}
    >
      <TableHead sx={{ border: 'none' }}>
        <TableRow>
          {head.map((item) => {
            return (
              <UITableBodyCell key={item.title}>
                <UITextTitleSmall sx={{ position: 'relative', display: 'inline-flex' }}>
                  {t(item.title)}
                  {item.sortable && sort ? (
                    <React.Fragment>
                      <UITriangleButton
                        direction="down"
                        active={sort.order === 'desc' && sort.key === item.key}
                        onClick={() => {
                          if (sort.order === 'desc' && sort.key === item.key) {
                            setSort({ order: '', key: '' })
                            return
                          }
                          setSort({
                            order: 'desc',
                            key: item.key,
                          })
                        }}
                      />
                      <UITriangleButton
                        direction="up"
                        active={sort.order === 'asc' && sort.key === item.key}
                        onClick={() => {
                          if (sort.order === 'asc' && sort.key === item.key) {
                            setSort({ order: '', key: '' })
                            return
                          }
                          setSort({
                            order: 'asc',
                            key: item.key,
                          })
                        }}
                      />
                    </React.Fragment>
                  ) : null}
                </UITextTitleSmall>
              </UITableBodyCell>
            )
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {content.map((row) => {
          return (
            <TableRow
              key={row.id}
              sx={{ my: '8px', cursor: row?.link ? 'pointer' : 'auto' }}
              onClick={() => {
                if (row?.link) {
                  navigate(row?.link)
                }
              }}
            >
              {row.cells.map((cell, index) => {
                return (
                  <UITableBodyCell
                    key={cell.key ?? index}
                    sx={{
                      py: cell.type === 'action' ? 0 : size === UISize.sm ? '5.5px' : '12px',
                    }}
                    onClick={(e) => {
                      if (cell.type === 'action' && row?.link) {
                        e.stopPropagation()
                      }
                    }}
                  >
                    {cell.type === 'text' ? (
                      cell?.value ? (
                        <UITextRegular text={cell.value} />
                      ) : (
                        <UITableNoDataIcon />
                      )
                    ) : null}
                    {cell.type === 'image' ? (
                      <ImageWrapper>
                        <Box
                          sx={{
                            position: 'relative',
                          }}
                        >
                          <img
                            src={cell.value ?? IconCarDefault}
                            alt=""
                            style={{
                              verticalAlign: 'top',
                            }}
                          />
                          {row.greenCar ? (
                            <Box
                              sx={{
                                position: 'absolute',
                                bottom: '-0px',
                                right: '-0px',
                              }}
                            >
                              <img
                                src={IconAppleRounded}
                                alt=""
                                style={{
                                  verticalAlign: 'top',
                                }}
                              />
                            </Box>
                          ) : null}
                        </Box>
                      </ImageWrapper>
                    ) : null}
                    {cell.type === 'text-with-vin' ? (
                      <Stack>
                        {cell?.value ? <UITextRegular text={cell.value} /> : <UITableNoDataIcon />}
                        {cell?.vin && (
                          <UITextBodySmall
                            sx={{ color: colorsConfig.lightGray, whiteSpace: 'no-wrap' }}
                            text={`VIN: ${cell?.vin}`}
                          />
                        )}
                      </Stack>
                    ) : null}
                    {cell.type === 'component' ? cell.value : null}
                    {cell.type === 'action' && renderActionItems ? renderActionItems(row) : null}
                  </UITableBodyCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  ) : (
    <Box
      sx={{
        minHeight: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <UITextRegular text={t(emptyText)} sx={{ color: colorsConfig.lightGray }} />
    </Box>
  )
}

export default UITable
