import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { FONT } from '../../utils/constants/ui.constants'

const UITextLabelExtraLarge = ({
  children,
  fontWeight = 400,
  color = colorsConfig.mainBlack,
  fontSize = '24px',
  text,
  sx,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Typography
      sx={{
        color,
        fontWeight,
        fontSize,
        fontFamily: FONT.PRIMARY,
        lineHeight: 'normal',
        ...sx,
      }}
      {...props}
    >
      {children || t(text)}
    </Typography>
  )
}

export default UITextLabelExtraLarge
