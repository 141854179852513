import managersApiService from '../../../api/managersApiService/managersApiService'
import { toast } from 'react-toastify'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getApiError } from '../../../api/api'

export const createManager = createAsyncThunk(
  'manager/createManager',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await managersApiService.createManager(data)
      return response.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)

export const getManager = createAsyncThunk(
  'manager/getManager',
  async ({ managerId }, { rejectWithValue }) => {
    try {
      const response = await managersApiService.getManager(managerId)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const updateManager = createAsyncThunk(
  'manager/updateManager',
  async ({ managerId, data }, { rejectWithValue }) => {
    try {
      const response = await managersApiService.updateManager(managerId, data)
      return response.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)
