import React from 'react'
import { Box, IconButton, Modal } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { Close } from '@mui/icons-material'
import TextH1 from '../../ui/Text/TextH1'
import TextNormal from '../../ui/Text/TextNormal'
import ButtonDanger from '../../ui/Buttons/ButtonDanger'
import ButtonLG from '../../ui/Buttons/ButtonLG'

const ServiceHintContinueAppraisalModal = (props) => {
  const { open, setOpen, handleAction } = props

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 686,
          background: colorsConfig.bgLightBlue,
          borderRadius: '10px',
          padding: '30px',
          pb: '35px',
          maxHeight: '80vh',
          overflow: 'auto',
        }}
        className={'disable-scrollbar'}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          <Close
            style={{
              fill: colorsConfig.mainRed,
            }}
          />
        </IconButton>
        <TextH1
          sx={{
            mb: '16px',
          }}
        >
          Appraisal is not finished
        </TextH1>
        <TextNormal
          sx={{
            mb: '60px',
          }}
        >
          Please finish the appraisal and go through the quality check before applying the car to
          the auction
        </TextNormal>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '24px',
          }}
        >
          <ButtonDanger
            sx={{
              flex: 1,
            }}
            onClick={() => {
              setOpen(false)
            }}
          >
            Cancel
          </ButtonDanger>
          <ButtonLG
            sx={{
              flex: 1,
            }}
            onClick={() => {
              handleAction()
            }}
          >
            Continue appraisal
          </ButtonLG>
        </Box>
      </Box>
    </Modal>
  )
}

export default ServiceHintContinueAppraisalModal
