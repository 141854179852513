import { createAsyncThunk } from '@reduxjs/toolkit'
import coinPricingApiService from '../../../api/coinPricingsService/coinPricingsApiService'
import { toast } from 'react-toastify'

export const getCoinPricings = createAsyncThunk(
  'coinPricings/getCoinPricings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await coinPricingApiService.getCoinPricings()
      return response.data['hydra:member']
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e.response.data)
    }
  },
)

export const updateCoinPricing = createAsyncThunk(
  'coinPricings/updateCoinPricing',
  async ({ coinPricing, data }, { rejectWithValue }) => {
    try {
      const response = await coinPricingApiService.updateCoinPricing(coinPricing, data)
      return response.data
    } catch (e) {
      if (!e.response) throw e
      toast.error(e.response.data.error)
      return rejectWithValue(e.response.data)
    }
  },
)
