import React, { useEffect, useRef } from 'react'
import { TableBody, TableRow, TableCell, Typography, Box, styled, Button } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { useNavigate } from 'react-router-dom'
import CarMiniAction from './CarMiniAction'
import TextNormal from '../../ui/Text/TextNormal'
import IconAppleRounded from '../../assests/img/icons/icon-apple-rounded.svg'
import TableRowGreenWrapper from '../../ui/Tables/TableRowGreenWrapper'

const CarTableBody = (props) => {
  const { content, setCars, cars, getActionList, small, disableColumns } = props

  const navigate = useNavigate()

  const StyledCell = styled(TableCell)(({ theme }) => ({
    border: 'none',
    paddingTop: '12px',
    paddingBottom: '12px',
    '&:first-of-type': {
      paddingLeft: 0,
    },
    '&:last-of-type': {
      paddingRight: 0,
    },
  }))

  const CellText = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    fontSize: '16px',
    color: colorsConfig.mainBlack,
    '&.variant-sm': {
      fontSize: '14px',
    },
  }))

  const CellCaption = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    fontSize: '12px',
    color: colorsConfig.lightGray,
    marginTop: '1px',
    '&.variant-sm': {
      fontSize: '10px',
      whiteSpace: 'nowrap',
    },
  }))

  const ImageWrapper = styled(Box)(({ theme }) => ({
    maxWidth: '86px',
    maxHeight: '52px',
    borderRadius: '6px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '100%',
      objectFit: 'fill',
    },
  }))

  return (
    <TableBody>
      {content.map((row) => {
        return (
          <TableRow
            key={row.id}
            sx={{
              mt: '8px',
              mb: '8px',
            }}
          >
            {row.cells.map((cell, index) => {
              if (disableColumns && disableColumns?.length > 0) {
                if (disableColumns.find((item) => item === index + 1)) return null
              }
              return (
                <StyledCell
                  key={index}
                  onClick={() => {
                    if (
                      row.cells.filter((item) => item.type === 'bids')[0] &&
                      row.cells[4].value !== 'Auction Running'
                    ) {
                      return
                    }
                    if (
                      row.cells.filter((item) => item.type === 'bids')[0] &&
                      row.cells[4].value === 'Auction Running'
                    ) {
                      navigate(`/cars-on-auction/${row.id}`)
                      return
                    }
                    if (cell.type !== 'actions') {
                      navigate(row.link, {
                        state: row?.linkParams,
                      })
                    }
                  }}
                  className={row.greenCar ? 'green' : ''}
                  sx={{
                    position: 'relative',
                    cursor: cell.type !== 'actions' ? 'pointer' : 'auto',
                    maxWidth: cell?.maxWidth,
                    '&:first-of-type': {
                      pl: '5px',
                    },
                    '&.green': {
                      background: colorsConfig.lightGreen,
                      borderTopLeftRadius: index === 0 ? '8px' : '',
                      borderTopRightRadius: index === row.cells.length - 1 ? '8px' : '',
                      borderBottomLeftRadius: index === 0 ? '8px' : '',
                      borderBottomRightRadius: index === row.cells.length - 1 ? '8px' : '',
                    },
                  }}
                >
                  <Box
                    sx={{
                      background: row.greenCar ? 'green' : '',
                      width: '100%',
                      height: '100%',
                    }}
                  ></Box>
                  {cell.type === 'text' ? (
                    <CellText
                      className={small ? 'variant-sm' : ''}
                      sx={{
                        position: 'relative',
                        zIndex: 2,
                        whiteSpace: cell.noWrap ? 'nowrap' : '',
                      }}
                    >
                      {cell.value}
                    </CellText>
                  ) : null}
                  {cell.type === 'image' ? (
                    <ImageWrapper
                      sx={{
                        position: 'relative',
                        zIndex: 2,
                      }}
                    >
                      <Box
                        sx={{
                          position: 'relative',
                        }}
                      >
                        <img
                          src={cell.value}
                          alt=""
                          style={{
                            verticalAlign: 'top',
                          }}
                        />
                        {row.greenCar ? (
                          <Box
                            sx={{
                              position: 'absolute',
                              bottom: '-0px',
                              right: '-0px',
                            }}
                          >
                            <img
                              src={IconAppleRounded}
                              alt=""
                              style={{
                                verticalAlign: 'top',
                              }}
                            />
                          </Box>
                        ) : null}
                      </Box>
                    </ImageWrapper>
                  ) : null}
                  {cell.type === 'text-with-caption' ? (
                    <Box
                      sx={{
                        position: 'relative',
                        zIndex: 2,
                      }}
                    >
                      <CellText>{cell.value}</CellText>
                      <CellCaption
                        className={small ? 'variant-sm' : ''}
                        sx={{
                          whiteSpace: cell.noWrap ? 'nowrap' : '',
                        }}
                      >
                        {cell.caption}
                      </CellCaption>
                    </Box>
                  ) : null}
                  {cell.type === 'actions' ? (
                    <CarMiniAction
                      cell={cell}
                      carId={row.id}
                      auctionCarId={row.auctionCarId ? row.auctionCarId : null}
                      cars={cars}
                      setCars={setCars}
                      getActionList={getActionList}
                      car={row.car}
                    />
                  ) : null}
                  {cell.type === 'component' ? cell.value : null}
                  {cell.type === 'bids' ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '160px',
                        position: 'relative',
                        zIndex: 2,
                      }}
                    >
                      <TextNormal
                        sx={{
                          px: '10px',
                          py: '6px',
                          borderRadius: '4px',
                          border: '1px solid',
                          borderColor: colorsConfig.mainBlack,
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {cell.value.amount}
                      </TextNormal>
                      {cell.value.actionAvailable ? (
                        <Button
                          sx={{
                            textTransform: 'none',
                            color: colorsConfig.mainBlue,
                            fontSize: '16px',
                            fontWeight: 400,
                            ml: '10px',
                            fontFamily: '"Helvetica", sans-serif',
                            textDecoration: 'underline',
                            transition: '0.25s all ease-in-out',
                            '&:hover': {
                              color: colorsConfig.buttonHover,
                              transition: '0.25s all ease-in-out',
                            },
                          }}
                        >
                          Change
                        </Button>
                      ) : null}
                    </Box>
                  ) : null}
                  {cell.type === 'text-with-marker' ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        position: 'relative',
                        zIndex: 2,
                      }}
                    >
                      <Box
                        sx={{
                          width: '18px',
                          height: '18px',
                          borderRadius: '50%',
                          background: cell.color,
                          mr: '9px',
                        }}
                      />
                      <TextNormal>{cell.value}</TextNormal>
                    </Box>
                  ) : null}
                </StyledCell>
              )
            })}
          </TableRow>
        )
      })}
    </TableBody>
  )
}

export default CarTableBody
