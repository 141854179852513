import formDate from '../../../../components/Forms/FormComponents/FormDate'

export const editRVIPolicyInitialState = {
  policyNumber: '',
  internalCustomerId: '',
  dealerId: '',
  modelId: '',
  customerContractNumber: '',
  color: '',
  mileage: '',
  vehicleGrossPriceExcludingOptionsIncludingVAT: '',
  vehicleOptionsIncludingVAT: '',
  discountIncludingVAT: '',
  vehicleGrossPriceIncludingOptionsIncludingVAT: '',
  vehicleNetPriceIncludingVAT: '',
  mileagePa: '',
  contractDurationInMonths: '',
  offerId: '',
  firstRegistrationDate: '',
  salesPeriod: '',
  businessPartnerId: '',
  RVWarrant: '',
  customerReference: '',
  listPriceIncludingOptionsExcludingVAT: '',
  RVPremiumExcludingVAT: '',
  RVPremiumPercent: '',
  risk: '',
  handlingFee: '',
  inRisk: '',
  reinsurance: '',
  reinsurer: '',
  reinsuranceBrutto: '',
  insuranceTax: '',
  reinsuranceNetto: '',
  reinsuranceRisk: '',
  reinsuranceVK: '',
  reserves: '',
  status: '',
  realEndDate: null,
  initialRVPercent: '',
  initialResidualValueIncludingVAT: '',
  RVPremiumPaid: false,
  RVIStartDate: null,
  RVIEndDate: null,
  modelName: '',
  makeName: '',
}

export const getRVIPolicyFormData = (formData) => {
  const data = formData
  delete data.realEndDate
  return {
    details: {
      ...data,
      make: data.make?.['@id'] ?? null,
      model: data.model?.['@id'] ?? null,
      risk: data.risk !== '' ? data.risk : '0',
      mileage: data.mileage !== '' ? parseInt(data.mileage) : 0,
      mileagePa: data.mileagePa !== '' ? parseInt(data.mileagePa) : 0,
      RVPremiumPercent: data.RVPremiumPercent !== '' ? parseFloat(data.RVPremiumPercent) : 0,
      initialRVPercent: data.initialRVPercent !== '' ? parseFloat(data.initialRVPercent) : 0,
      initialResidualValueIncludingVAT:
        data.initialResidualValueIncludingVAT !== ''
          ? data.initialResidualValueIncludingVAT.toString()
          : '0',
    },
  }
}
