import React from 'react'
import { Box } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'

const RVMatrixWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: colorsConfig.buttonDisabled,
        borderRadius: '8px',
        overflow: 'hidden',
      }}
    >
      {children}
    </Box>
  )
}

export default RVMatrixWrapper
