import React, { useState } from 'react'
import { Item } from 'react-photoswipe-gallery'

const PhotoSectionItem = (props) => {
  const { photo } = props

  const [imgWidth, setImgWidth] = useState(0)
  const [imgHeight, setImgHeight] = useState(0)

  return (
    <Item original={photo} thumbnail={photo} width={imgWidth} height={imgHeight}>
      {({ ref, open }) => (
        <img
          style={{
            width: '100%',
            borderRadius: '6px',
          }}
          ref={ref}
          onClick={open}
          src={photo}
          onLoad={() => {
            const img = new Image()
            img.src = photo
            img.onload = () => {
              setImgHeight(img.height)
              setImgWidth(img.width)
            }
          }}
          alt=""
        />
      )}
    </Item>
  )
}

export default PhotoSectionItem
