import React from 'react'
import UIBottomModal from '../../ui/UIModal/UIBottomModal'
import UITextHeadlineSmall from '../../ui/UIText/UITextHeadlineSmall'
import { Container, Stack } from '@mui/material'
import UITextRegular from '../../ui/UIText/UITextRegular'
import UIButton from '../../ui/UIButtons/UIButton'
import { UIVariant } from '../../utils/constants/ui.constants'
import { colorsConfig } from '../../config/themeConfig'
import UIButtonLink from '../../ui/UIButtons/UIButtonLink'
import { Trans, useTranslation } from 'react-i18next'

const CookiesBottomModal = ({ open, setOpen }) => {
  const { t, i18n } = useTranslation()
  const handleOpenCookieModal = () => {
    document.getElementById(`cookie-policy-link-${i18n.language}`).click()
  }

  return (
    <UIBottomModal open={open} setOpen={setOpen} sx={{ background: colorsConfig.bgLightBlue }}>
      <Container maxWidth="lg">
        <Stack gap="30px">
          <UITextHeadlineSmall text="common_cookie_banner_title" />
          <UITextRegular>
            <Trans
              components={{
                a: <UIButtonLink onClick={() => handleOpenCookieModal()} />,
              }}
            >
              common_cookie_banner_p
            </Trans>
            {/*We use essential cookies to improve your browsing experience and analyze website*/}
            {/*traffic. By clicking "Accept", you agree to our website's cookie use as described in our{' '}*/}
            {/*<UIButtonLink onClick={() => handleOpenCookieModal()}>Cookies Policy</UIButtonLink>. You*/}
            {/*can change your cookies settings at any time.*/}
          </UITextRegular>
          <Stack direction="row" gap="10px" justifyContent="flex-end" alignItems="center">
            <UIButton
              variant={UIVariant.hollow}
              text="button_reject"
              onClick={() => setOpen(false)}
            />
            <UIButton text="button_accept" onClick={() => setOpen(false)} />
          </Stack>
        </Stack>
      </Container>
    </UIBottomModal>
  )
}

export default CookiesBottomModal
