import { updateCompany } from '../company.actions'

export default (builder) => {
  builder
    .addCase(updateCompany.pending, (state) => {
      state.update.isLoading = true
    })
    .addCase(updateCompany.fulfilled, (state) => {
      state.update.isLoading = false
      state.update.error = null
    })
    .addCase(updateCompany.rejected, (state, action) => {
      state.update.isLoading = false
      state.update.error = action.payload
    })
}
