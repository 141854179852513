import React from 'react'
import { colorsConfig } from '../../config/themeConfig'
import { Tabs } from '@mui/material'

const UITabs = ({ children, sx, ...props }) => {
  return (
    <Tabs
      variant="scrollable"
      sx={{
        width: '100%',
        '& .MuiTabs-indicator': {
          background: colorsConfig.mainGreen,
        },
        '& .MuiTab-textColorPrimary': {
          color: colorsConfig.mainBlack,
          textTransform: 'none',
          fontFamily: '"HelveticaNeue", sans-serif',
          fontSize: '16px',
          fontWeight: 500,
        },
        '& .MuiButtonBase-root': {
          background: colorsConfig.bgLightBlue,
        },
        '& .Mui-selected': {
          color: colorsConfig.mainBlack,
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Tabs>
  )
}

export default UITabs
