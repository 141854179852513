import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { privateRequest } from '../../../../utils/request'
import { API_ENDPOINTS } from '../../../../config/default'
import { toast } from 'react-toastify'
import { setCurrentPageTitle } from '../../../../store/features/authSlice'
import { HomeRounded } from '@mui/icons-material'
import { colorsConfig } from '../../../../config/themeConfig'
import BreadcrumbsComponent from '../../../../components/BreadcrumbsComponent'
import TextH1 from '../../../../ui/Text/TextH1'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'

const AdminPanelInsuranceCompaniesAdd = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [systemName, setSystemName] = useState('')
  const [email, setEmail] = useState('')

  const hasErrors = !name || !systemName || !email

  const handleAdd = () => {
    const formData = {
      name,
      sysName: systemName,
      email,
    }
    privateRequest
      .post(API_ENDPOINTS.insurance.companies, formData)
      .then(() => {
        toast.success('Added company successfully', { autoClose: 1000 })
        navigate('/admin/insurance/companies')
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data['hydra:description']) {
          toast.error(error.response.data['hydra:description'])
        } else toast.error('Unexpected error')
      })
  }

  useEffect(() => {
    dispatch(setCurrentPageTitle('Add a new insurance company'))
  }, [])

  return (
    <Box>
      <BreadcrumbsComponent
        currentPage={{ name: `Add a new insurance company` }}
        parentPages={[
          {
            name: 'Companies',
            icon: <HomeRounded sx={{ fill: colorsConfig.lightGray }} />,
            link: '/admin/insurance/companies',
          },
        ]}
      />
      <TextH1
        sx={{
          fontSize: '36px',
          mb: '30px',
          mt: '10px',
        }}
      >
        Add a new insurance company
      </TextH1>
      <Grid container spacing={'20px'}>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Company name'}
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            fullWidth
            label={'System name'}
            value={systemName}
            onChange={(e) => {
              setSystemName(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            fullWidth
            label={'Company email'}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: '40px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <ButtonLG
          sx={{
            minWidth: '187px',
          }}
          disabled={hasErrors}
          onClick={() => {
            handleAdd()
          }}
        >
          Save
        </ButtonLG>
      </Box>
    </Box>
  )
}

export default AdminPanelInsuranceCompaniesAdd
