import * as Yup from 'yup'
import { formErrors } from '../formErrors'

export const getEditCompanyFormInitialState = (data) => ({
  name: data?.name ?? '',
  invoiceEmail: data?.invoiceEmail ?? '',
  tradeLicenseNumber: data?.tradeLicenseNumber ?? '',
  tradeLicense: null,
  landlineNumber: data?.landlineNumber ?? '',
})

export const editCompanyFormFormValidationSchema = Yup.object().shape({
  name: Yup.string().required(formErrors.required),
  invoiceEmail: Yup.string().required(formErrors.required).email(formErrors.invalidEmail),
  tradeLicenseNumber: Yup.string().required(formErrors.required),
  landlineNumber: Yup.string().required(formErrors.required),
})
