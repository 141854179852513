import { createCustomerCar } from '../customerAppraisal.actions'

export default (builder) => {
  builder
    .addCase(createCustomerCar.pending, (state) => {
      state.customer.car.create.isLoading = true
    })
    .addCase(createCustomerCar.fulfilled, (state, action) => {
      state.customer.car.create.isLoading = false
      state.customer.car.create.error = null
      state.customer.car.data = action.payload
    })
    .addCase(createCustomerCar.rejected, (state, action) => {
      state.customer.car.create.isLoading = false
      state.customer.car.create.error = action.payload
    })
}
