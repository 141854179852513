import React, { useEffect, useState } from 'react'
import BreadcrumbsComponent from '../../../../components/BreadcrumbsComponent'
import IconProductType from '../../../../assests/img/icons/icon-product-type.svg'
import TextH1 from '../../../../ui/Text/TextH1'
import { Box, Grid } from '@mui/material'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import ButtonPlain from '../../../../ui/Buttons/ButtonPlain'
import { colorsConfig } from '../../../../config/themeConfig'
import { Delete } from '@mui/icons-material'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import ModalWrap from '../../../../components/Modals/ModalWrap'
import ButtonHollow from '../../../../ui/Buttons/ButtonHollow'
import ButtonDanger from '../../../../ui/Buttons/ButtonDanger'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { adminRequest } from '../../../../utils/request'
import { API_FINANCE_ADMIN_ENDPOINTS } from '../../../../config/default'
import { toast } from 'react-toastify'
import { setCurrentPageTitle } from '../../../../store/features/authSlice'

const EditProductType = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const [productTypeName, setProductTypeName] = useState('')
  const [savedProductTypeName, setSavedProductTypeName] = useState('')
  const [productTypeCode, setProductTypeCode] = useState('')
  const [confirmDeletionModal, setConfirmDeletionModal] = useState(false)
  const [disableDeleteButton, setDisableDeleteButton] = useState(false)

  const hasErrors = !productTypeName || !productTypeCode

  const handleEditProductType = () => {
    const formData = {
      title: productTypeName,
      code: productTypeCode,
    }
    adminRequest
      .patch(`${API_FINANCE_ADMIN_ENDPOINTS.productTypes}/${params.id}`, formData, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      })
      .then((response) => {
        toast.success('Updated product type successfully', { autoClose: 1000 })
        navigate('/admin/financing/product-types')
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data['hydra:description']) {
          toast.error(error.response.data['hydra:description'])
        } else toast.error('Unexpected error')
      })
  }

  const handleDeleteProductType = () => {
    setDisableDeleteButton(true)
    adminRequest
      .delete(`${API_FINANCE_ADMIN_ENDPOINTS.productTypes}/${params.id}`)
      .then((response) => {
        toast.success('Product type has been deleted', { autoClose: 1000 })
        navigate('/admin/financing/product-types')
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data['hydra:description']) {
          toast.error(error.response.data['hydra:description'])
        } else toast.error('Unexpected error')
      })
      .finally(() => {
        setDisableDeleteButton(false)
      })
  }

  const getProductType = () => {
    adminRequest
      .get(`${API_FINANCE_ADMIN_ENDPOINTS.productTypes}/${params.id}`)
      .then((response) => {
        setProductTypeName(response.data.title)
        setProductTypeCode(response.data.code)
        setSavedProductTypeName(response.data.title)
      })
  }

  useEffect(() => {
    getProductType()
    dispatch(setCurrentPageTitle('Edit product type'))
  }, [])

  return (
    <Box>
      <BreadcrumbsComponent
        currentPage={{ name: savedProductTypeName ? savedProductTypeName : `Edit product type` }}
        parentPages={[
          {
            name: 'Product types',
            icon: (
              <img
                src={IconProductType}
                style={{ filter: 'brightness(73%)', width: '13px' }}
                alt=""
              />
            ),
            link: '/admin/financing/product-types',
          },
        ]}
      />
      <TextH1
        sx={{
          fontSize: '36px',
          mb: '30px',
          mt: '10px',
        }}
      >
        Edit product type "{savedProductTypeName}"
      </TextH1>
      <Grid container spacing={'20px'}>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Product type name'}
            value={productTypeName}
            onChange={(e) => {
              setProductTypeName(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Product type code'}
            value={productTypeCode}
            onChange={(e) => {
              setProductTypeCode(e.target.value)
            }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: '40px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ButtonPlain
          sx={{
            color: colorsConfig.mainRed,
            '&:hover': {
              color: colorsConfig.darkRed,
            },
          }}
          onClick={() => {
            setConfirmDeletionModal(true)
          }}
        >
          <Delete
            sx={{
              fill: colorsConfig.mainRed,
              mr: '4px',
            }}
          />
          Delete
        </ButtonPlain>
        <ButtonLG
          sx={{
            minWidth: '187px',
          }}
          disabled={hasErrors}
          onClick={() => {
            handleEditProductType()
          }}
        >
          Save
        </ButtonLG>
      </Box>
      <ModalWrap open={confirmDeletionModal} setOpen={setConfirmDeletionModal}>
        <TextH1 sx={{ mb: '30px', fontSize: '32px' }}>
          Are you sure you want to delete product type "{savedProductTypeName}"?
        </TextH1>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <ButtonHollow
            sx={{
              flex: 1,
            }}
            onClick={() => {
              setConfirmDeletionModal(false)
            }}
          >
            Cancel
          </ButtonHollow>
          <ButtonDanger
            sx={{
              flex: 1,
              '&.Mui-disabled': {
                border: 'none',
                background: colorsConfig.lightGray,
                color: 'white',
              },
            }}
            disabled={disableDeleteButton}
            onClick={() => {
              handleDeleteProductType()
            }}
          >
            Delete
          </ButtonDanger>
        </Box>
      </ModalWrap>
    </Box>
  )
}

export default EditProductType
