import React, { useState } from 'react'
import {
  Paper,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import { colorsConfig } from '../../../config/themeConfig'

const expandAccordionIconBig = (
  <svg
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform="rotate(180)"
  >
    <path
      d="M6.70871 1.02402C7.10904 0.505947 7.89096 0.505946 8.29129 1.02402L14.7548 9.38855C15.2627 10.0459 14.7942 11 13.9635 11H1.03649C0.205806 11 -0.262713 10.0459 0.245209 9.38855L6.70871 1.02402Z"
      fill="#3D3D3D"
    />
  </svg>
)

const decodeHTMLEntities = (text) => {
  const textarea = document.createElement('textarea')
  textarea.innerHTML = text
  return textarea.value
}

const ManufacturerCarSpecs = (props) => {
  const { manufacturerSpecs, isCarPageDesign } = props

  const [isManufacturerSpecsAccordionOpen, setIsManufacturerSpecsAccordionOpen] = useState(false)

  const handleManufacturerSpecsAccordionToggle = () => {
    setIsManufacturerSpecsAccordionOpen((prevIsOpen) => !prevIsOpen)
  }

  return (
    <Accordion
      expanded={isManufacturerSpecsAccordionOpen}
      sx={{
        background: 'none',
        boxShadow: 'none',
        border: 'none',
        position: 'static',
      }}
    >
      <AccordionSummary
        expandIcon={expandAccordionIconBig}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          position: 'relative',
          height: '65px',
          width: isCarPageDesign ? '100%' : 'fit-content',
          padding: '0',
        }}
        onClick={handleManufacturerSpecsAccordionToggle}
      >
        <TextBoldNormal
          sx={{
            fontSize: '20px',
            marginRight: '8px',
            marginBottom: '3px',
          }}
        >
          Manufacturer specifications
        </TextBoldNormal>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <Paper sx={{ boxShadow: 'none' }}>
          <Grid container>
            {manufacturerSpecs.map((item, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <Typography sx={{ marginBottom: '5px' }}>{decodeHTMLEntities(item)}</Typography>
                </Grid>
              )
            })}
          </Grid>
        </Paper>
      </AccordionDetails>
    </Accordion>
  )
}

export default ManufacturerCarSpecs
