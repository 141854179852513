import React, { useEffect, useState } from 'react'
import TextH1 from '../../../../ui/Text/TextH1'
import ButtonHollow from '../../../../ui/Buttons/ButtonHollow'
import { Box, IconButton, Modal } from '@mui/material'
import { useSelector } from 'react-redux'
import TextNormal from '../../../../ui/Text/TextNormal'
import TextBoldNormal from '../../../../ui/Text/TextBoldNormal'
import { sumDelimiter } from '../../../../utils/sumDelimiter'
import { privateRequest } from '../../../../utils/request'
import { API_ENDPOINTS } from '../../../../config/default'
import LoanDealLoggingNodes from './LoanDealLoggingNodes'
import { Close } from '@mui/icons-material'
import { colorsConfig } from '../../../../config/themeConfig'

const LoanApplicationSummary = () => {
  const customer = useSelector((state) => state.finance.loanApplication.customer)
  const loanApplication = useSelector((state) => state.finance.loanApplication)

  const [loanAmount, setLoanAmount] = useState('')
  const [downPayment, setDownPayment] = useState('')
  const [managerName, setManagerName] = useState('')
  const [managerPhoneNumber, setManagerPhoneNumber] = useState('')
  const [loggingNodesModal, setLoggingNodesModal] = useState(false)

  const getManager = () => {
    privateRequest
      .get(API_ENDPOINTS.managerById(loanApplication.manager.replace(/[^0-9]/g, '')))
      .then((response) => {
        setManagerName(`${response.data.firstName} ${response.data.surname}`)
        const phoneNumber = response.data.phoneNumber
        setManagerPhoneNumber(`+${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3)}`)
      })
  }

  const handleSetMainLoanInfo = () => {
    setLoanAmount(sumDelimiter(parseInt(loanApplication.loanDeals[0].loanAmount)).replace("'", ' '))
    setDownPayment(
      Math.round((parseInt(loanApplication.dp) * 100) / parseInt(loanApplication.carPrice)),
    )
  }

  useEffect(() => {
    if (loanApplication && loanApplication.customer) {
      getManager()
      handleSetMainLoanInfo()
    }
  }, [loanApplication])

  return (
    <Box
      sx={{
        mt: '30px',
      }}
    >
      {loanApplication && loanApplication.customer ? (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <TextH1>
              Customer: {customer?.person?.firstName} {customer?.person?.lastName}
            </TextH1>
            <ButtonHollow
              onClick={() => {
                setLoggingNodesModal(true)
              }}
            >
              Open change log
            </ButtonHollow>
          </Box>
          <Box
            sx={{
              mt: '19px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: '24px',
            }}
          >
            <Box>
              <TextNormal>Loan amount</TextNormal>
              <TextBoldNormal>{loanAmount} AED</TextBoldNormal>
            </Box>
            <Box>
              <TextNormal>Down Payment</TextNormal>
              <TextBoldNormal>{downPayment} %</TextBoldNormal>
            </Box>
            <Box>
              <TextNormal>Interest rate</TextNormal>
              <TextBoldNormal>{loanApplication?.loanDeals[0]?.rate} %</TextBoldNormal>
            </Box>
            <Box>
              <TextNormal>Annual Mileage</TextNormal>
              <TextBoldNormal>10 000 km/year</TextBoldNormal>
            </Box>
            <Box>
              <TextNormal>Loan term</TextNormal>
              <TextBoldNormal>{loanApplication.term} months</TextBoldNormal>
            </Box>
          </Box>
          <Box
            sx={{
              mt: '19px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: '24px',
            }}
          >
            <Box>
              <TextNormal>Dealer</TextNormal>
              <TextBoldNormal>
                {managerName}{' '}
                <span style={{ fontWeight: 400 }}>({loanApplication.company.name})</span>
              </TextBoldNormal>
            </Box>
            <Box>
              <TextNormal>Dealer's phone number</TextNormal>
              <TextBoldNormal>
                <a
                  className={'simple-link'}
                  href={`tel:${managerPhoneNumber.trim().replace(' ', '')}`}
                  style={{
                    fontWeight: 700,
                  }}
                >
                  {managerPhoneNumber}
                </a>
              </TextBoldNormal>
            </Box>
          </Box>
          <Modal
            open={loggingNodesModal}
            onClose={() => {
              setLoggingNodesModal(false)
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 870,
                background: 'white',
                borderRadius: '10px',
                padding: '55px',
                maxHeight: '80vh',
                overflow: 'auto',
              }}
              className={'disable-scrollbar'}
            >
              <LoanDealLoggingNodes />
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                }}
                onClick={() => {
                  setLoggingNodesModal(false)
                }}
              >
                <Close
                  style={{
                    fill: colorsConfig.mainRed,
                  }}
                />
              </IconButton>
            </Box>
          </Modal>
        </>
      ) : null}
    </Box>
  )
}

export default LoanApplicationSummary
