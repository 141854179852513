import React from 'react'
import { InputLabel } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const InputLabelTFS = (props) => {
  const { children, sx, ...rest } = props

  return (
    <InputLabel
      sx={{
        color: colorsConfig.iconGray,
        fontWeight: 400,
        fontSize: '14px',
        '&.Mui-focused': {
          background: 'white',
          color: colorsConfig.mainBlue,
          boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
          overflow: 'visible',
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </InputLabel>
  )
}

export default InputLabelTFS
