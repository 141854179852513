import React from 'react'
import { Tab } from '@mui/material'

const UITab = ({ children, sx, ...props }) => {
  return (
    <Tab
      sx={{
        fontSize: '13px',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Tab>
  )
}

export default UITab
