import React, { useEffect, useState } from 'react'
import SimpleTable from '../../ui/SimpleTable/SimpleTable'
import {
  Container,
  Typography,
  Box,
  Button,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
  Autocomplete,
  Tabs,
  Tab,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import { tableHeadCells } from './TableData'
import { API_ENDPOINTS, CDN_BASE_URL } from '../../config/default'
import { useSelector } from 'react-redux'
import { privateRequest } from '../../utils/request'
import carDefaultPicture from '../../assests/img/car-not-found.png'

const Garage = () => {
  const statusConfig = {
    newCar: {
      name: 'New Car',
      color: '#0efffd',
    },
    appraisalOnSite: {
      name: 'Appraisal On-Site',
      color: '#07a6ff',
    },
    requestAppraisalOnCompound: {
      name: 'Requested Appraisal On Compound',
      color: '#9b10af',
    },
    awaitingDeliveryOnCompound: {
      name: 'Awaiting Deliery On Compound',
      color: '#4f12af',
    },
    appraisalOnCompound: {
      name: 'Appraisal On Compound',
      color: '#0600af',
    },
    qualityCheck: {
      name: 'Quality Check',
      color: '#ffe55d',
    },
    qualityCheckFailed: {
      name: 'Quality Check Failed',
      color: '#af1100',
    },
    readyForAuction: {
      name: 'Ready For Auction',
      color: '#69e577',
    },
    onAuction: {
      name: 'On Auction',
      color: '#2cb226',
    },
    dealInProgress: {
      name: 'Deal in progress',
      color: '#00ff0c',
    },
    sold: {
      name: 'Deal in progress',
      color: '#9d9d9d',
    },
    auctionPending: {
      color: '#cf6965',
      name: 'Auction Pending',
    },
    auctionActive: {
      color: '#ccde32',
      name: 'Active',
    },
    auctionCompleted: {
      color: '#32de1f',
      name: 'Active',
    },
    dealPending: {
      color: '#e9ff1f',
      name: 'Deal Pending',
    },
    dealAwaitingPayment: {
      color: '#00f1ff',
      name: 'Awaiting Payment',
    },
    dealTransportation: {
      color: '#2fff1d',
      name: 'Car sold: transportation in progress',
    },
    dealCompleted: {
      color: '#cc06ff',
      name: 'Car sold and delivered',
    },
  }

  // const filtersCars = [
  //     statusConfig.newCar.name,
  //     statusConfig.appraisalOnSite.name,
  //     statusConfig.qu.name,
  //     statusConfig.newCar.name,
  //     statusConfig.newCar.name,
  //     statusConfig.newCar.name,
  //     statusConfig.newCar.name,
  // ]
  // const filtersAuctions
  // const filtersDeals

  const navigate = useNavigate()
  const isTablet = useMediaQuery('(max-width: 700px)')

  const [loaded, setLoaded] = useState(false)
  const [cars, setCars] = useState([])
  const [carsData, setCarsData] = useState([])
  const [filters, setFilters] = useState([])
  const [companies, setCompanies] = useState([])
  const [availableFilters, setAvailableFilters] = useState([])
  const [filterStatus, setFilterStatus] = useState('all')
  const [filterCompany, setFilterCompany] = useState(null)
  const [refNumber, setRefNumber] = useState('')
  const [vin, setVin] = useState('')
  const [tab, setTab] = useState(1)

  const workflows = useSelector((state) => state.workflows.list)
  const role = useSelector((state) => state.auth.role)

  const tabs = [
    {
      label: 'My Cars',
      head: tableHeadCells,
      content: [
        {
          id: 1,
          body: [
            {
              type: 'text',
              content: '3829',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'Subaru Justy',
            },
            {
              type: 'text',
              content: '2020',
            },
            {
              type: 'text',
              content: 'Your Garage',
            },
            {
              type: 'text',
              content: '2 days',
            },
            {
              type: 'status',
              color: statusConfig.newCar.color,
              content: statusConfig.newCar.name,
            },
          ],
        },
        {
          id: 2,
          body: [
            {
              type: 'text',
              content: '4169',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'Honda Civic',
            },
            {
              type: 'text',
              content: '2014',
            },
            {
              type: 'text',
              content: 'Your Garage',
            },
            {
              type: 'text',
              content: '12 days',
            },
            {
              type: 'status',
              color: statusConfig.appraisalOnSite.color,
              content: statusConfig.appraisalOnSite.name,
            },
          ],
        },
        {
          id: 3,
          body: [
            {
              type: 'text',
              content: 'AA2121',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'Nissan 360X',
            },
            {
              type: 'text',
              content: '2002',
            },
            {
              type: 'text',
              content: 'Your Garage',
            },
            {
              type: 'text',
              content: '14 days',
            },
            {
              type: 'status',
              color: statusConfig.qualityCheck.color,
              content: statusConfig.qualityCheck.name,
            },
          ],
        },
        {
          id: 4,
          body: [
            {
              type: 'text',
              content: 'RN0023',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'Ford Mustang',
            },
            {
              type: 'text',
              content: '2010',
            },
            {
              type: 'text',
              content: 'Your Garage',
            },
            {
              type: 'text',
              content: '8 days',
            },
            {
              type: 'status',
              color: statusConfig.qualityCheckFailed.color,
              content: statusConfig.qualityCheckFailed.name,
            },
          ],
        },
        {
          id: 5,
          body: [
            {
              type: 'text',
              content: '092',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'GT 300',
            },
            {
              type: 'text',
              content: '2012',
            },
            {
              type: 'text',
              content: 'Your Garage',
            },
            {
              type: 'text',
              content: '20 days',
            },
            {
              type: 'status',
              color: statusConfig.requestAppraisalOnCompound.color,
              content: statusConfig.requestAppraisalOnCompound.name,
            },
          ],
        },
        {
          id: 6,
          body: [
            {
              type: 'text',
              content: '092',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'Subaru BRZ',
            },
            {
              type: 'text',
              content: '2023',
            },
            {
              type: 'text',
              content: 'In Delivery',
            },
            {
              type: 'text',
              content: '21 days',
            },
            {
              type: 'status',
              color: statusConfig.awaitingDeliveryOnCompound.color,
              content: statusConfig.awaitingDeliveryOnCompound.name,
            },
          ],
        },
        {
          id: 7,
          body: [
            {
              type: 'text',
              content: '32',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'Suzuki 960',
            },
            {
              type: 'text',
              content: '2009',
            },
            {
              type: 'text',
              content: "Insercle's Compound",
            },
            {
              type: 'text',
              content: '11 days',
            },
            {
              type: 'status',
              color: statusConfig.appraisalOnCompound.color,
              content: statusConfig.appraisalOnCompound.name,
            },
          ],
        },
        {
          id: 8,
          body: [
            {
              type: 'text',
              content: '02',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'Toyota 400',
            },
            {
              type: 'text',
              content: '2019',
            },
            {
              type: 'text',
              content: "Insercle's Compound",
            },
            {
              type: 'text',
              content: '4 days',
            },
            {
              type: 'status',
              color: statusConfig.readyForAuction.color,
              content: statusConfig.readyForAuction.name,
            },
          ],
        },
        {
          id: 9,
          body: [
            {
              type: 'text',
              content: '02',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'Ford Explorer',
            },
            {
              type: 'text',
              content: '2018',
            },
            {
              type: 'text',
              content: 'Your Garage',
            },
            {
              type: 'text',
              content: '9 days',
            },
            {
              type: 'status',
              color: statusConfig.onAuction.color,
              content: statusConfig.onAuction.name,
            },
          ],
        },
        {
          id: 10,
          body: [
            {
              type: 'text',
              content: '12',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'Ford Bronco',
            },
            {
              type: 'text',
              content: '2020',
            },
            {
              type: 'text',
              content: "Insercle's Compound",
            },
            {
              type: 'text',
              content: '41 days',
            },
            {
              type: 'status',
              color: statusConfig.dealInProgress.color,
              content: statusConfig.dealInProgress.name,
            },
          ],
        },
      ],
    },
    {
      label: 'My Auctions',
      head: [
        {
          title: 'Reference Number',
          sortable: true,
        },
        {
          title: 'Image',
          sortable: false,
        },
        {
          title: 'Brand, Model',
          sortable: true,
        },
        {
          title: 'Auction Start Time',
          sortable: true,
        },
        {
          title: 'Auction End Time',
          sortable: true,
        },
        {
          title: 'Highest Bid',
          sortable: true,
        },
        {
          title: 'Status',
          sortable: false,
        },
      ],
      content: [
        {
          id: 1,
          body: [
            {
              type: 'text',
              content: '3829',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'Subaru Justy',
            },
            {
              type: 'text',
              content: '28/02/2023 3:00 AM',
            },
            {
              type: 'text',
              content: '05/03/2023 8:00 AM',
            },
            {
              type: 'text',
              content: 'No bids',
            },
            {
              type: 'status',
              color: statusConfig.auctionPending.color,
              content: statusConfig.auctionPending.name,
            },
          ],
        },
        {
          id: 2,
          body: [
            {
              type: 'text',
              content: '3829',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'Subaru BRZ',
            },
            {
              type: 'text',
              content: '25/02/2023 5:00 PM',
            },
            {
              type: 'text',
              content: '02/03/2023 5:00 PM',
            },
            {
              type: 'text',
              content: '35000 AED',
            },
            {
              type: 'status',
              color: statusConfig.auctionActive.color,
              content: statusConfig.auctionActive.name,
            },
          ],
        },
        {
          id: 3,
          body: [
            {
              type: 'text',
              content: '0219',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'Ford Mustang',
            },
            {
              type: 'text',
              content: '02/02/2023 11:00 PM',
            },
            {
              type: 'text',
              content: '09/03/2023 11:00 PM',
            },
            {
              type: 'text',
              content: '97000 AED',
            },
            {
              type: 'status',
              color: statusConfig.auctionCompleted.color,
              content: statusConfig.auctionCompleted.name,
            },
          ],
        },
      ],
    },
    {
      label: 'My Deals',
      head: [
        {
          title: 'Reference Number',
          sortable: true,
        },
        {
          title: 'Image',
          sortable: false,
        },
        {
          title: 'Brand, Model',
          sortable: true,
        },
        {
          title: 'Amount',
          sortable: true,
        },
        {
          title: 'Location',
          sortable: true,
        },
        {
          title: 'Status',
          sortable: false,
        },
      ],
      content: [
        {
          id: 1,
          body: [
            {
              type: 'text',
              content: '3829',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'Subaru Justy',
            },
            {
              type: 'text',
              content: '150000 AED',
            },
            {
              type: 'text',
              content: 'Your Garage',
            },
            {
              type: 'status',
              color: statusConfig.dealAwaitingPayment.color,
              content: statusConfig.dealAwaitingPayment.name,
            },
          ],
        },
        {
          id: 2,
          body: [
            {
              type: 'text',
              content: '2129',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'Ford Mustang',
            },
            {
              type: 'text',
              content: '98999 AED',
            },
            {
              type: 'text',
              content: 'In Delivery',
            },
            {
              type: 'status',
              color: statusConfig.dealTransportation.color,
              content: statusConfig.dealTransportation.name,
            },
          ],
        },
        {
          id: 3,
          body: [
            {
              type: 'text',
              content: '0021',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'Nissan 350X',
            },
            {
              type: 'text',
              content: '20302 AED',
            },
            {
              type: 'text',
              content: 'Your Garage',
            },
            {
              type: 'status',
              color: statusConfig.dealPending.color,
              content: statusConfig.dealPending.name,
            },
          ],
        },
        {
          id: 4,
          body: [
            {
              type: 'text',
              content: '0021',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'Honda Civic',
            },
            {
              type: 'text',
              content: '51111 AED',
            },
            {
              type: 'text',
              content: 'Buyer',
            },
            {
              type: 'status',
              color: statusConfig.dealCompleted.color,
              content: statusConfig.dealCompleted.name,
            },
          ],
        },
      ],
    },
    {
      label: 'My Purchased Cars',
      head: [
        {
          title: 'Reference Number',
          sortable: true,
        },
        {
          title: 'Image',
          sortable: false,
        },
        {
          title: 'Brand, Model',
          sortable: true,
        },
        {
          title: 'Amount',
          sortable: true,
        },
        {
          title: 'Location',
          sortable: true,
        },
        {
          title: 'Status',
          sortable: false,
        },
      ],
      content: [
        {
          id: 1,
          body: [
            {
              type: 'text',
              content: '3829',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'Subaru Justy',
            },
            {
              type: 'text',
              content: '150000 AED',
            },
            {
              type: 'text',
              content: "Seller's Garage",
            },
            {
              type: 'status',
              color: statusConfig.dealAwaitingPayment.color,
              content: statusConfig.dealAwaitingPayment.name,
            },
          ],
        },
        {
          id: 2,
          body: [
            {
              type: 'text',
              content: '2129',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'Ford Mustang',
            },
            {
              type: 'text',
              content: '98999 AED',
            },
            {
              type: 'text',
              content: 'In Delivery',
            },
            {
              type: 'status',
              color: statusConfig.dealTransportation.color,
              content: statusConfig.dealTransportation.name,
            },
          ],
        },
        {
          id: 3,
          body: [
            {
              type: 'text',
              content: '0021',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'Nissan 350X',
            },
            {
              type: 'text',
              content: '20302 AED',
            },
            {
              type: 'text',
              content: "Seller's Garage",
            },
            {
              type: 'status',
              color: statusConfig.dealPending.color,
              content: statusConfig.dealPending.name,
            },
          ],
        },
        {
          id: 4,
          body: [
            {
              type: 'text',
              content: '0021',
            },
            {
              type: 'image',
              content: carDefaultPicture,
            },
            {
              type: 'text',
              content: 'Honda Civic',
            },
            {
              type: 'text',
              content: '51111 AED',
            },
            {
              type: 'text',
              content: 'Your Garage',
            },
            {
              type: 'status',
              color: statusConfig.dealCompleted.color,
              content: statusConfig.dealCompleted.name,
            },
          ],
        },
      ],
    },
  ]

  const filtersList = [
    {
      label: 'Status',
      items: filters,
      allowForRoles: ['COMPANY', 'OPERATOR', 'EXPERT', 'ADMIN'],
    },
  ]

  const defaultSearchLines = {
    OPERATOR:
      '&currentWorkflowState[]=on_moderation&currentWorkflowState[]=requested_appraisal_compound',
    EXPERT: '&awaiting_appraisal_compound&currentWorkflowState[]=appraisal_compound',
    COMPANY: '',
  }

  const handleSetCars = (initialData) => {
    setCars(
      initialData.map((item) => {
        let carPhoto
        if (item.foreshortenings[0]) {
          carPhoto = `${CDN_BASE_URL}${item.foreshortenings[0].photos[0].photo.path}`
        } else {
          carPhoto = carDefaultPicture
        }
        const date1 = new Date()
        const date2 = new Date(item.createdAt)
        const diffTime = Math.abs(date2 - date1)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        const curWorkflow = workflows.filter(
          (workflow) => workflow.sysName === item.currentWorkflowState,
        )[0]
        const workflowName = curWorkflow ? curWorkflow.value : ''
        const workflowColor = curWorkflow ? `#${curWorkflow.color}` : 'red'
        return {
          id: item.id,
          body: [
            {
              type: 'text',
              content:
                item.referenceNumber && item.referenceNumber !== '-'
                  ? item.referenceNumber
                  : `#${item.id}`,
            },
            {
              type: 'image',
              content: carPhoto,
            },
            {
              type: 'text-with-caption',
              content: {
                main: `${item.make.name} ${item.model.name}`,
                caption: item.vin,
              },
            },
            {
              type: 'text',
              content: item.firstRegistrationYear ? item.firstRegistrationYear : 'N/A',
            },
            {
              type: 'text',
              content: 'Your Garage',
            },
            {
              type: 'text',
              content: diffDays === 1 ? `today` : `${diffDays} days`,
            },
            {
              type: 'status',
              color: workflowColor,
              content: workflowName,
            },
          ],
        }
      }),
    )
  }

  const handleSetFilters = (initialData, role) => {
    let availableWorkflows
    switch (role) {
      case 'OPERATOR':
        availableWorkflows = initialData
          .filter(
            (item) =>
              item.sysName === 'on_moderation' || item.sysName === 'requested_appraisal_compound',
          )
          .map((item) => Object({ label: item.value, value: item.sysName }))
        break
      case 'EXPERT':
        availableWorkflows = initialData
          .filter(
            (item) =>
              item.sysName === 'awaiting_appraisal_compound' ||
              item.sysName === 'appraisal_compound',
          )
          .map((item) => Object({ label: item.value, value: item.sysName }))
        break
      default:
        availableWorkflows = initialData.map((item) =>
          Object({ label: item.value, value: item.sysName }),
        )
    }
    setFilters([
      {
        label: 'All',
        value: 'all',
      },
      ...availableWorkflows,
    ])
  }

  const getCars = (role) => {
    let requestUrl
    switch (role) {
      case 'OPERATOR':
        requestUrl = `${API_ENDPOINTS.cars}?currentWorkflowState[]=on_moderation&currentWorkflowState[]=requested_appraisal_compound&pagination=false&isArchived=false`
        break
      case 'EXPERT':
        requestUrl = `${API_ENDPOINTS.cars}?currentWorkflowState[]=awaiting_appraisal_compound&currentWorkflowState[]=appraisal_compound&pagination=false&isArchived=false`
        break
      default:
        requestUrl = `${API_ENDPOINTS.cars}?pagination=false&isArchived=false`
        break
    }
    privateRequest.get(requestUrl).then((response) => {
      setCarsData(response.data['hydra:member'])
      setLoaded(true)
    })
  }

  const getCompanies = () => {
    privateRequest.get(API_ENDPOINTS.companies).then((response) => {
      setCompanies(response.data['hydra:member'])
    })
  }

  const getFilteredCars = (status, company, refNumber, make) => {
    const statusLine =
      status === 'all'
        ? defaultSearchLines[role]
        : status === 'archived'
          ? '&isArchived=true'
          : `&currentWorkflowState=${status}`
    const companyLine = company === null ? '' : `&or[company.name]=${company}`
    const refNumberLine = refNumber === '' ? '' : `&referenceNumber=${refNumber}`
    const vinLine = vin === '' ? '' : `&vin=${vin}`
    privateRequest
      .get(
        `${API_ENDPOINTS.cars}?pagination=false${statusLine}${companyLine}${refNumberLine}${vinLine}`,
      )
      .then((response) => {
        setCarsData(response.data['hydra:member'])
      })
  }

  const handleSetAvailableFilters = () => {
    setAvailableFilters(
      filtersList.filter((item) => item.allowForRoles.filter((itemRole) => itemRole === role)[0]),
    )
  }

  const handleSetFilterStatus = (value) => {
    setFilterStatus(value)
  }

  useEffect(() => {
    if (loaded) {
      if (filterStatus === 'all' && filterCompany === 'all' && refNumber === '' && vin === '') {
        getCars(role)
      } else {
        getFilteredCars(filterStatus, filterCompany, refNumber, vin)
      }
    }
  }, [filterStatus, filterCompany, refNumber, vin])

  useEffect(() => {
    getCars(role)
    handleSetAvailableFilters()
    if (role === 'OPERATOR' || role === 'EXPERT') {
      getCompanies()
    }
  }, [])

  useEffect(() => {
    if (workflows.length > 0 && carsData.length > 0) {
      handleSetCars(carsData)
      handleSetFilters(workflows, role)
    } else if (workflows.length > 0) {
      setCars([])
      handleSetFilters(workflows, role)
    }
  }, [workflows, carsData])

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          textAlign: isTablet ? 'left' : 'center',
          mt: '40px',
          position: 'relative',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: 'center',
          }}
        >
          {role === 'OPERATOR' ? "Operator's Panel" : 'My Garage'}
        </Typography>

        {role === 'COMPANY' ? (
          <Button
            variant="contained"
            sx={{
              mt: 2,
              position: {
                xs: 'relative',
                md: 'absolute',
              },
              top: {
                xs: 'auto',
                md: '120px',
              },
              left: {
                xs: 'auto',
                md: '0px',
              },
              width: {
                xs: '100%',
                md: 'auto',
              },
            }}
            startIcon={<AddIcon />}
            onClick={() => {
              navigate('/add-new-vehicle')
            }}
          >
            Add New Vehicle
          </Button>
        ) : null}
      </Box>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Tabs
              value={tab}
              onChange={(event, newValue) => {
                setTab(newValue)
              }}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              sx={{
                width: '100%',
              }}
            >
              {tabs.map((tabItem, index) => {
                return (
                  <Tab
                    key={index}
                    label={tabItem.label}
                    sx={{
                      width: '25%',
                    }}
                  />
                )
              })}
            </Tabs>
          </Grid>
          <Grid item md={3}>
            {role === 'OPERATOR' || role === 'EXPERT' ? (
              <TextField
                fullWidth
                id="vin"
                label="VIN"
                value={vin}
                onChange={(e) => {
                  setVin(e.target.value)
                }}
              />
            ) : null}
          </Grid>
          <Grid item md={3}>
            {role === 'OPERATOR' || role === 'EXPERT' ? (
              <TextField
                fullWidth
                id="ref-number"
                label="Reference Number"
                value={refNumber}
                onChange={(e) => {
                  setRefNumber(e.target.value)
                }}
              />
            ) : null}
          </Grid>
          {/*<FormControl fullWidth>*/}
          {/*    <InputLabel id='filter-company-label'>Company</InputLabel>*/}
          {/*    <Select*/}
          {/*        labelId='filter-company-label'*/}
          {/*        id='filter-company'*/}
          {/*        label='Company'*/}
          {/*        value={filterCompany}*/}
          {/*        onChange={(e) => {*/}
          {/*            setFilterCompany(e.target.value)*/}
          {/*        }}*/}
          {/*    >*/}
          {/*        <MenuItem value='all'>*/}
          {/*            All*/}
          {/*        </MenuItem>*/}
          {/*        {*/}
          {/*            companies.map((company, index) => {*/}
          {/*                return (*/}
          {/*                    <MenuItem*/}
          {/*                        key={index}*/}
          {/*                        value={company['@id']}*/}
          {/*                    >*/}
          {/*                        {company.name}*/}
          {/*                    </MenuItem>*/}
          {/*                )*/}
          {/*            })*/}
          {/*        }*/}
          {/*    </Select>*/}
          {/*</FormControl>*/}
          <Grid item md={3} xs={12}>
            {role === 'OPERATOR' || role === 'EXPERT' ? (
              <Autocomplete
                fullWidth
                id="autocomplete-company"
                value={filterCompany}
                onChange={(e, newValue) => {
                  setFilterCompany(newValue)
                }}
                renderInput={(params) => <TextField {...params} label="Company" />}
                options={companies.map((item) => item.name)}
              />
            ) : null}
          </Grid>
          <Grid item md={3} xs={12}>
            {/*<FormControl fullWidth>*/}
            {/*    <InputLabel id='filter-status-label'>Status</InputLabel>*/}
            {/*    <Select*/}
            {/*        labelId='filter-status-label'*/}
            {/*        id='filter-status'*/}
            {/*        label='Status'*/}
            {/*        value={filterStatus}*/}
            {/*        onChange={(e) => {*/}
            {/*            handleSetFilterStatus(e.target.value)*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        {*/}
            {/*            filters.map((status, index) => {*/}
            {/*                return (*/}
            {/*                    <MenuItem*/}
            {/*                        key={index}*/}
            {/*                        value={status.value}*/}
            {/*                    >*/}
            {/*                        {status.label}*/}
            {/*                    </MenuItem>*/}
            {/*                )*/}
            {/*            })*/}
            {/*        }*/}
            {/*        <MenuItem*/}
            {/*            value='archived'*/}
            {/*        >*/}
            {/*            Archived*/}
            {/*        </MenuItem>*/}
            {/*    </Select>*/}
            {/*</FormControl>*/}
            <FormControl fullWidth>
              <InputLabel id="filter-status-label">Status</InputLabel>
              <Select
                labelId="filter-status-label"
                id="filter-status"
                label="Status"
                value={filterStatus}
                onChange={(e) => {
                  handleSetFilterStatus(e.target.value)
                }}
              >
                {filters.map((status, index) => {
                  return (
                    <MenuItem key={index} value={status.value}>
                      {status.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {cars.length !== 0 ? (
        <SimpleTable
          tableHeadCells={tabs[tab].head}
          tableCellsContent={tabs[tab].content}
          filters={filters}
        />
      ) : (
        <Box
          sx={{
            height: '400px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: 'lightgray',
            }}
          >
            No cars yet
          </Typography>
        </Box>
      )}
    </Container>
  )
}

export default Garage
