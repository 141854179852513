export const getNavigatorLanguage = () => {
  const reference = [
    {
      code: 'en',
      values: ['en', 'en-US', 'en-GB', 'en-CA', 'en-AU', 'en-NZ', 'en-IE', 'en-IN', 'en-ZA'],
    },
    { code: 'de', values: ['de', 'de-DE', 'de-AT', 'de-CH', 'de-LI', 'de-LU'] },
    { code: 'fr', values: ['fr', 'fr-BE', 'fr-FR', 'fr-CA', 'fr-CH', 'fr-LU', 'fr-MC'] },
    { code: 'it', values: ['it', 'it-IT', 'it-CH'] },
  ]
  const lang = navigator.language
  let locale = 'en'
  reference.forEach((item) => {
    if (item.values.find((val) => val === lang)) {
      locale = item?.code
    }
  })
  return locale
}
