import { createCar } from '../addNewCar.actions'

export default (builder) => {
  builder
    .addCase(createCar.pending, (state) => {
      state.isLoading = true
    })
    .addCase(createCar.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = null
      state.carCreatedId = action.payload?.['@id']
    })
    .addCase(createCar.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
}
