import { privateRequest } from '../../utils/request'
import { patchHeaders } from '../api'

class AddressesApiService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      collection: '/addresses',
      instance: (id) => `/addresses/${id}`,
    }
  }

  createAddress = async (data) => {
    const response = await this.#api.post(this.#endPoints.collection, data)
    return response
  }

  patchAddress = async (addressId, data) => {
    const response = await this.#api.patch(this.#endPoints.instance(addressId), data, {
      headers: patchHeaders,
    })
    return response
  }
}

const addressesApiService = new AddressesApiService({ api: privateRequest })

export default addressesApiService
