import { closePolicyAsDealer } from '../rviPolicy.actions'

export default (builder) => {
  builder
    .addCase(closePolicyAsDealer.pending, (state) => {
      state.closeAsDealer.isLoading = true
    })
    .addCase(closePolicyAsDealer.fulfilled, (state) => {
      state.closeAsDealer.isLoading = false
    })
    .addCase(closePolicyAsDealer.rejected, (state) => {
      state.closeAsDealer.isLoading = false
    })
}
