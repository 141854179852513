import React from 'react'
import { Box, Container, Typography } from '@mui/material'
import Banner from '../../assests/img/green-page-banner.png'
import { colorsConfig } from '../../config/themeConfig'

const GreenPageBanner = () => {
  return (
    <Box
      sx={{
        height: '279px',
        backgroundImage: `url(${Banner})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Container maxWidth={false} sx={{ maxWidth: '1240px' }}>
        <Typography
          sx={{
            color: 'white',
            fontSize: '60px',
            fontFamily: '"Inter", sans-serif',
            fontWeight: 700,
            lineHeight: 'normal',
            '& span': {
              color: colorsConfig.mainGreen,
              display: 'inline-block',
              px: '7px',
              background: 'white',
              borderRadius: '7px',
              fontWeight: 700,
              lineHeight: '59px',
              fontFamily: '"Inter", sans-serif',
            },
          }}
        >
          Turn the user Car <br />
          Market <span>GreenER</span>
        </Typography>
      </Container>
    </Box>
  )
}

export default GreenPageBanner
