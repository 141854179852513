import React from 'react'
import { colorsConfig } from '../../config/themeConfig'
import { AddRounded } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

const AddButton = ({ sx, text, prefixIcon, ...props }) => {
  const { t } = useTranslation()

  return (
    <Button
      variant="contained"
      sx={{
        background: colorsConfig.mainGreen,
        borderRadius: '4px',
        fontSize: '16px',
        fontWeight: 700,
        textTransform: 'none',
        px: '60px',
        py: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        color: colorsConfig.mainBlack,
        transition: '0.25s all ease-in-out',
        '&:hover': {
          background: colorsConfig.mainGreen,
          opacity: '0.8',
          transition: '0.25s all ease-in-out',
        },
        '& svg': {
          fill: colorsConfig.mainBlack,
          mr: '11px',
          position: 'relative',
          mt: '-1px',
        },
        ...sx,
      }}
      {...props}
    >
      {prefixIcon ? prefixIcon : <AddRounded />}
      {t(text)}
    </Button>
  )
}

export default AddButton
