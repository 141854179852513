import { createSlice } from '@reduxjs/toolkit'
import getWarrantyApplicationsBuilder from './builders/getWarrantyApplications.builder'
import getWarrantyApplicationsWorkflowStateCountsBuilder from './builders/getWarrantyApplicationsWorkflowStateCounts.builder'

const initialState = {
  isLoading: false,
  error: null,
  currentTab: 0,
  warrantyApplications: [],
  pagination: {
    itemsPerPage: 10,
    page: 0,
  },
  searchLine: '',
  workflowStateCounts: {
    isLoading: false,
    error: null,
    applicationPreparationCount: 0,
    issuedCount: 0,
  },
}

const warrantyApplicationsSlice = createSlice({
  name: 'warrantyApplications',
  initialState,
  reducers: {
    setSearchLine: (state, action) => {
      state.searchLine = action.payload
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload
      state.pagination.itemsPerPage = initialState.pagination.itemsPerPage
      state.pagination.page = initialState.pagination.page
      state.searchLine = initialState.searchLine
    },
  },
  extraReducers: (builder) => {
    getWarrantyApplicationsBuilder(builder)
    getWarrantyApplicationsWorkflowStateCountsBuilder(builder)
  },
})

export const { setSearchLine, setCurrentTab } = warrantyApplicationsSlice.actions

export default warrantyApplicationsSlice.reducer
