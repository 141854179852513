import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { useSelector } from 'react-redux'
import RBox from '../../../../ui/RBox'
import TextBoldNormal from '../../../../ui/Text/TextBoldNormal'
import { colorsConfig } from '../../../../config/themeConfig'
import UIMarker from '../../../../ui/Markers/UIMarker'
import TextWithCaption from '../../../../ui/Text/TextWithCaption'
import UIMarkerChecked from '../../../../ui/Markers/UIMarkerChecked'
import { generateUID } from '../../../../utils/generateUID'
import BoxShadowedGradient from '../../../../ui/Layout/BoxShadowedGradient'
import {
  getDealerCommission,
  getInsuranceCompanyName,
  getMonthlyInstallment,
  getSavedResponseClassName,
  getTotalPremium,
} from '../operator-insurance.helpers'
import { insuranceDealWorkflowTypes } from '../operator-insurance.constants'

const InsuranceRequestSaved = () => {
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const selectedTab = useSelector((state) => state.insuranceOperator.selectedTab)
  const deal = useSelector((state) => state.insuranceOperator.deals[selectedTab])
  const referenceCompanies = useSelector((state) => state.insuranceOperator.referenceCompanies)
  const referenceRisks = useSelector((state) => state.insuranceOperator.referenceRisks)

  return (
    <BoxShadowedGradient
      className={getSavedResponseClassName(deal?.currentWorkflowState)}
      sx={{
        pb: '30px',
        px: '15px',
        mt: '30px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          mb: '24px',
        }}
      >
        <RBox
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '&.xs-only': {
              flexWrap: 'wrap',
            },
          }}
        >
          <TextBoldNormal>
            {getInsuranceCompanyName(referenceCompanies, deal?.insuranceCompany)}:
          </TextBoldNormal>
          <TextBoldNormal
            sx={{
              ml: '6px',
              color: colorsConfig.mainBlue,
            }}
          >
            {deal?.dealName}
          </TextBoldNormal>
          {!xsOnly ? (
            <UIMarker
              text={
                insuranceDealWorkflowTypes.find(
                  (item) => item.systemName === deal?.currentWorkflowState,
                )?.name
              }
              markerVariant={getSavedResponseClassName(deal?.currentWorkflowState)}
              sx={{ ml: '10px' }}
            />
          ) : null}
        </RBox>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '30px',
        }}
      >
        <TextWithCaption
          text={getMonthlyInstallment(deal?.totalPremium)}
          caption="Monthly instalment"
        />
        <TextWithCaption
          text={getTotalPremium(deal?.totalPremium)}
          caption="Total annual instalment"
        />
        <TextWithCaption
          text={getDealerCommission(deal?.totalDealerCommission, deal?.totalPremium)}
          caption="Total dealer commission"
        />
      </Box>
      {deal?.riskDetails?.length !== 0 ? (
        <Box sx={{ mt: '30px' }}>
          <TextBoldNormal sx={{ lineHeight: 'normal', mb: '16px' }}>Coverage:</TextBoldNormal>
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              flexWrap: 'wrap',
            }}
          >
            {deal?.riskDetails?.map((item) => (
              <UIMarkerChecked
                key={generateUID()}
                text={referenceRisks.find((risk) => risk['@id'] === item?.risk)?.name}
              />
            ))}
          </Box>
        </Box>
      ) : null}
    </BoxShadowedGradient>
  )
}

export default InsuranceRequestSaved
