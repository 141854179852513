import React from 'react'
import { colorsConfig } from '../../config/themeConfig'
import IconCheckmark from '../../assests/img/icons/icon-checkmark.svg'
import { Box } from '@mui/material'

const UIStepCounter = ({ completed, current, number, sx, ...props }) => {
  return (
    <Box
      className={current ? 'active' : completed ? 'completed' : ''}
      sx={{
        width: '30px',
        minWidth: '30px',
        height: '30px',
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        background: colorsConfig.buttonDisabled,
        color: colorsConfig.mainBlack,
        '&.active': {
          background: colorsConfig.mainBlue,
          color: 'white',
        },
        '&.completed': {
          background: colorsConfig.mainBlue,
        },
        ...sx,
      }}
      {...props}
    >
      {completed ? <img src={IconCheckmark} alt="" /> : number}
    </Box>
  )
}

export default UIStepCounter
