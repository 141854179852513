import { archiveRviPolicy } from '../rviOperator.actions'

export default (builder) => {
  builder
    .addCase(archiveRviPolicy.pending, (state) => {
      state.archivePolicy.isLoading = true
    })
    .addCase(archiveRviPolicy.fulfilled, (state, action) => {
      state.archivePolicy.isLoading = false
      state.policies.data = state.policies.data.filter((item) => item?.id !== action.payload?.id)
    })
    .addCase(archiveRviPolicy.rejected, (state, action) => {
      state.archivePolicy.isLoading = false
    })
}
