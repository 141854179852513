import React, { useEffect, useState } from 'react'
import { Box, Grid, InputAdornment } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { setRiskDetails } from '../../../../store/features/insuranceOperatorSlice'
import TextBoldNormal from '../../../../ui/Text/TextBoldNormal'
import SimpleCheckbox from '../../../../ui/SimpleCheckbox'
import IconCheckmark from '../../../../assests/img/icons/icon-checkmark-black.svg'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import { generateUID } from '../../../../utils/generateUID'
import TextNormal from '../../../../ui/Text/TextNormal'
import { colorsConfig } from '../../../../config/themeConfig'
import { sumDelimiter } from '../../../../utils/sumDelimiter'
import { CURRENCY, REGION_LOCALE } from '../../../../utils/constants/global.constants'

const InsuranceRequestRisk = ({ index, showErrors }) => {
  const selectedTab = useSelector((state) => state.insuranceOperator.selectedTab)
  const riskData = useSelector(
    (state) => state.insuranceOperator.deals[selectedTab].risks[index].risksDetails,
  )
  const name = useSelector((state) => state.insuranceOperator.deals[selectedTab].risks[index].name)
  const dispatch = useDispatch()

  return (
    <Grid item xs={12} md={6} container spacing="16px">
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <TextBoldNormal sx={{ fontSize: '18px' }}>{name}</TextBoldNormal>
          <SimpleCheckbox
            icon={IconCheckmark}
            value={riskData?.checked}
            setValue={(newVal) => {
              dispatch(
                setRiskDetails({
                  index,
                  data: {
                    ...riskData,
                    checked: newVal,
                  },
                }),
              )
            }}
            boxStyles={{
              width: '24px',
              height: '24px',
            }}
            checkboxStyles={{
              p: '4px',
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldSmallEdible
          fullWidth
          label="Total annual installment"
          value={sumDelimiter(riskData?.premium) || ''}
          disabled={!riskData?.checked}
          onChange={(e) => {
            dispatch(
              setRiskDetails({
                index,
                data: {
                  ...riskData,
                  premium: e.target.value.replace(/[^0-9.]/g, ''),
                },
              }),
            )
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position={'end'}>
                <TextNormal
                  sx={{
                    fontSize: '14px',
                    color: colorsConfig.iconGray,
                  }}
                >
                  {CURRENCY[REGION_LOCALE]}
                </TextNormal>
              </InputAdornment>
            ),
          }}
          error={showErrors && riskData?.checked && !riskData?.premium}
          helperText={
            showErrors && riskData?.checked && !riskData?.premium
              ? 'This field can not be empty when risk is checked'
              : ''
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldSmallEdible
          fullWidth
          label="Total dealer commission"
          value={sumDelimiter(riskData?.dealerCommission) || ''}
          disabled={!riskData?.checked}
          onChange={(e) => {
            dispatch(
              setRiskDetails({
                index,
                data: {
                  ...riskData,
                  dealerCommission: e.target.value.replace(/[^0-9.]/g, ''),
                },
              }),
            )
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position={'end'}>
                <TextNormal
                  sx={{
                    fontSize: '14px',
                    color: colorsConfig.iconGray,
                  }}
                >
                  AED
                </TextNormal>
              </InputAdornment>
            ),
          }}
          error={showErrors && riskData?.checked && !riskData?.dealerCommission}
          helperText={
            showErrors && riskData?.checked && !riskData?.dealerCommission
              ? 'This field can not be empty when risk is checked'
              : ''
          }
        />
      </Grid>
    </Grid>
  )
}

export default InsuranceRequestRisk
