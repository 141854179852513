import React from 'react'
import UIModal from '../../../ui/UIModal/UIModal'
import { COMPANY_NAME, REGION_LOCALE } from '../../../utils/constants/global.constants'
import UIButton from '../../../ui/UIButtons/UIButton'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import UITextHeadlineLarge from '../../../ui/UIText/UITextHeadlineLarge'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { Stack } from '@mui/material'

const VINAutofillFailedModal = ({ open, setOpen }) => {
  const { isMaxWidth600 } = useBreakpoints()
  return (
    <UIModal width="688px" open={open} setOpen={setOpen}>
      <UITextHeadlineLarge
        text="😔Sorry, we don’t have information on your VIN"
        sx={{
          fontSize: isMaxWidth600 ? '24px' : '40px',
          mb: '10px',
          textAlign: isMaxWidth600 ? 'left' : 'center',
        }}
      />
      <UITextRegular
        sx={{
          fontSize: isMaxWidth600 ? '14px' : '16px',
          mb: '30px',
          textAlign: 'center',
        }}
      >
        {COMPANY_NAME[REGION_LOCALE]} coins will be returned to your account promptly
      </UITextRegular>
      <Stack direction="row" justifyContent="center">
        <UIButton sx={{ width: '300px' }} text="button_got_it" onClick={() => setOpen(false)} />
      </Stack>
    </UIModal>
  )
}

export default VINAutofillFailedModal
