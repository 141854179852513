import { linkPoliciesToDealers } from '../rviOperator.actions'

export default (builder) => {
  builder
    .addCase(linkPoliciesToDealers.pending, (state) => {
      state.importPolicies.linkPolicies.isLoading = true
    })
    .addCase(linkPoliciesToDealers.fulfilled, (state, action) => {
      state.importPolicies.linkPolicies.isLoading = false
    })
    .addCase(linkPoliciesToDealers.rejected, (state, action) => {
      state.importPolicies.linkPolicies.isLoading = false
    })
}
