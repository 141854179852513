import React from 'react'
import { Button, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { sumDelimiter } from '../../../../utils/sumDelimiter'
import { colorsConfig } from '../../../../config/themeConfig'
import { updateSelectedTerm } from '../../../../store/features/financeCar'
import TextBoldNormal from '../../../../ui/Text/TextBoldNormal'
import TextNormal from '../../../../ui/Text/TextNormal'

const FormTermsSelectItem = ({ slide, onSelectSlide, selectedTerm }) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const renderPrice = () => {
    return <>{sumDelimiter(Math.floor(slide.price)).replace("'", '')}</>
  }

  return (
    <Button
      className={[selectedTerm === slide.term ? 'active' : '', xsOnly ? 'xs-only' : ''].join(' ')}
      sx={{
        borderRadius: '6px',
        boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
        height: '161px',
        display: 'flex',
        p: 0,
        width: '100%',
        textTransform: 'none',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        transition: '0.25s all ease-in-out',
        cursor: 'pointer',
        '&:hover': {
          background: colorsConfig.mainBlue,
          transition: '0.25s all ease-in-out',
          '& .slide-title': {
            color: 'white',
            transition: '0.25s all ease-in-out',
          },
          '& .slide-caption': {
            color: 'white',
            transition: '0.25s all ease-in-out',
          },
        },
        '&.active': {
          background: colorsConfig.mainBlue,
          transition: '0.25s all ease-in-out',
          '& .slide-title': {
            color: 'white',
            transition: '0.25s all ease-in-out',
          },
          '& .slide-caption': {
            color: 'white',
            transition: '0.25s all ease-in-out',
          },
        },
        '&.xs-only': {
          py: '20px',
          height: '95px',
        },
      }}
      onClick={() => {
        onSelectSlide(slide.term)
      }}
    >
      <TextBoldNormal
        className={['slide-title', xsOnly ? 'xs-only' : ''].join(' ')}
        sx={{
          mb: '7px',
          fontSize: '24px',
          transition: '0.25s all ease-in-out',
          '&.xs-only': {
            fontSize: '16px',
            mb: '2px',
          },
        }}
      >
        {slide.term} months
      </TextBoldNormal>
      <TextNormal
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          fontSize: '16px',
          mb: '4px',
          px: '6px',
          py: '2px',
          borderRadius: '6px',
          background: colorsConfig.bgLightBlue,
          boxShadow: '0px 0px 24px 0px rgba(0, 0, 0, 0.10)',
          '&.xs-only': {
            fontSize: '12px',
            px: '4px',
          },
        }}
      >
        From {renderPrice()} CHF
      </TextNormal>
      <TextNormal
        className={'slide-caption'}
        sx={{
          fontSize: '12px',
          color: colorsConfig.lightGray,
          transition: '0.25s all ease-in-out',
        }}
      >
        per month
      </TextNormal>
    </Button>
  )
}

export default FormTermsSelectItem
