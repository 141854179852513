import React, { useEffect, useState } from 'react'
import { Box, Table } from '@mui/material'
import { useDispatch } from 'react-redux'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import { privateRequest } from '../../../utils/request'
import { API_ENDPOINTS } from '../../../config/default'
import WrapWithPreloader from '../../../components/WrapWithPreloader'
import CarTableHead from '../../VirtualGarage/CarTableHead'
import CarTableBody from '../../VirtualGarage/CarTableBody'
import ButtonPlain from '../../../ui/Buttons/ButtonPlain'
import { AddRounded } from '@mui/icons-material'
import { colorsConfig } from '../../../config/themeConfig'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import SimplePagination from '../../../ui/SimplePagination'
import { handleFormatDate } from '../../../utils/formatDate'
import TextNormal from '../../../ui/Text/TextNormal'
import { useNavigate } from 'react-router-dom'
import { tableHeadCellsRisks } from '../../../config/tablesConfig'

const AdminPanelInsuranceRisks = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [risks, setRisks] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [count, setCount] = useState(0)

  const handleSetRisks = (data) => {
    setRisks(
      data.map((item) => {
        return {
          id: item.id,
          link: `/admin/insurance/risks/${item.id}/edit`,
          cells: [
            {
              type: 'text',
              value: `#${item.id}`,
            },
            {
              type: 'text',
              value: item.name,
            },
            {
              type: 'text',
              value: 'Default',
            },
            {
              type: 'text',
              value: handleFormatDate(item?.updatedAt, '.'),
            },
          ],
        }
      }),
    )
  }

  const getRisks = () => {
    setLoading(true)
    privateRequest.get(API_ENDPOINTS.insurance.risks).then((response) => {
      handleSetRisks(response.data['hydra:member'])
      setCount(response.data['hydra:totalItems'])
      setLoading(false)
    })
  }

  useEffect(() => {
    dispatch(setCurrentPageTitle('Risks'))
    getRisks()
  }, [])

  return (
    <Box>
      <WrapWithPreloader loadingText={'Loading risks...'} loading={loading}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            mb: '20px',
          }}
        >
          <ButtonPlain
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => {
              navigate('/admin/insurance/risks/add')
            }}
          >
            <AddRounded
              sx={{
                fill: colorsConfig.mainBlue,
                mr: '4px',
                fontSize: '21px',
              }}
            />
            Add risk
          </ButtonPlain>
        </Box>
        <BoxShadowed>
          {risks.length > 0 ? (
            <Table>
              <CarTableHead tableCells={tableHeadCellsRisks} />
              <CarTableBody content={risks} />
            </Table>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <TextNormal
                sx={{
                  color: colorsConfig.lightGray,
                }}
              >
                No risks have been added yet.
              </TextNormal>
            </Box>
          )}
        </BoxShadowed>
        {count !== 0 ? (
          <SimplePagination
            count={count}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        ) : null}
      </WrapWithPreloader>
    </Box>
  )
}

export default AdminPanelInsuranceRisks
