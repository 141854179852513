import React from 'react'
import { Typography, Box } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import IconTabCheck from '../../assests/img/icons/icon-tab-check.svg'

const CarPageTabTitle = (props) => {
  const { title, isCompleted } = props

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isCompleted ? (
        <Box
          sx={{
            mr: '10px',
          }}
        >
          <img src={IconTabCheck} alt="" />
        </Box>
      ) : null}
      <Typography
        sx={{
          fontFamily: '"HelveticaNeue", sans-serif',
          fontSize: '16p',
          color: colorsConfig.mainBlack,
        }}
      >
        {title}
      </Typography>
    </Box>
  )
}

export default CarPageTabTitle
