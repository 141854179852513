import React from 'react'
import UIModal from '../../../ui/UIModal/UIModal'
import { Stack } from '@mui/material'
import UITextHeadlineSmall from '../../../ui/UIText/UITextHeadlineSmall'
import AddRVEntryForm from '../../Forms/FormTemplates/Admin/AddRVEntryForm/AddRVEntryForm'

const AddRVEntryModal = ({ open, setOpen }) => {
  return (
    <UIModal open={open} setOpen={setOpen}>
      <Stack gap="30px">
        <UITextHeadlineSmall text="Add RV matrix row" />
        <AddRVEntryForm onClose={() => setOpen(false)} />
      </Stack>
    </UIModal>
  )
}

export default AddRVEntryModal
