export const isCarDataValid = (coreData, mainData) => {
  const { vin, make, model } = coreData
  if (!vin || !make || !model || vin.length !== 17) return false
  const {
    firstRegistrationDate,
    mileage,
    engineType,
    engineSize,
    bodyType,
    origin,
    driveType,
    gearbox,
    seatsNumber,
    doorsNumber,
  } = mainData
  if (
    !firstRegistrationDate ||
    !mileage ||
    !engineType ||
    !engineSize ||
    !bodyType ||
    !origin ||
    !driveType ||
    !gearbox ||
    !seatsNumber ||
    !doorsNumber
  ) {
    return false
  }
  return true
}
