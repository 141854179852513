import { TABLE_CELL_TYPE } from '../../constants/tables/table.constants'

export const getLoanProgramsTableData = (data) => {
  if (!data) return []
  return data.map((item) => {
    return {
      initialData: item,
      id: item.id,
      link: `/admin/financing/loan-programs/${item.id}/edit`,
      cells: [
        {
          type: TABLE_CELL_TYPE.TEXT,
          value: item?.title,
        },
        {
          type: TABLE_CELL_TYPE.TEXT,
          value: item?.marketingTitle,
        },
        {
          type: TABLE_CELL_TYPE.TEXT,
          value: item?.creditType,
        },
        {
          type: TABLE_CELL_TYPE.TEXT,
          value: item?.productProvider?.title,
        },
        {
          type: 'action',
        },
      ],
    }
  })
}
